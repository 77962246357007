import {
  FETCH_AUDITS_FAILURE_ACTION,
  FETCH_AUDITS_SUCCESS_ACTION,
  FETCH_AGENT_AUDITS_SUCCESS_ACTION,
  FETCH_AGENT_AUDITS_FAILURE_ACTION,
  FETCH_AUDIT_IMAGES_SUCCESS_ACTION,
  FETCH_AUDIT_IMAGES_FAILURE_ACTION,
  UPDATE_AUDIT_SUCCESS_ACTION,
  UPDATE_AUDIT_FAILURE_ACTION,
  DOWNLOAD_REPORT_SUCCESS_ACTION,
  DOWNLOAD_REPORT_FAILURE_ACTION,
  DOWNLOAD_AGENT_REPORT_SUCCESS_ACTION,
  DOWNLOAD_AGENT_REPORT_FAILURE_ACTION,
  DOWNLOAD_AGENT_STATUS_CHANGE_SUCCESS_ACTION,
  DOWNLOAD_AGENT_STATUS_CHANGE_FAILURE_ACTION,
  MAIL_REPORT_FAILURE_ACTION,
  MAIL_REPORT_SUCCESS_ACTION,
  FETCH_DEALERS_SUCCESS_ACTION,
  CLEAR_DEALERS_ACTION,
  FETCH_DEALERS_FAILURE_ACTION,
  FETCH_VEHICLES_SUCCESS_ACTION,
  FETCH_VEHICLES_FAILURE_ACTION,
  UPLOAD_PHOTO_SUCCESS,
  UPLOAD_PHOTO_FAILURE,
  FETCH_EXCESS_VEHICLES_SUCCESS_ACTION,
  FETCH_EXCESS_VEHICLES_FAILURE_ACTION,
  UPDATE_EXCESS_VEHICLES_FAILURE_ACTION,
  UPDATE_EXCESS_VEHICLES_SUCCESS_ACTION,
  FETCH_DAIMLER_TEMPLATES_SUCCESS_ACTION,
  FETCH_DAIMLER_TEMPLATES_FAILURE_ACTION,
  DOWNLOAD_DAIMLER_REPORT_SUCCESS_ACTION,
  DOWNLOAD_DAIMLER_REPORT_FAILURE_ACTION,
  FETCH_DAIMLER_DEALERS_SUCCESS_ACTION,
  FETCH_DAIMLER_DEALERS_FAILURE_ACTION,
  FETCH_DAIMLER_CLIENT_TEMPLATES_SUCCESS_ACTION,
  FETCH_DAIMLER_CLIENT_TEMPLATES_FAILURE_ACTION,
  FETCH_DAIMLER_CLIENT_DEALERS_SUCCESS_ACTION,
  FETCH_DAIMLER_CLIENT_DEALERS_FAILURE_ACTION,
  DOWNLOAD_DAIMLER_CLIENT_REPORT_SUCCESS_ACTION,
  DOWNLOAD_DAIMLER_CLIENT_REPORT_FAILURE_ACTION,
  FETCH_DAIMLER_CLIENT_VEHICLES_SUCCESS_ACTION,
  FETCH_DAIMLER_CLIENT_VEHICLES_FAILURE_ACTION,
  FETCH_DAIMLER_ADMIN_TEMPLATES_SUCCESS_ACTION,
  FETCH_DAIMLER_ADMIN_TEMPLATES_FAILURE_ACTION,
  CHANGE_DEALER_APPROVAL,
  ALL_READY_FETCHED_AUDITS_ACTION,
  ALL_READY_SEARCHED_AUDITS_ACTION,
  SEARCH_AUDITS_SUCCESS_ACTION,
  CLEAR_SEARCHED_AUDITS_ACTION,
  CLEAR_VEHICLES_ACTION,
  CLEAR_VOLKSWAGEN_DEALERS,
  CLEAR_DEALERS_VEHICLE,
  FETCH_VEHICLES_COUNTS_SUCCESS_ACTION,
  FETCH_VEHICLES_COUNTS_FAILURE_ACTION
} from "../actions/types";
import findIndex from "lodash/findIndex";


var fileDownload = require("js-file-download");

const INIT_STATE = {
  audits: {},
  users: [],
  isSearchResult: false,
  appliedFilters: {},
  searchResults: {
    audits: {}
  },
  pageLimit: 30,
  activePageKey: "",
  totalPages: null
};

 function Audit (state = INIT_STATE,action) {
  switch (action.type) {
    case FETCH_AUDITS_SUCCESS_ACTION:
      let objAssign = Object.assign({}, state, {
        users: action.payload.users,
        orgs: action.payload.orgs,
        audits: Object.assign({}, state.audits, {
          [action.navigation.activePageKey]: {
            data: action.payload.audits,
            pagination: action.payload.pagination
          }
        }),
        isSearchResult: false,
        fetchAuditsError: false,
        searchResults: {
          audits: {}
        },
        appliedFilters: {},
        activePageKey: action.navigation.activePageKey,
        totalPages: Math.round(
          action.payload.pagination.totalItems / state.pageLimit
        )
      });
      return objAssign;

    case SEARCH_AUDITS_SUCCESS_ACTION:
      return Object.assign({}, state, {
        users: action.payload.users,
        orgs: action.payload.orgs,
        isSearchResult: true,
        appliedFilters: action.appliedFilters
          ? action.appliedFilters
          : state.appliedFilters,
        searchResults: Object.assign({}, state.searchResults, {
          activePageKey: action.navigation.activePageKey,
          totalPages: Math.round(
            action.payload.pagination.totalItems / state.pageLimit
          ),
          audits: Object.assign({}, state.searchResults.audits, {
            [action.navigation.activePageKey]: {
              data: action.payload.audits,
              pagination: action.payload.pagination
            }
          })
        })
      });

    case ALL_READY_FETCHED_AUDITS_ACTION:
      return Object.assign({}, state, {
        isSearchResult: false,
        searchResults: {
          audits: {}
        },
        appliedFilters: {},
        activePageKey: action.navigation.activePageKey
      });

    case ALL_READY_SEARCHED_AUDITS_ACTION:
      return Object.assign({}, state, {
        isSearchResult: true,
        appliedFilters: action.appliedFilters
          ? action.appliedFilters
          : state.appliedFilters,
        searchResults: Object.assign({}, state.searchResults, {
          activePageKey: action.navigation.activePageKey
        })
      });

    case FETCH_AUDITS_FAILURE_ACTION:
    case FETCH_AGENT_AUDITS_FAILURE_ACTION:
      return Object.assign({}, state, {
        fetchAuditsError: true
      });

    case CLEAR_SEARCHED_AUDITS_ACTION:
      return Object.assign({}, state, {
        isSearchResult: false,
        appliedFilters: {},
        searchResults: {
          audits: {}
        }
      });

    case FETCH_AGENT_AUDITS_SUCCESS_ACTION:
      return Object.assign({}, state, {
        users: action.payload.users,
        orgs: action.payload.orgs,
        agentAudits: action.payload.agents,
        summary: action.payload.summary,
        fetchAuditsError: false
      });

    case FETCH_AUDIT_IMAGES_SUCCESS_ACTION:
      return Object.assign({}, state, {
        images: action.payload.questions,
        images_name: action.payload.name
      });

    case FETCH_AUDIT_IMAGES_FAILURE_ACTION:
      return Object.assign({}, state, {
        fetchImagesError: true
      });

    case UPDATE_AUDIT_SUCCESS_ACTION:
      var index = findIndex(state.dynamicAudits, {
        _id: action.payload.audit._id
      });

      state.dynamicAudits[index] = Object.assign(
        {},
        state.dynamicAudits[index],
        action.payload.audit
      );

      return Object.assign({}, state, {
        dynamicAudits: state.dynamicAudits,
        updatedAudit: action.payload.audit,
        updateAuditError: false
      });

    case UPDATE_AUDIT_FAILURE_ACTION:
      return Object.assign({}, state, {
        updatedAudit: null,
        updateAuditError: true
      });


    case FETCH_VEHICLES_COUNTS_SUCCESS_ACTION:
      return Object.assign({}, state, {
        counts: action.payload.counts,
        fetchVehiclesError: false
      });
      case FETCH_VEHICLES_COUNTS_FAILURE_ACTION:
        return Object.assign({}, state, {
          vehicles: [],
          dealerSummary:[],
          fetchVehiclesError: false,
          
        });




    case DOWNLOAD_REPORT_SUCCESS_ACTION:

      return Object.assign({}, state, {
      reportDownloadSuccess:true
      });

    case DOWNLOAD_REPORT_FAILURE_ACTION:
    case DOWNLOAD_AGENT_REPORT_FAILURE_ACTION:
      return Object.assign({}, state, {
        downloadSuccess: false
      });

    case DOWNLOAD_AGENT_REPORT_SUCCESS_ACTION:
      var buf = new Buffer(action.payload, "base64");
      var texts = buf.toString("utf-8");

      fileDownload(texts, "Agent Verification Report.xlsx");

      return Object.assign({}, state, {
        downloadSuccess: true
      });

    case MAIL_REPORT_SUCCESS_ACTION:
      return Object.assign({}, state, {
        mailSentStatus: action.payload.sent_success
      });

    case MAIL_REPORT_FAILURE_ACTION:
      return Object.assign({}, state, {
        mailSentStatus: false
      });

    case DOWNLOAD_AGENT_STATUS_CHANGE_SUCCESS_ACTION:
      return Object.assign({}, state, {
        statusChanged: action.payload.status
      });

    case DOWNLOAD_AGENT_STATUS_CHANGE_FAILURE_ACTION:
      return Object.assign({}, state, {
        statusChanged: false
      });

    case FETCH_DEALERS_SUCCESS_ACTION:
      return Object.assign({}, state, {
        users: action.payload.users,
        orgs: action.payload.orgs,
        dealers: action.payload.dealers,
        dealerVolkSummary: action.payload.vehicleVerificationSummary,
        fetchDealersError: false
      });
    case CLEAR_VOLKSWAGEN_DEALERS:
      return Object.assign({},state, {
        users: [],
        orgs: [],
        dealers:[],
        dealerVolkSummary: [],
        fetchDealersError: false
      })


    case FETCH_DEALERS_FAILURE_ACTION:
      return Object.assign({}, state, {
        fetchDealersError: true
      });

    case FETCH_VEHICLES_SUCCESS_ACTION:

    case FETCH_DAIMLER_CLIENT_VEHICLES_SUCCESS_ACTION:
      return Object.assign({}, state, {
        vehicles: action.payload.vehicles,
        // dealerVehicleSummary : action.payload.vehicleVerificationSummary ,
        dealerSummary: action.payload.vehicleVerificationSummary,
        dynamicAudits: action.payload.audits,
        fetchVehiclesError: false
      });
      case CLEAR_DEALERS_VEHICLE:
      case CLEAR_VEHICLES_ACTION:
        return Object.assign({}, state, {
          vehicles: [],
          dealerSummary:[],
          fetchVehiclesError: false,
          
        });
    case FETCH_VEHICLES_FAILURE_ACTION:
    case FETCH_DAIMLER_CLIENT_VEHICLES_FAILURE_ACTION:
      return Object.assign({}, state, {
        fetchVehiclesError: true
      });
 
    case UPLOAD_PHOTO_SUCCESS:
      return Object.assign({}, state, {
        savedPhotos: action.payload
      });

    case UPLOAD_PHOTO_FAILURE:
      return Object.assign({}, state, {});

    case FETCH_EXCESS_VEHICLES_SUCCESS_ACTION:
      return Object.assign({}, state, {
        excessVehicles: action.payload.excessVehicles,
        fetchExcessVehiclesError: false
      });

    case FETCH_EXCESS_VEHICLES_FAILURE_ACTION:
      return Object.assign({}, state, {
        fetchExcessVehiclesError: true
      });

    case UPDATE_EXCESS_VEHICLES_SUCCESS_ACTION:
      return Object.assign({}, state, {
        isExcessVehicleUpdated: true
      });

    case UPDATE_EXCESS_VEHICLES_FAILURE_ACTION:
      return Object.assign({}, state, {
        isExcessVehicleUpdated: false
      });

    case FETCH_DAIMLER_TEMPLATES_SUCCESS_ACTION:
    case FETCH_DAIMLER_CLIENT_TEMPLATES_SUCCESS_ACTION:
      state = Object.assign({}, state, {
        templates: action.payload.templates,
        fetchDaimlerTemplatesError: false
      });
      return state;

    case FETCH_DAIMLER_TEMPLATES_FAILURE_ACTION:
    case FETCH_DAIMLER_CLIENT_TEMPLATES_FAILURE_ACTION:
      state = {
        fetchDaimlerTemplatesError: true
      };
      return state;

    case FETCH_DAIMLER_DEALERS_SUCCESS_ACTION:
    case FETCH_DAIMLER_CLIENT_DEALERS_SUCCESS_ACTION:
      state = Object.assign({}, state, {
        dealers: action.payload.dealerList,
        users: action.payload.users,
        orgs: action.payload.orgs,
        // dealerSummary: action.payload.vehicleVerificationSummary,
        dealerVehicleSummary: action.payload.vehicleVerificationSummary,
        fetchDaimlerDealersError: false
      });
      return state;
    case CLEAR_DEALERS_ACTION:
      return Object.assign({}, state, {
        users: [],
        orgs: [],
        dealers: [],
        dealerVehicleSummary: [],
        fetchDaimlerTemplatesError: false,
        fetchDealersError: false
      });
    case FETCH_DAIMLER_DEALERS_FAILURE_ACTION:
    case FETCH_DAIMLER_CLIENT_DEALERS_FAILURE_ACTION:
      state = {
        fetchDaimlerDealersError: true
      };
      return state;

    case DOWNLOAD_DAIMLER_REPORT_SUCCESS_ACTION:
    case DOWNLOAD_DAIMLER_CLIENT_REPORT_SUCCESS_ACTION:
      return Object.assign({}, state, {
        downloadDaimlerReportSuccess: true
      });

    case DOWNLOAD_DAIMLER_REPORT_FAILURE_ACTION:
    case DOWNLOAD_DAIMLER_CLIENT_REPORT_FAILURE_ACTION:
      return Object.assign({}, state, {
        downloadDaimlerReportSuccess: false
      });
    case FETCH_DAIMLER_ADMIN_TEMPLATES_SUCCESS_ACTION:
      return Object.assign({}, state, {
        fetchDaimlerAdminTemplatesSuccess: true
      });

    case FETCH_DAIMLER_ADMIN_TEMPLATES_FAILURE_ACTION:
      return Object.assign({}, state, {
        fetchDaimlerAdminTemplatesSuccess: false
      });

    case CHANGE_DEALER_APPROVAL:
      let dealers = state.dealers;
      for (let i = 0; i < dealers.length; i++) {
        if (dealers[i]._id === action.payload) {
          dealers[i].showClient = true;
        }
      }
      return state;
    default:
      return state;  
  }
}

export default Audit