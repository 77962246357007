import axios from "axios";
import * as types from "./types";
import * as FileSaver from "file-saver";

export const fetchDealersAction = templateId => {
  let endPath = "/auditor/v1/dealers";
  if (templateId) {
    endPath = `/auditor/v1/dealers?templateId=${templateId}`;
  }
  return function(dispatch) {
    axios
      .get(types.API_URL + endPath)
      .then(function(response) {
        dispatch({
          type: types.FETCH_DEALERS_SUCCESS_ACTION,
          payload: response.data
        });
      })
      .catch(function(error) {
        dispatch({
          type: types.FETCH_DEALERS_FAILURE_ACTION,
          payload: error
        });
      });
  };
};

export const clearDealersAction = () => {
  return {
    type: types.CLEAR_DEALERS_ACTION
  };
};

export const fetchVehiclesAction = () => {
  return function(dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/vehicles")
      .then(function(response) {
        dispatch({
          type: types.FETCH_VEHICLES_SUCCESS_ACTION,
          payload: response.data
        });
      })
      .catch(function(error) {
        dispatch({
          type: types.FETCH_VEHICLES_FAILURE_ACTION,
          payload: error
        });
      });
  };
};

export const fetchDealerVehiclesAction = dealerId => {
  return function(dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/vehicles/web/" + dealerId)
      .then(function(response) {
        dispatch({
          type: types.FETCH_VEHICLES_SUCCESS_ACTION,
          payload: response.data
        });
      })
      .catch(function(error) {
        dispatch({
          type: types.FETCH_VEHICLES_FAILURE_ACTION,
          payload: error
        });
      });
  };
};
export const clearDealersVehiclesAction = () => {
  return {
    type: types.CLEAR_DEALERS_VEHICLE
  }
}

export const fetchDealerAuditReportAction = (dealerId,email) => {
  return function(dispatch) {
    axios
      .post(
        types.API_URL +
          `/client/v1/report/daimler/export?dealerId=${dealerId}&email=${email}`)
      .then(function(response) {
        dispatch({
          type: types.DOWNLOAD_DAIMLER_REPORT_SUCCESS_ACTION,
          payload: response.data
        });
      })
      .catch(function(error) {
        dispatch({
          type: types.DOWNLOAD_REPORT_FAILURE_ACTION,
          payload: error
        });
      });
  };
};


export const fetchVolkswagenAuditReportAction = dealerId => {
  return function(dispatch) {
      axios
          .get(types.API_URL + '/auditor/v1/report/vehicleAudit/export?dealerId=' + dealerId, {
              responseType: 'blob'
          })
          .then(function(response) {
              FileSaver.saveAs(response.data, 'Dealer-Audit-Report.xlsx');
              dispatch({
                  type: types.DOWNLOAD_REPORT_SUCCESS_ACTION,
                  payload: response.data
              });
          })
          .catch(function(error) {
              dispatch({
                  type: types.DOWNLOAD_REPORT_FAILURE_ACTION,
                  payload: error
              });
          });
  };
};



export const fetchVehiclesCountsAction = dealerId => {
  return function(dispatch) {
    axios
      .get(types.API_URL + `/auditor/v1/verificationCounts?dealerId=${dealerId}`)
      .then(function(response) {
        dispatch({
          type: types.FETCH_VEHICLES_COUNTS_SUCCESS_ACTION,
          payload: response.data
        });
      })
      .catch(function(error) {
        dispatch({
          type: types.FETCH_VEHICLES_COUNTS_FAILURE_ACTION,
          payload: error
        });
      });
  };
};


export const updateVehicleAuditAction = audit => {
  return function(dispatch) {
    axios
      .post(types.API_URL + "/auditor/v1/audit/vehicleVerification/submit", audit)
      .then(function(response) {
        dispatch({
          type: types.UPDATE_AUDIT_SUCCESS_ACTION,
          payload: response.data
        });
      })
      .catch(function(error) {
        dispatch({
          type: types.UPDATE_AUDIT_FAILURE_ACTION,
          payload: error
        });
      });
  };
};

export const updateDealerAction = (dealerId, dealerAssessment) => {
  return function(dispatch) {
    axios
      .post(types.API_URL + "/auditor/v1/dealers/" + dealerId, dealerAssessment)
      .then(function(response) {
        dispatch({
          type: types.UPDATE_AUDIT_SUCCESS_ACTION,
          payload: response.data
        });
      })
      .catch(function(error) {
        dispatch({
          type: types.UPDATE_AUDIT_FAILURE_ACTION,
          payload: error
        });
      });
  };
};

export const uploadDaimlerPhotoAction = (data, details) => {
  var xhr = new XMLHttpRequest();
  var all_pictures = [];
  var to_save = [];
  for (var k = 0; k < data.length; k++) {
    all_pictures.push(data[k].name);
  }
  details.files = all_pictures;
  return function(dispatch) {
    axios
      .post(types.API_URL + "/auditor/v1/storage/addMultipleImage", details)
      .then(function(response) {
        var FileData = response.data.allData;
        for (var i = 0; i < FileData.length; i++) {
          var each_file = FileData[i];
          for (var j = 0; j < data.length; j++) {
            if (each_file.oldName === data[j].name) {
              var blob = data[i].slice(0, -1, data[j].type);
              var newFile = new File([blob], each_file.objectId, {
                type: data[j].type
              });
              to_save.push({
                newName: each_file.objectId,
                oldName: each_file.oldName
              });
              xhr.onreadystatechange = function() {};
              xhr.open("PUT", each_file.url, true);
              xhr.setRequestHeader("Content-Type", data[j].type);
              
              xhr.onload = () => {
         
                if (xhr.status === 200) {
                  // success!
                  console.log("sucess");
                
                  to_save.push({ responseStatus: response.status });
                }
              };
              xhr.onerror = () => {
                // error...
                console.log("error");
              };
              xhr.send(newFile); // `file` is a File object here
            
            }
          }
        }

        dispatch({
          type: types.UPLOAD_PHOTO_SUCCESS,
          payload: to_save
        });
      })
      .catch(function(error) {
        dispatch({
          type: types.UPLOAD_PHOTO_FAILURE,
          payload: error
        });
      });
  };
};

export const fetchDaimlerTemplatesAction = (year) => {
  return function(dispatch) {
    axios
      .get(`${types.API_URL}/auditor/v1/templates?name=Daimler&year=${year}`)
      .then(function(response) {
        dispatch({
          type: types.FETCH_DAIMLER_TEMPLATES_SUCCESS_ACTION,
          payload: response.data
        });
      })
      .catch(function(error) {
        dispatch({
          type: types.FETCH_DAIMLER_TEMPLATES_FAILURE_ACTION,
          payload: error
        });
      });
  };
};

export const fetchDaimlerConsolidatedReportAction = (
  templateId,
  email,
  dealersId = null
) => {
  return function(dispatch) {
    let url =
      types.API_URL +
      "/auditor/v1/report/daimler/consolidated/" +
      templateId +
      "?email=" +
      email;
    if (dealersId) url = url + "&dealers=" + encodeURI(dealersId);
    axios
      .get(
        url,
        {
          //          responseType: "blob"
        }
      )
      .then(function(response) {
        dispatch({
          type: types.DOWNLOAD_DAIMLER_REPORT_SUCCESS_ACTION,
          payload: response.data
        });
      })
      .catch(function(error) {
        dispatch({
          type: types.DOWNLOAD_DAIMLER_REPORT_FAILURE_ACTION,
          payload: error
        });
      });
  };
};

export const updateExcessVehicleAction = data => {
  return function(dispatch) {
    axios
      .post(types.API_URL + "/auditor/v1/audit/updateExcessVehicle", data)
      .then(function(response) {
        dispatch({
          type: types.UPDATE_EXCESS_VEHICLES_SUCCESS_ACTION,
          payload: response.data
        });
      })
      .catch(function(error) {
        dispatch({
          type: types.UPDATE_EXCESS_VEHICLES_FAILURE_ACTION,
          payload: error
        });
      });
  };
};

export const fetchExcessVehiclesAction = (dealerId, templateId) => {
  return function(dispatch) {
    axios
      .get(
        types.API_URL +
          "/auditor/v1/audit/getExcessVehicles?dealerId=" +
          dealerId +
          "&templateId=" +
          templateId
      )
      .then(function(response) {
        dispatch({
          type: types.FETCH_EXCESS_VEHICLES_SUCCESS_ACTION,
          payload: response.data
        });
      })
      .catch(function(error) {
        dispatch({
          type: types.FETCH_EXCESS_VEHICLES_FAILURE_ACTION,
          payload: error
        });
      });
  };
};

export const fetchDaimlerDealersActions = templateId => {
  return function(dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/dealers?templateId=" + templateId)
      .then(function(response) {
        dispatch({
          type: types.FETCH_DAIMLER_DEALERS_SUCCESS_ACTION,
          payload: response.data
        });
      })
      .catch(function(error) {
        dispatch({
          type: types.FETCH_DAIMLER_DEALERS_FAILURE_ACTION,
          payload: error
        });
      });
  };
};

export const fetchShowDealerToClientAction = dealer_id => {
  return function(dispatch) {
    axios
      .get(types.API_URL + "/admin/v1/showClient/" + dealer_id)
      .then(function(response) {
        dispatch({
          type: types.CHANGE_DEALER_APPROVAL,
          payload: dealer_id
        });

        dispatch({
          type: types.FETCH_DAIMLER_ADMIN_TEMPLATES_SUCCESS_ACTION,
          payload: response.data
        });
      })
      .catch(function(error) {
        
        dispatch({
          type: types.FETCH_DAIMLER_ADMIN_TEMPLATES_FAILURE_ACTION,
          payload: error
        });
      });
  };
};

export const fetchDaimlerClientTemplatesAction = (year) => {
  return function(dispatch) {
    axios
      .get( `${types.API_URL}/client/v1/daimler/templates?year=${year}`)
      .then(function(response) {
        dispatch({
          type: types.FETCH_DAIMLER_CLIENT_TEMPLATES_SUCCESS_ACTION,
          payload: response.data
        });
      })
      .catch(function(error) {
        dispatch({
          type: types.FETCH_DAIMLER_CLIENT_TEMPLATES_FAILURE_ACTION,
          payload: error
        });
      });
  };
};

export const fetchDaimlerClientDealersAction = templateId => {
  return function(dispatch) {
    axios
      .get(types.API_URL + "/client/v1/dealers/" + templateId)
      .then(function(response) {
        dispatch({
          type: types.FETCH_DAIMLER_CLIENT_DEALERS_SUCCESS_ACTION,
          payload: response.data
        });
      })
      .catch(function(error) {
        dispatch({
          type: types.FETCH_DAIMLER_CLIENT_DEALERS_FAILURE_ACTION,
          payload: error
        });
      });
  };
};


export const fetchDealerClientVehiclesAction = dealerId => {
  return function(dispatch) {
    axios
      .get(types.API_URL + "/client/v1/vehicles/" + dealerId)
      .then(function(response) {
        dispatch({
          type: types.FETCH_DAIMLER_CLIENT_VEHICLES_SUCCESS_ACTION,
          payload: response.data
        });
      })
      .catch(function(error) {
        dispatch({
          type: types.FETCH_DAIMLER_CLIENT_VEHICLES_FAILURE_ACTION,
          payload: error
        });
      });
  };
};


export const fetchVolkswagenClientAuditReportAction = (dealerId) => {
  return function(dispatch) {
      axios
          .get(types.API_URL + '/client/v1/report/volkswagen/export?dealerId=' + dealerId, {
              responseType: 'blob'
          })
          .then(function(response) {
              FileSaver.saveAs(response.data, 'Dealer-Client-Audit-Report.xlsx');
              dispatch({
                  type: types.DOWNLOAD_DAIMLER_CLIENT_REPORT_SUCCESS_ACTION,
                  payload: response.data
              });
          })
          .catch(function(error) {
              dispatch({
                  type: types.DOWNLOAD_DAIMLER_CLIENT_REPORT_FAILURE_ACTION,
                  payload: error
              });
          });
  };
};


export const clearVolkswagenDealersAction = () => {
  return function(dispatch){
    dispatch({
      type: types.CLEAR_VOLKSWAGEN_DEALERS
    })
  }
}
export const clearVehiclesAction = () => {
  return function(dispatch) {
    dispatch({
      type: types.CLEAR_VEHICLES_ACTION,
     
    })
    
  };
};


export const fetchTemplatesAction = (orgName,year) => {
  return function(dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/volkswagen/templates?name=" + orgName + "&year=" + year)
      .then(function(response) {
        dispatch({
          type: types.FETCH_DAIMLER_TEMPLATES_SUCCESS_ACTION,
          payload: response.data
        });
      })
      .catch(function(error) {
        dispatch({
          type: types.FETCH_DAIMLER_TEMPLATES_FAILURE_ACTION,
          payload: error
        });
      });
  };
};

export const fetchVolkswagenClientTemplatesAction = () => {
  return function(dispatch) {
    axios
      .get(types.API_URL + "/client/v1/volkswagen/templates")
      .then(function(response) {
        dispatch({
          type: types.FETCH_DAIMLER_CLIENT_TEMPLATES_SUCCESS_ACTION,
          payload: response.data
        });
      })
      .catch(function(error) {
        dispatch({
          type: types.FETCH_DAIMLER_CLIENT_TEMPLATES_FAILURE_ACTION,
          payload: error
        });
      });
  };
};




export const uploadDaimlerSignOffAction = (file,id,{onSuccess,onFailure}) => {

  var formData = new FormData();
  formData.append('document', file[0], file[0].name);
  const config = {
      headers: {
          'content-type': 'multipart/form-data'
      }
  }
  return function (dispatch) {
      axios.post(types.API_URL + `/auditor/v1/storage/uploadDocument?dealerId=${id}`, formData, config)
          .then(function (response) {
              dispatch({
                  type: types.UPLOAD_PHOTO_SUCCESS,
                  payload: response.data
              });
              onSuccess(response.data);
          })
          .catch(function (error) {
              dispatch({
                  type: types.UPLOAD_PHOTO_FAILURE,
                  payload: error
              });
              onFailure();
          });
  };
}



export const sendSignOffCopyUrl = (dealerId,data,{onSuccess,onFailure}) => {
  return function(dispatch) {
    axios
      .post(types.API_URL + `/auditor/v1/daimler/dealerSignOff?dealerId=${dealerId}&data=${data}`)
      .then(function(response) {
        dispatch({
          type: types.FETCH_DAIMLER_SIGNOFFURL_SUCCESS_ACTION,
          payload: response.data
        });
        onSuccess();
      })
      .catch(function(error) {
        dispatch({
          type: types.FETCH_DAIMLER_SIGNOFFURL_FAILURE_ACTION,
          payload: error
        });
        onFailure();
      });
  };
};