import axios from "axios";
import * as FileSaver from "file-saver";
import * as types from "./types";

export const getJandJTemplates = ({ onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/JandJ/templates")
      .then((response) => {
        dispatch({
          type: types.jandj_get_templates_success,
          payload: response.data,
        });
        onSuccess();
      })
      .catch((error) => {
        dispatch({
          type: types.jandj_get_templates_failure,
          payload: error,
        });
        onFailure();
      });
  };
};

export const getJandJDealers = ({ templateId, onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + `/auditor/v1/JandJ/dealers?templateId=${templateId}`)
      .then((response) => {
        dispatch({
          type: types.jandj_get_dealers_success,
          payload: response.data,
        });
        onSuccess();
      })
      .catch((error) => {
        dispatch({
          type: types.jandj_get_dealers_failure,
          payload: error,
        });
        onFailure();
      });
  };
};

export const getJandJAudit = (dealerId, { onSuccess, onFailure }) => {
  console.log(dealerId, "id");
  return function (dispatch) {
    axios
      .get(types.API_URL + `/auditor/v1/JandJ/audits?dealerId=${dealerId}`)
      .then((response) => {
        dispatch({
          type: types.jandj_get_audit_success,
          payload: response.data,
        });
        onSuccess();
      })
      .catch((error) => {
        dispatch({
          type: types.jandj_get_audit_failure,
          payload: error,
        });
        onFailure(error);
      });
  };
};

export const saveAudit = (data) => {
  return function (dispatch) {
    axios
      .post(types.API_URL + "/auditor/v1/JandJ/saveAudits", data)
      .then(function (response) {
        dispatch({
          type: types.jandj_save_audit_success,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.jandj_save_audit_failure,
          payload: error,
        });
      });
  };
};

export const getJandJAuditReport = (auditId) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + `/auditor/v1/JandJ/report?auditId=${auditId}`, {
        responseType: "blob",
      })
      .then((response) => {
        FileSaver.saveAs(response.data, "johnsonAndJohnson.zip");
        dispatch({
          type: types.jandj_get_audit_report_success,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.jandj_get_audit_report_failure,
          payload: error,
        });
      });
  };
};

export const mailJandJAuditReport = (auditId, email) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL +
          `/auditor/v1/JandJ/mailReport?auditId=${auditId}&email=${email}`
      )
      .then((response) => {
        dispatch({
          type: types.jandj_download_audit_report_success,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.jandj_download_audit_report_failure,
          payload: error,
        });
      });
  };
};
