import axios from "axios";
import FileSaver from "file-saver";
import * as types from "./types";

export const fetchDamagesTemplatesAction = ({ year, onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + `/auditor/v1/damages/getTemplates?year=${year}`)
      .then(function (response) {
        console.log(response, "data");
        dispatch({
          type: types.FETCH_DAMAGES_TEMPLATE_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_DAMAGES_TEMPLATE_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const fetchDamagesTemplateAuditsAction = ({
  templateId,
  onSuccess,
  onFailure,
}) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/damages/getAudits/" + templateId)
      .then(function (response) {
        dispatch({
          type: types.FETCH_DAMAGES_AUDITS_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_DAMAGES_AUDITS_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const saveDamagesAuditAction = (audit) => {
  return function (dispatch) {
    axios
      .post(types.API_URL + "/auditor/v1/damages/submit", audit)
      .then(function (response) {
        dispatch({
          type: types.SAVE_DAMAGES_AUDIT_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.SAVE_DAMAGES_AUDIT_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const downloadDamagesReportAction = (templateId) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL +
          "/auditor/v1/damages/report/export?templateId=" +
          templateId,
        {
          responseType: "blob",
        }
      )
      .then(function (response) {
        FileSaver.saveAs(response.data, "Audit-Report.xlsx");
        dispatch({
          type: types.DOWNLOAD_REPORT_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        console.log(error);
        dispatch({
          type: types.DOWNLOAD_REPORT_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};
export const downloadDamagesAuditReportAction = (auditId, templateId) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL +
          "/auditor/v1/damages/report/export/" +
          auditId +
          "?templateId=" +
          templateId,
        {
          responseType: "blob",
        }
      )
      .then(function (response) {
        var blob = new Blob([response.data], { type: "application/pdf" });
        FileSaver.saveAs(blob, "Audit-Report.pdf");
        dispatch({
          type: types.DOWNLOAD_DAMAGES_AUDIT_REPORT_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        console.log(error);
        dispatch({
          type: types.DOWNLOAD_DAMAGES_AUDIT_REPORT_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const fetchExcessMaster = () => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/damages/getExcessTracker")
      .then(function (response) {
        dispatch({
          type: types.FETCH_DAMAGES_TRACKER_MASTER_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_DAMAGES_TRACKER_MASTER_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const saveTrackerAuditAction = (audits) => {
  return function (dispatch) {
    axios
      .post(types.API_URL + "/auditor/v1/damages/saveTracker", audits)
      .then(function (response) {
        dispatch({
          type: types.SAVE_TRACKER_AUDIT_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.SAVE_TRACKER_AUDIT_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const downloadTrackerAuditsReportAction = (masterId) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL +
          "/auditor/v1/damages/trackerReport?masterId=" +
          masterId,
        {
          responseType: "blob",
        }
      )
      .then(function (response) {
        var blob = new Blob([response.data], { type: "application/pdf" });

        FileSaver.saveAs(blob, "Audit-Report.xlsx");
        dispatch({
          type: types.DOWNLOAD_DAMAGES_TRACKER_REPORT_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        console.log(error);
        dispatch({
          type: types.DOWNLOAD_DAMAGES_TRACKER_REPORT_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const uploadDamagesPvSheetAction = (
  data,
  masterId,
  { onSuccess, onFailure }
) => {
  var xhr = new XMLHttpRequest();
  var all_pictures = [];
  var to_save = [];
  for (var k = 0; k < data.length; k++) {
    all_pictures.push(data[k].name);
  }
  return function (dispatch) {
    axios
      .post(types.API_URL + "/auditor/v1/damages/uploadPvSheet", {
        photos: all_pictures,
        masterId: masterId,
      })
      .then(function (response) {
        var FileData = response.data.data;
        for (var i = 0; i < FileData.length; i++) {
          var each_file = FileData[i];
          for (var j = 0; j < data.length; j++) {
            if (each_file.oldName === data[j].name) {
              var blob = data[i].slice(0, -1, data[j].type);
              var newFile = new File([blob], each_file.objectId, {
                type: data[j].type,
              });
              to_save.push({
                newname: each_file.objectId,
                oldName: each_file.oldName,
              });

              xhr.onreadystatechange = function () {};
              xhr.open("PUT", each_file.url, true);
              xhr.setRequestHeader("Content-Type", data[j].type);

              xhr.onload = () => {
                if (xhr.status === 200) {
                  // success!
                  console.log("sucess");

                  to_save.push({ responseStatus: response.status });
                }
              };
              xhr.onerror = () => {
                // error...
                console.log("error");
              };

              xhr.send(newFile); // `file` is a File object here
            }
          }
        }

        dispatch({
          type: types.UPLOAD_PVSHEET_SUCCESS,
          payload: to_save,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.UPLOAD_PVSHEET_FAILURE,
          payload: error,
        });
        onFailure();
      });
  };
};
