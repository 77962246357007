import React from "react";
import {
  Input,
  Divider,
  Modal,
  Select,
  Radio,
  Image,
} from "semantic-ui-react";
import config from "../../config.js";
import moment from "moment";
import {
  getTextColor,
  getMainBackground,
  getFontColor,
} from "../../ThemeHandlers/theme";
import ConstantLabel from "../commonViews/ConstantLabel.jsx";

const storageBaseUrl = config["storage_base_url"];
class QuestionsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTc: null,
      showPopup: false,
      editMode: false,
      selectedPhoto: "",
      showPhotoSlider: false,
      currentAudit: JSON.parse(JSON.stringify(this.props.selectedAudit)),
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedAudit._id != prevProps.selectedAudit._id) {
      this.setState({
        currentAudit: JSON.parse(JSON.stringify(this.props.selectedAudit)),
        editMode: false,
      });
    }
  }

  componentWillMount() { }

  componentDidMount() {
    this.setState({
      currentAudit: JSON.parse(JSON.stringify(this.props.selectedAudit)),
    });
  }

  handlePhotoClick = (e, p) => {
    this.setState({
      selectedPhoto: p,
      showPhotoSlider: true,
    });
  };

  photoSliderClose = (e) => {
    this.setState({
      showPhotoSlider: false,
    });
  };

  changeAnswer = (question, answerObject) => {
    var answer = "";
    let audit = this.state.currentAudit;
    if (question.answerType == "text") {
      answer = answerObject.target.value;
    }

    if (question.answerType == "number") {
      answer = answerObject.target.value;
    }

    if (question.answerType == "yes/no") {
      answer = answerObject;
    }

    if (question.answerType == "dropdown") {
      answer = answerObject;
    }

    audit.questions.map((eachQuestion) => {
      if (eachQuestion._id == question._id) {
        eachQuestion.answer = answer;
      }
    });

    this.setState({ currentAudit: audit });
  };

  editAudit = () => {
    this.setState({ editMode: true });
  };

  cancelEditAudit = () => {
    this.setState({
      editMode: false,
      currentAudit: JSON.parse(JSON.stringify(this.props.selectedAudit)),
    });
  };

  saveAudit = () => {
    this.props.onSaveAuditClick(this.state.currentAudit);
    this.setState({ editMode: false });
  };

  onDownloadPhotos = (id) => {
    let open = `#/images/${id}?tag=godrej`;
    window.open(open);
  };

  onDownloadReport = (id) => {
    this.props.onDownloadAuditReportClick(id);
  };

  render() {
    var selectedAudit = this.state.currentAudit;
    return (
      <div>
        {selectedAudit && (
          <div>
            <HeaderView
              selectedAudit={selectedAudit}
              editMode={this.state.editMode}
              onDownloadReport={this.onDownloadReport}
              onDownloadPhotos={this.onDownloadPhotos}
              editAudit={this.editAudit}
              saveAudit={this.saveAudit}
              cancelEditAudit={this.cancelEditAudit}
              color={this.props.color}
            />
            <Modal
              dimmer="blurring"
              size="fullscreen"
              closeOnEscape={true}
              closeOnDimmerClick={true}
              basic
              open={this.state.showPhotoSlider}
              onClose={this.photoSliderClose}
            >
              <Modal.Content>
                <Image
                  fuild
                  centered
                  src={
                    storageBaseUrl + this.state.selectedPhoto.uploadedImageUrl
                  }
                />
              </Modal.Content>
            </Modal>
            {selectedAudit.questions != null &&
              selectedAudit.questions
                .filter((eachQuestion) => {
                  if (eachQuestion.hasOwnProperty("dependency")) {
                    let question = null;
                    let questionId = eachQuestion.dependency.questionId;
                    let answerNeeded = eachQuestion.dependency.answer;
                    selectedAudit.questions.map((q) => {
                      if (q._id == questionId) {
                        question = q;
                      }
                    });
                    if (question.answer != answerNeeded) {
                      return false;
                    }
                    return true;
                  } else {
                    return true;
                  }
                })
                .map((eachQuestion, index) => {
                  return (
                    <div key={index}>
                      <div
                        key={eachQuestion.id}
                        style={{ paddingTop: "30px", paddingBottom: "20px" }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                            marginLeft: "20px",
                            fontSize: "18px",
                            paddingBottom: "20px",
                          }}
                        >
                          {eachQuestion.question}
                        </div>
                        {eachQuestion.answerType == "text" && (
                          <Input
                            style={{ marginLeft: "40px" }}
                            disabled={!this.state.editMode}
                            value={eachQuestion.answer}
                            onChange={(e) => this.changeAnswer(eachQuestion, e)}
                          />
                        )}
                        {eachQuestion.answerType == "number" && (
                          <Input
                            style={{ marginLeft: "40px" }}
                            disabled={!this.state.editMode}
                            value={eachQuestion.answer}
                            type="number"
                            onChange={(e) => this.changeAnswer(eachQuestion, e)}
                          />
                        )}

                        {eachQuestion.answerType == "yes/no" && (
                          <div>
                            <Radio
                              style={{
                                marginRight: "20px",
                                fontWeight: "bold",
                              }}
                              label={"Yes"}
                              value={"Yes"}
                              checked={eachQuestion.answer === "Yes"}
                              disabled={!this.state.editMode}
                              onChange={(e, { value }) =>
                                this.changeAnswer(eachQuestion, value)
                              }
                            />
                            <Radio
                              style={{ marginLeft: "20px", fontWeight: "bold" }}
                              label={"No"}
                              value={"No"}
                              checked={eachQuestion.answer === "No"}
                              disabled={!this.state.editMode}
                              onChange={(e, { value }) =>
                                this.changeAnswer(eachQuestion, value)
                              }
                            />
                          </div>
                        )}
                        {eachQuestion.answerType == "dropdown" && (
                          <div>
                            <Select
                              disabled={!this.state.editMode}
                              options={eachQuestion.options.map(
                                (eachOption, i) => {
                                  return {
                                    value: eachOption.value,
                                    text: eachOption.label,
                                    key: i,
                                  };
                                }
                              )}
                              placeholder={"Select any option"}
                              value={Number(eachQuestion.answer)}
                              onChange={(e, { value }) =>
                                this.changeAnswer(eachQuestion, value)
                              }
                            />
                          </div>
                        )}
                        {eachQuestion.answerType == "photo" && (
                          <Image.Group
                            size="mini"
                            style={{ cursor: "pointer" }}
                          >
                            {eachQuestion.photos.map((p, i) => {
                              if (p.uploadedImageUrl != null) {
                                return (
                                  <Image
                                    key={i}
                                    src={storageBaseUrl + p.uploadedImageUrl}
                                    onClick={((k) => {
                                      return (e) => this.handlePhotoClick(e, k);
                                    })(p)}
                                  />
                                );
                              }
                            })}
                          </Image.Group>
                        )}
                        {eachQuestion.answeredTime && (
                          <div
                            style={{ marginTop: "10px", marginLeft: "16px" }}
                          >
                            On -{" "}
                            <span
                              style={{
                                color: getTextColor(this.props.color),
                                fontWeight: "bold",
                              }}
                            >
                              {" "}
                              {moment(eachQuestion.answeredTime).format("llll")}
                            </span>
                          </div>
                        )}
                      </div>
                      <Divider />
                    </div>
                  );
                })}
          </div>
        )}
      </div>
    );
  }
}

const HeaderView = function (props) {
  let {
    selectedAudit,
    editMode,
    onDownloadPhotos,
    onDownloadReport,
    editAudit,
    saveAudit,
    cancelEditAudit,
  } = props;
  return (
    <div>
      <h1 style={{ color: getTextColor(props.color) }}>
        {selectedAudit.distributorName}
      </h1>
      <div
        style={{
          color: "#494949",
          fontsize: "15px",
          fontWeight: "100",
          paddingBottom: "10px",
          marginLeft: "10px",
          marginTop: "-15px",
        }}
      >
        {selectedAudit.location}
      </div>
      <ConstantLabel
        title={"Show Photos"}
        size={"medium"}
        onClick={() => {
          onDownloadPhotos(selectedAudit._id);
        }} styles={{
          cursor: "pointer",
          backgroundColor: getMainBackground(props.color),
          color: getFontColor(props.color),
        }}
        iconName={"picture"}
      />


      {!editMode && (
        <ConstantLabel
          title={"Edit audit"}
          size={"medium"}
          onClick={editAudit}
          styles={{
            cursor: "pointer",
            backgroundColor: getMainBackground(props.color),
            color: getFontColor(props.color),
          }}
          iconName={"edit"}
        />

      )}

      {editMode && (
        <div style={{ marginTop: "5px" }}>
          <ConstantLabel
            title={"Save audit"}
            size={"medium"}
            onClick={saveAudit}
            styles={{
              cursor: "pointer",
              marginRight: "5px",
              backgroundColor: getMainBackground(props.color),
              color: getFontColor(props.color),
            }}
            iconName={"save"}
          />
          <ConstantLabel
            title={"Cancel"}
            size={"medium"}
            onClick={cancelEditAudit}
            styles={{
              cursor: "pointer", marginLeft: "5px"
            }}
            iconName={"cancel"}
          />


        </div>
      )}
      <ConstantLabel
        title={"Download Report"}
        size={"medium"}
        onClick={() => {
          onDownloadReport(selectedAudit.templateId);
        }} styles={{
          cursor: "pointer", display: "none"
        }}
        iconName={"table"}
      />


      <div style={{ color: getFontColor(props.color) }}>
        By {selectedAudit.auditor != null && selectedAudit.auditor.displayName}{" "}
        on {moment(selectedAudit.createdAt).format("DD-MM-YYYY  HH:MM:SS")}
      </div>
    </div>
  );
};

export default QuestionsView;
