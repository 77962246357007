import {
  FETCH_SALES_TEMPLATE_SUCCESS_ACTION,
  FETCH_SALES_TEMPLATE_FAILURE_ACTION,
  FETCH_STORE_SUCCESS_ACTION,
  FETCH_STORE_FAILURE_ACTION,
  FETCH_PRODUCT_SUCCESS_ACTION,
  FETCH_PRODUCT_FAILURE_ACTION,
  DOWNLOAD_SALES_REPORT_SUCCESS_ACTION,
  DOWNLOAD_SALES_REPORT_FAILURE_ACTION,
  FETCH_SALES_AUDITS_SUCCESS_ACTION,
  FETCH_SALES_AUDITS_FAILURE_ACTION,
  SAVE_SALES_AUDITS_SUCCESS_ACTION,
  SAVE_SALES_AUDITS_FAILURE_ACTION,
  FETCH_SALES_AUDIT_IMAGES_SUCCESS_ACTION,
  FETCH_SALES_AUDIT_IMAGES_FAILURE_ACTION,
  GET_ALL_PHOTOS_SUCCESS,
  save_photo_success,
  get_store_photo_success,
  get_audit_dates_success,
  get_leaflet_store_success,
  get_leaflet_store_failure,
  CLEAR_STORE_ACTION,
  GET_ALL_CATEGORYPHOTOS_SUCCESS,
  GET_ALL_CATEGORYPHOTOS_FAILURE,
  GET_ALL_DISPLAYPHOTOS_SUCCESS,
  GET_ALL_DISPLAYPHOTOS_FAILURE,
  GET_ALL_MANUALPHOTOS_SUCCESS,
  GET_ALL_MANUALPHOTOS_FAILURE,
  get_audited_store_success,
  get_audited_store_failure,
  get_photo_success,
  get_photo_failure
} from "../actions/types";

const INIT_STATE = {};

export default function (state = INIT_STATE,action) {
  state = Object.assign({}, state, {});

  switch (action.type) {
    case FETCH_SALES_TEMPLATE_SUCCESS_ACTION:
      state.templates = action.payload.templates;
      state.fetchTemplateError = false;
      return state;

    case FETCH_SALES_TEMPLATE_FAILURE_ACTION:
      state.fetchTemplateError = true;
      return state;
    case FETCH_STORE_SUCCESS_ACTION:
      state.storeList = action.payload.stores;
      state.fetchStoreError = false;
      return state;

    case FETCH_STORE_FAILURE_ACTION:
      state.fetchStoreError = true;
      return state;
    case FETCH_PRODUCT_SUCCESS_ACTION:
      state.productList = action.payload.master_data;
      state.fetchProductError = false;
      return state;

    case FETCH_PRODUCT_FAILURE_ACTION:
      state.fetchProductError = true;
      return state;

    case DOWNLOAD_SALES_REPORT_SUCCESS_ACTION:
      var buff = new Buffer(action.payload, "base64");
      var text = buff.toString("utf-8");

      // fileDownload(text, "salesAuditReport.xlsx");

      return Object.assign({}, state, {
        downloadSuccess: true
      });

    case DOWNLOAD_SALES_REPORT_FAILURE_ACTION:
      return Object.assign({}, state, {
        downloadSuccess: false
      });

    case FETCH_SALES_AUDITS_SUCCESS_ACTION:
      state.audits = action.payload.audits;
      state.fetchAuditsError = false;
      return state;

    case FETCH_SALES_AUDITS_FAILURE_ACTION:
      state.fetchAuditsError = true;
      return state;

    case SAVE_SALES_AUDITS_SUCCESS_ACTION:
      return Object.assign({}, state, {
        saveSalesAuditSuccessful: true
      });

    case SAVE_SALES_AUDITS_FAILURE_ACTION:
      return Object.assign({}, state, {
        saveSalesAuditSuccessful: false
      });

    case FETCH_SALES_AUDIT_IMAGES_SUCCESS_ACTION:
      return Object.assign({}, state, {
        images: action.payload.photos
      });

    case FETCH_SALES_AUDIT_IMAGES_FAILURE_ACTION:
      return Object.assign({}, state, {
        fetchSalesImagesError: true
      });

    case GET_ALL_PHOTOS_SUCCESS:
      state = Object.assign({}, state, {
        allPhotos: action.payload.photos
      });
      return state;

    case GET_ALL_CATEGORYPHOTOS_SUCCESS:
    case GET_ALL_CATEGORYPHOTOS_FAILURE:
      state = Object.assign({}, state, {
        categoryPhotos: action.payload.photos
      });
      return state;

    case GET_ALL_DISPLAYPHOTOS_SUCCESS:
    case GET_ALL_DISPLAYPHOTOS_FAILURE:
      state = Object.assign({}, state, {
        displayPhotos: action.payload.photos
      });
      return state;

    case GET_ALL_MANUALPHOTOS_SUCCESS:
    case GET_ALL_MANUALPHOTOS_FAILURE:
      state = Object.assign({}, state, {
        manualPhotos: action.payload.photos
      });
      return state;

    case save_photo_success:
      return Object.assign({}, state, {
        statusOFPhoto: true,
        publicPhotoUrl: action.payload.publicPhotoUrl,
        photoObjectId: action.payload.objectId
      });

    case get_store_photo_success:
      return Object.assign({}, state, {
        storePhoto: action.payload.photos
      });

    case get_audit_dates_success:
      return Object.assign({}, state, {
        auditDates: action.payload.dates
      });



    case get_leaflet_store_success:
      state.leafletStores = action.payload.storelist;
      state.fetchLeafletStores = false;
      return state;


    case get_leaflet_store_failure:
      state.fetchLeafletStores = true;
      return state;

    case get_audited_store_success:
      state.auditedStores = action.payload.storelist;
      state.fetchAuditedStores = false;
      return state;


    case get_audited_store_failure:
      state.fetchAuditedStores = true;
      return state;


    case CLEAR_STORE_ACTION:
      delete state.leafletStores;
      return state;


    case get_photo_success:
      return Object.assign({}, state, {
        getPhotoSuccess: true,
    });

    case get_photo_failure:
      return Object.assign({}, state, {
        getPhotoFailure: false
      });
  }
  return state;
}
