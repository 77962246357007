import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "react-datepicker/dist/react-datepicker.css";
import IconPicker from "../commonViews/IconPicker.jsx";
import "react-notifications/lib/notifications.css";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import TableView from "../commonViews/TableView.jsx";
import {
    getTextColor,
    getMainBackground,
    getFontColor,
} from "../../ThemeHandlers/theme";
import { fetchOlaMasterDataAction } from '../../actions/ola_actions'
import Metrics from "../commonViews/Metrics.jsx";
import OlaAudits from "./OlaAudits.jsx";

export class OlaVehicles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            CustomerTableView: true,
            selectedCustomer: {},
            auditid: "",
            selection: [],
            isLoading: false,
            vehiclesList: [],
            counts: {}
        };
    }
    componentDidMount() {
        console.log(this.props.selectedTemplate._id, 'template')
        this.setState({ isLoading: true });
        let templateId = this.props.selectedTemplate._id;
        this.props.fetchOlaMasterDataAction({
            templateId,
            onSuccess: (res) => {
                this.setState({ isLoading: false, vehiclesList: res.vehicles, counts: res.counts });
            },
            onFailure: () => {
                this.setState({ isLoading: false });
            },
        });
    }
    auditIdFromOlaAudit = (auditId) => {
        this.setState({
            auditid: auditId,
        });
    };

    olahandleTableViewAuditClick = (customer) => {
        this.setState({ selectedCustomer: customer, CustomerTableView: false });
    };

    onGotoCustomerView = () => {
        this.setState({
            CustomerTableView: true,
        });
    };
    render() {

        const olaCol = [
            {
                title: "Vehicle Registration Number",
                field: "code",
                render: (rowData) => (
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            this.olahandleTableViewAuditClick(rowData);
                        }}
                    >
                        {rowData.vehicleNo}
                    </span>
                ),
                customFilterAndSearch: (term, rowData) =>
                    rowData.vehicleNo.toLowerCase().indexOf(term.toLowerCase()) > -1,
            },
            {
                title: "City",
                field: "",
                render: (rowData) => (
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            this.olahandleTableViewAuditClick(rowData);
                        }}
                    >
                        {rowData.city}
                    </span>
                ),
                customFilterAndSearch: (term, rowData) =>
                    rowData.city.toLowerCase().indexOf(term.toLowerCase()) > -1,
            },
            {
                title: "Parking Location",
                field: "",
                render: (rowData) => (
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            this.olahandleTableViewAuditClick(rowData);
                        }}
                    >
                        {rowData.parkingLocation}
                    </span>
                ),
                customFilterAndSearch: (term, rowData) =>
                    rowData.parkingLocation.toLowerCase().indexOf(term.toLowerCase()) >
                    -1,
            },
            {
                title: "Vehicle Verification Status",
                field: "",
                render: (rowData) => (
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            this.olahandleTableViewAuditClick(rowData);
                        }}
                    >
                        {rowData.vehicleVerification === true ? <IconPicker
                            name="check"
                            styles={{
                                color: getTextColor(this.props.color),
                                marginLeft: 75
                            }}
                        /> : <IconPicker
                            name="hourglass half"
                            styles={{
                                color: getTextColor(this.props.color),
                                marginLeft: 75
                            }}
                        />}
                    </span>
                ),
            },
            {
                title: "Document Verification Status",
                field: "",
                render: (rowData) => (
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            this.olahandleTableViewAuditClick(rowData);
                        }}
                    >
                        {rowData.documentVerification === true ? <IconPicker
                            name="check"
                            styles={{
                                color: getTextColor(this.props.color),
                                marginLeft: 75
                            }}
                        /> : <IconPicker
                            name="hourglass half"
                            styles={{
                                color: getTextColor(this.props.color),
                                marginLeft: 75
                            }}
                        />}
                    </span>
                ),
            },
        ];

        return (
            <div>
                <NotificationContainer />
                {this.state.CustomerTableView && (
                    <div>
                        <div style={{ display: "inline-block", marginBottom: 20 }}>
                            <IconPicker
                                name="arrow left"
                                click={this.props.onClose}
                                styles={{
                                    color: getTextColor(this.props.color),
                                    float: "right",
                                    cursor: "pointer",
                                    marginTop: 35,
                                    position: "absolute",
                                    right: 20,
                                }}
                            />
                            <h1
                                style={{
                                    display: "inline-block",
                                    color: getTextColor(this.props.color),
                                }}
                            >
                                OLA Vehicles List
                            </h1>

                        </div>
                        <div style={{ marginTop: 10 }}>
                            {this.state.counts !== undefined &&
                                Object.keys(this.state.counts).length !== 0 &&
                                this.state.counts && (<Metrics metrics={this.state.counts} color={this.props.color} />

                                )}
                        </div>
                    </div>
                )}
                {this.state.CustomerTableView && (
                    <div
                        style={{
                            display: "flex",
                            flexGrow: 1,
                            flexFlow: "column",
                            marginTop: 20,

                        }}
                    >
                        <TableView
                            data={this.state.vehiclesList}
                            columns={olaCol}
                            load={this.state.isLoading}
                        />

                    </div>
                )}

                {!this.state.CustomerTableView && (
                    <div style={{ flexGrow: 1, display: "flex" }}>
                        <OlaAudits
                            template={this.props.template}
                            customer={this.state.selectedCustomer}
                            onClose={this.onGotoCustomerView}
                            auditId={this.auditIdFromOlaAudit}
                            color={this.props.color}
                        />
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            fetchOlaMasterDataAction
        },
        dispatch
    );
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(OlaVehicles)
);
