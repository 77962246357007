import React from "react";
import { Input } from "semantic-ui-react";
const TextInput = (props) => {
    return (
        <Input
            icon={props.icon !== undefined ? props.icon : null}
            iconPosition={props.position !== undefined ? props.position : null}
            transparent={props.transparent !== undefined ? props.transparent : null}
            key={props.key !== undefined ? props.key : undefined}
            placeholder={props.placeholder !== undefined ? props.placeholder : null}
            value={props.value}
            disabled={props.disabled !== undefined ? props.disabled : false}
            style={props.styles !== undefined ? props.styles : { marginBottom: 5 }}
            type={props.type !== undefined ? props.type : 'text'}

            onChange={(e, data) => props.onChangeTextInput(e)}
            focus
            fluid
        />
    );
};

export default TextInput;
