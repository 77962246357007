import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { isLoggedIn, uuidv4 } from '../../util';
import { createYamahaLineItemAction, deleteYamahaLineItemAction } from '../../actions/yamaha_actions';
import { Icon, Button, Grid, Form, Dropdown, Divider, TextArea } from 'semantic-ui-react';
import ReactTable from 'react-table';

class ManPower extends React.Component {
    static propTypes = {
        history: PropTypes.object.isRequired
    };
    constructor(props) {
        super(props);

        var departments = this.getComboData(["Sales", "Service", "Spares"]);

        var designations = this.getComboData(["Asstt.Technician",
            "Accountant",
            "CCSR",
            "CRM",
            "PDI Technician",
            "RTO/Insurance",
            "Sales",
            "SalesManager",
            "SalesConsultant",
            "Spares",
            "SparesPartsManager",
            "Sr. Technician",
            "Supervisor",
            "Technician",
            "Telecaller"]);

        var qualifiedProductTrainings = this.getComboData([
            "Beginner",
            "Bronze",
            "Dealer operation level 1",
            "FI-Expert",
            "New Model-FZ",
            "New Model-SZ",
            "New Model R15",
            "New Model-Scooter Level 1",
            "New Model-Scooter Level 2",
            "R15",
            "Sales Training",
            "Silver-Body",
            "Silver-Electrical",
            "Specialist",
            "YSA "]);

        var availabilities = this.getComboData(["Present", "Absent", "Left"]);

        var reasonsForLeaving = this.getComboData(["Salary hike ", "Other good offer",
            "Dealership Internal Issues", "Personal Family Issues", "Long Distance", "High Work Load",
            "No Career Growth", "On Leave (Not available during audit)",]);

        var manPowerRecord = {
            id: uuidv4(),
            uid: "",
            name: "",
            department: '',
            designation: "",
            qualifiedProductTraining: "",
            status: "",
            mobile: "",
            dateOfJoin: "",
            availability: "",
            dateOfLeaving: "",
            reasonforLeaving: "",
            reasonforNoUpdate: "",
            counterAction: "",
            counterActionDetails: ""

        };

        this.state = {
            manPowerRecord: manPowerRecord,
            newManPowerRecords: [],
            departments: departments,
            designations: designations,
            qualifiedProductTrainings: qualifiedProductTrainings,
            availabilities: availabilities,
            reasonsForLeaving: reasonsForLeaving
        };
    }

    componentDidMount() {
        if (!isLoggedIn(this.props.auth)) {
            this.props.history.push(`/login`);
            return;
        }
    }

    handleTableViewAuditClick = templates => {

    };

    resetManPowerRecordValues() {
        this.state.manPowerRecord.id = uuidv4();
        this.state.manPowerRecord.uid = '';
        this.state.manPowerRecord.name = '';
        this.state.manPowerRecord.department = '';
        this.state.manPowerRecord.designation = '';
        this.state.manPowerRecord.qualifiedProductTraining = '';
        this.state.manPowerRecord.status = '';
        this.state.manPowerRecord.mobile = '';
        this.state.manPowerRecord.dateOfJoin = '';
        this.state.manPowerRecord.availability = '';
        this.state.manPowerRecord.dateOfLeaving = '';
        this.state.manPowerRecord.reasonforLeaving = '';
        this.state.manPowerRecord.reasonforNoUpdate = '';
        this.state.manPowerRecord.counterAction = '';
        this.state.manPowerRecord.counterActionDetails = '';

        this.setState({ manPowerRecord: this.state.manPowerRecord });
    }

    addManPowerClick() {
        var record = JSON.parse(JSON.stringify(this.state.manPowerRecord))
        this.state.newManPowerRecords.insertAt(0, record);

        this.props.createYamahaLineItemAction({
            auditId: this.props.auditId,
            itemType: 'manpower',
            data: record
        });

        this.resetManPowerRecordValues();
    }

    handleAddition(options, arg, { value }) {
        options.insertAt(0, { key: options.lenght + 1, text: value, value: value })
    }

    onComboChange(propertyName, arg, { value }) {
        this.state.manPowerRecord[propertyName] = value;
        this.setState({ manPowerRecord: this.state.manPowerRecord })
    }

    onTextChange(propertyName, e) {
        this.state.manPowerRecord[propertyName] = e.target.value;
        this.setState({ manPowerRecord: this.state.manPowerRecord })
    }

    isNumber(value) {
        return /^\d+$/i.test(value);
    }

    getComboData(records) {
        var data = [];

        for (var idx = 0; idx < records.length; idx++) {
            data.push({
                key: idx,
                text: records[idx],
                value: records[idx]
            });
        }

        return data;
    }

    removeTableClick(row) {
        this.props.deleteYamahaLineItemAction({
            itemId: row.id,
            itemType: "manpower",
            auditId: this.props.auditId,
        });
        var records = this.props.manPowerRecords
        records.splice(records.indexOf(row), 1);
        var newRecords = this.state.newManPowerRecords
        newRecords.splice(newRecords.indexOf(row), 1);
        this.setState({ newManPowerRecords: newRecords });
    }

    render() {
        var records = this.state.newManPowerRecords.concat(this.props.manPowerRecords);
        var columns = [
            {
                Header: '',
                width: 35,
                style: { cursor: 'pointer' },
                Cell: row => (
                    <Icon className="remove large icon" color="red" title="Delete Row"
                        onClick={this.removeTableClick.bind(this, row.original)}
                    />
                )
            },
            {
                Header: 'Uid',
                accessor: 'uid',
                style: { cursor: 'pointer' },
                Cell: row => (
                    <AuditTableCell
                        row={row.original}
                        text={row.original.uid}
                        onClick={this.handleTableViewAuditClick}
                    />
                )
            },
            {
                Header: 'Name',
                accessor: 'name',
                style: { cursor: 'pointer' },
                Cell: row => (
                    <AuditTableCell
                        row={row.original}
                        text={row.original.name}
                        onClick={this.handleTableViewAuditClick}
                    />
                )
            },
            {
                Header: 'Department',
                accessor: 'department',
                style: { cursor: 'pointer' },
                Cell: row => (
                    <AuditTableCell
                        row={row.original}
                        text={row.original.department}
                        onClick={this.handleTableViewAuditClick}
                    />
                )
            },
            {
                Header: 'Designation',
                accessor: 'designation',
                style: { cursor: 'pointer' },
                Cell: row => (
                    <AuditTableCell
                        row={row.original}
                        text={row.original.designation}
                        onClick={this.handleTableViewAuditClick}
                    />
                )
            },
            {
                Header: 'Trainings',
                accessor: 'qualifiedProductTraining',
                style: { cursor: 'pointer' },
                Cell: row => (
                    <AuditTableCell
                        row={row.original}
                        text={row.original.qualifiedProductTraining}
                        onClick={this.handleTableViewAuditClick}
                    />
                )
            },
            {
                Header: 'Status',
                accessor: 'status',
                style: { cursor: 'pointer' },
                Cell: row => (
                    <AuditTableCell
                        row={row.original}
                        text={row.original.status}
                        onClick={this.handleTableViewAuditClick}
                    />
                )
            },
            {
                Header: 'Availability',
                accessor: 'availability',
                style: { cursor: 'pointer' },
                Cell: row => (
                    <AuditTableCell
                        row={row.original}
                        text={row.original.availability}
                        onClick={this.handleTableViewAuditClick}
                    />
                )
            }
        ];

        var canEnableAddbuton = this.state.manPowerRecord.uid.trim() && this.state.manPowerRecord.name.trim()
            && this.state.manPowerRecord.department.trim()
            && this.state.manPowerRecord.designation.trim()
            && this.state.manPowerRecord.qualifiedProductTraining.trim()
            && this.state.manPowerRecord.status.trim()
            && this.state.manPowerRecord.mobile.trim()
            && this.state.manPowerRecord.dateOfJoin.trim()
            && this.state.manPowerRecord.availability.trim()

        return (
            <div style={{ flexGrow: 1, display: 'flex', flexFlow: 'column' }}>
                <div>
                    <div style={{ display: 'flex', flexGrow: 1, flexFlow: 'column' }}>
                        <div>
                            <Divider horizontal>Add ManPower</Divider>
                            <Form>
                                <Grid>

                                    <Grid.Row columns={4} >

                                        <Grid.Column  >
                                            <Form.Field >
                                                <label>Unique Id</label>
                                                <input value={this.state.manPowerRecord.uid}
                                                    onChange={this.onTextChange.bind(this, "uid")} />
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column >
                                            <Form.Field >
                                                <label>Name</label>
                                                <input value={this.state.manPowerRecord.name}
                                                    onChange={this.onTextChange.bind(this, "name")} />
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column >
                                            <a className="ui teal left ribbon label primarybtnColor" style={{ marginLeft: 15 }}>
                                                Departments
                                             </a>
                                            <Dropdown
                                                placeholder="Select Department"
                                                fluid
                                                search
                                                selection
                                                allowAdditions
                                                options={this.state.departments}
                                                onAddItem={this.handleAddition.bind(this, this.state.departments)}
                                                value={this.state.manPowerRecord.department}
                                                onChange={this.onComboChange.bind(this, "department")}
                                            />
                                        </Grid.Column>
                                        <Grid.Column  >
                                            <a className="ui teal left ribbon label primarybtnColor" style={{ marginLeft: 15 }}>
                                                Designations
                                            </a>
                                            <Dropdown
                                                placeholder="Select Designation"
                                                fluid
                                                search
                                                selection
                                                allowAdditions
                                                options={this.state.designations}
                                                onAddItem={this.handleAddition.bind(this, this.state.designations)}
                                                value={this.state.manPowerRecord.designation}
                                                onChange={this.onComboChange.bind(this, "designation")}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row columns={4}>
                                        <Grid.Column  >
                                            <a className="ui teal left ribbon label primarybtnColor" style={{ marginLeft: 15 }}>
                                                Qualified Product Trainings
                                            </a>
                                            <Dropdown
                                                placeholder="Select  Qualified Product Training"
                                                fluid
                                                search
                                                selection
                                                allowAdditions
                                                options={this.state.qualifiedProductTrainings}
                                                onAddItem={this.handleAddition.bind(this, this.state.qualifiedProductTrainings)}
                                                value={this.state.manPowerRecord.qualifiedProductTraining}
                                                onChange={this.onComboChange.bind(this, "qualifiedProductTraining")}
                                            />
                                        </Grid.Column>
                                        <Grid.Column  >
                                            <Form.Field >
                                                <label>Status</label>
                                                <input value={this.state.manPowerRecord.status}
                                                    onChange={this.onTextChange.bind(this, "status")} />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Form.Field >
                                                <label>Mobile</label>
                                                <input value={this.state.manPowerRecord.mobile}
                                                    onChange={this.onTextChange.bind(this, "mobile")} />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Form.Field >
                                                <label>Date of Joining</label>
                                                <input value={this.state.manPowerRecord.dateOfJoin}
                                                    onChange={this.onTextChange.bind(this, "dateOfJoin")} />
                                            </Form.Field>
                                        </Grid.Column>

                                    </Grid.Row>

                                    <Grid.Row columns={4}>
                                        <Grid.Column  >
                                            <a className="ui teal left ribbon label primarybtnColor" style={{ marginLeft: 15 }}>
                                                Availability
                                            </a>
                                            <Dropdown
                                                placeholder="Select  Availability"
                                                fluid
                                                search
                                                selection
                                                options={this.state.availabilities}
                                                value={this.state.manPowerRecord.availability}
                                                onChange={this.onComboChange.bind(this, "availability")}
                                            />
                                        </Grid.Column>
                                        {this.state.manPowerRecord.availability === "Left" &&
                                            <Grid.Column>
                                                <Form.Field >
                                                    <label>Date of Leaving</label>
                                                    <input value={this.state.manPowerRecord.dateOfLeaving}
                                                        onChange={this.onTextChange.bind(this, "dateOfLeaving")} />
                                                </Form.Field>
                                            </Grid.Column>
                                        }
                                        {this.state.manPowerRecord.availability === "Left" &&
                                            <Grid.Column  >
                                                <a className="ui teal left ribbon label primarybtnColor" style={{ marginLeft: 15 }}>
                                                    Reason for Leaving
                                                </a>
                                                <Dropdown
                                                    placeholder="Select Reason for Leaving"
                                                    fluid
                                                    search
                                                    selection
                                                    allowAdditions
                                                    options={this.state.reasonsForLeaving}
                                                    onAddItem={this.handleAddition.bind(this, this.state.reasonsForLeaving)}
                                                    value={this.state.manPowerRecord.reasonforLeaving}
                                                    onChange={this.onComboChange.bind(this, "reasonforLeaving")}
                                                />
                                            </Grid.Column>
                                        }

                                        {this.state.manPowerRecord.availability === "Left" &&
                                            <Grid.Column>
                                                <Form.Field >
                                                    <label>Reason for Not Updating</label>
                                                    <input value={this.state.manPowerRecord.reasonforNoUpdate}
                                                        onChange={this.onTextChange.bind(this, "reasonforNoUpdate")} />
                                                </Form.Field>
                                            </Grid.Column>
                                        }

                                    </Grid.Row>
                                    <Grid.Row columns={3}>
                                        <Grid.Column width={4}>
                                            {this.state.manPowerRecord.availability === "Left" &&
                                                <Form.Field >
                                                    <label>Any Counter Action for Replacement</label>
                                                    <input value={this.state.manPowerRecord.counterAction}
                                                        onChange={this.onTextChange.bind(this, "counterAction")} />
                                                </Form.Field>
                                            }
                                        </Grid.Column>

                                        <Grid.Column width={8}>
                                            {this.state.manPowerRecord.availability === "Left" &&
                                                <Form.Field >
                                                    <label> Counter Action Details</label>
                                                    <TextArea rows={2} placeholder='Enter Counter Action Details'
                                                        value={this.state.manPowerRecord.counterActionDetails}
                                                        onChange={this.onTextChange.bind(this, "counterActionDetails")} />
                                                </Form.Field>
                                            }
                                        </Grid.Column>

                                        <Grid.Column width={4}>
                                            <Form.Field >
                                                <Button primary disabled={!canEnableAddbuton} onClick={this.addManPowerClick.bind(this)}>Add Man Power</Button>
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Divider horizontal> View ManPower</Divider>

                                    <Grid.Row>
                                        <ReactTable
                                            noDataText="We couldn't find anything"
                                            filterable={true}
                                            defaultPageSize={20}
                                            sortable={true}
                                            defaultFilterMethod={this.searchFilter}
                                            style={{ height: '85%', width: '95%', marginLeft: 30 }}
                                            data={records}
                                            columns={columns}
                                        />
                                    </Grid.Row>
                                </Grid>
                            </Form>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

function AuditTableCell(props) {
    function onClick() {
        props.onClick(props.row);
    }
    return (
        <div style={props.style} onClick={onClick}>
            {props.text}
        </div>
    );
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        yamaha: state.yamaha
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            createYamahaLineItemAction: createYamahaLineItemAction,
            deleteYamahaLineItemAction: deleteYamahaLineItemAction
        },
        dispatch
    );
};

// ManPower.PropTypes = {
//     dealers: PropTypes.array
// };

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ManPower)
);
