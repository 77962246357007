import axios from "axios";
import * as FileSaver from "file-saver";
import * as types from "./types";

export const fetchUdaanTemplatesAction = ({ onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/udaan/templates")
      .then(function (resp) {
        dispatch({
          type: types.FETCH_UDAAN_TEMPLATE_SUCCESS_ACTION,
          payload: resp.data,
        });
        onSuccess();
      })
      .catch(function (err) {
        dispatch({
          type: types.FETCH_UDAAN_TEMPLATE_FAILURE_ACTION,
          payload: err.data,
        });
        onFailure();
      });
  };
};

export const fetchUdaanStoresAction = ({ id, onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/udaan/getMasterData?templateId=" + id)
      .then(function (response) {
        dispatch({
          type: types.FETCH_UDAAN_STORE_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_UDAAN_STORE_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const filterUdaanStoresAction = ({
  templateId,
  region,
  state,
  city,
  status,
  onSuccess,
  onFailure,
}) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL +
          `/auditor/v1/udaan/filterAudits?templateId=${templateId}&region=${region}&state=${state}&city=${city}&status=${status}`
      )
      .then(function (response) {
        dispatch({
          type: types.FILTER_UDAAN_STORE_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.FILTER_UDAAN_STORE_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const AssignAuditsAction = ({ assignData, onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .post(types.API_URL + "/auditor/v1/udaan/multiAssignAudits", assignData)
      .then(function (response) {
        dispatch({
          type: types.ASSIGN_AUDITS_DETAILS_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.ASSIGN_AUDITS_FAILIURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const fetchUdaanAuditReportAction = (templateId) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL + "/auditor/v1/udaan/report?templateId=" + templateId,
        {
          responseType: "blob",
        }
      )
      .then(function (response) {
        FileSaver.saveAs(response.data, "udaanAuditReport.xlsx");
        dispatch({
          type: types.DOWNLOAD_UDAAN_REPORT_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.DOWNLOAD_UDAAN_REPORT_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const fetchUdaanAuditAction = (id) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/udaan/getAudit?storeId=" + id)
      .then(function (response) {
        dispatch({
          type: types.FETCH_UDAAN_AUDIT_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_UDAAN_AUDIT_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};
