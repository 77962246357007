import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "react-datepicker/dist/react-datepicker.css";
import CheckboxTable from "../commonTables/CheckboxView.jsx";
import {
    Grid,
} from "semantic-ui-react";
import IconPicker from "../commonViews/IconPicker.jsx";
import ButtonHandler from "../commonViews/ButtonHandler.jsx";
import HersheysCompetitionAuditsDetail from "./HersheysCompetitionAuditsDetail.jsx";
import "react-notifications/lib/notifications.css";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import TableView from "../commonViews/TableView.jsx";
import {
    getTextColor,
    getMainBackground,

    getFontColor,
} from "../../ThemeHandlers/theme";
import { fetchProductsDetailAction } from "../../actions/hersheys_competition_actions"


// const CheckboxTable = selectTable(ReactTable);

export class HersheysCompetitionProducts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            products: [],
            CustomerTableView: true,
        };
    }
    componentDidMount() {
        this.setState({ isLoading: true });
        let masterId = this.props.customer._id;
        this.props.fetchProductsDetailAction({
            masterId,
            onSuccess: (res) => {
                console.log(res.products, 'products')
                this.setState({ isLoading: false, products: res.products });
            },
            onFailure: () => {
                this.setState({ isLoading: false });
            },
        });
    }


    hersheysCompetitionhandleTableViewAuditClick = (customer) => {
        this.setState({ selectedCustomer: customer, CustomerTableView: false });
    };



    sendedData = (rowData, row) => {
        let ids = [];

        if (rowData.length !== 0) {
            rowData.forEach((data) => {
                ids.push(data._id);
            });
        }

        this.setState({
            assignData: ids,
        });
    };
    onGotoCustomerView = () => {
        this.setState({
            CustomerTableView: true,
        });
    };


    render() {

        const productsCol = [
            {
                title: "Company Name",
                field: "companyName",
                render: (rowData) => (
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            this.hersheysCompetitionhandleTableViewAuditClick(rowData);
                        }}
                    >
                        {rowData.companyName}
                    </span>
                ),
                customFilterAndSearch: (term, rowData) =>
                    rowData.companyName.toLowerCase().indexOf(term.toLowerCase()) > -1,
            },
            {
                title: "Brand",
                field: "brand",
                render: (rowData) => (
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            this.hersheysCompetitionhandleTableViewAuditClick(rowData);
                        }}
                    >
                        {rowData.brand}
                    </span>
                ),
                customFilterAndSearch: (term, rowData) =>
                    rowData.brand.toLowerCase().indexOf(term.toLowerCase()) > -1,
            },
            {
                title: "SKU",
                field: "sku",
                render: (rowData) => (
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            this.hersheysCompetitionhandleTableViewAuditClick(rowData);
                        }}
                    >
                        {rowData.sku}
                    </span>
                ),
                customFilterAndSearch: (term, rowData) =>
                    rowData.sku.toLowerCase().indexOf(term.toLowerCase()) >
                    -1,
            },

            {
                title: "Product Type",
                field: "type",
                render: (rowData) => (
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            this.hersheysCompetitionhandleTableViewAuditClick(rowData);
                        }}
                    >
                        {rowData.type}
                    </span>
                ),
                customFilterAndSearch: (term, rowData) =>
                    rowData.type.toLowerCase().indexOf(term.toLowerCase()) > -1,
            }
        ];

        return (
            <div>
                <NotificationContainer />
                {this.state.CustomerTableView && (
                    <div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ display: "inline-block", marginBottom: 20 }}>
                                <IconPicker
                                    name="arrow left"
                                    click={this.props.onClose}
                                    styles={{
                                        color: getTextColor(this.props.color),
                                        float: "right",
                                        cursor: "pointer",
                                        // marginTop: 35,
                                        position: "absolute",
                                        right: 20,
                                    }}
                                />
                                <h1
                                    style={{
                                        display: "inline-block",
                                        color: getTextColor(this.props.color),
                                    }}
                                >
                                    Hersheys Competition Products
                                </h1>
                            </div>

                        </div>

                    </div>
                )}
                {this.state.CustomerTableView && (
                    <div
                        style={{
                            // display: "flex",
                            // flexGrow: 1,
                            // flexFlow: "column",
                            marginTop: 20,
                            width: 1200

                        }}
                    >
                        <TableView
                            selection={false}
                            data={this.state.products}
                            columns={productsCol}
                            load={this.state.isLoading}
                            onSelectionChange={(rows, row) => this.sendedData(rows, row)}
                        />

                    </div>
                )}

                {!this.state.CustomerTableView && (
                    <div style={{ flexGrow: 1, display: "flex" }}>
                        <HersheysCompetitionAuditsDetail
                            template={this.props.template}
                            product={this.state.selectedCustomer}
                            onClose={this.onGotoCustomerView}
                            auditId={this.auditIdFromHersheysCompetitionAudit}
                            color={this.props.color}
                        />
                    </div>
                )}
            </div>
        );
    }
}

function AuditTableCell(props) {
    function onClick() {
        props.onClick(props.row);
    }
    return (
        <div style={props.style} onClick={onClick}>
            {props.text}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {

    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            fetchProductsDetailAction
        },
        dispatch
    );
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(HersheysCompetitionProducts)
);
