import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { isLoggedIn } from './../util';
import Document from './Document.jsx';

class AgentDocumentDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedSection: "",
        };
    }

    componentDidMount() {
        if (!isLoggedIn(this.props.auth)) {
            this.props.history.push(`/login`);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!isLoggedIn(this.props.auth)) {
            this.props.history.push(`/login`);
            return false;
        }
        return true;
    }

    render() {
        var questions = this.props.audit.questions;

        return (
            <Document question={questions[0]} />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AgentDocumentDetail));