import React, { Component } from "react";
import { Card, Form, Grid } from "semantic-ui-react";
import TextInput from "../commonViews/TextInput";
import DropDownPicker from "../commonViews/DropDownPicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export class FurlencoAuditTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: "",
      audits: [],
      parent: {},
      changeAns: null,
      handleChange: null,
    };
  }
  componentDidMount() {
    if (
      this.props.item &&
      this.props.audits &&
      this.props.state &&
      this.props.changeAns &&
      this.props.handleChange
    ) {
      this.setState({
        item: this.props.item,
        audits: this.props.audits,
        parent: this.props.state,
        changeAns: this.props.changeAns,
        handleChange: this.props.handleChange,
      });
    }
  }
  static getDerivedStateFromProps(nextProps) {
    if (
      nextProps.item &&
      nextProps.audits &&
      nextProps.state &&
      nextProps.changeAns &&
      nextProps.handleChange
    ) {
      return {
        item: nextProps.item,
        audits: nextProps.audits,
        parent: nextProps.state,
        changeAns: nextProps.changeAns,
        handleChange: nextProps.handleChange,
      };
    }
  }

  render() {
    let questions = [];
    if (this.state.audits.length !== 0)
      this.state.audits.questionnaire.map((ques) => {
        if (ques.name == this.state.item) {
          ques.questions != null &&
            ques.questions
              .filter((eachQuestion) => {
                if (eachQuestion.hasOwnProperty("dependency")) {
                  let question = null;
                  let questionId = eachQuestion.dependency.questionId;
                  let answerNeeded = eachQuestion.dependency.answer;
                  ques.questions.map((q) => {
                    if (q._id == questionId) {
                      question = q;
                    }
                  });
                  if (question.answer != answerNeeded) {
                    return false;
                  }
                  return true;
                } else {
                  return true;
                }
              })
              .map((q) => {
                questions.push(
                  <Grid.Row style={{ paddingLeft: "10%" }}>
                    <Grid.Column
                      width={8}
                      style={{
                        marginTop: "3%",
                        fontWeight: "bold",
                        display: "inline-block",
                        fontSize: "15px",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>{q.question}</span>
                    </Grid.Column>
                    <Grid.Column width={8} style={{ marginTop: "3%" }}>
                      {q.answerType == "text" && (
                        <TextInput
                          disabled={!this.state.parent.editModalAudit}
                          value={q.answer}
                          onChangeTextInput={(e) => { this.state.changeAns(q, e) }}
                          style={{ display: "inline-block" }}
                        />

                      )}
                      {q.answerType == "number" && (
                        <TextInput
                          disabled={!this.state.parent.editModalAudit}
                          value={q.answer}
                          onChangeTextInput={(e) => { this.state.changeAns(q, e) }}
                          style={{ display: "inline-block" }}
                        />

                      )}
                      {q.answerType == "date" && (
                        <DatePicker
                          selected={this.state.parent.startDate}
                          onChange={(date) => this.state.handleChange(q, date)}
                          className="form-control"
                          monthsShown={1}
                          dateFormat={"dd/MM/yyyy"}
                          popperPlacement="bottom"
                        />
                      )}
                      {q.answerType == "options" && (
                        <DropDownPicker
                          disabled={!this.state.parent.editModalAudit}
                          styles={{ display: "inline-block", width: "50%" }}
                          value={q.answer}
                          placeholder={"Select an option"}
                          options={q.options.map((label, i) => {
                            return {
                              value: label.value,
                              text: label.label,
                              key: label.value,
                            };
                          })}
                          onChangeDropDown={(data) => {
                            this.state.changeAns(q, data);
                          }}
                        />
                        // <Dropdown
                        //   disabled={!this.state.parent.editModalAudit}
                        //   style={{ display: "inline-block", width: "50%" }}
                        //   options={q.options.map((label, i) => {
                        //     return {
                        //       value: label.value,
                        //       text: label.label,
                        //       key: label.value,
                        //     };
                        //   })}
                        //   selection
                        //   value={q.answer}
                        //   onChange={(e, { value }) =>
                        //     this.state.changeAns(q, value)
                        //   }
                        //   placeholder={"select an option"}
                        // />
                      )}

                      {q.answerType == "auto" && (
                        <TextInput
                          disabled={true}
                          value={q.answer}
                          style={{ display: "inline-block" }}
                        />

                      )}
                    </Grid.Column>
                  </Grid.Row>
                );
              });
        }
      });
    return (
      <Card
        style={{
          width: "100%",
          height: "100%",
          marginBottom: "1%",
        }}
      >
        <Card.Content>
          <Form.Field>
            <Grid>{questions}</Grid>
          </Form.Field>
        </Card.Content>
      </Card>
    );
  }
}

export default FurlencoAuditTypes;
