import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn, isClient, isAdmin } from "../../util";
import {
  fetchDealerVehiclesAction,
  updateVehicleAuditAction,
  updateDealerAction,
  fetchDealerClientVehiclesAction,
  fetchShowDealerToClientAction,
} from "../../actions/vehicle_verification_actions";
import { Label } from "semantic-ui-react";
import IconPicker from "../commonViews/IconPicker";
import ButtonHandler from '../commonViews/ButtonHandler.jsx'
import ModalView from "../commonViews/ModalView";
import TableView from "../commonViews/TableView";
import VolkswagenAudit from "./VolkswagenAudit.jsx";
import VolkswagenAuditViewDetails from "./VolkswagenAuditViewDetails.jsx";
import CommonStatistic from "../commonTables/CommonStatistic.jsx";

import {
  getMainBackground,
  getTextColor,
  getFontColor,
  getMatchingTint,
} from "../../ThemeHandlers/theme";

class VolkswagenVehicles extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedVehicleId: "",
      selectedAudit: "",
      showPopup: false,
      showNoAuditFound: false,
      editMode: false,
      editedAudit: {},
      vehicles: [],
    };
  }

  componentDidMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
      return;
    }

    if (this.isClientProfile())
      this.props.fetchDealerClientVehicles(this.props.dealer._id);
    else {
      this.props.fetchVehicles(this.props.dealer._id);
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.vehicles !== prevProps.vehicles) {
      this.setState({ vehicles: this.props.vehicles });
    }
  }

  searchFilter = (filter, row) => {
    var flatData = JSON.stringify(row._original);
    return flatData.toLowerCase().includes(filter.value.toLowerCase());
  };

  getAudit(vehicleId) {
    for (var audit of this.props.audits) {
      if (audit.vehicleId === vehicleId) return audit;
    }

    return false;
  }

  isClientProfile = () => {
    return isClient(this.props.auth);
  };

  isAdminProfile = () => {
    return isAdmin(this.props.auth);
  };
  handleTableViewAuditClick = (vehicle) => {
    if (!vehicle._id) return;

    var audit = this.getAudit(vehicle._id);
    if (!audit) {
      this.setState({ showNoAuditFound: true });
      return;
    }

    this.setState({
      selectedVehicleId: vehicle._id,
      selectedAudit: audit,
      showPopup: true,
    });
  };

  handleCancel = () => this.setState({ showNoAuditFound: false });

  closePopup = () => {
    this.setState({ showPopup: false, newAudit: {}, editMode: false });
  };
  authenticate = () => {
    this.props.login(this.state.username, this.state.password);
  };

  editAudit = () => this.setState({ editMode: true });

  saveAudit = () => {
    var audit = this.state.selectedAudit;
    if (!audit.soldInvoice) audit.soldInvoice = {};

    var dealerAssessment = this.props.dealer.dealerAssessment;
    if (!dealerAssessment) dealerAssessment = {};

    var date = this.state.editedAudit.invoiceDate;
    audit.soldInvoice.date = date ? date.toISOString() : "";

    audit.notes = this.state.editedAudit.notes;
    audit.storeRoom = this.state.editedAudit.storeRoom;
    audit.questions[0].answer = this.state.editedAudit.vehicleStatus;

    audit.daysSent = this.state.editedAudit.daysSent;
    audit.expectedReturnDate = this.state.editedAudit.expectedReturnDate;
    audit.doDate = this.state.editedAudit.doDate;
    audit.vehicleCondition = this.state.editedAudit.vehicleCondition;
    audit.odometerReading = this.state.editedAudit.odometerReading;
    audit.gatepassCopyUploaded = this.state.editedAudit.gatepassCopyUploaded;
    audit.eventName = this.state.editedAudit.eventName;
    audit.eventLocation = this.state.editedAudit.eventLocation;
    audit.doCopyUploaded = this.state.editedAudit.doCopyUploaded;
    audit.form21Available = this.state.editedAudit.form21Available;
    audit.form22Available = this.state.editedAudit.form22Available;
    audit.hypothecationDetails = this.state.editedAudit.hypothecationDetails;
    audit.doFinancier = this.state.editedAudit.doFinancier;
    audit.customerName = this.state.editedAudit.customerName;
    audit.paymentReceivedFromCustDate =
      this.state.editedAudit.paymentReceivedFromCustDate;
    audit.paymentToDFSIDate = this.state.editedAudit.paymentToDFSIDate;

    dealerAssessment.isInvoiceShown = this.state.editedAudit.isInvoiceShown;
    dealerAssessment.cooperationScale = this.state.editedAudit.cooperationScale;

    this.setState({ showPopup: false, newAudit: {}, editMode: false });
    this.props.updateVehicleAudit(this.state.selectedAudit);
  };

  handlePhotoClick = () => { };

  handleApprove = () => {
    var dealerId = this.props.dealer._id;
    this.setState({ isApproved: true });
    this.props.fetchShowDealerToClien(dealerId);
  };

  render() {
    var vehicles = this.state.vehicles.length !== 0 ? this.state.vehicles : [];

    function getStatus(status) {
      if (status === "submitted") return "Verified";

      return "Not Verified";
    }

    var col = [
      {
        title: "",
        field: "",
        render: (rowData) => {
          var status = this.getAudit(rowData._id).status;
          if (status == "submitted") {
            return <Label size="medium" color="green"></Label>;
          } else {
            return <Label size="medium"></Label>;
          }
        },
      },

      {
        title: "Chassis Number (VIN)",
        field: "",
        render: (rowData) => (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.handleTableViewAuditClick(rowData);
            }}
          >
            {rowData.VIN}
          </span>
        ),
        customFilterAndSearch: (term, rowData) =>
          rowData.VIN.toLowerCase().includes(term.toLowerCase()),
      },
      {
        title: "BP No",
        field: "",
        render: (rowData) => (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.handleTableViewAuditClick(rowData);
            }}
          >
            {rowData.bertaCaratNo}
          </span>
        ),
        customFilterAndSearch: (term, rowData) =>
          rowData.BPNo.toLowerCase().includes(term.toLowerCase()),
      },
      {
        title: "Model Name",
        field: "",
        render: (rowData) => (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.handleTableViewAuditClick(rowData);
            }}
          >
            {rowData.modelDesc}
          </span>
        ),
        customFilterAndSearch: (term, rowData) =>
          rowData.modelDesc.toLowerCase().includes(term.toLowerCase()),
      },
      {
        title: "Invoice No",
        field: "",
        render: (rowData) => (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.handleTableViewAuditClick(rowData);
            }}
          >
            {rowData.invoiceNo}
          </span>
        ),
        customFilterAndSearch: (term, rowData) =>
          rowData.invoiceNo.toLowerCase().includes(term.toLowerCase()),
      },
      {
        title: "Invoice Date",
        field: "",
        render: (rowData) => (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.handleTableViewAuditClick(rowData);
            }}
          >
            {rowData.invoiceDate}
          </span>
        ),
        customFilterAndSearch: (term, rowData) =>
          rowData.invoiceDate.toLowerCase().includes(term.toLowerCase()),
      },
      {
        title: "Invoice Amount",
        field: "",
        render: (rowData) => (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.handleTableViewAuditClick(rowData);
            }}
          >
            {rowData.DISBAmount}
          </span>
        ),
        customFilterAndSearch: (term, rowData) =>
          rowData.DISBAmount.toLowerCase().includes(term.toLowerCase()),
      },
    ];



    var title = this.props.dealer.name + " - Vehicles";

    var dealer = this.props.dealer || {};

    var selectedAudit = this.state.selectedAudit;

    return (
      <div style={{ flex: 1, padding: 15, width: "95%" }}>
        <IconPicker
          styles={{
            color: "#808080",
            marginTop: 10,
            marginRight: 10,
            float: "right",
            cursor: "pointer",
          }}
          click={this.props.onClose}
          size={"big"}
          name={"arrow left"}
        />

        {this.isAdminProfile() ? (
          <ShowApproveButtons
            dealer={this.props.dealer}
            handleApprove={this.handleApprove}
            color={this.props.color}
            backColor={getMainBackground}
            fontColor={getFontColor}
          />
        ) : (
          <div></div>
        )}

        <div style={{ flexGrow: 1, display: "flex", flexFlow: "column" }}>
          <CommonStatistic
            title={title}
            summary={this.props.summary}
            color={this.props.color}
            textColor={getTextColor}
          />
        </div>
        <div style={{ maxWidth: "1360px", overflowX: "scroll" }}>
          <div>
            <TableView selection={false} data={vehicles} columns={col} />
          </div>
        </div>
        {this.state.showPopup && selectedAudit && (
          <ModalView
            open={this.state.showPopup}
            close={this.closePopup}
            header={selectedAudit.auditName}
            content={
              <>
                {!this.state.editMode &&
                  <VolkswagenAuditViewDetails
                    selectedAudit={selectedAudit}
                    backColor={getMatchingTint}
                    color={this.props.color}
                  />}
                {this.state.editMode &&
                  <VolkswagenAudit
                    audit={selectedAudit}
                    dealer={dealer}
                    newAudit={this.state.editedAudit}
                  />
                }
              </>

            }

            size={'small'}
            saveText={!this.state.editMode ? 'Edit' : 'Save'}
            showSave={true}
            saveClick={!this.state.editMode ? this.editAudit : this.saveAudit}
          />

        )}

        {this.state.showNoAuditFound && (
          <ModalView open={this.state.showNoAuditFound}
            close={this.handleCancel}
            header={'Audit Not Found'}
            content={
              <div class="ui message">
                <div class="header">No Audit found for selected dealer.</div>
              </div>
            }
            showSave={false}
          />

        )}
      </div>
    );
  }
}

function AuditTableCell(props) {
  function onClick() {
    props.onClick(props.row);
  }
  return (
    <div onClick={onClick} style={props.style}>
      {props.text}
    </div>
  );
}

function ShowApproveButtons(props) {
  if (props.dealer.showClient === true) {
    return (

      <ButtonHandler color={'grey'}
        styles={{ marginTop: 10, marginRight: 30, float: "right" }}
        title={'Approved'}
        size={"medium"}
        click={() => { }}
      />
    );
  } else {
    return (
      <ButtonHandler
        click={props.handleApprove}
        styles={{
          marginTop: 10,
          marginRight: 30,
          float: "right",
          backgroundColor: props.backColor(props.color),
          color: props.fontColor(props.color),
        }}
        title={'Approve'}
      />
    );
  }
}

// VolkswagenVehicles.PropTypes = {
//     vehicles: PropTypes.array,
//     audits: PropTypes.array,
//     dealer: PropTypes.object,
//     summary: PropTypes.object,
// }

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    audit: state.audits.audit,
    vehicles: state.audits.vehicles,
    summary: state.audits.dealerSummary,
    audits: state.audits.dynamicAudits,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchVehicles: fetchDealerVehiclesAction,
      updateVehicleAudit: updateVehicleAuditAction,
      updateDealer: updateDealerAction,
      fetchDealerClientVehicles: fetchDealerClientVehiclesAction,
      fetchShowDealerToClien: fetchShowDealerToClientAction,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VolkswagenVehicles)
);
