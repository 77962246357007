import React, { useState, useEffect } from "react";
import { Tab } from "semantic-ui-react";
import StoreTypes from "./StoreTypes.jsx";
import { fetchDashboardDisplayAudit } from "../../../actions/cannon_action";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";

const Panes = (props) => {
  const [active, setActive] = useState(0);
  const [TabList] = useState([
    {
      menuItem: "Planogram",
      render: () => (
        <Tab.Pane>
          <StoreTypes storeType={"Planogram"} storeId={props.storeId} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Store Hygiene",
      render: () => (
        <Tab.Pane>
          <StoreTypes storeType={"Store Hygiene"} storeId={props.storeId} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Appliances Working Condition",
      render: () => (
        <Tab.Pane>
          <StoreTypes
            storeType={"Appliances"}
            storeId={props.storeId}
          />
        </Tab.Pane>
      ),
    },
  ]);

  const getText = (index) => {
    switch (index) {
      case 0:

        return props.cannon.dashboardPlanogramData ? props.cannon.dashboardPlanogramData[0].display: null;
      case 1:
        return props.cannon.dashboardHygieneData?props.cannon.dashboardHygieneData[0].display:null;
      case 2:
        return props.cannon.dashboardAppliancesData?props.cannon.dashboardAppliancesData[0].display: null;
    }
  };

  const getPanText =  (event, data) => {
    setActive(data.activeIndex);
    
    let currentTabText = event.target.text
    let text = getText(data.activeIndex);
    getDisplayAudits(currentTabText,text);
  };

  const getDisplayAudits = (data, preCategory) => {
    let reqObj = {
      storeId: props.storeId,
      productName: preCategory,
      auditType: data === "Appliances Working Condition" ? "Appliances" : data,
    };
    props.fetchDashboardDisplayAudit({
      displayDetails: reqObj,
      onSuccess: () => {},
      onFailure: () => {},
    });
  };
  const getActiveCategory = (params) => {
  };
  return <Tab panes={TabList} onTabChange={getPanText} />;
};

const mapStateToProps = (state) => {
  return {
    //reducer props to state
    cannon: state.cannon,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchDashboardDisplayAudit }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Panes));
