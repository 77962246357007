import {
  FETCH_CANNON_TEMPLATE_SUCCESS_ACTION,
  FETCH_CANNON_TEMPLATE_FAILURE_ACTION,
  FETCH_CANNON_STORE_SUCCESS_ACTION,
  FETCH_CANNON_STORE_FAILURE_ACTION,
  FETCH_CANNON_PRODUCT_SUCCESS_ACTION,
  FETCH_CANNON_PRODUCT_FAILURE_ACTION,
  FETCH_AUDITORS_SUCCESS_ACTION,
  FETCH_AUDITORS_FAILURE_ACTION,
  FETCH_DASHBOARD_SUCCESS_ACTION,
  FETCH_DASHBOARD_FAILURE_ACTION,
  CLEAR_CANNON_GRAPH_DATA,
  DOWNLOAD_CANNON_REPORT_SUCCESS_ACTION,
  DOWNLOAD_CANNON_REPORT_FAILURE_ACTION,
  FETCH_CANNON_AUDITS_SUCCESS_ACTION,
  FETCH_CANNON_AUDITS_FAILURE_ACTION,
  GET_ALL_CANONPHOTOS_SUCCESS,
  GET_ALL_CANONPHOTOS_FAILURE,
  FETCH_CANNON_DASHBOARD_SCORE_SUCCESS_ACTION,
  FETCH_CANNON_DASHBOARD_SCORE_FAILURE_ACTION,
  FETCH_CANNON_DISPLAY_PHOTO_SUCCESS_ACTION,
  FETCH_CANNON_DISPLAY_PHOTO_FAILURE_ACTION,
  FETCH_CANNON_PRODUCTDISPLAY_SUCCESS_ACTION,
  FETCH_CANNON_PRODUCTDISPLAY_FAILURE_ACTION,
  FETCH_DASHBOARD_CATEGORY_AUDITS_SUCCESS_ACTION,
  DOWNLOAD_COMPILED_REPORT_SUCCESS_ACTION,
  DOWNLOAD_COMPILED_REPORT_FAILURE_ACTION,
  FETCH_WIDGETS_SUCCESS_ACTION,
  FETCH_WIDGETS_FAILURE_ACTION,
  FETCH_CANNON_HYGIENE_SUCCESS_ACTION,
  FETCH_CANNON_HYGIENE_FAILURE_ACTION,
  FETCH_CANNON_APPLIANCES_SUCCESS_ACTION,
  FETCH_CANNON_APPLIANCES_FAILURE_ACTION,
  FETCH_DASHBOARD_DISPLAY_AUDIT_SUCCESS_ACTION
} from "../actions/types";

const CANNON_STATE = {};

 function cannon (state = CANNON_STATE, action) {
  state = Object.assign({}, state, {});
  switch (action.type) {
    case FETCH_CANNON_TEMPLATE_SUCCESS_ACTION:
      state.cannonTemplate = action.payload.template;
      state.cannonTemplateError = false;
      return state;
    case FETCH_CANNON_TEMPLATE_FAILURE_ACTION:
      state.cannonTemplateError = true;
      return state;
    case FETCH_CANNON_STORE_SUCCESS_ACTION:
      state.cannonStoreList = action.payload.stores;
      state.cannonStoreError = false;
      return state;
    case FETCH_CANNON_STORE_FAILURE_ACTION:
      state.cannonStoreError = true;
      return state;
    case FETCH_CANNON_PRODUCT_SUCCESS_ACTION:
      state.cannonProducts = action.payload.products;
      state.cannonStoreScore = action.payload.score;
      state.fetchCannonProductError = false;
      return state;
    case FETCH_CANNON_PRODUCT_FAILURE_ACTION:
      state.fetchCannonProductError = true;
      return state;
    case FETCH_CANNON_HYGIENE_SUCCESS_ACTION:
      state.cannonHygiene = action.payload.audits;
      state.fetchcannonHygieneError = false;
      return state;
    case FETCH_CANNON_HYGIENE_FAILURE_ACTION:
      state.fetchcannonHygieneError = true;
      return state;
    case FETCH_CANNON_APPLIANCES_SUCCESS_ACTION:
      state.cannonAppliances = action.payload.audits;
      state.fetchcannonAppliancesError = false;
      return state;
    case FETCH_CANNON_APPLIANCES_FAILURE_ACTION:
      state.fetchcannonAppliancesError = true;
      return state;
    case FETCH_AUDITORS_SUCCESS_ACTION:
      state.cannonAuditors = action.payload.data;
      state.fetchAuditorsError = false;
      return state;
    case FETCH_AUDITORS_FAILURE_ACTION:
      state.fetchAuditorsError = true;
      return state;
    case FETCH_DASHBOARD_SUCCESS_ACTION:

      if (action.payload.regions) {
        state.regionData = action.payload.regions;
        state.allMetrics = action.payload.metrics[0];
        state.cannonRegionData = action.payload.regions;
      } else if (action.payload.state) {
        state.cannonRegionData = action.payload.state;
      } else if (action.payload.city) {
        state.cannonRegionData = action.payload.city
      } else if (action.payload.store) {
        state.cannonRegionData = action.payload.store
      } else if (action.payload.product) {
        state.cannonRegionData = action.payload.product
      }
      state.fetchCannonRegionError = false;
      return state;
    case FETCH_DASHBOARD_FAILURE_ACTION:
      state.fetchCannonRegionError = true;
      return state;
    case GET_ALL_CANONPHOTOS_SUCCESS:
    case GET_ALL_CANONPHOTOS_FAILURE:
      state = Object.assign({}, state, {
        canonPhotos: action.payload.photos
      });
      return state;

    case CLEAR_CANNON_GRAPH_DATA:
      state.cannonRegionData = action.payload.data;
      return state;
    case DOWNLOAD_CANNON_REPORT_SUCCESS_ACTION:
      state.cannonReportDownloaded = true;
      return state;
    case DOWNLOAD_CANNON_REPORT_FAILURE_ACTION:
      state.cannonReportDownloaded = false;
      return state;

    case DOWNLOAD_COMPILED_REPORT_SUCCESS_ACTION:
      state.cannonReportDownloaded = true;
      return state;
    case DOWNLOAD_COMPILED_REPORT_FAILURE_ACTION:
      state.cannonReportDownloaded = false;
      return state;

    case FETCH_CANNON_AUDITS_SUCCESS_ACTION:
      state.cannonAudits = action.payload.audits;
      state.fetchAuditsError = false;
      return state;
    case FETCH_CANNON_AUDITS_FAILURE_ACTION:
      state.fetchAuditsError = true;
      return state;
    case FETCH_CANNON_DASHBOARD_SCORE_SUCCESS_ACTION:
      state.dashboardPlanogramData = action.payload.planogam;
      state.dashboardAppliancesData = action.payload.appliances;
      state.dashboardHygieneData =action.payload.hygiene;
      state.fetchdashboardScoreError = false;
      return state;
    case FETCH_CANNON_DASHBOARD_SCORE_FAILURE_ACTION:
      state.fetchdashboardScoreError = true;
      return state;
    case FETCH_CANNON_DISPLAY_PHOTO_SUCCESS_ACTION:
      state.dashboardDisplayPhotos = action.payload.photos;
      state.photoError = false;
      return state;
    case FETCH_CANNON_DISPLAY_PHOTO_FAILURE_ACTION:
      state.photoError = true;
      return state;
    case FETCH_CANNON_PRODUCTDISPLAY_SUCCESS_ACTION:
      state.displayProducts = action.payload.products;
      state.productAudits = action.payload.audits
      state.displayProductError = false;
      return state;
    case FETCH_CANNON_PRODUCTDISPLAY_FAILURE_ACTION:
      state.displayProductError = true;
      return state;
    case FETCH_DASHBOARD_CATEGORY_AUDITS_SUCCESS_ACTION:
      state.dashboardCategoryAudits = action.payload.categories;
      state.categoryError = false;
      return state;
    case FETCH_DASHBOARD_CATEGORY_AUDITS_SUCCESS_ACTION:
      state.categoryError = true;
      return state;

    case FETCH_WIDGETS_SUCCESS_ACTION:
      state.overallScore = action.payload.overallPercentage;
      state.widgetsTopScore = action.payload.topScore;
      state.widgetsBottomScore = action.payload.belowScore
      state.widgetsRegion = action.payload.regions;
      state.widgetsState = action.payload.state;
      state.widgetsLocation = action.payload.locations;
      // state.widgetsTopStore = action.payload.topScore;
      // state.widgetsBottomStore = acton.payload.belowScore; 
      state.fetchAllWidgetsError = false;
      return state;
    case FETCH_WIDGETS_FAILURE_ACTION:
      state.fetchAllWidgetsError = true;
      return state
    case FETCH_DASHBOARD_DISPLAY_AUDIT_SUCCESS_ACTION:
      state.displayDashboardAudits = action.payload.audits;  
    default:
      return state  
  }
}

export default cannon
