import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "react-datepicker/dist/react-datepicker.css";
import CheckboxTable from "../commonTables/CheckboxView.jsx";
import ModalView from "../commonViews/ModalView.jsx";
import IconPicker from "../commonViews/IconPicker.jsx";
import ButtonHandler from "../commonViews/ButtonHandler.jsx";
import { fetchBikanoStoreAction, bikanoDamagesAssignAudit, getAuditorsList } from "../../actions/bikano_actions";
import { fetchHersheysVisibilityStoresAction, HerysheysVisibilityAssignAuditsAction } from "../../actions/hersheys_actions";
import { fetchUserDetailAction } from "../../actions/user_detail_action";
import BikanoAuditDetails from "./BikanoAuditDetails"
import "react-notifications/lib/notifications.css";
import { NotificationContainer, NotificationManager } from "react-notifications";
import TableView from "../commonViews/TableView.jsx";
import { getTextColor, getMainBackground, getFontColor } from "../../ThemeHandlers/theme";
import Metrics from "../commonViews/Metrics.jsx";

export class BikanoAudits extends Component {
    constructor(props) {
        super(props);
        this.checkboxTable = null;

        this.setHersheysCheckboxRef = (element) => {
            this.checkboxTable = element;
        };

        this.state = {
            CustomerTableView: true,
            selectedStore: {},
            auditid: "",
            customerSearch: "",
            filteredData: [],
            modalOpen: false,
            selectedRegion: "",
            selectedState: "",
            selectedCity: "",
            selectedStatus: "",
            selection: [],
            userId: [],
            selectAll: false,
            hershysCustomers: [],
            bikanoStores: [],
            hershysFilters: [],
            userDetails: [],
            assignData: [],
            isLoading: false,
            formAssignData: {},
            selectedStoreDetail: {}
        };
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        this.props.fetchBikanoStoreAction({
            id: this.props.selectedTemplate._id,
            onSuccess: (res) => {
             if(this.props.bikano.stores !== undefined && this.props.bikano.stores.length > 0) {
            //    console.log(this.props.bikano.stores)
               this.setState({
                bikanoStores: this.props.bikano.stores,
              });
             } else {
              console.log("Stores Unavailable");
            }
                this.setState({ isLoading: false });
            },
            onFailure: (err) => {
                this.setState({ isLoading: false });
            },
        });
        
        // GettingList of Auditors
        this.props.getAuditorsList({ 
            orgName: "Bikano Damages" ,
            onSuccess: (res) => {
                if(res !== undefined && res.length > 0) {
                    this.setState({
                        userDetails: res,
                    });
                }
            },
            onFailure: (err) => {
                console.log("Error - Auditor List: ", err);
            }
        })

    }

    componentWillReceiveProps(nextProps) {
            // if (this.props.hersheys.customers !== nextProps.hersheys.customers) {
        //     this.setState({
        //         selectedRegion: "All",
        //         selectedCity: "All",
        //         selectedStatus: "All",
        //         filteredData: nextProps.hersheys.customers,
        //     });
        // }
    }

    componentDidUpdate(prevProps) {
        // console.log("Bikanoooo", this.props.bikano.stores)
        // if(this.props.bikano.stores !== undefined && this.props.bikano.stores.length > 0) {
        //     this.setState({
        //         bikanoStores: this.props.bikano.stores,
        //     });
        // } else {
        //     console.log("Not Happening");
        // }

        // if (this.props.hersheys.customers !== undefined) {
        //     if (
        //         this.props.hersheys.filters === undefined &&
        //         this.props.hersheys.customers !== prevProps.hersheys.customers
        //     ) {
        //         this.setState({
        //             hershysCustomers: this.props.hersheys.customers,
        //         });
        //     } else if (
        //         this.props.hersheys?.filters !== undefined &&
        //         this.props.hersheys.filters !== prevProps.hersheys.filters
        //     ) {
                // this.setState({
                //     hershysCustomers: this.props.hersheys.filters,
                // });
        //     }
        // }
        // if (this.props.hersheys.userDetails !== prevProps.hersheys.userDetails) {
        //     console.log("this.props.hersheys.userDetails", this.props.hersheys.userDetails )
        //     this.setState({
        //         userDetails: this.props.hersheys.userDetails,
        //     });
        // }
        // if (this.props.hersheys.userDetails !== prevProps.hersheys.userDetails) {
        //     console.log("this.props.hersheys.userDetails", this.props.hersheys.userDetails )
        //     this.setState({
        //         userDetails: this.props.hersheys.userDetails,
        //     });
        // }
    }

    auditIdFromBikanoAudit = (auditId) => {
        this.setState({
            auditid: auditId,
        });
    };

    handleViewAuditDetails = (auditInfo) => {
        // console.log("Store Audit Info : ", auditInfo);
        if(auditInfo.status.toLowerCase() == 'completed') {
            this.setState({ selectedStoreDetail : auditInfo })
            this.setState({ selectedStore: auditInfo, CustomerTableView: false });
        } else {
            NotificationManager.info(
                "Audit is not Completed for this store",
                "INFO MESSAGE",
                3000,
                true
            );
        }
    };

    onGotoCustomerView = () => {
        this.setState({
            CustomerTableView: true,
        });
    };

    searchFilter = (filter, row) => {
        let value = filter.value && filter.value.toLowerCase();

        if (filter.id) {
            var flatedData = JSON.stringify(
                row._original[filter.id] ? row._original[filter.id] : ""
            );
            return flatedData.toLowerCase().includes(value);
        }
    };

    addNewUser = () => {
        if (this.state.assignData.length !== 0) {
            this.setState({ modalOpen: true });

        } else {
            alert('Please Select the Stores to Assign')
        }
    };
    closeEditUser = () => {
        this.setState({ modalOpen: false });
    };

    removeDuplicates = (e) => {
        let newArray = [];
        let UniqueObject = {};
        for (let i in e) {
            let objTitle = e[i]["text"];
            UniqueObject[objTitle] = e[i];
        }
        for (let i in UniqueObject) {
            newArray.push(UniqueObject[i]);
        }

        return newArray;
    };

    sendedData = (rowData, row) => {
        // console.log(row, 'row')
        let ids = [];
        let check = [];

        if (rowData.length !== 0) {
            rowData.forEach((data) => {
                ids.push(data._id);
                if (data.status === 'Completed') {
                    check.push(data._id);
                    NotificationManager.info(
                        "Audit already has been completed for this store",
                        "INFO MESSAGE",
                        3000,
                        true
                    );
                } 
            });
        }
        // console.log(check, 'check')
        if (check.length !== 0) {
            this.setState({
                assignData: [],
            });
        }
        else {
            this.setState({ assignData: ids })
        }
    };

    // set Assign Data 
    userData = (rowData, checkedValue) => {
        var selectedData = this.state.assignData;
        let data = {
            auditorId: rowData._id,
            auditorName: rowData.displayName,
            masterIds: selectedData,
        };
        this.setState({ formAssignData: data})
    };

    // Assign Auditor 
    userAssign = () => {
        this.props.bikanoDamagesAssignAudit({
            assignData: this.state.formAssignData,
            onSuccess: (response) => {
                this.setState({ isLoading: true });
                console.log("Assign Status :", response)
                this.props.fetchBikanoStoreAction({
                    id: this.props.selectedTemplate._id,
                    onSuccess: (res) => {
                     if(this.props.bikano.stores !== undefined && this.props.bikano.stores.length > 0) {
                       this.setState({
                        bikanoStores: this.props.bikano.stores,
                      });
                     } else {
                      console.log("Stores Unavailable");
                    }
                        this.setState({ isLoading: false });
                    },
                    onFailure: (err) => {
                        this.setState({ isLoading: false });
                    },
                });

                this.setState({
                    modalOpen: false,
                    assignData: [],
                });
                
                NotificationManager.success(
                    "Audits Assigned Successfully",
                    "SUCCESS MESSAGE",
                    3000,
                    true
                );
            },
            onFailure: (err) => {
                console.log("Error - Assign Status : ", err)
                NotificationManager.error(
                    "Audit Not Assigned",
                    "ERROR MESSAGE",
                    3000,
                    true
                );
            },
        });
    };

    render() {
        var stores;
        if (this.state.bikanoStores.length > 0) {
            stores = this.state.bikanoStores;
        } else {
            // console.log("Stores List is empty")
            stores = this.state.hershysFilters;
        }
        // console.log(stores)
        var unique;
        var cityData = [];
        let uniqueCity;
        let status = [
            {
                text: "Pending",
                value: "Pending",
            },
            {
                text: "Assigned",
                value: "Assigned",
            },
            {
                text: "In Progress",
                value: "In Progress",
            },
            {
                text: "Completed",
                value: "Completed",
            },
            {
                text: "All",
                value: "All",
            },
        ];

        var UserList =
            this.state.userDetails.length !== 0 ? this.state.userDetails : [];

        if (this.props.bikano.stores != undefined) {
            var regionList = this.props.bikano.stores;
            // regionList.map((e) => {
            //     cityData.push({ text: e.city, value: e.city });
            // });
        }
        if (cityData.length > 0) {
            uniqueCity = this.removeDuplicates(cityData);
        }

        const newHershysCol = [
            {
                title: "Material Code",
                field: "code",
                render: (rowData) => (
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            this.handleViewAuditDetails(rowData);
                        }}
                    >
                        {rowData.materialCode}
                    </span>
                ),
            },
            {
                title: "Material Description",
                field: "description",
                render: (rowData) => (
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            this.handleViewAuditDetails(rowData);
                        }}
                    >
                        {rowData.materialDescription}
                    </span>
                ),
                customFilterAndSearch: (term, rowData) =>
                    rowData.name.toLowerCase().indexOf(term.toLowerCase()) > -1,
            },
            // {
            //     title: "Distributor Name",
            //     field: "",
            //     render: (rowData) => (
            //         <span
            //             style={{ cursor: "pointer" }}
            //             onClick={() => {
            //                 this.handleViewAuditDetails(rowData);
            //             }}
            //         >
            //             {rowData.distName}
            //         </span>
            //     ),
            //     customFilterAndSearch: (term, rowData) =>
            //         rowData.distName.toLowerCase().indexOf(term.toLowerCase()) >
            //         -1,
            // },
            // {
            //     title: "TSI Name",
            //     field: "",
            //     render: (rowData) => (
            //         <span
            //             style={{ cursor: "pointer" }}
            //             onClick={() => {
            //                 this.handleViewAuditDetails(rowData);
            //             }}
            //         >
            //             {rowData.tsiNameContact}
            //         </span>
            //     ),
            //     customFilterAndSearch: (term, rowData) =>
            //         rowData.tsiName.toLowerCase().indexOf(term.toLowerCase()) > -1,
            // },
            // {
            //     title: "TSI Beat Name",
            //     field: "tsiBeatName",
            //     render: (rowData) => (
            //         <span
            //             style={{ cursor: "pointer" }}
            //             onClick={() => {
            //                 this.handleViewAuditDetails(rowData);
            //             }}
            //         >
            //             {rowData.tsiNameContact}
            //         </span>
            //     ),
            //     customFilterAndSearch: (term, rowData) =>
            //         rowData.beat.toLowerCase().indexOf(term.toLowerCase()) > -1,
            // },
            {
                title: "Status",
                field: "status",
                filtering: true,
                render: (rowData) => (
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            this.handleViewAuditDetails(rowData);
                        }}
                    >
                        {rowData.status}
                    </span>
                ),
            },
            // {
            //     title: "Assignee",
            //     field: "userName",
            //     render: (rowData) => (
            //         <span
            //             style={{ cursor: "pointer" }}
            //             onClick={() => {
            //                 this.handleViewAuditDetails(rowData);
            //             }}
            //         >
            //             {rowData.auditorName ? rowData.auditorName : ""}
            //         </span>
            //     ),
            // },
            // {
            //     title: "Pincode",
            //     field: "outletAddress",
            //     render: (rowData) => (
            //         <span
            //             style={{ cursor: "pointer" }}
            //             onClick={() => {
            //                 this.handleViewAuditDetails(rowData);
            //             }}
            //         >
            //             {rowData.pinCode}
            //         </span>
            //     ),
            //     customFilterAndSearch: (term, rowData) =>
            //         rowData.pinCode.toLowerCase().indexOf(term.toLowerCase()) > -1,
            // },
        ];

        const column = [
            {
                Header: "Email Id's",
                accessor: "email",
                style: { textAlign: "left", cursor: "pointer" },
                Cell: ({ row: { original } }) => {
                    return <span>{original.email}</span>;
                },
            },
        ];
        return (
            <div>
                <NotificationContainer />
                {this.state.CustomerTableView && (
                    <div>
                        <div style={{ display: "inline-block", marginBottom: 20 }}>
                            <IconPicker
                                name="arrow left"
                                click={this.props.onClose}
                                styles={{
                                    color: getTextColor(this.props.color),
                                    float: "right",
                                    cursor: "pointer",
                                    marginTop: 35,
                                    position: "absolute",
                                    right: 20,
                                }}
                            />
                            <h1
                                style={{
                                    display: "inline-block",
                                    color: getTextColor(this.props.color),
                                }}
                            >
                                Bikano Damages Audit
                            </h1>

                        </div>
                        <div style={{ display: "inline-block", marginBottom: 20, marginLeft: 30 }}>
                            <ButtonHandler
                                title={'Assign Audits'}
                                size={'Medium'}
                                click={this.addNewUser}
                                styles={{
                                    cursor: "pointer",
                                    width: "100%",
                                    backgroundColor: getMainBackground(this.props.color),
                                    color: getFontColor(this.props.color),
                                }}
                                isIcon={true}
                                iconName={'add'}
                                disabled={this.state.assignData.length === 0}
                            />
                        </div>
                        <div style={{ marginTop: 10 }}>
                            {this.props.hersheys.metrics !== undefined &&
                                Object.keys(this.props.hersheys.metrics).length !== 0 &&
                                this.props.hersheys.metrics && (<Metrics metrics={this.props.hersheys.metrics} color={this.props.color} />

                                )}
                        </div>
                    </div>
                )}
                {this.state.CustomerTableView && (
                    <div
                        style={{
                            display: "flex",
                            flexGrow: 1,
                            flexFlow: "column",
                            marginTop: 20,

                        }}
                    >
                        <TableView
                            selection={true}
                            data={stores.length !== 0 ? stores : []}
                            columns={newHershysCol}
                            load={this.state.isLoading}
                            onSelectionChange={(rows, row) => this.sendedData(rows, row)}
                        />

                    </div>
                )}


                <ModalView
                    open={this.state.modalOpen}
                    close={this.closeEditUser}
                    size={'small'}
                    content={
                        <CheckboxTable
                            data={UserList}
                            columns={column}
                            sendedData={this.userData}
                        />
                    }
                    showSave={this.state.formAssignData.auditorId != undefined && this.state.formAssignData.auditorId.length > 0 ? true : false}
                    saveClick={this.userAssign}
                />

                {!this.state.CustomerTableView && (
                    <div style={{ flexGrow: 1, display: "flex" }}>
                        <BikanoAuditDetails
                            template={this.props.selectedTemplate}
                            customer={this.state.selectedStore}
                            onClose={this.onGotoCustomerView}
                            auditId={this.auditIdFromBikanoAudit}
                            color={this.props.color}
                            selectedStore={this.state.selectedStoreDetail}
                        />
                    </div>
                )}
            </div>
        );
    }
}

function AuditTableCell(props) {
    function onClick() {
        props.onClick(props.row);
    }
    return (
        <div style={props.style} onClick={onClick}>
            {props.text}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        hersheys: state.hersheys,
        user: state.user,
        bikano: state.bikano
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            fetchBikanoStoreAction,
    fetchHersheysVisibilityStoresAction,
    HerysheysVisibilityAssignAuditsAction,
    bikanoDamagesAssignAudit,
    getAuditorsList
        },
        dispatch
    );
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(BikanoAudits)
);
