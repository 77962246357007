import React from "react";
import { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import {
    Modal,
    Image,
    Segment,
    Tab,
    Step,
    Icon,
    Message
} from "semantic-ui-react";
import IconPicker from "../commonViews/IconPicker.jsx";
import "semantic-ui-css/semantic.min.css";
import config from "../../config.js";
import {
    getTextColor,
} from "../../ThemeHandlers/theme";
import ButtonHandler from "../commonViews/ButtonHandler.jsx";
import { uploadDTCPhotos, uploadDocument, saveDtcTemplates, updateDtcMaster, fetchDTCSKUPhotosAction, sendConfirmationMail, updateSkuList, getDTCSkuImages } from "../../actions/dtc_actions";
// Newly Added
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import { NotificationContainer, NotificationManager } from "react-notifications";
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Swal from 'sweetalert2';


const storageBaseUrl = config["storage_base_url"];
const confirmationMails = config["confirmation_emails"];

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-right',
    showConfirmButton: false,
    timer: 3000,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

export class Audits extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editMode: false,
            audit: null,
            selectedPhoto: "",
            audit: {},
            templates: {},
            location: {},
            declarationLetterUrl: "",
            trackerUrl: "",
            type: "",
            audits: [],
            confirmedBy: "",
            auditStatus: "",
            claimPickUpFormSubmitted: false,
            tabData: '',
            cuttingGuidelineEdit: false,
            processGuidelineEdit: false,
            claimPickUpEdit: false,
            skuImagesEdit: false,
            confirmationEmails: this.props.auth.role != "admin" ? confirmationMails : "",
            isLoading: false,
            mailSending: false,
            mailSent: false,
            claimPickUpRemarks: "",
            historyData : [
                { uploader: 'User 1', uploadDate: '2024-04-24', fileUrl: '/path/to/file1.pdf' },
                { uploader: 'User 2', uploadDate: '2024-04-23', fileUrl: '/path/to/file2.pdf' },
                // Add more history items here
              ],
            audit_history:[],
            softAuditReportUrl:"",
            signedReportUrl:""
        };
    }



    componentDidMount() {
       
       
        this.setState({
            templates: this.props.template,
            location: this.props.customer !== undefined ? this.props.customer : {},
            auditStatus: this.props.customer.auditStatus !== undefined ? this.props.customer.auditStatus : "",
            confirmedBy: this.props.customer.matrixConfirmedBy !== undefined && this.props.customer.matrixConfirmedBy,
            claimPickUpRemarks: this.props.customer.remarks !== undefined && this.props.customer.remarks,
            audit_history: this.props.customer.audit_history && this.props.customer.audit_history.length > 0 ? this.props.customer.audit_history : []
        })

        let locationId = this.props.customer._id;
        this.props.fetchDTCSKUPhotosAction({
            locationId,
            onSuccess: (res) => {
                this.setState({ audits: res.audits })
                
            },
            onFailure: () => {
            }
        })

        this.setState({ type: 'guideline' })
        
    }

    downloadFile = (url) => {
        
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = url;
        a.download = `DTC_Claim_pickup_report.xlsx`;
        a.click();
    }

    editGuidelines = (e, tabName) => {
        e.preventDefault()
        this.setState({ [tabName]: !this.state[tabName] })
    }

    downloadSkuImages = () => {
        this.setState({ isLoading: true })
        this.props.getDTCSkuImages(this.state.location._id, {
            onSuccess: () => {
                this.setState({ isLoading: false })
            },
            onFailure: () => {
                this.setState({ isLoading: false })
            }
        })
    }

    onPhotoSelect = async (e, data) => {
        this.setState({
            selectedPhoto: e.target.files[0],
        });
        
        this.props.uploadDocument(e.target.files[0], this.state.templates.orgId, {
            onSuccess: (resp) => {
                let templates = this.state.templates
                if (data == 'dtcProcess') {
                    templates.dtcProcessNoteAndguidelines.map((d) => {
                        if (d.name === 'DTC - Process Note and guidelines') {
                            d.url = resp
                            this.setState({ type: 'guideline' })
                        }
                    })
                }

                else if (data === "cuttingGuideline") {
                    templates.SampleCuttingCollectionAndPackingMethod.map((d) => {
                        if (d.name === 'Guideline for Sample Cutting Collection and Packing Method') {
                            d.url = resp
                            this.setState({ type: 'guideline' });
                        }
                    })
                }

                else if (data === "claimTracker") {
                    templates.dtcClaimPickupTemplate.map((d) => {
                        if (d.name === 'DTC Claim Pickup Template') {
                            d.url = resp
                            this.setState({ type: 'guideline' });
                        }
                    })
                }

                else if (data === 'declarationLetter') {
                    this.setState({ declarationLetterUrl: resp, type: 'letter' })
                }
                else if (data === 'dtcTracker') {
                    this.setState({ trackerUrl: resp, type: 'tracker' })
                }
                else if(data === 'softAuditReport'){
                    this.setState({softAuditReportUrl:resp,type:'tracker'})
                }
                else if(data === 'signedReport'){
                    this.setState({signedReportUrl:resp,type:'tracker'})
                }

                else {
                    templates.cuttingGuidlines.map((d) => {
                        if (d.name === data.name) {
                            d.url.push(resp)
                            this.setState({ type: 'guideline' })
                        }
                    })
                }
                this.setState({ templates: templates })
            },
            onFailure: () => {
                alert("Photo Upload Failure");
            },
        });
    };

    // SkuImages Edit 
    onHandleChangeSkuImages = async (e, data) => {
        this.setState({
            selectedPhoto: e.target.files[0],
            isLoading: true
        });
        let skuListCopy = this.state.audits;
        this.props.uploadDocument(e.target.files[0], this.state.templates.orgId, {
            onSuccess: (resp) => {
                skuListCopy[0].skuList.map((item) => {
                    if (data.name == item.name) {
                        item.photos.push({ isUploaded: true, localImageUrl: e.target.files[0], uploadedImageUrl: resp })
                    }
                })
                this.setState({ audits: skuListCopy, isLoading: false })
            },
            onFailure: () => {
                alert("Photo Upload Failure");
            },
        });

    };

    onHandleDeleteSkuImage = async (e, data, photo) => {
        e.preventDefault();
        // console.log("ldkj ckjd")
        let auditCopy = this.state.audits;
        auditCopy[0].skuList.map((item) => {
            if (data.name == item.name) {
                let filteredData = item.photos.filter(e => e.uploadedImageUrl !== photo.uploadedImageUrl)
                item.photos = filteredData;
            }
        })
        this.setState({ audits: auditCopy })
    };

    // Saving Template
    saveTemplate = (e) => {
        e.preventDefault();
        if (this.state.type === 'guideline') {
            let tempId = this.state.templates._id;
            let templates = this.state.templates;
            this.props.saveDtcTemplates({
                tempId,
                templates,
                onSuccess: (resp) => {
                    alert('Save Success')
                    this.setState({ cuttingGuidelineEdit: false, claimPickUpFormSubmitted: true,processGuidelineEdit:false })
                },
                onFailure: () => {
                    alert("Save Failed");
                },
            });
        }
        else if (this.state.type === 'letter') {
            let locationId = this.state.location._id;
            let master = this.state.location;
            let dtcCuttingDeclarationLetter = [{
                "name":"DTC - Cutting Declaration Letter",
                "url" : this.state.declarationLetterUrl
            }]
            master.dtcCuttingDeclarationLetter = dtcCuttingDeclarationLetter
            this.props.updateDtcMaster({
                locationId,
                master,
                onSuccess: (resp) => {
                    NotificationManager.success("Cutting Declaration Updated Successfully", "SUCCESS MESSAGE", 3000, true)
                    this.setState({ processGuidelineEdit: false })
                },
                onFailure: () => {
                    NotificationManager.error("Cutting Declaration Update Failed", "ERROR MESSAGE", 3000, true)
                },
            });
        }
        else if (this.state.type === 'tracker') { // Claim Pickup Template
            let locationId = this.state.location._id;
            let master = this.state.location;
            master.matrixConfirmedBy = this.state.confirmedBy  !== undefined && this.state.confirmedBy !== false ? this.state.confirmedBy : "" ;
            master.auditStatus = this.state.auditStatus !== undefined && this.state.auditStatus !== false ? this.state.auditStatus : "" ;
            master.remarks = this.state.claimPickUpRemarks !== undefined  && this.state.claimPickUpRemarks !== false ? this.state.claimPickUpRemarks : "";
            if (master['dtcClaimPickupTemplate'] && master['dtcClaimPickupTemplate'].length > 0) {
                master['dtcClaimPickupTemplate'][0].url = this.state.trackerUrl.length > 0 ? this.state.trackerUrl : master['dtcClaimPickupTemplate'][0].url;
            } else {
                if (this.state.trackerUrl.length > 0) {
                    master['dtcClaimPickupTemplate'] = [{ name: "DTC Claim Pickup Template", url: this.state.trackerUrl }];
                } else {
                    master['dtcClaimPickupTemplate'] = [{ name: "DTC Claim Pickup Template", url: '' }];
                }
            }
            if (master['softAuditReport'] && master['softAuditReport'].length > 0) {
                master['softAuditReport'][0].url = this.state.softAuditReportUrl.length > 0 ? this.state.softAuditReportUrl : master['softAuditReport'][0].url;
            } else {
                if (this.state.softAuditReportUrl.length > 0) {
                    master['softAuditReport'] = [{ name: "Soft Audit Report", url: this.state.softAuditReportUrl }];
                } else {
                    master['softAuditReport'] = [{ name: "Soft Audit Report", url: '' }];
                }
            }
            if (master['signedReport'] && master['signedReport'].length > 0) {
                master['signedReport'][0].url = this.state.signedReportUrl.length > 0 ? this.state.signedReportUrl : master['signedReport'][0].url;
            } else {
                if (this.state.signedReportUrl.length > 0) {
                    master['signedReport'] = [{ name: "Signed Report", url: this.state.signedReportUrl }];
                } else {
                    master['signedReport'] = [{ name: "Signed Report", url: '' }];
                }
            }
            // console.log("Master : ", master)
            this.props.updateDtcMaster({
                locationId,
                master,
                onSuccess: (resp) => {
                    NotificationManager.success("DTC Claim Tracker Updated Successfully", "SUCCESS MESSAGE", 3000, true)
                    this.setState({ claimPickUpEdit: false, claimPickUpFormSubmitted: true })
                },
                onFailure: () => {
                    NotificationManager.error("DTC Master Update Failed", "ERROR MESSAGE", 3000, true)
                },
            });
        } else {
            let data = {
                locationId: this.state.location._id,
                templateId: this.state.templates._id,
                SKUList: this.state.audits[0].skuList
            }
            this.props.updateSkuList({
                data,
                onSuccess: (res) => { NotificationManager.success("SKU List Updated Successfully", "SUCCESS MESSAGE", 3000, true) },
                onFailure: (err) => { NotificationManager.error("SKU List Update Failed", "ERROR MESSAGE", 3000, true) }
            }
            )
            this.setState({ skuImagesEdit: false })
        }
    }

    // New Changes 
    handleChangeConfirmedBy = (event) => {
        this.setState({ confirmedBy: event.target.value })
    }

    handleChangeAuditCompleted = (event) => {
        this.setState({ auditStatus: event.target.value })
    }

    // Handle Change Type
    handleChange = (e, data) => {
        if (data.activeIndex == 0) {
            this.setState({ type: 'guideline' })
        } else if (data.activeIndex == 1) {
            this.setState({ type: 'letter' })
        } else if (data.activeIndex == 2) {
            this.setState({ type: 'tracker' })
        } else {
            this.setState({ type: 'skuImages' })
        }
    }

    // Email Handle
    sendConfirmationEmail = (e) => {
        e.preventDefault();
        this.setState({ mailSending: true })
        let locationId = this.props.customer._id
        let mailIds = this.state.confirmationEmails;
        let remarks = this.state.claimPickUpRemarks;
        this.props.sendConfirmationMail({
            locationId, mailIds, remarks, onSuccess: (resp) => {
                Toast.fire({
                    icon: 'success',
                    title: 'Mail Sent Successfully'
                })
                this.saveTemplate(e)
                this.setState({ editMode: false, claimPickUpFormSubmitted: true, mailSending: false, confirmationEmails: "", claimPickUpRemarks: "" })
            },
            onFailure: (err) => {
                Toast.fire({
                    icon: 'error',
                    title: 'Failed to send Mail'
                })
                this.setState({ editMode: false, claimPickUpFormSubmitted: true, mailSending: false, confirmationEmails: "", claimPickUpRemarks: "" })
            },
        })
    }

    render() {
   
        const panes = this.props.auth.role == 'admin' ? [
            {
                menuItem: 'Cutting Guidelines',
                render: () => <Tab.Pane>
                    <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'end', marginLeft: '80%' }}>
                        <ButtonHandler
                            click={(e) => { this.editGuidelines(e, 'cuttingGuidelineEdit') }}
                            isIcon={true}
                            iconName={this.state.cuttingGuidelineEdit === false ? 'edit' : 'cancel'}
                            title={this.state.cuttingGuidelineEdit === false ? 'Edit' : "Cancel"}
                            color={this.state.cuttingGuidelineEdit === false ? 'green' : 'red'}
                            disabled={this.props.auth.role == 'admin' ? false : true}
                        />
                        {this.state.cuttingGuidelineEdit == true && (
                            <ButtonHandler
                                click={(e) => { this.saveTemplate(e) }}
                                isIcon={true}
                                iconName={'save'}
                                title={'Save'}
                                color={'green'}
                                disabled={this.props.auth.role == 'admin' ? false : true}
                            />
                        )}

                    </div>

                    {this.state.templates !== undefined && Object.keys(this.state.templates).length !== 0 && this.state.templates.cuttingGuidlines.map((d) => {
                        return (
                            <div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>

                                    <h3>{d.name}</h3>
                                    {this.state.cuttingGuidelineEdit === true && (
                                        <div style={{ marginLeft: 20, marginTop: 5 }}>
                                            <input
                                                type="file"
                                                content="Upload additional Photos"
                                                icon="cloud upload"
                                                labelPosition="right"
                                                onChange={(e) => this.onPhotoSelect(e, d)}
                                                multiple
                                                accept=".jpg,.png,.jpeg"
                                            />
                                        </div>
                                    )}


                                </div>
                                {d.url.map((p) => {
                                    return (
                                        <Image
                                            centered
                                            width={'50%'}
                                            style={{ marginLeft: 20, margin: 10 }}
                                            src={
                                                storageBaseUrl + p
                                            }
                                        />
                                    )

                                })}

                                <br></br>
                                <br></br>
                            </div>
                        )
                    })}
                </Tab.Pane>
            },
            {
                menuItem: 'DTC Process Guidelines & Cutting Tracker Template',
                render: () =>
                    <Tab.Pane>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'end', marginLeft: '70%' }}>
                            <ButtonHandler
                                click={(e) => { this.editGuidelines(e, 'processGuidelineEdit') }}
                                isIcon={true}
                                iconName={this.state.processGuidelineEdit === false ? 'edit' : 'cancel'}
                                title={this.state.processGuidelineEdit === false ? 'Edit' : "Cancel"}
                                color={this.state.processGuidelineEdit === false ? 'green' : 'red'}
                            />
                            {this.state.processGuidelineEdit == true && (
                                <ButtonHandler
                                    click={(e) => { this.saveTemplate(e) }}
                                    isIcon={true}
                                    iconName={'save'}
                                    title={'Save'}
                                    color={'green'}
                                />
                            )}

                        </div>
                        <div style={{ marginTop: 20 }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <h3>DTC Process Note and Guidelines</h3>
                                {this.state.processGuidelineEdit === true && this.props.auth.role == 'admin' &&(
                                    <div style={{ marginLeft: 20, marginTop: 5 }}>
                                        <input
                                            type="file"
                                            content="Upload additional Photos"
                                            icon="cloud upload"
                                            labelPosition="right"
                                            onChange={(e) => this.onPhotoSelect(e, 'dtcProcess')}
                                            multiple
                                            accept=".pdf"
                                        />
                                    </div>
                                )}
                            </div>

                            <IconPicker
                                name="download"
                                size={'huge'}
                                styles={{
                                    marginLeft: '40%',
                                    color: 'blue',
                                    cursor: "pointer",
                                    marginTop: 20

                                }}
                                click={() => this.downloadFile(storageBaseUrl + this.state.templates.dtcProcessNoteAndguidelines[0].url)}
                            />
                            <br></br>
                            <br></br>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <h3>Guideline for Sample Cutting Collection and Packing Method</h3>
                                {this.state.processGuidelineEdit === true && this.props.auth.role == 'admin' && (
                                    <div style={{ marginLeft: 20, marginTop: 5 }}>
                                        <input
                                            type="file"
                                            content="Upload additional Photos"
                                            icon="cloud upload"
                                            labelPosition="right"
                                            onChange={(e) => this.onPhotoSelect(e, 'cuttingGuideline')}
                                            multiple
                                            accept=".pdf"
                                        />
                                    </div>
                                )}
                            </div>

                            <IconPicker
                                name="download"
                                size={'huge'}

                                styles={{
                                    marginLeft: '40%',
                                    color: 'blue',
                                    cursor: "pointer",
                                    marginTop: 20
                                }}
                                click={() => this.downloadFile(storageBaseUrl + this.state.templates.SampleCuttingCollectionAndPackingMethod[0].url)}
                            />
                            <br></br>
                            <br></br>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <h3>Claim Pick-Up Cutting Tracker Format</h3>
                                {this.state.processGuidelineEdit === true && this.props.auth.role == 'admin' && (
                                    <div style={{ marginLeft: 20, marginTop: 5 }}>
                                        <input
                                            type="file"
                                            content="Upload additional Photos"
                                            icon="cloud upload"
                                            labelPosition="right"
                                            onChange={(e) => this.onPhotoSelect(e, 'claimTracker')}
                                            multiple
                                            accept=".xlsx"
                                        />
                                    </div>
                                )}
                            </div>

                            <IconPicker
                                name="download"
                                size={'huge'}

                                styles={{
                                    marginLeft: '40%',
                                    color: 'blue',
                                    cursor: "pointer",
                                    marginTop: 20
                                }}
                                click={() => this.downloadFile(storageBaseUrl + this.state.templates.dtcClaimPickupTemplate[0].url)}
                            />
                            <br></br>
                            <br></br>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <h3>DTC Cutting Declaration Letter</h3>
                                {this.state.processGuidelineEdit === true && (
                                    <div style={{ marginLeft: 20, marginTop: 5 }}>
                                        <input
                                            type="file"
                                            content="Upload additional Photos"
                                            icon="cloud upload"
                                            labelPosition="right"
                                            onChange={(e) => this.onPhotoSelect(e, 'declarationLetter')}
                                            multiple
                                            accept=".docx"
                                        />
                                    </div>
                                )}
                            </div>

                            <IconPicker
                                name="download"
                                size={'huge'}

                                styles={{
                                    marginLeft: '40%',
                                    color: 'blue',
                                    cursor: "pointer",
                                    marginTop: 20

                                }}
                                click={() => this.downloadFile(storageBaseUrl + (this.state.location.dtcCuttingDeclarationLetter && this.state.location.dtcCuttingDeclarationLetter != undefined ? this.state.location.dtcCuttingDeclarationLetter[0].url : this.state.templates.dtcCuttingDeclarationLetter[0].url))}
                            />
                            <br></br>
                            <br></br>
                        </div>

                    </Tab.Pane>
            },
            {
                menuItem: 'Auditor Information',
                render: () =>
                    <Tab.Pane style={{ padding: 30 }}>
                        {/* Start Selfie */}
                        {this.state.location !== undefined && this.state.location.startSelfieUrl !== undefined &&
                            <div>
                                <h3>Auditor selfie at the start of the audit</h3>
                                {this.state.location.startSelfieUrl && this.state.location.startSelfieUrl.length > 0 &&
                                    <div>

                                        {this.state.location.startSelfieUrl.map((startSelfie) => {
                                            // console.log(startSelfie)
                                            return (
                                                <Image
                                                    centered
                                                    width={'30%'}
                                                    style={{ marginLeft: 20 }}
                                                    src={
                                                        startSelfie.photoUploaded && storageBaseUrl + startSelfie.uploadedUrl
                                                    }
                                                />
                                            )
                                        })}
                                    </div>
                                }
                            </div>
                        }
                        {/* Middle Selfie */}
                        {this.state.location !== undefined && this.state.location.midSelfieUrl !== undefined &&
                            <div style={{ margin: 8, marginTop: 30, marginBottom: 30 }}>
                                <h3>Auditor selfie at the middle of the audit</h3>
                                {this.state.location.midSelfieUrl && this.state.location.midSelfieUrl.length > 0 &&
                                    <div>
                                        {this.state.location.midSelfieUrl.map((startSelfie) => {
                                            // console.log(startSelfie)
                                            return (
                                                <Image
                                                    centered
                                                    width={'30%'}
                                                    style={{ marginLeft: 20 }}
                                                    src={
                                                        startSelfie.photoUploaded && storageBaseUrl + startSelfie.uploadedUrl
                                                    }
                                                />
                                            )
                                        })}
                                    </div>
                                }
                            </div>
                        }
                        {/* End Selfie */}
                        {this.state.location !== undefined && this.state.location.midSelfieUrl !== undefined &&
                            <div style={{ margin: 8, marginTop: 30, marginBottom: 30 }}>
                                <h3>Auditor selfie at the end of the audit</h3>
                                {this.state.location.midSelfieUrl && this.state.location.midSelfieUrl.length > 0 &&
                                    <div>

                                        {this.state.location.midSelfieUrl.map((startSelfie) => {
                                            // console.log(startSelfie)
                                            return (
                                                <Image
                                                    centered
                                                    width={'30%'}
                                                    style={{ marginLeft: 20 }}
                                                    src={
                                                        startSelfie.photoUploaded && storageBaseUrl + startSelfie.uploadedUrl
                                                    }
                                                />
                                            )
                                        })}
                                    </div>
                                }
                            </div>
                        }
                        {/* Counting Video  */}
                        {this.state.location !== undefined && this.state.location.countingUrl !== undefined &&
                            <div style={{ margin: 8, marginTop: 30, marginBottom: 30 }}>
                                <h3>Counting Video</h3>
                                {this.state.location.countingUrl && this.state.location.countingUrl.length > 0 &&
                                    <div>
                                        <video controls style={{ width: 300, height: 300 }}>
                                            <source src={storageBaseUrl + this.state.location.countingUrl} type="video/webm" />
                                            <source src={storageBaseUrl + this.state.location.countingUrl} type="video/mp4" />
                                            <p>Your browser doesn't support HTML5 video. Here is
                                                a <a href="myVideo.mp4">link to the video</a> instead.</p>
                                        </video>
                                    </div>
                                }
                            </div>
                        }
                    </Tab.Pane>
            },
            {
                menuItem: 'SKU Images',
                render: () =>
                    <Tab.Pane style={{ padding: 30, paddingTop: 20 }}>
                        {this.state.isLoading && <CircularProgress size={25} />}
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'space-between', marginLeft: '50%' }}>
                            <ButtonHandler
                                click={(e) => { this.downloadSkuImages() }}
                                isIcon={true}
                                iconName={'download'}
                                title={'Download SKU Images'}
                                color={'orange'}
                            />
                            <ButtonHandler
                                click={(e) => { this.editGuidelines(e, 'skuImagesEdit') }}
                                isIcon={true}
                                iconName={this.state.skuImagesEdit === false ? 'edit' : 'cancel'}
                                title={this.state.skuImagesEdit === false ? 'Edit' : "Cancel"}
                                color={this.state.skuImagesEdit === false ? 'green' : 'red'}
                            />
                            {this.state.skuImagesEdit == true && (
                                <ButtonHandler
                                    click={(e) => { this.saveTemplate(e) }}
                                    isIcon={true}
                                    iconName={'save'}
                                    title={'Save'}
                                    color={'green'}
                                />
                            )}
                        </div>
                        <div>
                            {this.state.audits !== undefined && this.state.audits.length !== 0 && this.state.audits[0].skuList.map((d) => {
                                return (
                                    <div>
                                        {d.photos.length > 0 &&
                                            <>
                                                <div style={{ display: 'flex', marginTop: 25, marginBottom: 10 }}>
                                                    <h3>{d.name}</h3>
                                                    {this.state.skuImagesEdit === true &&
                                                        <div style={{ marginLeft: 20 }}>
                                                            <input
                                                                type="file"
                                                                content="Upload additional Photos"
                                                                icon="cloud upload"
                                                                labelPosition="right"
                                                                onChange={(e) => this.onHandleChangeSkuImages(e, d)}
                                                                multiple
                                                                accept=".jpg,.png,.jpeg"
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            </>
                                        }
                                        {d.photos.map((p) => {
                                            return (
                                                <Card style={{ maxWidth: 345, margin: 20 }}>
                                                    <CardActionArea>
                                                        <CardMedia
                                                            component="img"
                                                            alt={d.name + "photo"}
                                                            image={
                                                                p.isUploaded && storageBaseUrl + p.uploadedImageUrl
                                                            }
                                                        />
                                                    </CardActionArea>
                                                    {/* {this.state.skuImagesEdit === true && 
                                           <CardActions style={{ display: 'flex', justifyContent: 'flex-end'}}>
                                           <IconPicker
                                             name="trash"
                                             click={(e)=> this.onHandleDeleteSkuImage(e, d, p)}
                                             styles={{
                                              color: 'red',
                                              cursor: "pointer",
                                             }}
                                              />
                                           </CardActions>
                                           } */}
                                                </Card>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                    </Tab.Pane>
            },      
            {
                menuItem: 'Claim Pickup Audit',
                render: () =>
                    <Tab.Pane>
                        <form action="">
                            <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'end', marginLeft: '70%' }}>
                                <ButtonHandler
                                    click={(e) => { this.editGuidelines(e, 'claimPickUpEdit') }}
                                    isIcon={true}
                                    iconName={this.state.claimPickUpEdit === false ? 'edit' : 'cancel'}
                                    title={this.state.claimPickUpEdit === false ? 'Edit' : "Cancel"}
                                    color={this.state.claimPickUpEdit === false ? 'green' : 'red'}
                                />
                                {this.state.claimPickUpEdit == true && (
                                    <ButtonHandler
                                        click={(e) => { this.saveTemplate(e) }}
                                        isIcon={true}
                                        iconName={'save'}
                                        title={'Save'}
                                        color={'green'}
                                    />
                                )}

                            </div>
                            <div>
                                {/* Matrix ConfirmedBy */}
                                <Box sx={{ width: 300, marginTop: 5, marginBottom: 5 }}>
                                    <InputLabel id="demo-simple-select-label" className="customInputLabel"><b>Matrix Confirmed By</b></InputLabel>
                                    <FormControl fullWidth style={{ marginTop: 2 }}>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={this.state.confirmedBy}
                                            disabled={this.props.auth.role !== "admin" ? true : false}
                                            onChange={this.handleChangeConfirmedBy}
                                        >
                                            <MenuItem value="">
                                                <em>Select Confirmed By</em>
                                            </MenuItem>
                                            <MenuItem value={`33%`}>33%</MenuItem>
                                            <MenuItem value={`100%`}>100%</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                {/* Audit Status */}
                                <Box sx={{ width: 300, marginTop: 5, marginBottom: 5 }}>
                                    <InputLabel id="demo-simple-select-label" className="customInputLabel">
                                        <b>Audit Completed</b>
                                        {this.props.customer.auditStatus && this.props.customer.auditStatus.toLowerCase() != 'yes' &&
                                            <Chip label="Audit status is not completed" style={{ background: "red", color: "#ffffff", marginLeft: 10, margin: 5, fontWeight: 'bold' }} />
                                        }
                                    </InputLabel>
                                    {/* {console.log("Audit Details : ", this.state.location)}
                        {console.log("Audit Status : ", this.state.audit)} */}
                                    <FormControl fullWidth style={{ marginTop: 2 }}>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={this.state.auditStatus}
                                            disabled={this.state.location.auditStatus !== undefined && this.state.location.auditStatus == "Yes" ? true : false}
                                            onChange={this.handleChangeAuditCompleted}
                                        >
                                            <MenuItem value="">
                                                <em>Select Audit Status</em>
                                            </MenuItem>
                                            <MenuItem value={'Yes'}>Yes</MenuItem>
                                            <MenuItem value={'No'}>No</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                                <h3>Claim Pickup Template</h3>
                                {this.state.claimPickUpEdit === true && (
                                    <div style={{ marginLeft: 20, marginTop: 5 }}>
                                        <input
                                            type="file"
                                            content="Upload additional Photos"
                                            icon="cloud upload"
                                            labelPosition="right"
                                            onChange={(e) => this.onPhotoSelect(e, 'dtcTracker')}
                                            multiple
                                            accept=".xlsx"
                                        />
                                    </div>
                                )}
                            </div>
                            <Box sx={{ display: 'flex', justifyContent: "center", marginBottom: 5 }}>
                                <IconPicker
                                    name="download"
                                    size={'huge'}
                                    styles={{
                                        color: 'blue',
                                        cursor: "pointer",
                                        marginTop: 10
                                    }}
                                    click={() => {
                                        if (this.state.location.dtcClaimPickupTemplate && this.state.location.dtcClaimPickupTemplate[0].url.length > 0) {
                                            this.downloadFile(storageBaseUrl + this.state.location.dtcClaimPickupTemplate[0].url)
                                        } else {
                                            alert("Download not available");
                                        }
                                    }}
                                />
                            </Box>
                            <br></br>
                     
                     <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                         <h3>Soft Audit Report</h3>
                         
                     </div>
                     <Box sx={{ display: 'flex', justifyContent: "center", marginBottom: 5 }}>
                         <IconPicker
                             name="download"
                             size={'huge'}
                             styles={{
                                 color: 'blue',
                                 cursor: "pointer",
                                 marginTop: 10
                             }}
                             click={() => {
                                 if (this.state.location.softAuditReport && this.state.location.softAuditReport[0].url.length > 0) {
                                     this.downloadFile(storageBaseUrl + this.state.location.softAuditReport[0].url)
                                 } else {
                                     alert("Download not available");
                                 }
                             }}
                         />
                     </Box>
                     <br></br>
         
                     <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                         <h3>Signed Report</h3>
                        
                     </div>
                     <Box sx={{ display: 'flex', justifyContent: "center", marginBottom: 5 }}>
                         <IconPicker
                             name="download"
                             size={'huge'}
                             styles={{
                                 color: 'blue',
                                 cursor: "pointer",
                                 marginTop: 10
                             }}
                             click={() => {
                                 if (this.state.location.signedReport && this.state.location.signedReport[0].url.length > 0) {
                                     this.downloadFile(storageBaseUrl + this.state.location.signedReport[0].url)
                                 } else {
                                     alert("Download not available");
                                 }
                             }}
                         />
                     </Box>
                            <hr />
                            {/* Remarks */}
                            <Box sx={{ width: '100%', marginTop: 5, marginBottom: 3 }}>
                                <InputLabel id="demo-simple-select-label" className="customInputLabel" style={{ marginBottom: 4 }}>
                                    <h3><b>Remarks</b></h3>
                                </InputLabel>
                                <FormControl fullWidth style={{ marginTop: 4 }}>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        multiline
                                        maxRows={4}
                                        value={this.state.claimPickUpRemarks}
                                        disabled={this.props.auth.role == "admin" ? false : true}
                                        onChange={(e) => { this.setState({ claimPickUpRemarks: e.target.value }) }}
                                    />
                                </FormControl>
                            </Box>
                            {/* List of Emails */}
                            <Box sx={{ width: '100%', marginTop: 5, marginBottom: 5 }}>
                                <InputLabel id="demo-simple-select-label" className="customInputLabel  ">
                                    <h3><b>Send Confirmation Mail To Matrix Team Once File Is Uploaded</b></h3>
                                    <i style={{ color: '#2185d0', fontWeight: 'bold' }}>
                                        NOTE : ( Split Each Mail Ids with  ";",   eg: example1@gmail.com;example2@gmail.com )
                                    </i>
                                </InputLabel>
                                <FormControl fullWidth style={{ marginTop: 2 }}>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        multiline
                                        maxRows={4}
                                        value={this.state.confirmationEmails}
                                        disabled={this.props.auth.role == "admin" ? false : true}
                                        onChange={(e) => { this.setState({ confirmationEmails: e.target.value }) }}
                                    />
                                </FormControl>
                            </Box>
                            {this.state.confirmationEmails && this.state.confirmationEmails.length > 0 && this.state.confirmationEmails !== "You don't have permission to do this action" &&
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <ButtonHandler
                                        click={(e) => { this.sendConfirmationEmail(e) }}
                                        isIcon={true}
                                        iconName={'mail'}
                                        title={'Send Email'}
                                        color={'green'}
                                        loading={this.state.mailSending === true ? true : false}
                                    />
                                </div>
                            }
                        </form>
                    </Tab.Pane>
            },
            {
                menuItem: 'Audit History',
                render: () =>
                    <Tab.Pane>
                        <form action="">
                        <Step.Group vertical>
                            {this.state.audit_history.length > 0 && this.state.audit_history.map((item, index) => (
                            <Step key={index} style={{width:750}}>
                             
                                  <IconPicker
                                name="download"
                                size={'huge'}
                                styles={{
                                    marginLeft: '5%',
                                    color: '#2185D0',
                                    cursor: "pointer",
                                }}
                                click={() => this.downloadFile(storageBaseUrl + item.uploadedFile)}
                            />
                                <Step.Content style={{marginLeft:80}}>
                                <Step.Title style={{color:'#2185D0'}}>Uploaded By : {item.uploadedBy}</Step.Title>
                                <br></br>
                                <Step.Title style={{color:'#2185D0'}}><strong>Uploaded Date : {`${item.uploadedDate}`.split('T')[0]}</strong> </Step.Title>
                                <br></br>
                                <Step.Description>
                                   
                                </Step.Description>
                                </Step.Content>
                            </Step>
                            ))}
                            {this.state.audit_history.length == 0 && 
                                <Message negative>
                                <Message.Header>{"History Data Not Found"}</Message.Header>
                                        <p><li>{"No History Data Found. Audit Not Yet Started"}</li></p>
                                </Message>
                            }
                        </Step.Group>
                        
                        </form>
                    </Tab.Pane>
            }
        ] :
        [
            {
                menuItem: 'Cutting Guidelines',
                render: () => <Tab.Pane>
                    <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'end', marginLeft: '80%' }}>
                        <ButtonHandler
                            click={(e) => { this.editGuidelines(e, 'cuttingGuidelineEdit') }}
                            isIcon={true}
                            iconName={this.state.cuttingGuidelineEdit === false ? 'edit' : 'cancel'}
                            title={this.state.cuttingGuidelineEdit === false ? 'Edit' : "Cancel"}
                            color={this.state.cuttingGuidelineEdit === false ? 'green' : 'red'}
                            disabled={this.props.auth.role == 'admin' ? false : true}
                        />
                        {this.state.cuttingGuidelineEdit == true && (
                            <ButtonHandler
                                click={(e) => { this.saveTemplate(e) }}
                                isIcon={true}
                                iconName={'save'}
                                title={'Save'}
                                color={'green'}
                                disabled={this.props.auth.role == 'admin' ? false : true}
                            />
                        )}

                    </div>

                    {this.state.templates !== undefined && Object.keys(this.state.templates).length !== 0 && this.state.templates.cuttingGuidlines.map((d) => {
                        return (
                            <div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>

                                    <h3>{d.name}</h3>
                                    {this.state.cuttingGuidelineEdit === true && (
                                        <div style={{ marginLeft: 20, marginTop: 5 }}>
                                            <input
                                                type="file"
                                                content="Upload additional Photos"
                                                icon="cloud upload"
                                                labelPosition="right"
                                                onChange={(e) => this.onPhotoSelect(e, d)}
                                                multiple
                                                accept=".jpg,.png,.jpeg"
                                            />
                                        </div>
                                    )}


                                </div>
                                {d.url.map((p) => {
                                    return (
                                        <Image
                                            centered
                                            width={'50%'}
                                            style={{ marginLeft: 20, margin: 10 }}
                                            src={
                                                storageBaseUrl + p
                                            }
                                        />
                                    )

                                })}

                                <br></br>
                                <br></br>
                            </div>
                        )
                    })}
                </Tab.Pane>
            },
            {
                menuItem: 'DTC Process Guidelines & Cutting Tracker Template',
                render: () =>
                    <Tab.Pane>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'end', marginLeft: '70%' }}>
                            <ButtonHandler
                                click={(e) => { this.editGuidelines(e, 'processGuidelineEdit') }}
                                isIcon={true}
                                iconName={this.state.processGuidelineEdit === false ? 'edit' : 'cancel'}
                                title={this.state.processGuidelineEdit === false ? 'Edit' : "Cancel"}
                                color={this.state.processGuidelineEdit === false ? 'green' : 'red'}
                            />
                            {this.state.processGuidelineEdit == true && (
                                <ButtonHandler
                                    click={(e) => { this.saveTemplate(e) }}
                                    isIcon={true}
                                    iconName={'save'}
                                    title={'Save'}
                                    color={'green'}
                                />
                            )}

                        </div>
                        <div style={{ marginTop: 20 }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <h3>DTC Process Note and Guidelines</h3>
                               
                            </div>

                            <IconPicker
                                name="download"
                                size={'huge'}
                                styles={{
                                    marginLeft: '40%',
                                    color: 'blue',
                                    cursor: "pointer",
                                    marginTop: 20

                                }}
                                click={() => this.downloadFile(storageBaseUrl + this.state.templates.dtcProcessNoteAndguidelines[0].url)}
                            />
                            <br></br>
                            <br></br>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <h3>Guideline for Sample Cutting Collection and Packing Method</h3>
                            
                            </div>

                            <IconPicker
                                name="download"
                                size={'huge'}

                                styles={{
                                    marginLeft: '40%',
                                    color: 'blue',
                                    cursor: "pointer",
                                    marginTop: 20
                                }}
                                click={() => this.downloadFile(storageBaseUrl + this.state.templates.SampleCuttingCollectionAndPackingMethod[0].url)}
                            />
                            <br></br>
                            <br></br>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <h3>Claim Pick-Up Cutting Tracker Format</h3>
                                
                            </div>

                            <IconPicker
                                name="download"
                                size={'huge'}

                                styles={{
                                    marginLeft: '40%',
                                    color: 'blue',
                                    cursor: "pointer",
                                    marginTop: 20
                                }}
                                click={() => this.downloadFile(storageBaseUrl + this.state.templates.dtcClaimPickupTemplate[0].url)}
                            />
                            <br></br>
                            <br></br>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <h3>DTC Cutting Declaration Letter</h3>
                                {this.state.processGuidelineEdit === true && (
                                    <div style={{ marginLeft: 20, marginTop: 5 }}>
                                        <input
                                            type="file"
                                            content="Upload additional Photos"
                                            icon="cloud upload"
                                            labelPosition="right"
                                            onChange={(e) => this.onPhotoSelect(e, 'declarationLetter')}
                                            multiple
                                            accept=".docx"
                                        />
                                    </div>
                                )}
                            </div>

                            <IconPicker
                                name="download"
                                size={'huge'}

                                styles={{
                                    marginLeft: '40%',
                                    color: 'blue',
                                    cursor: "pointer",
                                    marginTop: 20

                                }}
                                click={() => this.downloadFile(storageBaseUrl + this.state.templates.dtcCuttingDeclarationLetter[0].url)}
                            />
                            <br></br>
                            <br></br>
                        </div>

                    </Tab.Pane>
            },
            {
                menuItem: 'Claim Pickup Audit',
                render: () =>
                    <Tab.Pane>
                        <form action="">
                            <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'end', marginLeft: '70%' }}>
                                <ButtonHandler
                                    click={(e) => { this.editGuidelines(e, 'claimPickUpEdit') }}
                                    isIcon={true}
                                    iconName={this.state.claimPickUpEdit === false ? 'edit' : 'cancel'}
                                    title={this.state.claimPickUpEdit === false ? 'Edit' : "Cancel"}
                                    color={this.state.claimPickUpEdit === false ? 'green' : 'red'}
                                />
                                {this.state.claimPickUpEdit == true && (
                                    <ButtonHandler
                                        click={(e) => { this.saveTemplate(e) }}
                                        isIcon={true}
                                        iconName={'save'}
                                        title={'Save'}
                                        color={'green'}
                                    />
                                )}

                            </div>
                            <div>
                                {/* Matrix ConfirmedBy */}
                                {this.props.auth.role == 'a'}
                                <Box sx={{ width: 300, marginTop: 5, marginBottom: 5 }}>
                                    <InputLabel id="demo-simple-select-label" className="customInputLabel"><b>Matrix Confirmed By</b></InputLabel>
                                    <FormControl fullWidth style={{ marginTop: 2 }}>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={this.state.confirmedBy}
                                            disabled={this.props.auth.role !== "admin" ? true : false}
                                            onChange={this.handleChangeConfirmedBy}
                                        >
                                            <MenuItem value="">
                                                <em>Select Confirmed By</em>
                                            </MenuItem>
                                            <MenuItem value={`33%`}>33%</MenuItem>
                                            <MenuItem value={`100%`}>100%</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                {/* Audit Status */}
                                <Box sx={{ width: 300, marginTop: 5, marginBottom: 5 }}>
                                    <InputLabel id="demo-simple-select-label" className="customInputLabel">
                                        <b>Audit Completed</b>
                                        {this.props.customer.auditStatus && this.props.customer.auditStatus.toLowerCase() != 'yes' &&
                                            <Chip label="Audit status is not completed" style={{ background: "red", color: "#ffffff", marginLeft: 10, margin: 5, fontWeight: 'bold' }} />
                                        }
                                    </InputLabel>
                                    {/* {console.log("Audit Details : ", this.state.location)}
                        {console.log("Audit Status : ", this.state.audit)} */}
                                    <FormControl fullWidth style={{ marginTop: 2 }}>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={this.state.auditStatus}
                                            disabled={this.state.location.auditStatus !== undefined && this.state.location.auditStatus == "Yes" ? true : false}
                                            onChange={this.handleChangeAuditCompleted}
                                        >
                                            <MenuItem value="">
                                                <em>Select Audit Status</em>
                                            </MenuItem>
                                            <MenuItem value={'Yes'}>Yes</MenuItem>
                                            <MenuItem value={'No'}>No</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                                <h3>Claim Pickup Template</h3>
                                {this.state.claimPickUpEdit === true && (
                                    <div style={{ marginLeft: 20, marginTop: 5 }}>
                                        <input
                                            type="file"
                                            content="Upload additional Photos"
                                            icon="cloud upload"
                                            labelPosition="right"
                                            onChange={(e) => this.onPhotoSelect(e, 'dtcTracker')}
                                            multiple
                                            accept=".xlsx"
                                        />
                                    </div>
                                )}
                            </div>
                            <Box sx={{ display: 'flex', justifyContent: "center", marginBottom: 5 }}>
                                <IconPicker
                                    name="download"
                                    size={'huge'}
                                    styles={{
                                        color: 'blue',
                                        cursor: "pointer",
                                        marginTop: 10
                                    }}
                                    click={() => {
                                        if (this.state.location.dtcClaimPickupTemplate && this.state.location.dtcClaimPickupTemplate[0].url.length > 0) {
                                            this.downloadFile(storageBaseUrl + this.state.location.dtcClaimPickupTemplate[0].url)
                                        } else {
                                            alert("Download not available");
                                        }
                                    }}
                                />
                            </Box>
                            <br></br>
                     
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                                <h3>Soft Audit Report</h3>
                                {this.state.claimPickUpEdit === true && (
                                    <div style={{ marginLeft: 20, marginTop: 5 }}>
                                        <input
                                            type="file"
                                            content="Upload additional Photos"
                                            icon="cloud upload"
                                            labelPosition="right"
                                            onChange={(e) => this.onPhotoSelect(e, 'softAuditReport')}
                                            multiple
                                            // accept=".xlsx"
                                        />
                                    </div>
                                )}
                            </div>
                            <Box sx={{ display: 'flex', justifyContent: "center", marginBottom: 5 }}>
                                <IconPicker
                                    name="download"
                                    size={'huge'}
                                    styles={{
                                        color: 'blue',
                                        cursor: "pointer",
                                        marginTop: 10
                                    }}
                                    click={() => {
                                        if (this.state.location.softAuditReport && this.state.location.softAuditReport[0].url.length > 0) {
                                            this.downloadFile(storageBaseUrl + this.state.location.softAuditReport[0].url)
                                        } else {
                                            alert("Download not available");
                                        }
                                    }}
                                />
                            </Box>
                            <br></br>
                
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                                <h3>Signed Report</h3>
                                {this.state.claimPickUpEdit === true && (
                                    <div style={{ marginLeft: 20, marginTop: 5 }}>
                                        <input
                                            type="file"
                                            content="Upload additional Photos"
                                            icon="cloud upload"
                                            labelPosition="right"
                                            onChange={(e) => this.onPhotoSelect(e, 'signedReport')}
                                            multiple
                                            // accept=".xlsx"
                                        />
                                    </div>
                                )}
                            </div>
                            <Box sx={{ display: 'flex', justifyContent: "center", marginBottom: 5 }}>
                                <IconPicker
                                    name="download"
                                    size={'huge'}
                                    styles={{
                                        color: 'blue',
                                        cursor: "pointer",
                                        marginTop: 10
                                    }}
                                    click={() => {
                                        if (this.state.location.signedReport && this.state.location.signedReport[0].url.length > 0) {
                                            this.downloadFile(storageBaseUrl + this.state.location.signedReport[0].url)
                                        } else {
                                            alert("Download not available");
                                        }
                                    }}
                                />
                            </Box>
                            <hr />
                            {/* Remarks */}
                            
                            {/* List of Emails */}
                            <Box sx={{ width: '100%', marginTop: 5, marginBottom: 5 }}>
                                <InputLabel id="demo-simple-select-label" className="customInputLabel  ">
                                    <h3><b>Send Confirmation Mail To Matrix Team Once File Is Uploaded</b></h3>
                                    <i style={{ color: '#2185d0', fontWeight: 'bold' }}>
                                        NOTE : ( Split Each Mail Ids with  ";",   eg: example1@gmail.com;example2@gmail.com )
                                    </i>
                                </InputLabel>
                                <FormControl fullWidth style={{ marginTop: 2 }}>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        multiline
                                        maxRows={4}
                                        value={this.state.confirmationEmails}
                                        disabled={this.props.auth.role == "admin" ? false : true}
                                        onChange={(e) => { this.setState({ confirmationEmails: e.target.value }) }}
                                    />
                                </FormControl>
                            </Box>
                            {this.state.confirmationEmails && this.state.confirmationEmails.length > 0 && this.state.confirmationEmails !== "You don't have permission to do this action" &&
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <ButtonHandler
                                        click={(e) => { this.sendConfirmationEmail(e) }}
                                        isIcon={true}
                                        iconName={'mail'}
                                        title={'Send Email'}
                                        color={'green'}
                                        loading={this.state.mailSending === true ? true : false}
                                    />
                                </div>
                            }
                        </form>
                    </Tab.Pane>
            },
        ]

        return (
            <div>
                <div>
                    <div style={{ display: "inline-block", marginBottom: 20 }}>
                        <IconPicker
                            name="arrow left"
                            click={this.props.onClose}
                            styles={{
                                color: getTextColor(this.props.color),
                                float: "right",
                                cursor: "pointer",
                                marginTop: 35,
                                position: "absolute",
                                right: 20,
                            }}
                        />
                        <h1
                            style={{
                                display: "inline-block",
                                marginLeft: 15,
                                color: getTextColor(this.props.color),
                            }}
                        >
                            DTC Claim Pickup
                        </h1>

                    </div>
                </div>

                <div>
                    <Tab
                        style={{ marginTop: 15, marginLeft: 10 }}
                        menu={{
                            color: "white",
                            attached: false,
                            tabular: false,
                        }}
                        panes={panes}
                        onTabChange={this.handleChange}
                    />
                </div>
                {/* <NotificationContainer/> */}
            </div>
        );
    }
}



const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        dtc: state.dtc
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            uploadDTCPhotos,
            saveDtcTemplates,
            updateDtcMaster,
            fetchDTCSKUPhotosAction,
            uploadDocument,
            sendConfirmationMail,
            updateSkuList,
            getDTCSkuImages
        },
        dispatch
    );
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Audits)
);
