import React, { Component } from "react";
import {
  useTable,
  usePagination,
  useFilters,
  useGlobalFilter,
} from "react-table";

import "./table.css";

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;
  return (
    // <input style={{border:'1px solid #efefef',height:28}}/>

    <input
      style={{ border: "1px solid #efefef", height: 28 }}
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
    />
  );
}

function Table({ columns, data, rowClick }) {
  // Use the state and functions returned from useTable to build your UI

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const checkId = (id,original)=>{
    if(id === 'verification'){
      return
    }else if(id === 'report'){
      return     
  
    }else if(id === "_id"){
    return
    }else if(id ==="reportUrl"){
      return
    }
    else if(id === 'downloadPdf'){
      return
    }else if(id === 'locations'){
      return rowClick(original)
    }
    else if(id === "location"){
      return rowClick(original)
    }
    else if(id === 'auditMonth'){
      return rowClick(original)
    }else if(id === 'VIN'){
      return rowClick(original)
    }else if(id === 'modelDesc'){
      return rowClick(original)
    }else if(id === 'edit'){
      return rowClick(original)
    }
    else if(id === 'pdf'){
      return 
    }
    else if(id === 'pdf'){
      return
    }
    else if(id !== 'report' && id  !== 'verification'){
       return rowClick(original)
    }
    
    
    
  }
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
      },
      defaultColumn, // Be sure to pass the defaultColumn option
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    usePagination
  );
  // Render the UI for your table
  return (
    <>
      <table id="customers" {...getTableProps()}  >
        <thead>
          {headerGroups.map((headerGroup) => (
            <>
              <tr {...headerGroup.getHeaderGroupProps()} style={{fontSize:17}}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
              <tr >
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{ backgroundColor: "#ececec" }}
                  >
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                ))}
              </tr>
            </>
          ))}
        </thead>
        {}
        <tbody {...getTableBodyProps()}>
          {page.length > 0 ? (
            page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  
                  style={{ cursor: "pointer",fontSize:17 }}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} onClick={()=>checkId(cell.column.id,row.original)}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })
          ) : (
            <>
              <tr style={{border:'0.1px solid #efefef',    padding: '8px',height:'30px'
}}>
           
            </tr>
            <tr style={{border:'0.1px solid #efefef', padding: '8px',height:'30px'}}>
           
            </tr>
            <tr style={{border:'0.1px solid #efefef', padding: '8px',height:'30px'}}>
            
            </tr>
            <tr style={{border:'0.1px solid #efefef', padding: '8px',height:'30px'}}>
            
            </tr>
            <tr style={{border:'0.1px solid #efefef', padding: '8px',height:'30px'}}>
           
            </tr>
            <tr style={{border:'0.1px solid #efefef', padding: '8px',height:'30px'}}>
            
            </tr>
            <tr style={{border:'0.1px solid #efefef', padding: '8px',height:'30px'}}>
           
            </tr>
            <tr style={{border:'0.1px solid #efefef', padding: '8px',height:'30px'}}>
           
            </tr>
            <tr style={{border:'0.1px solid #efefef', padding: '8px',height:'30px'}}>
           
            </tr>
            <tr style={{border:'0.1px solid #efefef', padding: '8px',height:'30px'}}>
           
            </tr>
            <tr style={{border:'0.1px solid #efefef', padding: '8px',height:'30px'}}>
           
            </tr>
            <tr style={{border:'0.1px solid #efefef', padding: '8px',height:'30px'}}>
           
            </tr>
            <tr style={{border:'0.1px solid #efefef', padding: '8px',height:'30px'}}>
           
            </tr>
            </>
          )}
        </tbody>
      </table>
      {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          height: 40,
          alignItems: "center",
          border:'1px solid #efefef',
          backgroundColor:'#ececec',
          width:'100%'
        }}
      >
        <div style={{ flex: "30%", flexDirection: "row" }}>
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </button>{" "}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </button>{" "}
        </div>

        <span style={{ marginRight: 20 }}>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>

        <select
          value={pageSize}
          style={{ marginRight: 20, border: "1px solid #e8e8e8" }}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}

export default Table;
