import {
    FETCH_DAMAGES_TEMPLATE_SUCCESS_ACTION,
    FETCH_DAMAGES_TEMPLATE_FAILURE_ACTION,
    FETCH_DAMAGES_AUDITS_SUCCESS_ACTION,
    FETCH_DAMAGES_AUDITS_FAILURE_ACTION,
    SAVE_DAMAGES_AUDIT_SUCCESS_ACTION,
    SAVE_DAMAGES_AUDIT_FAILURE_ACTION,
    DOWNLOAD_DAMAGES_REPORT_SUCCESS_ACTION,
    DOWNLOAD_DAMAGES_REPORT_FAILURE_ACTION,
    DOWNLOAD_DAMAGES_AUDIT_REPORT_SUCCESS_ACTION,
    DOWNLOAD_DAMAGES_AUDIT_REPORT_FAILURE_ACTION,
    FETCH_DAMAGES_TRACKER_MASTER_SUCCESS_ACTION,
    FETCH_DAMAGES_TRACKER_MASTER_FAILURE_ACTION,
    SAVE_TRACKER_AUDIT_SUCCESS_ACTION,
    SAVE_TRACKER_AUDIT_FAILURE_ACTION,
    DOWNLOAD_DAMAGES_TRACKER_REPORT_SUCCESS_ACTION,
    DOWNLOAD_DAMAGES_TRACKER_REPORT_FAILURE_ACTION,
    UPLOAD_PVSHEET_SUCCESS,
    UPLOAD_PVSHEET_FAILURE
} from '../actions/types';


const INIT_STATE = {};

 function damages (state = INIT_STATE, action) {
    state = Object.assign({}, state, {});

    switch (action.type) {
        case FETCH_DAMAGES_TEMPLATE_SUCCESS_ACTION:
            state.templates = action.payload.templates;
            state.fetchDamagesTemplateError = false;
            return state;

        case FETCH_DAMAGES_TEMPLATE_FAILURE_ACTION:
            state.fetchDamagesTemplateError = true;
            return state;

        case FETCH_DAMAGES_AUDITS_SUCCESS_ACTION:
            state.damagesAudit = action.payload.damagesAudit;
            state.fetchDamagesAuditsError = false;
            return state;

        case FETCH_DAMAGES_AUDITS_FAILURE_ACTION:
            state.fetchDamagesAuditsError = true;
            return state;

        case SAVE_DAMAGES_AUDIT_SUCCESS_ACTION:
            return Object.assign({}, state, {
                saveDamagesAuditSuccessful: true
            });

        case SAVE_DAMAGES_AUDIT_FAILURE_ACTION:
            return Object.assign({}, state, {
                saveDamagesAuditSuccessful: false
            });
        case DOWNLOAD_DAMAGES_REPORT_SUCCESS_ACTION:
            var buff = new Buffer(action.payload, 'base64');
            var text = buff.toString('utf-8');

            // fileDownload(text, 'report.xlsx');

            return Object.assign({}, state, {
                downloadSuccess: true
            });

        case DOWNLOAD_DAMAGES_REPORT_FAILURE_ACTION:
            return Object.assign({}, state, {
                downloadSuccess: false
            });
        case DOWNLOAD_DAMAGES_AUDIT_REPORT_SUCCESS_ACTION:
            return Object.assign({}, state, {
                isAuditPDFDownloaded: true
            });
        case DOWNLOAD_DAMAGES_AUDIT_REPORT_FAILURE_ACTION:
            return Object.assign({}, state, {
                isAuditPDFDownloaded: false
            });


        case FETCH_DAMAGES_TRACKER_MASTER_SUCCESS_ACTION:
            state.tracker = action.payload.excess_master;
            state.fetchDamagesTrackerError = false;
            return state;

        case FETCH_DAMAGES_TRACKER_MASTER_FAILURE_ACTION:
            state.fetchDamagesTrackerError = true;
            return state;



        case SAVE_TRACKER_AUDIT_SUCCESS_ACTION:
            alert('Data Add Successfully')
            return Object.assign({}, state, {
                saveTrackerAuditSuccessful: true
            });

        case SAVE_TRACKER_AUDIT_FAILURE_ACTION:
            return Object.assign({}, state, {
                saveTrackerAuditSuccessful: false
            });




        case DOWNLOAD_DAMAGES_TRACKER_REPORT_SUCCESS_ACTION:
            var buff = new Buffer(action.payload, 'base64');
            var text = buff.toString('utf-8');

            // fileDownload(text, 'report.xlsx');

            return Object.assign({}, state, {
                downloadSuccess: true
            });

        case DOWNLOAD_DAMAGES_TRACKER_REPORT_FAILURE_ACTION:
            return Object.assign({}, state, {
                downloadSuccess: false
            });

        case UPLOAD_PVSHEET_SUCCESS:
            return Object.assign({}, state, {
                savedPvsheet: action.payload
            });

        case UPLOAD_PVSHEET_FAILURE:
            return Object.assign({}, state, {});
        default:
            return state    
    }
}

export default damages
