import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn } from "./../../util";
import {
  dismissLogs,
  initiateUpload,
  atmMasterCV,
} from "../../actions/control_panel_action";
import { Dimmer, Loader, Header } from "semantic-ui-react";
import { Button, Modal } from "semantic-ui-react";
import CommonForAllPannels from "./CommonForAllPannels.jsx";
import {
  getTextColor,
  getMainBackground,
  getMatchingTint,
  getFontColor,
} from "../../ThemeHandlers/theme";
var assignfile = {};
const AtmControlPanel = (props) => {
  useEffect(() => {
    if (!isLoggedIn(props.auth)) {
      props.history.push(`/login`);
    }
  }, [props.auth]);

  const atmfileupload = (file) => {
    console.log(file.target.files[0], "fileupload....");
    assignfile = file.target.files[0];
  };

  const atmuploadData = () => {
    console.log(assignfile, "uploading.....");
    props.initiateUpload(true);
    props.uploadatmMatserCV(assignfile);
  };

  let atmPannel = [
    {
      itemForSpan: " Upload CSV to add master Customers",
      itemInputChange: atmfileupload,
      itemClickButton: atmuploadData,
      itemContentForButton: "Upload Masters CSV",
    },
  ];

  const mastercloseLog = () => {
    props.dismissLogs(false);
  };

  return (
    <div
      style={{
        marginLeft: "2vw",
        marginTop: "7vh",
        fontWeight: "bold",
        fontFamily: "Arial, Helvetica, sans-serif",
      }}
    >
      <Dimmer active={props.controlPanel.uploadIncomplete}>
        <Loader size="massive">Uploading</Loader>
      </Dimmer>

      <CommonForAllPannels child={atmPannel} color={props.color} />

      {props.controlPanel.showLog && (
        <Modal close={mastercloseLog} open={props.controlPanel.showLog}>
          <Modal.Header>Upload Report</Modal.Header>
          <Modal.Content>
            <div
              dangerouslySetInnerHTML={{
                __html: props.controlPanel.log,
              }}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button color="black" onClick={mastercloseLog}>
              Dismiss
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    home: state.home,
    controlPanel: state.controlPanel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      uploadatmMatserCV: atmMasterCV,
      initiateUpload: initiateUpload,
      dismissLogs: dismissLogs,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AtmControlPanel)
);
