import {
    RESET_PASSWORD_SUCCESS_ACTION,
    RESET_PASSWORD_FAILURE_ACTION
} from "../actions/types";
import { toast } from "react-semantic-toasts";

const INIT_STATE = {};
export default function (state = INIT_STATE, action) {
    state = Object.assign({}, state, {});
    switch (action.type) {
        case RESET_PASSWORD_SUCCESS_ACTION:
           state.message = action.payload.message;

          alert(action.payload.message)
          
            state.resetError = false;
            return state;

        case RESET_PASSWORD_FAILURE_ACTION:
            state.resetError = true;
            return state;
    }
    return state;

}
