import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { isLoggedIn, isClient } from '../../util';
import { fetchYamahaDealersAction } from '../../actions/yamaha_actions';
import { Icon,Segment } from 'semantic-ui-react';
import Table from "../commonTables/Table.jsx"
import YamahaAudits from './YamahaAudits.jsx';

class YamahaDealers extends React.Component {
    static propTypes = {
        history: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            selectedDealer: '',
            isAuditsView: false
        };
    }

    searchFilter = (filter, row) => {
        var flatData = JSON.stringify(row._original);
        return flatData.toLowerCase().includes(filter.value.toLowerCase());
    }

    componentDidMount() {
        if (!isLoggedIn(this.props.auth)) {
            this.props.history.push(`/login`);
            return;
        }
        this.props.fetchYamahaDealersAction(this.props.template._id);
    }

    handleTableViewAuditClick = dealer => {
        this.setState({ selectedDealer: dealer, isAuditsView: true });
    };


    isClientProfile = () => {
        return isClient(this.props.auth);
    };

    onGoBack() {
        this.setState({ isAuditsView: false });
    }

    render() {
        var dealers = this.props.yamaha.dealers;
        var columns = [
            {
                Header: 'DealerCode',
                accessor: 'dealerCode',
                style: { cursor: 'pointer' },
                Cell: row => (
                    <AuditTableCell
                        row={row.original}
                        text={row.original.dealerCode}
                        onClick={this.handleTableViewAuditClick}
                    />
                )
            },
            {
                Header: 'DealerName',
                accessor: 'dealerName',
                style: { textAlign: 'center', cursor: 'pointer' },
                Cell: row => (
                    <AuditTableCell
                        row={row.original}
                        text={row.original.dealerName}
                        onClick={this.handleTableViewAuditClick}
                    />
                )
            },
            {
                Header: 'Location',
                accessor: 'dealerNameDealer / branch location',
                style: { textAlign: 'center', cursor: 'pointer' },
                Cell: row => (
                    <AuditTableCell
                        row={row.original}
                        text={row.original['Dealer / branch location']}
                        onClick={this.handleTableViewAuditClick}
                    />
                )
            }
        ];

        return (
            <div style={{ flexGrow: 1, display: 'flex', flexFlow: 'column' }}>
                 <Segment onClick={this.props.onClose} style={{
                                color: '#808080',
                                float: 'right',
                                cursor: 'pointer',
                                marginTop: -7,
                                position: 'absolute',
                                right: 58
                            }}>
                        <Icon name="arrow" className="left large" color="brown" />
                 </Segment>
                {!this.state.isAuditsView && (
                    <div>
                        <h1 style={{ paddingLeft: 30, flex: '0 0 30px' }}>Dealers</h1>
                        <div style={{ display: 'flex', flexGrow: 1, flexFlow: 'column' }}>
                            <div>
                                <Table
                                    defaultFilterMethod={this.searchFilter}
                                    templates={dealers}
                                    columns={columns}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {this.state.isAuditsView && (
                    <YamahaAudits templateId={this.props.template._id}
                        dealerId={this.state.selectedDealer._id}
                        onGoBack={this.onGoBack.bind(this)}></YamahaAudits>
                )}
            </div>
        );
    }
}

function AuditTableCell(props) {
    function onClick() {
        props.onClick(props.row);
    }
    return (
        <div style={props.style} onClick={onClick}>
            {props.text}
        </div>
    );
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        yamaha: state.yamaha
    };
};
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            fetchYamahaDealersAction: fetchYamahaDealersAction
        },
        dispatch
    );
};


// YamahaDealers.PropTypes = {
//     dealers: PropTypes.array
// };

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(YamahaDealers)
);
