import React, { useState, useEffect } from "react";
import { fetchDashboardDisplayAudit } from "../../../actions/cannon_action";
import { Tab } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ProductAudits from './ProductAudits.jsx'
import "./pan.css";

const StoreTypes = (props) => {
  const [panes, setPanes] = useState([]);
  const [panLoading, setPanLoading] = useState(false);
  useEffect(() => {
    if (
        props.storeType === "Planogram" &&
        props.cannon.dashboardPlanogramData !== undefined
      ) {
        getDisplayAudits("",props.cannon.dashboardPlanogramData[0].display)
      } else if (
        props.storeType === "Store Hygiene" &&
        props.cannon.dashboardHygieneData !== undefined
      ) {
        getDisplayAudits("",props.cannon.dashboardHygieneData[0].display)
      } else if (
        props.storeType === "Appliances Working Condition" &&
        props.cannon.dashboardAppliancesData !== undefined
      ) {
        getDisplayAudits("",props.cannon.dashboardAppliancesData[0].display)
      }
  }, []);
  useEffect(() => {
    if (
      props.storeType === "Planogram" &&
      props.cannon.dashboardPlanogramData !== undefined
    ) {
      let getPlanogram = getList(props.cannon.dashboardPlanogramData);

      setPanes(getPlanogram);
    } else if (
      props.storeType === "Store Hygiene" &&
      props.cannon.dashboardHygieneData !== undefined
    ) {
      let getHygiene = getList(props.cannon.dashboardHygieneData);
      setPanes(getHygiene);
    } else if (
      props.storeType === "Appliances" &&
      props.cannon.dashboardAppliancesData !== undefined
    ) {
      let getAppliances = getList(props.cannon.dashboardAppliancesData);
      setPanes(getAppliances);
    }
  }, [props.storeType, props.cannon]);

  const getList = (type) => {
    let Data = [];
    type.forEach((data) => {
      if (Data.indexOf(data) === -1) {
        Data.push({
          menuItem: data.display.toUpperCase(),
          render: () => (
            <Tab.Pane loading={panLoading}>
                <ProductAudits storeType={props.storeType}/>
            </Tab.Pane>
          ),
        });
      }
    });
    return Data;
  };
  const getDisplayAudits = (event,preCategory) => {
    
    let reqObj = {
      storeId: props.storeId,
      productName: event !== "" && event.target !== undefined ? event.target.text : preCategory,
      auditType: props.storeType,
    };
    props.fetchDashboardDisplayAudit({
      displayDetails: reqObj,
      onSuccess: () => {},
      onFailure: () => {},
    });
  };
  return (
    <Tab
    //   defaultActiveIndex={0}
      menu={{
        color: "grey",
        inverted: true,
        fluid: true,
        vertical: true,
        tabular: true,
      }}
      panes={panes}
      onTabChange={getDisplayAudits}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    //reducer props to state
    cannon: state.cannon,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchDashboardDisplayAudit }, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StoreTypes)
);
