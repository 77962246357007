import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn } from "../../util";
import moment from "moment";
import { Segment } from "semantic-ui-react";
import { getMainBackground, getMatchingTint } from "../../ThemeHandlers/theme";

class PandGAuditsThumbnail extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillReceiveProps(nextProps) {}

  componentWillMount() {}

  componentDidMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
      return false;
    }
    return true;
  }

  handleListViewAuditClick = (audit) => {
    this.props.handleListViewAuditClick({ audit });
  };
  render() {
    const selectedAudit = this.props.pandg.selectedPandGAudit;

    return (
      <div>
        <div style={{ flex: "0 0 300px", background: "#fafafa", padding: 15 }}>
          {this.props.pandgaudits.map((audit, i) => {
            return (
              <AuditCell
                audit={audit}
                key={i}
                selected={selectedAudit._id === audit._id}
                onClick={this.handleListViewAuditClick}
                color={this.props.color}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

function AuditCell(props) {
  var audit = props.audit;
  var bgStyle = {
    width: 5,
  };

  if (props.selected) {
    bgStyle.background = getMainBackground(props.color);
  }

  return (
    <Segment
      style={{
        display: "flex",
        padding: "0px",
        backgroundColor: getMatchingTint(props.color),
      }}
      onClick={() => {
        props.onClick(audit);
      }}
    >
      <div style={bgStyle} />
      <div style={{ padding: "1em", lineHeight: 1.7, wordBreak: "break-word" }}>
        {audit.auditName} ({audit.chassis || "HTH"})
        <br />
        by {audit.auditorName}
        <br />
        {moment(audit.createdAt).format("llll")}
      </div>
    </Segment>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    pandg: state.pandg,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PandGAuditsThumbnail)
);
