import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "react-datepicker/dist/react-datepicker.css";
import CheckboxTable from "../commonTables/CheckboxView.jsx";
import {
  Grid,
} from "semantic-ui-react";
import ModalView from "../commonViews/ModalView.jsx";
import IconPicker from "../commonViews/IconPicker.jsx";
import ButtonHandler from "../commonViews/ButtonHandler.jsx";
import {
  fetchHersheysStoresAction,
  filterHersheysStoresAction,
  AssignAuditsAction,
  fetchAuditorsDetailAction,
  HerysheysReAssignAuditsAction
} from "../../actions/hersheys_actions";
import { fetchUserDetailAction } from "../../actions/user_detail_action";
import HersheysAuditsDetail from "./HersheysAuditsDetail.jsx";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import TableView from "../commonViews/TableView.jsx";
import {
  getTextColor,
  getMainBackground,

  getFontColor,
} from "../../ThemeHandlers/theme";
import Metrics from "../commonViews/Metrics.jsx";
import ConstantLabel from "../commonViews/ConstantLabel.jsx";
import DropDownPicker from "../commonViews/DropDownPicker.jsx";
import { template } from "lodash";

// const CheckboxTable = selectTable(ReactTable);

export class HersheysAudits extends Component {
  constructor(props) {
    super(props);
    this.checkboxTable = null;

    this.setHersheysCheckboxRef = (element) => {
      this.checkboxTable = element;
    };

    this.state = {
      CustomerTableView: true,
      selectedCustomer: {},
      auditid: "",
      customerSearch: "",
      filteredData: [],
      modalOpen: false,
      selectedRegion: "",
      selectedState: "",
      selectedCity: "",
      selectedStatus: "",
      selection: [],
      userId: [],
      selectAll: false,
      hershysCustomers: [],
      hershysFilters: [],
      userDetails: [],
      assignData: [],
      isLoading: false,
    };
  }
  componentDidMount() {
    this.setState({ isLoading: true });
    this.props.fetchHersheysStoresAction({
      id: this.props.selectedTemplate._id,
      onSuccess: () => {
        this.setState({ isLoading: false });
      },
      onFailure: () => {
        this.setState({ isLoading: false });
      },
    });
    this.props.fetchAuditorsDetailAction();
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.hersheys.customers !== nextProps.hersheys.customers) {
      this.setState({
        selectedRegion: "All",
        selectedCity: "All",
        selectedStatus: "All",
        filteredData: nextProps.hersheys.customers,
      });
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.hersheys.customers !== undefined) {
      if (
        this.props.hersheys.filters === undefined &&
        this.props.hersheys.customers !== prevProps.hersheys.customers
      ) {
        this.setState({
          hershysCustomers: this.props.hersheys.customers,
        });
      } else if (
        this.props.hersheys?.filters !== undefined &&
        this.props.hersheys.filters !== prevProps.hersheys.filters
      ) {
        this.setState({
          hershysCustomers: this.props.hersheys.filters,
        });
      }
      // else if(this.props.hersheys?.filters !== undefined && this.props.hersheys.filters.length === 0){
      //   this.setState({
      //     hershysCustomers:this.props.hersheys.customers
      //   })
      // }
    }
    //   if(this.props.hersheys.filters !== undefined){
    //     if(this.props.hersheys.filters !== prevProps.hersheys.filters){
    //       this.setState({
    //         hershysFilters:this.props.hersheys.filters
    //       })
    //   }

    // }
    if (this.props.hersheys.userDetails !== prevProps.hersheys.userDetails) {
      this.setState({
        userDetails: this.props.hersheys.userDetails,
      });
    }
  }
  auditIdFromHersheysAudit = (auditId) => {
    this.setState({
      auditid: auditId,
    });
  };

  hersheyshandleTableViewAuditClick = (customer) => {
    this.setState({ selectedCustomer: customer, CustomerTableView: false });
    // this.setState({ modalOpen: true })
  };
  onGotoCustomerView = () => {
    this.setState({
      CustomerTableView: true,
    });
  };
  // searchFilter = (filter, row) => {
  //   var flatData = JSON.stringify(row._original);
  //   return flatData.toLowerCase().includes(filter.value.toLowerCase());
  // };

  searchFilter = (filter, row) => {
    let value = filter.value && filter.value.toLowerCase();

    if (filter.id) {
      var flatedData = JSON.stringify(
        row._original[filter.id] ? row._original[filter.id] : ""
      );
      return flatedData.toLowerCase().includes(value);
    }
  };

  addNewUser = () => {
    if (this.state.assignData.length !== 0) {
      this.setState({ modalOpen: true });

    } else {
      alert('Please Select the Stores to Assign')
    }
  };

  reAssignAudit = () =>{
    this.setState({ isLoading: true });
    let assignData = {
      master_ids:this.state.assignData,
      templateId:this.props.selectedTemplate._id
    }
    this.props.HerysheysReAssignAuditsAction({assignData,
      onSuccess:()=>{
        this.setState({ isLoading: false });
      },
      onFailure:()=>{
        this.setState({ isLoading: false });
      }
    })
    console.log(this.state.assignData,'assignData')
  }


  closeEditUser = () => {
    this.setState({ modalOpen: false });
  };

  userAssign = () => {
    this.setState({
      modalOpen: false,
      assignData: [],
    });
  };

  onRegionChange = (value) => {
    let region = value;
    this.setState({
      selectedRegion: value,
      selectedCity: "",
      selectedStatus: "",
    });
  };
  onStateComboChange = (value) => {
    this.setState({
      selectedState: value,
    });
  };
  onCityComboChange = (value) => {
    this.setState({
      selectedCity: value,
    });
  };

  onStatusComboChange = (value) => {
    this.setState({
      selectedStatus: value,
    });
  };
  onViwDetailsClick = () => {
    let region = this.state.selectedRegion;
    let city = this.state.selectedCity;
    let status = this.state.selectedStatus;
    let value = this.props.selectedTemplate._id;
    this.setState({ isLoading: true });
    this.props.filterHersheysStoresAction({
      value,
      region,
      city,
      status,
      onSuccess: (result) => {
        this.setState({ isLoading: false });
      },
      onFailure: (result) => {
        this.setState({ isLoading: false });
      },
    });
  };

  removeDuplicates = (e) => {
    let newArray = [];

    // Declare an empty object
    let UniqueObject = {};

    // Loop for the array elements
    for (let i in e) {
      // Extract the title
      let objTitle = e[i]["text"];

      // Use the title as the index
      UniqueObject[objTitle] = e[i];
    }

    // Loop to push unique object into array
    for (let i in UniqueObject) {
      newArray.push(UniqueObject[i]);
    }

    return newArray;
  };

  sendedData = (rowData, row) => {
    let ids = [];

    if (rowData.length !== 0) {
      rowData.forEach((data) => {
        ids.push(data._id);
      });
    }

    this.setState({
      assignData: ids,
    });
  };

  userData = (rowData, checkedValue) => {
    var selectedData = this.state.assignData;
    let formAssignData = {
      userId: rowData._id,
      storeId: selectedData,
    };
    this.props.AssignAuditsAction({
      assignData: formAssignData,
      onSuccess: () => {
        this.setState({ isLoading: true });
        this.props.fetchHersheysStoresAction({
          id: this.props.selectedTemplate._id,
          onSuccess: () => {
            this.setState({ isLoading: false, assignData: [] });
          },
          onFailure: () => {
            this.setState({ isLoading: false, assignData: [] });
          },
        });

        NotificationManager.success(
          "Audits Assigned Successfully",
          "SUCCESS MESSAGE",
          3000,
          true
        );
      },
      onFailure: () => {
        NotificationManager.error(
          "Audit Not Assigned",
          "ERROR MESSAGE",
          3000,
          true
        );
      },
    });
  };

  render() {
    var customers;
    if (this.state.hershysCustomers.length !== 0) {
      customers = this.state.hershysCustomers;
    } else {
      customers = this.state.hershysFilters;
    }

    var unique;
    var regionData = [];
    // var stateData = []
    var cityData = [];
    let uniqueCity;
    let status = [
      {
        text: "Pending",
        value: "Pending",
      },
      {
        text: "Assigned",
        value: "Assigned",
      },
      {
        text: "In Progress",
        value: "In Progress",
      },
      {
        text: "Completed",
        value: "Completed",
      },
      {
        text: "All",
        value: "All",
      },
    ];

    var UserList =
      this.state.userDetails.length !== 0 ? this.state.userDetails : [];

    if (this.props.hersheys.customers != undefined) {
      var regionList = this.props.hersheys.customers;

      regionList.map((e) => {
        regionData.push({ text: e.zone, value: e.zone });
        // stateData.push({text:e.state,value:e.state})
        cityData.push({ text: e.city, value: e.city });
      });
    }
    if (regionData.length > 0) {
      unique = this.removeDuplicates(regionData);
    }
    // if(stateData.length>0){
    //   uniqueState = this.removeDuplicates(stateData)

    // }
    if (cityData.length > 0) {
      uniqueCity = this.removeDuplicates(cityData);
    }

    const newHershysCol = [
      {
        title: "Outlet Id",
        field: "outletId",
        render: (rowData) => (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.hersheyshandleTableViewAuditClick(rowData);
            }}
          >
            {rowData.outletId}
          </span>
        ),
        // customFilterAndSearch: (term, rowData) => rowData.outletId == term,
      },
      {
        title: "Outlet Name",
        field: "",
        render: (rowData) => (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.hersheyshandleTableViewAuditClick(rowData);
            }}
          >
            {rowData.outletName}
          </span>
        ),
        customFilterAndSearch: (term, rowData) =>
          rowData.outletName.toLowerCase().indexOf(term.toLowerCase()) > -1,
      },
      {
        title: "Distributor Name",
        field: "",
        render: (rowData) => (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.hersheyshandleTableViewAuditClick(rowData);
            }}
          >
            {rowData.dbName}
          </span>
        ),
        customFilterAndSearch: (term, rowData) =>
          rowData.dbName.toLowerCase().indexOf(term.toLowerCase()) >
          -1,
      },

      {
        title: "TSI Name",
        field: "",
        render: (rowData) => (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.hersheyshandleTableViewAuditClick(rowData);
            }}
          >
            {rowData.tsiName}
          </span>
        ),
        customFilterAndSearch: (term, rowData) =>
          rowData.tsiName.toLowerCase().indexOf(term.toLowerCase()) > -1,
      },
      {
        title: "TSI Beat Name",
        field: "tsiBeatName",
        render: (rowData) => (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.hersheyshandleTableViewAuditClick(rowData);
            }}
          >
            {rowData.tsiBeatName}
          </span>
        ),
        customFilterAndSearch: (term, rowData) =>
          rowData.tsiBeatName.toLowerCase().indexOf(term.toLowerCase()) > -1,
      },
      {
        title: "Status",
        field: "status",
        filtering: true,
        render: (rowData) => (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.hersheyshandleTableViewAuditClick(rowData);
            }}
          >
            {rowData.status}
          </span>
        ),
        // customFilterAndSearch: (term, rowData) =>
        //   rowData.status.toLowerCase().indexOf(term.toLowerCase()) > -1,
      },
      {
        title: "Assignee",
        field: "userName",
        render: (rowData) => (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.hersheyshandleTableViewAuditClick(rowData);
            }}
          >
            {rowData.userName}
          </span>
        ),
        // customFilterAndSearch: (term, rowData) =>
        // rowData.userName.toLowerCase().indexOf(term.toLowerCase()) > -1,
      },
      {
        title: "Address",
        field: "outletAddress",
        render: (rowData) => (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.hersheyshandleTableViewAuditClick(rowData);
            }}
          >
            {rowData.outletAddress}
          </span>
        ),
        // customFilterAndSearch: (term, rowData) =>
        //   rowData.outletAddress.toLowerCase().indexOf(term.toLowerCase()) > -1,
      },
    ];

    const column = [
      {
        Header: "Email Id's",
        accessor: "email",
        style: { textAlign: "left", cursor: "pointer" },
        Cell: ({ row: { original } }) => {
          return <span>{original.email}</span>;
        },
      },
    ];
    return (
      <div>
        <NotificationContainer />
        {this.state.CustomerTableView && (
          <div>
            <div style={{ display: "inline-block", marginBottom: 20 }}>
              <IconPicker
                name="arrow left"
                click={this.props.onClose}
                styles={{
                  color: getTextColor(this.props.color),
                  float: "right",
                  cursor: "pointer",
                  marginTop: 35,
                  position: "absolute",
                  right: 20,
                }}
              />
              <h1
                style={{
                  display: "inline-block",
                  color: getTextColor(this.props.color),
                }}
              >
                Hersheys Stores
              </h1>
            </div>

            <Grid>
              <Grid.Row columns={6}>
                <Grid.Column>
                  <DropDownPicker
                    placeholder={"Select Region"}
                    styles={{ zIndex: 100 }}
                    value={this.state.selectedRegion}
                    options={unique}
                    onChangeDropDown={(data) => {
                      this.onRegionChange(data);
                    }}
                  />

                </Grid.Column>
                <Grid.Column>
                  <DropDownPicker
                    placeholder={"Select City"}
                    styles={{ zIndex: 100 }}
                    value={this.state.selectedCity}
                    options={uniqueCity}
                    onChangeDropDown={(data) => {
                      this.onCityComboChange(data);
                    }}
                  />

                </Grid.Column>
                <Grid.Column>
                  <DropDownPicker
                    placeholder={"Select Status"}
                    styles={{ zIndex: 100 }}
                    value={this.state.selectedStatus}
                    options={status}
                    onChangeDropDown={(data) => {
                      this.onStatusComboChange(data);
                    }}
                  />

                </Grid.Column>
                <Grid.Column>
                  <ButtonHandler
                    title={'View Details'}
                    size={'medium'}
                    click={this.onViwDetailsClick}
                    styles={{
                      cursor: "pointer",
                      width: "100%",
                      backgroundColor: getMainBackground(this.props.color),
                      color: getFontColor(this.props.color),
                    }}
                  />

                </Grid.Column>
                <Grid.Column>
                  <ButtonHandler
                    title={'Assign Audits'}
                    size={'Medium'}
                    click={this.addNewUser}
                    styles={{
                      cursor: "pointer",
                      width: "100%",
                      backgroundColor: getMainBackground(this.props.color),
                      color: getFontColor(this.props.color),
                    }}
                    isIcon={true}
                    iconName={'add'}
                    disabled={this.state.assignData.length === 0}
                  />


                </Grid.Column>
                <Grid.Column>
                  <ButtonHandler
                    title={'Re-Assign Audits'}
                    size={'Medium'}
                    click={this.reAssignAudit}
                    styles={{
                      cursor: "pointer",
                      width: "100%",
                      backgroundColor: getMainBackground(this.props.color),
                      color: getFontColor(this.props.color),
                    }}
                    isIcon={true}
                    iconName={'add'}
                    disabled={this.state.assignData.length === 0}
                  />


                </Grid.Column>
              </Grid.Row>
            </Grid>
            <div style={{ marginTop: 10 }}>
              {this.props.hersheys.metrics !== undefined &&
                Object.keys(this.props.hersheys.metrics).length !== 0 &&
                this.props.hersheys.metrics && (<Metrics metrics={this.props.hersheys.metrics} color={this.props.color} />

                )}
            </div>
          </div>
        )}
        {this.state.CustomerTableView && (
          <div
            style={{
              display: "flex",
              flexGrow: 1,
              flexFlow: "column",
              marginTop: 20,

            }}
          >
            <TableView
              selection={true}
              data={customers.length !== 0 ? customers : []}
              columns={newHershysCol}
              load={this.state.isLoading}
              onSelectionChange={(rows, row) => this.sendedData(rows, row)}
            />

          </div>
        )}


        <ModalView
          open={this.state.modalOpen}
          close={this.closeEditUser}
          size={'small'}
          content={
            <CheckboxTable
              data={UserList}
              columns={column}
              sendedData={this.userData}
            />
          }
          showSave={true}
          saveClick={this.userAssign}
        />

        {!this.state.CustomerTableView && (
          <div style={{ flexGrow: 1, display: "flex" }}>
            <HersheysAuditsDetail
              template={this.props.template}
              customer={this.state.selectedCustomer}
              onClose={this.onGotoCustomerView}
              auditId={this.auditIdFromHersheysAudit}
              color={this.props.color}
            />
          </div>
        )}
      </div>
    );
  }
}

function AuditTableCell(props) {
  function onClick() {
    props.onClick(props.row);
  }
  return (
    <div style={props.style} onClick={onClick}>
      {props.text}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    hersheys: state.hersheys,
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchHersheysStoresAction: fetchHersheysStoresAction,
      filterHersheysStoresAction: filterHersheysStoresAction,
      fetchUserDetailAction: fetchUserDetailAction,
      AssignAuditsAction: AssignAuditsAction,
      fetchAuditorsDetailAction: fetchAuditorsDetailAction,HerysheysReAssignAuditsAction
    },
    dispatch
  );
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HersheysAudits)
);
