import {
    SELECT_MENU_ACTION
} from '../actions/types';

const INTIAL_STATE = {};

export default function (state = INTIAL_STATE,action) {
    if (action.type === SELECT_MENU_ACTION) {
            return Object.assign({}, state, {
                selectedMenu: action.payload
            });
    }
    return state;
}