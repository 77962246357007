import axios from "axios";
import * as FileSaver from "file-saver";
import * as types from "./types";

export const fetchBikanoTemplatesAction = ({ onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/bikanoDamages/get_template")
      .then(function (response) {
        dispatch({
          type: types.FETCH_BIKANO_TEMPLATE_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response.data);
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_BIKANO_TEMPLATE_FAILURE_ACTION,
          payload: error,
        });
        onFailure(error);
      });
  };
};

export const fetchBikanoStoreAction = ({ id, onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL +
          "/auditor/v1/bikanoDamages/get_master_data?templateId=" +
          id
      )
      .then(function (response) {
        dispatch({
          type: types.FETCH_BIKANO_STORE_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response.data);
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_BIKANO_STORE_FAILURE_ACTION,
          payload: error,
        });
        onFailure(error);
      });
  };
};

// Getting List of auditors by orgName for audit assign //
export const getAuditorsList = ({ orgName, onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL + `/auditor/v1/getAuditorsForAssign?orgName=${orgName}`
      )
      .then(function (response) {
        // dispatch({
        //   type: types.FETCH_BIKANO_AUDITORLIST_SUCCESS_ACTION,
        //   payload: response.data,
        // });
        onSuccess(response.data.data);
      })
      .catch(function (error) {
        // dispatch({
        //   type: types.FETCH_BIKANO_AUDITORLIST_FAILURE_ACTION,
        //   payload: error,
        // });
        onFailure(error);
      });
  };
};

export const filterHersheysStoresAction = ({
  value,
  region,
  city,
  status,
  onSuccess,
  onFailure,
}) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL +
          `/auditor/v1/hersheys/filterAudits?templateId=${value}&region=${region}&city=${city}&status=${status}`
      )
      .then(function (response) {
        dispatch({
          type: types.FILTER_HERSHEYS_STORE_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(false);
      })
      .catch(function (error) {
        dispatch({
          type: types.FILTER_HERSHEYS_STORE_FAILURE_ACTION,
          payload: error,
        });
        onFailure(true);
      });
  };
};

// Assigning Audit ( PUT - Method )
export const bikanoDamagesAssignAudit = ({
  assignData,
  onSuccess,
  onFailure,
}) => {
  return function (dispatch) {
    axios
      .put(types.API_URL + "/auditor/v1/bikanoDamages/assignAudit", assignData)
      .then(function (response) {
        dispatch({
          type: types.ASSIGN_BIKANO_AUDITS_DETAILS_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response);
      })
      .catch(function (error) {
        dispatch({
          type: types.ASSIGN_BIKANO_AUDITS_FAILIURE_ACTION,
          payload: error,
        });
        onFailure(error);
      });
  };
};

// Get Store Audit Details Action
export const fetchBikanoAuditDetails = ({
  masterId,
  templateId,
  onSuccess,
  onFailure,
}) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL +
          `auditor/v1/bikanoDamages/get_audit?masterId=${masterId}&templateId=${templateId}`
      )
      .then(function (response) {
        dispatch({
          type: types.FETCH_BIKANO_AUDIT_SUCCESS_ACTION,
          payload: response.data.data[0],
        });
        onSuccess(response.data.data[0]);
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_BIKANO_AUDIT_FAILURE_ACTION,
          payload: error,
        });
        onFailure(error);
      });
  };
};

// Save Audit Action
export const saveBikanoDamagesAuditAction = ({
  audit,
  onSuccess,
  onFailure,
}) => {
  return function (dispatch) {
    axios
      .put(types.API_URL + "auditor/v1/bikanoDamages/web/save_audit", audit)
      .then(function (response) {
        dispatch({
          type: types.SAVE_BIKANO_AUDIT_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response);
      })
      .catch(function (error) {
        dispatch({
          type: types.SAVE_BIKANO_AUDIT_FAILURE_ACTION,
          payload: error,
        });
        onFailure(error);
      });
  };
};

// Download Audit Details report Action
export const downloadBikanoDamagesAuditReportAction = ({
  auditId,
  storeName,
  storeCode,
  onSuccess,
  onFailure,
}) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL +
          `auditor/v1/bikanoDamages/generateReport?auditId=${auditId}`,
        {
          responseType: "blob",
        }
      )
      .then(function (response) {
        console.log(response);
        FileSaver.saveAs(response.data, `${storeName + "-" + storeCode}.pdf`);
        dispatch({
          type: types.DOWNLOAD_BIKANO_REPORT_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        // console.log(error);
        dispatch({
          type: types.DOWNLOAD_BIKANO_REPORT_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

// Download Final  report Excel
export const downloadFinalReportExcelAction = ({
  templateId,
  auditName,
  onSuccess,
  onFailure,
}) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL +
          `/auditor/v1/bikanoDamages/generateConsolidatedReport?templateId=${templateId}`,
        {
          responseType: "blob",
        }
      )
      .then(function (response) {
        console.log(response);
        FileSaver.saveAs(response.data, `${auditName}.xlsx`);
        // dispatch({
        //   type: types.DOWNLOAD_BIKANO_REPORT_SUCCESS_ACTION,
        //   payload: response.data,
        // });
        onSuccess(response);
      })
      .catch(function (error) {
        // console.log(error);
        // dispatch({
        //   type: types.DOWNLOAD_BIKANO_REPORT_FAILURE_ACTION,
        //   payload: error,
        // });
        onFailure(error);
      });
  };
};

export const downloadHersheysReportAction = ({
  templateId,
  onSuccess,
  onFailure,
}) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL + "/auditor/v1/report/hersheys?templateId=" + templateId,
        {
          responseType: "blob",
        }
      )
      .then(function (response) {
        onSuccess();
        FileSaver.saveAs(response.data, "HersheysAuditReport.xlsx");
        dispatch({
          type: types.DOWNLOAD_HERSHEYS_REPORT_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        console.log(error);
        dispatch({
          type: types.DOWNLOAD_HERSHEYS_REPORT_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

// export const getAuditReports = ({
//   templateId,
//   startDate,
//   endDate,
//   email,
//   onSuccess,
//   onFailure,
// }) => {
//   return function (dispatch) {
//     axios
//       .get(
//         types.API_URL +
//           `/auditor/v1/hersheys/bulkReportDownload?templateId=${templateId}&startDate=${startDate}&endDate=${endDate}&email=${email}`
//       )
//       .then((response) => {
//         // FileSaver.saveAs(response.data, "hersheys.zip");
//         dispatch({
//           type: types.get_audit_reports_success,
//           payload: response.data,
//         });
//         onSuccess();
//       })
//       .catch((error) => {
//         dispatch({
//           type: types.get_audit_reports_failure,
//           payload: error,
//         });
//         onFailure();
//       });
//   };
// };

// export const getAuditCount = (templateId, startDate, endDate) => {
//   return function (dispatch) {
//     axios
//       .get(
//         types.API_URL +
//           `/auditor/v1/hersheys/auditsCount?templateId=${templateId}&startDate=${startDate}&endDate=${endDate}`
//       )
//       .then((response) => {
//         dispatch({
//           type: types.get_audit_count_success,
//           payload: response.data,
//         });
//       })
//       .catch((error) => {
//         dispatch({
//           type: types.get_audit_count_failure,
//           payload: error,
//         });
//       });
//   };
// };

// export const fetchHersheysVisibilityTemplatesAction = ({
//   onSuccess,
//   onFailure,
// }) => {
//   return function (dispatch) {
//     axios
//       .get(types.API_URL + "/auditor/v1/hersheysVisibility/getTemplates")
//       .then(function (response) {
//         dispatch({
//           type: types.FETCH_HERSHEYS_TEMPLATE_SUCCESS_ACTION,
//           payload: response.data,
//         });
//         onSuccess();
//       })
//       .catch(function (error) {
//         dispatch({
//           type: types.FETCH_HERSHEYS_TEMPLATE_FAILURE_ACTION,
//           payload: error,
//         });
//         onFailure();
//       });
//   };
// };

// export const fetchHersheysVisibilityStoresAction = ({
//   id,
//   onSuccess,
//   onFailure,
// }) => {
//   return function (dispatch) {
//     axios
//       .get(
//         types.API_URL +
//           "/auditor/v1/hersheysVisibility/getMasterData?templateId=" +
//           id
//       )
//       .then(function (response) {
//         dispatch({
//           type: types.FETCH_HERSHEYS_STORE_SUCCESS_ACTION,
//           payload: response.data,
//         });
//         onSuccess();
//       })
//       .catch(function (error) {
//         dispatch({
//           type: types.FETCH_HERSHEYS_STORE_FAILURE_ACTION,
//           payload: error,
//         });
//         onFailure();
//       });
//   };
// };

// export const HerysheysVisibilityAssignAuditsAction = ({
//   assignData,
//   onSuccess,
//   onFailure,
// }) => {
//   return function (dispatch) {
//     axios
//       .post(
//         types.API_URL + "/auditor/v1/hersheysVisibility/assignAudits",
//         assignData
//       )
//       .then(function (response) {
//         dispatch({
//           type: types.ASSIGN_HERSHEYS_AUDITS_DETAILS_SUCCESS_ACTION,
//           payload: response.data,
//         });
//         onSuccess();
//       })
//       .catch(function (error) {
//         dispatch({
//           type: types.ASSIGN_HERSHEYS_AUDITS_FAILIURE_ACTION,
//           payload: error,
//         });
//         onFailure();
//       });
//   };
// };

// export const fetchHersheysVisibilityAuditAction = (id) => {
//   return function (dispatch) {
//     axios
//       .get(
//         types.API_URL + "/auditor/v1/hersheysVisibility/getAudit?storeId=" + id
//       )
//       .then(function (response) {
//         dispatch({
//           type: types.FETCH_HERSHEYS_AUDIT_SUCCESS_ACTION,
//           payload: response.data,
//         });
//       })
//       .catch(function (error) {
//         dispatch({
//           type: types.FETCH_HERSHEYS_AUDIT_FAILURE_ACTION,
//           payload: error,
//         });
//       });
//   };
// };

// export const downloadHersheysVisibilityAuditReportAction = (auditId) => {
//   return function (dispatch) {
//     axios
//       .get(
//         types.API_URL +
//           "/auditor/v1/hersheysVisibility/pdfReport?auditId=" +
//           auditId,
//         {
//           responseType: "blob",
//         }
//       )
//       .then(function (response) {
//         FileSaver.saveAs(response.data, "Audit-Report.pdf");
//         dispatch({
//           type: types.DOWNLOAD_HERSHEYS_AUDIT_REPORT_SUCCESS_ACTION,
//           payload: response.data,
//         });
//       })
//       .catch(function (error) {
//         console.log(error);
//         dispatch({
//           type: types.DOWNLOAD_HERSHEYS_AUDIT_REPORT_FAILURE_ACTION,
//           payload: error,
//         });
//       });
//   };
// };

// export const downloadHersheysVisibilityReportAction = ({
//   templateId,
//   onSuccess,
//   onFailure,
// }) => {
//   return function (dispatch) {
//     axios
//       .get(
//         types.API_URL +
//           "/auditor/v1/hersheysVisibility/report?templateId=" +
//           templateId,
//         {
//           responseType: "blob",
//         }
//       )
//       .then(function (response) {
//         onSuccess();
//         FileSaver.saveAs(response.data, "HersheysVisibilityAuditReport.xlsx");
//         dispatch({
//           type: types.DOWNLOAD_HERSHEYS_REPORT_SUCCESS_ACTION,
//           payload: response.data,
//         });
//       })
//       .catch(function (error) {
//         console.log(error);
//         dispatch({
//           type: types.DOWNLOAD_HERSHEYS_REPORT_FAILURE_ACTION,
//           payload: error,
//         });
//         onFailure();
//       });
//   };
// };
