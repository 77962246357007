import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  loginAction,
  forgotPassword,
  modalActionforopen,
  ModalOpenAction,
  ModalCloseAction,
} from "../actions/index";
import { isLoggedIn } from "../util";
import {
  Form,
  Grid,
  Message,
  Input,
  Modal,
} from "semantic-ui-react";
import IconPicker from "./commonViews/IconPicker";
import ButtonHandler from "./commonViews/ButtonHandler";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { toast, SemanticToastContainer } from "react-semantic-toasts";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "../styles/main.css";
import AppIcon from "../images/logo.png";
import {
  getTextColor,
  getMainBackground,
  getMatchingTint,
  getFontColor,
} from "../ThemeHandlers/theme";

class Login extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      email: false,
      EmailId: "",
      forgotModal: false,
      color: "blue",
    };
  }
  componentDidMount() {
    if (localStorage.getItem("themeColor") !== null) {
      this.setState({ color: localStorage.getItem("themeColor") });
    }
  }

  handleUsernameChange = (e) => {
    this.setState({ username: e.target.value });
  };

  handlePasswordChange = (e) => {
    this.setState({ password: e.target.value });
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.authenticate();
    }
  };

  authenticate = () => {
    this.props.login({
      username: this.state.username,
      password: this.state.password,
      onSuccess: (res) => { },
      onFailure: (err) => {
        NotificationManager.error(
          "Please check username or password",
          "Failed to Login",

          3000,
          true
        );
        // console.log(err, "err");
      },
    });
  };

  SendEmail = () => {
    console.log(this.state.EmailId, "emailId");
    let email = this.state.EmailId;
    let Email = {
      email,
    };

    this.props.forgotpassword({
      email: Email,
      onSuccess: (result) => {
        this.setState({ forgotModal: result });
      },
      onFailure: (result) => {
        this.setState({ forgotModal: result });
      },
    });
  };
  Email = () => {
    this.setState({
      forgotModal: true,
    });
  };
  Close = () => {
    this.setState({
      forgotModal: false,
    });
  };

  Closed = () => {
    console.log("closed inside modal");
  };

  handleEmail = (e) => {
    this.setState({
      EmailId: e.target.value,
    });
  };
  componentWillMount() {
    if (isLoggedIn(this.props.auth)) {
      this.props.history.push(`/home`);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (isLoggedIn(nextProps.auth)) {
      this.props.history.push(`/home`);
      return false;
    }
    return true;
  }

  render() {
    var loginBoxStyle = {
      borderRadius: 6,
      WebkitBoxShadow: "10px 11px 81px 3px rgba(191,191,191,1)",
      MozBoxShadow: "10px 11px 81px 3px rgba(191,191,191,1)",
      boxShadow: "10px 11px 81px 3px rgba(191,191,191,1)",
      width: "100%",
      height: "100%",
    };
    return (
      <Grid
        textAlign="center"
        style={{
          height: "100vh",
          backgroundColor: getMainBackground(this.state.color),
        }}
        verticalAlign="middle"
      >
        <Grid.Column
          style={{
            maxWidth: 500,
            borderRadius: "15px",
          }}
        >
          <Form
            className="auth-inner"
            style={{ backgroundColor: getMatchingTint(this.state.color) }}
          >
            <img src={AppIcon} style={{ height: "120px", width: "180px" }} />
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder="E-mail address"
              onChange={this.handleUsernameChange}
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              type="password"
              onChange={this.handlePasswordChange}
            />
            <ButtonHandler
              title={'Login'}
              styles={{
                cursor: "pointer",
                backgroundColor: getMainBackground(this.state.color),
                color: getFontColor(this.state.color),
              }}
              size={'large'}
              click={this.authenticate}
              isfluid={true}
            />

            <NotificationContainer />
          </Form>
          <Message>
            <p
              style={{ textDecorationLine: "underline", cursor: "pointer" }}
              onClick={() => {
                this.Email();
              }}
            >
              Forgot Password?
            </p>
          </Message>
        </Grid.Column>
        <Modal
          size="mini"
          dimmer="blurring"
          open={this.state.forgotModal}
          onClose={this.Close}
        >
          <SemanticToastContainer />

          <Modal.Header>Enter LoginId</Modal.Header>
          <Modal.Content>
            <Form>
              <Input
                type="email"
                iconPosition="left"
                style={{ width: 300 }}
                placeholder="Enter the Email"
                onChange={this.handleEmail}
              >
                <IconPicker name="at" />
                <input />
              </Input>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <ButtonHandler
                title={'Cancel'}
                color={'red'}
                click={this.Close}
              />
              <ButtonHandler
                title={'Send'}
                color={'green'}
                click={this.SendEmail}
              />
            </div>


          </Modal.Actions>
        </Modal>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.user,
    Modal: state.modal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      login: loginAction,
      modalopenforforgetpwd: ModalOpenAction,
      modalcloseforforgetpwd: ModalCloseAction,
      forgotpassword: forgotPassword,
      modalopen: modalActionforopen,
    },
    dispatch
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
