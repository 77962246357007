import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Card, Image, Icon } from "semantic-ui-react";
import tanishq from "../../images/tanishq.png";
import Daimler from "../../images/Daimler.jpg";
import volks from "../../images/volks.jpg";
import britlogo from "../../images/britLogo.png";
import loader2 from "../../images/loader.gif";
import godrej from "../../images/godrej.svg";
import pandglogo from "../../images/pandglogo.jpg";
import furlenco from "../../images/furlenco.jpg";
import jandj from "../../images/jandj.png";
import grb from "../../images/grb.jpg";
import udaan from "../../images/udaan.png";
import hersheys from "../../images/hersheys.png";
import mrretail from "../../images/mrretail.png";
import canon from "../../images/canon.png";
import key from "../../images/key.jpg";
import atm from "../../images/atm.png";

import { getFontColor, getMainBackground } from "../../ThemeHandlers/theme";

const ClientDashboards = (props) => {
  const [auth, setOrgs] = useState({});
  const [createdCards, setCreatedCards] = useState([]);
  const [showIframe, setShow] = useState(false);
  const [currentFrame, setCurrentFrame] = useState("");
  const [color, setColor] = useState("");
  const orgs = useSelector((state) => state.auth);

  useEffect(() => {
    setColor(props.color);
  }, [props.color]);
  useEffect(() => {
    let cardData = [];
    setOrgs(orgs.orgs);
    if (orgs.orgs.length !== 0) {
      orgs.orgs.map((data) => {
        if (data === "5a0c1fd4709f16d6fe232142") {
          cardData.push({
            name: "Tanishq",
            icon: tanishq,
            frame:
              "https://grafana.matrixbscloud.com/d/TTjuODeMz/tanishq-dashboard?orgId=1&var-org=Tanishq&var-orgId=5a0c1fd4709f16d6fe232142&var-template=Tanishq%20Lean%20audit%20-%20Q2%202018-19&var-templateId=5a69c7173487272e0c721448&from=1622625841346&to=1622647441346&kiosk",
            height: 800,
            width: 1300,
          });
        }
        if (data === "5b4efae7b89f85c29de80f08") {
          cardData.push({
            name: "Daimler",
            icon: Daimler,
            frame:
              "https://grafana.matrixbscloud.com/d/ek_9NveMk/daimler-dashboard?orgId=1&from=1622625916704&to=1622647516704&kiosk",
            height: 800,
            width: 1300,
          });
        }
        if (data === "5ac5ce8b3788c53c19c155e4") {
          cardData.push({
            name: "Volkswagen",
            icon: volks,
            frame:
              "https://grafana.matrixbscloud.com/d/V4IjDv6Gz/volkswagen-dashboard?orgId=1&var-org=Volkswagen&var-orgId=5ac5ce8b3788c53c19c155e4&var-template=Volkswagen%20audit%202017%20-%202018&var-templateId=5abf60eed370259ffd418620&from=1622626167594&to=1622647767595&kiosk",
            height: 800,
            width: 1300,
          });
        }
        if (data === "5b96aca9b443696fdd455599") {
          cardData.push({
            name: "P & G",
            icon: pandglogo,
            frame:
              "https://grafana.matrixbscloud.com/d/pSCUcHeMz/p-andg?orgId=1&from=1623028179241&to=1623049779241&kiosk",
            height: 800,
            width: 1300,
          });
        }
        if (data === "5bd2aeeb301cb0c3d9cc7ccc") {
          cardData.push({
            name: "Damages",
            icon: britlogo,
            frame:
              "https://grafana.matrixbscloud.com/d/xOtgOveGk/damages-dashboard?orgId=1&var-org=Damages&var-orgId=5bd2aeeb301cb0c3d9cc7ccc&var-template=Britannia%20Damages%20Audit%20-%20January,%202019&var-templateId=5c066d3f690ed28dc39959e4&from=1622626306351&to=1622647906351&kiosk",
            height: 800,
            width: 1300,
          });
        }
        if (data === "5d1b5358d7646014cd5e0ed6") {
          cardData.push({
            name: "Godrej",
            icon: godrej,
            frame:
              "https://grafana.matrixbscloud.com/d/VO7fcN6Mk/godrej?orgId=1&from=1622795318384&to=1622816918384&kiosk",
            height: 800,
            width: 1300,
          });
        }
        if (data === "5dc8fe8db8e59747497e2ef4") {
          cardData.push({
            name: "Daimler Key Management",
            icon: key,
            frame:
              "https://grafana.matrixbscloud.com/d/CWznDv6Gk/daimler-key-management?orgId=1&var-org=Daimler%20Key%20Management&var-orgId=5dc8fe8db8e59747497e2ef4&var-template=Daimler%20Key%20Management&var-templateId=5feb11c0098c3e40d512908b&from=1622626779182&to=1622648379182&kiosk",
            height: 800,
            width: 1300,
          });
        }
        if (data === "5de7a27f0cd45a4cb475819e") {
          cardData.push({
            name: "Furlenco",
            icon: furlenco,
            frame:
              "https://grafana.matrixbscloud.com/d/G7-JcNeGk/furlenco?orgId=1&from=1623028771820&to=1623050371820&kiosk",
            height: 800,
            width: 1300,
          });
        }
        if (data === "5e788041781f3d7b2c7dffc3") {
          cardData.push({
            name: "Johnson & Johnson",
            icon: jandj,
            frame:
              "https://grafana.matrixbscloud.com/d/ojudOD6Mz/jandj-dashboard?orgId=1&var-org=Johnson%20%26%20Johnson&var-orgId=5e788041781f3d7b2c7dffc3&var-template=Johnson%20%26%20Johnson%20-%20February%202020&var-templateId=602684aa098c3e40d5a67f0e&from=1622626895905&to=1622648495906&kiosk",
            height: 800,
            width: 1300,
          });
        }
        if (data === "5eec7fe7ce787bdb7bff1867") {
          cardData.push({
            name: "GRB",
            icon: grb,
            frame:
              "https://grafana.matrixbscloud.com/d/keUiOv6Mz/grb-dashboard?orgId=1&var-org=GRB&var-orgId=5eec7fe7ce787bdb7bff1867&var-template=GRB%20Audit%20-%20July,%202020&var-templateId=5eec84f5ce787bdb7bff1f56&from=1622627081959&to=1622648681960&kiosk",
            height: 800,
            width: 1300,
          });
        }
        if (data === "5f0e8b94ce787bdb7b1c6a21") {
          cardData.push({
            name: "Udaan",
            icon: udaan,
            frame:
              "https://grafana.matrixbscloud.com/d/7OFyOveMk/udaan-dashboard?orgId=1&var-org=Udaan&var-orgId=5f0e8b94ce787bdb7b1c6a21&var-template=Udaan%20Audit&var-templateId=5f0e942fce787bdb7b1c74b8&from=1622627202505&to=1622648802505&kiosk",
            height: 800,
            width: 1300,
          });
        }
        if (data === "5f59b94ad524acc50845fc79") {
          cardData.push({
            name: "Hersheys",
            icon: hersheys,
            frame:
              "https://grafana.matrixbscloud.com/d/5rxRdClMz/audits?orgId=1&var-org=Hersheys&var-orgId=5f59b94ad524acc50845fc79&kiosk",
            height: 800,
            width: 1300,
          });
        }
        if (data === "5f6c3b52d524acc508509b31") {
          cardData.push({
            name: "MR Retail",
            icon: mrretail,
            frame:
              "https://grafana.matrixbscloud.com/d/CjfwdveMk/mr-retail-dashboard?orgId=1&var-org=MR%20Retail%20Store&var-orgId=5f6c3b52d524acc508509b31&var-template=MR%20Retail%20Audit-%20November,2020&var-templateId=5f6c3ba1d524acc508509b7a&from=1622627469636&to=1622649069636&kiosk",
            height: 800,
            width: 1300,
          });
        }
        if (data === "5fb204f4d524acc508839783") {
          cardData.push({
            name: "Cannon",
            icon: canon,
            frame:
              "https://grafana.matrixbscloud.com/d/WdWI5N6Gz/canon-dashboard?orgId=1&var-template=Canon%20-%20August%202021&var-templateId=61160730d3b4aa04194fafcc&kiosk",
            height: 800,
            width: 1300,
          });
        }
        if (data === "5fbcbcd07cc708707aed2410") {
          cardData.push({
            name: "ATM",
            icon: atm,
            frame:
              "https://grafana.matrixbscloud.com/d/apFT3PuMz/atm-dashboard?orgId=1&kiosk",
            height: 800,
            width: 1300,
          });
        }
        // if (data === "61bc1a124f2dc40fd89cbab3") {
        //   cardData.push({
        //     name: "Hersheys Check List",
        //     icon: loader2,
        //     frame: "",
        //     height: 800,
        //     width: 1300,
        //   });
        // }
      });
    }
    setCreatedCards(cardData);
  }, [orgs]);

  return (
    <div
      style={{
        flexGrow: 1,
        // height: "98vh",
        display: "flex",
        flex: 1,
        height: "100vh",
        flexFlow: "column",
        margin: 10,
      }}
    >
      {showIframe !== true ? (
        <Card.Group itemsPerRow={4}>
          {createdCards.map((data) => {
            return (
              <Card
                style={{
                  height: 170,
                  // backgroundColor: "#f2f2f5",
                  border: `2px solid ${getMainBackground(color)}`,
                }}
                onClick={() => {
                  setShow(true);
                  setCurrentFrame(data.frame);
                }}
              >
                <Card.Content>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    {/* <h4>{data.name}</h4> */}

                    <Image
                      size="small"
                      src={data.icon}
                      rounded
                      // fluid
                    />
                  </div>
                </Card.Content>
              </Card>
            );
          })}
        </Card.Group>
      ) : (
        <div>
          <Button
            floated="right"
            onClick={() => {
              setShow(false);
            }}
            style={{
              marginBottom: 10,
              backgroundColor: getMainBackground(color),
              color: getFontColor(color),
            }}
          >
            Back
          </Button>
          <iframe
            src={currentFrame}
            height={800}
            width={1300}
            frameBorder={0}
          />
        </div>
      )}
    </div>
  );
};

export default ClientDashboards;
