import axios from 'axios';
import * as types from './types';

export const fetchUserDetailAction = () => {
    return function(dispatch) {
        axios
            .get(types.API_URL + '/admin/v1/auditors')
            .then(function(response) {
                dispatch({
                    type: types.FETCH_USERDETAILS_SUCCESS_ACTION,
                    payload: response.data
                });
            })
            .catch(function(error) {
                dispatch({
                    type: types.FETCH_USERDETAILS_FAILIURE_ACTION,
                    payload: error
                });
            });
            
    };
};


export const fetchUserDetailAction1 = ({onSuccess, onFailure}) => {
    return function(dispatch) {
        axios
            .get(types.API_URL + '/admin/v1/auditors')
            .then(function(response) {
                dispatch({
                    type: types.FETCH_USERDETAILS_SUCCESS_ACTION,
                    payload: response.data
                });
                onSuccess(response.data)
            })
            .catch(function(error) {
                dispatch({
                    type: types.FETCH_USERDETAILS_FAILIURE_ACTION,
                    payload: error
                });
                onFailure('Error')
            });
            
    };
};


export const editUserDetailAction = (user) => {
    return function(dispatch) {
        axios.put(types.API_URL + '/admin/v1/editAuditors', user)
        .then(function(response) {
            dispatch({
                type: types.EDIT_USERDETAILS_SUCCESS_ACTION,
                payload: response.data
            });
        })
        .catch(function(err) {
            dispatch({
                type: types.EDIT_USERDETAILS_FAILIURE_ACTION,
                payload: err
            });
        });
    };
};
export const fetchOrgsDetailAction = () => {
    return function(dispatch) {
        axios
            .get(types.API_URL + '/admin/v1/orgs')
            .then(function(response) {
                dispatch({
                    type: types.FETCH_ORGSDETAILS_SUCCESS_ACTION,
                    payload: response.data
                });
            })
            .catch(function(error) {
                dispatch({
                    type: types.FETCH_ORGSDETAILS_FAILIURE_ACTION,
                    payload: error
                });
            });
    };
};
export const fetchDealersDetailAction = (orgId) => {
    return function(dispatch) {
        axios
            .get(types.API_URL + '/admin/v1/dealers?orgId=' + orgId)
            .then(function(response) {
                dispatch({
                    type: types.FETCH_DEALERSDETAILS_SUCCESS_ACTION,
                    payload: response.data
                });
            })
            .catch(function(error) {
                dispatch({
                    type: types.FETCH_DEALERSDETAILS_FAILIURE_ACTION,
                    payload: error
                });
            });
    };
};
export const AddUsersDetailAction = (user) => {
    return function(dispatch) {
        axios
            .post(types.API_URL + '/admin/v1/auditors/addUsers',user)
            .then(function(response) {
                dispatch({
                    type: types.ADD_USERSDETAILS_SUCCESS_ACTION,
                    payload: response.data
                });
            })
            .catch(function(error) {
                dispatch({
                    type: types.ADD_USERSDETAILS_FAILIURE_ACTION,
                    payload: error
                });
            });
    };
};

export const ChangePasswordAction = (changePassword) => {
   
    return function(dispatch) {
        axios
            .post(types.API_URL + '/admin/v1/changePassword',changePassword)
            .then(function(response) {
                dispatch({
                    type: types.CHANGE_PASSWORD_SUCCESS_ACTION,
                    payload: response.data
                });
            })
            .catch(function(error) {
                dispatch({
                    type: types.CHANGE_PASSWORD_FAILURE_ACTION,
                    payload: error
                });
            });
    };
};

export const setCurrentUser = (user) => {
    console.log(user,"user")
    return function(dispatch) {
        dispatch({
            type: types.SET_CURRENT_USER,
            payload: user
        })
    }
}

export const clearUserAction = () => {
    return {
      type: types.CLEAR_USER_ACTION
    };
  };