import React, { Component } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
 
import CustomizedLabel from './CustomizedLabel.jsx'

export class Graph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      colors: [
        "#008d1c",
        "#0000ff",
        "#00993d",
        "#00008b",
        "#00a664",
        "#03b490",
        "#4169e1",
        "#00c0c0",
        "#00b38f",
        "#00009c",
        "#00a3cc",
        "#0082d9",
        "#005ce6",
        "#2a52be",
        "#00a664",
        "#03b490",
        "#003399",
        "#00c0c0",
        "#00b38f",
        "#002387",
        "#00a3cc",
        "#0082d9",
        "#008d1c",
        "#0000b8",
        "#00993d",
        "#03b490",
        "#00c0c0",
        "#00b38f",
        "#00a3cc",
        "#0082d9",
        "#008d1c",
        "#00993d",
        "#00a664",
        "#008d1c",
        "#0000ff",
        "#00993d",
        "#00008b",
        "#00a664",
        "#03b490",
        "#4169e1",
        "#00c0c0",
        "#00b38f",
        "#00009c",
        "#00a3cc",
        "#0082d9",
        "#005ce6",
        "#2a52be",
        "#00a664",
        "#03b490",
        "#003399",
        "#00c0c0",
        "#00b38f",
        "#002387",
        "#00a3cc",
        "#0082d9",
        "#008d1c",
        "#0000b8",
        "#00993d",
        "#03b490",
        "#00c0c0",
        "#00b38f",
        "#00a3cc",
        "#0082d9",
        "#008d1c",
        "#00993d",
        "#00a664"
      ]
    };
  }
  render() {
    return (
      <ResponsiveContainer width={this.props.gap}>
        <BarChart
          data={this.props.data}
          margin={{
            top: 15,
            right: 30,
            left: 20,
            bottom: 25
          }}
          barCategoryGap={this.props.data.length > 20 ? 40 : 10}
          barGap={this.props.data.length > 20 ? 100 : 30}
          barSize={40}
        >
          <CartesianGrid strokeDasharray="1 1" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          {/* {this.props.data.length > 20
            ? <Brush dataKey="name" height={30} stroke="#8884d8" />
            : null} */}
          <Bar
            dataKey="score"
            // barSize={this.props.data.length > 20 ? 50 : 50}
            fill="#8884d8"
            label={<CustomizedLabel />}
          >
            {/* <LabelList dataKey="score" position="top" /> */}
            {this.state.colors.map((data, index) => {
              return <Cell fill={data} />;
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  }
}



export default Graph;
