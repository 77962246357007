import React from 'react';
import { Button, Form, Modal, Segment, Input, Grid, Label } from 'semantic-ui-react';
import { resetpassword } from '../actions/login_actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import AppIcon from '../images/textLogo.png'
import matrixIcon from '../images/matrix-logo.png'
import { toast, SemanticToastContainer } from "react-semantic-toasts";
class LoginEmail extends React.Component {
    constructor(props) {
        super(props);
        let filter = new URLSearchParams(props.location.search)
        this.state = {
            hidePass: true,
            hidePass1: true,
            password: "",
            confirmpassword: "",
            open: false,
            email: filter.get("email"),

        };
    }

    onReset = () => {

        let email = this.state.email;
        let new_password = this.state.password;
        let confirm_password = this.state.confirmpassword;
        var ResetPassword = {
            email,
            new_password,
            confirm_password
        };

        this.props.reset(ResetPassword);

    }

    loginClickable = () => {
        this.setState({
            hidePass: false
        })
    }

    loginClickable1 = () => {
        this.setState({
            hidePass1: false
        })
    }


    onNewPassword = e => {
        this.setState({
            password: e.target.value
        })
    }

    ConfirmPassword = e => {
        this.setState({
            confirmpassword: e.target.value
        })
    }

    render() {

        return (

            <div style={{ height: "100%" }}>
                <div>
                    <Segment
                        raised
                        style={{ backgroundColor: "#wefwef(", height: 60, marginBottom: 0 }}

                    >

                        <div style={{ display: "inline-block" }}>
                            <img
                                style={{
                                    height: 120,
                                    marginTop: -40,
                                    float: "left"
                                }}
                                src={AppIcon}
                            />

                            <img
                                style={{
                                    height: 60,
                                    marginTop: -13,
                                    float: "left"
                                }}
                                src={matrixIcon}
                            />
                        </div>

                    </Segment>
                </div>



                <div style={{ position: "absolute", top: 150, left: 400, width: "550px", padding: 40, border: "2px outset", boxSizing: "border-box", backgroundColor: "lightblue", display: "inline-block", borderRadius: "7px" }}>
                    <h1>Reset password</h1>

                    <Form>
                        <Form.Field>
                            <label>New password</label>
                            <Input
                                action={{ icon: 'eye', onClick: () => this.loginClickable() }}
                                type={this.state.hidePass ? "password" : "text"}
                                placeholder='Enter new password...'
                                value={this.state.password}
                                onChange={this.onNewPassword}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Confirm Password</label>
                            <Input
                                action={{ icon: 'eye', onClick: () => this.loginClickable1() }}
                                type={this.state.hidePass1 ? "password" : "text"}
                                placeholder='Confirm password...'
                                value={this.state.confirmpassword}
                                onChange={this.ConfirmPassword}
                            />
                        </Form.Field>

                        <Form.Input>
                            <Label>The Characters should Contain (A-Z,@#!$&%,1-10) ("e.g:Siva@123")</Label>
                        </Form.Input>
                        <Form.Field>
                            <Button color='green' onClick={this.onReset} >
                                Send
                            </Button>
                        </Form.Field>
                    </Form>
                </div>

            </div>
        )
    }


}
const mapStateToProps = (state) => {
    return {
        reset: state.reset,
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        reset: resetpassword,
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginEmail));
