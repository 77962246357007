import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn } from "../../util";

import IconPicker from '../commonViews/IconPicker.jsx'
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import DatePicker from "react-datepicker";

import BikanoAudits from "./BikanoAudits";
import {
  fetchBikanoTemplatesAction,
  downloadFinalReportExcelAction
} from "../../actions/bikano_actions";
import { Grid } from "semantic-ui-react";
import TableView from "../commonViews/TableView";
import {
  getTextColor,
  getMainBackground,
  getMatchingTint,
  getFontColor,
} from "../../ThemeHandlers/theme";
import ConstantLabel from "../commonViews/ConstantLabel";
import ModalView from "../commonViews/ModalView";


class BikanoTemplates extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      bikanoSelectedTemplate: {},
      selectedTemplateData: "",
      thisTemplatesView: true,
      thisReportDownloading: false,
      template: {},
      templates: [],
      matTableLoad: false,
      color: "",
    };
  }

  componentDidMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
      return;
    }
    if (this.props.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
    this.setState({ matTableLoad: true });

    // Fetch Bikano Templates
    this.props.fetchBikanoTemplatesAction({
      onSuccess: (resp) => {
        this.setState({ matTableLoad: false });
      },
      onFailure: (error) => {
        this.setState({ matTableLoad: false });
      },
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth.color !== prevProps.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
    if (this.props.bikano.templates !== prevProps.bikano.templates) {
      console.log(this.props.bikano.templates)
      this.setState({
        templates: this.props.bikano.templates,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ thisReportDownloading: false });
  }

  handleCloseClick = () => {
    this.setState({ thisTemplatesView: true });
  };

  handleTableViewAuditClick = (template) => {
    if (!template._id) return;
    this.setState({
      bikanoSelectedTemplate: template,
      thisTemplatesView: false,
    });
    // console.log(template._id)
  };

  downloadReport = (template) => {
    this.setState({ thisReportDownloading: true, matTableLoad: true });
    console.log("Template Id: ", template._id)
    this.props.downloadFinalReportExcelAction({
      templateId: template._id,
      auditName: template.auditName,
      onSuccess: (res) => {
        console.log("Response: ", res)
    this.setState({ thisReportDownloading: false, matTableLoad: false });
    NotificationManager.success(
      "Report Downloaded Successfully",
      "SUCCESS",
      3000,
      true
  );
      },
      onFailure: (err) => {
        console.log("Error: ", err)
    this.setState({ thisReportDownloading: false, matTableLoad: false });
      NotificationManager.error(
      "Unable to downoad report",
      "ERROR OCCURED",
      3000,
      true
  );
      },
    });
  };

  render() {
    var templates =
      this.state.templates.length !== 0 ? this.state.templates : [];

    var col = [
      {
        title: "Template Name",
        field: "auditName",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.auditName}
            </span>
          );
        },
      },
      // {
      //   title: "Draft Audits",
      //   field: "",
      //   render: (rowData) => {
      //     return (
      //       <span
      //         style={{ cursor: "pointer" }}
      //         onClick={() => {
      //           this.handleTableViewAuditClick(rowData);
      //         }}
      //       >
      //         {rowData.stats !== undefined ? rowData.stats.draftAudits : ""}
      //       </span>
      //     );
      //   },
      //   customFilterAndSearch: (term, rowData) =>
      //     rowData.stats.draftAudits == term,
      // },
      // {
      //   title: "Submitted Audits",
      //   field: "",
      //   render: (rowData) => {
      //     return (
      //       <span
      //         style={{ cursor: "pointer" }}
      //         onClick={() => {
      //           this.handleTableViewAuditClick(rowData);
      //         }}
      //       >
      //         {rowData.stats !== undefined ? rowData.stats.submittedAudits : ""}
      //       </span>
      //     );
      //   },
      //   customFilterAndSearch: (term, rowData) =>
      //     rowData.stats.submittedAudits == term,
      // },
      // {
      //   title: "Total Audits",
      //   field: "",
      //   render: (rowData) => {
      //     return (
      //       <span
      //         style={{ cursor: "pointer" }}
      //         onClick={() => {
      //           this.handleTableViewAuditClick(rowData);
      //         }}
      //       >
      //         {rowData.stats !== undefined ? rowData.stats.totalAudits : ""}
      //       </span>
      //     );
      //   },
      //   customFilterAndSearch: (term, rowData) =>
      //     rowData.stats.totalAudits == term,
      // },
      {
        title: "Report",
        field: "",
        render: (rowData) => {
          if (rowData._id) {
            return (
              <IconPicker
                size={"large"}
                styles={{
                  cursor: "pointer",
                  color: getTextColor(this.state.color),
                }}
                name={"file excel outline"}
                click={() => this.downloadReport(rowData)}
              />
            );
          }
        },
      }
    ];

    return (
      <div style={{ flexGrow: 1, display: "flex", flexFlow: "column" }}>
        <NotificationContainer />
        {this.state.thisTemplatesView && (
          <div>
            <h1 style={{ color: getTextColor(this.state.color) }}>Templates</h1>

            <TableView
              columns={col}
              data={templates}
              selection={false}
              load={this.state.matTableLoad}
            />
          </div>
        )}
        {!this.state.thisTemplatesView && (
          <BikanoAudits
            selectedTemplate={this.state.bikanoSelectedTemplate}
            onClose={this.handleCloseClick}
            color={this.state.color}
            orgId={this.props.org}
          />
        )}
        <ModalView
          open={this.state.modalOpen}
          close={this.hersheyscloseEditUser}
          size={"small"}
          content={
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column >
                  <ConstantLabel
                    title={"Date"}
                    size={"large"}
                    styles={{
                      backgroundColor: getMainBackground(this.props.color),
                      color: getFontColor(this.props.color),
                    }}
                  />

                  <div style={{ display: "inline-block", marginLeft: 10 }}>
                    <DatePicker
                      selected={this.state.hersheysfromDate}
                      onChange={this.handleFromDate}
                      placeholderText="Choose From Date"
                      style={{ marginRight: -20 }}

                    />
                  </div>
                </Grid.Column>

                <Grid.Column >

                  <ConstantLabel
                    title={"Email"}
                    size={"large"}
                    styles={{
                      backgroundColor: getMainBackground(this.props.color),
                      color: getFontColor(this.props.color),
                    }}
                  />
                  <input
                    type="email"
                    placeholder="Enter your email address"
                    value={this.state.email}
                    style={{ borderRadius: 5, marginLeft: 10, height: 30 }}
                    onChange={this.handleEmail}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          }
          showSave={true}
          saveClick={this.hersheysdownloadPdfsReports}
          saveText={'Mail Reports'}
        />

      </div>
    );
  }
}

function AuditTableCell(props) {
  function onClick() {
    props.onClick(props.row);
  }
  return (
    <div style={props.style} onClick={onClick}>
      {props.text}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    hersheys: state.hersheys,
    bikano: state.bikano
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchBikanoTemplatesAction,
      downloadFinalReportExcelAction
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BikanoTemplates)
);
