import React from "react";
import ConstantLabel from "../commonViews/ConstantLabel";
import {
  getMainBackground,
  getFontColor,
  getTextColor,

} from "../../ThemeHandlers/theme";
class PandGHeader extends React.Component {
  render() {
    var audit = this.props.audit;
    console.log(this.props.color, "color coming");
    return (
      <div>
        <h1 style={{ color: getTextColor(this.props.color) }}>
          {audit.auditName}({audit.chassis})
        </h1>
        <div
          style={{
            color: "#494949",
            fontsize: "15px",
            fontWeight: "100",
            paddingBottom: "10px",
            marginLeft: "10px",
            marginTop: "-15px",
          }}
        >
          {audit.location}
        </div>
        <ConstantLabel
          title={"Download Report"}
          size={"large"}
          onClick={this.props.onClick}
          styles={{
            cursor: "pointer",
            backgroundColor: getMainBackground(this.props.color),
            color: getFontColor(this.props.color),
          }}
          iconName={'table'}
        />
        <ConstantLabel
          title={"Show Photos"}
          size={"large"}
          onClick={this.props.onPhotos}
          styles={{
            cursor: "pointer",
            backgroundColor: getMainBackground(this.props.color),
            color: getFontColor(this.props.color),
          }}
          iconName={'picture'}
        />

      </div>
    );
  }
}
export default PandGHeader;
