import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import IconPicker from '../commonViews/IconPicker.jsx'
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  fetchAtmTemplatesAction,
  downloadAtmReportAction,
} from "../../actions/bank_action";
import BankAudits from "./BankAudits.jsx";
import TableView from "../commonViews/TableView";
import {
  getTextColor,

} from "../../ThemeHandlers/theme";

class Bank extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      firstDateChoose: null,
      SelectedTemplate: {},
      selectedTemplateData: "",
      thisTemplatesView: true,
      thisReportDownloading: false,
      modalOpen: false,
      template: {},
      count: "",
      templates: [],
      color: "",
    };
  }

  componentDidMount() {
    if (this.props.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
    this.props.fetchAtmTemplatesAction();
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth.color !== prevProps.auth.color) {
      this.setState({ color: this.props.auth.color });
    }

    if (this.props.atm.template !== prevProps.atm.template) {
      this.setState({ templates: this.props.atm.template });
    }
  }

  handleCloseClick = () => {
    this.setState({ thisTemplatesView: true });
  };

  handleTableViewAuditClick = (template) => {
    this.setState({
      SelectedTemplate: template,
      thisTemplatesView: false,
    });
  };

  downloadReport = (template) => {
    this.props.downloadAtmReportAction(template);
  };

  hersheyscloseEditUser = () => {
    this.setState({ modalOpen: false });
  };

  render() {
    var templates = this.state.templates ? this.state.templates : [];
    var col = [
      {
        title: "Template Name",
        field: "auditName",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.auditName}
            </span>
          );
        },
      },
      {
        title: "Report",
        field: "",
        render: (rowData) => {
          if (rowData._id) {
            return (
              <IconPicker
                size={"large"}
                styles={{
                  cursor: "pointer",
                  color: getTextColor(this.state.color),
                }}
                name={"file pdf outline"}
                click={() => this.downloadReport(rowData._id)}
              />
            );
          }
        },
      },
    ];



    return (
      <div
        style={{
          flex: 1,
          display: "flex",
          flexFlow: "column",
          height: "100%",
        }}
      >
        <NotificationContainer />
        {this.state.thisTemplatesView && (
          <div>
            <h1 style={{ color: getTextColor(this.state.color) }}>Templates</h1>
            <TableView selection={false} columns={col} data={templates} />
          </div>
        )}
        {!this.state.thisTemplatesView && (
          <div style={{ flexGrow: 1 }}>
            <BankAudits
              selectedTemplate={this.state.SelectedTemplate}
              onClose={this.handleCloseClick}
              color={this.state.color}
            />
          </div>
        )}
      </div>
    );
  }
}

function AuditTableCell(props) {
  function onClick() {
    props.onClick(props.row);
  }
  return (
    <div style={props.style} onClick={onClick}>
      {props.text}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    atm: state.atm,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchAtmTemplatesAction: fetchAtmTemplatesAction,
      downloadAtmReportAction: downloadAtmReportAction,
    },
    dispatch
  );
};

// Hersheys.PropTypes = {
//   dealers: PropTypes.array
// };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Bank));
