import {
    FETCH_HERSHEYS_COMPETITION_TEMPLATE_SUCCESS_ACTION,
    FETCH_HERSHEYS_COMPETITION_TEMPLATE_FAILURE_ACTION,
    FETCH_HERSHEYS_COMPETITION_STORE_SUCCESS_ACTION,
    FETCH_AUDITORS_DETAILS_SUCCESS_ACTION,
    FETCH_HERSHEYS_COMPETITION_AUDIT_SUCCESS_ACTION
} from '../actions/types';


const INIT_STATE = {};

export default function (state = INIT_STATE, action) {
    state = Object.assign({}, state, {});

    switch (action.type) {
        case FETCH_HERSHEYS_COMPETITION_TEMPLATE_SUCCESS_ACTION:
            state.templates = action.payload.templates;
            state.fetchHersheysTemplateError = false;
            return state;

        case FETCH_HERSHEYS_COMPETITION_TEMPLATE_FAILURE_ACTION:
            state.fetchHersheysTemplateError = true;
            return state;

        case FETCH_HERSHEYS_COMPETITION_STORE_SUCCESS_ACTION:
            return Object.assign({}, state, {
                customers: action.payload.data,
                metrics: action.payload.counts,
                isFetchUdaanStoresSuccess: true
            });
        case FETCH_AUDITORS_DETAILS_SUCCESS_ACTION:

            state.userDetails = action.payload.data;
            state.fetchUserDetailError = false;
            return state;

        case FETCH_HERSHEYS_COMPETITION_AUDIT_SUCCESS_ACTION:
            return Object.assign({}, state, {
                audits: action.payload.audits[0],
                isFetchUdaanAuditSuccess: true
            });


    }
    return state;
}
