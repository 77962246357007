import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn } from "./../util";
import axios from "axios";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.theFrame = React.createRef();
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div style={{ marginLeft: 40, marginTop: 40 }}>
        {this.props.access === "common_dashboard" && (
          //Common Dashboard
          <div>
            <iframe
              src="https://grafana.matrixbscloud.com/d/5rxRdClMz/audits?orgId=1&var-org=Hersheys&var-orgId=5f59b94ad524acc50845fc79&kiosk"
              width={"1300"}
              height={"800"}
              frameBorder={0}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
