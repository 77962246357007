import {
    FETCH_UDAAN_TEMPLATE_SUCCESS_ACTION,
    FETCH_UDAAN_TEMPLATE_FAILURE_ACTION,
    FETCH_UDAAN_STORE_SUCCESS_ACTION,
    FETCH_UDAAN_STORE_FAILURE_ACTION,
    ASSIGN_AUDITS_DETAILS_SUCCESS_ACTION,
    ASSIGN_AUDITS_FAILIURE_ACTION,
    DOWNLOAD_UDAAN_REPORT_SUCCESS_ACTION,
    DOWNLOAD_UDAAN_REPORT_FAILURE_ACTION,
    FETCH_UDAAN_AUDIT_SUCCESS_ACTION,
    FETCH_UDAAN_AUDIT_FAILURE_ACTION,
    FILTER_UDAAN_STORE_SUCCESS_ACTION,
    FILTER_UDAAN_STORE_FAILURE_ACTION

} from "../actions/types";

const INIT_STATE = {};

export default function (state = INIT_STATE,action) {
    state = Object.assign({}, state, {});

    switch (action.type) {
        case FETCH_UDAAN_TEMPLATE_SUCCESS_ACTION:
            state.templates = action.payload.templates;
            state.fetchFurlencoTemplateError = false;
            return state;
        case FETCH_UDAAN_TEMPLATE_FAILURE_ACTION:
            state.fetchFurlencoTemplateError = true;
            return state;

        case FETCH_UDAAN_STORE_SUCCESS_ACTION:
            return Object.assign({}, state, {
                customers: action.payload.data,
                metrics: action.payload.counts,
                isFetchUdaanStoresSuccess: true
            });


        case FETCH_UDAAN_STORE_FAILURE_ACTION:
        case FILTER_UDAAN_STORE_FAILURE_ACTION:
            return Object.assign({}, state, {
                isFetchUdaanStoresFailure: false
            });

        case FILTER_UDAAN_STORE_SUCCESS_ACTION:
            return Object.assign({}, state, {
                filters: action.payload.data,
                metrics: action.payload.counts,
                isFetchUdaanStoresSuccess: true
            });
        
        case ASSIGN_AUDITS_DETAILS_SUCCESS_ACTION:

            state.assignAuditsDetails = action.payload.assignAudits;
            state.assignAuditsDetailError = false;
            
            return state;

        case ASSIGN_AUDITS_FAILIURE_ACTION:
            state.assignAuditsDetailError = true;
            
            return state;

        case FETCH_UDAAN_AUDIT_SUCCESS_ACTION:
            return Object.assign({}, state, {
                audits: action.payload.data,
                isFetchUdaanAuditSuccess: true
            });


        case FETCH_UDAAN_AUDIT_FAILURE_ACTION:
            return Object.assign({}, state, {
                isFetchUdaanAuditFailure: false
            });

        case DOWNLOAD_UDAAN_REPORT_SUCCESS_ACTION:
            var buff = new Buffer(action.payload, "base64");
            var text = buff.toString("utf-8");

            // fileDownload(text, "udaanAuditReport.xlsx");

            return Object.assign({}, state, {
                downloadSuccess: true
            });

        case DOWNLOAD_UDAAN_REPORT_FAILURE_ACTION:
            return Object.assign({}, state, {
                downloadSuccess: false
            });
    }
    return state;
}
