import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn } from "./../../util";
import {
  UploadUserCsv,
  dismissLogs,
  initiateUpload,
  UploadDealerCsv,
} from "../../actions/control_panel_action";
import { Grid, Dimmer, Loader } from "semantic-ui-react";
import { Button, Modal } from "semantic-ui-react";
import CommonForAllPannels from "./CommonForAllPannels.jsx";

class PreethiControlPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      preethiuserCsvFile: null,
      preethidealersCsvFile: null,
    };
  }

  componentWillMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!isLoggedIn(nextProps.auth)) {
      this.props.history.push(`/login`);
      return false;
    }
    return true;
  }

  preethihandleUserFileUpload = (files) => {
    const file = files.target.files[0];
    this.setState({ preethiuserCsvFile: file });
    files.target.files = null;
  };

  preethihandleDealerFileUpload = (files) => {
    const file = files.target.files[0];
    this.setState({ preethidealersCsvFile: file });
    files.target.files = null;
  };

  preethiuploadUserData = () => {
    let preethiUrl = "/admin/v1/preethi/addUsers";
    this.props.initiateUpload(true);
    this.props.uploadUserCsv(preethiUrl, this.state.preethiuserCsvFile);
  };

  preethiuploadDealerData = () => {
    let preethiDealerUrl = "/admin/v1/preethi/addMaster";
    this.props.initiateUpload(true);
    this.props.uploadDealerCsv(
      preethiDealerUrl,
      this.state.preethidealersCsvFile
    );
  };

  preethicloseLog = () => {
    this.props.dismissLogs(false);
  };

  render() {
    let preethiPannel = [
      {
        itemForSpan: " Upload User CSV to add Users",
        itemInputChange: this.preethihandleUserFileUpload,
        itemClickButton: this.preethiuploadUserData,
        itemContentForButton: "Upload User CSV",
      },
      {
        itemForSpan: "Upload Preethi Master CSV to add Master",
        itemInputChange: this.preethihandleDealerFileUpload,
        itemClickButton: this.preethiuploadDealerData,
        itemContentForButton: "Upload Preethi Master",
      },
    ];
    return (
      <div
        style={{
          marginLeft: "2vw",
          marginTop: "7vh",
          fontWeight: "bold",
          fontFamily: "Arial, Helvetica, sans-serif",
        }}
      >
        <Dimmer active={this.props.controlPanel.uploadIncomplete}>
          <Loader size="massive">Uploading</Loader>
        </Dimmer>
        <Grid columns={2}>
          <Grid.Row>
            <CommonForAllPannels
              child={preethiPannel}
              color={this.props.color}
            />
          </Grid.Row>
        </Grid>
        {this.props.controlPanel.showLog && (
          <Modal
            close={this.preethicloseLog}
            open={this.props.controlPanel.showLog}
          >
            <Modal.Header>Upload Report</Modal.Header>
            <Modal.Content>
              <div
                dangerouslySetInnerHTML={{
                  __html: this.props.controlPanel.log,
                }}
              />
            </Modal.Content>
            <Modal.Actions>
              <Button color="black" onClick={this.preethicloseLog}>
                Dismiss
              </Button>
            </Modal.Actions>
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    home: state.home,
    controlPanel: state.controlPanel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      uploadUserCsv: UploadUserCsv,
      uploadDealerCsv: UploadDealerCsv,
      initiateUpload: initiateUpload,
      dismissLogs: dismissLogs,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PreethiControlPanel)
);
