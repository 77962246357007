import React, { Component } from "react";
import Audits from "../Tanishq/Audits.jsx";
import {
  fetchAuditsAction,
  downloadAuditReportAction,
  mailAuditReportAction,
  downloadAuditReportPhotoAction,
  updateAuditAction
} from "../../actions/audit_actions";
import {
  NaturalsfetchAuditsAction,
  NaturalsdownloadAuditReportAction,
  NaturalsmailAuditReportAction,
  NaturalsdownloadAuditReportPhotoAction,
  NaturalsupdateAuditAction
} from "../../actions/natural_actions";
import {
  fetchDamagesTemplatesAction,
  downloadDamagesReportAction,
  fetchDamagesTemplateAuditsAction,
  downloadDamagesAuditReportAction,
  saveDamagesAuditAction,
} from "../../actions/damages_actions";
import {
  fetchGrbTemplatesAction,
  downloadGrbReportAction,
  fetchGrbTemplateAuditsAction,
  downloadGrbAuditReportAction,
  saveGrbAuditAction,
} from "../../actions/grb_actions";
import Damages from "../damages/Damages.jsx";

export class TanishqOrNaturals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client: "",
      loading: false
    };
  }
  componentDidMount() {
    if (this.props.client !== "") {
      this.setState({
        client: this.props.client
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.client !== this.props.client) {
      this.setState({
        client: this.props.client
      });
    }
  }
  render() {
    let TanishqSpecificApiCalls = {
      fetchAuditsAction: fetchAuditsAction,
      downloadAuditReportAction: downloadAuditReportAction,
      mailAuditReportAction: mailAuditReportAction,
      downloadAuditReportPhotoAction: downloadAuditReportPhotoAction,
      updateAuditAction: updateAuditAction
    };
    let NaturalsSpecificApiCalls = {
      fetchAuditsAction: NaturalsfetchAuditsAction,
      downloadAuditReportAction: NaturalsdownloadAuditReportAction,
      mailAuditReportAction: NaturalsmailAuditReportAction,
      downloadAuditReportPhotoAction: NaturalsdownloadAuditReportPhotoAction,
      updateAuditAction: NaturalsupdateAuditAction
    };
    let DamagesSpecificApiCalls = {
      fetchTemplatesAction: fetchDamagesTemplatesAction,
      downloadReportAction: downloadDamagesReportAction,
      fetchTemplateAuditAction: fetchDamagesTemplateAuditsAction,
      downloadAuditReportAction: downloadDamagesAuditReportAction,
      saveAuditAction: saveDamagesAuditAction,
    };
    let GRBSpecificApiCalls = {
      fetchTemplatesAction:fetchGrbTemplatesAction,
      downloadReportAction: downloadGrbReportAction,
      fetchTemplateAuditAction: fetchGrbTemplateAuditsAction,
      downloadAuditReportAction: downloadGrbAuditReportAction,
      saveAuditAction: saveGrbAuditAction,
    };
    return (
      <div style={{ flexGrow: 1, display: "flex", flexFlow: "column", }}>
        {/* {this.state.loading === true &&
          <div>
            <Dimmer active>
              <Loader size="massive">Loading</Loader>
            </Dimmer>
          </div>} */}
        {this.state.client === "Tanishq"
          ? <Audits apis={TanishqSpecificApiCalls} />
          : this.state.client === "Naturals"
            ? <Audits apis={NaturalsSpecificApiCalls} />
            : this.state.client === "Damages"
              ? <Damages apis={DamagesSpecificApiCalls} name="Damages"/>
              : this.state.client === "grb"
                ? <Damages apis={GRBSpecificApiCalls} name="grb"/>
                : null
        }
      </div>
    );
  }
}

export default TanishqOrNaturals;
