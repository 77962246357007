import React from "react";
import AppIcon from "../../images/textLogo.png";
import matrixIcon from "../../images/matrix-logo.png";
import IconPicker from "./IconPicker";
import ThemeButton from "./ThemeButton";
import "./navbar.css";
import { Menu, Dropdown } from "semantic-ui-react";


const ImageAndSliderContent = (props) => {
    return (
        <div
            style={{
                display: "flex",
                flex: 70,
                flexDirection: "row",
                justifyContent: "flex-start",
            }}
        >
            <IconPicker
                styles={{
                    display: "inline-block",
                    cursor: "pointer",
                    float: "left",
                    color: "#606060",
                    marginTop: 10,
                }}
                click={props.toggleSidebar}
                size={"big"}
                name={"bars"}
            />
            <img
                style={{
                    height: 120,
                    marginTop: -35,
                    float: "left",
                }}
                src={AppIcon}
            />
            <img
                style={{
                    height: 50,

                    float: "left",
                }}
                src={matrixIcon}
            />
        </div>
    );
};

const HorizontalNavbarContent = (props) => {
    const { itemOptions, auth } = props
    return (
        <>
            <ImageAndSliderContent toggleSidebar={props.toggleSidebar} />
            <div className="split">
                <div className="split-first ">
                    <ThemeButton changeColor={props.ColorToBlue} color={"blue"} />
                    <ThemeButton changeColor={props.colorToTeal} color={"teal"} />
                    <ThemeButton changeColor={props.colorToYellow} color={"yellow"} />
                </div>
                <div>
                    <Menu.Menu>
                        <Dropdown
                            pointing
                            text={auth.displayName}
                            style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                            <Dropdown.Menu>
                                {itemOptions.map((data) => {
                                    return (
                                        <Dropdown.Item onClick={data.click}>{data.name}</Dropdown.Item>
                                    )
                                })}

                            </Dropdown.Menu>
                        </Dropdown>
                    </Menu.Menu>
                </div>

            </div>

        </>
    );
};

export default HorizontalNavbarContent;
