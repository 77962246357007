import axios from "axios";
import * as FileSaver from "file-saver";
import { template } from "underscore";
import * as types from "./types";

export const fetchOlaTemplatesAction = ({ onSuccess, onFailure }) => {
    return function (dispatch) {
        axios
            .get(types.API_URL + "/auditor/v1/ola/getTemplates")
            .then(function (response) {
                dispatch({
                    type: types.FETCH_OLA_TEMPLATE_SUCCESS_ACTION,
                    payload: response.data,
                });
                onSuccess(response.data);
            })
            .catch(function (error) {
                dispatch({
                    type: types.FETCH_OLA_TEMPLATE_FAILURE_ACTION,
                    payload: error,
                });
                onFailure();
            });
    };
};

export const fetchOlaMasterDataAction = ({ templateId, onSuccess, onFailure }) => {
    return function (dispatch) {
        axios
            .get(types.API_URL + `/auditor/v1/ola/web/getMasterData?templateId=${templateId}`)
            .then(function (response) {
                dispatch({
                    type: types.FETCH_OLA_MASTER_DATA_SUCCESS_ACTION,
                    payload: response.data,
                });
                onSuccess(response.data);
            })
            .catch(function (error) {
                dispatch({
                    type: types.FETCH_OLA_MASTER_DATA_FAILURE_ACTION,
                    payload: error,
                });
                onFailure();
            });
    };
};

export const fetchOlaAuditsDataAction = ({ templateId, vehicleId, onSuccess, onFailure }) => {
    return function (dispatch) {
        axios
            .get(types.API_URL + `/auditor/v1/ola/web/getAudit?templateId=${templateId}&vehicleId=${vehicleId}`)
            .then(function (response) {
                dispatch({
                    type: types.FETCH_OLA_AUDITS_DATA_SUCCESS_ACTION,
                    payload: response.data,
                });
                onSuccess(response.data);
            })
            .catch(function (error) {
                dispatch({
                    type: types.FETCH_OLA_AUDITS_DATA_FAILURE_ACTION,
                    payload: error,
                });
                onFailure();
            });
    };
};


export const saveOlaAudit = ({ audit, onSuccess, onFailure }) => {
    return function (dispatch) {
        axios
            .post(types.API_URL + '/auditor/v1/ola/saveVehicleAudit', audit)
            .then(function (response) {
                dispatch({
                    type: types.SAVE_OLA_AUDITS_DATA_SUCCESS_ACTION,
                    payload: response.data,
                });
                onSuccess();
            })
            .catch(function (error) {
                dispatch({
                    type: types.SAVE_OLA_AUDITS_DATA_FAILURE_ACTION,
                    payload: error,
                });
                onFailure();
            });
    };
};

export const downloadOlaReportAction = ({ templateId, onSuccess, onFailure }) => {
    return function (dispatch) {
        axios
            .get(types.API_URL + `/auditor/v1/ola/report?templateId=${templateId}`, {
                responseType: "blob",
            })
            .then(function (response) {
                FileSaver.saveAs(response.data, `OlaAuditReport.xlsx`);
                dispatch({
                    type: types.REPORT_OLA_DATA_SUCCESS_ACTION,
                    payload: response.data,
                });
                onSuccess();
            })
            .catch(function (error) {
                dispatch({
                    type: types.REPORT_OLA_DATA_FAILURE_ACTION,
                    payload: error,
                });
                onFailure();
            });
    };
};

export const fetchOlaAuditsLocationDataAction = ({ onSuccess, onFailure }) => {
    return function (dispatch) {
        axios
            .get(types.API_URL + `/auditor/v1/ola/locationsList`)
            .then(function (response) {
                dispatch({
                    type: types.FETCH_OLA_AUDITS_LOCATIONS_SUCCESS_ACTION,
                    payload: response.data,
                });
                onSuccess(response.data);
            })
            .catch(function (error) {
                dispatch({
                    type: types.FETCH_OLA_AUDITS_LOCATIONS_FAILURE_ACTION,
                    payload: error,
                });
                onFailure();
            });
    };
};

export const downloadOlaReportLocationWiseAction = ({ templateId, locationId, onSuccess, onFailure }) => {
    return function (dispatch) {
        axios
            .get(types.API_URL + `/auditor/v1/ola/report/locationWise?templateId=${templateId}&locationId=${locationId}`, {
                responseType: "blob",
            })
            .then(function (response) {
                FileSaver.saveAs(response.data, `OlaAuditReport.xlsx`);
                dispatch({
                    type: types.REPORT_OLA_DATA_LOCATIONWISE_SUCCESS_ACTION,
                    payload: response.data,
                });
                onSuccess();
            })
            .catch(function (error) {
                dispatch({
                    type: types.REPORT_OLA_DATA_LOCATIONWISE_FAILURE_ACTION,
                    payload: error,
                });
                onFailure();
            });
    };
};