import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { isLoggedIn } from './../util';
import Slider from 'react-slick';
import { Segment, Image, Modal} from 'semantic-ui-react';
import config from '../config.js';
const storageBaseUrl = config["storage_base_url"];

class Document extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedSection: "",
            selectedPhoto: "",
            showPhotoSlider: false,
            selectedAnswer: ""
        };
    }

    componentDidMount() {
        if (!isLoggedIn(this.props.auth)) {
            this.props.history.push(`/login`);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!isLoggedIn(this.props.auth)) {
            this.props.history.push(`/login`);
            return false;
        }
        return true;
    }

    handlePhotoClick = (e, p) => {
        this.setState({
            selectedPhoto: p,
            showPhotoSlider: true
        });
    }

    photoSliderClose = (e) => {
        this.setState({
            showPhotoSlider: false
        });
    }

    render() {
        var q = this.props.question;

        var doc = undefined;


        return (
            <Segment style={{ display: "flex" }}>
                <div style={{ flexGrow: 1 }}>

                    <Modal dimmer='blurring' size="fullscreen" basic open={this.state.showPhotoSlider} onClose={this.photoSliderClose}>
                        <Modal.Content>
                            <Slider infinite={false} variableWidth={true} centerMode={true}>
                                {doc && doc.photos.map((p, i) => {
                                    return <img key={i} src={storageBaseUrl + p.uploadedImageUrl} />;
                                })}
                            </Slider>
                        </Modal.Content>
                    </Modal>

                    <span style={{ lineHeight: 1.5, color: "#404040", fontSize: 15, display: "block", marginBottom: 10 }}>{q.question}</span>

                    <div>
                        <span style={{ verticalAlign: "middle", fontSize: 18, fontWeight: "bold" }}>Is Cooperative:</span>
                        <span style={{ verticalAlign: "middle", fontSize: 18, color: "#2ecc71", fontWeight: "bold", marginLeft: 15 }}>{q.isCoOperative ? 'Yes' : 'No'}</span>
                    </div>
                    <div style={{ marginTop: 15 }}>
                        <span style={{ verticalAlign: "middle", fontSize: 18, fontWeight: "bold" }}>Agent Type:</span>
                        <span style={{ verticalAlign: "middle", fontSize: 18, color: "#2ecc71", fontWeight: "bold", marginLeft: 15 }}>{q.agentVerificationType}</span>
                    </div>
                    <br />
                    <span style={{ fontSize: 18, fontWeight: "bold" }}>Documents:</span>
                    {q.documents.map((d, i) => {
                        return <Segment vertical style={{ paddingTop: 10, paddingBottom: 20, height: 150 }}>
                            <div style={{ verticalAlign: "middle" }}>{(i + 1) + ". " + d.name}</div>
                            <div style={{ verticalAlign: "middle", marginTop: 10, fontWeight: "bold" }}>Notes :</div>
                            <div>{d.notes}</div>
                            <br />

                            {d.hasPhoto &&
                                <Image.Group size='mini' style={{ cursor: "pointer" }}>
                                    {d.photos.map((p, j) => {
                                        return <Image key={j} src={storageBaseUrl + p.uploadedImageUrl} onClick={((k) => {
                                            return (e) => this.handlePhotoClick(e, k);
                                        })(p)} />;
                                    })}
                                </Image.Group>
                            }
                        </Segment>
                    })}
                </div>
            </Segment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Document));