import axios from "axios";
import * as FileSaver from "file-saver";
import { template } from "underscore";
import * as types from "./types";

export const fetchDTCTemplatesAction = ({ onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/dtc/templates")
      .then(function (response) {
        dispatch({
          type: types.FETCH_DTC_TEMPLATE_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response.data);
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_DTC_TEMPLATE_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const fetchDTCMasterAction = ({ templateId, onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL + `/auditor/v1/dtc/masterData?templateId=${templateId}`
      )
      .then(function (response) {
        dispatch({
          type: types.FETCH_DTC_MASTER_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response.data);
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_DTC_MASTERS_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const uploadDTCPhotos = (photo, orgId, { onSuccess, onFailure }) => {
  let file = new FormData();
  file.append("photo", photo);
  return function (dispatch) {
    axios
      .post(types.API_URL + `/auditor/v1/uploadAuditPhoto?orgId=${orgId}`, file)
      .then(function (response) {
        dispatch({
          type: types.UPLOAD_DTC_PHOTO_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response.data);
      })
      .catch(function (error) {
        dispatch({
          type: types.UPLOAD_DTC_PHOTO_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const uploadDocument = (document, orgId, { onSuccess, onFailure }) => {
  let file = new FormData();
  file.append("document", document);
  return function (dispatch) {
    axios
      .post(types.API_URL + `/auditor/v1/uploadDocument?orgId=${orgId}`, file)
      .then(function (response) {
        dispatch({
          type: types.UPLOAD_DTC_PHOTO_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response.data);
      })
      .catch(function (error) {
        dispatch({
          type: types.UPLOAD_DTC_PHOTO_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const saveDtcTemplates = ({
  tempId,
  templates,
  onSuccess,
  onFailure,
}) => {
  return function (dispatch) {
    axios
      .post(
        types.API_URL + `/auditor/v1/dtc/saveTemplates?templateId=${tempId}`,
        templates
      )
      .then(function (response) {
        dispatch({
          type: types.SAVE_DTC_TEMPLATES_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response.data);
      })
      .catch(function (error) {
        dispatch({
          type: types.SAVE_DTC_TEMPLATES_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const updateDtcMaster = ({
  locationId,
  master,
  onSuccess,
  onFailure,
}) => {
  return function (dispatch) {
    axios
      .post(
        types.API_URL + `/auditor/v1/dtc/updateMaster?locationId=${locationId}`,
        master
      )
      .then(function (response) {
        dispatch({
          type: types.UPDATE_DTC_MASTER_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response.data);
      })
      .catch(function (error) {
        dispatch({
          type: types.UPDATE_DTC_MASTER_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const fetchDTCSKUPhotosAction = ({
  locationId,
  onSuccess,
  onFailure,
}) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL +
        `/auditor/v1/dtc/web/getSkuData?locationId=${locationId}`
      )
      .then(function (response) {
        dispatch({
          type: types.FETCH_DTC_SKU_PHOTOS_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response.data);
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_DTC_SKU_PHOTOS_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const sendConfirmationMail = ({
  locationId,
  mailIds,
  remarks,
  onSuccess,
  onFailure,
}) => {
  let data = { locationId: locationId, mailIds: mailIds, remarks: remarks };
  return function (dispatch) {
    axios
      .post(types.API_URL + `/auditor/v1/dtc/web/sendMail`, data)
      .then(function (response) {
        onSuccess(response.data);
      })
      .catch(function (error) {
        onFailure(error);
      });
  };
};

// Sku List Upadate Func
export const updateSkuList = ({ data, onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .put(types.API_URL + `/auditor/v1/dtc/updateSkuList`, data)
      .then(function (response) {
        onSuccess(response);
      })
      .catch(function (error) {
        onFailure(error);
      });
  };
};

export const getDTCSkuImages = (locationId, { onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + `/auditor/v1/dtc/skuImages?locationId=${locationId}`, {
        responseType: "blob",
      })
      .then((response) => {
        FileSaver.saveAs(response.data, "DTC_Images.zip");
        dispatch({
          type: types.jandj_get_audit_report_success,
          payload: response.data,
        });
        onSuccess();
      })
      .catch((error) => {
        dispatch({
          type: types.jandj_get_audit_report_failure,
          payload: error,
        });
        onFailure();
      });
  };
};
