import "react-notifications/lib/notifications.css";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { withRouter } from "react-router";
import "react-datepicker/dist/react-datepicker.css";
import {
  Grid,
} from "semantic-ui-react";
import IconPicker from "../commonViews/IconPicker.jsx";
import FurlencoAudits from "./FurlencoAudits.jsx";
import TableView from "../commonViews/TableView.jsx";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import {
  fetchFurlencoCustomerAction,
  filterCustomersAction,
  assignUserToCustomer,
} from "../../actions/furlenco_actions";
import { fetchUserDetailAction } from "../../actions/user_detail_action";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import {
  getTextColor,
  getMainBackground,

  getFontColor,
} from "../../ThemeHandlers/theme";
import Metrics from "../commonViews/Metrics.jsx";
import ConstantLabel from "../commonViews/ConstantLabel.jsx";
import DatePicker from "../commonViews/DatePicker.jsx";
import DropDownPicker from "../commonViews/DropDownPicker.jsx";

export class FurlencoCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCustomerTableView: true,
      selectedCustomer: {},
      auditid: "",
      customerSearch: "",
      filteredData: [],
      fromDate: new Date(),
      toDate: new Date(),
      customers: [],
      loading: false,
    };
  }
  componentDidMount() {
    this.setState({ loading: true });
    this.props.fetchFurlencoCustomerAction({
      id: this.props.template._id,
      onSuccess: () => {
        this.setState({ loading: false });
      },
      onFailure: () => {
        this.setState({ loading: false });
      },
    });
    this.props.fetchUserDetailAction();
    if (this.props.template.startDate && this.props.template.endDate) {
      this.setState({
        fromDate: moment(this.props.template.startDate).format("DD-MM-YYYY"),
        toDate: new Date(),
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.Furlenco.customers != this.props.Furlenco.customers) {
      this.setState({
        filteredData: nextProps.Furlenco.customers,
      });
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.Furlenco.customers !== prevProps.Furlenco.customers) {
      this.setState({
        customers: this.props.Furlenco.customers,
      });
    }
  }
  auditIdFromFurlencoAudit = (auditId) => {
    this.setState({
      auditid: auditId,
    });
  };
  handleFromDate = (date) => {
    this.setState({
      fromDate: date,
    });
  };
  handleToDate = (date) => {
    this.setState({
      toDate: date,
    });
  };
  handleTableViewAuditClick = (customer) => {
    this.setState({ selectedCustomer: customer, isCustomerTableView: false });
  };
  onGotoCustomerView = () => {
    this.setState({
      isCustomerTableView: true,
    });
  };
  searchFilter = (filter, row) => {
    var flatData = JSON.stringify(row._original);
    return flatData.toLowerCase().includes(filter.value.toLowerCase());
  };

  filterCustomers = () => {
    var From = moment(this.state.fromDate).format("DD-MM-YYYY");
    var To = moment(this.state.toDate).format("DD-MM-YYYY");
    var CompFrom = moment(this.state.fromDate).format("YYYY-MM-DD");
    var compToDate = moment(this.state.toDate).format("YYYY-MM-DD");
    let result = moment(compToDate).isAfter(CompFrom, 'day'); // false
    let fil = {
      fromDate: From, toDate: To, tempId: this.props.template._id,
      onSuccess: () => {
        this.setState({ loading: false });
      },
      onFailure: () => {
        this.setState({ loading: false });
      },
    }
    if (result === true) {
      this.setState({ loading: true });
      this.props.filterCustomers(fil);
    } else {
      // alert("choose a date greater than from date");
      NotificationManager.info(
        "Choose a Date Greater Than From Date",
        "INFO MESSAGE",
        3000,
        true
      );
    }
  };

  render() {
    var customers =
      this.state.customers.length !== 0 ? this.state.customers : [];
    const col = [
      {
        title: "Customer Name",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.customerName}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.customerName.toLowerCase().includes(term.toLowerCase()),
      },
      {
        title: "Status",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.status}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.status.toLowerCase().includes(term.toLowerCase()),
      },
      {
        title: "Address",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.address}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.status.toLowerCase().indexOf(term.toLowerCase()) > -1,
      },
      {
        title: "User",
        field: "",
        render: (rowData) => {
          let details = rowData;
          let user = undefined;
          let users = [{ key: "", text: "", value: "" }];
          if (this.props.user.userDetails != null) {
            this.props.user.userDetails.map((u) => {
              users.push({
                key: u._id,
                text: u.displayName,
                value: u,
              });
              if (u._id === details.userId) {
                user = u;
              }
            });
            console.log(users)
          }
          return (
            <DropDownPicker placeholder={"Assign User"} value={user} options={users} onChangeDropDown={(data) => {
              user = data;
              if (data !== "") {
                this.props.assignUserToCustomer(details._id, data._id);

              }
            }} />
            // <Dropdown
            //   placeholder="Assign User"
            //   fluid
            //   selection
            //   search
            //   value={user}
            //   options={users}
            //   onChange={(e, data) => {
            //     user = data.value;
            //     if (data.value !== "") {
            //       this.props.assignUserToCustomer(details._id, data.value._id);
            //     }
            //   }}
            // />
          );
        },
      },
    ];

    return (
      <div style={{ flexGrow: 1, display: "flex", flexFlow: "column" }}>
        <NotificationContainer />
        {this.state.isCustomerTableView && (
          <div>
            <IconPicker
              name="arrow left"
              click={this.props.onClose}
              size={'large'}
              styles={{
                color: getTextColor(this.props.color),
                float: "right",
                cursor: "pointer",
                marginTop: "40px",
                position: "absolute",
                right: 20,
              }}
            />
            <h1 style={{ color: getTextColor(this.props.color) }}>Customers</h1>
            {this.props.Furlenco.metrics && (
              <div>
                <br />
                <Metrics metrics={this.props.Furlenco.metrics} color={this.props.color} />

              </div>
            )}

            <div
              style={{
                display: "flex",
                flexGrow: 1,
                flexFlow: "column",
                marginTop: 20,
              }}
            >
              <div>
                <div
                  style={{
                    display: "block",
                    marginLeft: "25%",
                    marginBottom: "40px",
                  }}
                >
                  <Grid verticalAlign="middle" columns={3} centered>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <Grid.Column style={{ display: "inline-block" }}>
                        <DatePicker
                          value={
                            this.state.fromDate !== null ? this.state.fromDate : new Date()
                          }
                          handleDateChange={(date) => {
                            this.handleFromDate(date)
                          }}
                          label={'From Date'}
                        />

                      </Grid.Column>
                      <Grid.Column style={{ display: "inline-block" }}>
                        <DatePicker
                          value={
                            this.state.toDate !== null ? this.state.toDate : new Date()}
                          handleDateChange={(date) => {
                            this.handleToDate(date)
                          }}
                          label={'To Date'}
                        />

                      </Grid.Column>
                    </MuiPickersUtilsProvider>
                    <Grid.Column style={{ display: "inline-block" }}>
                      <ConstantLabel
                        title={"Filter customers"}
                        size={"large"}
                        onClick={this.filterCustomers}
                        styles={{
                          display: "inline-block",
                          cursor: "pointer",
                          backgroundColor: getMainBackground(this.props.color),
                          color: getFontColor(this.props.color),
                        }}
                      />

                    </Grid.Column>
                  </Grid>
                </div>
                <TableView
                  selection={false}
                  data={customers}
                  columns={col}
                  load={this.state.loading}
                />
                {/* <Table
                  data={customers}
                  rowClick={this.handleTableViewAuditClick}
                  columns={columns}
                /> */}
              </div>
            </div>
          </div>
        )}
        {!this.state.isCustomerTableView && (
          <div style={{ flexGrow: 1, display: "flex" }}>
            <FurlencoAudits
              template={this.props.template}
              customer={this.state.selectedCustomer}
              onClose={this.onGotoCustomerView}
              auditId={this.auditIdFromFurlencoAudit}
              color={this.props.color}
            />
          </div>
        )}
      </div>
    );
  }
}

function AuditTableCell(props) {
  function onClick() {
    props.onClick(props.row);
  }
  return (
    <div style={props.style} onClick={onClick}>
      {props.text}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    Furlenco: state.Furlenco,
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchFurlencoCustomerAction: fetchFurlencoCustomerAction,
      filterCustomers: filterCustomersAction,
      fetchUserDetailAction: fetchUserDetailAction,
      assignUserToCustomer: assignUserToCustomer,
    },
    dispatch
  );
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FurlencoCustomer)
);
