import React from 'react';
import { Header, Dropdown, Input, Form, TextArea, Radio, Segment } from 'semantic-ui-react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import moment from 'moment';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
  } from 'react-day-picker/moment';


class VolkswagenAudit extends React.Component {

    constructor(props) {
        super(props);

        var soldInvoice = this.props.audit.soldInvoice || {};

        var dealer = this.props.dealer || {};
        var dealerAssessment = dealer.dealerAssessment || {};
        this.state = {
            "invoiceDate": soldInvoice.date ? new Date(soldInvoice.date) : "",
            "invoiceNo": soldInvoice.no || "",
            "gatePassDate": this.props.audit.gatePassDate ? new Date(this.props.audit.gatePassDate) : "",
            "gatePassNo": this.props.audit.gatePassNo || "",
            "vehicleStatus": this.props.audit.questions[0].answer,
            "storeRoom": this.props.audit.storeRoom,
            "notes": this.props.audit.notes,
            "isInvoiceShown": dealerAssessment.isInvoiceShown,
            "cooperationScale": dealerAssessment.cooperationScale
        }

        this.props.newAudit.vehicleStatus = this.state.vehicleStatus;
        this.props.newAudit.storeRoom = this.state.storeRoom;
        this.props.newAudit.invoiceNo = this.state.invoiceNo;
        this.props.newAudit.invoiceDate = this.state.invoiceDate;
        this.props.newAudit.notes = this.state.notes;
        this.props.newAudit.isInvoiceShown = this.state.isInvoiceShown;
        this.props.newAudit.cooperationScale = this.state.cooperationScale;
    }

    handleVehicleStatusChanged = (e, data) => {
        this.setState({ vehicleStatus: data.value });
        this.props.newAudit.vehicleStatus = data.value;
    }
    handleStoreRoomChanged = (e, data) => {
        this.setState({ storeRoom: data.value });
        this.props.newAudit.storeRoom = data.value;
    }
    onInvoiceNoChanged = (e, data) => {
        this.setState({ invoiceNo: data.value });
        this.props.newAudit.invoiceNo = data.value;
    }
    onInvoiceDateChange = (value) => {
        this.setState({ invoiceDate: value });
        this.props.newAudit.invoiceDate = value;
    }

    onGatePassNoChanged = (e, data) => {
        this.setState({ gatePassNo: data.value });
        this.props.audit.gatePassNo = data.value;
    }

    onGatePassDateChange = (value) => {
        this.setState({ gatePassDate: value });
        this.props.newAudit.gatePassDate = value;
    }

    onNotesChange = (e, data) => {
        this.setState({ notes: data.value });
        this.props.newAudit.notes = data.value;
    }
    onInvoiceRadioButtonChanged = (e, data) => {
        this.setState({ isInvoiceShown: data.checked });
        this.props.newAudit.isInvoiceShown = data.checked;
    }
    onCooperationScaleChanged = (e, data) => {
        this.setState({ cooperationScale: data.value });
        this.props.newAudit.cooperationScale = data.value;
    }

    render() {
        var audit = this.props.audit;

        var storeRoomOptions = [
            { text: 'Showroom', value: 'Show Room' },
            { text: 'Store Yard', value: 'Store Yard' },
            { text: 'Others', value: 'Others' }
        ];

        var cooperationScaleOptions = [
            {
                text: "1 - Satisfactory",
                value: "1 - Satisfactory"
            }
        ];

        var vehicleStatusOptions = [
            {
                text: "Demo",
                value: 1
            },
            {
                text: "Transit",
                value: 2
            },
            {
                text: "Exhibition",
                value: 3
            },
            {
                text: "Sold",
                value: 4
            },
            {
                text: "Checked & Verified",
                value: 5
            },
            {
                text: "Other reasons",
                value: 6
            }
        ];

        return (
            <React.Fragment>
                <Segment.Group>
                    <Segment>
                        <span style={{ fontSize: 15, fontWeight: "bold" }}>{audit.questions[0].question}</span>
                        <Dropdown style={{ marginLeft: '22px' }} onChange={this.handleVehicleStatusChanged} placeholder='Select your vehicle status' defaultValue={this.state.vehicleStatus} options={vehicleStatusOptions} />
                    </Segment>
                    <Segment>
                        <span style={{ fontSize: 15, fontWeight: "bold" }}>Storeroom</span>
                        <Dropdown style={{ marginLeft: '22px' }} onChange={this.handleStoreRoomChanged} placeholder='Storeroom' defaultValue={this.state.storeRoom} options={storeRoomOptions} />
                    </Segment>

                    {this.state.vehicleStatus === 4 &&
                        <Segment>
                            <div>
                                <span style={{ fontSize: 15, fontWeight: "bold" }}>Sold Invoice No</span>
                                <Input style={{ marginLeft: '22px', width: '120px' }} onChange={this.onInvoiceNoChanged} placeholder="Invoice No" value={this.state.invoiceNo} />
                            </div>
                            <div style={{ marginTop: 15 }}>
                                
                                <span style={{ marginRight: '9px', fontSize: 15, fontWeight: "bold" }}>Sold Invoice Date</span>
                                <span>DAY PICKER</span>
                                {/* <DayPickerInput formatDate={formatDate}
                                    style={{ marginLeft: 22 }}
                                    parseDate={parseDate}
                                    format="ll"
                                    placeholder="Select a Date"
                                    value={this.state.invoiceDate && formatDate(moment(this.state.invoiceDate), 'll')}
                                    onDayChange={this.onInvoiceDateChange}
                                /> */}
                            </div>
                        </Segment>
                    }

                    {this.state.vehicleStatus === 3 &&
                        <Segment>
                            <div>
                                <span style={{ fontSize: 15, fontWeight: "bold" }}>Gate Pass No</span>
                                <Input style={{ marginLeft: '22px', width: '120px' }} onChange={this.onGatePassNoChanged} placeholder="Gate Pass No" value={this.state.gatePassNo} />
                            </div>
                            <div style={{ marginTop: 15 }}>
                                <span style={{ marginRight: '9px', fontSize: 15, fontWeight: "bold" }}>Gate Pass Date</span>
                                <span>DAY PICKER</span>

                                <DayPickerInput formatDate={formatDate}
                                    style={{ marginLeft: 22 }}
                                    parseDate={parseDate}
                                    format="ll"
                                    placeholder="Select a Date"
                                    value={this.state.gatePassDate && formatDate(moment(this.state.gatePassDate), 'll')}
                                    onDayChange={this.onGatePassDateChange}
                                />
                            </div>
                        </Segment>
                    }

                    <Segment>
                        <span style={{ fontSize: 15, fontWeight: "bold" }}>Remarks</span>
                        <br />
                        <Form><TextArea style={{ marginRight: '22px', marginTop: '12px', width: '100%' }} onChange={this.onNotesChange} value={this.state.notes} /></Form>
                    </Segment>
                    <Segment style={{ display: "none" }}>
                        <Header as='h3' block > Dealer Assessment </Header>
                        <div>
                            <span style={{ marginRight: '22px' }} style={{ fontSize: 15, fontWeight: "bold" }}>Invoice Shown </span>
                            <Radio style={{ marginLeft: '50px' }} toggle label="No/Yes" onChange={this.onInvoiceRadioButtonChanged} checked={this.state.isInvoiceShown} />
                        </div>
                        <br />
                        <div style={{ marginTop: '5px' }}>
                            <span style={{ marginRight: '22px' }} style={{ fontSize: 15, fontWeight: "bold" }}>Cooperation Scale </span>
                            <Dropdown style={{ marginLeft: '22px' }} onChange={this.onCooperationScaleChanged} placeholder='Select Cooperation Scale' defaultValue={this.state.cooperationScale} options={cooperationScaleOptions} />
                        </div>
                    </Segment>
                </Segment.Group>
            </React.Fragment>
        )
    }
}

export default VolkswagenAudit;