import axios from "axios";
import * as FileSaver from "file-saver";
import * as types from "./types";

export const fetchPGCTemplatesAction = ({ onSuccess, onFailure }) => {
    return function (dispatch) {
        axios
            .get(types.API_URL + "/auditor/v1/pcg/templates")
            .then(function (response) {
                dispatch({
                    type: types.FETCH_PGC_TEMPLATE_SUCCESS_ACTION,
                    payload: response.data,
                });
                onSuccess(response.data);
            })
            .catch(function (error) {
                dispatch({
                    type: types.FETCH_PGC_TEMPLATE_FAILURE_ACTION,
                    payload: error,
                });
                onFailure();
            });
    };
};


export const fetchPGDMasterAction = ({
    templateId,
    onSuccess,
    onFailure,
}) => {
    return function (dispatch) {
        axios
            .get(
                types.API_URL +
                `/auditor/v1/pcg/web/getMasterData?templateId=${templateId}`
            )
            .then(function (response) {
                dispatch({
                    type: types.FETCH_PGC_MASTER_SUCCESS_ACTION,
                    payload: response.data,
                });
                onSuccess(response.data);
            })
            .catch(function (error) {
                dispatch({
                    type: types.FETCH_PGC_MASTERS_FAILURE_ACTION,
                    payload: error,
                });
                onFailure();
            });
    };
};

export const PGCAssignAuditsAction = ({ assignData, onSuccess, onFailure }) => {
    return function (dispatch) {
        axios
            .post(
                types.API_URL + "/auditor/v1/pcg/assignAudits",
                assignData
            )
            .then(function (response) {
                dispatch({
                    type: types.ASSIGN_PGC_AUDITS_DETAILS_SUCCESS_ACTION,
                    payload: response.data,
                });
                onSuccess();
            })
            .catch(function (error) {
                dispatch({
                    type: types.ASSIGN_PGC_AUDITS_FAILIURE_ACTION,
                    payload: error,
                });
                onFailure();
            });
    };
};

export const fetchPGCAuditorsDetailAction = () => {
    return function (dispatch) {
        axios
            .get(types.API_URL + "/auditor/v1/pcg/auditors")
            .then(function (response) {
                dispatch({
                    type: types.FETCH_PGC_AUDITORSDETAILS_SUCCESS_ACTION,
                    payload: response.data,
                });
            })
            .catch(function (error) {
                dispatch({
                    type: types.FETCH_PGC_AUDITORSDETAILS_FAILIURE_ACTION,
                    payload: error,
                });
            });
    };
};

export const fetchPGCAuditAction = (id) => {
    return function (dispatch) {
        axios
            .get(types.API_URL + "/auditor/v1/get_audit?masterId=" + id)
            .then(function (response) {
                dispatch({
                    type: types.FETCH_PGC_AUDIT_SUCCESS_ACTION,
                    payload: response.data,
                });
            })
            .catch(function (error) {
                dispatch({
                    type: types.FETCH_PGC_AUDIT_FAILURE_ACTION,
                    payload: error,
                });
            });
    };
};

export const savePGCAuditAction = ({ audit, onSuccess, onFailure }) => {
    return function (dispatch) {
        axios
            .put(types.API_URL + "/auditor/v1/web/saveAudit", audit)
            .then(function (response) {
                dispatch({
                    type: types.SAVE_PGC_AUDIT_SUCCESS_ACTION,
                    payload: response.data,
                });
                onSuccess(response);
            })
            .catch(function (error) {
                dispatch({
                    type: types.SAVE_PGC_AUDIT_FAILURE_ACTION,
                    payload: error,
                });
                onFailure(error);
            });
    };
};

export const downloadPGCAuditReportAction = (auditId) => {
    return function (dispatch) {
        axios
            .get(types.API_URL + "/auditor/v1/report/generateReport?masterId=" + auditId, {
                responseType: "blob",
            })
            .then(function (response) {
                FileSaver.saveAs(response.data, "Audit-Report.pdf");
                dispatch({
                    type: types.DOWNLOAD_PGC_AUDIT_REPORT_SUCCESS_ACTION,
                    payload: response.data,
                });
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: types.DOWNLOAD_PGC_AUDIT_REPORT_FAILURE_ACTION,
                    payload: error,
                });
            });
    };
};

export const downloadPGCReportAction = ({
    data,
    onSuccess,
    onFailure,
}) => {
    return function (dispatch) {
        axios
            .post(
                types.API_URL + "/auditor/v1/pgc/excelReport", data, {
                responseType: "blob",
            }
            )
            .then(function (response) {
                onSuccess();
                FileSaver.saveAs(response.data, "P&G_Health_Audit_Report.xlsx");
                dispatch({
                    type: types.DOWNLOAD_HERSHEYS_REPORT_SUCCESS_ACTION,
                    payload: response.data,
                });
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: types.DOWNLOAD_HERSHEYS_REPORT_FAILURE_ACTION,
                    payload: error,
                });
                onFailure();
            });
    };
};
