import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn } from "../../util";
import Slider from "react-slick";
import { Segment, Image, Modal, Label } from "semantic-ui-react";
import NoteModal from "../NoteModal.jsx";
import config from "../../config.js";

const storageBaseUrl = config["storage_base_url"];

class Question extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSection: "",
      selectedPhoto: "",
      showPhotoSlider: false,
      selectedAnswer: "",
    };
  }

  componentDidMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
      return false;
    }
    return true;
  }

  photoSliderClose = (e) => {
    this.setState({
      showPhotoSlider: false,
    });
  };

  handlePhotoClick = (e, p) => {
    this.setState({
      selectedPhoto: p,
      showPhotoSlider: true,
    });
  };

  handleLabelClick = (e, value) => {
    if (!this.props.editMode) return;

    this.props.question.answer = value;
    this.setState({
      selectedAnswer: value,
    });
  };

  render() {
    var q = this.props.question;

    q.actualScore = q.actualScore || 0;

    function getAnswerColor(o) {
      // This is a hack, This is to change the answer options color accordingly. Needs to be revised maybe. :-(
      var sixOptions = [
        "Average",
        "Good",
        "Very Good",
        "Excellent",
        "NA",
        "Poor",
      ];
      var threeOptions = ["Yes", "No", "NA", "1", "0"];
      if (o.value === q.answer) {
        if (sixOptions.includes(o.label)) {
          if (o.value > 3) {
            return "green";
          } else if (o.value < 0) {
            return "green";
          } else if (o.value < 3) {
            return "red";
          } else {
            return "orange";
          }
        } else if (threeOptions.includes(o.label)) {
          if (o.value === 0) {
            return "red";
          } else {
            return "green";
          }
        }
      } else {
        return "lightgrey";
      }
    }

    return (
      <Segment
        style={{
          display: "flex",
          backgroundColor: this.props.backColor(this.props.color),
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <Modal
            dimmer="blurring"
            size="fullscreen"
            basic
            open={this.state.showPhotoSlider}
            onClose={this.photoSliderClose}
          >
            <Modal.Content>
              <Slider infinite={false} variableWidth={true} centerMode={true}>
                {q.photos.map((p, i) => {
                  if (p.uploadedImageUrl === undefined) {
                    return <h3>Image Not Available</h3>;
                  } else {
                    return (
                      <img
                        key={i}
                        src={storageBaseUrl + p.uploadedImageUrl}
                        alt={"Image Not Available"}
                      />
                    );
                  }
                })}
              </Slider>
            </Modal.Content>
          </Modal>

          <span
            style={{
              lineHeight: 1.5,
              color: "#404040",
              fontSize: 15,
              display: "block",
              marginBottom: 10,
            }}
          >
            {q.question}
          </span>

          {q.options.map((o, i) => {
            return (
              <Label
                key={i}
                style={{ cursor: "pointer" }}
                onClick={(e) => this.handleLabelClick(e, o.value)}
                color={getAnswerColor(o)}
              >
                {o.label}
              </Label>
            );
          })}
          <br />
          <br />
          <NoteModal editMode={this.props.editMode} question={q} />
          <br />
          <br />
          {q.hasPhoto && (
            <Image.Group size="mini" style={{ cursor: "pointer" }}>
              {q.photos.map((p, i) => {
                if (p.uploadedImageUrl !== undefined) {
                  return (
                    <Image
                      key={i}
                      src={storageBaseUrl + p.uploadedImageUrl}
                      onClick={((k) => {
                        return (e) => this.handlePhotoClick(e, k);
                      })(p)}
                    />
                  );
                }
              })}
            </Image.Group>
          )}
          <div style={{ color: "#e67e22" }}>
            <span>{q.categoryName}</span>
            <span style={{ float: "right" }}>{q.tag}</span>
          </div>
        </div>
        <div style={{ flex: "0 0 100px", textAlign: "center" }}>
          <Label
            style={{
              backgroundColor: this.props.buttonColor(this.props.color),
              color: this.props.fontColor(this.props.color),
            }}
            // color="green"
            size="huge"
          >
            {q.actualScore.toFixed(1)}
          </Label>
          <br />
          <br />
          Weightage: {q.weightage}
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div style={{  textAlign: "center",flexDirection:'row' }}>
          <Label
            style={{
              backgroundColor: this.props.buttonColor(this.props.color),
              color: this.props.fontColor(this.props.color),
            }}
            // color="green"
            size="huge"
          >
            {q.previousActualScore1 && q.previousActualScore1 != undefined ? `${q.previousActualScore1}` :0 }
          </Label>
        
          <Label
            style={{
              backgroundColor: this.props.buttonColor(this.props.color),
              color: this.props.fontColor(this.props.color),
            }}
            // color="green"
            size="huge"
          >
            {console.log(q.previousActualScore1)}
            {console.log(q.previousActualScore2)}
            {q.previousActualScore2 && q.previousActualScore2 != undefined ? `${q.previousActualScore2}` :0 }
          </Label>
          <br />
          <br />
        Previous Scores
        </div>
        
        
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Question)
);
