import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Segment } from "semantic-ui-react";
import IconPicker from "../commonViews/IconPicker.jsx";
import { withRouter } from "react-router";
import moment from "moment";
import DiamlerKeyNewAudits from "./DiamlerKeyNewAudits.jsx";
import {
  fetchDiamlerKeyVehiclesAction,
  fetchDiamlerKeyAuditsAction,
} from "../../actions/diamler_key_action";
import TableView from "../commonViews/TableView.jsx";
import {
  getTextColor,
  getMainBackground,
  getMatchingTint,
  getFontColor,
} from "../../ThemeHandlers/theme";
import Metrics from '../commonViews/Metrics'
import ConstantLabel from "../commonViews/ConstantLabel.jsx";


class DiamlerKeyAudits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTableView: true,
      onModalOpen: false,
      audit: null,
      dealer: this.props.dealer._id,
      form21: "",
      form22: "",
      audits: [],
      loading: false,
    };
  }
  componentDidMount() {
    this.setState({ loading: true });
    this.props.fetchDiamlerKeyAudits({
      id: this.props.dealer._id,
      onSuccess: () => {
        this.setState({ loading: false });
      },
      oFailure: () => {
        this.setState({ loading: false });
      },
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.DiamlerKey?.audits !== undefined &&
      this.props.DiamlerKey.audits !== prevProps.DiamlerKey.audits
    ) {
      this.setState({ audits: this.props.DiamlerKey.audits });
    }
  }
  addNewAudits = (state, audit) => {
    this.setState({
      onModalOpen: state,
      audit: audit,
    });
  };

  searchFilter = (filter, row) => {
    var filterText = filter.value.toLowerCase();
    switch (filter.id) {
      case "label":
        let label = "";
        row._original.dealerAssessment.map((question) => {
          if (question._id === "5dd78d4e207b830ceeaeb3e2") {
            question.options.map((option) => {
              if (option.value == question.answer) {
                label = option.label;
              }
            });
          }
        });
        return label.toLowerCase().includes(filterText);
      case "createdAt":
        return moment(row._original.createdAt)
          .format("DD-MM-YYYY")
          .toLowerCase()
          .includes(filterText);
      case "engineNo":
        return row._original.vehicle.engineNo
          .toLowerCase()
          .includes(filterText);
      case "vinNo":
        return row._original.vehicle.vinNo.toLowerCase().includes(filterText);
      default:
        return true;
    }
  };

  editForm21 = (e) => {
    if (this.state.audit != null) {
      this.setState({
        form21: this.state.audit.form21OriginalGiven,
      });
    }
  };

  getAuditStatusString = (assessment) => {
    var str = "";
    let ans = assessment.answer;
    assessment.options.map((option) => {
      if (option.value == ans) {
        str = option.label;
      }
    });
    return str;
  };

  render() {
    var audits = this.state.audits.length !== 0 ? this.state.audits : [];
    var col = [
      {
        title: "Date",
        field: "createdAt",
        render: (rowData) => {
          return (
            <span
              onClick={() => {
                this.addNewAudits(true, rowData);
              }}
              style={{ cursor: "pointer" }}
            >
              {moment(rowData.createdAt).format("DD-MM-YYYY")}
            </span>
          );
        },
      },
      {
        title: "Status",
        field: "",
        render: (rowData) => {
          return (
            <span
              onClick={() => {
                this.addNewAudits(true, rowData);
              }}
              style={{ cursor: "pointer" }}
            >
              {this.getAuditStatusString(rowData.dealerAssessment[1])}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          term !== ""
            ? this.getAuditStatusString(rowData.dealerAssessment[1])
              .toLowerCase()
              .indexOf(term.toLowerCase()) > -1
            : undefined,
      },
      {
        title: "Engine No",
        field: "",
        render: (rowData) => {
          return (
            <span
              onClick={() => {
                this.addNewAudits(true, rowData);
              }}
              style={{ cursor: "pointer" }}
            >
              {rowData.vehicle.engineNo}{" "}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          term !== ""
            ? rowData.vehicle.engineNo
              .toLowerCase()
              .indexOf(term.toLowerCase()) > -1
            : undefined,
      },
      {
        title: "VIN",
        field: "",
        render: (rowData) => {
          return (
            <span
              onClick={() => {
                this.addNewAudits(true, rowData);
              }}
              style={{ cursor: "pointer" }}
            >
              {rowData.vehicle.vinNo}{" "}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          term !== ""
            ? rowData.vehicle.vinNo.toLowerCase().indexOf(term.toLowerCase()) >
            -1
            : undefined,
      },
    ];

    return (
      <div style={{ flexGrow: 1, display: "flex", flexFlow: "column" }}>
        {this.state.isTableView && (
          <div>
            <Segment
              onClick={this.props.onClose}
              style={{
                backgroundColor: getMatchingTint(this.props.color),
                float: "right",
                cursor: "pointer",
                marginTop: "10px",
                position: "absolute",
                right: 10,
              }}
            >
              <IconPicker
                name={"arrow left"}
                // className={"left large"}
                styles={{ color: getTextColor(this.props.color) }}
              />
            </Segment>
            <div>
              <h1
                style={{
                  paddingLeft: 10,
                  flex: "0 0 30px",
                  color: getTextColor(this.props.color),
                }}
              >
                Daimler Key Management -{" "}
                <span style={{ color: "black" }}>{this.props.dealer.name}</span>
              </h1>
              <ConstantLabel
                title={"Add Key Status"}
                size={"large"}
                onClick={() => this.addNewAudits(true, this.props.template)}
                styles={{
                  cursor: "pointer",
                  backgroundColor: getMainBackground(this.props.color),
                  color: getFontColor(this.props.color),
                  marginLeft: 10,
                }}
                iconName={"add"}
              />


              {this.props.DiamlerKey.metrics && (
                <div>
                  <br />
                  <Metrics metrics={this.props.DiamlerKey.metrics} color={this.props.color} size={'mini'} />

                </div>
              )}

              {this.state.onModalOpen && (
                <DiamlerKeyNewAudits
                  isVisible={this.state.onModalOpen}
                  onVisibilityChange={this.addNewAudits}
                  dealerId={this.state.dealer}
                  template={this.state.audit}
                  form21={this.state.form21}
                  onForm21Change={this.editForm21}
                  color={this.props.color}
                />
              )}
              <div style={{ display: "flex", flexGrow: 1, flexFlow: "column" }}>
                <div>
                  <TableView
                    selection={false}
                    data={audits}
                    columns={col}
                    load={this.state.loading}
                  />
                  {/* <Table
                    rowClick={(e) => this.addNewAudits(true, e)}
                    columns={columns}
                    data={audits}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
function AuditTableCell(props) {
  function onClick() {
    props.onRowClick(props.row);
  }
  return (
    <div style={props.style} onClick={onClick}>
      {props.text}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    DiamlerKey: state.DiamlerKey,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchDiamlerKeyVehicles: fetchDiamlerKeyVehiclesAction,
      fetchDiamlerKeyAudits: fetchDiamlerKeyAuditsAction,
    },
    dispatch
  );
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DiamlerKeyAudits)
);
