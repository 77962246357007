import axios from 'axios';
import * as types from './types';
import * as FileSaver from 'file-saver';
import { data } from 'jquery';
export const fetchHershysCheckTemplatesAction = () => {
    
    return function(dispatch) {
        axios
            .get(types.API_URL + '/auditor/v1/hersheysChecklist/getTemplate')
            .then(function(response) {
                
                dispatch({
                    type: types.FETCH_HERSHYS_CHECK_TEMPLATE_SUCCESS_ACTION,
                    payload: response.data
                });
            })
            .catch(function(error) {
                dispatch({
                    type: types.FETCH_HERSHYS_CHECK_TEMPLATE_FAILURE_ACTION,
                    payload: error
                });
            });
    };
};

export const fetchHershysCheckMasterAction = (templateId) => {
    
    return function(dispatch) {
        axios
            .get(types.API_URL + `/auditor/v1/hersheysChecklist/getMaster?templateId=${templateId}`)
            .then(function(response) {
                
                dispatch({
                    type: types.FETCH_HERSHYS_CHECK_MASTER_SUCCESS_ACTION,
                    payload: response.data
                });
            })
            .catch(function(error) {
                dispatch({
                    type: types.FETCH_HERSHYS_CHECK_MASTER_FAILURE_ACTION,
                    payload: error
                });
            });
    };
};

export const saveHershysCheckAction = ({data,onSuccess,onFailure}) => {
    return function(dispatch) {
        axios
            .post(types.API_URL + `/auditor/v1/hersheysChecklist/save`,data)
            .then(function(response) {
                
                dispatch({
                    type: types.SAVE_HERSHYS_CHECK_SUCCESS_ACTION,
                    payload: response.data
                });
                onSuccess()
            })
            .catch(function(error) {
                dispatch({
                    type: types.SAVE_HERSHYS_CHECK_FAILURE_ACTION,
                    payload: error
                });
                onFailure()
            });
    };
};


export const downloadHershysChecklistAuditReportAction = ({id,name,onSuccess,onFailure}) => {
    return function(dispatch) {
      axios
        .get(
          types.API_URL + `/auditor/v1/hersheysChecklist/report?masterId=${id}`,
          {
            responseType: "blob"
          }
        )
        .then(function(response) {
          FileSaver.saveAs(response.data, `${name}.xlsx`);
          dispatch({
            type: types.DOWNLOAD_HERSHYSCHECKAUDITS_REPORT_SUCCESS_ACTION,
            payload: response.data
          });
          onSuccess()
        })
        .catch(function(error) {
          dispatch({
            type: types.DOWNLOAD_HERSHYSCHECKAUDITS_REPORT_FAILURE_ACTION,
            payload: error
          });
          onFailure()
        });
    };
  };

export const fetchExistingAuditsIfPresent = ({masterId,onSuccess,onFailure}) => {
    return function(dispatch) {
        axios
            .get(types.API_URL + `/auditor/v1/hersheysChecklist/getAudit?masterId=${masterId}`)
            .then(function(response) {
                dispatch({
                    type: types.FETCH_EXISTING_AUDITS_SUCCESS_ACTION,
                    payload: response.data
                });
                onSuccess()
            })
            .catch(function(error) {
                dispatch({
                    type: types.FETCH_EXISTING_AUDITS_FAILURE_ACTION,
                    payload: error
                });
                onFailure()
            });
    };
};

export const AssignAuditsAction = ({assignData,onSuccess,onFailure}) => {
    return function(dispatch) {
      axios
        .post(types.API_URL + "/auditor/v1/hersheysChecklist/multiAssignAudits", assignData)
        .then(function(response) {
          dispatch({
            type: types.ASSIGN_HERSHEYS_AUDITS_DETAILS_SUCCESS_ACTION,
            payload: response.data
          });
          onSuccess();
        })
        .catch(function(error) {
          dispatch({
            type: types.ASSIGN_HERSHEYS_AUDITS_FAILIURE_ACTION,
            payload: error
          });
          onFailure()
        });
    };
  };

export const fetchAuditorsDetailAction = () => {
    return function(dispatch) {
      axios
        .get(types.API_URL + "/auditor/v1/hersheys/auditors")
        .then(function(response) {
          dispatch({
            type: types.FETCH_AUDITORSDETAILS_SUCCESS_ACTION,
            payload: response.data
          });
        })
        .catch(function(error) {
          dispatch({
            type: types.FETCH_AUDITORSDETAILS_FAILIURE_ACTION,
            payload: error
          });
        });
    };
  };

export const UploadHershysCheckAction = ({form,orgId,config,onSuccess,onFailure}) => {
    let file = new FormData();
    file.append('photo', form,form.name);
    return function(dispatch) {
        axios
            .post(types.API_URL + `/auditor/v1/uploadAuditPhoto?orgId=${orgId}`,file)
            .then(function(response) {
                
                dispatch({
                    type: types.UPLOAD_HERSHYS_CHECK_SUCCESS_ACTION,
                    payload: response.data
                });
                onSuccess(response.data)
            })
            .catch(function(error) {
                dispatch({
                    type: types.UPLOAD_HERSHYS_CHECK_FAILURE_ACTION,
                    payload: error
                });
                onFailure(error)
            });
    };
};


export const setSelectedTemplateData = (data) => {
    return function(dispatch) {
        dispatch({
          type: types.SET_SELECTED_TEMPLATE_DATA,
          payload:{
              data:data
          }
        })
      }
}
export const setSelectedMasterData = (data) => {
    return function(dispatch) {
        dispatch({
          type: types.SET_SELECTED_MASTER_DATA,
          payload:{
              data:data
          }
        })
      }
}



export const clearFetchedAudits = (data) => {
    return function(dispatch) {
        dispatch({
          type: types.CLEAR_FETCHED_AUDITS,
          payload:{
              data:{}
          }
        })
      }
}