import React from "react";
import { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
    Modal,
    Image,
    Segment,
    Tab,
    Grid,
} from "semantic-ui-react";
import IconPicker from "../commonViews/IconPicker.jsx";
import TextInput from '../commonViews/TextInput.jsx'
import "semantic-ui-css/semantic.min.css";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import config from "../../config.js";
import {
    getTextColor,
    getMainBackground,
    getMatchingTint,
    getFontColor,
} from "../../ThemeHandlers/theme";
import ConstantLabel from "../commonViews/ConstantLabel.jsx";
import DropDownPicker from "../commonViews/DropDownPicker.jsx";
import ModalView from "../commonViews/ModalView.jsx";
import { fetchOlaAuditsDataAction, saveOlaAudit } from '../../actions/ola_actions'

const storageBaseUrl = config["storage_base_url"];

export class OlaAudits extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editMode: false,
            audit: null,
            selectedPhoto: "",
            audits: [],
            audit: {}
        };
    }

    componentDidMount() {
        let vehicleId = this.props.customer._id
        let templateId = this.props.customer.templateId;
        this.props.fetchOlaAuditsDataAction({
            templateId,
            vehicleId,
            onSuccess: (res) => {
                let audits = []
                let data = {}
                data.name = 'Vehicle Verification'
                data.questions = res.audit.vehicleVerification ? res.audit.vehicleVerification : []
                audits.push(data)
                let doc = {}
                doc.name = 'Document Verification'
                doc.questions = res.audit.documentVerification ? res.audit.documentVerification : []
                audits.push(doc)
                this.setState({ isLoading: false, audits: audits, audit: res.audit });
            },
            onFailure: () => {
                this.setState({ isLoading: false });
            },
        });
    }

    editAudit = () => {
        this.setState({ editMode: true });
    };

    cancelEditAudit = () => {
        this.setState({ editMode: false });
    };

    saveAudit = () => {
        let audit = this.state.audit;
        audit.vehicleVerification = this.state.audits[0].questions;
        audit.documentVerification = this.state.audits[1].questions;
        delete audit._id;
        this.props.saveOlaAudit({
            audit,
            onSuccess: () => {
                alert('Audit Save Successfully')
            },
            onFailure: () => {

            }
        });
        this.setState({ editMode: false });
    };

    photoSliderClose = (e) => {
        this.setState({
            showPhotoSlider: false,
        });
    };

    handlePhotoClick = (e, p) => {
        this.setState({
            selectedPhoto: p,
            showPhotoSlider: true,
        });
    };

    onAnswerChange = (name, questionId, answerType, e) => {
        let audit = this.state.audits;
        audit.map((q) => {
            if (q.name === name) {
                q.questions.map((qe) => {
                    if (qe.id === questionId) {
                        qe.answer = e;
                    }
                });
            }
        });
        this.setState({ audits: audit });
    };


    render() {
        return (
            <div>
                {this.state.audits.length == 0 ? (
                    <div>Loading</div>
                ) : (
                    <div>
                        <HeaderView
                            selectedAudit={this.state.audit}
                            editMode={this.state.editMode}
                            editAudit={this.editAudit}
                            savedAudit={this.saveAudit}
                            cancelEditAudit={this.cancelEditAudit}
                            goBack={this.props.onClose}
                            color={this.props.color}
                        />
                        <ModalView
                            open={this.state.showPhotoSlider}
                            close={this.photoSliderClose}
                            content={
                                <Image
                                    fuild
                                    centered
                                    src={
                                        storageBaseUrl + this.state.selectedPhoto.uploadedImageUrl
                                    }
                                />
                            }
                            showSave={false}
                        />
                        <Tab
                            style={{ marginTop: 15, marginLeft: 10 }}
                            menu={{
                                color: "white",
                                attached: false,
                                tabular: false,
                            }}
                            panes={this.state.audits.map((q) => {
                                return {
                                    menuItem: q.name,
                                    render: () => (
                                        <Questionnaire
                                            name={q.name}
                                            questions={q.questions}
                                            editMode={this.state.editMode}
                                            onChange={this.onAnswerChange}
                                            handlePhotoClick={this.handlePhotoClick}
                                            color={this.props.color}
                                        />
                                    ),
                                };
                            })}
                        />
                    </div>
                )}
            </div>
        );
    }
}

const HeaderView = function (props) {
    let {
        selectedAudit,
        editMode,
        onDownloadPhotos,
        editAudit,
        savedAudit,
        cancelEditAudit,
        dealer,
        goBack,
    } = props;
    return (
        <div>
            <div style={{ flex: 1, flexDirection: 'row' }}>

                <div>
                    <h1 style={{ color: getTextColor(props.color), marginLeft: 20 }}>
                        {selectedAudit.auditName}
                    </h1>
                </div>
                <div>
                    <IconPicker
                        styles={{
                            color: "#808080",
                            marginLeft: 1200,
                            cursor: "pointer",
                        }}
                        click={goBack}
                        size={"large"}
                        name={"arrow left"}
                    />
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    marginLeft: 20
                }}
            >
                {!editMode && (
                    <ConstantLabel
                        title={"Edit audit"}
                        size={"medium"}
                        onClick={editAudit}
                        styles={{
                            cursor: "pointer",
                            backgroundColor: getMainBackground(props.color),
                            color: getFontColor(props.color),
                        }}
                        iconName={"edit"}
                    />

                )}

                {editMode && (
                    <div >
                        <ConstantLabel
                            title={"Save audit"}
                            size={"medium"}
                            onClick={savedAudit}
                            styles={{
                                cursor: "pointer",
                                backgroundColor: getMainBackground(props.color),
                                color: getFontColor(props.color),
                            }}
                            iconName={"save"}
                        />
                        <ConstantLabel
                            title={"Cancel"}
                            size={"medium"}
                            onClick={cancelEditAudit}
                            styles={{
                                cursor: "pointer",
                                backgroundColor: "grey",
                                color: "white",
                            }}
                            iconName={"cancel"}
                        />
                    </div>
                )}

            </div>
            <div style={{ marginLeft: 20 }}>
                By{" "}
                {selectedAudit.auditorId != null && selectedAudit.auditorId.displayName}{" "}
                on {moment(selectedAudit.createdAt).format("DD-MM-YYYY  HH:MM:SS")}
            </div>
        </div>
    );
};

const Questionnaire = function (props) {
    let questionViews = [];
    let { questions, editMode, onChange, name, handlePhotoClick } =
        props;
    questions
        .filter((eachQuestion) => {
            if (eachQuestion.hasOwnProperty("dependency")) {
                let question = null;
                let questionId = eachQuestion.dependency.questionId;
                let answerNeeded = eachQuestion.dependency.answer;
                questions.map((q) => {
                    if (q.id == questionId) {
                        question = q;
                    }
                });
                if (question.answer != answerNeeded) {
                    return false;
                }
                return true;
            } else {
                return true;
            }
        })
        .forEach((q) => {
            questionViews.push(
                <Segment
                    key={q.id}
                    style={{ backgroundColor: getMatchingTint(props.color) }}
                >
                    <span
                        style={{
                            lineHeight: 1.5,
                            width: 500,
                            color: "#404040",
                            fontSize: 15,
                            display: "block",
                        }}
                    >
                        {q.question}
                    </span>
                    {q.answerType === "text" && (
                        <TextInput
                            disabled={!editMode}
                            value={q.answer}
                            onChangeTextInput={(e) => {
                                onChange(name, q.id, q.answerType, e.target.value)

                            }}
                        />

                    )}
                    {q.answerType === "date" && (
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                            <KeyboardDatePicker
                                autoOk={true}
                                variant="inline"
                                format="YYYY-MM-DD"
                                value={moment(q.answer)}
                                onChange={(date, _) =>
                                    onChange(name, q.id, q.answerType, date)
                                }
                                disabled={!editMode}
                            />
                        </MuiPickersUtilsProvider>
                    )}

                    {q.answerType === "dropdown" && (
                        <DropDownPicker
                            styles={{ display: "inline-block", width: "50%" }}
                            placeholder={"select an option"}
                            disabled={!editMode}
                            options={q.options.map((label, i) => {
                                return {
                                    value: label.value,
                                    text: label.label,
                                    key: label.value,
                                };
                            })}
                            onChangeDropDown={(date) => {
                                onChange(name, q.id, q.answerType, date)
                            }}
                            value={q.answer}
                        />
                    )}
                    {<br></br>}
                    {q.photos && q.photos.length > 0 && (
                        <Image.Group size="mini" style={{ cursor: "pointer" }}>
                            {q.photos.map((p, i) => {
                                if (p.uploadedImageUrl != null) {
                                    return (
                                        <div>
                                            <br></br>
                                            <Image
                                                key={i}
                                                src={storageBaseUrl + p.uploadedImageUrl}
                                                onClick={((k) => {
                                                    return (e) => handlePhotoClick(e, k);
                                                })(p)}
                                            />
                                        </div>
                                    );
                                }
                            })}
                        </Image.Group>

                    )}
                </Segment>
            );
        });

    return <div>{questionViews}</div>;
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            fetchOlaAuditsDataAction,
            saveOlaAudit
        },
        dispatch
    );
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(OlaAudits)
);
