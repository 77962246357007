import React, { Component } from "react";

export class CustomizedLabel extends Component {
  render() {
    const { x, y, fill, value } = this.props;
    
    return (
      <text
        x={x}
        y={y}
        dy={-4}
        fontSize="14"
        fontFamily="sans-serif"
        fill={fill}
        textAnchor="inherit"
      >
        {value}%
        
        
      </text>
    );
  }
}

export default CustomizedLabel;
