import React from "react";
import { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { fetchUdaanAuditAction } from "../../actions/udaan_actions";
import { Divider, Image, Grid } from "semantic-ui-react";
import IconPicker from "../commonViews/IconPicker";
import moment from "moment";

import config from "../../config.js";
import {
  getTextColor,

} from "../../ThemeHandlers/theme";
const storageBaseUrl = config["storage_base_url"];

export class Audits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      audit: null,
      showPhotoSlider: false,
      selectedPhoto: "",
      isMailPopupView: false,
      showStockAudit: false,
      modalOpen: false,
      selectedPhotoUrl: false,
    };
  }

  componentDidMount() {
    this.props.fetchUdaanAuditAction(this.props.customer._id);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.udaan.customers) {
      this.setState({
        audit: JSON.parse(JSON.stringify(this.props.udaan.customers)),
      });
    }
  }

  closeEditUser = () => {
    // this.props.clearUserAction();
    this.setState({ modalOpen: false });
  };
  OnClickPhoto = () => {
    this.setState({ modalOpen: true });
  };
  onPhotoClick = (photoUrl) => {
    this.setState({ selectedPhotoUrl: photoUrl });
  };

  render() {
    return (
      <div>
        <IconPicker
          name={"arrow left"}
          click={this.props.onClose}
          styles={{
            color: getTextColor(this.props.color),

            float: "right",
            cursor: "pointer",
            marginTop: "35px",
            position: "absolute",
            right: 20,
          }}
        />

        <h1
          style={{
            color: getTextColor(this.props.color),
          }}
        >
          {"Udaan Audits"}
        </h1>
        <br></br>
        {this.props.udaan.audits != undefined &&
          this.props.udaan.audits.map((e) => {
            return (
              <div
                style={{
                  fontWeight: "bold",
                  marginLeft: "20px",
                  fontSize: "18px",
                  paddingBottom: "20px",
                }}
              >
                <div style={{ color: getTextColor(this.props.color) }}>
                  Audit Done By {e.auditorId != null && e.auditorId.displayName}{" "}
                  on {moment(e.createdAt).format("DD-MM-YYYY")}
                </div>
                <div style={{ marginLeft: "20px" }}>
                  <br></br>
                  <h3>{e.questions[0].question}</h3>
                  {e.questions[0].photos.map((k) => {
                    if (k.uploadedImageUrl === undefined) {
                      return (
                        <h3
                          style={{
                            fontSize: 14,
                            marginBottom: 20,
                            marginLeft: 5,
                          }}
                        >
                          Photo not available
                        </h3>
                      );
                    } else {
                      return (
                        <Grid>
                          <Grid.Row columns={3} divided>
                            {/* <Grid.Column > */}
                            <Image.Group
                              size="medium"
                              style={{ marginBottom: "10px" }}
                            >
                              <Image
                                src={storageBaseUrl + k.uploadedImageUrl}
                              />
                            </Image.Group>
                            {/* </Grid.Column> */}
                          </Grid.Row>
                        </Grid>
                      );
                    }
                  })}

                  <h3>{e.questions[1].question}</h3>
                  {e.questions[1].photos.map((s) => {
                    if (s.uploadedImageUrl === undefined) {
                      return (
                        <h3
                          style={{
                            fontSize: 14,
                            marginBottom: 20,
                            marginLeft: 5,
                          }}
                        >
                          Photo not available
                        </h3>
                      );
                    } else {
                      return (
                        <Grid>
                          <Grid.Row columns={3} divided>
                            {/* <Grid.Column > */}
                            <Image.Group
                              size="medium"
                              style={{ marginBottom: "10px" }}
                            >
                              <Image
                                src={storageBaseUrl + s.uploadedImageUrl}
                                alt={"Image Not Available"}
                              />
                            </Image.Group>
                            {/* </Grid.Column> */}
                          </Grid.Row>
                        </Grid>
                      );
                    }
                  })}
                  <Divider />
                </div>
              </div>
            );
          })}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    udaan: state.udaan,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchUdaanAuditAction,
    },
    dispatch
  );
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Audits));
