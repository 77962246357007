import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn } from "./../../util";
import {
  godrejAuditUpload,
  dismissLogs,
  initiateUpload,
} from "../../actions/control_panel_action";
import { Dimmer, Loader } from "semantic-ui-react";
import { Button, Modal } from "semantic-ui-react";
import CommonForAllPannels from "./CommonForAllPannels.jsx";

class GodrejControlPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auditFile: null,
    };
  }

  componentWillMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!isLoggedIn(nextProps.auth)) {
      this.props.history.push(`/login`);
      return false;
    }
    return true;
  }

  handleUserFileUpload = (files) => {
    const file = files.target.files[0];
    this.setState({ auditFile: file });
    files.target.files = null;
  };

  uploadUserData = () => {
    this.props.initiateUpload(true);
    this.props.auditUpload(this.state.auditFile);
  };

  godrejcloseLog = () => {
    this.props.dismissLogs(false);
  };

  render() {
    let godrejPannel = [
      {
        itemForSpan: "Upload Audit",
        itemInputChange: this.handleUserFileUpload,
        itemClickButton: this.uploadUserData,
        itemContentForButton: "Upload Audit excel",
      },
    ];
    return (
      <div
        style={{
          marginLeft: "2vw",
          marginTop: "7vh",
          fontWeight: "bold",
          fontFamily: "Arial, Helvetica, sans-serif",
        }}
      >
        <Dimmer active={this.props.controlPanel.uploadIncomplete}>
          <Loader size="massive">Uploading</Loader>
        </Dimmer>

        <CommonForAllPannels child={godrejPannel} color={this.props.color} />

        {this.props.controlPanel.showLog && (
          <Modal
            close={this.godrejcloseLog}
            open={this.props.controlPanel.showLog}
          >
            <Modal.Header>Upload Report</Modal.Header>
            <Modal.Content>
              <div
                dangerouslySetInnerHTML={{
                  __html: this.props.controlPanel.log,
                }}
              />
            </Modal.Content>
            <Modal.Actions>
              <Button color="black" onClick={this.godrejcloseLog}>
                Dismiss
              </Button>
            </Modal.Actions>
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    home: state.home,
    controlPanel: state.controlPanel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      auditUpload: godrejAuditUpload,
      initiateUpload: initiateUpload,
      dismissLogs: dismissLogs,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GodrejControlPanel)
);
