import {
    FETCH_DTC_TEMPLATE_SUCCESS_ACTION,
    FETCH_DTC_TEMPLATE_FAILURE_ACTION,
} from "../actions/types";

const DTC_STATE = {};

function dtc(state = DTC_STATE, action) {
    state = Object.assign({}, state, {});
    switch (action.type) {
        case FETCH_DTC_TEMPLATE_SUCCESS_ACTION:
            state.dtcTemplate = action.payload.templates;
            state.dtcTemplateError = false;
            return state;
        case FETCH_DTC_TEMPLATE_FAILURE_ACTION:
            state.dtcTemplateError = true;
            return state;

        default:
            return state
    }
}

export default dtc
