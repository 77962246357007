import axios from 'axios';
import * as types from './types';

export const resetpassword = (ResetPassword) => {
    return function (dispatch) {
        axios.post(types.API_URL + '/auditor/v1/resetPassword',ResetPassword )
            .then(function (response) {
                dispatch({
                    type: types.RESET_PASSWORD_SUCCESS_ACTION,
                    payload: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: types.RESET_PASSWORD_FAILURE_ACTION,
                    payload: error
                });
            });
    };
}