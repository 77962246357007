import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { fetchImagesOfAudit } from '../actions/audit_actions';
import { Segment } from 'semantic-ui-react';
import { Image } from 'semantic-ui-react';
import config from '../config.js';

export const API_URL = config['storage_base_url'];

class ShowSinglePhoto extends React.Component {
    constructor(props) {
        super(props);
        let filter = new URLSearchParams(this.props.location.search);
        this.state = {
            photoUrl: filter.get("photoUrl"),
        };
    }
    componentDidMount() {
    }

    render() {
        // var questions = this.getQuestions();
        return (
            <div style={{ height: '100%' }}>
                <Segment raised style={{ backgroundColor: '#wefwef(', height: 60, marginBottom: 0 }}>
                    <div style={{ display: 'inline-block' }}>
                        <img
                            style={{
                                height: 120,
                                marginTop: -40,
                                float: 'left'
                            }}
                            src="../images/textLogo.png"
                        />

                        <img
                            style={{
                                height: 60,
                                marginTop: -13,
                                float: 'left'
                            }}
                            src="../images/matrix-logo.png"
                        />
                    </div>
                </Segment>
                <Segment style={{ marginTop: 0 }}>
                    <Image style={{"margin": "0 auto"}} src={config['storage_base_url'] + this.state.photoUrl} />
                </Segment>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        questions: state.audits.images,
        name: state.audits.images_name
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            fetchImages: fetchImagesOfAudit
        },
        dispatch
    );
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ShowSinglePhoto)
);
