import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn } from "../../util";
import {
  fetchExcessVehiclesAction,
  uploadDaimlerPhotoAction,
  updateExcessVehicleAction,
} from "../../actions/vehicle_verification_actions";
import { Radio, Input, Label, Image, Button } from "semantic-ui-react";
import { Header, List, Modal, Segment } from "semantic-ui-react";

import Table from "../commonTables/Table.jsx";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import TableView from "../commonViews/TableView";

import config from "../../config.js";





const storageBaseUrl = config["storage_base_url"];
class ExcessVehicles extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedVehicle: "",
      selectedAudit: "",
      showPopup: false,
      excessVehicleStatus: "",
      showNoAuditFound: false,
      excessVehicleEditMode: false,

      vehicleStatus: "",
      model: "",
      color: "",
      location: "",
      odometer: "",
      vehicleCondition: "",
      remarks: "",
      form21: "",
      form22: "",
      hypothecationValues: "",
      photos: [],
      toUpload: [],
      excessVehicles: [],
    };
  }

  authenticate = () => {
    this.props.login(this.state.username, this.state.password);
  };

  componentDidMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
      return;
    }

    this.props.fetchExcessVehiclesAction(
      this.props.dealer._id,
      this.props.dealer.templateId
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.excessVehicles !== prevProps.excessVehicles) {
      this.setState({
        excessVehicles: this.props.excessVehicles,
      });
    }
  }

  searchFilter = (filter, row) => {
    var flatData = JSON.stringify(row._original);
    return flatData.toLowerCase().includes(filter.value.toLowerCase());
  };

  handleTableViewAuditClick = (vehicle) => {
    console.log(vehicle, "clicked");
    this.setState({ showPopup: true });
    if (!vehicle._id) return;

    var audit = vehicle._id;
    if (!audit) {
      this.setState({ showNoAuditFound: true });
      return;
    }

    this.setState({ vehicleStatus: vehicle.vehicleStatus });
    this.setState({ odometer: vehicle.odometerReading });
    this.setState({ color: vehicle.color });
    this.setState({ model: vehicle.model });
    this.setState({ location: vehicle.location });
    this.setState({ vehicleCondition: vehicle.vehicleCondition });
    this.setState({ remarks: vehicle.remarks });
    this.setState({ hypothecationValues: vehicle.hypothecationValues });
    this.setState({ form21: vehicle.form21 });
    this.setState({ form22: vehicle.form22 });
    this.setState({ photos: vehicle.photos });

    this.setState({ selectedVehicle: vehicle, showPopup: true });
  };

  handleCancel = () => this.setState({ showNoAuditFound: false });

  closePopup = () => {
    this.setState({
      showPopup: false,
      newAudit: {},
      excessVehicleEditMode: false,
    });
  };

  editAudit = () => this.setState({ excessVehicleEditMode: true });

  onExcessVehicleStatusChange = (e, data) => {
    this.setState({ vehicleStatus: data.value });
  };

  onExcessVehicleOdometerChange = (data) => {
    this.setState({ odometer: data.target.value });
  };

  onExcessVehicleColorChange = (data) => {
    this.setState({ color: data.target.value });
  };

  onExcessVehicleModelChange = (data) => {
    this.setState({ model: data.target.value });
  };

  onExcessVehicleLocationChange = (e, data) => {
    this.setState({ location: data.value });
  };

  onExcessVehicleConditionChange = (e, data) => {
    this.setState({ vehicleCondition: data.value });
  };

  onExcessVehicleRemarksChange = (data) => {
    this.setState({ remarks: data.target.value });
  };

  onHypothecationDetailsChange = (e, data) => {
    this.setState({ hypothecationValues: data.value });
  };

  onForm21Change = (e, data) => {
    this.setState({ form21: data.value });
  };

  onForm22Change = (e, data) => {
    this.setState({ form22: data.value });
  };

  saveAudit = () => {
    var excessVehicle = this.state.selectedVehicle;
    excessVehicle.vehicleStatus = this.state.vehicleStatus;
    excessVehicle.model = this.state.model;
    excessVehicle.color = this.state.color;
    excessVehicle.location = this.state.location;
    excessVehicle.odometerReading = this.state.odometer;
    excessVehicle.vehicleCondition = this.state.vehicleCondition;
    excessVehicle.remarks = this.state.remarks;
    excessVehicle.form21 = this.state.form21;
    excessVehicle.form22 = this.state.form22;
    excessVehicle.hypothecationValues = this.state.hypothecationValues;
    excessVehicle.photos = this.state.photos;
    if (this.state.toUpload != null && this.state.toUpload.length > 0) {
      this.props.uploadPhoto(this.state.toUpload, {
        templateId: this.props.dealer.templateId,
        excessVehicle: excessVehicle._id,
        type: "excessVehicle",
      });
    }
    this.props.updateExcessVehicle(excessVehicle);
    this.setState({
      showPopup: false,
      newAudit: {},
      excessVehicleEditMode: false,
    });
  };

  excessVehicleUploadPhoto = (data) => {
    this.setState({ toUpload: data.target.files });
  };

  render() {
    var cols = [
      {
        title: "",
        field: "",
        render: (rowData) => {
          return <Label size="medium"></Label>;
        },
      },
      {
        title: "Chassis Number (VIN)",
        field: "VIN",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.VIN}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.VIN.toLowerCase().includes(term.toLowerCase()),
      },
      {
        title: "Model Name",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.model}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.model.toLowerCase().includes(term.toLowerCase()),
      },
      {
        title: "Location",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.location}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.location.toLowerCase().includes(term.toLowerCase()),
      },
      {
        title: "Condition",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.vehicleCondition}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.vehicleCondition.toLowerCase().includes(term.toLowerCase()),
      },
      {
        title: "Status",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.vehicleStatus}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.vehicleStatus.toLowerCase().includes(term.toLowerCase()),
      },
    ];

    var selectedVehicle = this.state.selectedVehicle;

    const Map = compose(
      withProps({
        googleMapURL:
          "https://maps.googleapis.com/maps/api/js?key=" +
          config.google_map_api_key,
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
      }),
      withScriptjs,
      withGoogleMap
    )((props) => (
      <GoogleMap
        defaultZoom={10}
        defaultCenter={{
          lat: selectedVehicle.latitude,
          lng: selectedVehicle.longitude,
        }}
      >
        {props.isMarkerShown && (
          <Marker
            position={{
              lat: selectedVehicle.latitude,
              lng: selectedVehicle.longitude,
            }}
          />
        )}
      </GoogleMap>
    ));

    return (
      <div style={{ flexGrow: 1 }}>
        <Header style={{ color: this.props.backColor(this.props.color) }}>
          Excess Vehicles
        </Header>
        <div style={{ flexGrow: 1, display: "flex", flexFlow: "column" }}>
          <div style={{ display: "flex", flexGrow: 1, flexFlow: "column" }}>
            <TableView
              selection={false}
              data={this.state.excessVehicles}
              columns={cols}
            />
          </div>
        </div>
        {this.state.showPopup == true && (
          <Modal open={true} onClose={this.close}>
            <Modal.Header
              style={{
                backgroundColor: this.props.backColor(this.props.color),
                color: this.props.fontColor(this.props.color),
              }}
            >
              {selectedVehicle.model}
            </Modal.Header>
            {!this.state.excessVehicleEditMode && (
              <div>
                <Modal.Content
                  style={{
                    backgroundColor: this.props.matchTint(this.props.color),
                  }}
                >
                  <Modal.Description>
                    <List>
                      <List.Item>
                        <div style={{ marginTop: 10 }}>
                          <span
                            style={{
                              verticalAlign: "middle",
                              width: 200,
                              display: "inline-block",
                              fontSize: 15,
                              fontWeight: "bold",
                            }}
                          >
                            VIN number:{" "}
                          </span>
                          <span
                            style={{
                              verticalAlign: "middle",
                              fontSize: 15,
                              color: "#2ecc71",
                              marginLeft: 15,
                            }}
                          >
                            {selectedVehicle.VIN}
                          </span>
                        </div>
                      </List.Item>
                      <List.Item>
                        <div style={{ marginTop: 10 }}>
                          <span
                            style={{
                              verticalAlign: "middle",
                              width: 200,
                              display: "inline-block",
                              fontSize: 15,
                              fontWeight: "bold",
                            }}
                          >
                            Vehicle Status:{" "}
                          </span>
                          <span
                            style={{
                              verticalAlign: "middle",
                              fontSize: 15,
                              marginLeft: 15,
                            }}
                          >
                            {selectedVehicle.vehicleStatus}
                          </span>
                        </div>
                      </List.Item>
                      <List.Item>
                        <div style={{ marginTop: 10 }}>
                          <span
                            style={{
                              verticalAlign: "middle",
                              width: 200,
                              display: "inline-block",
                              fontSize: 15,
                              fontWeight: "bold",
                            }}
                          >
                            Model:{" "}
                          </span>
                          <span
                            style={{
                              verticalAlign: "middle",
                              fontSize: 15,
                              marginLeft: 15,
                            }}
                          >
                            {selectedVehicle.model}
                          </span>
                        </div>
                      </List.Item>
                      <List.Item>
                        <div style={{ marginTop: 10 }}>
                          <span
                            style={{
                              verticalAlign: "middle",
                              width: 200,
                              display: "inline-block",
                              fontSize: 15,
                              fontWeight: "bold",
                            }}
                          >
                            Color:{" "}
                          </span>
                          <span
                            style={{
                              verticalAlign: "middle",
                              fontSize: 15,
                              marginLeft: 15,
                            }}
                          >
                            {selectedVehicle.color}
                          </span>
                        </div>
                      </List.Item>
                      <List.Item>
                        <div style={{ marginTop: 10 }}>
                          <span
                            style={{
                              verticalAlign: "middle",
                              width: 200,
                              display: "inline-block",
                              fontSize: 15,
                              fontWeight: "bold",
                            }}
                          >
                            Location:{" "}
                          </span>
                          <span
                            style={{
                              verticalAlign: "middle",
                              fontSize: 15,
                              marginLeft: 15,
                            }}
                          >
                            {selectedVehicle.location}
                          </span>
                        </div>
                      </List.Item>
                      <List.Item>
                        <div style={{ marginTop: 10 }}>
                          <span
                            style={{
                              verticalAlign: "middle",
                              width: 200,
                              display: "inline-block",
                              fontSize: 15,
                              fontWeight: "bold",
                            }}
                          >
                            Odometer reading:{" "}
                          </span>
                          <span
                            style={{
                              verticalAlign: "middle",
                              fontSize: 15,
                              marginLeft: 15,
                            }}
                          >
                            {selectedVehicle.odometerReading}
                          </span>
                        </div>
                      </List.Item>
                      <List.Item>
                        <div style={{ marginTop: 10 }}>
                          <span
                            style={{
                              verticalAlign: "middle",
                              width: 200,
                              display: "inline-block",
                              fontSize: 15,
                              fontWeight: "bold",
                            }}
                          >
                            Vehicle Condition:{" "}
                          </span>
                          <span
                            style={{
                              verticalAlign: "middle",
                              fontSize: 15,
                              marginLeft: 15,
                            }}
                          >
                            {selectedVehicle.vehicleCondition}
                          </span>
                        </div>
                      </List.Item>
                      <List.Item>
                        <div style={{ marginTop: 10 }}>
                          <span
                            style={{
                              verticalAlign: "middle",
                              width: 200,
                              display: "inline-block",
                              fontSize: 15,
                              fontWeight: "bold",
                            }}
                          >
                            Vehicle Remarks:{" "}
                          </span>
                          <span
                            style={{
                              verticalAlign: "middle",
                              fontSize: 15,
                              marginLeft: 15,
                            }}
                          >
                            {selectedVehicle.remarks}
                          </span>
                        </div>
                      </List.Item>
                      <List.Item>
                        <Image.Group size="mini" style={{ cursor: "pointer" }}>
                          {selectedVehicle.photos != null &&
                            selectedVehicle.photos.length > 0 &&
                            selectedVehicle.photos.map((p, i) => {
                              return (
                                <Image
                                  key={i}
                                  src={storageBaseUrl + p}
                                  onClick={((k) => {
                                    return (e) => this.handlePhotoClick(e, k);
                                  })(p)}
                                />
                              );
                            })}
                        </Image.Group>
                      </List.Item>
                      <List.Item>
                        <div style={{ marginTop: 10 }}>
                          <span
                            style={{
                              verticalAlign: "middle",
                              width: 200,
                              display: "inline-block",
                              fontSize: 15,
                              fontWeight: "bold",
                            }}
                          >
                            Form 21 Available:{" "}
                          </span>
                          <span
                            style={{
                              verticalAlign: "middle",
                              fontSize: 15,
                              marginLeft: 15,
                            }}
                          >
                            {selectedVehicle.form21
                              ? selectedVehicle.form21
                              : "Not available"}
                          </span>
                        </div>
                      </List.Item>
                      <List.Item>
                        <div style={{ marginTop: 10 }}>
                          <span
                            style={{
                              verticalAlign: "middle",
                              width: 200,
                              display: "inline-block",
                              fontSize: 15,
                              fontWeight: "bold",
                            }}
                          >
                            Form 22 Available:{" "}
                          </span>
                          <span
                            style={{
                              verticalAlign: "middle",
                              fontSize: 15,
                              marginLeft: 15,
                            }}
                          >
                            {selectedVehicle.form22
                              ? selectedVehicle.form22
                              : "Not available"}
                          </span>
                        </div>
                      </List.Item>
                      <List.Item>
                        <div style={{ marginTop: 10 }}>
                          <span
                            style={{
                              verticalAlign: "middle",
                              width: 200,
                              display: "inline-block",
                              fontSize: 15,
                              fontWeight: "bold",
                            }}
                          >
                            Hypothecation values available:{" "}
                          </span>
                          <span
                            style={{
                              verticalAlign: "middle",
                              fontSize: 15,
                              marginLeft: 15,
                            }}
                          >
                            {selectedVehicle.hypothecationValues
                              ? selectedVehicle.hypothecationValues
                              : "Not available"}
                          </span>
                        </div>
                      </List.Item>
                    </List>
                    {selectedVehicle.latitude && selectedVehicle.longitude ? (
                      <Map isMarkerShown />
                    ) : null}
                  </Modal.Description>
                </Modal.Content>
              </div>
            )}
            {this.state.excessVehicleEditMode && (
              <Modal.Content
                style={{
                  backgroundColor: this.props.matchTint(this.props.color),
                }}
              >
                <Modal.Description>
                  <Segment.Group>
                    <Segment>
                      <Input
                        type="file"
                        content="Upload additional Photos"
                        icon="cloud upload"
                        labelPosition="right"
                        onChange={this.excessVehicleUploadPhoto}
                        multiple
                      />
                    </Segment>
                  </Segment.Group>
                  <Segment.Group>
                    <Segment>
                      <div style={{ marginTop: 10 }}>
                        <span style={{ fontSize: 15, fontWeight: "bold" }}>
                          Vehicle Status:{" "}
                        </span>
                        <span style={{ marginLeft: "22px" }}>
                          <Radio
                            label={"New"}
                            name={"vehicleStatus"}
                            value={"New"}
                            checked={this.state.vehicleStatus === "New"}
                            onChange={this.onExcessVehicleStatusChange}
                          />
                          <Radio
                            label={"Demo"}
                            name={"vehicleStatus"}
                            value={"Demo"}
                            style={{ marginLeft: 10 }}
                            checked={this.state.vehicleStatus === "Demo"}
                            onChange={this.onExcessVehicleStatusChange}
                          />
                        </span>
                      </div>
                    </Segment>
                    <Segment>
                      <div style={{ marginTop: 10 }}>
                        <span style={{ fontSize: 15, fontWeight: "bold" }}>
                          Model:{" "}
                        </span>
                        <Input
                          style={{ marginLeft: "22px", width: "120px" }}
                          onChange={this.onExcessVehicleModelChange}
                          placeholder="Vehicle model.. "
                          value={this.state.model}
                        />
                      </div>
                    </Segment>
                    <Segment>
                      <div style={{ marginTop: 10 }}>
                        <span style={{ fontSize: 15, fontWeight: "bold" }}>
                          Color:{" "}
                        </span>
                        <Input
                          style={{ marginLeft: "22px", width: "120px" }}
                          onChange={this.onExcessVehicleColorChange}
                          placeholder="Vehicle color.. "
                          value={this.state.color}
                        />
                      </div>
                    </Segment>
                    <Segment>
                      <div style={{ marginTop: 10 }}>
                        <span style={{ fontSize: 15, fontWeight: "bold" }}>
                          Vehicle Location:{" "}
                        </span>
                        <span style={{ marginLeft: "22px" }}>
                          <Radio
                            label={"Showroom"}
                            name={"vehicleLocation"}
                            value={"Showroom"}
                            checked={this.state.location === "Showroom"}
                            onChange={this.onExcessVehicleLocationChange}
                          />
                          <Radio
                            label={"Yard"}
                            name={"vehicleLocation"}
                            value={"Yard"}
                            style={{ marginLeft: 10 }}
                            checked={this.state.location === "Yard"}
                            onChange={this.onExcessVehicleLocationChange}
                          />
                          <Radio
                            label={"Others"}
                            name={"vehicleLocation"}
                            value={"Others"}
                            style={{ marginLeft: 10 }}
                            checked={this.state.location === "Others"}
                            onChange={this.onExcessVehicleLocationChange}
                          />
                        </span>
                      </div>
                    </Segment>
                    <Segment>
                      <div style={{ marginTop: 10 }}>
                        <span style={{ fontSize: 15, fontWeight: "bold" }}>
                          Odometer Reading:{" "}
                        </span>
                        <Input
                          style={{ marginLeft: "22px", width: "120px" }}
                          onChange={this.onExcessVehicleOdometerChange}
                          placeholder="Odometer reading: "
                          value={this.state.odometer}
                        />
                      </div>
                    </Segment>
                    <Segment>
                      <div style={{ marginTop: 10 }}>
                        <span style={{ fontSize: 15, fontWeight: "bold" }}>
                          Vehicle Condition:{" "}
                        </span>
                        <span style={{ marginLeft: "22px" }}>
                          <Radio
                            label={"Good"}
                            name={"vehicleCondition"}
                            value={"Good"}
                            checked={this.state.vehicleCondition === "Good"}
                            onChange={this.onExcessVehicleConditionChange}
                          />
                          <Radio
                            label={"Damaged"}
                            name={"vehicleCondition"}
                            value={"Damage"}
                            style={{ marginLeft: 10 }}
                            checked={this.state.vehicleCondition === "Damage"}
                            onChange={this.onExcessVehicleConditionChange}
                          />
                        </span>
                      </div>
                    </Segment>
                    <Segment>
                      <div style={{ marginTop: 10 }}>
                        <span style={{ fontSize: 15, fontWeight: "bold" }}>
                          Remarks:{" "}
                        </span>
                        <Input
                          style={{ marginLeft: "22px", width: "120px" }}
                          onChange={this.onExcessVehicleRemarksChange}
                          placeholder="Remarks..: "
                          value={this.state.remarks}
                        />
                      </div>
                    </Segment>
                    <Segment>
                      <div style={{ marginTop: 10 }}>
                        <span style={{ fontSize: 15, fontWeight: "bold" }}>
                          Form 21 Available:{" "}
                        </span>
                        <span style={{ marginLeft: "22px" }}>
                          <Radio
                            label={"Yes"}
                            name={"form21"}
                            value={"Yes"}
                            checked={this.state.form21 === "Yes"}
                            onChange={this.onForm21Change}
                          />
                          <Radio
                            label={"No"}
                            name={"form21"}
                            value={"No"}
                            style={{ marginLeft: 10 }}
                            checked={this.state.form21 === "No"}
                            onChange={this.onForm21Change}
                          />
                        </span>
                      </div>
                    </Segment>
                    <Segment>
                      <div style={{ marginTop: 10 }}>
                        <span style={{ fontSize: 15, fontWeight: "bold" }}>
                          Form 22 Available:{" "}
                        </span>
                        <span style={{ marginLeft: "22px" }}>
                          <Radio
                            label={"Yes"}
                            name={"form22"}
                            value={"Yes"}
                            checked={this.state.form22 === "Yes"}
                            onChange={this.onForm22Change}
                          />
                          <Radio
                            label={"No"}
                            name={"form22"}
                            value={"No"}
                            style={{ marginLeft: 10 }}
                            checked={this.state.form22 === "No"}
                            onChange={this.onForm22Change}
                          />
                        </span>
                      </div>
                    </Segment>
                    <Segment>
                      <div style={{ marginTop: 10 }}>
                        <span style={{ fontSize: 15, fontWeight: "bold" }}>
                          Hypothecation Values:{" "}
                        </span>
                        <span style={{ marginLeft: "22px" }}>
                          <Radio
                            label={"Yes"}
                            name={"hypothecationValues"}
                            value={"Yes"}
                            checked={this.state.hypothecationValues === "Yes"}
                            onChange={this.onHypothecationDetailsChange}
                          />
                          <Radio
                            label={"No"}
                            name={"hypothecationValues"}
                            value={"No"}
                            style={{ marginLeft: 10 }}
                            checked={this.state.hypothecationValues === "No"}
                            onChange={this.onHypothecationDetailsChange}
                          />
                        </span>
                      </div>
                    </Segment>
                    <Segment style={{ height: 200 }}></Segment>
                  </Segment.Group>
                </Modal.Description>
              </Modal.Content>
            )}
            <Modal.Actions>
              {!this.state.excessVehicleEditMode && (
                <Button
                  style={{
                    backgroundColor: this.props.backColor(this.props.color),
                    color: this.props.fontColor(this.props.color),
                  }}
                  onClick={this.editAudit}
                >
                  Edit
                </Button>
              )}
              {this.state.excessVehicleEditMode && (
                <Button
                  style={{
                    backgroundColor: this.props.backColor(this.props.color),
                    color: this.props.fontColor(this.props.color),
                  }}
                  onClick={this.saveAudit}
                >
                  Save
                </Button>
              )}
              <Button color="black" onClick={this.closePopup}>
                Cancel
              </Button>
            </Modal.Actions>
          </Modal>
        )}
        {this.state.showNoAuditFound && (
          <Modal open={this.state.showNoAuditFound} onClose={this.handleCancel}>
            <Modal.Header>Excess vehicle Not Found</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <div class="ui message">
                  <div class="header">No excess vehicles found.</div>
                </div>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button color="black" onClick={this.handleCancel}>
                Nope
              </Button>
            </Modal.Actions>
          </Modal>
        )}
      </div>
    );
  }
}

function AuditTableCell(props) {
  function onClick() {
    props.onClick(props.row);
  }
  return (
    <div style={props.style} onClick={onClick}>
      {props.text}
    </div>
  );
}

// ExcessVehicles.PropTypes = {
//     excessVehicles: PropTypes.array,
//     audits: PropTypes.array,
//     dealer: PropTypes.object,
//     summary: PropTypes.object,
// }

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    audit: state.audits.audit,
    excessVehicles: state.audits.excessVehicles,
    summary: state.audits.dealerSummary,
    audits: state.audits.audits,
    savedPhotos: state.savedPhotos,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchExcessVehiclesAction: fetchExcessVehiclesAction,
      uploadPhoto: uploadDaimlerPhotoAction,
      updateExcessVehicle: updateExcessVehicleAction,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ExcessVehicles)
);
