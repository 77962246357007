import React from 'react';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";


const DatePicker = (props) => {
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
                autoOk
                variant="inline"
                inputVariant="outlined"
                label={props.label}
                format="DD/MM/YYYY"
                value={props.value}
                InputAdornmentProps={{ position: "start" }}
                minDate={props.minDate !== undefined ? props.minDate : undefined}
                onChange={props.handleDateChange !== undefined ? props.handleDateChange : null}
                disabled={props.disabled !== undefined ? props.disabled : false}
                InputProps={{
                    style: {
                        fontSize: 14,
                        height: 37,
                    },
                }}
                style={{ width: "100%", height: "30px" }}
            />
        </MuiPickersUtilsProvider>)
}

export default DatePicker