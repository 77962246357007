import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn } from "./../../util";
import {
  UploadUserCsv,
  dismissLogs,
  initiateUpload,
} from "../../actions/control_panel_action";
import { Dimmer, Loader } from "semantic-ui-react";
import { Button, Modal } from "semantic-ui-react";
import CommonForAllPannels from "./CommonForAllPannels.jsx";

class TanishqAndWOTControlPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userCsvFile: null,
      color: "",
    };
  }

  componentWillMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
    }
  }
  componenDidMount() {
    if (this.props.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.auth.color !== prevProps.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!isLoggedIn(nextProps.auth)) {
      this.props.history.push(`/login`);
      return false;
    }
    return true;
  }

  tanishqhandleUserFileUpload = (files) => {
    const file = files.target.files[0];
    this.setState({ userCsvFile: file });
    files.target.files = null;
  };

  tanishquploadUserData = () => {
    let tanishqUrl = "/admin/v1/tanishq/addUsers";
    this.props.initiateUpload(true);
    this.props.uploadUserCsv(tanishqUrl, this.state.userCsvFile);
  };

  tanishqcloseLog = () => {
    this.props.dismissLogs(false);
  };

  render() {
    let tanishqPannel = [
      {
        itemForSpan: "Upload User CSV to add Users",
        itemInputChange: this.tanishqhandleUserFileUpload,
        itemClickButton: this.tanishquploadUserData,
        itemContentForButton: "Upload User CSV",
      },
    ];
    return (
      <div
        style={{
          marginLeft: "2vw",
          marginTop: "7vh",
          fontWeight: "bold",
          fontFamily: "Arial, Helvetica, sans-serif",
        }}
      >
        <Dimmer active={this.props.controlPanel.uploadIncomplete}>
          <Loader size="massive">Uploading</Loader>
        </Dimmer>
        <CommonForAllPannels child={tanishqPannel} color={this.props.color} />
        {this.props.controlPanel.showLog && (
          <Modal
            close={this.tanishqcloseLog}
            open={this.props.controlPanel.showLog}
          >
            <Modal.Header>Upload Report</Modal.Header>
            <Modal.Content>
              <div
                dangerouslySetInnerHTML={{
                  __html: this.props.controlPanel.log,
                }}
              />
            </Modal.Content>
            <Modal.Actions>
              <Button color="black" onClick={this.tanishqcloseLog}>
                Dismiss
              </Button>
            </Modal.Actions>
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    home: state.home,
    controlPanel: state.controlPanel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      uploadUserCsv: UploadUserCsv,
      initiateUpload: initiateUpload,
      dismissLogs: dismissLogs,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TanishqAndWOTControlPanel)
);
