import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "react-datepicker/dist/react-datepicker.css";
import IconPicker from "../commonViews/IconPicker.jsx";
import "react-notifications/lib/notifications.css";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import TableView from "../commonViews/TableView.jsx";
import {
    getTextColor,
    getMainBackground,
    getFontColor,
} from "../../ThemeHandlers/theme";
import PGDLocation from "./Locations.jsx";
import { fetchPGDMasterAction, fetchPGDCitiesList } from '../../actions/pgd_actions'
import Metrics from "../commonViews/Metrics.jsx";
import Audits from "./Audits.jsx";

export class CityList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            citiesListView: true,
            selectedCustomer: {},
            auditid: "",
            selection: [],
            isLoading: false,
            locationsList: [],
            counts: {},
            templates: [],
            templateId: ''
        };
    }
    componentDidMount() {
        // console.log(this.props.selectedTemplate._id, 'template')
        this.setState({ isLoading: true });
        let templateId = this.props.selectedTemplate._id;
        this.setState({ templates: this.props.selectedTemplate, templateId:  this.props.selectedTemplate._id})
        this.props.fetchPGDCitiesList({
            templateId,
            onSuccess: (res) => {
                console.log("Response : ", res)
                this.setState({ isLoading: false, locationsList: res.cityList });
            },
            onFailure: (err) => {
                console.log(err)
                this.setState({ isLoading: false });
            },
        });
    }
    auditIdFromOlaAudit = (auditId) => {
        this.setState({
            auditid: auditId,
        });
    };

    dtchandleTableViewAuditClick = (customer) => {
        this.setState({ selectedCustomer: customer, citiesListView: false });
    };

    onCityViewClose = () => {
        this.setState({
            citiesListView: true,
        });
    };
    render() {

        const pgdCol = [
            {
                title: "City Name",
                field: "code",
                render: (rowData) => (
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            this.dtchandleTableViewAuditClick(rowData);
                        }}
                    >
                        {rowData.cityName ? rowData.cityName : ""}
                    </span>
                ),
                customFilterAndSearch: (term, rowData) =>
                    rowData.cityName.toLowerCase().indexOf(term.toLowerCase()) > -1,
            },
            {
                title: "Pending Audits",
                field: "",
                render: (rowData) => (
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            this.dtchandleTableViewAuditClick(rowData);
                        }}
                    >
                        {rowData.Pending ? rowData.Pending  : ""}
                    </span>
                ),
                // customFilterAndSearch: (term, rowData) =>
                //     rowData.Pending.toLowerCase().indexOf(term.toLowerCase()) > -1,
            },
            {
                title: "Completed Audits",
                field: "",
                render: (rowData) => (
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            this.dtchandleTableViewAuditClick(rowData);
                        }}
                    >
                      {rowData["Completed"] !== undefined && rowData["Completed"]}
                    </span>
                ),
                // customFilterAndSearch: (term, rowData) =>
                //     rowData.Completed.toLowerCase().indexOf(term.toLowerCase()) > -1,
            },
        ];

        return (
            <div>
                <NotificationContainer />
                {this.state.citiesListView && (
                    <div>
                        <div style={{ display: "inline-block", marginBottom: 20 }}>
                            <IconPicker
                                name="arrow left"
                                click={this.props.onClose}
                                styles={{
                                    color: getTextColor(this.props.color),
                                    float: "right",
                                    cursor: "pointer",
                                    marginTop: 35,
                                    position: "absolute",
                                    right: 20,
                                }}
                            />
                            <h1
                                style={{
                                    display: "inline-block",
                                    color: getTextColor(this.props.color),
                                }}
                            >
                                Cities List
                            </h1>

                        </div>
                    </div>
                )}
                {this.state.citiesListView && (
                    <div
                        style={{
                            display: "flex",
                            flexGrow: 1,
                            flexFlow: "column",
                            marginTop: 20,

                        }}
                    >
                        <TableView
                            data={this.state.locationsList}
                            columns={pgdCol}
                            load={this.state.isLoading}
                        />

                    </div>
                )}

                {!this.state.citiesListView && (
                    <PGDLocation
                        selectedCity={this.state.selectedCustomer}
                        templateId={this.state.templateId}
                        onClose={this.onCityViewClose}
                        color={this.props.color}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            fetchPGDMasterAction,
            fetchPGDCitiesList
        },
        dispatch
    );
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CityList)
);
