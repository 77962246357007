import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn } from "../../util";
import Slider from "react-slick";
import { Segment, Image, Modal, Label, Input } from "semantic-ui-react";
import NoteModal from "../NoteModal.jsx";
import config from "../../config.js";

const storageBaseUrl = config["storage_base_url"];

class YamahaQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSection: "",
      selectedPhoto: "",
      showPhotoSlider: false,
      selectedAnswer: "",
      answerText: ""
    };
  }

  componentDidMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
    }

    if (this.props.question.answerType != "options") {
      this.setState({ answerText: this.props.question.answerText });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
      return false;
    }
    return true;
  }

  handlePhotoClick = (e, p) => {
    this.setState({
      selectedPhoto: p,
      showPhotoSlider: true
    });
  };

  photoSliderClose = e => {
    this.setState({
      showPhotoSlider: false
    });
  };

  handleLabelClick = (e, value) => {
    if (!this.props.editMode) return;

    this.props.question.answer = value;
    this.setState({
      selectedAnswer: value
    });
  };

  onAnswerChange = e => {
    this.props.question.answerText = e.target.value;
    this.setState({ answerText: e.target.value });
  };

  render() {
    var q = this.props.question;

    q.actualScore = q.actualScore || 0;

    function getAnswerColor(o) {
      // This is a hack, This is to change the answer options color accordingly. Needs to be revised maybe. :-(
      var threeOptions = ["Yes", "No", "Not Applicable"];
      if (o.value === q.answer) {
        if (threeOptions.includes(o.label)) {
          if (o.value === 0) {
            return "red";
          } else {
            return "green";
          }
        }
      } else {
        return "lightgrey";
      }
    }

    return (
      <Segment style={{ display: "flex" }}>
        <div style={{ flexGrow: 1 }}>
          <Modal
            dimmer="blurring"
            size="fullscreen"
            basic
            open={this.state.showPhotoSlider}
            onClose={this.photoSliderClose}
          >
            <Modal.Content>
              <Slider infinite={false} variableWidth={true} centerMode={true}>
                {q.photos.map((p, i) => {
                  return (
                    <img key={i} src={storageBaseUrl + p.uploadedImageUrl} />
                  );
                })}
              </Slider>
            </Modal.Content>
          </Modal>

          <span
            style={{
              lineHeight: 1.5,
              color: "#404040",
              fontSize: 15,
              display: "block",
              marginBottom: 10
            }}
          >
            {q.question}
          </span>

          {q.answerType === "options"
            ? q.options.map((o, i) => {
                return (
                  <Label
                    key={i}
                    style={{ cursor: "pointer" }}
                    onClick={e => this.handleLabelClick(e, o.value)}
                    color={getAnswerColor(o)}
                  >
                    {o.label}
                  </Label>
                );
              })
            : <Input
                value={this.state.answerText}
                disabled={!this.props.editMode}
                type={q.answerType === "text" ? "text" : "number"}
                onChange={this.onAnswerChange}
              />}

          <br />
          <br />
          <NoteModal editMode={this.props.editMode} question={q} />
          <br />
          <br />
          {q.hasPhoto &&
            <Image.Group size="mini" style={{ cursor: "pointer" }}>
              {q.photos.map((m, i) => {
                if (m.uploadedImageUrl != null) {
                  return (
                    <Image
                      key={i}
                      src={storageBaseUrl + m.uploadedImageUrl}
                      onClick={(p => {
                        return e => this.handlePhotoClick(e, p);
                      })}
                    />
                  );
                }
              })}
            </Image.Group>}
        </div>
      </Segment>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(YamahaQuestion)
);
