import React from "react";
import { Menu } from "semantic-ui-react";
import Dashboard from "../Dashboard";
import TimeSheetTemplate from "./TimeSheetTemplate";

class TabviewTimeSheet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: "TimeSheet",
    };
  }

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };

  render() {
    const { activeItem } = this.state;

    return (
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          flexFlow: "column",
          padding: 5,
        }}
      >
        <div>
          <Menu pointing secondary widths={5}>
            {/* <Menu.Item
                            name="Dashboard"
                            active={activeItem === "Dashboard"}
                            onClick={this.handleItemClick}
                        /> */}

            <Menu.Item
              name="TimeSheet"
              active={activeItem === "TimeSheet"}
              onClick={this.handleItemClick}
            />
          </Menu>
        </div>
        <div>
          {activeItem === "TimeSheet" && <TimeSheetTemplate />}

          {activeItem === "Dashboard" && <Dashboard />}
        </div>
      </div>
    );
  }
}

export default TabviewTimeSheet;
