import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import moment from "moment";
import {
  Segment,
  Grid,
  Input,
  Menu,
} from "semantic-ui-react";
import ModalView from "../commonViews/ModalView";
import IconPicker from '../commonViews/IconPicker'
// import DatePicker from "react-datepicker";
import DatePicker from "../commonViews/DatePicker.jsx";
import TableView from "../commonViews/TableView.jsx";
import {
  getTextColor,
  getMainBackground,
  getMatchingTint,
  getFontColor,
} from "../../ThemeHandlers/theme";
import "react-datepicker/dist/react-datepicker.css";
import {
  fetchFurlencoAuditsAction,
  saveFurlencoAuditAction,
  downloadFurlencoAuditReportAction,
} from "../../actions/furlenco_actions";
import FurlencoAuditTypes from "./FurlencoAuditTypes.jsx";
import ConstantLabel from "../commonViews/ConstantLabel.jsx";
import DropDownPicker from "../commonViews/DropDownPicker.jsx";
import TextInput from "../commonViews/TextInput.jsx";

export class FurlencoAudits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: "customerPoint",
      editMode: false,
      editModalAudit: false,
      onModalOpen: false,
      audit: null,
      startDate: new Date(),
      secondDate: new Date(),
      setDate: {},
      audits: {},
    };
  }
  componentDidMount() {
    this.props.fetchFurlencoAuditsAction(this.props.customer._id);
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.Furlenco.furlencoAudits != this.props.Furlenco.furlencoAudits
    ) {
      this.setState({
        editedAudit: nextProps.Furlenco.furlencoAudits,
      });
    }
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.Furlenco.furlencoAudits !== prevProps.Furlenco.furlencoAudits
    ) {
      this.setState({
        audits: this.props.Furlenco.furlencoAudits,
      });
    }
  }
  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  // handleQuestionsDateChange = (quesId, date) => {
  //   var ans = this.state.setDate;
  //   ans[quesId] = date;

  //   this.setState({ setDate: ans });
  // };

  handleDateChange = (question, date) => {
    let answer = "";
    let audit = this.state.editedAudit;
    if (question.answerType === "date") {
      answer = moment(date).toISOString();
      audit.questions.map((q) => {
        if (q._id == question._id) {
          q.answer = answer;
        }
      });
    }
    this.setState({
      startDate: date,
      editedAudit: audit,
    });
  };
  handleAuditClick = (audit) => {
    this.setState({
      onModalOpen: true,
      audit: audit,
    });
  };
  onModalClose = () => {
    this.setState({
      onModalOpen: false,
    });
  };
  handleDownloadReportClick = (auditId) => {
    if (!auditId) {
      this.setState({ showReportDataNotFound: true });
      return;
    } else {
      this.props.downloadFurlencoAuditReportAction(
        this.props.Furlenco.furlencoAudits._id
      );
    }
  };
  onDownloadPhotos = (id) => {
    let open = `#/images/${id}?tag=furlenco`;
    window.open(open);
  };

  editAudit = () => {
    this.setState({ editMode: true });
  };
  editModalAudit = () => {
    this.setState({ editModalAudit: true });
  };
  cancelEditAudit = () => {
    this.setState({ editMode: false });
  };

  changeAnswers = (question, answer, type) => {
    let audit = this.state.audit;
    audit.questionnaire.map((ques) => {
      ques.questions.map((ans) => {
        if (ans._id == question._id) {
          ans.answer = answer;
        }
      });
    });

    this.setState({ audit: audit });
  };

  changeFurnituresAnswer = (question, answerObject) => {
    let answer = "";
    let type = "";

    if (question.answerType == "text") {
      answer = answerObject.target.value;
      type = "text";
      this.changeAnswers(question, answer, type);
    }
    if (question.answerType == "number") {
      answer = answerObject.target.value;
      type = "number";
      this.changeAnswers(question, answer, type);
    }

    if (question.answerType == "options") {
      answer = answerObject;
      type = "options";
      this.changeAnswers(question, answer, type);
    }
  };

  ChangeCommonQuestionsAnswer = (question, answerObject, date) => {
    let answer = "";
    let audit = this.state.editedAudit;
    if (question.answerType == "text") {
      answer = answerObject.target.value;

      audit.questions.map((q) => {
        if (q._id == question._id) {
          q.answer = answer;
        }
      });
    }
    if (question.answerType == "options") {
      answer = answerObject;
      audit.questions.map((q) => {
        if (q._id == question._id) {
          q.answer = answer;
        }
      });
    }
    this.setState({ editedAudit: audit });
  };
  saveAudit = () => {
    let audit = this.state.editedAudit;
    this.props.saveFurlencoAuditAction(audit._id, audit);

    this.setState({
      editMode: false,
      editModalAudit: false,
      onModalOpen: false,
    });
  };

  render() {
    var audit = this.props.Furlenco.furlencoAudits;

    var columns = [
      {
        title: "Product Type",
        field: "currentFurniture",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleAuditClick(rowData);
              }}
            >
              {rowData.currentFurniture.name}
            </span>
          );
        },
      },
    ];

    return (
      <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
        {audit != undefined && (
          <HeaderView
            style={{ marginTop: "5%" }}
            selectedAudit={this.props.Furlenco.furlencoAudits}
            editMode={this.state.editMode}
            onDownloadPhotos={this.onDownloadPhotos}
            editAudit={this.editAudit}
            saveAudit={this.saveAudit}
            cancelEditAudit={this.cancelEditAudit}
            reportDownload={this.handleDownloadReportClick}
            color={this.props.color}
          />
        )}

        <IconPicker
          click={this.props.onClose}
          name={"arrow left"}
          size={'large'}
          styles={{
            color: getTextColor(this.props.color),
            float: "right",
            cursor: "pointer",
            marginTop: 20,
            marginRight: "20px",
            position: "absolute",
            right: 1,
          }}
        />

        {audit != undefined && (
          <CommonQuestions
            state={this.state}
            audits={this.props.Furlenco.furlencoAudits}
            changeAnswer={this.ChangeCommonQuestionsAnswer}
            handleChange={this.handleDateChange}
            color={this.props.color}
          />
        )}
        <TableView
          data={
            Object.keys(this.state.audits).length !== 0
              ? this.state.audits?.furnitures !== undefined &&
              this.state.audits.furnitures
              : []
          }
          columns={columns}
        // rowClick={this.handleAuditClick}
        />
        {audit != undefined && this.state.onModalOpen && (
          <ModalViews
            isVisible={this.state.onModalOpen}
            onVisiblityChange={this.handleAuditClick}
            audits={this.state.audit}
            state={this.state}
            save={this.saveAudit}
            editAudit={this.editModalAudit}
            cancelEditAudit={this.cancelEditAudit}
            onModalClose={this.onModalClose}
            handleItemClick={this.handleItemClick}
            handleChange={this.handleDateChange}
            changeAnswer={this.changeFurnituresAnswer}
            color={this.props.color}
          />
        )}
      </div>

    );
  }
}

const ModalViews = function (props) {
  const { activeItem } = props.state;
  return (
    <ModalView
      open={props.isVisible}
      content={
        <>
          <Menu
            pointing
            size="small"
            color="white"
            style={{
              width: "100%",
            }}
          >
            <Menu.Item
              name="customerPoint"
              active={activeItem === "customerPoint"}
              onClick={props.handleItemClick}
            />
            <Menu.Item
              name="Warehouse"
              active={activeItem === "Warehouse"}
              onClick={props.handleItemClick}
            />
            <Menu.Item
              name="CustomerDetails"
              active={activeItem === "CustomerDetails"}
              onClick={props.handleItemClick}
            />
          </Menu>
          {activeItem === "customerPoint" && (
            <FurlencoAuditTypes
              item={activeItem}
              state={props.state}
              audits={props.audits}
              changeAns={props.changeAnswer}
              handleChange={props.handleChange}
            />
          )}
          {activeItem === "Warehouse" && (
            <FurlencoAuditTypes
              item={activeItem}
              state={props.state}
              audits={props.audits}
              changeAns={props.changeAnswer}
              handleChange={props.handleChange}
            />
          )}
          {activeItem === "CustomerDetails" && (
            <FurlencoAuditTypes
              item={activeItem}
              state={props.state}
              audits={props.audits}
              changeAns={props.changeAnswer}
              handleChange={props.handleChange}
            />
          )}
        </>
      }
      size={'small'}
      saveText={props.state.editModalAudit === true ? 'Save' : 'Edit'}
      showSave={true}
      saveClick={props.state.editModalAudit === true ? props.save : props.editAudit}
      close={props.onModalClose}

    />

  );
};

const CommonQuestions = function (props) {
  var Questions = [];
  if (Object.keys(props.audits).length > 1) {
    props.audits.questions.map((q) => {
      Questions.push(
        <Grid.Column>
          {q.answerType == "text" && (
            <Grid>
              <Grid.Row>
                <Grid.Column width={6} style={{ display: "inline-block" }}>
                  <span
                    style={{
                      fontWeight: "bold",
                      color: getTextColor(props.color),
                    }}
                  >
                    {q.question}
                  </span>
                </Grid.Column>
                <Grid.Column width={5} style={{ display: "inline-block" }}>
                  <TextInput styles={{ width: 200 }}
                    disabled={!props.state.editMode}
                    value={q.answer}
                    onChangeTextInput={(e) => { props.changeAnswer(q, e) }}
                  />

                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
          {q.answerType == "options" && (
            <Grid>
              <Grid.Row>
                <Grid.Column width={6} style={{ display: "inline-block" }}>
                  <span
                    style={{
                      fontWeight: "bold",
                      color: getTextColor(props.color),
                    }}
                  >
                    {q.question}
                  </span>
                </Grid.Column>
                <Grid.Column style={{ display: "inline-block" }} width={6}>
                  <DropDownPicker
                    disabled={!props.state.editMode}
                    styles={{
                      // display: "inline-block",
                      width: '200px',
                    }}
                    value={q.answer}
                    placeholder={"Select an option"}
                    options={q.options.map((label, i) => {
                      return {
                        value: label.value,
                        text: label.label,
                        key: label.value,
                      };
                    })}
                    onChangeDropDown={(data) => {
                      props.changeAnswer(q, data);
                    }}

                  />

                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
          {q.answerType == "date" && (
            <Grid>
              <Grid.Row>
                <Grid.Column style={{ display: "inline-block" }} width={6}>
                  <span
                    style={{
                      fontWeight: "bold",
                      color: getTextColor(props.color),
                    }}
                  >
                    {q.question}
                  </span>
                </Grid.Column>
                <Grid.Column width={6} style={{ display: "inline-block" }}>
                  <DatePicker
                    disabled={!props.state.editMode}
                    value={
                      q.answer ? new Date(q.answer) : props.state.setDate[q._id]
                    }
                    handleDateChange={(date) => {
                      props.handleChange(q, date)
                    }}
                    label={q.question}
                  />
                  {/* <DatePicker
                    disabled={!props.state.editMode}
                    selected={
                      q.answer ? new Date(q.answer) : props.state.setDate[q._id]
                    }
                    onChange={(date) => props.handleChange(q, date)}
                    className="form-control"
                    monthsShown={1}
                    dateFormat={"dd/MM/yyyy"}
                    popperPlacement="bottom"
                    style={{
                      display: "inline-block",
                    }}
                    popperModifiers={{
                      flip: {
                        behavior: ["bottom"],
                      },
                      preventOverflow: {
                        enabled: false,
                      },
                      hide: {
                        enabled: false,
                      },
                    }}
                  /> */}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
          {q.answerType == "auto" && (
            <Grid>
              <Grid.Row>
                <Grid.Column width={6} >
                  <span
                    style={{
                      fontWeight: "bold",
                      color: getTextColor(props.color),
                    }}
                  >
                    {q.question}
                  </span>
                </Grid.Column>
                <Grid.Column width={5} >
                  <Input
                    disabled
                    style={{
                      width: '200px',
                    }}
                    value={q.answer}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
        </Grid.Column>
      );
    });
  } else {
    return "";
  }

  return (
    <Segment
      style={{ width: "100%", backgroundColor: getMatchingTint(props.color) }}
    >
      <Grid>
        <Grid.Row
          columns={2}
          style={{
            marginTop: "5%",
            height: 450,
            width: "80%",
            marginLeft: "8%",
          }}
        >
          {Questions}
        </Grid.Row>
      </Grid>
    </Segment>
  );
};
const HeaderView = function (props) {
  let {
    selectedAudit,
    editMode,
    onDownloadPhotos,
    editAudit,
    saveAudit,
    cancelEditAudit,
  } = props;

  // UTC date to IST date conversion
  var date = selectedAudit.createdAt;

  var formatString = moment.utc(date).format("MMM DD YYYY, HH:mm:ss");

  return (
    <div>
      <h1 style={{ color: getTextColor(props.color) }}>Furlenco Audits</h1>
      <div
        style={{
          display: "flex",
          flex: 1,
          width: 600,
          flexDirection: "row",
          justifyContent: "flex-start",
        }}
      >
        <ConstantLabel
          title={"Show Photos"}
          size={"small"}
          onClick={() => {
            onDownloadPhotos(selectedAudit._id);
          }} styles={{
            cursor: "pointer",
            backgroundColor: getMainBackground(props.color),
            color: getFontColor(props.color),
          }}
          iconName={"picture"}
        />
        <ConstantLabel
          title={"Download Report"}
          size={"small"}
          onClick={() => {
            props.reportDownload(selectedAudit._id);
          }} styles={{
            cursor: "pointer",
            backgroundColor: getMainBackground(props.color),
            color: getFontColor(props.color),
            marginLeft: 5,
          }}
          iconName={"table"}
        />


        {!editMode && (
          <ConstantLabel
            title={"Edit audit"}
            size={"small"}
            onClick={editAudit}
            styles={{
              cursor: "pointer",
              backgroundColor: getMainBackground(props.color),
              color: getFontColor(props.color),
              marginLeft: 5,
            }}
            iconName={"edit"}
          />

        )}

        {editMode && (
          <div>
            <ConstantLabel
              title={"Save audit"}
              size={"small"}
              onClick={saveAudit}
              styles={{
                cursor: "pointer",
                backgroundColor: getMainBackground(props.color),
                color: getFontColor(props.color),
                marginLeft: 5,
              }}
              iconName={"save"}
            />
            <ConstantLabel
              title={"Cancel"}
              size={"small"}
              onClick={cancelEditAudit}
              styles={{
                cursor: "pointer", marginLeft: "5px"
              }}
              iconName={"cancel"}
            />


          </div>
        )}

      </div>

      {Object.keys(selectedAudit).length > 1 && (
        <div>
          By{" "}
          {selectedAudit.auditorId != null &&
            selectedAudit.auditorId.displayName}{" "}
          on{" "}
          <h4 style={{ color: getTextColor(props.color) }}>{formatString}</h4>
        </div>
      )}
    </div>
  );
};

function AuditTableCell(props) {
  function onClick() {
    props.onClick(props.row);
  }
  return (
    <div style={props.style} onClick={onClick}>
      {props.text}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    Furlenco: state.Furlenco,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchFurlencoAuditsAction: fetchFurlencoAuditsAction,
      saveFurlencoAuditAction: saveFurlencoAuditAction,
      downloadFurlencoAuditReportAction: downloadFurlencoAuditReportAction,
    },
    dispatch
  );
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FurlencoAudits)
);
