import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn, isClient } from "../../util";
import {
  fetchDealersAction,
  fetchVolkswagenAuditReportAction,
  fetchDaimlerClientDealersAction,
  fetchVolkswagenClientAuditReportAction,
  clearDealersVehiclesAction,
} from "../../actions/vehicle_verification_actions";
import { Card, Icon, Button, Confirm } from "semantic-ui-react";
import IconPicker from "../commonViews/IconPicker";
import { Popup, List } from "semantic-ui-react";
import TableView from "../commonViews/TableView";
import VolkswagenVehicles from "./VolkswagenVehicles.jsx";
import {
  getMainBackground,
  getTextColor,
  getFontColor,
} from "../../ThemeHandlers/theme";
import Metrics from "../commonViews/Metrics";

class VolkswagenAudits extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedDealer: "",
      isTableView: true,
      showReportDataNotFound: false,
      dealers: [],
    };
  }

  authenticate = () => {
    this.props.login(this.state.username, this.state.password);
  };

  componentDidMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
      return;
    }
    if (this.isClientProfile())
      this.props.fetchClientDealers(this.props.template._id);
    else {
      this.props.fetchDealers(this.props.template._id);
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.dealers !== prevProps.dealers) {
      this.setState({ dealers: this.props.dealers });
    }
  }

  isClientProfile = () => {
    return isClient(this.props.auth);
  };

  searchFilter = (filter, row) => {
    var flatData = JSON.stringify(row._original);
    return flatData.toLowerCase().includes(filter.value.toLowerCase());
  };

  handleCloseClick = () => {
    this.props.clearDealersVehiclesAction();
    this.setState({ isTableView: true });
  };

  handleTableViewAuditClick = (dealer) => {
    this.props.clearDealersVehiclesAction();
    if (!dealer._id) return;

    this.setState({ selectedDealer: dealer, isTableView: false });
  };

  handleDownloadAgentReportClick = (dealer) => {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
      return;
    }

    if (!dealer._id) {
      this.setState({ showReportDataNotFound: true });
      return;
    }

    if (this.isClientProfile()) {
      this.props.downloadClientAuditReport(dealer._id);
    } else {
      this.props.downloadAuditReport(dealer._id);
    }
  };

  handleCancel = () => this.setState({ showReportDataNotFound: false });

  render() {
    var dealers = this.state.dealers.length !== 0 ? this.state.dealers : [];

    var dealerCol = [
      {
        title: "Name",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.name}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.name.toLowerCase().includes(term.toLowerCase()),
      },
      {
        title: "BP No",
        field: "",
        render: (rowData) => (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.handleTableViewAuditClick(rowData);
            }}
          >
            {rowData.BPNo}
          </span>
        ),
        customFilterAndSearch: (term, rowData) => {
          return rowData.BPNo.toLowerCase().includes(term.toLowerCase());
        },
      },
      {
        title: "Brand",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.brand}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.brand.toLowerCase().includes(term.toLowerCase()),
      },
      {
        title: "Code",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.dealerCode}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.dealerCode.toLowerCase().includes(term.toLowerCase()),
      },
      {
        title: "Address",
        field: "",
        render: (rowData) => {
          return (
            <div row={rowData} onClick={this.handleTableViewAuditClick}>
              <Popup
                wide
                trigger={
                  <Button
                    size="mini"
                    content="Locations"
                    style={{
                      backgroundColor: getMainBackground(this.props.color),
                      color: getFontColor(this.props.color),
                    }}
                  />
                }
                position="bottom center"
                on="click"
              >
                <Card.Group>
                  {rowData.locations.map((location, i) => {
                    if (location) {
                      return (
                        <Card>
                          <Card.Content>
                            <Card.Header>Address</Card.Header>
                            <Card.Description>
                              <List>
                                <List.Item>
                                  <List.Icon name="marker" />
                                  <List.Content>
                                    <List.Header>Show Room</List.Header>
                                    {location.showRoomAddress}
                                  </List.Content>
                                </List.Item>
                                <List.Item>
                                  <List.Icon name="marker" />
                                  <List.Content>
                                    <List.Header>Stock Yard</List.Header>
                                    {location.stockYardAddress}
                                  </List.Content>
                                </List.Item>
                                <List.Item>
                                  <List.Icon name="marker" />
                                  <List.Content>
                                    <List.Header>Work Shop</List.Header>
                                    {location.workShop}
                                  </List.Content>
                                </List.Item>
                              </List>
                            </Card.Description>
                          </Card.Content>
                        </Card>
                      );
                    }
                  })}
                </Card.Group>
              </Popup>
            </div>
          );
        },
      },
      {
        title: "Report",
        field: "",
        render: (rowData) => {
          if (rowData._id) {
            return (
              <Icon
                size="large"
                style={{ color: getTextColor(this.props.color) }}
                name="file excel outline"
                onClick={() => this.handleDownloadAgentReportClick(rowData)}
              />
            );
          }
        },
      },
    ];

    var summary = this.props.summary || {};
    let metrics = {
      Dealers: summary["totalDealers"] || 0,
      Vehicles: summary["totalVehicles"] || 0,
      Verified: (summary["totalVerified"] || 0) +
        "/" +
        (summary["totalVehicles"] || 0),


    }

    return (
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          flexFlow: "column",
          width: "100%",
        }}
      >
        {this.state.isTableView && (
          <h1 style={{ color: getTextColor(this.props.color) }}>
            Vehicle Verification - Audits
          </h1>
        )}
        {this.state.isTableView && (
          <div style={{ marginTop: "20px" }}>
            <IconPicker
              styles={{
                color: "#808080",
                marginTop: 10,
                marginRight: 40,
                float: "right",
                cursor: "pointer",
              }}
              click={this.props.onClose}
              size={'big'}
              name={"arrow left"}
            />

            <div style={{ marginBottom: 10, marginRight: "35px" }}>
              <Metrics metrics={metrics} color={this.props.color} />

            </div>
            <div style={{ display: "flex", flexGrow: 1, flexFlow: "column" }}>
              <div>
                <TableView
                  selection={true}
                  data={dealers}
                  columns={dealerCol}
                />
              </div>
            </div>
          </div>
        )}
        {!this.state.isTableView && (
          <div style={{ flexGrow: 1, display: "flex" }}>
            <VolkswagenVehicles
              dealer={this.state.selectedDealer}
              onClose={this.handleCloseClick}
              color={this.props.color}
            />
          </div>
        )}
        {this.state.showReportDataNotFound && (
          <Confirm
            open={this.state.showReportDataNotFound}
            onCancel={this.handleCancel}
            onConfirm={this.handleCancel}
          />
        )}
      </div>
    );
  }
}

function AuditTableCell(props) {
  function onClick() {
    props.onClick(props.row);
  }
  return (
    <div style={props.style} onClick={onClick}>
      {props.text}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    dealers: state.audits.dealers,
    summary: state.audits.dealerVolkSummary,
    showReportDataNotFound: state.reportAuditFound,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchDealers: fetchDealersAction,
      downloadAuditReport: fetchVolkswagenAuditReportAction,
      fetchClientDealers: fetchDaimlerClientDealersAction,
      downloadClientAuditReport: fetchVolkswagenClientAuditReportAction,
      clearDealersVehiclesAction: clearDealersVehiclesAction,
    },
    dispatch
  );
};

// VolkswagenAudits.PropTypes = {
//   dealers: PropTypes.array
// };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VolkswagenAudits)
);
