import axios from "axios";
import * as types from "./types";
import * as FileSaver from 'file-saver';

export const fetchYamahaTemplateNameAction = () => {
    return function(dispatch) {
        axios.get(types.API_URL + "/auditor/v1/audit/yamaha/templates")
            .then(function(response) {
                dispatch({
                    type: types.FETCH_YAMAHA_TEMPLATENAME_SUCCESS_ACTION,
                    payload: response.data
                });
            })
            .catch(function(error) {
                dispatch({
                    type: types.FETCH_YAMAHA_TEMPLATENAME_FAILURE_ACTION,
                    payload: error
                });
            });
    };
};

export const fetchYamahaDealerCountAction = () => {
    return function(dispatch) {
        axios.get(types.API_URL + "/auditor/v1/audit/yamaha/dealers")
            .then(function(response) {
                dispatch({
                    type: types.FETCH_YAMAHA_DEALERCOUNT_SUCCESS_ACTION,
                    payload: response.data
                });

            })
            .catch(function(error) {
                dispatch({
                    type: types.FETCH_YAMAHA_DEALERCOUNT_FAILURE_ACTION,
                    payload: error
                });
            });
    };
};


export const fetchYamahaDealerCodeAction = () => {
    return function(dispatch) {
        axios.get(types.API_URL + "/auditor/v1/audit/yamaha/dealers")
            .then(function(response) {
                dispatch({
                    type: types.FETCH_YAMAHA_DEALERCODE_SUCCESS_ACTION,
                    payload: response.data
                });

            })
            .catch(function(error) {
                dispatch({
                    type: types.FETCH_YAMAHA_DEALERCODE_FAILURE_ACTION,
                    payload: error
                });
            });
    };
}; 


export const fetchYamahaDealersAction = (templateId) => {
    return function(dispatch) {
        axios.get(types.API_URL + "/auditor/v1/audit/yamaha/dealers?templateId=" + templateId)
            .then(function(response) {
                dispatch({
                    type: types.FETCH_YAMAHA_DEALERS_SUCCESS_ACTION,
                    payload: response.data
                });

            })
            .catch(function(error) {
                dispatch({
                    type: types.FETCH_YAMAHA_DEALERS_FAILURE_ACTION,
                    payload: error
                });
            });
    };
};

export const fetchYamahaAuditsAction = (templateId, dealerId) => {
    return function(dispatch) {
        axios.get(types.API_URL + "/auditor/v1/audit/yamaha/audits?templateId=" + templateId + "&dealerId=" + dealerId)
            .then(function(response) {
                dispatch({
                    type: types.FETCH_YAMAHA_AUDITS_SUCCESS_ACTION,
                    payload: response.data
                });

            })
            .catch(function(error) {
                dispatch({
                    type: types.FETCH_YAMAHA_AUDITS_FAILURE_ACTION,
                    payload: error
                });
            });
    };
};

export const createYamahaLineItemAction = (payload) => {
    return function(dispatch) {
        axios.post(types.API_URL + "/auditor/v1/audit/yamaha/lineitems/save", payload)
            .then(function(response) {
                dispatch({
                    type: types.UPDATE_YAMAHA_LINEITEM_SUCCESS_ACTION,
                    payload: response.data
                });

            })
            .catch(function(error) {
                dispatch({
                    type: types.UPDATE_YAMAHA_LINEITEM_FAILURE_ACTION,
                    payload: error
                });
            });
    };
};

export const deleteYamahaLineItemAction = (payload) => {
    return function(dispatch) {
        axios.post(types.API_URL + "/auditor/v1/audit/yamaha/lineitems/delete", payload)
            .then(function(response) {
                dispatch({
                    type: types.UPDATE_YAMAHA_LINEITEM_SUCCESS_ACTION,
                    payload: response.data
                });

            })
            .catch(function(error) {
                dispatch({
                    type: types.UPDATE_YAMAHA_LINEITEM_FAILURE_ACTION,
                    payload: error
                });
            });
    };
};


export const fetchYamahaExportAction = (auditId) => {
    return function(dispatch) {
        axios.get(types.API_URL + '/auditor/v1/report/yamaha?auditId=' + auditId, {
                responseType: "blob"
            }, {timeout: 300000})
            .then(function(response) {
                FileSaver.saveAs(response.data, "Yamaha-Audit-Report.xlsx");
                dispatch({
                    type: types.DOWNLOAD_YAMAHA_AUDIT_REPORT_SUCCESS_ACTION,
                    payload: response.data
                });
            })
            .catch(function(error) {
                dispatch({
                    type: types.DOWNLOAD_YAMAHA_AUDIT_REPORT_FAILURE_ACTION,
                    payload: error
                });
            });
    };
}

export const saveYamahaAudit = (audit) => {
    return function(dispatch) {
        axios.post(types.API_URL + '/auditor/v1/audit/yamaha/save', audit)
        .then(function(response) {
            dispatch({
                type: types.SAVE_YAMAHA_AUDITS_SUCCESS_ACTION,
                payload: response.data
            });

        })
        .catch(function(error) {
            dispatch({
                type: types.SAVE_YAMAHA_AUDITS_FAILURE_ACTION,
                payload: error
            });
        });
    }
}

export const submitYamahaAudit = (audit) => {
    return function(dispatch) {
        axios.post(types.API_URL + '/auditor/v1/audit/yamaha/submit', audit)
        .then(function(response) {
            dispatch({
                type: types.SAVE_YAMAHA_AUDITS_SUCCESS_ACTION,
                payload: response.data
            });

        })
        .catch(function(error) {
            dispatch({
                type: types.SAVE_YAMAHA_AUDITS_FAILURE_ACTION,
                payload: error
            });
        });
    }
}

export const emailYamahaReport = (emails, auditId) => {
    return function(dispatch) {
        axios.get(types.API_URL + '/auditor/v1/report/yamaha/mail?auditId=' + auditId + '&email=' + emails)
        .then(function(response) {
            dispatch({
                type: types.SAVE_YAMAHA_AUDITS_SUCCESS_ACTION,
                payload: response.data
            });

        })
        .catch(function(error) {
            dispatch({
                type: types.SAVE_YAMAHA_AUDITS_FAILURE_ACTION,
                payload: error
            });
        });
    }
}