import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn } from "../../util";
import { Icon, Grid } from "semantic-ui-react";
import IconPicker from "../commonViews/IconPicker";
import PandGAuditsThumbnail from "./PandGAuditsThumbnail.jsx";
import PandGAuditsQuestionare from "./PandGAuditsQuestionare.jsx";
import { selectAuditsFromLeftPane } from "../../actions/pandg_actions.js";
import PandGHospitalAuditsQuestionare from "./PandGHospitalAuditsQuestionare.jsx";
import PandGSchoolAuditsQuestionare from "./PandGSchoolAuditsQuestionare.jsx";

class PandGAuditDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAudit: this.props.selectedAudit,
    };
  }
  componentDidMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
      return false;
    }
    return true;
  }
  componentWillMount() {
    if (this.props.audit) {
      this.audit = JSON.parse(JSON.stringify(this.props.audit));
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.audit) {
      this.audit = JSON.parse(JSON.stringify(nextProps.audit));
    }
  }

  handleBackNavigaion = () => {
    this.props.handleBackNavigaion({});
  };

  handleListViewAuditClick = (audit) => {
    this.setState({ selectedAudit: audit });
    this.props.selectAuditsFromLeftPane(audit.audit);
  };

  render() {
    console.log(
      this.props.color,
      this.state.selectedAudit.audit.chassis,
      "firstttttt"
    );
    return (
      <div>
        <IconPicker
          styles={{
            color: "#808080",
            marginTop: 10,
            marginRight: 20,
            float: "right",
            cursor: "pointer",
          }}
          click={this.props.onClose}
          size={"large"}
          name={"arrow left"}
        />

        <Grid divided columns="two">
          <Grid.Row>
            <Grid.Column width={4}>
              <PandGAuditsThumbnail
                selectedAudit={this.state.selectedAudit}
                pandgaudits={this.props.pandgaudits}
                handleListViewAuditClick={this.handleListViewAuditClick}
                color={this.props.color}
              />
            </Grid.Column>
            <Grid.Column width={12}>
              {this.state.selectedAudit.audit.chassis === "Schools" && (
                <PandGSchoolAuditsQuestionare
                  selectedAudit={this.state.selectedAudit.audit}
                  color={this.props.color}
                />
              )}
              {this.state.selectedAudit.audit.chassis === "Hospital" && (
                <PandGHospitalAuditsQuestionare
                  selectedAudit={this.state.selectedAudit.audit}
                  color={this.props.color}
                />
              )}
              {(this.state.selectedAudit.audit.chassis === "HTH" ||
                this.state.selectedAudit.audit.chassis ==
                "HTH Whisper Sampling" ||
                this.state.selectedAudit.audit.chassis == "Olay BCP" ||
                !this.state.selectedAudit.audit.chassis) && (
                  <PandGAuditsQuestionare
                    selectedAudit={this.props.selectedAudit}
                    auditsPropKey="houses"
                    auditPropTitle="Houses"
                    auditQuestionsKey="tcHouseQuestions"
                    color={this.props.color}
                  />
                )}

              {this.state.selectedAudit.audit.chassis === "CP" && (
                <PandGAuditsQuestionare
                  selectedAudit={this.props.selectedAudit}
                  auditsPropKey="audits"
                  auditPropTitle="Customers"
                  auditQuestionsKey="auditQuestions"
                  color={this.props.color}
                />
              )}

              {this.state.selectedAudit.audit.chassis === "India 1" && (
                <PandGAuditsQuestionare
                  selectedAudit={this.props.selectedAudit}
                  auditsPropKey="audits"
                  auditPropTitle="Customers"
                  auditQuestionsKey="auditQuestions"
                  color={this.props.color}
                />
              )}
              {this.state.selectedAudit.audit.chassis === "iTrial" && (
                <PandGAuditsQuestionare
                  selectedAudit={this.props.selectedAudit}
                  auditsPropKey="audits"
                  auditPropTitle="Consumers"
                  auditQuestionsKey="auditQuestions"
                  color={this.props.color}
                />
              )}
              {this.state.selectedAudit.audit.chassis === "SOCP" && (
                <PandGAuditsQuestionare
                  selectedAudit={this.props.selectedAudit}
                  auditsPropKey="audits"
                  auditPropTitle="Customers"
                  auditQuestionsKey="auditQuestions"
                  color={this.props.color}
                />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    pandg: state.pandg,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      selectAuditsFromLeftPane: selectAuditsFromLeftPane,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PandGAuditDetail)
);
