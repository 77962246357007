import React from 'react';
import { Header, List, Image, Modal } from 'semantic-ui-react';

import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import moment from 'moment';
import config from '../../config.js';

const storageBaseUrl = config["storage_base_url"];

class DaimlerAuditViewDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedPhoto: "",
            showPhotoSlider: false,
            auditorList: []
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedAudit !== prevProps.selectedAudit) {
            this.setState({ auditorList: this.props.selectedAudit });
        }
    }

    handlePhotoClick = (e, p) => {
        this.setState({
            selectedPhoto: p,
            showPhotoSlider: true
        });
    };

    photoSliderClose = e => {
        this.setState({
            showPhotoSlider: false
        });
    };

    render() {


        var selectedAudit = this.props.selectedAudit;

        let status = "";
        if (selectedAudit) {
            var vehicleStatusOptions = [
                {
                    text: "Demo",
                    value: 1
                },
                {
                    text: "Transit",
                    value: 2
                },
                {
                    text: "Exhibition",
                    value: 3
                },
                {
                    text: "Not found - Sold",
                    value: 4
                },
                {
                    text: "Found",
                    value: 5
                },
                {
                    text: "Other reasons",
                    value: 6
                }
            ];
            vehicleStatusOptions.map((op) => {
                if (op.value === selectedAudit.questions[0].answer) {
                    return status = op.text;
                }
            })
        }
        const Map = compose(
            withProps({
                googleMapURL: "https://maps.googleapis.com/maps/api/js?key=" + config.google_map_api_key,
                mapElement: <div style={{ height: `100%` }} />,
                loadingElement: <div style={{ height: `100%` }} />,
                containerElement: <div style={{ height: `400px` }} />,
            }),
            withScriptjs,
            withGoogleMap
        )((props) =>
            <GoogleMap
                defaultZoom={10}
                defaultCenter={{ lat: selectedAudit.latitude, lng: selectedAudit.longitude }}
            >
                {props.isMarkerShown && <Marker position={{ lat: selectedAudit.latitude, lng: selectedAudit.longitude }} />}
            </GoogleMap>
        )


        return (
            <Modal.Content>
                <Modal
                    dimmer="blurring"
                    size="fullscreen"
                    closeOnEscape={true}
                    closeOnDimmerClick={true}
                    basic
                    open={this.state.showPhotoSlider}
                    onClose={this.photoSliderClose}>
                    <Modal.Content>
                        <Image fuild centered src={storageBaseUrl + this.state.selectedPhoto.uploadedImageUrl} />
                    </Modal.Content>
                </Modal>
                <Modal.Description style={{ height: "100%" }}>
                    <List >
                        {/* Always show there items */}
                        <List.Item>
                            <div style={{ marginTop: 10 }}>
                                <span style={{ verticalAlign: "middle", width: 200, display: "inline-block", fontSize: 15, fontWeight: "bold" }}>{"Vehicle Status: "} :</span>
                                <span style={{ verticalAlign: "middle", fontSize: 15, color: "#2ecc71", marginLeft: 15 }}>{status}</span>
                            </div>
                        </List.Item>
                        <List.Item>
                            <div style={{ marginTop: 10 }}>
                                <span style={{ verticalAlign: "middle", width: 200, display: "inline-block", fontSize: 15, fontWeight: "bold" }}>Storeroom :</span>
                                <span style={{ verticalAlign: "middle", fontSize: 15, marginLeft: 15 }}>{selectedAudit.storeRoom}</span>
                            </div>
                        </List.Item>
                        <List.Item>
                            <div style={{ marginTop: 10 }}>
                                <span style={{ verticalAlign: "middle", width: 200, display: "inline-block", fontSize: 15, fontWeight: "bold" }}>Storeroom :</span>
                                <span style={{ verticalAlign: "middle", fontSize: 15, marginLeft: 15 }}>{selectedAudit.auditStatus}</span>
                            </div>
                        </List.Item>
                        <List.Item>
                            <div style={{ marginTop: 10 }}>
                                <span style={{ verticalAlign: "middle", width: 200, display: "inline-block", fontSize: 15, fontWeight: "bold" }}>Vehicle Condition: </span>
                                <span style={{ verticalAlign: "middle", fontSize: 15, marginLeft: 15 }}>{selectedAudit.vehicleCondition}</span>
                            </div>
                        </List.Item>
                        <List.Item>
                            <div style={{ marginTop: 10 }}>
                                <span style={{ verticalAlign: "middle", width: 200, display: "inline-block", fontSize: 15, fontWeight: "bold" }}>Odometer Reading: </span>
                                <span style={{ verticalAlign: "middle", fontSize: 15, marginLeft: 15 }}>{selectedAudit.odometerReading}</span>
                            </div>
                        </List.Item>
                        {selectedAudit.questions[0].answer === 3 &&
                            <div>
                                <List.Item>
                                    <div style={{ marginTop: 10 }}>
                                        <span style={{ verticalAlign: "middle", width: 200, display: "inline-block", fontSize: 15, fontWeight: "bold" }}>Gate Pass :</span>
                                        <span style={{ verticalAlign: "middle", fontSize: 15, marginLeft: 15 }}>
                                            {(selectedAudit.gatePassNo) ? selectedAudit.gatePassNo : ""}
                                        </span>
                                    </div>
                                </List.Item>
                                <List.Item>
                                    <div style={{ marginTop: 10 }}>
                                        <span style={{ verticalAlign: "middle", width: 200, display: "inline-block", fontSize: 15, fontWeight: "bold" }}>Gate Pass Date :</span>
                                        <span style={{ verticalAlign: "middle", fontSize: 15, marginLeft: 15 }}>{(selectedAudit.gatePassDate) ? moment(selectedAudit.gatePassDate).format('DD/MM/YYYY') : ""}</span>
                                    </div>
                                </List.Item>
                                <List.Item>
                                    <div style={{ marginTop: 10 }}>
                                        <span style={{ verticalAlign: "middle", width: 200, display: "inline-block", fontSize: 15, fontWeight: "bold" }}>Gate Pass Uploaded:</span>
                                        <span style={{ verticalAlign: "middle", fontSize: 15, marginLeft: 15 }}>{selectedAudit.gatepassCopyUploaded ? "Yes" : "No"}</span>
                                    </div>
                                </List.Item>
                                <List.Item>
                                    <div style={{ marginTop: 10 }}>
                                        <span style={{ verticalAlign: "middle", width: 200, display: "inline-block", fontSize: 15, fontWeight: "bold" }}>Event name:</span>
                                        <span style={{ verticalAlign: "middle", fontSize: 15, marginLeft: 15 }}>{selectedAudit.eventName}</span>
                                    </div>
                                </List.Item>
                                <List.Item>
                                    <div style={{ marginTop: 10 }}>
                                        <span style={{ verticalAlign: "middle", width: 200, display: "inline-block", fontSize: 15, fontWeight: "bold" }}>Event Location:</span>
                                        <span style={{ verticalAlign: "middle", fontSize: 15, marginLeft: 15 }}>{selectedAudit.eventLocation}</span>
                                    </div>
                                </List.Item>
                                <List.Item>
                                    <div style={{ marginTop: 10 }}>
                                        <span style={{ verticalAlign: "middle", width: 200, display: "inline-block", fontSize: 15, fontWeight: "bold" }}>No. of days it was sent for:</span>
                                        <span style={{ verticalAlign: "middle", fontSize: 15, marginLeft: 15 }}>{selectedAudit.daysSent}</span>
                                    </div>
                                </List.Item>
                                <List.Item>
                                    <div style={{ marginTop: 10 }}>
                                        <span style={{ verticalAlign: "middle", width: 200, display: "inline-block", fontSize: 15, fontWeight: "bold" }}>Expected return:</span>
                                        <span style={{ verticalAlign: "middle", fontSize: 15, marginLeft: 15 }}>{selectedAudit.expectedReturnDate}</span>
                                    </div>
                                </List.Item>
                            </div>
                        }
                        {/* {selectedAudit.questions[0].answer === 4 ?console.log(selectedAudit,"222222"): null} */}
                        {selectedAudit.questions[0].answer === 4 &&

                            <div>
                                <List.Item>
                                    <div style={{ marginTop: 10 }}>
                                        <span style={{ verticalAlign: "middle", width: 200, display: "inline-block", fontSize: 15, fontWeight: "bold" }}>Invoice No :</span>
                                        <span style={{ verticalAlign: "middle", fontSize: 15, marginLeft: 15 }}>
                                            {(selectedAudit.soldInvoice) ? selectedAudit.soldInvoice.no : ""}
                                        </span>
                                    </div>
                                </List.Item>

                                <List.Item>
                                    <div style={{ marginTop: 10 }}>
                                        <span style={{ verticalAlign: "middle", width: 200, display: "inline-block", fontSize: 15, fontWeight: "bold" }}>DO Date: </span>
                                        <span style={{ verticalAlign: "middle", fontSize: 15, marginLeft: 15 }}>{(selectedAudit.doDate) ? (selectedAudit.doDate && moment(selectedAudit.doDate).format('DD/MM/YYYY')) : ""}</span>
                                    </div>
                                </List.Item>
                                <List.Item>
                                    <div style={{ marginTop: 10 }}>
                                        <span style={{ verticalAlign: "middle", width: 200, display: "inline-block", fontSize: 15, fontWeight: "bold" }}>Invoice Date :</span>
                                        <span style={{ verticalAlign: "middle", fontSize: 15, marginLeft: 15 }}>{(selectedAudit.invoiceDate) ? (selectedAudit.invoiceDate && moment(selectedAudit.invoiceDate).format('DD/MM/YYYY')) : ""}</span>
                                    </div>
                                </List.Item>
                                <List.Item>
                                    <div style={{ marginTop: 10 }}>
                                        <span style={{ verticalAlign: "middle", width: 200, display: "inline-block", fontSize: 15, fontWeight: "bold" }}>DO Financier: </span>
                                        <span style={{ verticalAlign: "middle", fontSize: 15, marginLeft: 15 }}>{selectedAudit.doFinancier}</span>
                                    </div>
                                </List.Item>
                                <List.Item>
                                    <div style={{ marginTop: 10 }}>
                                        <span style={{ verticalAlign: "middle", width: 200, display: "inline-block", fontSize: 15, fontWeight: "bold" }}>Customer Name: </span>
                                        <span style={{ verticalAlign: "middle", fontSize: 15, marginLeft: 15 }}>{selectedAudit.customerName}</span>
                                    </div>
                                </List.Item>
                                <List.Item>
                                    <div style={{ marginTop: 10 }}>
                                        <span style={{ verticalAlign: "middle", width: 200, display: "inline-block", fontSize: 15, fontWeight: "bold" }}>Final payment received from customer/retail financier: </span>
                                        <span style={{ verticalAlign: "middle", fontSize: 15, marginLeft: 15 }}>{(selectedAudit.paymentReceivedFromCustDate) ? (selectedAudit.paymentReceivedFromCustDate && moment(selectedAudit.paymentReceivedFromCustDate).format('DD/MM/YYYY')) : ""}</span>
                                    </div>
                                </List.Item>
                                <List.Item>
                                    <div style={{ marginTop: 10 }}>
                                        <span style={{ verticalAlign: "middle", width: 200, display: "inline-block", fontSize: 15, fontWeight: "bold" }}>Last Credit Amount as per Ledger: </span>
                                        <span style={{ verticalAlign: "middle", fontSize: 15, marginLeft: 15 }}>{selectedAudit.paymentToDFSIDate}</span>
                                    </div>
                                </List.Item>
                                <List.Item>
                                    <div style={{ marginTop: 10 }}>
                                        <span style={{ verticalAlign: "middle", width: 200, display: "inline-block", fontSize: 15, fontWeight: "bold" }}>DO copy - Photo: </span>
                                        <span style={{ verticalAlign: "middle", fontSize: 15, marginLeft: 15 }}>{selectedAudit.doCopyUploaded}</span>
                                    </div>
                                </List.Item>
                            </div>
                        }
                        <List.Item>
                            <div style={{ marginTop: 10 }}>
                                <span style={{ verticalAlign: "middle", fontSize: 15, fontWeight: "bold" }}>Remarks :</span>
                                <br />
                                <p style={{ marginTop: 5 }}>{selectedAudit.notes}</p>
                                <br />
                                <Image.Group size='mini' style={{ cursor: "pointer" }}>
                                    {selectedAudit.questions[0].photos.map((p, i) => {
                                        return <Image key={i} src={storageBaseUrl + p.uploadedImageUrl} onClick={((k) => {
                                            return (e) => this.handlePhotoClick(e, k);
                                        })(p)} />;
                                    })}
                                </Image.Group>

                            </div>
                        </List.Item>
                        {/* <List.Item>
                            <div style={{ marginTop: 10 }}>
                                <span style={{ verticalAlign: "middle", fontSize: 15, fontWeight: "bold" }}>signOffCopy :</span>
                                <br />
                                <p style={{ marginTop: 5 }}>{selectedAudit.notes}</p>
                                <br />
                                <Image.Group size='mini' style={{ cursor: "pointer" }}>
                                    {selectedAudit.questions[0].photos.map((p, i) => {
                                        return <Image key={i} src={storageBaseUrl + p.uploadedImageUrl} onClick={((p) => {
                                            return (e) => this.handlePhotoClick(e, p);
                                        })(p)} />;
                                    })}
                                </Image.Group>

                            </div>
                        </List.Item> */}
                        <span style={{ verticalAlign: "middle", width: 200, display: "inline-block", fontSize: 15, fontWeight: "bold" }}>Back office Details: </span>
                        <List.Item>
                            <div style={{ marginTop: 10 }}>
                                <span style={{ verticalAlign: "middle", width: 200, display: "inline-block", fontSize: 15, fontWeight: "bold" }}>Hypothecation Details - DFSI: </span>
                                <span style={{ verticalAlign: "middle", fontSize: 15, marginLeft: 15 }}>{selectedAudit.hypothecationDetails ? "Available" : "Not available"}</span>
                            </div>
                        </List.Item>
                        <List.Item>
                            <div style={{ marginTop: 10 }}>
                                <span style={{ verticalAlign: "middle", width: 200, display: "inline-block", fontSize: 15, fontWeight: "bold" }}>Form 21: </span>
                                <span style={{ verticalAlign: "middle", fontSize: 15, marginLeft: 15 }}>{selectedAudit.form21Available ? "Available" : "Not available"}</span>
                            </div>
                        </List.Item>
                        <List.Item>
                            <div style={{ marginTop: 10 }}>
                                <span style={{ verticalAlign: "middle", width: 200, display: "inline-block", fontSize: 15, fontWeight: "bold" }}>Form 22: </span>
                                <span style={{ verticalAlign: "middle", fontSize: 15, marginLeft: 15 }}>{selectedAudit.form22Available ? "Available" : "Not available"}</span>
                            </div>
                        </List.Item>
                    </List>

                    {selectedAudit.latitude && selectedAudit.longitude ? <Map isMarkerShown /> : null}

                    {false &&
                        <div>
                            <Header> Dealer Assessment </Header>
                            <List>
                                <List.Item>
                                    <div style={{ marginTop: 10 }}>
                                        <span style={{ verticalAlign: "middle", width: 200, display: "inline-block", fontSize: 15, fontWeight: "bold" }}>Invoice Shown :</span>
                                        {/* <span style={{ verticalAlign: "middle", fontSize: 15, marginTop: 5, marginLeft: 15 }}>{dealerAssessment.isInvoiceShown ? "Yes" : "No"}</span> */}
                                    </div>
                                </List.Item>
                                <List.Item>
                                    <div style={{ marginTop: 10 }}>
                                        <span style={{ verticalAlign: "middle", width: 200, display: "inline-block", fontSize: 15, fontWeight: "bold" }}>Cooperation Scale :</span>
                                        {/* <span style={{ verticalAlign: "middle", fontSize: 15, marginLeft: 15 }}>{dealerAssessment.cooperationScale}</span> */}
                                    </div>
                                </List.Item>
                            </List>
                        </div>
                    }
                </Modal.Description>
            </Modal.Content>
        )
    }
}

export default DaimlerAuditViewDetails;