import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Dropdown,
  Modal,
  Grid,
  Divider,
  Card,
  Form,
  Header,
  Image,
} from "semantic-ui-react";
import ModalView from "../commonViews/ModalView";
import ButtonHandler from "../commonViews/ButtonHandler";
import { withRouter } from "react-router";
import TextInput from "../commonViews/TextInput";
import {
  fetchDiamlerKeyVehiclesAction,
  saveDiamlerKeyAudit,
  uploadDiamlerKeyPhotoAction,
  showLoadingScreen,
  toCheckPhotos,
} from "../../actions/diamler_key_action";
import { uuidv4 } from "../../util";
import DatePicker from "../commonViews/DatePicker";
import "react-datepicker/dist/react-datepicker.css";
import config from "../../config.js";
import {
  getTextColor,
  getMainBackground,
  getMatchingTint,
} from "../../ThemeHandlers/theme";
import ConstantLabel from "../commonViews/ConstantLabel";
import moment from "moment";
import DropDownPicker from '../commonViews/DropDownPicker.jsx'
const storageBaseUrl = config["storage_base_url"];

class DiamlerKeyNewAudits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedVehicle: [],
      IsbuttonClick: false,
      typedAnswer: {}, // This is the answered dealer assessment questions
      questionAnswer: {}, // this is for answer for 7 questions
      template: this.props.template,
      questions: this.props.template.questions, // this is the answered questions from template
      showQuestions: true,
      selectedPhoto: [],
      newDate: new Date(),
      editMode: false,
      showPhotoSlider: false,
      answerisSold: false,
      selectedVehicleId: "",
      selectedVehicleDealerId: "",
      selectedVehicleForm22: "",
      selectedVehicleForm21: "",
      editedForm21: "",
      soldDateVisible: false,
      dealerAssesmentPic: false,
      historyPic: false,
      VinNo: "",
      selectedVinNumber: "",
    };
  }

  componentWillMount() {
    this.props.fetchDiamlerKeyVehicles(this.props.dealerId);
  }

  componentDidMount() {
    var typedAnswer = {};
    var selectedVehicle = {};
    var answer = {
      typedAnswer: typedAnswer,
      selectedVehicle: selectedVehicle,
    };
    this.props.template.dealerAssessment.map((ques) => {
      if ("answer" in ques) {
        if (ques.answerType == "options") {
          typedAnswer[ques._id] = Number(ques.answer);
        } else {
          typedAnswer[ques._id] = ques.answer;
        }
        if (ques.answerType == "photo") {
          typedAnswer[ques._id] = ques.photos;
        }
      }
    });

    answer.selectedVehicleForm21 = this.props.template.vehicle
      ? this.props.template.vehicle.form21OriginalGiven
      : "";

    answer.selectedVehicleForm22 = this.props.template.vehicle
      ? this.props.template.vehicle.form22OriginalGiven
      : "";
    // console.info(answer.typedAnswer)
    this.setState(Object.assign({}, this.state, answer));
  }

  handlePhotoClick = (e, p) => {
    this.setState({
      selectedPhoto: p,
      showPhotoSlider: true,
    });
  };

  photoSliderClose = (e) => {
    console.log('clickedd')
    this.setState({
      showPhotoSlider: false,
    });
  };
  handleVinDropdown = (e, d) => {
    console.log(d, "d");
    let answer = d.value.form21OriginalGiven;
    let answer22Form = d.value.form22OriginalGiven;
    this.setState({
      selectedVehicleId: d.value._id,
      selectedVehicleForm21: answer,
      selectedVehicleForm22: answer22Form,
      selectedVehicleDealerId: d.value.dealerId,
    });
  };


  onPhotoSelect = (e, type) => {
    if (type === "dealerAssessment") {
      this.setState({
        selectedPhoto: e.target.files,
        dealerAssesmentPic: true,
      });
    } else if (type === "history") {
      this.setState({ selectedPhoto: e.target.files, historyPic: true });
    }
  };

  uploadPhoto = () => {
    var details = { orgId: this.props.template.orgId };
    var CheckPhotos = {
      assessmentPic: this.state.dealerAssesmentPic,
      historyPic: this.state.historyPic,
    };
    this.props.toCheckPhotos(CheckPhotos);
    this.props.showLoadingScreen(true);
    this.props.uploadDiamlerKeyPhotoAction(
      this.state.selectedPhoto,
      details,
      CheckPhotos
    );
  };

  handleNewButtonClick = () => {
    this.setState({
      IsbuttonClick: !this.state.IsbuttonClick,
    });
  };

  handleQuestionsDateChange = (quesId, date) => {
    var ans = this.state.questionAnswer;
    ans[quesId] = date;

    this.setState({ questionAnswer: ans });
  };

  handleQuestionsInputChange = (quesId, e) => {
    var ans = this.state.questionAnswer;
    ans[quesId] = e;

    this.setState({ questionAnswer: ans });
  };

  handleDealerAssessmentInputChange = (quesId, e) => {
    var ans = this.state.typedAnswer;
    ans[quesId] = e;
    this.setState({ typedAnswer: ans });
  };

  handleDealerAssessmentOptionsSelect = (quesId, value) => {
    var ans = this.state.typedAnswer;
    ans[quesId] = value;
    if (value === 5) {
      this.setState({ soldDateVisible: true });
    } else if (value === 1 || value === 2 || value === 3 || value === 4) {
      this.setState({ soldDateVisible: false });
    }
    this.setState({ typedAnswer: ans, showQuestions: true });
  };

  handleSaveClick = () => {
    let audit = JSON.parse(JSON.stringify(this.state.template)); // Deep copying objects
    var questions = this.state.questions;
    delete audit._id;
    if (this.props.DiamlerKey.vehicles) {
      this.props.DiamlerKey.vehicles.map((ID) => {
        if (ID._id == this.state.selectedVehicleId) {
          ID.form21OriginalGiven = this.state.selectedVehicle;
        }
      });
    }
    if (!("auditId" in audit)) {
      audit.auditId = uuidv4();
    }
    if (!("vehicleId" in audit)) {
      audit.vehicleId = this.state.selectedVehicleId;
    }
    if (!("form21OriginalGiven" in audit) || "form21OriginalGiven" in audit) {
      audit.form21OriginalGiven = this.state.selectedVehicleForm21;
    }
    if (!("form22OriginalGiven" in audit) || "form22OriginalGiven" in audit) {
      audit.form22OriginalGiven = this.state.selectedVehicleForm22;
    }

    if (!("createdAt" in audit)) {
      audit.createdAt = new Date();
    }

    if (!("dealerId" in audit)) {
      audit.dealerId = this.state.selectedVehicleDealerId;
    }

    audit.dealerAssessment.map((ques) => {
      if (ques.answerType === "photo") {
        if (
          this.props.DiamlerKey.savedPhotos != null &&
          this.state.dealerAssesmentPic === true
        ) {
          this.props.DiamlerKey.savedPhotos.map((photo) => {
            if (photo.newName != undefined && photo.oldName != undefined) {
              ques.photos.push({
                isUploaded: true,
                uploadedImageUrl: photo.newName,
                localImageUrl: photo.oldName,
              });
            }
          });
        }
      }
      ques.answer = this.state.typedAnswer[ques._id];
    });

    if (!("answers" in audit)) {
      audit.answers = [];
    }

    this.props.showLoadingScreen(true);
    if (this.state.IsbuttonClick) {
      questions.map((ques) => {
        ques.answer = this.state.questionAnswer[ques._id];
        if (ques.answerType == "photo") {
          ques.photos = [];
          if (this.props.DiamlerKey.savedPhotos != null) {
            this.props.DiamlerKey.savedPhotos.map((photo) => {
              if (photo.newName != undefined && photo.oldName != undefined) {
                ques.photos.push({
                  isUploaded: true,
                  uploadedImageUrl: photo.newName,
                  localImageUrl: photo.oldName,
                });
              }
            });
          }
        }
      });

      audit.answers.push({
        answers: questions,
        id: uuidv4(),
        createdAt: Date(),
      });
    }
    this.props.saveDiamlerKeyAudit(audit);
    this.props.onVisibilityChange(false);
  };
  changeForm21Data = (e) => {
    this.setState({
      selectedVehicleForm21: e.target.value,
    });
  };
  changeForm22Data = (e) => {
    this.setState({
      selectedVehicleForm22: e.target.value,
    });
  };

  render() {
    var options = [];
    if (this.props.DiamlerKey.vehicles) {
      this.props.DiamlerKey.vehicles.map((vehicle) => {
        options.push({
          key: vehicle._id,
          text: vehicle.vinNo,
          value: vehicle,
        });
      });
    }

    return (
      <div>
        <ModalView
          open={this.props.isVisible}
          close={() => { this.props.onVisibilityChange(false, {}) }}
          content={
            <>
              <Card
                style={{
                  width: "100%",
                  backgroundColor: getMatchingTint(this.props.color),
                }}
              >
                <Card.Content>
                  <Grid>
                    <Grid.Row style={{ display: "inline-block" }}>
                      <Grid.Column width={8} style={{ display: "inline-block" }}>
                        <Form.Field style={{ display: "inline-block" }}>
                          <Header as="h5" style={{ display: "inline-block" }}>
                            VIN Number
                          </Header>
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column width={8} style={{ display: "inline-block" }}>
                        {this.props.template.vehicle ? (
                          <span>{this.props.template.vehicle.vinNo}</span>
                        ) : (
                          <Dropdown
                            style={{ display: "inline-block", width: "100%" }}
                            placeholder="VIN number"
                            onChange={this.handleVinDropdown}
                            search
                            selection
                            options={options}
                          />



                        )}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Grid>
                    <Grid.Row style={{ display: "inline-block" }}>
                      <Grid.Column width={8} style={{ display: "inline-block" }}>
                        <Form.Field style={{ display: "inline-block" }}>
                          <Header as="h5" style={{ display: "inline-block" }}>
                            Form 21 original given
                          </Header>
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column width={8} style={{ display: "inline-block" }}>
                        {this.state.template.vehicle ? (
                          <TextInput
                            placeholder={"Choose from21 available"}
                            value={this.state.selectedVehicleForm21}
                            onChangeTextInput={(e) => { this.changeForm21Data(e) }}
                          />

                        ) : (
                          <TextInput
                            placeholder={"Choose from21 available"}
                            value={
                              this.state.selectedVehicleForm21
                                ? this.state.selectedVehicleForm21
                                : ""
                            }
                            onChangeTextInput={(e) => { this.changeForm21Data(e) }}
                          />

                        )}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Grid>
                    <Grid.Row style={{ display: "inline-block" }}>
                      <Grid.Column width={8} style={{ display: "inline-block" }}>
                        <Form.Field style={{ display: "inline-block" }}>
                          <Header as="h5" style={{ display: "inline-block" }}>
                            Form 22 original given
                          </Header>
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column width={8} style={{ display: "inline-block" }}>
                        {this.state.template.vehicle ? (
                          <TextInput
                            placeholder={"Choose from22 available"}
                            value={this.state.selectedVehicleForm22}

                            onChangeTextInput={(e) => { this.changeForm22Data(e) }}
                          />

                        ) : (
                          <TextInput
                            placeholder={"from22 available"}
                            value={
                              this.state.selectedVehicleForm22
                                ? this.state.selectedVehicleForm22
                                : ""
                            }
                            onChangeTextInput={(e) => { this.changeForm22Data(e) }}
                          />

                        )}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Card.Content>
                <Card.Content>
                  <Divider horizontal>Dealer Assessment</Divider>
                  <Form.Field>
                    <Grid>
                      {this.props.template.dealerAssessment.map((question) => {
                        return (
                          <Grid.Row>
                            <Grid.Column
                              width={8}
                              style={{ display: "inline-block" }}
                            >
                              {question.question}
                            </Grid.Column>
                            <Grid.Column width={8}>
                              {question.answerType == "text" && (
                                <TextInput
                                  value={this.state.typedAnswer[question._id]}

                                  onChangeTextInput={(e) => {
                                    this.handleDealerAssessmentInputChange(
                                      question._id,
                                      e.target.value
                                    );
                                  }}
                                />

                              )}
                              {question.answerType == "auto" && (
                                <TextInput
                                  value={this.state.typedAnswer[question._id]}
                                  disabled={true}
                                  onChangeTextInput={(e) => {
                                    this.handleDealerAssessmentInputChange(
                                      question._id,
                                      e.target.value
                                    );
                                  }}
                                />

                              )}
                              {question.answerType === "date" &&
                                this.state.soldDateVisible === true && (
                                  <Grid.Column
                                    width={8}
                                    style={{ display: "inline-block" }}
                                  >
                                    <DatePicker
                                      value={
                                        this.state.typedAnswer[question._id]
                                          ? new Date(
                                            this.state.typedAnswer[question._id]
                                          )
                                          : new Date()
                                      }
                                      handleDateChange={(date) => {
                                        this.handleDealerAssessmentInputChange(
                                          question._id,
                                          moment(date).toISOString()
                                        );
                                      }}
                                      label={question.question}
                                    />
                                  </Grid.Column>
                                )}

                              {question.answerType == "options" && (
                                <DropDownPicker
                                  styles={{
                                    display: "inline-block",
                                    width: "100%",
                                  }}

                                  options={question.options.map((label, i) => {
                                    return {
                                      value: label.value,
                                      text: label.label,
                                      key: label.value,
                                    };
                                  })}
                                  value={this.state.typedAnswer[question._id]}
                                  placeholder={"Select any option"}
                                  onChangeDropDown={(data) => {
                                    this.handleDealerAssessmentOptionsSelect(
                                      question._id,
                                      data
                                    );
                                  }}

                                />

                              )}
                              {question.answerType == "photo" && (
                                <Grid.Column
                                  width={8}
                                  style={{ display: "inline-block" }}
                                >
                                  {question.photos.length > 0 ? (
                                    question.photos.map((p, i) => {
                                      return (
                                        <Image.Group
                                          size="mini"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <Image
                                            key={i}
                                            src={
                                              storageBaseUrl + p.uploadedImageUrl
                                            }
                                            onClick={((k) => {
                                              return (e) =>
                                                this.handlePhotoClick(e, k);
                                            })(p)}
                                          />
                                        </Image.Group>
                                      );
                                    })
                                  ) : (
                                    <Grid.Column
                                      width={8}
                                      style={{ display: "inline-block" }}
                                    >
                                      <input
                                        type="file"
                                        content="Upload additional Photos"
                                        icon="cloud upload"
                                        labelPosition="right"
                                        onChange={(e) =>
                                          this.onPhotoSelect(
                                            e,
                                            "dealerAssessment"
                                          )
                                        }
                                        multiple
                                        style={{ display: "inline-block" }}
                                        accept=".jpg,.png"
                                      />
                                      <ButtonHandler
                                        styles={{
                                          marginTop: "10px",
                                          marginLeft: "70%",
                                        }}
                                        color={"green"}
                                        size={"small"}
                                        click={this.uploadPhoto}
                                        title={'Upload'}
                                      />

                                    </Grid.Column>
                                  )}
                                </Grid.Column>
                              )}
                            </Grid.Column>
                          </Grid.Row>
                        );
                      })}
                    </Grid>
                  </Form.Field>
                </Card.Content>
              </Card>
              {!this.state.answerisSold && (
                <div style={{ marginTop: 10, display: "ïnline-block" }}>
                  {this.state.showQuestions && (
                    <ButtonHandler
                      click={this.handleNewButtonClick}
                      styles={{
                        width: "100%",
                        backgroundColor: getMatchingTint(this.props.color),
                        color: getTextColor(this.props.color),
                      }}
                      title={'Add New Update'}
                    />

                  )}
                  {this.state.IsbuttonClick && (
                    <NormalQuestions
                      onDateChange={this.handleQuestionsDateChange}
                      inputChange={this.handleQuestionsInputChange}
                      questions={this.props.template.questions}
                      state={this.state}
                      onPhotoUpload={this.uploadPhoto}
                      onPhotoSelect={this.onPhotoSelect}
                    />
                  )}
                </div>
              )}

              {!this.state.answerisSold && (
                <div>
                  <Divider horizontal>Updates</Divider>
                  <Modal
                    dimmer="blurring"
                    size="fullscreen"
                    closeOnEscape={true}
                    closeOnDimmerClick={true}
                    basic
                    open={this.state.showPhotoSlider}
                    onClose={this.photoSliderClose}
                  >
                    <Modal.Content>
                      <Image
                        fuild
                        centered
                        src={
                          storageBaseUrl +
                          this.state.selectedPhoto.uploadedImageUrl
                        }
                      />
                    </Modal.Content>
                  </Modal>

                  <Card
                    style={{
                      width: "100%",
                      backgroundColor: getMatchingTint(this.props.color),
                    }}
                  >

                    {this.props.template.answers ? (
                      this.props.template.answers.map((eachAnswerGroup) => {
                        return (
                          <Card.Content>
                            <Form.Field>
                              <Grid>
                                {eachAnswerGroup.answers.map((question) => {
                                  return (
                                    <Grid.Row>
                                      <Grid.Column
                                        width={8}
                                        style={{ display: "inline-block" }}
                                      >
                                        {question.question}
                                      </Grid.Column>
                                      {question.answerType == "text" && (
                                        <Grid.Column
                                          width={8}
                                          style={{ display: "inline-block" }}
                                        >
                                          <TextInput
                                            value={question.answer}

                                            onChangeTextInput={(e) => {
                                              console.log(e)
                                            }}
                                          />

                                        </Grid.Column>
                                      )}
                                      {question.answerType == "auto" && (
                                        <Grid.Column
                                          width={8}
                                          style={{ display: "inline-block" }}
                                        >
                                          <TextInput
                                            value={question.answer}
                                            disabled={true}
                                            onChangeTextInput={(e) => {
                                              console.log(e)
                                            }}
                                          />

                                        </Grid.Column>
                                      )}
                                      {question.answerType == "date" && (
                                        <Grid.Column
                                          width={8}
                                          style={{ display: "inline-block" }}
                                        >
                                          <DatePicker
                                            value={
                                              question.answer
                                                ? new Date(question.answer)
                                                : null
                                            }
                                            disabled={true}
                                            label={question.question}
                                          />
                                        </Grid.Column>
                                      )}
                                      {question.answerType == "photo" && (
                                        <Grid.Column
                                          width={8}
                                          style={{ display: "inline-block" }}
                                        >
                                          <Image.Group
                                            size="mini"
                                            style={{ cursor: "pointer" }}
                                          >
                                            {question.photos &&
                                              question.photos.map((p, i) => {
                                                return (
                                                  <Image
                                                    key={i}
                                                    src={
                                                      storageBaseUrl +
                                                      p.uploadedImageUrl
                                                    }
                                                    onClick={((k) => {
                                                      return (e) =>
                                                        this.handlePhotoClick(
                                                          e,
                                                          k
                                                        );
                                                    })(p)}
                                                  />
                                                );
                                              })}
                                          </Image.Group>
                                        </Grid.Column>
                                      )}
                                    </Grid.Row>
                                  );
                                })}
                              </Grid>
                            </Form.Field>
                          </Card.Content>
                        );
                      })
                    ) : (
                      <Card.Content>
                        <ConstantLabel
                          title={"No updates present"}
                          size={"small"}
                          color={"green"}
                        />
                      </Card.Content>
                    )}

                  </Card>
                </div>
              )}
            </>
          }
          size={'small'}
          saveText={!this.state.editMode ? 'Save' : 'Edit'}
          showSave={true}
          saveClick={!this.state.editMode ? this.handleSaveClick : () => { }}
        // close={this.modalClose}
        />

      </div>
    );
  }
}

function NormalQuestions(props) {
  var newQuestionComponent = [];
  props.questions.map((question) => {
    newQuestionComponent.push(
      <Grid.Row>
        <Grid.Column width={8} style={{ display: "inline-block" }}>
          {question.question}
        </Grid.Column>

        {question.answerType == "text" && (
          <Grid.Column width={8} style={{ display: "inline-block" }}>
            <TextInput
              value={props.state.questionAnswer[question._id]}

              onChangeTextInput={(e) => {
                props.inputChange(question._id, e.target.value);
              }}
            />

          </Grid.Column>
        )}
        {question.answerType == "auto" && (
          <Grid.Column width={8} style={{ display: "inline-block" }}>
            <TextInput
              value={props.state.questionAnswer[question._id]}
              disabled={true}
              onChangeTextInput={(e) => {
                props.inputChange(question._id, e.target.value);
              }}
            />

          </Grid.Column>
        )}
        {question.answerType == "date" && (
          <Grid.Column width={8} style={{ display: "inline-block" }}>
            <DatePicker
              value={props.state.questionAnswer[question._id]}
              handleDateChange={(date) => {
                props.onDateChange(question._id, moment(date).toISOString());
              }}
              label={question.question}
            />
          </Grid.Column>
        )}

        {question.answerType == "photo" && (
          <Grid.Column width={8} style={{ display: "inline-block" }}>
            <input
              type="file"
              content="Upload additional Photos"
              icon="cloud upload"
              labelPosition="right"
              onChange={(e) => props.onPhotoSelect(e, "history")}
              multiple
              style={{ display: "inline-block" }}
              accept=".jpg,.png"
            />
            <ButtonHandler
              styles={{ marginTop: "10px", marginLeft: "66%" }}
              color={"green"}
              size={"small"}
              click={props.onPhotoUpload}
              title={'Upload'}
            />

          </Grid.Column>
        )}
      </Grid.Row>
    );
  });

  return (
    <Card style={{ width: "100%" }}>
      <Card.Content>
        <Form.Field>
          <Grid>{newQuestionComponent}</Grid>
        </Form.Field>
      </Card.Content>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    DiamlerKey: state.DiamlerKey,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchDiamlerKeyVehicles: fetchDiamlerKeyVehiclesAction,
      saveDiamlerKeyAudit: saveDiamlerKeyAudit,
      uploadDiamlerKeyPhotoAction: uploadDiamlerKeyPhotoAction,
      showLoadingScreen: showLoadingScreen,
      toCheckPhotos: toCheckPhotos,
    },
    dispatch
  );
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DiamlerKeyNewAudits)
);
