import React, { Component } from "react";
import { Menu } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import CannonStore from "./CannonStore.jsx";
import DashboardIndex from "./Dashboard/DashboardIndex.jsx";
import WidgetIndex from "./widgets/index.jsx";
import {
  fetchDashboardAction,
  clearCannonGraphData,
} from "../../actions/cannon_action";

export class TabView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: "Audits",
    };
  }
  handleItemClick = (e, { name }) => {
    if (name === "Audits") {
      this.props.clearCannonGraphData(this.props.cannon.regionData);
    }
    this.setState({ activeItem: name });
  };
  render() {
    const { activeItem } = this.state;
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          padding: 5,
        }}
      >
        <Menu pointing secondary widths={5}>
          <Menu.Item
            name="Audits"
            active={activeItem === "Audits"}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name="Dashboard"
            active={activeItem === "Dashboard"}
            onClick={this.handleItemClick}
          />
          {/* <Menu.Item
            name="Widgets"
            active={activeItem === "Widgets"}
            onClick={this.handleItemClick}
          /> */}
        </Menu>
        {activeItem === "Audits" && (
          <CannonStore
            template={this.props.template}
            onClose={this.props.onClose}
            color={this.props.color}
          />
        )}
        {activeItem === "Dashboard" && (
          <DashboardIndex
            template={this.props.template}
            onClose={this.props.onClose}
            color={this.props.color}
          />
        )}
        {/* {activeItem === "Widgets" && (
          <WidgetIndex
            template={this.props.template}
            onClose={this.props.onClose}
            color={this.props.color}
          />
        )} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //reducer props to state
    cannon: state.cannon,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchDashboardAction,
      clearCannonGraphData,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TabView)
);
