import {
  LOGIN_FAILURE_ACTION,
  LOGIN_SUCCESS_ACTION,
  LOGOUT_ACTION,
  FORGOT_PASSWORD_SUCCESS_ACTION,
  FORGOT_PASSWORD_FAILURE_ACTION,
  MODAL_OPEN,
  MODAL_CLOSE,
  CHANGE_COLOR,
  FETCH_TIMESHEET_DATA_SUCCESS_ACTION,
  FETCH_TIMESHEET_DATA_FAILURE_ACTION,
} from "../actions/types";
import moment from "moment";
import { setupAxios } from "../util";
import { toast } from "react-semantic-toasts";

const INIT_STATE = {};

function Auth(state = INIT_STATE, action) {
  state = Object.assign({}, state, {});

  switch (action.type) {
    case LOGIN_SUCCESS_ACTION:
      // console.log(action.payload.orgs,"orgs")
      state = {
        id: action.payload.userId,
        displayName: action.payload.displayName,
        mobileNo: action.payload.mobileNo,
        role: action.payload.role,
        accessToken: action.payload.access_token,
        expiresIn: moment().add(action.payload.expires_in, "seconds").format(),
        loginError: false,
        orgs: action.payload.orgs,
        color: "blue",
        timesheetAccess: action.payload.timesheetAccess,

        access: action.payload.access,
      };
      setupAxios(state.accessToken);
      return state;

    case LOGIN_FAILURE_ACTION:
      state = {
        loginError: true,
      };

      return state;
    // case MODAL_OPEN:
    //     console.log(true, "modalopen in redux")
    //     state = {
    //         modalopen: true,
    //     }
    //     return state
    // case MODAL_CLOSE:
    //     console.log(false, "modalclose in redux")
    //     state = {
    //         modalopen: false
    //     }
    //     return state
    case FORGOT_PASSWORD_SUCCESS_ACTION:
      state.message = action.payload.message;
      setTimeout(() => {
        toast({
          type: "info",
          title: action.payload.message,
          description: "",
          time: 3000,
        });
      }, 0);
      state.messageError = false;
      return state;
    case FORGOT_PASSWORD_FAILURE_ACTION:
      state.messageError = true;

    case LOGOUT_ACTION:
      state = {};
      return state;

    case FETCH_TIMESHEET_DATA_SUCCESS_ACTION:
      // console.log(action.payload.master, "payload in timesheet")
      state.timesheetData = action.payload.master;
      return state;

    case FETCH_TIMESHEET_DATA_FAILURE_ACTION:
      state.timesheetDatafailure = action.payload;
      return state;
    case CHANGE_COLOR:
      // console.log(action.payload, "pppppppppppppppppppppcolor");
      state.color = action.payload;
      return state;
  }

  if (state.accessToken) {
    setupAxios(state.accessToken);
  }

  return state;
}

export default Auth;
