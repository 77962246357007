import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { isLoggedIn } from '../../util';
import YamahaQuestion from './YamahaQuestion.jsx';
import { Segment, Input, Dropdown, Button} from 'semantic-ui-react';
import { saveYamahaAudit } from '../../actions/yamaha_actions.js';

class YamahaQuestionnaireDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editMode: false,
            selectedItem: "",
            query: ""
        };
    }

    componentDidMount() {
        if (!isLoggedIn(this.props.auth)) {
            this.props.history.push(`/login`);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!isLoggedIn(this.props.auth)) {
            this.props.history.push(`/login`);
            return false;
        }
        return true;
    }

    handleChangeItem = (e, data) => {
        this.setState({
            selectedItem: this.props.questionnaire.items.filter(s => s.id === data.value)[0]
        });
    }

    searchFilter = (e, data) => {
        this.setState({ query: data.value });
    }

    handleEditClick = () => {
        this.setState({ editMode: true });
    }

    handleCancelClick = () => {
        this.setState({ editMode: false });
    }

    handleSaveClick = () => {
        this.props.saveYamahaAudit(this.props.yamaha.audits[0]);
        this.setState({ editMode: false });
    }

    matches = (question, query) => {
        if (!query) {
            return true;
        }

        var flatData = JSON.stringify(question);
        return flatData.toLowerCase().includes(query.toLowerCase());
    }

    render() {
        var questionnaire = this.props.questionnaire;
        var items = [];
        var questions = [];

        for (var s of questionnaire.items) {
            items.push({
                key: s.id,
                value: s.id,
                text: s.name 
            });
        }

        if (!this.state.selectedItem) {
            this.state.selectedItem = questionnaire.items[0];
        }

        if (this.state.selectedItem) {
            for (var criteria of this.state.selectedItem.criterias) {
                for (var question of criteria.questions) {
                    if (this.matches(question, this.state.query)) {
                        question.criteriaName = criteria.name;
                        questions.push(question);
                    }
                }
            }
        }

        return (
            <Segment>
                <Dropdown style={{ display: "inline-block" }} placeholder='Item' search selection defaultValue={items[0].value} options={items} onChange={this.handleChangeItem} />
                <div style={{ display: "inline-block", marginLeft: 20 }} >
                    {!this.state.editMode &&
                        <div style={{ display: "inline-block" }}>
                            <Button size='mini' style={{ display: "inline-block" }} onClick={this.handleEditClick}>
                                Edit
                            </Button>
                        </div>
                    }

                    {this.state.editMode &&
                        <div style={{ display: "inline-block" }}>
                            <Button size='mini' color='blue' style={{ display: "inline-block" }} onClick={this.handleSaveClick}>
                                Save
                            </Button>
                            <Button size='mini' style={{ marginLeft: 10, display: "inline-block" }} onClick={this.handleCancelClick}>
                                Cancel
                            </Button>
                        </div>
                    }
                </div>
                <Input icon='search' style={{ display: "inline-block", float: "right" }} onChange={this.searchFilter} placeholder='Search...' />

                {questions.map((q, i) => {
                    return <YamahaQuestion key={questionnaire.auditId + q.id} question={q} editMode={this.state.editMode} />;
                })}
            </Segment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        yamaha: state.yamaha
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        saveYamahaAudit
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(YamahaQuestionnaireDetail));