import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { isLoggedIn } from "../../util";
import AuditScore from "./AuditScore.jsx";
import AuditDetail from "./AuditDetail.jsx";
import {
  Segment,
  Dropdown,
  Popup,
  Button,

} from "semantic-ui-react";
import DropDownPicker from '../commonViews/DropDownPicker.jsx'
import PropTypes from "prop-types";
import { withRouter } from "react-router";


import TanishqTable from "../commonViews/TanishqTable";
import moment from "moment";
import MailPopup from "../MailPopup.jsx";
import {
  exportReportsAction,
  fetchAuditsAction,
  Users,
  getPreviousAudits,
  approvedAudits,
  getTanishqTemplate
} from "../../actions/audit_actions";

import "react-datepicker/dist/react-datepicker.css";
import {
  getMainBackground,
  getMatchingTint,
  getFontColor,
  getTextColor,
} from "../../ThemeHandlers/theme";
import ConstantLabel from '../commonViews/ConstantLabel.jsx'

class Audits extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedAuditId: "",
      isTableView: true,
      isAuditDetailView: false,
      isMailPopupView: false,
      mailIds: "",
      ccIds: "",
      isDownloading: false,
      isLoading: false,
      year: "",
      audits: {},
      tableLoad: false,
      dateChanged: false,
      color: "",
      previousAudits : [],
      templatesList:[]
    };
  }

  handleUsernameChange = (e) => {
    this.setState({ username: e.target.value });
  };

  handlePasswordChange = (e) => {
    this.setState({ password: e.target.value });
  };

  handleKeyPress = (e) => {
    if (e.key == "Enter") {
      this.authenticate();
    }
  };

  authenticate = () => {
    this.props.login(this.state.username, this.state.password);
  };

  async componentDidMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
      return;
    }
    if (this.props.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
    await this.props.getTanishqTemplate({
      onSuccess:(res)=>{
        this.setState({templatesList:res})
        this.setState({year:res[res.length-1]['auditName']})
        this.fetchData(0, 30, res[res.length-1]['auditName']);
      },
      onFailure:()=>{

      }
    })
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      isLoading: false,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.audits !== prevProps.audits) {
      this.setState({
        audits: this.props.audits,
      });
    }
    if (this.props.auth.color !== prevProps.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
      return false;
    }
    return true;
  }

  onOrgClick(org) { }

  handleAuditScoreCloseClick = () => {
    this.setState({ isTableView: true });
  };

  handleAuditDetailCloseClick = () => {
    this.setState({ isAuditDetailView: false });
  };

  handleTableViewAuditClick = (audit) => {
    this.props.getPreviousAudits({
      templateId:audit.templateId,
      locationCode:audit.locationCode,
      onSuccess:(res)=>{
        this.setState({previousAudits:res})
      },
      onFailure:()=>{

      }
    })
    this.setState({ selectedAuditId: audit._id, isTableView: false });
  };

  handleListViewAuditClick = (audit) => {
    this.setState({ selectedAuditId: audit._id });
  };

  handleViewAuditDetailsClick = (e, audit) => {
    this.setState({ isAuditDetailView: true });
  };

  handleDownloadReportClick = (e) => {
    this.props.downloadReport(this.state.selectedAuditId);
  };

  handleMailReportClick = (e) => {
    this.setState({ isMailPopupView: !this.state.isMailPopupView });
  };

  handleApproveAudit = (e) =>{
    let auditId = this.state.selectedAuditId;
    this.props.approvedAudits({auditId,
      onSuccess:(res)=>{
        alert('Audits Approved Successfully')
    },
    onFailure:()=>{

    }
  })
    console.log(this.state.selectedAuditId,'aduitId')
  }

  handleSendMailReportClick = (m, cc) => {
    this.setState({ isMailPopupView: !this.state.isMailPopupView, mailIds: m, ccIds: cc });
    // console.log(m, cc, 'mails')
    this.props.mailReport(this.state.selectedAuditId, m, cc);
  };

  handleDownloadReportPhotosClick = (e) => {
    let open = `#/images/` + this.state.selectedAuditId;
    window.open(open);
  };

  handleOnNavigationClick = (type, pagination, _audits) => {
    switch (type) {
      case "previous":
        if (pagination.hasPrevious) {
          this.fetchData(
            {
              directNaviationKey: pagination.previousPage,
              isSearchAction: _audits.isSearchResult,
            },
            _audits.isSearchResult ? _audits.searchResults : _audits.audits,
            this.state.year
          );
        } else {
          console.log("Its first page");
        }
        break;
      case "next":
        if (pagination.hasNext) {
          this.fetchData(
            {
              directNaviationKey: pagination.nextPage,
              isSearchAction: _audits.isSearchResult,
            },
            _audits.isSearchResult ? _audits.searchResults : _audits.audits,
            this.state.year
          );
        } else {
          console.log("Its last page");
        }
        break;
    }
  };

  getAudits = (activePageKey) => {
    var audits = [];
    let pagination;
    let _audits = this.state.audits;
    let __auditsObject = _audits.isSearchResult
      ? _audits.searchResults.audits
      : _audits.audits;
    if (__auditsObject != null && __auditsObject[activePageKey] !== undefined) {
      if (__auditsObject[activePageKey]) {
        let activeAudits = __auditsObject[activePageKey].data;
        pagination = __auditsObject[activePageKey].pagination;
        for (var audit of activeAudits) {
          var org = _audits.orgs.filter((o) => o._id == audit.orgId)[0];
          audit.org = org;
          try {
            audit.auditor = _audits.users.filter(
              (u) => u._id === audit.auditorId
            )[0].displayName;
          } catch (err) {
            audit.auditor = "";
          }
          audits.push(audit);
        }
        audits.push(audit);
      }
    }
    return { audits, pagination };
  };

  exportData = () => {
    this.props.exportReportsAction();
  };

  onTemplateYearChange = (data) => {
    this.fetchData(0, 30, data);

    this.setState({ year: data, isLoading: true, dateChanged: true });
  };

  fetchData = (index, page, date) => {
    this.setState({
      isLoading: true,
      tableLoad: true,
    });
    this.props.fetchAudits({
      queryObejct: { pageIndex: index, pageSize: page },
      audits: this.props.audits.audits,
      month: date === undefined ? this.state.year : date,
      clearFilter: null,
      onSuccess: () => {
        this.setState({
          isLoading: false,
          tableLoad: false,
          dateChanged: false,
        });
      },
      onFailure: () => {
        this.setState({
          isLoading: false,
          tableLoad: false,
          dateChanged: false,
        });
      },
    });
  };

  render() {
    let _audits = this.state.audits;
    let activePageKey, totalPages, nextPageText;
    if (Object.keys(_audits).length !== 0) {
      if (_audits["isSearchResult"] === true) {
        activePageKey = _audits.searchResults.activePageKey;
        totalPages = _audits.searchResults.totalPages;
        nextPageText = "Search Next";
      } else {
        activePageKey = _audits.activePageKey;
        totalPages = _audits.totalPages;
        nextPageText = "Next";
      }
    }

    let { audits, pagination } = this.getAudits(activePageKey);
    var tanishqCol = [
      {
        title: "Audit",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.auditName}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.auditName.toLowerCase().indexOf(term.toLowerCase()) > -1,
      },
      {
        title: "Location",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.location}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.location.toLowerCase().indexOf(term.toLowerCase()) > -1,
      },
      {
        title: "Location Code",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.locationCode}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.locationCode.toLowerCase().indexOf(term.toLowerCase()) > -1,
      },
      {
        title: "Auditor",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.auditor}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.auditor.toLowerCase().indexOf(term.toLowerCase()) > -1,
      },
      {
        title: "Status",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.status}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          term !== ""
            ? rowData.status.toLowerCase().indexOf(term.toLowerCase()) > -1
            : undefined,
      },
      {
        title: "Approved Status",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.auditApprovedStatus}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          term !== ""
            ? rowData.status.toLowerCase().indexOf(term.toLowerCase()) > -1
            : undefined,
      },
      {
        title: "Score",
        field: "lsi",
        type: "numeric",
        render: (rowData, index) => {
          return (
            <span
              key={`${index - rowData._id}`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.lsi}
            </span>
          );
        },
      },

      {
        title: "Audit Start Date",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.createdAt}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.createdAt.toLowerCase().indexOf(term.toLowerCase()) > -1,
      },
    ];


    let filteredAudits = audits.filter(function (element) {
      return element !== undefined;
    });

    var years = []

    if(this.state.templatesList.length > 0){
      this.state.templatesList.map((template)=>{
        years.push(
          {
            value:template.auditName,
            text:template.auditName,
            key:template.auditName
          }
        )
      })
    }

    var title = "Tanishq Lean Audits";
    var selectedAudit;

    if (audits.length > 0 && audits[0] != undefined) {
      console.log(audits,'audits')
      if (!this.state.selectedAuditId) {
        selectedAudit = audits[0];
        this.state.selectedAuditId = selectedAudit._id;
      } else {
        if (audits[0] !== undefined) {
          selectedAudit = audits.filter((a) =>
            a != undefined ? a._id == this.state.selectedAuditId : ""
          )[0];
        }
      }

      selectedAudit = selectedAudit ? selectedAudit : audits[0];

      if (!this.state.isTableView) {
        title = selectedAudit.auditName;
      }
    } else if (!this.state.isTableView) {
      return (
        <div>
          <h1>No data found!</h1>
          {_audits.isSearchResult && (
            <div>
              <span>{JSON.stringify(_audits.appliedFilters)}</span>
              <button
                onClick={(e) => {
                  this.props.fetchAudits(null, null, this.state.year, true);
                }}
              >
                Clear Filters
              </button>
            </div>
          )}
        </div>
      );
    }

    return (
      <div style={{ flexGrow: 1, display: "flex", flexFlow: "column" }}>
        <div>
          <div style={{ flexDirection: "row" }}>
            {this.state.isTableView && (
              <div style={{ marginTop: "-50" }}>
                <h1 style={{ color: getTextColor(this.state.color) }}>
                  {title}
                </h1>
              </div>
            )}
            {this.state.isTableView && (
              <div style={{ marginBottom: "20" }}>
                <DropDownPicker styles={{
                  display: "inline-block",
                  width: "20%",
                  zIndex: "100",
                  width: 300
                }}
                  placeholder={"Select Year"}
                  value={this.state.year}
                  options={years}
                  onChangeDropDown={(data) => { this.onTemplateYearChange(data) }}

                />
               
              </div>
            )}
          </div>

          {this.state.isTableView && (
            <div
              style={{
                float: "right",
                marginBottom: 20,
                marginTop: -30,
                marginRight: 10,
              }}
            >
              <ConstantLabel
                title={"Export Audit Details"}
                size={"medium"}
                onClick={() => {
                  this.exportData();
                }} styles={{
                  cursor: "pointer",
                  backgroundColor: getMainBackground(this.state.color),
                  color: getFontColor(this.state.color),
                }}
                iconName={"download"}
              />

            </div>
          )}

          {!this.state.isTableView && (
            <div
              style={{
                position: "absolute",
                top: "1.5%",
                right: "2%",
                zIndex: 1000,
              }}
            >
              <Button.Group>
                {!pagination.hasPrevious && (
                  <Popup
                    trigger={
                      <Button
                        loading={this.state.isLoading}
                        onClick={(e, data) => {
                          this.handleOnNavigationClick(
                            "previous",
                            pagination,
                            _audits
                          );
                        }}
                      >
                        Previous
                      </Button>
                    }
                    inverted
                    content="It's first page"
                    position="bottom right"
                  />
                )}
                {pagination.hasPrevious && (
                  <Button
                    loading={this.state.isLoading}
                    onClick={(e, data) => {
                      this.handleOnNavigationClick(
                        "previous",
                        pagination,
                        _audits
                      );
                    }}
                  >
                    Previous
                  </Button>
                )}
                <Button.Or />
                {!pagination.hasNext && (
                  <Popup
                    trigger={
                      <Button
                        loading={this.state.isLoading}
                        onClick={(e, d) => {
                          this.handleOnNavigationClick(
                            "next",
                            pagination,
                            _audits
                          );
                        }}
                        style={{
                          backgroundColor: getMainBackground(this.state.color),
                          color: getFontColor(this.state.color),
                        }}
                      >
                        {nextPageText}
                      </Button>
                    }
                    inverted
                    content="It's last page"
                    position="bottom right"
                  />
                )}
                {pagination.hasNext && (
                  <Button
                    loading={this.state.isLoading}
                    onClick={(e, d) => {
                      this.handleOnNavigationClick("next", pagination, _audits);
                    }}
                    style={{
                      backgroundColor: getMainBackground(this.state.color),
                      color: getFontColor(this.state.color),
                    }}
                  >
                    {nextPageText}
                  </Button>
                )}
              </Button.Group>
            </div>
          )}
        </div>
        <div style={{ display: "flex", flexGrow: 1, flexFlow: "column" }}>
          {
            this.state.isTableView && (
              // Array.isArray(filteredAudits) === true && (

              <TanishqTable
                selection={false}
                data={
                  Array.isArray(filteredAudits) === true ? filteredAudits : []
                }
                columns={tanishqCol}
                paginate={pagination}
                handle={(a, b, c) => {
                  this.handleOnNavigationClick(a, b, c);
                }}
                load={this.state.isLoading}
              />
            )
          }

          {!this.state.isTableView && (
            <div style={{ flexGrow: 1, display: "flex" }}>
              <div
                style={{
                  flex: "0 0 300px",
                  // background: "#fafafa",
                  padding: 15,
                }}
              >
                {audits.map((audit, i) => {
                  return (
                    <AuditCell
                      audit={audit}
                      key={i}
                      selected={audit._id === selectedAudit._id}
                      onClick={this.handleListViewAuditClick}
                      getColor={getMainBackground}
                      color={this.state.color}
                      getCellColor={getMatchingTint}
                    />
                  );
                })}
              </div>
              {!this.state.isAuditDetailView && (
                <AuditScore
                  audit={selectedAudit}
                  previousAudits={this.state.previousAudits}
                  onClose={this.handleAuditScoreCloseClick}
                  onViewDetails={this.handleViewAuditDetailsClick}
                  onDownloadReport={this.handleDownloadReportClick}
                  onDownloadPhotos={this.handleDownloadReportPhotosClick}
                  onMailReport={this.handleMailReportClick}
                  onApproveReport={this.handleApproveAudit}
                />
              )}

              {this.state.isAuditDetailView && (
                <AuditDetail
                  audit={selectedAudit}
                  previousAudits={this.state.previousAudits}
                  onClose={this.handleAuditDetailCloseClick}
                  apis={this.props.apis}
                  getColor={getMainBackground}
                  color={this.state.color}
                  getCellColor={getMatchingTint}
                />
              )}
            </div>
          )}

          {!this.state.isTableView && this.state.isLoading && (
            <div
              style={{
                height: "100%",
                width: "100%",
                position: "fixed",
                marginTop: "5%",
              }}
            >
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  backgroundColor: "#ffffff70",
                }}
              >
                {/* <Loader active inline='centered' indeterminate>Preparing Files</Loader> */}
              </div>
            </div>
          )}
        </div>
        {this.state.isMailPopupView ? (
          <MailPopup
            open={this.state.isMailPopupView}
            mailIds={this.state.mailIds}
            ccIds={this.state.ccIds}
            closePopup={this.handleMailReportClick.bind(this)}
            sendMailAndClosePopup={this.handleSendMailReportClick}
            getColor={getMainBackground}
            color={this.state.color}
            getCellColor={getMatchingTint}
          />
        ) : null}

        {!this.props.audits.mailSentStatus}
      </div>
    );
  }
}

function InputFilter(props) {
  return (
    <input
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          if (e.target.value)
            props.cellInfo.onChange({
              filterName: props.filterName,
              data: e.target.value,
            });
          else props.cellInfo.onChange("");
        }
      }}
      onChange={(e) => {
        if (e.target.value === "") props.cellInfo.onChange("");
      }}
    />
  );
}

function AuditTableCell(props) {
  function onClick() {
    props.onClick(props.row);
  }
  return (
    <div style={props.style} onClick={onClick}>
      {props.text}
    </div>
  );
}

function AuditCell(props) {
  var audit = props.audit;
  var bgStyle = {
    width: 5,
  };

  if (props.selected) {
    bgStyle.background = props.getColor(props.color);
  }

  return (
    <Segment
      style={{
        display: "flex",
        padding: "0px",
        backgroundColor: props.getCellColor(props.color),
        border: `1px solid ${props.getColor(props.color)}`,
      }}
      onClick={() => {
        props.onClick(audit);
      }}
    >
      <div style={bgStyle} />
      <div style={{ padding: "1em", lineHeight: 1.7 }}>
        {audit.auditName} - {audit.org.name}
        <br />
        by {audit.auditor}
        <br />
        {moment(audit.createdAt).format("llll")}
      </div>
    </Segment>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    audits: state.audits,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(
    {
      fetchAudits: fetchAuditsAction,
      users: Users,
      downloadReport: ownProps.apis.downloadAuditReportAction,
      mailReport: ownProps.apis.mailAuditReportAction,
      downloadPhotos: ownProps.apis.downloadAuditReportPhotoAction,
      exportReportsAction: exportReportsAction,
      getPreviousAudits,
      approvedAudits,
      getTanishqTemplate
    },
    dispatch
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Audits));
