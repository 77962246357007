import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn } from "../../util";
import { Grid, Dropdown } from "semantic-ui-react";
import moment from "moment";

import PandGAudits from "./PandGAudits.jsx";
import PandGAuditDetail from "./PandGAuditDetail.jsx";

import {
  fetchPandGAgentsAction,
  fetchPandGDistributersAction,
  fetchPandGManagersAction,
  fetchPandGLocationsAction,
  fetchPandGGetAllAuditsAction,
  fetchPandGTemplatesAction,
} from "../../actions/pandg_actions.js";
import {
  getTextColor,
  getMainBackground,
  getFontColor,
} from "../../ThemeHandlers/theme";
import ConstantLabel from "../commonViews/ConstantLabel.jsx";

class PandG extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAudit: "",
      isTableView: true,
      selectedDistributor: "",
      selectedManager: "",
      selectedAgency: "",
      selectedLocation: "",
      monthsRangeList: this.months(),
      audits: [],
      color: "",
    };
  }

  months() {
    let startDate = moment();
    let endDate = moment().subtract(1, "y");
    let dates = [];
    let month = moment(startDate); //clone the startDate
    let index = 0;
    while (endDate < month) {
      dates.push({
        key: index,
        text: month.format("MMMM YYYY"),
        value: month.format("MMMM,YYYY"),
      });
      index++;
      month.subtract(1, "month");
    }
    return dates;
  }
  componentWillReceiveProps(nextProps) { }

  componentWillMount() { }
  componentDidUpdate(prevProps) {
    if (this.props.auth.color !== prevProps.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
    if (this.props.pandg.audits !== prevProps.pandg.audits) {
      this.setState({ audits: this.props.pandg.audits });
    }
  }
  componentDidMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
    }
    if (this.props.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
    this.props.fetchPandGTemplatesAction();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
      return false;
    }
    return true;
  }

  handleTableViewAuditClick = (audit) => {
    this.setState({ selectedAudit: audit, isTableView: false });
  };

  handleAuditDetailCloseClick = () => {
    this.setState({ isTableView: true });
  };

  convertToComboData(records, _for) {
    if (!records || !(records instanceof Array)) return [];
    var comboData = [];
    var {
      selectedMonth,
      selectedDistributor,
      selectedManager,
      selectedAgency,
    } = this.state;
    switch (_for) {
      case "months":
        // comboData = this.state.monthsRangeList;
        // break;
        comboData = records
          .map((template) => {
            return {
              key: template["_id"],
              text: template["auditName"],
              value: template,
            };
          })
          .filter((_) => {
            if (_) return true;
          });

        break;
      case "distributors":
        comboData = records
          .map((distributor) => {
            if (distributor.templateId === selectedMonth) {
              return {
                key: distributor["_id"],
                text: distributor["code"] + " - " + distributor["name"],
                value: distributor,
              };
            }
          })
          .filter((_) => {
            if (_) return true;
          });

        break;
      case "manager":
        comboData = records
          .map((manager) => {
            if (
              manager.distributorId &&
              manager.distributorId === selectedDistributor
            )
              return {
                key: manager["_id"],
                text: manager["code"] + "-" + manager["name"],
                value: manager,
              };
          })
          .filter((_) => {
            if (_) return true;
          });
        break;
      case "agency":
        comboData = records
          .map((agency) => {
            if (
              agency.distributorId === selectedDistributor &&
              agency.managerId == selectedManager
            )
              return {
                key: agency["_id"],
                text: agency["code"] + "-" + agency["name"],
                value: agency,
              };
          })
          .filter((_) => {
            if (_) return true;
          });
        break;
      case "locations":
        comboData = records
          .map((location) => {
            if (
              location.distributorId === selectedDistributor &&
              location.managerId == selectedManager &&
              location.agencyId === selectedAgency
            )
              return {
                key: location["_id"],
                text: location["code"] + "-" + location["name"],
                value: location,
              };
          })
          .filter((_) => {
            if (_) return true;
          });
        break;
    }
    return comboData;
  }

  onMonthComboChange = (e, combobox) => {
    this.setState({
      selectedMonth: combobox.value["_id"],
      selectedDistributor: [],
      selectedManager: [],
      selectedAgency: [],
      selectedLocation: [],
    });
    this.props.fetchPandGDistributers(combobox.value["_id"]);
  };

  onDistributorsComboChange = (e, combobox) => {
    this.setState({ selectedDistributor: combobox.value["_id"] });
    this.props.fetchPandGManagers(
      this.state.selectedMonth,
      combobox.value["_id"]
    );
  };

  onManagersComboChange = (e, combobox) => {
    this.setState({ selectedDistributor: combobox.value["distributorId"] });
    this.setState({ selectedManager: combobox.value["_id"] });
    this.props.fetchPandGAgents(
      this.state.selectedMonth,
      this.state.selectedDistributor,
      combobox.value["_id"]
    );
  };

  onAgenciesComboChange = (e, combobox) => {
    this.setState({ selectedDistributor: combobox.value["distributorId"] });
    this.setState({ selectedManager: combobox.value["managerId"] });
    this.setState({ selectedAgency: combobox.value["_id"] });
    this.props.fetchPandGLocations(
      this.state.selectedMonth,
      this.state.selectedDistributor,
      this.state.selectedManager,
      combobox.value["_id"]
    );
  };

  onLocationsComboChange = (e, combobox) => {
    this.setState({ selectedDistributor: combobox.value["distributorId"] });
    this.setState({ selectedManager: combobox.value["managerId"] });
    this.setState({ selectedAgency: combobox.value["agencyId"] });
    this.setState({ selectedLocation: combobox.value["_id"] });
  };

  onViwDetailsClick = (e, element) => {
    var state = this.state;
    if (
      state.selectedDistributor !== "" &&
      state.selectedManager !== "" &&
      state.selectedAgency !== "" &&
      state.selectedLocation !== ""
    ) {
      this.props.fetchPandGGetAllAudits(
        state.selectedDistributor,
        state.selectedManager,
        state.selectedAgency,
        state.selectedLocation
      );
    } else {
      alert(
        "No Data Provided please select Month, Distributor, Manager, Agency, Location"
      );
    }
  };

  render() {
    var monthsList = this.convertToComboData(
      this.props.pandg.templateList,
      "months"
    );

    var distributorList = this.convertToComboData(
      this.props.pandg.distributorList,
      "distributors"
    );

    var agentList = this.convertToComboData(
      this.props.pandg.agentList,
      "agency"
    );
    var managerList = this.convertToComboData(
      this.props.pandg.managerList,
      "manager"
    );
    var locations = this.convertToComboData(
      this.props.pandg.locations,
      "locations"
    );
    var audits = this.state.audits.length !== 0 ? this.state.audits : [];

    return (
      <div
        style={{
          overflow: "auto",
          width: "100%",
          marginRight: "-35px",
        }}
      >
        <h1 style={{ color: getTextColor(this.state.color) }}>
          Procter and Gamble DTC audit
        </h1>

        {this.state.isTableView && (
          <Grid style={{ marginBottom: 10, paddingLeft: 10, marginRight: 10 }}>
            <Grid.Row columns={3} style={{ height: 70 }}>
              <Grid.Column>
                <Dropdown
                  placeholder="Select Month"
                  fluid
                  search
                  selection
                  style={{ zIndex: 500 }}
                  options={monthsList}
                  onChange={this.onMonthComboChange}
                />
              </Grid.Column>
              <Grid.Column>
                <Dropdown
                  placeholder="Select Distributors"
                  fluid
                  search
                  selection
                  style={{ zIndex: 500 }}
                  options={distributorList}
                  onChange={this.onDistributorsComboChange}
                />
              </Grid.Column>
              <Grid.Column>
                <Dropdown
                  placeholder="Select Functional Managers"
                  fluid
                  search
                  selection
                  style={{ zIndex: 500 }}
                  options={managerList}
                  onChange={this.onManagersComboChange}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={3} style={{ height: 70 }}>
              <Grid.Column>
                <Dropdown
                  placeholder="Select Agencies"
                  fluid
                  search
                  selection
                  style={{ zIndex: 100 }}
                  options={agentList}
                  onChange={this.onAgenciesComboChange}
                />
              </Grid.Column>
              <Grid.Column>
                <Dropdown
                  placeholder="Select Locations"
                  fluid
                  search
                  selection
                  style={{ zIndex: 100 }}
                  options={locations}
                  onChange={this.onLocationsComboChange}
                />
              </Grid.Column>
              <Grid.Column>
                <ConstantLabel
                  title={"View Details"}
                  size={"large"}
                  onClick={this.onViwDetailsClick}
                  styles={{
                    cursor: "pointer",
                    padding: "10px",
                    backgroundColor: getMainBackground(this.state.color),
                    color: getFontColor(this.state.color),
                  }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <PandGAudits
                pandgaudits={audits}
                manager={this.state}
                handleTableViewAuditClick={this.handleTableViewAuditClick}
              >
                {" "}
              </PandGAudits>
            </Grid.Row>
          </Grid>
        )}
        {!this.state.isTableView && (
          <PandGAuditDetail
            pandgaudits={audits}
            color={this.state.color}
            selectedAudit={this.state.selectedAudit}
            onClose={this.handleAuditDetailCloseClick}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    pandg: state.pandg,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchPandGAgents: fetchPandGAgentsAction,
      fetchPandGDistributers: fetchPandGDistributersAction,
      fetchPandGManagers: fetchPandGManagersAction,
      fetchPandGLocations: fetchPandGLocationsAction,
      fetchPandGGetAllAudits: fetchPandGGetAllAuditsAction,
      fetchPandGTemplatesAction: fetchPandGTemplatesAction,
    },
    dispatch
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PandG));
