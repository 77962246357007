import React from "react";
import { Menu } from "semantic-ui-react";
import {
    getActiveColor,
    getMainBackground,
    getFontColor,
} from "../../ThemeHandlers/theme";
import IconPicker from "./IconPicker";

const FormMenuItem = (props) => {
    return (
        <Menu.Item
            name={props.name}
            active={props.activeItem === props.name}
            style={{
                backgroundColor:
                    props.activeItem === props.name ? getActiveColor(props.color) : null,
            }}
            onClick={props.itemClick}
        >
            <IconPicker
                name={props.iconName}
                styles={{
                    color:
                        props.activeItem === props.name
                            ? getMainBackground(props.color)
                            : getFontColor(props.color),
                }}
            />
            <span
                style={{
                    color:
                        props.activeItem === props.name
                            ? getMainBackground(props.color)
                            : getFontColor(props.color),
                }}
            >
                {props.title}
            </span>
        </Menu.Item>
    );
};

export default FormMenuItem;
