import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn } from "./../../util";
import {
  salesUploadUserCsv,
  salesUploadMasterCsv,
  salesUploaDCountryCsv,
  dismissLogs,
  initiateUpload,
} from "../../actions/control_panel_action";
import { Grid, Dimmer, Loader } from "semantic-ui-react";
import { Button, Modal } from "semantic-ui-react";
import CommonForAllPannels from "./CommonForAllPannels.jsx";

export class SalesControlPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userCsvFile: null,
      masterCsvFile: null,
      countryCsvFile: null,
    };
  }

  componentWillMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!isLoggedIn(nextProps.auth)) {
      this.props.history.push(`/login`);
      return false;
    }
    return true;
  }

  saleshandleUserFileUpload = (files) => {
    const file = files.target.files[0];
    this.setState({ userCsvFile: file });
    files.target.files = null;
  };

  saleshandleCountryFileUpload = (files) => {
    const file = files.target.files[0];
    this.setState({ countryCsvFile: file });
    files.target.files = null;
  };

  saleshandleMasterFileUpload = (files) => {
    const file = files.target.files[0];
    this.setState({ masterCsvFile: file });
    files.target.files = null;
  };
  salesuploadUserData = () => {
    this.props.initiateUpload(true);
    this.props.salesUploadUserCsv(this.state.userCsvFile);
  };

  salesuploadMasterData = () => {
    this.props.initiateUpload(true);
    this.props.salesUploadMasterCsv(this.state.masterCsvFile);
  };

  salesuploadCountryData = () => {
    this.props.initiateUpload(true);
    this.props.salesUploaDCountryCsv(this.state.countryCsvFile);
  };

  salescloseLog = () => {
    this.props.dismissLogs(false);
  };

  render() {
    let salesPannel = [
      {
        itemForSpan: " Upload User CSV ",
        itemInputChange: this.saleshandleUserFileUpload,
        itemClickButton: this.salesuploadUserData,
        itemContentForButton: "Upload User CSV",
      },
      {
        itemForSpan: "Upload Store and Countrylist CSV ",
        itemInputChange: this.saleshandleCountryFileUpload,
        itemClickButton: this.salesuploadCountryData,
        itemContentForButton: "Upload Store and Countrylist CSV",
      },
      {
        itemForSpan: "Upload Master CSV",
        itemInputChange: this.saleshandleMasterFileUpload,
        itemClickButton: this.salesuploadMasterData,
        itemContentForButton: "Upload Master CSV",
      },
    ];
    return (
      <div
        style={{
          marginLeft: "2vw",
          marginTop: "7vh",
          fontWeight: "bold",
          fontFamily: "Arial, Helvetica, sans-serif",
        }}
      >
        <Dimmer active={this.props.controlPanel.uploadIncomplete}>
          <Loader size="massive">Uploading</Loader>
        </Dimmer>
        <Grid columns={2}>
          <Grid.Row>
            <CommonForAllPannels child={salesPannel} color={this.props.color} />
          </Grid.Row>
        </Grid>
        {this.props.controlPanel.showLog && (
          <Modal
            close={this.salescloseLog}
            open={this.props.controlPanel.showLog}
          >
            <Modal.Header>Upload Report</Modal.Header>
            <Modal.Content>
              <div
                dangerouslySetInnerHTML={{
                  __html: this.props.controlPanel.log,
                }}
              />
            </Modal.Content>
            <Modal.Actions>
              <Button color="black" onClick={this.salescloseLog}>
                Dismiss
              </Button>
            </Modal.Actions>
          </Modal>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    home: state.home,
    controlPanel: state.controlPanel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      initiateUpload: initiateUpload,
      dismissLogs: dismissLogs,
      salesUploadUserCsv: salesUploadUserCsv,
      salesUploadMasterCsv: salesUploadMasterCsv,
      salesUploaDCountryCsv: salesUploaDCountryCsv,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SalesControlPanel)
);
