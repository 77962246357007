import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn } from "../../util";
import { Header, Dropdown } from "semantic-ui-react";
import ModalView from "../commonViews/ModalView";
import IconPicker from "../commonViews/IconPicker";
import DropDownPicker from "../commonViews/DropDownPicker";
import TableView from "../commonViews/TableView";
import QuestionsView from "./QuestionsView.jsx";
import {
  fetchAudits,
  fetchProducts,
  updateAudit,
  downloadReport,
  downloadPDFReport,
} from "../../actions/godrej_action";
import {
  getTextColor,
  getMainBackground,
  getMatchingTint,
  getFontColor,
} from "../../ThemeHandlers/theme";

class Godrej extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      title: "Godrej Audits",
      audits: [],
      selectedAudit: null,
      filteredAudits: [],
      products: [],
      isAuditsListView: true,
      isAuditView: false,
      isProductsView: false,
      isQuestionsView: false,
      year: new Date().getFullYear(),
      godrejAudits: [],
      color: "",
      loading: false,
    };
  }

  componentDidMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
      return;
    }
    if (this.props.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
    this.setState({ loading: true });
    this.props.fetchAudits({
      year: this.state.year,
      onSuccess: () => {
        this.setState({ loading: false });
      },
      onFailure: () => {
        this.setState({ loading: false });
      },
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.isProductsView && nextProps.godrej.products.length) {
      this.setState({
        products: nextProps.godrej.products,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth.color !== prevProps.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
    if (this.props.godrej.audits !== prevProps.godrej.audits) {
      this.setState({ godrejAudits: this.props.godrej.audits });
    }
  }

  showAudits = (audits) => {
    this.setState({
      title: audits.length
        ? `Godrej Audits (${audits[0].claimMonth}-${audits[0].claimYear})`
        : "Godrej Audits",
      isAuditsListView: false,
      isAuditView: true,
      isProductsView: false,
      products: [],
      selectedAudit: null,
      // filteredAudits: audits ? audits : this.state.filteredAudits
    });
    var selectedAuidts = audits.length !== 0 ? audits : [];
    this.setState({ filteredAudits: selectedAuidts.audits });
  };

  showAuditsListView = () => {
    this.setState({
      title: "Godrej Audits",
      isAuditsListView: true,
      isAuditView: false,
      isProductsView: false,
      filteredAudits: [],
    });
  };

  showProducts = (audit) => {
    this.setState({
      title: audit.distributorName,
      subTitle: audit.distributorCode,
      isAuditsListView: false,
      isAuditView: false,
      isProductsView: true,
    });
    this.props.fetchProducts(audit._id);
  };

  showQuestionsView = (audit) => {
    let user = this.props.godrej.users.filter((u) =>
      audit.auditors !== undefined ? u._id == audit.auditors[0] : null
    )[0];
    audit.auditor = user;
    this.setState({
      isQuestionsView: true,
      selectedAudit: audit,
    });
  };

  onQuestionViewCloseClick = () => {
    this.setState({ isQuestionsView: true })
    // this.showAudits(this.state.filteredAudits);
  };

  onSaveAuditClick = (audit) => {
    this.props.updateAudit(audit, (isUpdated) => {
      if (isUpdated)
        this.setState({
          selectedAudit: audit,
        });
    });
  };

  downloadReport = (auditId) => {
    this.props.downloadReport(auditId);
  };

  downloadPDFReport = (auditId) => {
    this.props.downloadPDFReport(auditId);
  };

  onTemplateYearChange = async (data) => {
    this.setState({ year: data, loading: true });

    this.props.fetchAudits({
      year: data,
      onSuccess: () => {
        this.setState({ loading: false });
      },
      onFailure: () => {
        this.setState({ loading: false });
      },
    });
  };

  render() {
    let audits =
      this.state.godrejAudits.length !== 0 ? this.state.godrejAudits : [];
    let columns = this.getAuditsListColumns();

    var years = [
      {
        value: 2018,
        text: "2018",
        key: "2018",
      },
      {
        value: 2019,
        text: "2019",
        key: "2019",
      },
      {
        value: 2020,
        text: "2020",
        key: "2020",
      },
      {
        value: 2021,
        text: "2021",
        key: "2021",
      },
      {
        value: 2022,
        text: "2022",
        key: "2022",
      },
      {
        value: 2023,
        text: "2023",
        key: "2023",
      },
    ];
    return (
      <div style={{ flexGrow: 1, display: "flex", flexFlow: "column" }}>
        {this.state.isAuditsListView && (
          <div>
            <div
              style={{
                display: "inline-block",
                marginBottom: 20,
              }}
            >
              <h1
                style={{
                  display: "inline-block",
                  color: getTextColor(this.state.color),
                }}
              >
                Templates
              </h1>
              <DropDownPicker styles={{
                width: "20%",
                zIndex: "100",
                width: 300
              }}
                placeholder={"Select Year"}
                value={this.state.year}
                options={years}
                onChangeDropDown={(data) => { this.onTemplateYearChange(data) }}

              />

            </div>
            <Header
              as="h2"
              style={{ margin: 5, color: getTextColor(this.state.color) }}
            >
              <Header.Content>{this.state.title}</Header.Content>
            </Header>
            <TableView
              selection={false}
              data={audits}
              columns={columns}
              load={
                audits.length === 0 && this.state.loading === true
                  ? true
                  : false
              }
            />
          </div>
        )}
        {this.state.isAuditView && (
          <div>
            <Header
              onClick={(_) => this.showAuditsListView()}
              as="h2"
              style={{ margin: 5 }}
            >
              <IconPicker name="arrow left" />
              <Header.Content>{this.state.title}</Header.Content>
            </Header>
            <TableView
              data={this.state.filteredAudits}
              selection={false}
              columns={this.getAuditsColumns()}
            />
          </div>
        )}
        {this.state.isProductsView && (
          <div>
            <Header
              onClick={(_) => this.showAudits(this.state.filteredAudits)}
              as="h2"
              style={{ margin: 5 }}
            >
              <IconPicker name="arrow left" />
              <Header.Content>
                {this.state.title}
                <Header.Subheader>{this.state.subTitle}</Header.Subheader>
              </Header.Content>
            </Header>
            <TableView
              data={this.state.products}
              columns={this.getProductsColumns()}
            />
          </div>
        )}
        {this.state.isQuestionsView && this.state.selectedAudit && (
          <ModalView
            open={this.state.isQuestionsView}
            header={this.state.title}
            content={
              <QuestionsView
                selectedAudit={this.state.selectedAudit}
                onSaveAuditClick={(e) => this.onSaveAuditClick(e)}
                onDownloadAuditReportClick={(e) => {
                  console.log("onDownloadAuditReportClick");
                }}
                color={this.state.color}
              />
            }
            showSave={false}
            close={() => { this.setState({ isQuestionsView: false }) }}
          />

        )}
      </div>
    );
  }

  getProductsColumns() {
    return [
      {
        title: "SKU Code",
        field: "",
        render: (rowData) => {
          return <span>{rowData.SKUCode}</span>;
        },
      },
      {
        title: "SKU Description",
        field: "",
        render: (rowData) => {
          return <span>{rowData.SKUDescription}</span>;
        },
      },
      {
        title: "Claim Type",
        field: "",
        render: (rowData) => {
          return <span>{rowData.claimType}</span>;
        },
      },
      {
        title: "MRP",
        field: "",
        render: (rowData) => {
          return <span>{rowData.MRP}</span>;
        },
      },
      {
        title: "Total Quantity",
        field: "",
        render: (rowData) => {
          return <span>{rowData.totalQuantity}</span>;
        },
      },
      {
        title: "Fire Damage",
        field: "",
        render: (rowData) => {
          return <span>{rowData.fireDamage}</span>;
        },
      },
      {
        title: "Wet Damage",
        field: "",
        render: (rowData) => {
          return <span>{rowData.wetDamage}</span>;
        },
      },
      {
        title: "Transit Damage",
        field: "",
        render: (rowData) => {
          return <span>{rowData.transitDamage}</span>;
        },
      },
      {
        title: "Others",
        field: "",
        render: (rowData) => {
          return <span>{rowData.others}</span>;
        },
      },
      {
        title: "Expiry",
        field: "",
        render: (rowData) => {
          return <span>{rowData.expiry}</span>;
        },
      },
      {
        title: "Saleable Stock",
        field: "",
        render: (rowData) => {
          return <span>{rowData.saleableStock}</span>;
        },
      },
      {
        title: "Shortage",
        field: "",
        render: (rowData) => {
          return <span>{rowData.shortage}</span>;
        },
      },
      {
        title: "Total Claim Quantity",
        field: "",
        render: (rowData) => {
          let product = rowData;
          let quantity =
            product.fireDamage +
            product.wetDamage +
            product.transitDamage +
            product.expiry +
            product.others;
          return <span>{isNaN(quantity) ? "" : quantity}</span>;
        },
      },
    ];
  }

  getAuditsColumns() {
    return [
      {
        title: "Distributor Name",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.showQuestionsView(rowData);
              }}
            >
              {rowData.distributorName}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.distributorName.toLowerCase().includes(term.toLowerCase()),
      },
      {
        title: "Distributor Code",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.showQuestionsView(rowData);
              }}
            >
              {rowData.distributorCode}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.distributorCode.toLowerCase().includes(term.toLowerCase()),
      },
      {
        title: "Location",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.showQuestionsView(rowData);
              }}
            >
              {rowData.location}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.location.toLowerCase().includes(term.toLowerCase()),
      },
      {
        title: "Status",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.showQuestionsView(rowData);
              }}
            >
              {rowData.status}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.status.toLowerCase().includes(term.toLowerCase()),
      },
      {
        title: "VIew Products",
        field: "",
        render: (rowData) => {
          return (
            <IconPicker
              size={"large"}
              color={"red"}
              name={"product hunt"}
              click={() => this.showProducts(rowData)}
            />
          );
        },
      },
      {
        title: "Report",
        field: "",
        render: (rowData) => {
          return (
            <IconPicker
              size={"large"}
              styles={{ color: getTextColor(this.state.color), cursor: 'pointer' }}
              name={"file excel outline"}
              click={() => this.downloadReport(rowData._id)}
            />
          );
        },
      },
    ];
  }

  getAuditsListColumns() {
    return [
      {
        title: "Audit Month",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.showAudits(rowData);
              }}
            >
              {rowData._id}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData._id.toLowerCase().includes(term.toLowerCase()),
      },
      {
        title: "Live Audits",
        field: "",
        render: (rowData) => {
          let count = rowData.audits.filter(function (audit) {
            return audit.status !== "submitted";
          }).length;
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.showAudits(rowData);
              }}
            >
              {count}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.audits.filter(function (audit) {
            return audit.status !== "submitted";
          }).length == term,
      },
      {
        title: "Submitted Audits",
        field: "",
        render: (rowData) => {
          let count = rowData.audits.filter(function (audit) {
            return audit.status === "submitted";
          }).length;
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.showAudits(rowData);
              }}
            >
              {count}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.audits.filter(function (audit) {
            return audit.status === "submitted";
          }).length == term,
      },
      {
        title: "Total Audits",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.showAudits(rowData);
              }}
            >
              {rowData.audits.length}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) => rowData.audits.length == term,
      },
    ];
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    godrej: state.godrej,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchAudits,
      fetchProducts,
      updateAudit,
      downloadReport,
      downloadPDFReport,
    },
    dispatch
  );
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Godrej));
