import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Segment, Dropdown, Grid } from "semantic-ui-react";
import ReactSpeedometer from "react-d3-speedometer";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import Graph from "./Graph.jsx";
import "./widgets.css";
export class WidgetIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      overallScore: "",
      widgetRegion: [],
      filterRegion: [],
      FilterState: [],
      topFive: [],
      bottomFive: [],
      FilterLocation: [],
      widgetState: [],
      widgetLocation: [],
      regionList: [
        {
          key: "All",
          text: "All",
          value: "All",
        },
      ],
      stateList: [
        {
          key: "All",
          text: "All",
          value: "All",
        },
      ],
      locationList: [
        {
          key: "All",
          text: "All",
          value: "All",
        },
      ],
      regionChoosed: [],
      stateChoosed: [],
      locationChoosed: [],
    };
  }
  componentDidMount() {
    let regionList = this.state.regionList;
    let stateList = this.state.stateList;
    let locationList = this.state.locationList;
    if (
      this.props.cannon.overallScore !== "" &&
      this.props.cannon.widgetsLocation &&
      this.props.cannon.widgetsRegion &&
      this.props.cannon.widgetsState &&
      this.props.cannon.widgetsTopScore &&
      this.props.cannon.widgetsBottomScore
    ) {
      if (this.props.cannon.widgetsRegion) {
        this.props.cannon.widgetsRegion.forEach((data) => {
          regionList.push({
            key: data.name,
            text: data.name,
            value: data.name,
          });
        });
        this.setState({ regionList });
      }
      if (this.props.cannon.widgetsState) {
        this.props.cannon.widgetsState.forEach((data) => {
          stateList.push({
            key: data.name,
            text: data.name,
            value: data.name,
          });
        });
        this.setState({ stateList });
      }
      if (this.props.cannon.widgetsLocation) {
        this.props.cannon.widgetsLocation.forEach((data) => {
          locationList.push({
            key: data.name,
            text: data.name,
            value: data.name,
          });
        });
        this.setState({ locationList });
      }

      this.setState({
        overallScore: this.props.cannon.overallScore,
        widgetRegion: this.props.cannon.widgetsRegion,
        widgetState: this.props.cannon.widgetsState,
        widgetLocation: this.props.cannon.widgetsLocation,
        topFive: this.props.cannon.widgetsTopScore,
        bottomFive: this.props.cannon.widgetsBottomScore,
      });
    } else {
      NotificationManager.error("Failed to load data", "Error");
    }
  }

  filterRegion = (event, data) => {

    let widgetRegion = this.state.widgetRegion;
    let filterRegion = this.state.filterRegion;
    let regionChoosed = this.state.regionChoosed;
    if (data.value.includes("All")) {
      if (event.target.textContent === "All") {
        regionChoosed = [];
        filterRegion = [];
      }
    } else {
      filterRegion = widgetRegion.filter((el) => {
        return data.value.includes(el.name);
      });
      regionChoosed = data.value;
    }

    this.setState({ filterRegion, regionChoosed });
  };
  filterState = (event, data) => {
    let widgetState = this.state.widgetState;
    let filteredState = this.state.FilterState;
    let states = this.state.stateChoosed;
    if (data.value.includes("All")) {
      filteredState = [];
      states = [];
    } else {
      filteredState = widgetState.filter((el) => {
        return data.value.includes(el.name);
      });
      states = data.value;
    }
    this.setState({
      filteredState: filteredState,
      states: states,
    });
  };
  filterLocation = (event, data) => {
    let widgetLocation = this.state.widgetLocation;
    let filteredLocation = this.state.FilterLocation;
    let location = this.state.locationChoosed;
    if (data.value.includes("All")) {
      filteredLocation = [];
      location = [];
    } else {
      filteredLocation = widgetLocation.filter((el) => {
        return data.value.includes(el.name);
      });
      location = data.value;
    }
    this.setState({
      FilterLocation: filteredLocation,
      locationChoosed: location,
    });
  };
  showEmptyData = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",

        }}
      >
        <h4>NIL</h4>
      </div>
    )
  }
  render() {
    return (
      <div>
        <div style={{ flexGrow: 1, display: "flex", flexFlow: "column" }}>
          <div>
            <Segment.Group horizontal raised>
              <Segment style={{ backgroundColor: "#f5f5f5" }}>
                <div
                  style={{
                    height: "200px",
                    width: "400px",
                    marginBottom: "20px",
                  }}
                >
                  <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                    Overall Score-Retail
                  </p>
                  <div
                    align="center"
                    style={{ marginTop: "10px", marginBottom: "20px" }}
                  >

                    <ReactSpeedometer
                      maxValue={100}
                      value={this.state.overallScore}
                      needleColor="red"
                      startColor="green"
                      segments={10}
                      endColor="blue"
                    />
                  </div>
                </div>
              </Segment>
              <Segment style={{ backgroundColor: "#f5f5f5" }}>
                <Grid>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                        Region wise score
                      </p>
                    </Grid.Column>
                    <Grid.Column>
                      <div style={{ float: "right", marginRight: "60px" }}>
                        <Dropdown
                          multiple
                          icon="filter"
                          options={this.state.regionList}
                          onChange={this.filterRegion}
                          value={this.state.regionChoosed}
                        />
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    {this.state.widgetRegion.length === 0 ? (
                      this.showEmptyData()
                    ) : (
                      <div
                        style={{
                          height: "200px",
                          width: "400px",
                          marginBottom: "20px",
                        }}
                      >
                        <Graph
                          data={
                            this.state.filterRegion.length === 0
                              ? this.state.widgetRegion
                              : this.state.filterRegion
                          }
                          gap={600}
                        />
                      </div>
                    )}
                  </Grid.Row>
                </Grid>
              </Segment>
            </Segment.Group>
          </div>
          <br />
          <div>
            <Segment.Group horizontal raised>
              <Segment style={{ backgroundColor: "#f5f5f5" }}>

                <div
                  style={{
                    height: "200px",
                    width: "450px",
                    marginBottom: "20px",
                  }}
                >
                  <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                    Top Five Stores
                  </p>
                  {this.state.topFive.length === 0 ?
                    this.showEmptyData() :
                    <Graph data={this.state.topFive} gap={550} />}
                </div>
              </Segment>
              <Segment style={{ backgroundColor: "#f5f5f5" }}>
                <div
                  style={{
                    height: "200px",
                    width: "450px",
                    marginBottom: "20px",
                  }}
                >
                  <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                    Bottom Five Stores
                  </p>
                  {this.state.bottomFive.length === 0 ?
                    this.showEmptyData() :
                    <Graph data={this.state.bottomFive} gap={550} />}
                </div>
              </Segment>
            </Segment.Group>
          </div>

          <br />
          <div>
            <Segment raised style={{ backgroundColor: "#f5f5f5" }}>
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                      State wise score
                    </p>
                  </Grid.Column>
                  <Grid.Column>
                    <div style={{ float: "right", marginRight: "60px" }}>
                      <Dropdown
                        multiple
                        icon="filter"
                        scrolling
                        options={this.state.stateList}
                        onChange={this.filterState}
                        value={this.state.stateChoosed}
                      />
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  {this.state.widgetState.length === 0 ?
                    this.showEmptyData() :
                    <div
                      className="con"
                    // style={{
                    //   height: "280px",
                    //   width: "1200px",
                    //   overflowX: "scroll",
                    //   overflowY: "hidden",
                    //   padding: "1%"
                    // }}
                    >
                      <Graph
                        data={
                          this.state.FilterState.length === 0
                            ? this.state.widgetState
                            : this.state.FilterState
                        }
                        gap={this.state.FilterState.length === 0 ? 2050 : "99%"}
                      />
                    </div>
                  }
                </Grid.Row>
              </Grid>
            </Segment>
          </div>
          <br />
          <div>
            <Segment raised style={{ backgroundColor: "#f5f5f5" }}>
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                      Location Wise Score
                    </p>
                  </Grid.Column>
                  <Grid.Column>
                    <div style={{ float: "right", marginRight: "60px" }}>
                      <Dropdown
                        multiple
                        icon="filter"
                        scrolling
                        options={this.state.locationList}
                        onChange={this.filterLocation}
                        value={this.state.locationChoosed}
                      />
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  {this.state.widgetLocation.length === 0 ?
                    this.showEmptyData() :
                    <div
                      className="con"
                    // style={{
                    //   height: "280px",
                    //   width: "1200px",
                    //   overflowX: "scroll",
                    //   overflowY: "hidden",
                    //   padding: "1%"
                    // }}
                    >
                      <Graph
                        data={
                          this.state.FilterLocation.length === 0
                            ? this.state.widgetLocation
                            : this.state.FilterLocation
                        }
                        gap={
                          this.state.FilterLocation.length === 0 ? 4050 : "99%"
                        }
                      />
                    </div>
                  }
                </Grid.Row>
              </Grid>
            </Segment>
          </div>
          <NotificationContainer enterTimeout="200" leaveTimeout="200" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //reducer props to state
    cannon: state.cannon,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WidgetIndex)
);
