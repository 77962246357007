import {
  FETCH_MR_TEMPLATE_SUCCESS_ACTION,
  FETCH_MR_TEMPLATE_FAILURE_ACTION,
  FETCH_MR_STORE_SUCCESS_ACTION,
  FETCH_MR_STORE_FAILURE_ACTION,
  FETCH_MR_AUDITS_SUCCESS_ACTION,
  FETCH_MR_AUDITS_FAILURE_ACTION,
  FETCH_MR_PHOTO_SUCCESS_ACTION,
  FETCH_MR_PHOTO_FAILURE_ACTION,
  DOWNLOAD_MRREPORT_SUCCESS_ACTION,
  DOWNLOAD_MRREPORT_FAILURE_ACTION,
  DOWNLOAD_MRREPORT_PHOTOS_SUCCESS_ACTION,
  DOWNLOAD_MRREPORT_PHOTOS_FAILURE_ACTION,
  CLEAR_AUDIT_ACTION,
  MR_DOWNLOAD_PHOTOS_SUCCESS,
  MR_DOWNLOAD_PHOTOS_FAILURE
} from "../actions/types";

const INIT_STATE = {};

export default function (state = INIT_STATE, action) {
  state = Object.assign({}, state, {});

  switch (action.type) {
    case FETCH_MR_TEMPLATE_SUCCESS_ACTION:
      state.templates = action.payload.templates;
      state.fetchMrTemplateError = false;
      return state;

    case FETCH_MR_TEMPLATE_FAILURE_ACTION:
      state.fetchMrTemplateError = true;
      return state;
    case FETCH_MR_STORE_SUCCESS_ACTION:
      state.store = action.payload.storelist;
      state.metrics = action.payload.counts;
      state.fetchMrStoreError = false;
      return state;
    case FETCH_MR_STORE_FAILURE_ACTION:
      state.fetchMrStoreError = true;
      return state;
    case FETCH_MR_AUDITS_SUCCESS_ACTION:
      state.audits = action.payload.audits;
      state.fetchMrAuditsError = false;
      return state;
    case FETCH_MR_AUDITS_FAILURE_ACTION:
      state.fetchMrAuditsError = true;
      return state;
    case FETCH_MR_PHOTO_SUCCESS_ACTION:
      state.storePhoto = action.payload.photos;
      state.fetchMrPhotoError = false
      return state;
    case FETCH_MR_PHOTO_FAILURE_ACTION:
      state.fetchMrPhotoError = true;
      return state;
    case DOWNLOAD_MRREPORT_SUCCESS_ACTION:
      state.reportDownloadSuccess = true
      return state;
    case DOWNLOAD_MRREPORT_FAILURE_ACTION:
      state.reportDownloadSuccess = false
      return state
    case DOWNLOAD_MRREPORT_PHOTOS_SUCCESS_ACTION:
      state.reportPhotosDownloadSucess = true;
      return state;
    case DOWNLOAD_MRREPORT_PHOTOS_FAILURE_ACTION:
      state.reportPhotosDownloadSucess = false;
      return state;
    case CLEAR_AUDIT_ACTION:
      state.store = [];
      state.audits = [];
      state.storePhoto = [];
      return state;
    case MR_DOWNLOAD_PHOTOS_SUCCESS:
      state.mrRetailDownloadPhotosError = false
      return state;
    case MR_DOWNLOAD_PHOTOS_FAILURE:
      state.mrRetailDownloadPhotosError = true;
      return state;
  }
  return state;
}
