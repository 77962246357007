import React from "react";
import { Header, List, Image, Modal } from "semantic-ui-react";
import moment from "moment";
import config from "../../config.js";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import { compose, withProps } from "recompose";
const storageBaseUrl = config["storage_base_url"];

class VolkswagenAuditViewDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPhoto: "",
      showPhotoSlider: false,
    };
  }

  handlePhotoClick = (e, p) => {
    this.setState({
      selectedPhoto: p,
      showPhotoSlider: true,
    });
  };

  photoSliderClose = (e) => {
    this.setState({
      showPhotoSlider: false,
    });
  };

  render() {
    var selectedAudit = this.props.selectedAudit;

    var vehicleStatusOptions = [
      {
        text: "Demo",
        value: 1,
      },
      {
        text: "Transit",
        value: 2,
      },
      {
        text: "Exhibition",
        value: 3,
      },
      {
        text: "Sold",
        value: 4,
      },
      {
        text: "Checked & Verified",
        value: 5,
      },
      {
        text: "Other reasons",
        value: 6,
      },
    ];
    const Map = compose(
      withProps({
        googleMapURL:
          "https://maps.googleapis.com/maps/api/js?key=" +
          config.google_map_api_key,
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
      }),
      withScriptjs,
      withGoogleMap
    )((props) => (
      <GoogleMap
        defaultZoom={10}
        defaultCenter={{
          lat: selectedAudit.latitude,
          lng: selectedAudit.longitude,
        }}
      >
        {props.isMarkerShown && (
          <Marker
            position={{
              lat: selectedAudit.latitude,
              lng: selectedAudit.longitude,
            }}
          />
        )}
      </GoogleMap>
    ));

    function getVehicleStatusText(value) {
      for (let i = 0; i < vehicleStatusOptions.length; i++) {
        var vehicleStatus = vehicleStatusOptions[i];
        if (vehicleStatus.value === value) return vehicleStatus.text;
      }

      return 0;
    }
    return (
      <Modal.Content
        style={{
          backgroundColor: this.props.backColor(this.props.color),
        }}
      >
        <Modal
          dimmer="blurring"
          size="fullscreen"
          closeOnEscape={true}
          closeOnDimmerClick={true}
          basic
          open={this.state.showPhotoSlider}
          onClose={this.photoSliderClose}
        >
          <Modal.Content>
            <Image
              fuild
              centered
              src={storageBaseUrl + this.state.selectedPhoto.uploadedImageUrl}
            />
          </Modal.Content>
        </Modal>
        <Modal.Description>
          <List>
            <List.Item>
              <div style={{ marginTop: 10 }}>
                <span
                  style={{
                    verticalAlign: "middle",
                    width: 200,
                    display: "inline-block",
                    fontSize: 15,
                    fontWeight: "bold",
                  }}
                >
                  {selectedAudit.questions[0].question} :
                </span>
                <span
                  style={{
                    verticalAlign: "middle",
                    fontSize: 15,
                    color: "#2ecc71",
                    marginLeft: 15,
                  }}
                >
                  {getVehicleStatusText(selectedAudit.questions[0].answer || 0)}
                </span>
              </div>
            </List.Item>
            <List.Item>
              <div style={{ marginTop: 10 }}>
                <span
                  style={{
                    verticalAlign: "middle",
                    width: 200,
                    display: "inline-block",
                    fontSize: 15,
                    fontWeight: "bold",
                  }}
                >
                  Storeroom :
                </span>
                <span
                  style={{
                    verticalAlign: "middle",
                    fontSize: 15,
                    marginLeft: 15,
                  }}
                >
                  {selectedAudit.storeRoom}
                </span>
              </div>
            </List.Item>
            {selectedAudit.questions[0].answer === 4 && (
              <div>
                <List.Item>
                  <div style={{ marginTop: 10 }}>
                    <span
                      style={{
                        verticalAlign: "middle",
                        width: 200,
                        display: "inline-block",
                        fontSize: 15,
                        fontWeight: "bold",
                      }}
                    >
                      Invoice No :
                    </span>
                    <span
                      style={{
                        verticalAlign: "middle",
                        fontSize: 15,
                        marginLeft: 15,
                      }}
                    >
                      {selectedAudit.soldInvoice
                        ? selectedAudit.soldInvoice.no
                        : ""}
                    </span>
                  </div>
                </List.Item>
                <List.Item>
                  <div style={{ marginTop: 10 }}>
                    <span
                      style={{
                        verticalAlign: "middle",
                        width: 200,
                        display: "inline-block",
                        fontSize: 15,
                        fontWeight: "bold",
                      }}
                    >
                      Invoice Date :
                    </span>
                    <span
                      style={{
                        verticalAlign: "middle",
                        fontSize: 15,
                        marginLeft: 15,
                      }}
                    >
                      {selectedAudit.soldInvoice
                        ? selectedAudit.soldInvoice.date &&
                          moment(selectedAudit.soldInvoice.date).format(
                            "DD/MM/YYYY"
                          )
                        : ""}
                    </span>
                  </div>
                </List.Item>
              </div>
            )}

            {selectedAudit.questions[0].answer === 3 && (
              <div>
                <List.Item>
                  <div style={{ marginTop: 10 }}>
                    <span
                      style={{
                        verticalAlign: "middle",
                        width: 200,
                        display: "inline-block",
                        fontSize: 15,
                        fontWeight: "bold",
                      }}
                    >
                      Gate Pass :
                    </span>
                    <span
                      style={{
                        verticalAlign: "middle",
                        fontSize: 15,
                        marginLeft: 15,
                      }}
                    >
                      {selectedAudit.gatePassNo ? selectedAudit.gatePassNo : ""}
                    </span>
                  </div>
                </List.Item>
                <List.Item>
                  <div style={{ marginTop: 10 }}>
                    <span
                      style={{
                        verticalAlign: "middle",
                        width: 200,
                        display: "inline-block",
                        fontSize: 15,
                        fontWeight: "bold",
                      }}
                    >
                      Gate Pass Date :
                    </span>
                    <span
                      style={{
                        verticalAlign: "middle",
                        fontSize: 15,
                        marginLeft: 15,
                      }}
                    >
                      {selectedAudit.gatePassDate
                        ? moment(selectedAudit.gatePassDate).format(
                            "DD/MM/YYYY"
                          )
                        : ""}
                    </span>
                  </div>
                </List.Item>
              </div>
            )}

            <List.Item>
              <div style={{ marginTop: 10 }}>
                <span
                  style={{
                    verticalAlign: "middle",
                    fontSize: 15,
                    fontWeight: "bold",
                  }}
                >
                  Remarks :
                </span>
                <br />
                <p style={{ marginTop: 5 }}>{selectedAudit.notes}</p>
                <br />
                <Image.Group size="mini" style={{ cursor: "pointer" }}>
                  {selectedAudit.questions[0].photos.map((p, i) => {
                    return (
                      <Image
                        key={i}
                        src={storageBaseUrl + p.uploadedImageUrl}
                        onClick={((k) => {
                          return (e) => this.handlePhotoClick(e, k);
                        })(p)}
                      />
                    );
                  })}
                </Image.Group>
              </div>
            </List.Item>
          </List>
          {selectedAudit.latitude && selectedAudit.longitude ? (
            <Map isMarkerShown />
          ) : null}
          {false && (
            <div>
              <Header> Dealer Assessment </Header>
              <List>
                <List.Item>
                  <div style={{ marginTop: 10 }}>
                    <span
                      style={{
                        verticalAlign: "middle",
                        width: 200,
                        display: "inline-block",
                        fontSize: 15,
                        fontWeight: "bold",
                      }}
                    >
                      Invoice Shown :
                    </span>
                    <span
                      style={{
                        verticalAlign: "middle",
                        fontSize: 15,
                        marginTop: 5,
                        marginLeft: 15,
                      }}
                    >
                      NEW CHANGE YES OR NO
                      {/* {dealerAssessment.isInvoiceShown ? 'Yes' : 'No'} */}
                    </span>
                  </div>
                </List.Item>
                <List.Item>
                  <div style={{ marginTop: 10 }}>
                    <span
                      style={{
                        verticalAlign: "middle",
                        width: 200,
                        display: "inline-block",
                        fontSize: 15,
                        fontWeight: "bold",
                      }}
                    >
                      Cooperation Scale :
                    </span>
                    <span
                      style={{
                        verticalAlign: "middle",
                        fontSize: 15,
                        marginLeft: 15,
                      }}
                    >
                      dealerAssessment[cooperationScale]
                      {/* {dealerAssessment.cooperationScale} */}
                    </span>
                  </div>
                </List.Item>
              </List>
            </div>
          )}
        </Modal.Description>
      </Modal.Content>
    );
  }
}

export default VolkswagenAuditViewDetails;
