import React from 'react'
import { Icon } from 'semantic-ui-react'

const IconPicker = (props) => {
    return (
        <Icon
            name={props.name}
            size={props.size !== undefined ? props.size : 'large'}
            style={props.styles !== undefined ? props.styles : null}
            onClick={props.click !== undefined ? props.click : null}
        />
    )
}
export default IconPicker