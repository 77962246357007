import axios from "axios";
import * as FileSaver from "file-saver";
import * as types from "./types";

export const fetchAudits = ({ year, onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/godrej/audits/group?year=" + year)
      .then((response) => {
        dispatch({
          type: types.GODREJ_AUDITS_FETCH_SUCCESS,
          payload: response.data,
        });
        onSuccess();
      })
      .catch((err) => {
        dispatch({
          type: types.GODREJ_AUDITS_FETCH_FAILURE,
          payload: err,
        });
        onFailure();
      });
  };
};

export const fetchProducts = (auditId) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/godrej/products/" + auditId)
      .then((response) => {
        dispatch({
          type: types.GODREJ_PRODUCTS_FETCH_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: types.GODREJ_PRODUCTS_FETCH_FAILURE,
          payload: err,
        });
      });
  };
};

export const updateAudit = (audit, callback) => {
  return function (dispatch) {
    axios
      .put(
        `${types.API_URL}/auditor/v1/godrej/audits/${audit._id}/save?status=${audit.status}`,
        audit.questions
      )
      .then((response) => {
        callback(true);
        dispatch({
          type: types.GODREJ_AUDIT_UPDATE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        callback(false);
        dispatch({
          type: types.GODREJ_AUDIT_UPDATE_FAILURE,
          payload: err,
        });
      });
  };
};

export const downloadReport = (auditId) => {
  return function (dispatch) {
    axios
      .get(`${types.API_URL}/auditor/v1/godrej/report/${auditId}`, {
        responseType: "blob",
      })
      .then((response) => {
        FileSaver.saveAs(
          response.data,
          response.headers["content-disposition"].split("=")[1]
        );
        dispatch({
          type: types.GODREJ_REPORT_DOWNLOAD_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GODREJ_REPORT_DOWNLOAD_FAILURE,
          payload: error,
        });
      });
  };
};

export const downloadPDFReport = (auditId) => {
  return function (dispatch) {
    axios
      .get(`${types.API_URL}/auditor/v1/generatePdfGodrejReport/${auditId}`, {
        responseType: "blob",
      })
      .then((response) => {
        FileSaver.saveAs(response.data, "getAuditReports.pdf");
        dispatch({
          type: types.GODREJ_REPORT_DOWNLOAD_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GODREJ_REPORT_DOWNLOAD_FAILURE,
          payload: error,
        });
      });
  };
};
