import React from 'react'
import { Statistic } from 'semantic-ui-react';
import { getTextColor } from '../../ThemeHandlers/theme';

const Metrics = (props) => {
    return (
        <div>
            {props.metrics && (
                <Statistic.Group
                    size={props.size !== undefined ? props.size : 'large'}
                    widths={Object.keys(props.metrics).length}
                >
                    {Object.keys(props.metrics).map((key) => {
                        return (
                            <Statistic>
                                <Statistic.Value
                                    style={{ color: getTextColor(props.color) }}
                                >
                                    {props.metrics[key]}
                                </Statistic.Value>
                                <Statistic.Label>{key}</Statistic.Label>
                            </Statistic>
                        );
                    })}
                </Statistic.Group>
            )}
        </div>
    )
}
export default Metrics