import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { isLoggedIn, isClient } from '../../util';
import { fetchYamahaTemplateNameAction } from '../../actions/yamaha_actions';
import { fetchYamahaDealerCountAction } from '../../actions/yamaha_actions';
import Table from "../commonTables/Table.jsx"
import YamahaDealers from './YamahaDealers.jsx';

class Yamaha extends React.Component {
    static propTypes = {
        history: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            selectedTemplate: '',
            isTemplatesView: true
        };
    }

    componentDidMount() {
        if (!isLoggedIn(this.props.auth)) {
            this.props.history.push(`/login`);
            return;
        }
        this.props.fetchYamahaTemplateNameAction();
    }


    isClientProfile = () => {
        return isClient(this.props.auth);
    };


    handleCloseClick = () => {
        this.setState({ isTemplatesView: true });
    };

    handleTableViewAuditClick = template => {
        this.setState({ selectedTemplate: template, isTemplatesView: false });
    };

    render() {
        var templates = this.props.yamaha.templates;
        var columns = [
            {
                Header: 'Template Name',
                accessor: 'templatename',
                style: { cursor: 'pointer' },
                Cell: row => (
                    <AuditTableCell
                        row={row.original}
                        text={row.original.auditName}
                        onClick={this.handleTableViewAuditClick}
                    />
                )
            },
            {
                Header: 'DealerCount',
                accessor: 'dealercount',
                style: { textAlign: 'center', cursor: 'pointer' },
                Cell: row => (
                    <AuditTableCell
                        row={row.original}
                        text={row.original.dealerCount}
                        onClick={this.handleTableViewAuditClick}
                    />
                )
            },
        ];


        return (
            <div style={{ flexGrow: 1, display: 'flex', flexFlow: 'column' }}>
                {this.state.isTemplatesView && (
                    <div>
                        <h1 style={{ paddingLeft: 30, flex: '0 0 30px' }}>Templates</h1>
                        <div style={{ display: 'flex', flexGrow: 1, flexFlow: 'column' }}>
                            <div>
                                <Table
                                    defaultFilterMethod={this.searchFilter}
                                    templates={templates}
                                    columns={columns}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {!this.state.isTemplatesView && (
                    <div style={{ flexGrow: 1, display: 'flex' }}>
                        <YamahaDealers template={this.state.selectedTemplate} onClose={this.handleCloseClick} />
                    </div>
                )}

            </div>
        );
    }
}

function AuditTableCell(props) {
    function onClick() {
        props.onClick(props.row);
    }
    return (
        <div style={props.style} onClick={onClick}>
            {props.text}
        </div>
    );
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        yamaha: state.yamaha
    };
};
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            fetchYamahaTemplateNameAction,
            fetchYamahaDealerCountAction,

        },
        dispatch
    );
};


// Yamaha.PropTypes = {
//     dealers: PropTypes.array
// };

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Yamaha)
);
