import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Card, Modal, Button, List, Radio } from "semantic-ui-react";
import {
  pandgAuditExportAction,
  pandgAuditSubmitAction,
} from "../../actions/pandg_actions.js";
import PandGHeader from "./PandGHeader.jsx";
import {
  getTextColor,
  getMainBackground,
  getFontColor,
  getMatchingTint,
} from "../../ThemeHandlers/theme";
import ConstantLabel from "../commonViews/ConstantLabel";
class PandGHospitalAuditsQuestionare extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentMother: null,
      showPopup: false,
      editMode: false,
      selectedAudit: this.props.selectedAudit,
    };
    this.auditForEdit = {};
  }

  selectMother = (mother) => {
    console.log(mother, "mother");
    this.setState({
      currentMother: mother,
      showPopup: true,
      editMode: false,
      houseDetails: false,
    });
  };

  viewMotherDetails = (tc) => {
    this.setState({
      currentTc: tc,
      showPopup: true,
      editMode: false,
      houseDetails: true,
    });
  };

  editAudit = () => this.setState({ editMode: true });

  saveAudit = (audit) => {
    this.setState({ showPopup: false, editMode: false });
    // replace the edited audit values with the original audit data
    //        this.props.selectedAudit = audit;
    this.props.pandgAuditSubmit(audit);
  };

  closePopup = () => {
    // restore the actual audit data.
    this.auditForEdit = this.props.selectedAudit;
    this.setState({ showPopup: false, editMode: false, houseDetails: false });
  };

  onDownloadReport = () => {
    this.props.pandgAuditExport(this.state.selectedAudit._id);
  };

  onDownloadPhotos = () => {
    window.open(`#/images/` + this.props.selectedAudit._id);
  };

  onMailReport = () => { };

  onQuestionAnswerChange = (question) => {
    question.answer = question.answer === "Yes" ? "No" : "Yes";
    // just for re-rendering the UI
    this.setState({ currentQuestion: question });
  };

  getHouseQuestions = (questions) => {
    return questions.map((eachQuestion, index) => {
      return (
        <List.Item key={index}>
          <div style={{ marginTop: 10 }}>
            <span
              style={{
                verticalAlign: "middle",
                fontWeight: "bold",
                width: "70%",
                display: "inline-block",
                fontSize: 15,
              }}
            >
              {eachQuestion.question}
            </span>
            <span
              style={{ fontSize: 15, marginLeft: 15, verticalAlign: "middle" }}
            >
              <Radio
                toggle
                disabled={this.state.editMode != true}
                checked={eachQuestion.answer == "Yes" ? true : false}
                onChange={(evt) => this.onQuestionAnswerChange(eachQuestion)}
              />{" "}
              {eachQuestion.answer === "Yes" ? eachQuestion.answer : "No"}
            </span>
          </div>
        </List.Item>
      );
    });
  };

  render() {
    var selectedAudit = this.state.selectedAudit,
      audit = this.auditForEdit;
    // create a copy of audit to use it for editing instead of modifying the original data.
    if (selectedAudit._id != this.auditForEdit._id) {
      audit = this.auditForEdit = JSON.parse(
        JSON.stringify(this.props.selectedAudit)
      );
    }

    return (
      <div>
        <PandGHeader
          audit={this.auditForEdit}
          onClick={this.onDownloadReport}
          onPhotos={this.onDownloadPhotos}
          color={this.props.color}
        />

        <div style={{ marginTop: 30 }}>
          <Card.Group columns={"three"}>
            {!audit
              ? null
              : audit.hospitalAudits.map((mother, i) => {
                return (
                  <div key={i}>
                    <Card style={{ margin: 5 }}>
                      <Card.Content
                        style={{
                          backgroundColor: getMainBackground(
                            this.props.color
                          ),

                          padding: 6,
                        }}
                      >
                        <div
                          style={{
                            color: getFontColor(this.props.color),
                            fontWeight: "bold",
                          }}
                        >
                          {mother.motherName}
                        </div>
                      </Card.Content>
                      <Card.Content
                        description=""
                        style={{ wordBreak: "break-word" }}
                      />
                      <Card.Content extra>
                        <div style={{ float: "right" }}>
                          <ConstantLabel
                            title={"Details"}
                            size={"medium"}
                            as={"a"}
                            onClick={() => this.selectMother(mother)}
                            styles={{
                              backgroundColor: getMatchingTint(
                                this.props.color
                              ),
                              color: getTextColor(this.props.color),
                            }}
                            iconName={"info circle"}
                          />

                        </div>
                      </Card.Content>
                    </Card>
                  </div>
                );
              })}
          </Card.Group>
          {this.state.showPopup === true ? (
            <Modal
              open={this.state.showPopup}
              onClose={this.state.closePopup}
              id="pandgQuestionarie"
              style={{ marginTop: "3.5rem auto !important" }}
            >
              <Modal.Header
                style={{
                  backgroundColor: getMainBackground(this.props.color),
                  color: getFontColor(this.props.color),
                }}
              >
                <ConstantLabel
                  title={` Patient: ${this.state.currentMother.motherName}`}
                  size={"medium"}
                  styles={{

                    backgroundColor: getMatchingTint(this.props.color),
                    color: getTextColor(this.props.color),
                  }}
                />

              </Modal.Header>
              <Modal.Content
                style={{ backgroundColor: getMatchingTint(this.props.color) }}
              >
                <Modal.Description>
                  <List>
                    {this.state.currentMother.questions &&
                      this.getHouseQuestions(
                        this.state.currentMother.questions
                      )}
                  </List>
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                {!this.state.editMode && (
                  <Button
                    style={{
                      backgroundColor: getMainBackground(this.props.color),
                      color: getFontColor(this.props.color),
                    }}
                    onClick={this.editAudit}
                  >
                    Edit
                  </Button>
                )}
                {this.state.editMode && (
                  <Button
                    style={{
                      backgroundColor: getMainBackground(this.props.color),
                      color: getFontColor(this.props.color),
                    }}
                    onClick={() => {
                      this.saveAudit(audit);
                    }}
                  >
                    Save
                  </Button>
                )}
                <Button onClick={this.closePopup} color="black">
                  Cancel
                </Button>
              </Modal.Actions>
            </Modal>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pandg: state.pandg,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      pandgAuditSubmit: pandgAuditSubmitAction,
      pandgAuditExport: pandgAuditExportAction,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PandGHospitalAuditsQuestionare)
);
