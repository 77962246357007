import {
  FETCH_FURLENCO_TEMPLATE_SUCCESS_ACTION,
  FETCH_FURLENCO_TEMPLATE_FAILURE_ACTION,
  FETCH_FURLENCO_CUSTOMER_SUCCESS_ACTION,
  FETCH_FURLENCO_CUSTOMER_FAILURE_ACTION,
  FETCH_FURLENCO_AUDITS_SUCCESS_ACTION,
  FETCH_FURLENCO_AUDITS_FAILURE_ACTION,
  SAVE_FURLENCO_AUDIT_SUCCESS_ACTION,
  SAVE_FURLENCO_AUDIT_FAILURE_ACTION,
  DOWNLOAD_FURLENCO_REPORT_SUCCESS_ACTION,
  DOWNLOAD_FURLENCO_REPORT_FAILURE_ACTION,
  FILTER_CUSTOMERS_SUCCESS_ACTION,
  FILTER_CUSTOMERS_FAILURE_ACTION,
  ASSIGN_USER_TO_CUSTOMER_FAILURE_ACTION,
  ASSIGN_USER_TO_CUSTOMER_SUCCESS_ACTION,
} from "../actions/types";

const INIT_STATE = {};

 function furlenco (state = INIT_STATE,action) {
  state = Object.assign({}, state, {});

  switch (action.type) {
    case FETCH_FURLENCO_TEMPLATE_SUCCESS_ACTION:
      state.templates = action.payload.templates;
      state.fetchFurlencoTemplateError = false;
      return state;
    case FETCH_FURLENCO_TEMPLATE_FAILURE_ACTION:
      state.fetchFurlencoTemplateError = true;
      return state;
    case FETCH_FURLENCO_CUSTOMER_SUCCESS_ACTION:
    case FILTER_CUSTOMERS_SUCCESS_ACTION:
      return Object.assign({}, state, {
        customers: action.payload.dealers,
        metrics: action.payload.metrics,
        isFetchFurlencoCustomerSuccess: true
      });

    case FETCH_FURLENCO_CUSTOMER_FAILURE_ACTION:
    case FILTER_CUSTOMERS_FAILURE_ACTION:
      return Object.assign({}, state, {
        isFetchFurlencoCustomerSuccess: false
      });
    case FETCH_FURLENCO_AUDITS_SUCCESS_ACTION:
      state.furlencoAudits = action.payload.audits;
      state.fetchFurlencoAuditsError = false;
      return state;

    case FETCH_FURLENCO_AUDITS_FAILURE_ACTION:
      state.fetchFurlencoAuditsError = true;
      return state;
    case SAVE_FURLENCO_AUDIT_SUCCESS_ACTION:
      return Object.assign({}, state, {
        saveFurlencoAuditSuccessful: true
      });

    case SAVE_FURLENCO_AUDIT_FAILURE_ACTION:
      return Object.assign({}, state, {
        saveFurlencoAuditSuccessful: false
      });
    case DOWNLOAD_FURLENCO_REPORT_SUCCESS_ACTION:
      var buff = new Buffer(action.payload, "base64");
      var text = buff.toString("utf-8");

      // fileDownload(text, "furlencoAuditReport.xlsx");

      return Object.assign({}, state, {
        downloadSuccess: true
      });

    case DOWNLOAD_FURLENCO_REPORT_FAILURE_ACTION:
      return Object.assign({}, state, {
        downloadSuccess: false
      });

    case ASSIGN_USER_TO_CUSTOMER_SUCCESS_ACTION:
      state.customers.map((cust) => {
        if(cust._id === action.payload.dealerId) {
          cust.userId = action.payload.userId;
        }
      })
      state.assignFailed = false;

      return state;

    case ASSIGN_USER_TO_CUSTOMER_FAILURE_ACTION:
      return Object.assign({}, state, {
        assignFailed: true
      });

    default:
      return state  
  }
}
export default furlenco
