import React from "react";
import { Form, Modal, TextArea } from "semantic-ui-react";
import ButtonHandler from "./commonViews/ButtonHandler";

class MailPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      mailIds: props.mailIds,
      ccIds: props.ccIds
    };
  }

  show = () => {
    this.setState({ open: true });
  };

  sendAndClose = () => {
    //email validation
    if (
      this.state.mailIds.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      )
    ) {
      this.props.sendMailAndClosePopup(this.state.mailIds, this.state.ccIds);
      this.setState({ open: false });
    } else {
      alert("Invalid Email Address");
      this.setState({ open: true });
    }
    // this.setState({ mailIds: this.state.mailIds, open: false });
    // this.props.sendMailAndClosePopup(this.state.mailIds);
  };

  close = () => {
    this.setState({ open: false });
    this.props.closePopup();
  };

  validateEmail = (e) => {
    this.setState({ mailIds: e.target.value });
  };

  validateCCEmail = (e) => {
    this.setState({ ccIds: e.target.value });
  }

  render() {
    return (
      <div>
        <Modal
          size="small"
          dimmer="blurring"
          open={this.state.open}
          onClose={this.close}
          style={{ color: "red" }}
        //   style={{ backgroundColor: "red" }}
        >
          <Modal.Header
            style={{
              backgroundColor: this.props.getCellColor(this.props.color),
            }}
          >
            Enter email ids
          </Modal.Header>
          <Modal.Content
            style={{
              backgroundColor: this.props.getCellColor(this.props.color),
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row', 'justifyContent': 'space-between' }}>
              <div>
                <Form>
                  <TextArea
                    placeholder="Enter multiple mail with semicolon"
                    onChange={this.validateEmail}
                    style={{ width: 300, height: 200 }}
                  />
                </Form>
              </div>
              <div>
                <Form>
                  <TextArea
                    placeholder="Enter CC emails with semicolon"
                    onChange={this.validateCCEmail}
                    style={{ width: 300, height: 200 }}
                  />
                </Form>
              </div>

            </div>

          </Modal.Content>
          <Modal.Actions
            style={{
              backgroundColor: this.props.getCellColor(this.props.color),
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", flex: 1, justifyContent: 'flex-end' }}>
              <ButtonHandler title={"Nope"} color={"grey"} click={this.close} />
              <ButtonHandler
                title={"Send"}
                click={this.sendAndClose}
                styles={{
                  backgroundColor: this.props.getColor(this.props.color),
                  color: "#FFF",
                }}
                isIcon={true}
                iconName={"checkmark"}
              />
            </div>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default MailPopup;
