import axios from 'axios';
import * as types from './types';
import * as FileSaver from 'file-saver';


export const fetchPandGAgentsAction = (templateId,distributorId,managerId) => {
    return function (dispatch) {
        axios.get(types.API_URL + `/auditor/v1/pandg/agents?templateId=${templateId}&distributorId=${distributorId}&managerId=${managerId}`)
            .then(function (response) {
                dispatch({
                    type: types.FETCH_PANDGAGENTS_SUCCESS_ACTION,
                    payload: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: types.FETCH_PANDGAGENTS_FAILURE_ACTION,
                    payload: error
                });
            });
    };
}

export const fetchPandGTemplatesAction = () => {
    return function (dispatch) {
        axios.get(types.API_URL + '/auditor/v1/pandg/templates')
            .then(function (response) {
                dispatch({
                    type: types.FETCH_PANDGTEMPLATES_SUCCESS_ACTION,
                    payload: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: types.FETCH_PANDGTEMPLATES_FAILURE_ACTION,
                    payload: error
                });
            });
    };
}

export const fetchPandGDistributersAction = (templateId) => {
    return function (dispatch) {
        axios.get(types.API_URL + '/auditor/v1/pandg/distributors?templateId='+templateId)
            .then(function (response) {
                dispatch({
                    type: types.FETCH_PANDGDISTRIBUTERS_SUCCESS_ACTION,
                    payload: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: types.FETCH_PANDGDISTRIBUTERS_FAILURE_ACTION,
                    payload: error
                });
            });
    };
}

export const fetchPandGManagersAction = (templateId,distributorId) => {
    return function (dispatch) {
        axios.get(types.API_URL + `/auditor/v1/pandg/managers?templateId=${templateId}&distributorId=${distributorId}`)
            .then(function (response) {
                dispatch({
                    type: types.FETCH_PANDGMANAGERS_SUCCESS_ACTION,
                    payload: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: types.FETCH_PANDGMANAGERS_FAILURE_ACTION,
                    payload: error
                });
            });
    };
}

export const fetchPandGLocationsAction = (templateId,distributorId,managerId,agencyId) => {
    return function (dispatch) {
        axios.get(types.API_URL + `/auditor/v1/pandg/locations?templateId=${templateId}&distributorId=${distributorId}&managerId=${managerId}&agencyId=${agencyId}`)
            .then(function (response) {
                dispatch({
                    type: types.FETCH_PANDGLOCATIONS_SUCCESS_ACTION,
                    payload: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: types.FETCH_PANDGLOCATIONS_FAILURE_ACTION,
                    payload: error
                });
            });
    };
}

export const fetchPandGGetAllAuditsAction = (distributorId, managerId, agencyId, locationId) => {
    return function (dispatch) {
        axios.get(types.API_URL + '/auditor/v1/pandg/getAllAudits?distributorId=' + distributorId + '&managerId=' + managerId + '&agencyId=' + agencyId + '&locationId=' +
                locationId)
            .then(function (response) {
                dispatch({
                    type: types.FETCH_PANDGGETALLAUDITS_SUCCESS_ACTION,
                    payload: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: types.FETCH_PANDGGETALLAUDITS_FAILURE_ACTION,
                    payload: error
                });
            });
    };
}

export const pandgAuditExportAction = (auditId) => {
    return function (dispatch) {
        axios.get(types.API_URL + '/auditor/v1/report/pandgAudit/export?auditId=' + auditId, {
                responseType: "blob"
            })
            .then(function (response) {
                FileSaver.saveAs(response.data, "PandG-Audit-Verification-Status-Report.xlsx");
                dispatch({
                    type: types.DOWNLOAD_PANDGGETAUDITEXPORT_SUCCESS_ACTION,
                    payload: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: types.DOWNLOAD_PANDGGETAUDITEXPORT_FAILURE_ACTION,
                    payload: error
                });
            });
    };
}

export const pandgAuditSubmitAction = (audit) => {
    return function (dispatch) {
        axios.post(types.API_URL + '/auditor/v1/pandg/submit', audit)
            .then(function (response) {
                dispatch({
                    type: types.UPDATE_PANDGPOSTSUBMIT_SUCCESS_ACTION,
                    payload: {
                        data: response.data,
                        audit
                    }
                });
            })
            .catch(function (error) {
                dispatch({
                    type: types.UPDATE_PANDGPOSTSUBMIT_FAILURE_ACTION,
                    payload: error
                });
            });
    };
}

export const selectAuditsFromLeftPane = (audit) => {
    return function (dispatch) {
        dispatch({
            type: types.SELECT_PANDG_AUDIT_FROM_LEFT_PANE,
            payload: audit
        })
    }
}