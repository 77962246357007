import axios from "axios";
import * as types from "./types";
import * as FileSaver from "file-saver";

export const fetchMrRetailTemplatesAction = ({ onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/retailAudit/templates")
      .then(function (response) {
        dispatch({
          type: types.FETCH_MR_TEMPLATE_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_MR_TEMPLATE_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const mrRetailDownloadPhotos = ({
  templateId,
  storeId,
  onSuccess,
  onFailure,
}) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL +
          `/auditor/v1/mraudit/photo?templateId=${templateId}&storeId=${storeId}`,
        {
          responseType: "blob",
        }
      )
      .then(function (response) {
        FileSaver.saveAs(response.data, "mrRetailPhoto.png");
        dispatch({
          type: types.MR_DOWNLOAD_PHOTOS_SUCCESS,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.MR_DOWNLOAD_PHOTOS_FAILURE,
          payload: error,
        });
        onFailure();
      });
  };
};

export const fetchMrRetailStoreAction = (templateId) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL +
          `/auditor/v1/retailAudit/audited/stores?templateId=${templateId}`
      )
      .then(function (response) {
        dispatch({
          type: types.FETCH_MR_STORE_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_MR_STORE_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const fetchMrRetailAuditAction = (templateId, store) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL +
          `/auditor/v1/retailAudit/getAudits?templateId=${templateId}&storeId=${store}`
      )
      .then(function (response) {
        dispatch({
          type: types.FETCH_MR_AUDITS_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_MR_AUDITS_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const fetchMrRetailPhotoAction = (templateId, store) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL +
          `/auditor/v1/retailAudit/getStorePhoto?templateId=${templateId}&storeId=${store}`
      )
      .then(function (response) {
        dispatch({
          type: types.FETCH_MR_PHOTO_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_MR_PHOTO_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const saveMrRetailAuditAction = ({ audit, onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .post(types.API_URL + "/auditor/v1/retailAudit/audit/save", audit)
      .then(function (response) {
        dispatch({
          type: types.SAVE_MRRETAIL_AUDIT_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.SAVE_MRRETAIL_AUDIT_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const downloadReportAction = (templateId, mailId) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL +
          `/auditor/v1/retailAudit/report?templateId=${templateId}&mailId=${mailId}`
      )
      .then(function (response) {
        // FileSaver.saveAs(response.data, 'MR_Audit-Report.xlsx');
        dispatch({
          type: types.DOWNLOAD_MRREPORT_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        console.log(error);
        dispatch({
          type: types.DOWNLOAD_MRREPORT_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const downloadReportPhotosAction = (templateId, mailId) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL +
          `/auditor/v1/retailAudit/photos/download?templateId=${templateId}&mailId=${mailId}`
      )
      .then(function (response) {
        // FileSaver.saveAs(response.data, 'MR_Audit-Report.xlsx');
        dispatch({
          type: types.DOWNLOAD_MRREPORT_PHOTOS_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        console.log(error);
        dispatch({
          type: types.DOWNLOAD_MRREPORT_PHOTOS_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const clearAudits = () => {
  return {
    type: types.CLEAR_AUDIT_ACTION,
  };
};

export const downloadSignoffAction = (templateId, storeId) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL +
          `/auditor/v1/retailAudit/photoReport?templateId=${templateId}&storeId=${storeId}`,
        {
          responseType: "blob",
        }
      )
      .then(function (response) {
        FileSaver.saveAs(response.data, "Signoff.pdf");
        dispatch({
          type: types.DOWNLOAD_MRSIGNOFF_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        console.log(error);
        dispatch({
          type: types.DOWNLOAD_MRSIGNOFF_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};
