import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn } from "../../util";

import "react-notifications/lib/notifications.css";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import MailPopup from "../MailPopup.jsx";
import PGDLocation from "./Locations.jsx";
import CitiesList from "./CityList";
import TableView from "../commonViews/TableView.jsx";
import IconPicker from '../commonViews/IconPicker.jsx'
import ModalView from "../commonViews/ModalView.jsx";
import {
    getTextColor,
    getMainBackground,
    getMatchingTint,
    getFontColor,
} from "../../ThemeHandlers/theme";

import { fetchPGDTemplatesAction, downloadPGDReportAction } from '../../actions/pgd_actions';
import CityList from "./CityList";

class Templates extends React.Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            dtcSelectedTemplate: {},
            selectedTemplateData: "",
            thisTemplatesView: true,
            thisReportDownloading: false,
            template: {},
            templates: [],
            matTableLoad: false,
            color: "",
            templateId: "",
            isMailPopupView: false,
        };
    }

    componentDidMount() {
        if (!isLoggedIn(this.props.auth)) {
            this.props.history.push(`/login`);
            return;
        }
        if (this.props.auth.color) {
            this.setState({ color: this.props.auth.color });
        }
        this.setState({ matTableLoad: true });
        this.props.fetchPGDTemplatesAction({
            onSuccess: (res) => {
                this.setState({ matTableLoad: false, templates: res.templates });
            },
            onFailure: () => {
                this.setState({ matTableLoad: false });
            },
        });


    }
    componentDidUpdate(prevProps) {
        if (this.props.auth.color !== prevProps.auth.color) {
            this.setState({ color: this.props.auth.color });
        }

    }

    handleCloseClick = () => {
        this.setState({ thisTemplatesView: true });
    };

    handleTableViewAuditClick = (template) => {
        this.setState({
            dtcSelectedTemplate: template,
            thisTemplatesView: false,
        });
    };

    sendedData = (rowData, row) => {
        console.log(rowData, 'row')

    };

    handleSendMailReportClick = (m) => {
        this.setState({ isMailPopupView: !this.state.isMailPopupView, mailIds: m });
        let data = {
            templateId: this.state.template._id,
            mailIds: m
        }
        this.props.downloadPGDReportAction({
            data,
            onSuccess: () => {
                NotificationManager.success(
                    "Report Sent to Your Email",
                    "SUCCESS MESSAGE",
                    3000,
                    true
                );
            },
            onFailure: () => {
                NotificationManager.success(
                    "Report Not Send",
                    "Error MESSAGE",
                    3000,
                    true
                );
            },
        });
    };

    handleMailReportClick = (template) => {
        this.setState({ isMailPopupView: !this.state.isMailPopupView, template: template });
    };

    downloadReport = (template) => {
        this.setState({ thisReportDownloading: true, matTableLoad: true });
        this.props.downloadPGDReportAction({
            templateId: template._id,
            onSuccess: () => {
                this.setState({ matTableLoad: false });
            },
            onFailure: () => {
                this.setState({ matTableLoad: false });
            },
        });
    }

    render() {
        var templates =
            this.state.templates.length !== 0 ? this.state.templates : [];


        var col = [
            {
                title: "Template Name",
                field: "auditName",
                render: (rowData) => {
                    return (
                        <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                this.handleTableViewAuditClick(rowData);
                            }}
                        >
                            {rowData.auditName}
                        </span>
                    );
                },
            },
            {
                title: "Report",
                field: "",
                render: (rowData) => {
                    if (rowData._id) {
                        return (
                            <IconPicker
                                size={"large"}
                                styles={{ color: getTextColor(this.state.color), cursor: 'pointer' }}
                                name={"file excel outline"}
                                click={() => this.handleMailReportClick(rowData)}
                            />
                        );
                    }
                },
            }

        ];

        return (
            <div style={{ flexGrow: 1, display: "flex", flexFlow: "column" }}>
                <NotificationContainer />
                {this.state.thisTemplatesView && (
                    <div>
                        <h1 style={{ color: getTextColor(this.state.color) }}>Templates</h1>

                        <TableView
                            columns={col}
                            data={templates}
                            selection={false}
                            load={this.state.matTableLoad}
                        />
                    </div>
                )}
                {!this.state.thisTemplatesView && (
                    <CitiesList
                        selectedTemplate={this.state.dtcSelectedTemplate}
                        onClose={this.handleCloseClick}
                        color={this.state.color}
                    />
                )}
                {/* {!this.state.thisTemplatesView && (
                    <PGDLocation
                        selectedTemplate={this.state.dtcSelectedTemplate}
                        onClose={this.handleCloseClick}
                        color={this.state.color}
                    />
                )} */}
                {this.state.isMailPopupView ? (
                    <MailPopup
                        open={this.state.isMailPopupView}
                        mailIds={this.state.mailIds}
                        closePopup={this.handleMailReportClick.bind(this)}
                        sendMailAndClosePopup={this.handleSendMailReportClick}
                        getColor={getMainBackground}
                        color={this.state.color}
                        getCellColor={getMatchingTint}
                    />
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            fetchPGDTemplatesAction,
            downloadPGDReportAction
        },
        dispatch
    );
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Templates)
);
