import axios from 'axios';
import * as types from './types';
import * as FileSaver from 'file-saver';

export const NaturalsfetchAuditsAction = (queryObejct, audits, clearFilter = null) => {
    if (clearFilter) {
        return {
            type: types.CLEAR_SEARCHED_NATURALS_AUDITS_ACTION
        }
    }

    let activePageKey, isSearchAction;
    let appliedFilters;
    let filtered;

    if (!queryObejct.directNaviationKey) {
        let {
            pageIndex,
            pageSize
        } = queryObejct;
        let skip = pageIndex > 0 ? pageIndex * pageSize : 0;
        activePageKey = `/auditor/v1/naturals/audits?skip=${skip}&limit=${pageSize}`;
        filtered = queryObejct.filtered;
        isSearchAction = filtered && filtered.length > 0;
    } else {
        activePageKey = queryObejct.directNaviationKey;
        isSearchAction = queryObejct.isSearchAction;
    }

    //Filter maping and already fetched data logic start
    // Bellow action only for already fetched data
    let action = {
        type: isSearchAction ? types.ALL_READY_SEARCHED_NATURALS_AUDITS_ACTION : types.ALL_READY_FETCHED_NATURALS_AUDITS_ACTION,
        appliedFilters
    }

    if (isSearchAction && !queryObejct.directNaviationKey) {
        appliedFilters = {}
        filtered = filtered.map(filter => {
            let formated = {}
            switch (filter.value.type) {
                case "date":
                    formated = {
                        id: filter.id,
                        type: filter.value.type,
                        start: filter.value.start
                    }
                    break;
                case "number":
                case "objectId":
                case "stringEq":

                    formated = {
                        id: filter.id,
                        type: filter.value.type,
                        value: filter.value.data
                    }
                    break;
                default:
                    formated = {
                        id: filter.id,
                        value: filter.value.data
                    }
            }
            appliedFilters[filter.value.filterName] = formated
            return formated;
        })
        activePageKey = `${activePageKey}&q=${JSON.stringify(filtered)}`

    }

    console.log(`%c${activePageKey}`, 'color:blue;')

    if (audits[activePageKey]) {
        console.log("%creturing already fetched data", 'color:red;font-weight: bold;');
        action.navigation = {
            activePageKey
        }
        return action;
    }
    //Filter maping and already fetched data logic end
    return function (dispatch) {
        let start = Date.now();
        axios.get(encodeURI(`${types.API_URL}${activePageKey}`))
            .then(function (response) {
                console.log(`%cAPI Fetch complete in ${Date.now() - start}ms.`, "color:red;font-weight: bold;")
                dispatch({
                    type: isSearchAction ? types.SEARCH_NATURALS_AUDITS_SUCCESS_ACTION : types.FETCH_NATURALS_AUDITS_SUCCESS_ACTION,
                    payload: response.data,
                    navigation: {
                        activePageKey
                    },
                    appliedFilters
                });
            })
            .catch(function (error) {
                console.log(error)
                dispatch({
                    type: types.FETCH_NATURALS_AUDITS_FAILURE_ACTION,
                    payload: error
                });
            });
    };
}

export const NaturalsupdateAuditAction = (auditId, audit) => {
    return function (dispatch) {
        axios.put(types.API_URL + '/auditor/v1/naturals/audits/' + auditId, audit)
            .then(function (response) {
                console.log("cnaclnascknal")
                dispatch({
                    type: types.UPDATE_NATURALS_AUDIT_SUCCESS_ACTION,
                    payload: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: types.UPDATE_NATURALS_AUDIT_FAILURE_ACTION,
                    payload: error
                });
            });
    };
}

export const NaturalsdownloadAuditReportAction = (auditId) => {
    return function (dispatch) {
        axios.get(types.API_URL + '/auditor/v1/naturals/report/export?auditId=' + auditId, {
                responseType: "blob"
            })
            .then(function (response) {
                FileSaver.saveAs(response.data, "Audit-Report.xls");
                dispatch({
                    type: types.DOWNLOAD_NATURALS_REPORT_SUCCESS_ACTION,
                    payload: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: types.DOWNLOAD_NATURALS_REPORT_FAILURE_ACTION,
                    payload: error
                });
            });
    };
}

export const NaturalsdownloadAuditReportPhotoAction = (auditId) => {
    return function (dispatch) {
        axios.get(types.API_URL + '/auditor/v1/report/audit_photos?auditId=' + auditId, {
                responseType: "blob"
            })
            .then(function (response) {
                FileSaver.saveAs(response.data, "Audit-Report-Photos.pdf");
                dispatch({
                    type: types.DOWNLOAD_NATURALS_REPORT_PHOTO_SUCCESS_ACTION,
                    payload: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: types.DOWNLOAD_NATURALS_REPORT_PHOTO_FAILURE_ACTION,
                    payload: error
                });
            });
    };
}

export const NaturalsmailAuditReportAction = (auditId, mailIds) => {
    return function (dispatch) {
        axios.get(types.API_URL + '/auditor/v1/naturals/report/sendmail?auditId=' + auditId + '&mailIds=' + mailIds)
            .then(function (response) {
                dispatch({
                    type: types.MAIL_NATURALS_REPORT_SUCCESS_ACTION,
                    payload: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: types.MAIL_NATURALS_REPORT_SUCCESS_ACTION,
                    payload: error
                });
            });
    };
}

export const fetchImagesOfAudit = (AuditId, tag) => {
    let endPoint = types.API_URL + '/auditor/v1/naturals/report/get_images/' + AuditId;
    if (tag) {
        endPoint = `${endPoint}?tag=${tag}`
    }
    return function (dispatch) {
        axios.get(endPoint)
            .then(function (response) {
                dispatch({
                    type: types.FETCH_NATURALS_AUDIT_IMAGES_SUCCESS_ACTION,
                    payload: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: types.FETCH_NATURALS_AUDIT_IMAGES_FAILURE_ACTION,
                    payload: error
                });
            });
    };
}