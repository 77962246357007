import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn, isClient, isAdmin } from "../../util";
import {
  fetchDealerVehiclesAction,
  updateVehicleAuditAction,
  updateDealerAction,
  uploadDaimlerPhotoAction,
  fetchDealerClientVehiclesAction,
  fetchShowDealerToClientAction,
  uploadDaimlerSignOffAction,
  sendSignOffCopyUrl,
} from "../../actions/vehicle_verification_actions";
import { Label } from "semantic-ui-react";
import IconPicker from "../commonViews/IconPicker";
import ButtonHandler from "../commonViews/ButtonHandler";
import { Input } from "semantic-ui-react";
import ModalView from "../commonViews/ModalView";
import TableView from "../commonViews/TableView";
import DaimlerAudit from "./DaimlerAudit.jsx";
import DaimlerAuditViewDetails from "./DaimlerAuditViewDetails.jsx";
import moment from "moment";
import CommonStatistic from "../commonTables/CommonStatistic.jsx";
import {
  getMainBackground,
  getMatchingTint,
  getTextColor,
  getFontColor,
} from "../../ThemeHandlers/theme";
class Vehicles extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedVehicleId: "",
      selectedAudit: "",
      showPopup: false,
      showNoAuditFound: false,
      editMode: false,
      editedAudit: {},
      saveError: false,
      isApproved: false,
      signOffPhotos: null,
      vehicles: [],
      color: "",
    };
  }

  componentDidMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
      return;
    }
    if (this.props.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
    if (this.isClientProfile())
      this.props.fetchDealerClientVehicles(this.props.dealer._id);
    else {
      this.props.fetchVehicles(this.props.dealer._id);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth.color !== prevProps.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
    if (this.props.vehicles !== prevProps.vehicles) {
      this.setState({ vehicles: this.props.vehicles });
    }
  }

  authenticate = () => {
    this.props.login(this.state.username, this.state.password);
  };

  searchFilter = (filter, row) => {
    var flatData = JSON.stringify(row._original);
    return flatData.toLowerCase().includes(filter.value.toLowerCase());
  };

  handleTableViewAuditClick = (vehicle) => {
    console.log("clicking");
    if (!vehicle._id) return;

    var audit = this.getAudit(vehicle._id);
    if (!audit) {
      this.setState({ showNoAuditFound: true });
      return;
    }

    this.setState({
      selectedVehicleId: vehicle._id,
      selectedAudit: audit,
      showPopup: true,
    });
  };

  getAudit(vehicleId) {
    for (var audit of this.props.audits) {
      if (audit.vehicleId === vehicleId) return audit;
    }

    return false;
  }

  handleCancel = () => this.setState({ showNoAuditFound: false });

  closePopup = () => {
    this.setState({ showPopup: false, newAudit: {}, editMode: false });
  };

  isClientProfile = () => {
    return isClient(this.props.auth);
  };

  isAdminProfile = () => {
    return isAdmin(this.props.auth);
  };
  saveAudit = () => {
    var audit = this.state.selectedAudit;
    if (!audit.soldInvoice) audit.soldInvoice = {};

    var dealerAssessment = this.props.dealer.dealerAssessment;
    if (!dealerAssessment) dealerAssessment = {};

    audit.soldInvoice.no = this.state.editedAudit.invoiceNo;

    var date = this.state.editedAudit.invoiceDate;
    audit.soldInvoice.date = date ? date.toISOString() : "";

    audit.notes = this.state.editedAudit.notes;
    audit.storeRoom = this.state.editedAudit.storeRoom;
    audit.questions[0].answer = this.state.editedAudit.vehicleStatus;

    //Daimler audit stuff
    audit.vehicleCondition = this.state.editedAudit.vehicleCondition;
    audit.odometerReading = this.state.editedAudit.odometerReading;
    audit.gatepassCopyUploaded = this.state.editedAudit.gatepassCopyUploaded;
    audit.eventName = this.state.editedAudit.eventName;
    audit.eventLocation = this.state.editedAudit.eventLocation;
    audit.daysSent = this.state.editedAudit.daysSent;
    audit.expectedReturnDate = this.state.editedAudit.expectedReturnDate;
    audit.doDate = this.state.editedAudit.doDate;
    audit.invoiceDate = this.state.editedAudit.invoiceDate;
    audit.doFinancier = this.state.editedAudit.doFinancier;
    audit.customerName = this.state.editedAudit.customerName;
    audit.paymentReceivedFromCustDate =
      this.state.editedAudit.paymentReceivedFromCustDate;
    audit.paymentToDFSIDate = this.state.editedAudit.paymentToDFSIDate;
    audit.doCopyUploaded = this.state.editedAudit.doCopyUploaded;
    audit.form21Available = this.state.editedAudit.form21Available;
    audit.form22Available = this.state.editedAudit.form22Available;
    audit.hypothecationDetails = this.state.editedAudit.hypothecationDetails;
    dealerAssessment.isInvoiceShown = this.state.editedAudit.isInvoiceShown;
    dealerAssessment.cooperationScale = this.state.editedAudit.cooperationScale;

    if (
      audit.form21Available === undefined ||
      audit.form22Available === undefined ||
      audit.hypothecationDetails === undefined
    ) {
      this.setState({ saveError: true });
      return;
    }
    if (this.state.editedAudit.photo != null) {
      this.props.uploadPhoto(this.state.editedAudit.photo, {
        orgId: audit.orgId,
        auditId: audit._id,
        type: "daimlerVerification",
      });
    }
    this.props.updateVehicleAudit(this.state.selectedAudit);

    this.setState({
      showPopup: false,
      newAudit: {},
      editMode: false,
      saveError: false,
    });
  };

  editAudit = () => this.setState({ editMode: true });

  handlePhotoClick = () => { };

  uploadSignOffPhotos = (_) => {
    this.props.uploadDaimlerSignOffAction(
      this.state.signOffPhotos,
      this.props.dealer._id,
      {
        onSuccess: (res) => {
          this.props.sendSignOffCopyUrl(this.props.dealer._id, res, {
            onSuccess: () => {
              alert("signoff copy uploaded successfully");
            },
            onFailure: () => { },
          });
        },
        onFailure: () => {
          alert("File Size is More than 1 Mb.Please Upload less than 1Mb");
        },
      }
    );
  };

  selectSignoffPhotos = (d) => {
    this.setState({ signOffPhotos: d.target.files });
  };

  handleApprove = () => {
    var dealerId = this.props.dealer._id;
    this.setState({ isApproved: true });
    this.props.fetchDaimlerAdminTemplates(dealerId);
  };

  render() {
    var vehicles = this.state.vehicles.length !== 0 ? this.state.vehicles : [];
    // var vehicles = []

    function getStatus(status) {
      if (status === "submitted") return "Verified";

      return "Not Verified";
    }
    var col = [
      {
        title: "",
        field: "",
        render: (rowData) => {
          var status = this.getAudit(rowData._id).status;
          if (status == "submitted") {
            return <Label size="medium" color="green"></Label>;
          } else {
            return <Label size="medium"></Label>;
          }
        },
      },
      {
        title: "Chassis Number (VIN)",
        field: "",
        render: (rowData) => (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.handleTableViewAuditClick(rowData);
            }}
          >
            {rowData.VIN}
          </span>
        ),
        customFilterAndSearch: (term, rowData) =>
          rowData.VIN.toLowerCase().includes(term.toLowerCase()),
      },
      {
        title: "Model Name",
        field: "",
        render: (rowData) => (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.handleTableViewAuditClick(rowData);
            }}
          >
            {rowData.modelDesc}
          </span>
        ),
        customFilterAndSearch: (term, rowData) =>
          rowData.modelDesc.toLowerCase().includes(term.toLowerCase()),
      },
    ];


    var title =
      this.props.dealer.name +
      " - Vehicles - " +
      this.props.dealer.locations[0].name;
    if (this.props.audits != null) {
      var temp = "";
      for (var i = 0; this.props.audits.length > 0; i++) {
        if (temp === "") {
          if (this.props.audits[0].createdAt) {
            temp = this.props.audits[0].createdAt;
          }
        } else {
          break;
        }
      }
      if (temp != "") {
        var dateToTitle = moment(this.props.audits[0].createdAt).format(
          "DD-MM-YYYY"
        );
        title = title + ". Audited on: " + dateToTitle;
      }
    }

    var dealer = this.props.dealer || {};

    var selectedAudit = this.state.selectedAudit;

    return (
      <div
        style={{
          flexGrow: 1,
        }}
      >
        <IconPicker
          styles={{
            color: "#808080",
            marginTop: 15,
            marginRight: 30,
            float: "right",
            cursor: "pointer",
          }}
          click={this.props.onClose}
          size={"large"}
          name={"arrow left"}
        />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Input type="file" onChange={this.selectSignoffPhotos} multiple />
          <ButtonHandler
            color={"blue"}
            styles={{
              marginTop: 10,
              marginRight: 30,
              marginLeft: 40,
              backgroundColor: getMainBackground(this.state.color),
              color: getFontColor(this.state.color),
            }}
            click={this.uploadSignOffPhotos}
            title={'Upload'}
          />
        </div>


        {this.isAdminProfile() ? (
          <ShowApproveButtons
            dealer={this.props.dealer}
            handleApprove={this.handleApprove}
            color={this.state.color}
            backColor={getMainBackground}
            fontColor={getFontColor}
          />
        ) : (
          <div></div>
        )}
        <div style={{ flexGrow: 1, display: "flex", flexFlow: "column" }}>
          <CommonStatistic
            title={title}
            summary={this.props.summary}
            color={this.state.color}
            textColor={getTextColor}
          />


          <TableView selection={false} data={vehicles} columns={col} />
        </div>
        {this.state.showNoAuditFound && (
          <ModalView open={this.state.showNoAuditFound}
            close={this.handleCancel}
            header={'Audit Not Found'}
            content={
              <div class="ui message">
                <div class="header">No Audit found for selected dealer.</div>
              </div>
            }
            showSave={false}
          />

        )}

        {this.state.showPopup && selectedAudit && (
          <ModalView
            open={this.state.showPopup}
            close={this.closePopup}
            header={selectedAudit.auditName}
            content={
              <>
                {!this.state.editMode &&
                  <DaimlerAuditViewDetails selectedAudit={selectedAudit} />
                }
                {this.state.editMode &&
                  <DaimlerAudit
                    audit={selectedAudit}
                    dealer={dealer}
                    newAudit={this.state.editedAudit}
                  />
                }
              </>

            }
            saveError={
              <>
                {this.state.saveError && (
                  <Label pointing={"right"}>
                    Back office details cannot be empty
                  </Label>
                )}
              </>
            }
            size={'small'}
            saveText={!this.state.editMode ? 'Edit' : 'Save'}
            showSave={true}
            saveClick={!this.state.editMode ? this.editAudit : this.saveAudit}
          />

        )}
      </div>
    );
  }
}

function AuditTableCell(props) {
  function onClick() {
    props.onClick(props.row);
  }
  return (
    <div style={props.style} onClick={onClick}>
      {props.text}
    </div>
  );
}

function ShowApproveButtons(props) {
  if (props.dealer.showClient == true) {
    return (
      <ButtonHandler color={'grey'}
        styles={{ marginTop: 10, marginRight: 30, float: "right" }}
        title={'Approved'}
        size={"medium"}
        click={() => { }}
      />

    );
  } else {
    return (
      <ButtonHandler
        click={props.handleApprove}
        styles={{
          marginTop: 10,
          marginRight: 30,
          float: "right",
          backgroundColor: props.backColor(props.color),
          color: props.fontColor(props.color),
        }}
        title={'Approve'}
      />

    );
  }
}

// Vehicles.PropTypes = {
//     vehicles: PropTypes.array,
//     audits: PropTypes.array,
//     dealer: PropTypes.object,
//     summary: PropTypes.object
// }

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    audit: state.audits.audit,
    vehicles: state.audits.vehicles,
    summary: state.audits.dealerSummary,
    audits: state.audits.dynamicAudits,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchVehicles: fetchDealerVehiclesAction,
      fetchDealerClientVehicles: fetchDealerClientVehiclesAction,
      updateVehicleAudit: updateVehicleAuditAction,
      updateDealer: updateDealerAction,
      uploadPhoto: uploadDaimlerPhotoAction,
      fetchDaimlerAdminTemplates: fetchShowDealerToClientAction,
      uploadDaimlerSignOffAction: uploadDaimlerSignOffAction,
      sendSignOffCopyUrl: sendSignOffCopyUrl,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Vehicles)
);
