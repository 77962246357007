import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn, isClient } from "../../util";
import {
  Card,
  Button,
  Confirm,
  Segment,
  Popup,

} from "semantic-ui-react";
import ModalView from "../commonViews/ModalView";
import ButtonHandler from "../commonViews/ButtonHandler";
import IconPicker from "../commonViews/IconPicker";
import {
  fetchDaimlerDealersActions,
  fetchDealerAuditReportAction,
  fetchDaimlerClientDealersAction,
  fetchDealerClientAuditReportAction,
  fetchDaimlerConsolidatedReportAction,
  clearDealersVehiclesAction,
  fetchVehiclesCountsAction,
} from "../../actions/vehicle_verification_actions";
import CheckBoxTable from "../commonTables/CheckboxView.jsx";
import ExcessVehicles from "../vehicle_verification/ExcessVehicles.jsx";
import DaimlerVehicles from "./DaimlerVehicles.jsx";
import MailPopup from "../MailPopup.jsx";
import config from "../../config.js";
import { ModalContent } from "semantic-ui-react";
import CheckboxTable from "../commonTables/CheckboxView.jsx";
import TableView from "../commonViews/TableView";
import {
  getFontColor,
  getTextColor,
  getMainBackground,
  getMatchingTint,
} from "../../ThemeHandlers/theme";
import Metrics from "../commonViews/Metrics";

const storageBaseUrl = config["storage_base_url"];

class DaimlerAudits extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.checkboxTable = null;

    this.setDaimlerCheckboxRef = (element) => {
      this.checkboxTable = element;
    };
    this.state = {
      selection: [],
      selectAll: false,
      selectedDealer: "",
      isTableView: true,
      isMailPopupView: false,
      showReportDataNotFound: false,
      modalOpen: false,
      dealers: [],
      color: "",
    };
  }

  componentDidMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
      return;
    }
    if (this.props.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
    if (this.isClientProfile())
      this.props.fetchDaimlerClientDealers(this.props.template._id);
    else {
      this.props.fetchDaimlerDealers(this.props.template._id);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth.color !== prevProps.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
    if (this.props.dealers !== prevProps.dealers) {
      this.setState({
        dealers: this.props.dealers,
      });
    }
  }

  handleCloseClick = () => {
    this.props.clearDealersVehiclesAction();
    // this.props.fetchDaimlerDealers(this.props.template._id);
    this.setState({ isTableView: true });
  };

  handleTableViewAuditClick = (dealer) => {
    this.props.clearDealersVehiclesAction();
    if (!dealer._id) return;

    this.setState({ selectedDealer: dealer, isTableView: false });
  };

  isClientProfile = () => {
    return isClient(this.props.auth);
  };

  searchFilter = (filter, row) => {
    var flatData = JSON.stringify(row._original);
    return flatData.toLowerCase().includes(filter.value.toLowerCase());
  };

  handleDownloadAgentReportClick = (dealer) => {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
      return;
    }

    if (!dealer._id) {
      this.setState({ showReportDataNotFound: true });
      return;
    }
    // if (this.isClientProfile()) {
    //     this.props.fetchDealerClientAuditReport(dealer._id);
    // }
    // else {
    //     this.props.downloadAuditReport(dealer._id);
    // }
  };

  // handleSendConsolidatedReportClick = emailIds => {
  //     this.setState({ isMailPopupView: !this.state.isMailPopupView, mailIds: emailIds });
  //     if (!emailIds) return;
  //     this.props.fetchDaimlerConsolidatedReport(
  //         this.props.template._id,
  //         emailIds,
  //         JSON.stringify(this.state.selection)
  //     );
  //     this.setState({ selection: [] });
  // };

  downloadConsolidatedReport = () => {
    this.setState({ isMailPopupView: true });
  };
  closeEditUser = () => {
    // this.props.clearUserAction();
    this.setState({ modalOpen: false });
  };

  handleCancel = () => this.setState({ showReportDataNotFound: false });

  toggleSelection = (key, shift, row) => {
    // start off with the existing state
    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(key);
    // check to see if the key exists
    if (keyIndex >= 0) {
      // it does exist so we will remove it using destructing
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1),
      ];
    } else {
      // it does not exist so add it
      selection.push(key);
    }
    // update the state
    this.setState({ selection });
  };

  toggleAll = () => {
    const selectAll = this.state.selectAll ? false : true;
    const selection = [];
    if (selectAll) {
      // we need to get at the internals of ReactTable
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // we just push all the IDs onto the selection array
      currentRecords.forEach((item) => {
        selection.push(item._original._id);
      });
    }
    this.setState({ selectAll, selection });
  };

  isSelected(key) {
    return this.state.selection.includes(key);
  }
  showCount = (dealer) => {
    this.props.fetchVehiclesCountsAction(dealer);

    this.setState({ modalOpen: true });
  };
  handleChangeMail = (mail) => {
    if (this.state.dealerId !== "") {
      this.props.downloadAuditReport(this.state.dealerId, mail);
    }
  };

  handleSendMailReportClick = (id) => {
    this.setState({
      isMailPopupView: !this.state.isMailPopupView,
      dealerId: id,
    });
  };

  handleMailReportClick = (e) => {
    this.setState({ isMailPopupView: !this.state.isMailPopupView });
  };
  getLocationName = (rowData) => {
    let value = "";
    rowData.locations.map((location, i) => {
      // console.log(location, "locateeeeeeeeeee");
      return (value = location.name);
    });
    return value;
  };

  render() {
    if (this.props.counts != undefined) {
    }
    let dealerArray = Array.isArray(this.state.dealers);
    var dealers =
      dealerArray === true
        ? this.state.dealers.length !== 0
          ? this.state.dealers
          : []
        : [];
    var dealerCol = [
      {
        title: "Name",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.name}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.name.toLowerCase().includes(term.toLowerCase()),
      },
      {
        title: "Location",
        field: "",
        render: (rowData) => (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.handleTableViewAuditClick(rowData);
            }}
          >
            {this.getLocationName(rowData)}
          </span>
        ),
        customFilterAndSearch: (term, rowData) => {
          let value = this.getLocationName(rowData);
          return value.toLowerCase().includes(term.toLowerCase());
        },
      },
      {
        title: "Brand",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.brand}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.brand.toLowerCase().includes(term.toLowerCase()),
      },
      {
        title: "Code",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.dealerCode}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.dealerCode.toLowerCase().includes(term.toLowerCase()),
      },
      {
        title: "Verification",
        field: "",
        render: (rowData) => {
          if (rowData._id) {
            return (
              <IconPicker
                size={"large"}
                color={"red"}
                name={"calculator"}
                click={() => this.showCount(rowData._id)}
              />
            );
          }
        },
      },
      {
        title: "Report",
        field: "",
        render: (rowData) => {
          if (rowData._id) {
            return (
              <IconPicker
                size={"large"}
                styles={{ color: getTextColor(this.state.color) }}
                name={"file excel outline"}
                click={() => this.handleSendMailReportClick(rowData._id)}
              />
            );
          }
        },
      },
      {
        title: "Sign Off",
        field: "",
        render: (rowData) => {
          return (
            <Popup
              wide
              trigger={

                <Button
                  size="mini"
                  content="Sign Off"
                  style={{
                    backgroundColor: getMainBackground(this.state.color),
                    color: getFontColor(this.state.color),
                  }}
                />
              }
              position="bottom center"
              on="click"
            >
              <Card.Group>
                {rowData.signOff &&
                  rowData.signOff.map((url, i) => {
                    if (url) {
                      return (
                        <Card>
                          <Card.Content>
                            <a href={storageBaseUrl + url}>File - {i + 1}</a>
                          </Card.Content>
                        </Card>
                      );
                    }
                  })}
              </Card.Group>
            </Popup>
          );
        },
      },
    ];

    var summary = this.props.summary || {};
    let metrics = {
      Dealers: summary["totalDealers"] || 0,
      Vehicles: summary["totalVehicles"] || 0,
      Verified: (summary["totalVerified"] || 0) +
        "/" +
        (summary["totalVehicles"] || 0),
      DealersAudited: summary["allVerified"] || 0,
      DealersNotAudited: summary["noVerified"] || 0,
      DealerAuditinprogress: summary["partialVerified"] || 0

    }
    return (
      <div style={{ flexGrow: 1, display: "flex", flexFlow: "column" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <h1 style={{ color: getTextColor(this.state.color) }}>
            Vehicle Verification - Audits
          </h1>
          {this.state.isTableView && (
            <IconPicker
              styles={{
                color: "#808080",
                marginTop: 10,
                marginRight: 30,
                float: "right",
                cursor: "pointer",
              }}
              click={this.props.onClose}
              size={"large"}
              name={"arrow left"}
            />
          )}
        </div>

        {this.state.isTableView && this.state.selection.length > 1 && (

          <Button
            style={{ position: "absolute", right: "10px" }}
            color="green"
            content="Download Report"
            icon="file excel outline"
            onClick={this.downloadConsolidatedReport}
            label={{
              basic: true,
              color: "green",
              pointing: "left",
              content: this.state.selection.length,
            }}
          />
        )}
        {this.state.isTableView && (
          <div style={{ marginBottom: "5px" }}>
            <Metrics metrics={metrics} color={this.state.color} size={'small'} />


            <div style={{ display: "flex", flexGrow: 1, flexFlow: "column" }}>
              <div>
                <TableView
                  selection={true}
                  data={dealers}
                  columns={dealerCol}
                  noofRows={50}
                />
              </div>
            </div>
          </div>
        )}
        <ModalView
          open={this.state.modalOpen}
          close={this.closeEditUser}
          size={'mini'}
          content={
            <>
              {this.props.counts != undefined && (
                <div>
                  <h1>Total Verified : {this.props.counts.totalVerified}</h1>
                  <h1>Total Vehicles : {this.props.counts.totalVehicles}</h1>
                </div>
              )}
            </>
          }
          showSave={false}
        />

        {!this.state.isTableView && (
          <div style={{ flexGrow: 1, display: "flex" }}>
            <Segment.Group
              style={{
                width: "100%",
                backgroundColor: getMatchingTint(this.state.color),
              }}
            >
              <Segment
                style={{ backgroundColor: getMatchingTint(this.state.color) }}
              >
                <DaimlerVehicles
                  dealer={this.state.selectedDealer}
                  onClose={this.handleCloseClick}
                />
              </Segment>
              <Segment
                style={{ backgroundColor: getMatchingTint(this.state.color) }}
              >
                <ExcessVehicles
                  dealer={this.state.selectedDealer}
                  onClose={this.handleCloseClick}
                  color={this.state.color}
                  matchTint={getMatchingTint}
                  backColor={getMainBackground}
                  textColor={getTextColor}
                  fontColor={getFontColor}
                />
              </Segment>
            </Segment.Group>
          </div>
        )}
        {this.state.isMailPopupView ? (
          <MailPopup
            open={this.state.isMailPopupView}
            mailIds={this.state.mailIds}
            closePopup={this.handleMailReportClick.bind(this)}
            sendMailAndClosePopup={this.handleChangeMail}
            getColor={getMainBackground}
            color={this.state.color}
            getCellColor={getMatchingTint}
          />
        ) : null}
        {this.state.showReportDataNotFound && (
          <Confirm
            open={this.state.showReportDataNotFound}
            onCancel={this.handleCancel}
            onConfirm={this.handleCancel}
          />
        )}
      </div>
    );
  }
}

function AuditTableCell(props) {
  function onClick() {
    props.onClick(props.row);
  }
  return (
    <div style={props.style} onClick={onClick}>
      {props.text}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    dealers: state.audits.dealers,
    summary: state.audits.dealerVehicleSummary,
    counts: state.audits.counts,
    showReportDataNotFound: state.reportAuditFound,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchDaimlerDealers: fetchDaimlerDealersActions,
      fetchDaimlerClientDealers: fetchDaimlerClientDealersAction,
      downloadAuditReport: fetchDealerAuditReportAction,
      fetchDaimlerConsolidatedReport: fetchDaimlerConsolidatedReportAction,
      clearDealersVehiclesAction: clearDealersVehiclesAction,
      fetchVehiclesCountsAction: fetchVehiclesCountsAction,
    },
    dispatch
  );
};

// DaimlerAudits.PropTypes = {
//     dealers: PropTypes.array
// };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DaimlerAudits)
);
