import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn } from "../../util";

import IconPicker from '../commonViews/IconPicker.jsx'
import "react-notifications/lib/notifications.css";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import OlaVehicles from "./OlaVehicles";
import TableView from "../commonViews/TableView.jsx";
import ModalView from "../commonViews/ModalView.jsx";
import {
    getTextColor,
    getMainBackground,
    getMatchingTint,
    getFontColor,
} from "../../ThemeHandlers/theme";
import { fetchOlaTemplatesAction, downloadOlaReportAction, fetchOlaAuditsLocationDataAction, downloadOlaReportLocationWiseAction } from '../../actions/ola_actions';

class OlaTemplates extends React.Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            olaSelectedTemplate: {},
            selectedTemplateData: "",
            thisTemplatesView: true,
            thisReportDownloading: false,
            template: {},
            templates: [],
            matTableLoad: false,
            color: "",
            modalOpen: false,
            location: [],
            templateId: ''
        };
    }

    componentDidMount() {
        if (!isLoggedIn(this.props.auth)) {
            this.props.history.push(`/login`);
            return;
        }
        if (this.props.auth.color) {
            this.setState({ color: this.props.auth.color });
        }
        this.setState({ matTableLoad: true });
        this.props.fetchOlaTemplatesAction({
            onSuccess: (res) => {
                this.setState({ matTableLoad: false, templates: res.templates });
            },
            onFailure: () => {
                this.setState({ matTableLoad: false });
            },
        });

        this.props.fetchOlaAuditsLocationDataAction({
            onSuccess: (res) => {
                this.setState({ matTableLoad: false, location: res.locationList });
            },
            onFailure: () => {
                this.setState({ matTableLoad: false });
            },
        });
    }
    componentDidUpdate(prevProps) {
        if (this.props.auth.color !== prevProps.auth.color) {
            this.setState({ color: this.props.auth.color });
        }

    }

    handleCloseClick = () => {
        this.setState({ thisTemplatesView: true });
    };

    handleTableViewAuditClick = (template) => {
        this.setState({
            olaSelectedTemplate: template,
            thisTemplatesView: false,
        });
    };

    downloadReport = (template) => {
        this.setState({ thisReportDownloading: true, matTableLoad: true });
        let templateId = template._id;
        this.props.downloadOlaReportAction({
            templateId,
            onSuccess: () => {
                this.setState({ matTableLoad: false });
            },
            onFailure: () => {
                this.setState({ matTableLoad: false });
            },
        });
    };

    locationWiseReport = (location) => {
        this.setState({ thisReportDownloading: true, matTableLoad: true });
        let locationId = location._id
        let templateId = this.state.templateId
        this.props.downloadOlaReportLocationWiseAction({
            templateId,
            locationId,
            onSuccess: () => {
                this.setState({ matTableLoad: false });
            },
            onFailure: () => {
                this.setState({ matTableLoad: false });
            },
        });
    }

    closeModal = () => {
        this.setState({ modalOpen: false });
    };

    openModal = (template) => {
        this.setState({ modalOpen: true, templateId: template._id });
    };

    sendedData = (rowData, row) => {
        console.log(rowData, 'row')

    };

    render() {
        var templates =
            this.state.templates.length !== 0 ? this.state.templates : [];

        var column = [
            {
                title: "Locations",
                field: "",
                render: (rowData) => {
                    return (
                        <span>
                            {`${rowData.yardName} - ${rowData.city}`}
                        </span>
                    );
                },
                customFilterAndSearch: (term, rowData) =>
                    rowData.yardName.toLowerCase().indexOf(term.toLowerCase()) > -1,
            },
            {
                title: "Report",
                field: "",
                render: (rowData) => {
                    if (rowData._id) {
                        return (
                            <IconPicker
                                size={"large"}
                                styles={{
                                    cursor: "pointer",
                                    color: getTextColor(this.state.color),
                                }}
                                name={"file excel outline"}
                                click={() => this.locationWiseReport(rowData)}
                            />
                        );
                    }
                },
            },
        ]

        var col = [
            {
                title: "Template Name",
                field: "auditName",
                render: (rowData) => {
                    return (
                        <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                this.handleTableViewAuditClick(rowData);
                            }}
                        >
                            {rowData.auditName}
                        </span>
                    );
                },
            },
            {
                title: "Total Locations",
                field: "",
                render: (rowData) => {
                    return (
                        <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                this.handleTableViewAuditClick(rowData);
                            }}
                        >
                            {rowData.totalLocations}
                        </span>
                    );
                },
                customFilterAndSearch: (term, rowData) =>
                    rowData.stats.draftAudits == term,
            },
            {
                title: "Total Vehicles",
                field: "",
                render: (rowData) => {
                    return (
                        <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                this.handleTableViewAuditClick(rowData);
                            }}
                        >
                            {rowData.totalVehicles}
                        </span>
                    );
                },
                customFilterAndSearch: (term, rowData) =>
                    rowData.stats.submittedAudits == term,
            },
            {
                title: "Overall Report",
                field: "",
                render: (rowData) => {
                    if (rowData._id) {
                        return (
                            <IconPicker
                                size={"large"}
                                styles={{
                                    cursor: "pointer",
                                    color: getTextColor(this.state.color),
                                }}
                                name={"file excel outline"}
                                click={() => this.downloadReport(rowData)}
                            />
                        );
                    }
                },
            },
            {
                title: "Location Wise Report",
                field: "",
                render: (rowData) => {
                    if (rowData._id) {
                        return (
                            <IconPicker
                                size={"large"}
                                styles={{
                                    cursor: "pointer",
                                    color: getTextColor(this.state.color),
                                }}
                                name={"file excel outline"}
                                click={() => this.openModal(rowData)}
                            />
                        );
                    }
                },
            }
        ];

        return (
            <div style={{ flexGrow: 1, display: "flex", flexFlow: "column" }}>
                <NotificationContainer />
                {this.state.thisTemplatesView && (
                    <div>
                        <h1 style={{ color: getTextColor(this.state.color) }}>Templates</h1>

                        <TableView
                            columns={col}
                            data={templates}
                            selection={false}
                            load={this.state.matTableLoad}
                        />
                    </div>
                )}
                <ModalView
                    open={this.state.modalOpen}
                    close={this.closeModal}
                    size={'small'}
                    content={
                        <TableView
                            data={this.state.location}
                            columns={column}
                            load={this.state.matTableLoad}
                        />
                    }
                    showSave={false}
                    saveClick={this.userAssign}
                />
                {!this.state.thisTemplatesView && (
                    <OlaVehicles
                        selectedTemplate={this.state.olaSelectedTemplate}
                        onClose={this.handleCloseClick}
                        color={this.state.color}
                    />
                )}

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            fetchOlaTemplatesAction,
            downloadOlaReportAction,
            fetchOlaAuditsLocationDataAction,
            downloadOlaReportLocationWiseAction
        },
        dispatch
    );
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(OlaTemplates)
);
