import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn } from "./../util";
import { Segment, Label, Input } from "semantic-ui-react";
import { getTextColor } from "../ThemeHandlers/theme";

class QuestionnaireSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSection: "",
    };
  }

  componentDidMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
      return false;
    }
    return true;
  }

  handleScoreChange(questionnaireId, e) {
    var questionnaire = this.props.audit.questionnaire.filter(
      (q) => q.id === questionnaireId
    )[0];
    questionnaire.score = parseInt(e.target.value);
    this.props.onDataChange(this.props.audit);
  }

  isScoreEditable(score, auth) {
    if (!this.props.editMode) return false;

    if (auth.role === "admin") {
      return score.scoreType !== "computed";
    } else if (auth.role === "auditor") {
      return score.scoreType === "auditorManual";
    }
    return false;
  }

  render() {
    var audit = this.props.audit;
    var auth = this.props.auth;

    return (
      <Segment
        style={{
          paddingTop: 0,
          paddingBottom: 0,
          backgroundColor: this.props.backColor(this.props.color),
        }}
      >
        {audit.score.map((s, i) => {
          return (
            <Segment
              key={i}
              vertical
              style={{ paddingTop: 20, paddingBottom: 20, height: 80 }}
            >
              <span style={{ verticalAlign: "middle" }}>{s.name}</span>
              {this.isScoreEditable(s, auth) && (
                <Input
                  style={{
                    verticalAlign: "middle",
                    float: "right",
                    fontWeight: "bold",
                    width: 80,
                  }}
                  onChange={((k) => {
                    return (e) => this.handleScoreChange(k.questionnaireId, e);
                  })(s)}
                  defaultValue={s.finalScore || 0}
                />
              )}

              {!this.isScoreEditable(s, auth) && (
                <Label
                  style={{
                    verticalAlign: "middle",
                    float: "right",
                    backgroundColor: this.props.buttonColor(this.props.color),
                    color: this.props.fontColor(this.props.color),
                  }}
                >
                  <strong>{s.finalScore || 0}</strong>
                </Label>
              )}
            </Segment>
          );
        })}
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(QuestionnaireSummary)
);
