import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { isLoggedIn} from '../../util';
import { fetchYamahaAuditsAction, fetchYamahaExportAction, submitYamahaAudit, emailYamahaReport } from '../../actions/yamaha_actions';
import { Icon, Tab, Segment, Label } from 'semantic-ui-react';
import HelmetDetails from './HelmetDetails.jsx';
import YamahaQuestionnaireDetail from './YamahaQuestionnaireDetail.jsx';
import ManPower from './ManPower.jsx';
import MailPopup from '../MailPopup.jsx';

class YamahaAudits extends React.Component {
    static propTypes = {
        history: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            isMainPopupView: false,
            mailIds: "",
            auditId: null
        };
    }

    componentDidMount() {
        if (!isLoggedIn(this.props.auth)) {
            this.props.history.push(`/login`);
            return;
        }
        this.props.fetchYamahaAuditsAction(this.props.templateId, this.props.dealerId);
    }
    onDownloadExcelClick() {
        this.setState({isDlBtnDisabled: true})
        if(!this.state.isDlBtnDisabled) {
            if (this.props.yamaha.audits && this.props.yamaha.audits.length > 0) {
                var auditId = this.props.yamaha.audits[0]._id;
                this.props.fetchYamahaExportAction(auditId);
            }
        }
        setTimeout(() => {
            this.setState({isDlBtnDisabled: false})
        }, 90000)
    }

    onSubmit = () => {
        this.props.submitYamahaAudit(this.props.yamaha.audits[0]);
    }

    onDownloadPhotos=()=>{
        window.open(`#/images/` + this.props.yamaha.audits[0]._id);
    }

    handleMailReportClick = () => {
        this.setState({ isMailPopupView: !this.state.isMailPopupView });
    };

    handleDownloadAgentReportClick = (mailIds, auditId) => {
        if (!isLoggedIn(this.props.auth)) {
            this.props.history.push(`/login`);
            return;
        }

        this.props.emailYamahaReport(mailIds, auditId);
    };

    handleSendMailReportClick = mailIds => {
        this.setState({ isMailPopupView: !this.state.isMailPopupView, mailIds: mailIds });
        this.handleDownloadAgentReportClick(mailIds, this.props.yamaha.audits[0]._id);
    };

    render() {

        var auditId = '';
        var helmets = [];
        var manPowers = [];
        var audit= {};

        if (this.props.yamaha.audits && this.props.yamaha.audits.length > 0) {
            auditId = this.props.yamaha.audits[0]._id;
            helmets = this.props.yamaha.audits[0].helmets || [];
            manPowers = this.props.yamaha.audits[0].manPower || [];
            audit = this.props.yamaha.audits[0];
        }

        const panes = [
            {
                menuItem: 'Man Power',
                render: () => <Tab.Pane attached={false}><ManPower auditId={auditId} manPowerRecords={manPowers}></ManPower></Tab.Pane>,
            },
            {
                menuItem: 'Helmet Details',
                render: () => <Tab.Pane attached={false}><HelmetDetails auditId={auditId} helmets={helmets}></HelmetDetails></Tab.Pane>,
            }
        ]

        if (this.props.yamaha.audits && this.props.yamaha.audits.length > 0) {
            for (var q of audit.questionnaire) {
                q.auditId = audit._id;
                    panes.push({
                        menuItem: q.name,
                        render: ((k) => {
                            return () => {
                                return <YamahaQuestionnaireDetail key={k.auditId + k.id}  questionnaire={k} />;
                            }
                        })
                    });
            }
        }

        return (
            <div style={{ flexGrow: 1, display: 'flex', flexFlow: 'column', marginLeft: 15 }}>
                <Segment.Group horizontal style={{ marginTop: -7, cursor: 'pointer', position: 'absolute', right: 0 }}>
                    <Segment onClick={this.props.onGoBack}>
                        <Icon name="arrow" className="left large" color="brown" />
                    </Segment>
                    <Segment onClick={this.onDownloadExcelClick.bind(this)}>
                        <Icon name="download" className="left large" color="brown" />
                    </Segment>
                </Segment.Group>
                <div style={{marginTop: "10px", marginBottom: "10px"}}>
                    <Label color="green" style={{ cursor: "pointer" }} onClick={this.handleMailReportClick}>
                        <Icon name="mail" />Email Report
                    </Label>
                    <Label color="green" style={{ cursor: "pointer" }} onClick={this.onDownloadPhotos}>
                        <Icon name="picture" />Show Photos
                    </Label>
                    <Label color="green" style={{ cursor: "pointer" }} onClick={this.onSubmit}>
                        <Icon name="picture" />Submit Audit
                    </Label>
                    {/* <Label color="green" style={{ cursor: "pointer" }} onClick={this.props.onMailReport}>
                        <Icon name="mail" /> Mail Report
                    </Label> */}

                </div>

                {this.state.isMailPopupView ? (
                    <MailPopup
                        open={this.state.isMailPopupView}
                        mailIds={this.state.mailIds}
                        closePopup={this.handleMailReportClick.bind(this)}
                        sendMailAndClosePopup={this.handleSendMailReportClick}
                    />
                ) : null}

                <Tab defaultActiveIndex={1} menu={{ color: 'teal', inverted: true, attached: false, tabular: false }} panes={panes} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        yamaha: state.yamaha
    };
};
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            fetchYamahaAuditsAction: fetchYamahaAuditsAction,
            fetchYamahaExportAction: fetchYamahaExportAction,
            submitYamahaAudit: submitYamahaAudit,
            emailYamahaReport: emailYamahaReport

        },
        dispatch
    );
};


// YamahaAudits.PropTypes = {
//     dealers: PropTypes.array
// };

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(YamahaAudits)
);
