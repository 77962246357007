import {
    GODREJ_AUDITS_FETCH_SUCCESS,
    GODREJ_AUDITS_FETCH_FAILURE,
    GODREJ_PRODUCTS_FETCH_SUCCESS,
    GODREJ_PRODUCTS_FETCH_FAILURE,
    GODREJ_AUDIT_UPDATE_SUCCESS,
    GODREJ_AUDIT_UPDATE_FAILURE,
    GODREJ_REPORT_DOWNLOAD_SUCCESS,
    GODREJ_REPORT_DOWNLOAD_FAILURE
} from '../actions/types'


const INIT_STATE = {};

 function godrej (state = INIT_STATE,action) {
    state = Object.assign({}, state, {});

    switch (action.type) {
        case GODREJ_AUDITS_FETCH_SUCCESS:
            state.audits = action.payload.audits;
            state.users = action.payload.users;
            state.error = false;
            return state;

        case GODREJ_AUDITS_FETCH_FAILURE:
        case GODREJ_PRODUCTS_FETCH_FAILURE:
        case GODREJ_AUDIT_UPDATE_FAILURE:
        case GODREJ_REPORT_DOWNLOAD_FAILURE:
            state.error = true;
            return state;

        case GODREJ_PRODUCTS_FETCH_SUCCESS:
            state.products = action.payload;
            state.error = false;
            return state;

        case GODREJ_AUDIT_UPDATE_SUCCESS:
        case GODREJ_REPORT_DOWNLOAD_SUCCESS:
            state.error = false;
            return state;
        default: return state
    }
}

export default godrej