import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
    Divider,
    Select,
    Radio,
    Image,
} from "semantic-ui-react";
import ModalView from "../commonViews/ModalView";
import IconPicker from "../commonViews/IconPicker";

import {
    fetchHersheysVisibilityAuditAction,
    saveHersheysVisibilityAuditAction,
    downloadHersheysVisibilityAuditReportAction,
} from "../../actions/hersheys_actions";
import { fetchBikanoAuditDetails, saveBikanoDamagesAuditAction, downloadBikanoDamagesAuditReportAction } from '../../actions/bikano_actions'
import config from "../../config.js";
import moment from "moment";
import {
    getTextColor,
    getMainBackground,
    getMatchingTint,
    getFontColor,
} from "../../ThemeHandlers/theme";
import ConstantLabel from "../commonViews/ConstantLabel";
import TextInput from "../commonViews/TextInput";
import Box from '@mui/material/Box';
import { NotificationContainer, NotificationManager } from "react-notifications";
import CircularProgress from '@material-ui/core/CircularProgress';




const storageBaseUrl = config["storage_base_url"];

class BikanoAuditDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTc: null,
            showPopup: false,
            hersheyseditMode: false,
            hersheysselectedPhoto: "",
            showPhotoSlider: false,
            modalOpen: false,
            zoomImage: "",
            currentAudit: {},
            auditId: '',
            selectedStoreInfo: {},
            isLoading: false,
        };
    }

    componentDidMount() {
        this.setState({ selectedStoreInfo: this.props.selectedStore })
        this.props.fetchBikanoAuditDetails({ 
            masterId: this.props.customer._id,
            templateId: this.props.template._id,
            onSuccess: (res) => { this.setState({ auditId: res._id}); console.log("Audit Details : ", res) },
            onFailure: (err) => { console.log("Error - Audit Details : ", err) }
    })
        // console.log("Old : ", this.props.hersheys.audits)
        // console.log("New : ", this.props.customer)
    }

    handlePhotoClick = (e, p) => {
        this.setState({
            hersheysselectedPhoto: p,
            showPhotoSlider: true,
        });
    };

    photoSliderClose = (e) => {
        this.setState({
            showPhotoSlider: false,
        });
    };

    // Value Change
    bikanoChangeAnswer = (question, answerObject) => {
        var answers = "";
        let audit = this.props.bikano.audits;
        if (question.answerType == "text") {
            answers = answerObject.target.value;
        }
        if (question.answerType == "dropdown") {
            answers = answerObject;
        }
        audit.questions.map((eachQuestion) => {
            if (eachQuestion.id == question.id) {
                eachQuestion.answer = answers;
            }
        });
        this.setState({ currentAudit: audit });
    };

    EditAudit = () => {
        this.setState({ hersheyseditMode: true });
    };

    cancelEditAudit = () => {
        this.setState({ hersheyseditMode: false });
    };

    // Save Audit
    saveAudit = () => {
        console.log(this.state.currentAudit)
        this.props.saveBikanoDamagesAuditAction({audit: this.state.currentAudit,
            onSuccess: (res) =>  {
                NotificationManager.success(
                    "Audits Saved Successfully",
                    "SUCCESS MESSAGE",
                    3000,
                    true
                );
                this.setState({ hersheyseditMode: false });
            },
        onFailure: (err) => {
            NotificationManager.error(
                "Error Occured while Saving Audit",
                "ERROR MESSAGE",
                3000,
                true
            );
            this.setState({ hersheyseditMode: false });
        }
    });
    };

    onDownloadPhotos = (id) => {
        let open = `#/images/${id}?tag=damages`;
        if (this.state.currentAudit.masterInfo) {
            open = `${open}&awCode=${this.state.currentAudit.masterInfo.awCode}&awName=${this.state.currentAudit.masterInfo.awName}`;
        }
        window.open(open);
    };

    onDownloadReport = async(id, name, code) => {
        this.setState({ isLoading: true });
        await this.props.downloadBikanoDamagesAuditReportAction({ 
           auditId : id,
           storeName: name,
           storeCode: code,
           onSuccess: () => {
            this.setState({ isLoading: false });
           },
           onFailure: () => {
            this.setState({ isLoading: false });
           }})
    };
    

    zoomPics = (url) => {
        this.setState({ modalOpen: true, zoomImage: url });
    };
    closeEditUser = () => {
        this.setState({ modalOpen: false });
    };

    // File Download 
    downloadFile = (url, fileName) => {
        window.open(url, '_blank')
        }

    render() {
        return (
            <div
                style={{
                    marginRight: "100px",
                }}
            >
                {this.props.bikano.audits == null && (
                    <IconPicker
                        name="arrow left"
                        click={this.props.onClose}
                        styles={{
                            color: getTextColor(this.props.color),
                            float: "right",
                            cursor: "pointer",
                            marginTop: 35,
                            position: "absolute",
                            right: 10,
                        }}
                    />
                )}
                {this.props.bikano.audits != undefined && this.props.bikano.audits ? (
                    <div>
                        <IconPicker
                            name="arrow left"
                            click={this.props.onClose}
                            styles={{
                                color: getTextColor(this.props.color),
                                float: "right",
                                cursor: "pointer",
                                marginTop: 35,
                                position: "absolute",
                                right: 10,
                            }}
                        />
                        <HeaderView
                            store={this.props.bikano.audits}
                            EditMode={this.state.hersheyseditMode}
                            onDownloadReport={this.onDownloadReport}
                            onDownloadPhotos={this.onDownloadPhotos}
                            editAudit={this.EditAudit}
                            SaveAudit={this.saveAudit}
                            CancelEditAudit={this.cancelEditAudit}
                            color={this.props.color}
                            selectedStoreInfo={this.props.selectedStore}
                            loading={this.state.isLoading}
                        />
                        <ModalView
                            open={this.state.modalOpen}
                            close={this.closeEditUser}
                            size={'small'}
                            content={
                                <img
                                    src={storageBaseUrl + this.state.zoomImage}
                                    style={{
                                        width: "550px",
                                        height: "20%",
                                    }}
                                />
                            }
                            showSave={false}
                        />


                        {this.props.bikano.audits.questions != null &&
                            this.props.bikano.audits.questions.length !== 0 &&
                            this.props.bikano.audits.questions
                                .filter((eachQuestion) => {
                                    if (eachQuestion.hasOwnProperty("dependency")) {
                                        let question = null;
                                        let questionId =
                                            eachQuestion.dependency.questionId;
                                        let answerNeeded =
                                            eachQuestion.dependency.answer;
                                        this.props.bikano.audits.questions.map((hv) => {
                                            if (hv.id === questionId) {
                                                question = hv;
                                            }
                                        });
                                        if (question.answer != answerNeeded) {
                                            return false;
                                        }
                                        return true;
                                    } else {
                                        return true;
                                    }
                                })
                                .map((eachQuestions, index) => {
                                    return (
                                        <div key={index}>
                                            <div
                                                key={eachQuestions.id}
                                                style={{ paddingTop: "30px", paddingBottom: "20px" }}
                                            >
                                                <div
                                                    style={{
                                                        fontWeight: "bold",
                                                        fontSize: "18px",
                                                        width: "100%",
                                                        paddingBottom: "20px",
                                                    }}
                                                >
                                                    {index + 1 + ". "}{eachQuestions.question}
                                                </div>
                                                {eachQuestions.answerType === "text" && (
                                                    <TextInput value={eachQuestions.answer}
                                                        disabled={!this.state.hersheyseditMode}
                                                        onChangeTextInput={(e) => {
                                                            this.bikanoChangeAnswer(eachQuestions, e)
                                                        }} />

                                                )}
                                                {eachQuestions.answerType == "dropdown" && (
                                                    <div>
                                                        <Select
                                                            disabled={!this.state.hersheyseditMode}
                                                            options={eachQuestions.options.map(
                                                                (eachOption, e) => {
                                                                    return {
                                                                        value: eachOption.value,
                                                                        text: eachOption.label,
                                                                        key: e,
                                                                    };
                                                                }
                                                            )}
                                                            placeholder={"Select any option"}
                                                            value={eachQuestions.answer}
                                                            onChange={(e, { value }) =>
                                                                this.bikanoChangeAnswer(eachQuestions, value)
                                                            }
                                                        />
                                                        {eachQuestions.photos &&
                                                            eachQuestions.photos.length > 0 && (
                                                                <Image.Group
                                                                    size="mini"
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    {eachQuestions.photos.map((p, i) => {
                                                                        if (p.uploadedImageUrl != null) {
                                                                            return (
                                                                                <Image
                                                                                    key={i}
                                                                                    src={
                                                                                        storageBaseUrl + p.uploadedImageUrl
                                                                                    }
                                                                                    onClick={() =>
                                                                                        this.zoomPics(p.uploadedImageUrl)
                                                                                    }
                                                                                />
                                                                            );
                                                                        }
                                                                    })}
                                                                </Image.Group>
                                                            )}
                                                    </div>
                                                )}

                                                {eachQuestions.answerType == "photo" && (
                                                    <Image.Group
                                                        size="mini"
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        {eachQuestions.photos.map((p, i) => {
                                                            if (p.uploadedImageUrl != null) {
                                                                return (
                                                                    <Image
                                                                        key={i}
                                                                        src={storageBaseUrl + p.uploadedImageUrl}
                                                                        onClick={() =>
                                                                            this.zoomPics(p.uploadedImageUrl)
                                                                        }
                                                                    // onClick={(k => {
                                                                    // return e => this.handlePhotoClick(e, k);
                                                                    // })(p)}
                                                                    />
                                                                );
                                                            }
                                                        })}
                                                    </Image.Group>
                                                )}

                                                {eachQuestions.answerType == "document" && (
                                                    <>
                                                  {eachQuestions.documents !== undefined && eachQuestions.documents.length > 0 ?  eachQuestions.documents.map((doc) => {
                                                      return(
                                                        <>
                                                         <ul style={{ marginLeft: 20, fontWeight: 'bold', color: "#2185d0" }}>
                                                             <li>File Name : {doc.fileName}</li>
                                                         </ul>
                                                        <Box sx={{ display: 'flex', justifyContent: "center", marginBottom: 3 }}>
                                                       <IconPicker
                                                       name="download"
                                                       size={'huge'}
                                                       styles={{
                                                           color: 'blue',
                                                           cursor: "pointer",
                                                           marginTop: 10
                                                        }}
                                                        click={(e) => {
                                                            if(doc.isUploaded == true && doc.uploadedImageUrl.length > 0) {
                                                                this.downloadFile(storageBaseUrl + doc.uploadedImageUrl, doc.fileName)
                                                            } else {
                                                                alert("Download not available");
                                                            }
                                                        }}
                                                        />
                                                       </Box>
                                                        </>
                                                      )
                                                  })
                                                  : ""
                                                  
                                                }
                                                </>
                                                )}

                                            </div>
                                            <Divider />
                                        </div>
                                    );
                                })}
                    </div>
                )
            :
            <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', height: "90vh", width: '80vw', color: '#2185d0'}}>
                <h1>Audit Not Available</h1>
            </div>
            }
            </div>
        );
    }
}

const HeaderView = function (props) {
    let {
        store,
        EditMode,
        onDownloadReport,
        editAudit,
        SaveAudit,
        CancelEditAudit,
        selectedStoreInfo,
        loading
    } = props;
    return (
        <div>
            <h1 style={{ color: getTextColor(props.color) }}>{store.auditName}</h1>
            <div style={{ color: getTextColor(props.color) }}>
                By {store.auditorId != null && store.auditorId.displayName} on{" "}
                {moment(store.createdAt).format("DD-MM-YYYY  HH:MM:SS")}
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    width: 500,
                    justifyContent: "flex-start",
                }}
            >
                {!EditMode && (
                    <ConstantLabel
                        title={"Edit Audit"}
                        size={"medium"}
                        onClick={editAudit}
                        styles={{
                            cursor: "pointer",
                            backgroundColor: getMainBackground(props.color),
                            color: getFontColor(props.color),
                        }}
                        iconName={'edit'}
                    />

                )}

                {EditMode && (
                    <div>
                        <ConstantLabel
                            title={"Save audit"}
                            size={"medium"}
                            onClick={SaveAudit}
                            styles={{
                                cursor: "pointer",
                                marginRight: "5px",
                                backgroundColor: getMainBackground(props.color),
                                color: getFontColor(props.color),
                            }}
                            iconName={'save'}
                        />
                        <ConstantLabel
                            title={"Cancel"}
                            size={"medium"}
                            onClick={CancelEditAudit}
                            styles={{
                                cursor: "pointer", marginLeft: "5px"
                            }}
                            iconName={'cancel'}
                        />


                    </div>
                )}

                {!loading ? 
                <ConstantLabel
                 title={"Download Report"}
                size={"medium"}
                onClick={() => {
                    onDownloadReport(store._id, selectedStoreInfo.productName, selectedStoreInfo.productCode);
                }}
                styles={{
                    cursor: "pointer",
                    backgroundColor: getMainBackground(props.color),
                    color: getFontColor(props.color),
                }}
                iconName={'table'}
               />
               :
            <CircularProgress size={25}/> }
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        hersheys: state.hersheys,
        bikano: state.bikano
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            fetchHersheysVisibilityAuditAction,
            saveHersheysVisibilityAuditAction,
            downloadHersheysVisibilityAuditReportAction,
            fetchBikanoAuditDetails,
            saveBikanoDamagesAuditAction,
            downloadBikanoDamagesAuditReportAction
        },
        dispatch
    );
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(BikanoAuditDetails)
);
