import axios from "axios";
import * as FileSaver from "file-saver";
import * as types from "./types";

export const fetchGrbTemplatesAction = ({ year, onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + `/auditor/v1/grb/getTemplates?year=${year}`)
      .then(function (response) {
        dispatch({
          type: types.FETCH_DAMAGES_TEMPLATE_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_DAMAGES_TEMPLATE_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const fetchGrbTemplateAuditsAction = ({
  templateId,
  onSuccess,
  onFailure,
}) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/grb/getAudits/" + templateId)
      .then(function (response) {
        dispatch({
          type: types.FETCH_DAMAGES_AUDITS_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_DAMAGES_AUDITS_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const saveGrbAuditAction = (audit) => {
  return function (dispatch) {
    axios
      .post(types.API_URL + "/auditor/v1/grb/submit", audit)
      .then(function (response) {
        dispatch({
          type: types.SAVE_DAMAGES_AUDIT_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.SAVE_DAMAGES_AUDIT_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const downloadGrbReportAction = (templateId) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL +
          "/auditor/v1/grb/report/export?templateId=" +
          templateId,
        {
          responseType: "blob",
        }
      )
      .then(function (response) {
        FileSaver.saveAs(response.data, "Audit-Report.xlsx");
        dispatch({
          type: types.DOWNLOAD_REPORT_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        console.log(error);
        dispatch({
          type: types.DOWNLOAD_REPORT_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};
export const downloadGrbAuditReportAction = (auditId, templateId) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL +
          "/auditor/v1/grb/report/export/" +
          auditId +
          "?templateId=" +
          templateId,
        {
          responseType: "blob",
        }
      )
      .then(function (response) {
        FileSaver.saveAs(response.data, "Audit-Report.pdf");
        dispatch({
          type: types.DOWNLOAD_DAMAGES_AUDIT_REPORT_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        console.log(error);
        dispatch({
          type: types.DOWNLOAD_DAMAGES_AUDIT_REPORT_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};
