import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { fetchImagesOfAudit } from "../actions/audit_actions";
import { Segment, Label, Divider } from "semantic-ui-react";
import { Header, Image } from "semantic-ui-react";
import TextImage from "../images/textLogo.png";
import matrixImage from "../images/matrix-logo.png";
import {
  getMainBackground,
  getMatchingTint,
  getFontColor,
  getTextColor,
} from "../ThemeHandlers/theme";

class ShowImages extends React.Component {
  constructor(props) {
    super(props);
    let filter = new URLSearchParams(props.location.search);
    this.state = {
      isDamagesView: filter.get("tag") === "damages" ? true : false,
      isFurlencoVeiw: filter.get("tag") === "furlenco" ? true : false,
      awCode: filter.get("awCode"),
      awName: filter.get("awName"),
      tag: filter.get("tag"),
      color: "",
    };
  }
  componentDidMount() {
    if (this.props.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
    this.props.fetchImages(this.props.match.params.auditId, this.state.tag);
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth.color !== prevProps.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
  }

  handlePhotoClick = (e, p) => {
    this.setState({
      selectedPhoto: p,
      showPhotoSlider: true,
    });
  };

  photoSliderClose = (e) => {
    this.setState({
      showPhotoSlider: false,
    });
  };

  getQuestions = () => {
    var questions = [];
    if (this.props.questions) {
      for (var eachQuestion of this.props.questions) {
        if (this.state.isDamagesView) {
          if (eachQuestion.answerType === "photo") {
            questions.push(eachQuestion);
          }
        } else if (this.state.isFurlencoVeiw) {
          questions = this.props.questions.filter(n => n)

        }
        else {

          questions.push(eachQuestion);
        }
      }
    }

    return questions;
  };

  getName = () => {
    return this.props.name;
  };

  render() {
    var questions = this.getQuestions();
    console.log(questions, "quessssss")
    return (
      <div style={{ height: "100%" }}>
        <Segment
          raised
          style={{ backgroundColor: "#wefwef(", height: 60, marginBottom: 0 }}
        >
          <div style={{ display: "inline-block" }}>
            <img
              style={{
                height: 120,
                marginTop: -40,
                float: "left",
              }}
              src={TextImage}
            />

            <img
              style={{
                height: 60,
                marginTop: -13,
                float: "left",
              }}
              src={matrixImage}
            />
          </div>
        </Segment>
        <Segment
          style={{
            marginTop: 0,
            backgroundColor: getMatchingTint(this.state.color),
          }}
        >
          <h1 style={{ color: getTextColor(this.state.color) }}>
            {this.getName()}
          </h1>
          {this.state.isDamagesView && (
            <Header as="h3" block>
              <div> AW Code: {this.state.awCode}</div>
              <div> AW Name: {this.state.awName}</div>
            </Header>
          )}
          {questions.map((ques, i) => {
            return (
              <div key={i} style={{ marginBottom: "10px" }}>
                <span
                  style={{
                    lineHeight: 1.5,
                    color: getTextColor(this.state.color),
                    fontSize: 17,
                    display: "block",
                    marginBottom: 10,
                  }}
                >
                  {ques.question}
                </span>
                {ques.photos.length == 0 && (
                  <Label color="red">No photos found!</Label>
                )}
                {ques.photos.length > 0 && (
                  <Image.Group size="medium" style={{ cursor: "pointer" }}>
                    {ques.photos.map((p, s) => {
                      return (
                        <Image
                          key={s}
                          src={p}
                          onClick={(k) => {
                            return (e) => this.handlePhotoClick(e, k);
                          }}
                        />
                      );
                    })}
                  </Image.Group>
                )}
                <Divider />
              </div>
            );
          })}
        </Segment>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    questions: state.audits.images,
    name: state.audits.images_name,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchImages: fetchImagesOfAudit,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShowImages)
);
