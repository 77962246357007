import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as _ from "underscore";
import { Loader, Dimmer, Grid, Image } from "semantic-ui-react";
import IconPicker from '../commonViews/IconPicker.jsx'
import ConstantLabel from "../commonViews/ConstantLabel.jsx";
import TableView from "../commonViews/TableView.jsx";
import MrRetailAuditsDetails from "./MrRetailAuditsDetails.jsx";
import {
  fetchMrRetailAuditAction,
  fetchMrRetailPhotoAction,
  downloadReportAction,
  clearAudits,
  mrRetailDownloadPhotos,
  downloadReportPhotosAction,
  downloadSignoffAction,
} from "../../actions/mrRetail_action";
import config from "../../config.js";
import MailPopup from "../MailPopup.jsx";
import {
  getTextColor,
  getMainBackground,
  getMatchingTint,
  getFontColor,
} from "../../ThemeHandlers/theme";
import Metrics from "../commonViews/Metrics.jsx";
import DropDownPicker from "../commonViews/DropDownPicker.jsx";
const storageBaseUrl = config["storage_base_url"];

function removeDuplicates(originalArray, prop) {
  var newArray = [];
  var lookupObject = {};

  for (var i in originalArray) {
    if (originalArray[i][prop]) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
  }

  for (i in lookupObject) {
    newArray.push(lookupObject[i]);
  }

  return newArray;
}

export class MrRetailAudits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMrRetailAuditsView: true,
      selectedAudits: {},
      allBrands: [],
      allCategory: [],
      storeValue: "",
      categoryValue: "",
      brandValue: "",
      MailPopupview: false,
      auditedAudits: [],
      downloadIcon: false,
      lodaingView: false,
    };
  }

  handleTableViewAuditClick = (audit) => {
    this.setState({ selectedAudits: audit, isMrRetailAuditsView: false });
  };

  static getDerivedStateFromProps(nextProps, nextState) {
    let category = [];

    if (nextProps.mrRetail.audits) {
      if (nextState.categoryValue === "" && nextState.brandValue === "") {
        nextProps.mrRetail.audits.forEach((audit) => {
          category.push({
            text: audit.category,
            value: audit.category,
          });
        });
        return {
          auditedAudits: nextProps.mrRetail.audits,
          // allBrands: brands,
          allCategory: removeDuplicates(category, "text"),
        };
      } else if (
        nextState.categoryValue !== "" &&
        nextState.brandValue === ""
      ) {
        let filterByCategory = [];
        let brand = [];
        if (nextProps.mrRetail) {
          nextProps.mrRetail.audits.forEach((data) => {
            if (data.category === nextState.categoryValue) {
              brand.push({
                text: data.brand,
                value: data.brand,
              });
              filterByCategory.push(data);
            }
          });

          return { auditedAudits: filterByCategory, allBrands: brand };
        }
      } else if (
        nextState.brandValue !== "" &&
        nextState.categoryValue !== ""
      ) {
        let filterByCategory = [];
        if (nextProps.mrRetail) {
          nextProps.mrRetail.audits.forEach((data) => {
            if (data.category === nextState.categoryValue) {
              if (data.brand === nextState.brandValue) {
                filterByCategory.push(data);
              }
            }
          });

          return { auditedAudits: filterByCategory };
        }
      }
    }
  }

  componentWillUnmount() {
    return {
      auditedAudits: [],
    };
  }

  onStoreSelected = (value) => {
    this.props.fetchMrRetailAuditAction(this.props.template._id, value);
    this.props.fetchMrRetailPhotoAction(this.props.template._id, value);
    this.setState({
      storeValue: value,
      categoryValue: "",
      brandValue: "",
    });
  };

  onCategoryChange = (value) => {
    this.setState({
      categoryValue: value,
      brandValue: "",
    });
  };

  onBrandChange = (value) => {
    this.setState({
      brandValue: value,
    });
  };
  onClose = () => {
    this.props.onClose();
    this.props.clearAudits();
  };
  handleCloseClick = () => {
    this.setState({ isMrRetailAuditsView: true });
  };

  download = () => {
    this.setState({
      MailPopupview: true,
    });
    // this.props.downloadReportPhotosAction(this.props.template._id);
  };
  handleMail = () => {
    this.setState({
      MailPopupview: false,
    });
  };
  handleMailchange = (mail) => {
    var templateId = this.props.template._id;
    this.props.downloadReportPhotosAction(templateId, mail);
  };
  handleShow = () => this.setState({ active: true });
  handleHide = () => this.setState({ active: false });
  downloadPhotos = () => {
    this.props.mrRetailDownloadPhotos({
      templateId: this.props.template._id,
      storeId: this.state.storeValue,
      onSuccess: () => {
        this.setState({
          lodaingView: false,
        });
      },
      onFailure: () => {
        this.setState({
          lodaingView: false,
        });
      },
    });
    this.setState({
      lodaingView: true,
    });
  };

  downloadSignoff = () => {
    if (this.state.storeValue !== "") {
      let templateId = this.props.template._id;
      let storeId = this.state.storeValue;
      this.props.downloadSignoffAction(templateId, storeId);
    } else {
      alert("Please Select a Store to download SignOff");
    }
  };

  render() {
    var Data = {};
    let store = [];
    var col = [
      {
        title: "Template Type",
        field: "templateType",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.templateType}
            </span>
          );
        },
      },
      {
        title: "Category",
        field: "category",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.category}
            </span>
          );
        },
      },
      {
        title: "Brand",
        field: "brand",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.brand}
            </span>
          );
        },
      },
      {
        title: "SOS",
        field: "sos",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.sos}
            </span>
          );
        },
      },
    ];

    if (this.props.mrRetail.store !== undefined) {
      this.props.mrRetail.store.forEach((storeList) => {
        store.push({
          text: storeList.storeCode,
          value: storeList._id,
        });
      });
    }
    const { active } = this.state;
    const content = (
      <div>
        {this.props.mrRetail.storePhoto &&
          this.props.mrRetail.storePhoto.length && (
            <h5 style={{ color: "yellow" }}>
              {this.props.mrRetail.storePhoto[0].photoTakenDate}
            </h5>
          )}
        {this.state.lodaingView === true ? (
          <Loader />
        ) : (
          <IconPicker
            size={"big"}
            color={"teal"}
            name={"cloud download"}
            click={this.downloadPhotos}
            styles={{ cursor: "pointer" }}
          />
        )}
      </div>
    );
    return (
      <div style={{ flexGrow: 1, display: "flex", flexFlow: "column" }}>
        {this.state.isMrRetailAuditsView === true ? (
          <div>
            <div style={{ display: "inline-block" }}>
              <IconPicker
                name="arrow left"
                click={this.onClose}
                styles={{
                  color: getTextColor(this.props.color),
                  float: "right",
                  cursor: "pointer",
                  marginTop: 30,
                  display: "inline-block",
                  // top:"15",
                  position: "absolute",
                  right: 20,
                }}
              />

              <h1
                style={{
                  display: "inline-block",
                  color: getTextColor(this.props.color),
                }}
              >
                MR Retail Audits
              </h1>

              <ConstantLabel
                title={"Mail Photos"}
                size={"large"}
                onClick={this.download}
                styles={{
                  display: "inline-block",
                  marginLeft: 30,
                  cursor: "pointer",
                  backgroundColor: getMainBackground(this.props.color),
                  color: getFontColor(this.props.color),
                }}
                iconName={"camera"}
              />

              <ConstantLabel
                title={"Download Signoff"}
                size={"large"}
                onClick={this.downloadSignoff}
                styles={{
                  display: "inline-block",
                  marginLeft: 30,
                  cursor: "pointer",
                  backgroundColor: getMainBackground(this.props.color),
                  color: getFontColor(this.props.color),
                }}
                iconName={"download"}
              />

              {this.state.MailPopupview && (
                <MailPopup
                  open={this.state.MailPopupview}
                  closePopup={this.handleMail.bind(this)}
                  sendMailAndClosePopup={this.handleMailchange}
                  getColor={getMainBackground}
                  color={this.props.color}
                  getCellColor={getMatchingTint}
                />
              )}
            </div>
            {this.props.mrRetail.metrics && (
              <div>
                <br />
                <Metrics
                  metrics={this.props.mrRetail.metrics}
                  color={this.props.color}
                />
              </div>
            )}

            <Grid
              style={{
                marginBottom: 10,
                // marginRight: 10,
              }}
            >
              <Grid.Row columns={3}>
                <Grid.Column>
                  <DropDownPicker
                    placeholder={"Select Store"}
                    styles={{ zIndex: 100 }}
                    value={this.state.storeValue}
                    options={store}
                    onChangeDropDown={(data) => {
                      this.onStoreSelected(data);
                    }}
                  />

                </Grid.Column>
                <Grid.Column>
                  <DropDownPicker
                    placeholder={"Select Category"}
                    styles={{ zIndex: 100 }}
                    value={this.state.categoryValue}
                    options={this.state.allCategory}
                    onChangeDropDown={(data) => {
                      this.onCategoryChange(data);
                    }}
                  />


                </Grid.Column>
                <Grid.Column>
                  <DropDownPicker
                    placeholder={"Select Brand"}
                    styles={{ zIndex: 100 }}
                    value={this.state.brandValue}
                    options={this.state.allBrands}
                    onChangeDropDown={(data) => {
                      this.onBrandChange(data);
                    }}
                  />


                </Grid.Column>
              </Grid.Row>
            </Grid>

            {this.props.mrRetail.storePhoto &&
              this.props.mrRetail.storePhoto.length > 0 &&
              this.props.mrRetail.storePhoto.map((e) => {
                if (e.photoUrl) {
                  return (
                    <div style={{ justifyContent: "space-around" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Dimmer.Dimmable
                          as={Image}
                          dimmed={active}
                          dimmer={{ active, content }}
                          onMouseEnter={this.handleShow}
                          onMouseLeave={this.handleHide}
                          size="small"
                          src={storageBaseUrl + e.photoUrl}
                        />
                      </div>
                      {e.signature && (
                        <div>
                          <Image.Group
                            size="medium"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Image
                              // size="large"
                              src={e.signature}
                            />
                          </Image.Group>
                        </div>
                      )}
                    </div>
                  );
                }
              })}

            <br />
            <div style={{ display: "flex", flexGrow: 1, flexFlow: "column" }}>
              <div>
                <TableView
                  columns={col}
                  data={this.state.auditedAudits}
                  selection={false}
                />
              </div>
            </div>
          </div>
        ) : (
          <MrRetailAuditsDetails
            audit={this.state.selectedAudits}
            goBack={this.handleCloseClick}
            color={this.props.color}
          />
        )}
      </div>
    );
  }
}
function AuditTableCell(props) {
  function onClick() {
    props.onClick(props.row);
  }
  return (
    <div style={props.style} onClick={onClick}>
      {props.text}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    mrRetail: state.mrRetail,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchMrRetailAuditAction: fetchMrRetailAuditAction,
      fetchMrRetailPhotoAction: fetchMrRetailPhotoAction,
      downloadReportAction: downloadReportAction,
      clearAudits: clearAudits,
      mrRetailDownloadPhotos: mrRetailDownloadPhotos,
      downloadReportPhotosAction: downloadReportPhotosAction,
      downloadSignoffAction: downloadSignoffAction,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MrRetailAudits)
);
