import React from 'react';
import { Modal, TextArea } from 'semantic-ui-react'
import ButtonHandler from './commonViews/ButtonHandler';

class NoteModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            notes: props.question.notes
        };
    }

    show = () => {
        if (!this.props.editMode)
            return;

        this.setState({ open: true });
    }

    saveAndClose = () => {
        this.props.question.notes = this.state.notes;
        this.setState({ notes: this.state.notes, open: false });
    }

    close = () => {
        this.setState({ open: false });
    }

    handleNotesChange = (e) => {
        this.setState({ notes: e.target.value });
    }

    render() {
        const { open } = this.state

        return (
            <div>
                <ButtonHandler isIcon={true} iconName={'write'} size={'tiny'} click={this.show} />
                Notes: <span style={{ color: "#808080" }}>{this.props.question.notes}</span>

                <Modal size="small" dimmer="blurring" open={open} onClose={this.close}>
                    <Modal.Header>Edit Note</Modal.Header>
                    <Modal.Content>
                        <TextArea placeholder="Enter notes" onChange={this.handleNotesChange} style={{ width: 300, height: 200 }}>
                            {this.props.question.notes}
                        </TextArea>
                    </Modal.Content>
                    <Modal.Actions>
                        <div style={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: "flex-end" }}>
                            <ButtonHandler title={"Nope"} color={"black"} click={this.close} />
                            <ButtonHandler
                                title={"Send"}
                                click={this.saveAndClose}
                                color={'blue'}
                                isIcon={true}
                                iconName={"checkmark"}
                            />
                        </div>

                    </Modal.Actions>
                </Modal>
            </div>
        )
    }
}

export default NoteModal

