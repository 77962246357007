import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
    Divider,
    Select,
    Radio,
    Image,
} from "semantic-ui-react";
import ModalView from "../commonViews/ModalView";
import IconPicker from "../commonViews/IconPicker";


import { fetchPGDAuditAction, savePGDAuditAction, downloadPGDAuditReportAction } from '../../actions/pgd_actions'
import config from "../../config.js";
import moment from "moment";
import {
    getTextColor,
    getMainBackground,
    getMatchingTint,
    getFontColor,
} from "../../ThemeHandlers/theme";
import ConstantLabel from "../commonViews/ConstantLabel";
import TextInput from "../commonViews/TextInput";
import { NotificationContainer, NotificationManager } from "react-notifications";


const storageBaseUrl = config["storage_base_url"];
class Audits extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTc: null,
            showPopup: false,
            hersheyseditMode: false,
            hersheysselectedPhoto: "",
            showPhotoSlider: false,
            modalOpen: false,
            zoomImage: "",
            currentAudit: {},
            changesMade: false,
            cityList: []
        };
    }

    componentDidMount() {
        this.props.fetchPGDAuditAction(this.props.customer._id);
        let data = []
        if (this.props.pgd.doctorsList !== undefined) {
            console.log(this.props.pgd.doctorsList, 'list')
            this.props.pgd.doctorsList.map((d) => {
                data.push({
                    label: d.name,
                    value: d.name
                })
            })
        }
        this.setState({ cityList: data })
    }

    handlePhotoClick = (e, p) => {
        this.setState({
            hersheysselectedPhoto: p,
            showPhotoSlider: true,
        });
    };

    photoSliderClose = (e) => {
        this.setState({
            showPhotoSlider: false,
        });
    };

    pgdChangeAnswer = (question, answerObject) => {
        console.log(question, 'ques')
        console.log(answerObject, 'answerObject')
        var answers = "";
        let audit = this.props.pgd.audits;
        if (question.answerType == "text") {
            answers = answerObject.target.value;
        }
        if (question.answerType == "dropdown") {
            answers = answerObject;
        }
        audit.questions.map((eachQuestion) => {
            if (eachQuestion.id == question.id) {
                eachQuestion.answer = answers;
            }
        });
        console.log(audit, 'audits')
        this.setState({ currentAudit: audit, changesMade: true });
    };

    EditAudit = () => {
        this.setState({ hersheyseditMode: true });
    };

    cancelEditAudit = () => {
        this.setState({ hersheyseditMode: false });
    };

    // Save Audit Changes
    saveAudit = () => {
        if (this.state.changesMade) {
            this.props.savePGDAuditAction({
                audit: this.state.currentAudit,
                onSuccess: (res) => {
                    console.log(res)
                    NotificationManager.success(
                        "Audit Successfully Updated",
                        "SUCCESS MESSAGE",
                        3000,
                        true
                    );
                },
                onFailure: (err) => {
                    console.log(err)
                    NotificationManager.error(
                        "Unable to save the Audit",
                        "ERROR MESSAGE",
                        3000,
                        true
                    );
                }
            });
        } else {
            NotificationManager.info(
                "No Changes Has been done",
                "INFO MESSAGE",
                3000,
                true
            );
        }
        this.setState({ hersheyseditMode: false });
    };

    onDownloadPhotos = (id) => {
        let open = `#/images/${id}?tag=damages`;
        if (this.state.currentAudit.masterInfo) {
            open = `${open}&awCode=${this.state.currentAudit.masterInfo.awCode}&awName=${this.state.currentAudit.masterInfo.awName}`;
        }
        window.open(open);
    };

    onDownloadReport = (id) => {
        this.props.downloadPGDAuditReportAction(id);
    };
    zoomPics = (url) => {
        this.setState({ modalOpen: true, zoomImage: url });
    };
    closeEditUser = () => {
        // this.props.clearUserAction();
        this.setState({ modalOpen: false });
    };

    render() {
        return (
            <div
                style={{
                    marginRight: "100px",
                }}
            >
                {this.props.pgd.audits == null && (
                    <IconPicker
                        name="arrow left"
                        click={this.props.onClose}
                        styles={{
                            color: getTextColor(this.props.color),
                            float: "right",
                            cursor: "pointer",
                            marginTop: 35,
                            position: "absolute",
                            right: 10,
                        }}
                    />
                )}
                {this.props.pgd.audits != undefined && this.props.pgd.audits && (
                    <div>
                        <IconPicker
                            name="arrow left"
                            click={this.props.onClose}
                            styles={{
                                color: getTextColor(this.props.color),
                                float: "right",
                                cursor: "pointer",
                                marginTop: 35,
                                position: "absolute",
                                right: 10,
                            }}
                        />
                        <HeaderView
                            customer={this.props.pgd.audits}
                            EditMode={this.state.hersheyseditMode}
                            onDownloadReport={this.onDownloadReport}
                            onDownloadPhotos={this.onDownloadPhotos}
                            editAudit={this.EditAudit}
                            SaveAudit={this.saveAudit}
                            CancelEditAudit={this.cancelEditAudit}
                            color={this.props.color}
                        />
                        <ModalView
                            open={this.state.modalOpen}
                            close={this.closeEditUser}
                            size={'small'}
                            content={
                                <img
                                    src={storageBaseUrl + this.state.zoomImage}
                                    style={{
                                        width: "550px",
                                        height: "20%",
                                    }}
                                />
                            }
                            showSave={false}
                        />


                        {this.props.pgd.audits.questions != null &&
                            this.props.pgd.audits.questions.length !== 0 &&
                            this.props.pgd.audits.questions
                                .filter((eachQuestion) => {
                                    if (eachQuestion.hasOwnProperty("dependency")) {
                                        let question = null;
                                        let questionId =
                                            eachQuestion.dependency.questionId;
                                        let answerNeeded =
                                            eachQuestion.dependency.answer;
                                        this.props.pgd.audits.questions.map((hv) => {
                                            if (hv.id === questionId) {
                                                question = hv;
                                            }
                                        });
                                        if (question.answer != answerNeeded) {
                                            return false;
                                        }
                                        return true;
                                    } else {
                                        return true;
                                    }
                                })
                                .map((eachQuestions, index) => {
                                    return (
                                        <div key={index}>
                                            <div
                                                key={eachQuestions.id}
                                                style={{ paddingTop: "30px", paddingBottom: "20px" }}
                                            >
                                                <div
                                                    style={{
                                                        fontWeight: "bold",
                                                        fontSize: "18px",
                                                        width: "100%",
                                                        paddingBottom: "20px",
                                                    }}
                                                >
                                                    {eachQuestions.question}
                                                </div>
                                                {eachQuestions.answerType === "text" && (
                                                    <TextInput value={eachQuestions.answer}
                                                        disabled={!this.state.hersheyseditMode}
                                                        onChangeTextInput={(e) => {
                                                            this.pgdChangeAnswer(eachQuestions, e)
                                                        }} />

                                                )}
                                                {eachQuestions.answerType == "dropdown" && !eachQuestions.additional && (
                                                    <div>
                                                        <Select
                                                            disabled={!this.state.hersheyseditMode}
                                                            options={eachQuestions.options.map(
                                                                (eachOption, e) => {
                                                                    return {
                                                                        value: eachOption.value,
                                                                        text: eachOption.label,
                                                                        key: e,
                                                                    };
                                                                }
                                                            )}
                                                            placeholder={"Select any option"}
                                                            value={eachQuestions.answer}
                                                            onChange={(e, { value }) =>
                                                                this.pgdChangeAnswer(eachQuestions, value)
                                                            }
                                                        />
                                                        {eachQuestions.photos &&
                                                            eachQuestions.photos.length > 0 && (
                                                                <Image.Group
                                                                    size="mini"
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    {eachQuestions.photos.map((p, i) => {
                                                                        if (p.uploadedImageUrl != null) {
                                                                            return (
                                                                                <Image
                                                                                    key={i}
                                                                                    src={
                                                                                        storageBaseUrl + p.uploadedImageUrl
                                                                                    }
                                                                                    onClick={() =>
                                                                                        this.zoomPics(p.uploadedImageUrl)
                                                                                    }
                                                                                />
                                                                            );
                                                                        }
                                                                    })}
                                                                </Image.Group>
                                                            )}
                                                    </div>
                                                )}

                                                {eachQuestions.answerType == "dropdown" && eachQuestions.additional && eachQuestions.additional === true && (
                                                    <div>
                                                        <Select
                                                            disabled={!this.state.hersheyseditMode}
                                                            search
                                                            options={this.state.cityList.map(
                                                                (eachOption, e) => {
                                                                    return {
                                                                        value: eachOption.value,
                                                                        text: eachOption.label,
                                                                        key: e,
                                                                    };
                                                                }
                                                            )}
                                                            placeholder={"Select any option"}
                                                            value={eachQuestions.answer}
                                                            onChange={(e, { value }) =>
                                                                this.pgdChangeAnswer(eachQuestions, value)
                                                            }
                                                        />

                                                    </div>
                                                )}

                                                {eachQuestions.answerType == "photo" && eachQuestions.photos &&
                                                    eachQuestions.photos.length > 0 && (
                                                        <Image.Group
                                                            size="mini"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            {eachQuestions.photos.map((p, i) => {
                                                                if (p.uploadedImageUrl != null) {
                                                                    return (
                                                                        <Image
                                                                            key={i}
                                                                            src={storageBaseUrl + p.uploadedImageUrl}
                                                                            onClick={() =>
                                                                                this.zoomPics(p.uploadedImageUrl)
                                                                            }
                                                                        // onClick={(k => {
                                                                        // return e => this.handlePhotoClick(e, k);
                                                                        // })(p)}
                                                                        />
                                                                    );
                                                                }
                                                            })}
                                                        </Image.Group>
                                                    )}
                                            </div>
                                            <Divider />
                                        </div>
                                    );
                                })}
                    </div>
                )}
            </div>
        );
    }
}

const HeaderView = function (props) {
    let {
        customer,
        EditMode,
        onDownloadReport,
        editAudit,
        SaveAudit,
        CancelEditAudit,
    } = props;
    return (
        <div>
            <h1 style={{ color: getTextColor(props.color) }}>{customer.auditName}</h1>
            <div style={{ color: getTextColor(props.color) }}>
                By {customer.auditorId != null && customer.auditorId.displayName} on{" "}
                {moment(customer.createdAt).format("DD-MM-YYYY  HH:MM:SS")}
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    width: 500,
                    justifyContent: "flex-start",
                }}
            >
                {!EditMode && (
                    <ConstantLabel
                        title={"Edit Audit"}
                        size={"medium"}
                        onClick={editAudit}
                        styles={{
                            cursor: "pointer",
                            backgroundColor: getMainBackground(props.color),
                            color: getFontColor(props.color),
                        }}
                        iconName={'edit'}
                    />

                )}

                {EditMode && (
                    <div>
                        <ConstantLabel
                            title={"Save audit"}
                            size={"medium"}
                            onClick={SaveAudit}
                            styles={{
                                cursor: "pointer",
                                marginRight: "5px",
                                backgroundColor: getMainBackground(props.color),
                                color: getFontColor(props.color),
                            }}
                            iconName={'save'}
                        />
                        <ConstantLabel
                            title={"Cancel"}
                            size={"medium"}
                            onClick={CancelEditAudit}
                            styles={{
                                cursor: "pointer", marginLeft: "5px"
                            }}
                            iconName={'cancel'}
                        />


                    </div>
                )}

                <ConstantLabel
                    title={"Download Report"}
                    size={"medium"}
                    onClick={() => {
                        onDownloadReport(customer._id);
                    }}
                    styles={{
                        cursor: "pointer",
                        backgroundColor: getMainBackground(props.color),
                        color: getFontColor(props.color),
                    }}
                    iconName={'table'}
                />


            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        pgd: state.pgd,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            fetchPGDAuditAction,
            savePGDAuditAction,
            downloadPGDAuditReportAction
        },
        dispatch
    );
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Audits)
);
