import {
  FETCH_BIKANO_TEMPLATE_SUCCESS_ACTION,
  FETCH_BIKANO_TEMPLATE_FAILURE_ACTION,
  FETCH_BIKANO_STORE_SUCCESS_ACTION,
  FETCH_BIKANO_STORE_FAILURE_ACTION,
  FETCH_BIKANO_AUDIT_SUCCESS_ACTION,
  FETCH_BIKANO_AUDIT_FAILURE_ACTION,
  SAVE_BIKANO_AUDIT_SUCCESS_ACTION,
  SAVE_BIKANO_AUDIT_FAILURE_ACTION,
  DOWNLOAD_BIKANO_REPORT_SUCCESS_ACTION,
  DOWNLOAD_BIKANO_REPORT_FAILURE_ACTION,
} from "../actions/types";

const BIKANO_STATE = {};

function bikano(state = BIKANO_STATE, action) {
  state = Object.assign({}, state, {});
  switch (action.type) {
    case FETCH_BIKANO_TEMPLATE_SUCCESS_ACTION:
      state.templates = action.payload.templateList;
      state.bikanoTemplateError = false;
      return state;
    case FETCH_BIKANO_TEMPLATE_FAILURE_ACTION:
      state.bikanoTemplateError = true;
      return state;

    case FETCH_BIKANO_STORE_SUCCESS_ACTION:
      return Object.assign({}, state, {
        stores: action.payload.masterData,
        // metrics: action.payload.counts,
        isFetchBikanoStoresSuccess: true,
      });

    case FETCH_BIKANO_STORE_FAILURE_ACTION:
      return Object.assign({}, state, {
        isFetchBikanoStoresFailure: false,
      });

    case FETCH_BIKANO_AUDIT_SUCCESS_ACTION:
      return Object.assign({}, state, {
        audits: action.payload,
        isFetchUdaanAuditSuccess: true,
      });

    case FETCH_BIKANO_AUDIT_FAILURE_ACTION:
      return Object.assign({}, state, {
        isFetchUdaanAuditFailure: false,
      });

    case SAVE_BIKANO_AUDIT_SUCCESS_ACTION:
      return Object.assign({}, state, {
        saveBikanoAuditSuccessful: true,
      });

    case SAVE_BIKANO_AUDIT_FAILURE_ACTION:
      return Object.assign({}, state, {
        saveBikanoAuditSuccessful: false,
      });

    case DOWNLOAD_BIKANO_REPORT_SUCCESS_ACTION:
      var buff = new Buffer(action.payload, "base64");
      var text = buff.toString("utf-8");

      // fileDownload(text, 'report.xlsx');

      return Object.assign({}, state, {
        downloadSuccess: true,
      });

    case DOWNLOAD_BIKANO_REPORT_FAILURE_ACTION:
      return Object.assign({}, state, {
        downloadSuccess: false,
      });

    default:
      return state;
  }
}

export default bikano;
