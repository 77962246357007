import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "react-datepicker/dist/react-datepicker.css";

import { Grid } from "semantic-ui-react";

import IconPicker from "../commonViews/IconPicker";
import "react-notifications/lib/notifications.css";
import {
  fetchAtmStoresAction,
  fetchAuditorsDetailAction,
  filteratm,
  AssignAtmAudits,
} from "../../actions/bank_action";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import BankAuditsDetail from "./BankAuditDetails.jsx";
import CheckboxforAssign from "../commonTables/CheckboxforAssign.jsx";
import TableView from "../commonViews/TableView";
import {
  getTextColor,
  getMainBackground,
  getFontColor,
} from "../../ThemeHandlers/theme";
import Metrics from "../commonViews/Metrics";
import DropDownPicker from "../commonViews/DropDownPicker";
import ButtonHandler from "../commonViews/ButtonHandler";
import ModalView from "../commonViews/ModalView";
// const CheckboxTable = selectTable(ReactTable);

export class BankAudits extends Component {
  constructor(props) {
    super(props);
    this.checkboxTable = null;
    this.setAtmCheckboxRef = (element) => {
      this.checkboxTable = element;
    };
    this.state = {
      CustomerTableView: true,
      selectedUser: {},
      auditid: "",
      customerSearch: "",
      filteredData: [],
      modalOpen: false,
      selectedState: "",
      selectedCity: "",
      selectedStatus: "",
      selection: [],
      userId: [],
      selectAll: false,
      customer: [],
      Userlists: [],
      filter: [],
      comingData: [],
      EmailId: [],
      load: false,
    };
  }
  componentDidMount() {
    this.setState({ load: true });
    this.props.fetchAtmStoresAction(this.props.selectedTemplate._id, {
      onSuccess: () => {
        this.setState({ load: false });
      },
      onFailure: () => {
        this.setState({ load: false });
      },
    });
    this.props.fetchAuditorsDetail();
  }

  componentDidUpdate(prevProps) {
    if (this.props.atm.stores !== prevProps.atm.stores) {
      this.setState({ customer: this.props.atm.stores });
    }
    if (this.props.atm.auditors !== prevProps.atm.auditors) {
      this.setState({ Userlists: this.props.atm.auditors });
    }

    if (this.props.atm.filters !== prevProps.atm.filters) {
      console.log(this.props.atm.filters, "filll")
      this.setState({ customer: this.props.atm.filters });
    }
  }
  addNewUser = () => {
    this.setState({
      modalOpen: true,
    });
  };
  closeEditUser = () => {
    this.setState({
      modalOpen: false,
    });
  };

  searchFilter = (filter, row) => {
    let value = filter.value && filter.value.toLowerCase();

    if (filter.id) {
      var flatedData = JSON.stringify(
        row._original[filter.id] ? row._original[filter.id] : ""
      );
      return flatedData.toLowerCase().includes(value);
    }
  };

  AtmhandleTableViewAuditClick = (user) => {
    if (user.status !== "Assigned") {
      this.setState({
        selectedUser: user,
        CustomerTableView: false,
      });
    }
  };
  onClose = () => {
    this.setState({
      CustomerTableView: true,
    });
  };

  userAssign = () => {
    this.setState({
      modalOpen: false,
      comingData: [],

    });
  };

  onStateChange = (value) => {
    this.setState({
      selectedState: value,
      selectedCity: "",
      selectedStatus: "",
    });
  };

  onCityComboChange = (value) => {
    this.setState({
      selectedCity: value,
    });
  };

  onStatusComboChange = (value) => {
    this.setState({
      selectedStatus: value,
    });
  };

  onViewDetailsClick = () => {
    let state = this.state.selectedState;
    let city = this.state.selectedCity;
    let status = this.state.selectedStatus;
    this.props.filteratm(this.props.selectedTemplate._id, state, city, status);
  };

  removeDuplicates = (e) => {
    let newArray = [];

    // Declare an empty object
    let UniqueObject = {};

    // Loop for the array elements
    for (let i in e) {
      // Extract the title
      let objTitle = e[i]["text"];

      // Use the title as the index
      UniqueObject[objTitle] = e[i];
    }

    // Loop to push unique object into array
    for (let i in UniqueObject) {
      newArray.push(UniqueObject[i]);
    }

    return newArray;
  };
  sendedData = (rowData) => {
    let ids = [];

    if (rowData.length !== 0) {
      rowData.forEach((data) => {
        ids.push(data._id);
      });
    }
    this.setState({
      comingData: ids,
    });
  };

  saveandexit = (rowData, checkedValue) => {
    var selectedData = this.state.comingData;
    this.setState({ modalOpen: false, load: true });

    this.props.AssignAudits(selectedData, [rowData[0]._id], {
      onSuccess: () => {
        this.props.fetchAtmStoresAction(this.props.selectedTemplate._id, {
          onSuccess: () => {
            this.setState({ load: false, comingData: [] });
          },
          onFailure: () => {
            this.setState({ load: false, comingData: [] });
          },
        });
      },
      onFailure: () => {
        this.setState({ load: false });
      },
    });
  };
  render() {

    var Userlist = this.state.Userlists ? this.state.Userlists : [];
    let dropStyle = { zIndex: 100 }

    var city = [{ text: "All", value: "All" }];
    var state = [{ text: "All", value: "All" }];
    var status = [{ text: "All", value: "All" }];
    let uniqueCity;
    let uniqueState;
    let uniqueStatus;
    if (this.props.atm.stores !== undefined) {
      var data = this.props.atm.stores;
      data.map((e) => {
        city.push({ text: e.city, value: e.city });
        state.push({ text: e.state, value: e.state });
        status.push({ text: e.status, value: e.status });
      });
    }

    if (city.length > 0) {
      uniqueCity = this.removeDuplicates(city);
    }
    if (status.length > 0) {
      uniqueStatus = this.removeDuplicates(status);
    }
    if (state.length > 0) {
      uniqueState = this.removeDuplicates(state);
    }

    const atmCol = [
      { title: "ATM Id", field: "atmId" },
      { title: "ATM Name", field: "atmName" },
      { title: "Base Branch", field: "baseBranch" },
      { title: "Branch Code", field: "branchCode" },
      { title: "Status", field: "status" },
      { title: "Assignee", field: "assigneeName" },

    ];

    const column = [
      {
        Header: "Email Id's",
        accessor: "email",
        style: { textAlign: "left", cursor: "pointer" },
        Cell: ({ row: { original } }) => {
          return <span>{original.email}</span>;
        },
        // Cell: row =>
        //   <AuditTableCell row={row.original} text={row.original.email} />
      },
    ];
    return (
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          flexFlow: "column",
          height: "100%",
        }}
      >
        <NotificationContainer />
        {this.state.CustomerTableView && (
          <div>
            <div
              style={{
                display: "inline-block",
                width: "100%",
                marginBottom: 20
              }}
            >
              <IconPicker
                name="arrow left"
                click={this.props.onClose}
                size={'large'}
                styles={{
                  color: getTextColor(this.props.color),
                  float: "right",
                  cursor: "pointer",
                  marginTop: 20,
                  position: "absolute",
                  right: 15,
                }}
              />
              <h1
                style={{
                  display: "inline-block",
                  color: getTextColor(this.props.color),
                }}
              >
                ATM Audit
              </h1>
            </div>

            <Grid>
              <Grid.Row columns={5}>
                <Grid.Column>
                  <DropDownPicker
                    value={this.state.selectedState}
                    options={uniqueState}
                    placeholder={"Select Region"}
                    styles={dropStyle}
                    onChangeDropDown={(combo) => {
                      this.onStateChange(combo);
                    }}
                  />
                </Grid.Column>

                <Grid.Column>
                  <DropDownPicker
                    value={this.state.selectedCity}
                    options={uniqueCity}
                    placeholder={"Select City"}
                    styles={dropStyle}

                    onChangeDropDown={(combo) => {
                      this.onCityComboChange(combo);
                    }}
                  />

                </Grid.Column>
                <Grid.Column>
                  <DropDownPicker
                    value={this.state.selectedStatus}
                    options={uniqueStatus}
                    placeholder={"Select Status"}
                    styles={dropStyle}

                    onChangeDropDown={(combo) => {
                      this.onStatusComboChange(combo);
                    }}
                  />

                </Grid.Column>
                <Grid.Column width={3}>
                  <ButtonHandler
                    title={'View Details'}
                    size={'medium'}
                    click={this.onViewDetailsClick}
                    disabled={this.state.selectedState === "" && this.state.selectedCity === "" && this.state.selectedStatus === ""}
                    styles={{
                      cursor: "pointer",
                      width: "100%",
                      textAlign: "center",
                      backgroundColor: getMainBackground(this.props.color),
                      color: getFontColor(this.props.color),
                    }}
                  />

                </Grid.Column>
                <Grid.Column>
                  <ButtonHandler
                    title={'Assign Audits'}
                    size={'medium'}
                    disabled={this.state.comingData.length === 0}
                    click={this.addNewUser}
                    styles={{
                      cursor: "pointer",
                      width: "100%",
                      textAlign: "center",
                      backgroundColor: getMainBackground(this.props.color),
                      color: getFontColor(this.props.color),
                    }}
                  />

                </Grid.Column>
              </Grid.Row>
            </Grid>
            <div style={{ marginTop: 10 }}>
              <Metrics
                metrics={this.props.atm.metrics}
                color={this.props.color}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexGrow: 1,
                flexFlow: "column",
                marginTop: 20,

                overflowX: "hidden",
              }}
            >
              <TableView
                selection={true}
                data={this.state.customer}
                columns={atmCol}
                onSelectionChange={(rows, row) => this.sendedData(rows, row)}
                onRowClick={(event, rowData) => {
                  this.AtmhandleTableViewAuditClick(rowData);
                }}
                load={this.state.load}
              />

            </div>
          </div>
        )}

        {!this.state.CustomerTableView && (
          <BankAuditsDetail
            user={this.state.selectedUser}
            onClose={this.onClose}
            color={this.props.color}
          />
        )}
        <ModalView
          open={this.state.modalOpen}
          close={this.closeEditUser}
          size={'small'}
          content={
            <CheckboxforAssign
              data={Userlist}
              columns={column}
              sendedData={(rowData, checkedValue) => {
                this.saveandexit(rowData, checkedValue);
              }}
            />}
          saveClick={this.userAssign}
          showSave={false}
        />
      </div>
    );
  }
}

function AuditTableCell(props) {
  function onClick() {
    props.onClick(props.row);
  }
  return (
    <div style={props.style} onClick={onClick}>
      {props.text}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    atm: state.atm,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchAtmStoresAction: fetchAtmStoresAction,
      fetchAuditorsDetail: fetchAuditorsDetailAction,
      filteratm: filteratm,
      AssignAudits: AssignAtmAudits,
    },
    dispatch
  );
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BankAudits)
);
