import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "react-datepicker/dist/react-datepicker.css";
import IconPicker from "../commonViews/IconPicker.jsx";
import "react-notifications/lib/notifications.css";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import TableView from "../commonViews/TableView.jsx";
import {
    getTextColor,
    getMainBackground,
    getFontColor,
} from "../../ThemeHandlers/theme";
import { fetchPGDMasterAction } from '../../actions/pgd_actions'
import Metrics from "../commonViews/Metrics.jsx";
import Audits from "./Audits.jsx";

export class Locations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            doctorsListView: true,
            selectedCustomer: {},
            auditid: "",
            selection: [],
            isLoading: false,
            locationsList: [],
            counts: {},
            templates: []
        };
    }
    componentDidMount() {
        // console.log(this.props.templateId, 'template')
        this.setState({ isLoading: true });
        let templateId = this.props.templateId;
        this.setState({ templates: this.props.selectedTemplate })
        this.props.fetchPGDMasterAction({
            templateId,
            cityName: this.props.selectedCity.cityName,
            onSuccess: (res) => {
                this.setState({ isLoading: false, locationsList: res.locations });
            },
            onFailure: () => {
                this.setState({ isLoading: false });
            },
        });
    }
    auditIdFromOlaAudit = (auditId) => {
        this.setState({
            auditid: auditId,
        });
    };

    dtchandleTableViewAuditClick = (customer) => {
        this.setState({ selectedCustomer: customer, doctorsListView: false });
    };

    onGotoCustomerView = () => {
        this.setState({
            doctorsListView: true,
        });
    };
    render() {

        const pgdCol = [
            {
                title: "Doctor Name",
                field: "code",
                render: (rowData) => (
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            this.dtchandleTableViewAuditClick(rowData);
                        }}
                    >
                        {rowData.name}
                    </span>
                ),
                customFilterAndSearch: (term, rowData) =>
                    rowData.name.toLowerCase().indexOf(term.toLowerCase()) > -1,
            },
            {
                title: "Unique CUST ID",
                field: "",
                render: (rowData) => (
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            this.dtchandleTableViewAuditClick(rowData);
                        }}
                    >
                        {rowData.uniqueCustId}
                    </span>
                ),
                customFilterAndSearch: (term, rowData) =>
                    rowData.uniqueCustId.toLowerCase().indexOf(term.toLowerCase()) > -1,
            },
            {
                title: "CUST ID",
                field: "",
                render: (rowData) => (
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            this.dtchandleTableViewAuditClick(rowData);
                        }}
                    >
                        {rowData.custId}
                    </span>
                ),
                customFilterAndSearch: (term, rowData) =>
                    rowData.custId.toLowerCase().indexOf(term.toLowerCase()) > -1,
            },
            {
                title: "Speciality",
                field: "",
                render: (rowData) => (
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            this.dtchandleTableViewAuditClick(rowData);
                        }}
                    >
                        {rowData.speciality}
                    </span>
                ),
                customFilterAndSearch: (term, rowData) =>
                    rowData.speciality.toLowerCase().indexOf(term.toLowerCase()) >
                    -1,
            },
            {
                title: "Degree Name",
                field: "",
                render: (rowData) => (
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            this.dtchandleTableViewAuditClick(rowData);
                        }}
                    >
                        {rowData.degreeName}
                    </span>
                ),
                customFilterAndSearch: (term, rowData) =>
                    rowData.degreeName.toLowerCase().indexOf(term.toLowerCase()) >
                    -1,
            },
            {
                title: "Address",
                field: "",
                render: (rowData) => (
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            this.dtchandleTableViewAuditClick(rowData);
                        }}
                    >
                        {rowData.address}
                    </span>
                ),
                customFilterAndSearch: (term, rowData) =>
                    rowData.address.toLowerCase().indexOf(term.toLowerCase()) >
                    -1,
            },
            {
                title: "Pin Code",
                field: "",
                render: (rowData) => (
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            this.dtchandleTableViewAuditClick(rowData);
                        }}
                    >
                        {rowData.pinCode}
                    </span>
                ),
                customFilterAndSearch: (term, rowData) =>
                    rowData.pinCode.toLowerCase().indexOf(term.toLowerCase()) >
                    -1,
            },
            {
                title: "city",
                field: "",
                render: (rowData) => (
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            this.dtchandleTableViewAuditClick(rowData);
                        }}
                    >
                        {rowData.city}
                    </span>
                ),
                customFilterAndSearch: (term, rowData) =>
                    rowData.city.toLowerCase().indexOf(term.toLowerCase()) >
                    -1,
            },
            {
                title: "Zone Name",
                field: "",
                render: (rowData) => (
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            this.dtchandleTableViewAuditClick(rowData);
                        }}
                    >
                        {rowData.zoneName}
                    </span>
                ),
                customFilterAndSearch: (term, rowData) =>
                    rowData.zoneName.toLowerCase().indexOf(term.toLowerCase()) >
                    -1,
            },
            {
                title: "Status",
                field: "",
                render: (rowData) => (
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            this.dtchandleTableViewAuditClick(rowData);
                        }}
                    >
                        {rowData.status}
                    </span>
                ),
                customFilterAndSearch: (term, rowData) =>
                    rowData.status.toLowerCase().indexOf(term.toLowerCase()) >
                    -1,
            },
        ];

        return (
            <div>
                <NotificationContainer />
                {this.state.doctorsListView && (
                    <div>
                        <div style={{ display: "inline-block", marginBottom: 20 }}>
                            <IconPicker
                                name="arrow left"
                                click={this.props.onClose}
                                styles={{
                                    color: getTextColor(this.props.color),
                                    float: "right",
                                    cursor: "pointer",
                                    marginTop: 35,
                                    position: "absolute",
                                    right: 20,
                                }}
                            />
                            <h1
                                style={{
                                    display: "inline-block",
                                    color: getTextColor(this.props.color),
                                }}
                            >
                                Doctors List
                            </h1>

                        </div>
                    </div>
                )}
                {this.state.doctorsListView && (
                    <div
                        style={{
                            display: "flex",
                            flexGrow: 1,
                            flexFlow: "column",
                            marginTop: 20,

                        }}
                    >
                        <TableView
                            data={this.state.locationsList}
                            columns={pgdCol}
                            load={this.state.isLoading}
                        />

                    </div>
                )}

                {!this.state.doctorsListView && (
                    <div style={{ flexGrow: 1, display: "flex" }}>
                        <Audits
                            template={this.state.templates}
                            customer={this.state.selectedCustomer}
                            onClose={this.onGotoCustomerView}
                            auditId={this.auditIdFromOlaAudit}
                            color={this.props.color}
                        />
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            fetchPGDMasterAction
        },
        dispatch
    );
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Locations)
);
