import { FETCH_ATM_TEMPLATE_SUCCESS_ACTION, FETCH_ATM_TEMPLATE_FAILURE_ACTION, 
    FETCH_AGENT_AUDITS_FAILURE_ACTION, 
    FETCH_ATM_STORE_SUCCESS_ACTION, 
    FETCH_ATM_STORE_FAILURE_ACTION, 
    FETCH_ATM_AUDITS_SUCCESS_ACTION, 
    FETCH_ATM_AUDITS_FAILURE_ACTION, 
    FILTER_ATM_SUCCESS_ACTION, 
    FILTER_ATM_FAILURE_ACTION, 
    FETCH_ATM_PRODUCTS_SUCCESS_ACTION, 
    FETCH_ATM_PRODUCTS_FAILURE_ACTION, 
    SAVE_ATM_AUDITS_SUCCESS_ACTION, 
    SAVE_ATM_AUDITS_FAILURE_ACTION, 
    DOWNLOAD_ATMAUDITS_REPORT_SUCCESS_ACTION, 
    DOWNLOAD_ATMAUDITS_REPORT_FAILURE_ACTION, 
    DOWNLOAD_ATMREPORT_SUCCESS_ACTION, 
    DOWNLOAD_ATMREPORT_FAILURE_ACTION, 
    SEND_ATMAUDITS_MAIL_SUCCESS_ACTION, 
    SEND_ATMAUDITS_MAIL_FAILURE_ACTION,
    UPLOAD_ATM_PHOTO_SUCCESS_ACTION,
    UPLOAD_ATM_PHOTO_FAILURE_ACTION 
} from '../actions/types'


const INIT_STATE = {};

 function bank (state = INIT_STATE, action) {
    state = Object.assign({}, state, {});

    switch (action.type) {
        case FETCH_ATM_TEMPLATE_SUCCESS_ACTION:
            state.template = action.payload.Data;
            state.templateError = false;
            return state;
        case FETCH_ATM_TEMPLATE_FAILURE_ACTION:
            state.templateError = true;
            return state;

        case FETCH_AGENT_AUDITS_FAILURE_ACTION:
            state.templateError = true;
            return state;
        case FETCH_ATM_STORE_SUCCESS_ACTION:
            state.stores = action.payload.Data;
            state.metrics = action.payload.Count;
            state.storeError = false;
            return state;
        case FETCH_ATM_STORE_FAILURE_ACTION:
            state.storeError = true;
            return state;
        case FETCH_ATM_AUDITS_SUCCESS_ACTION:
            state.auditors = action.payload.Users;
            state.auditorsError = false;
            return state;
        case FETCH_ATM_AUDITS_FAILURE_ACTION:
            state.auditorsError = true;
            return state;
        case FILTER_ATM_SUCCESS_ACTION:
            state.filters = action.payload.Data;
            state.filterError = false;
            return state;
        case FILTER_ATM_FAILURE_ACTION:
            state.filterError = true;
            return state;
        case FETCH_ATM_PRODUCTS_SUCCESS_ACTION:
            state.audits = action.payload.Audit;
            state.quesError = false;
            return state;
        case FETCH_ATM_PRODUCTS_FAILURE_ACTION:
            state.quesError = true;
            return state;
        case SAVE_ATM_AUDITS_SUCCESS_ACTION:
            state.saveAudits = action.payload;
            state.saveError = false;
            return state;
        case SAVE_ATM_AUDITS_FAILURE_ACTION:
            state.saveError = true;
            return state;


        case UPLOAD_ATM_PHOTO_SUCCESS_ACTION:
            state.photoUrl = action.payload;
            return state;
        case UPLOAD_ATM_PHOTO_FAILURE_ACTION:
            state.saveError = true;
            return state;




        case DOWNLOAD_ATMREPORT_SUCCESS_ACTION:
            var buff = new Buffer(action.payload, 'base64');
            var text = buff.toString('utf-8');

            // fileDownload(text, 'report.xlsx');

            return Object.assign({}, state, {
                downloadSuccess: true
            });
        case DOWNLOAD_ATMREPORT_FAILURE_ACTION:
            return Object.assign({}, state, {
                downloadSuccess: false
            })
        case DOWNLOAD_ATMAUDITS_REPORT_SUCCESS_ACTION:
            return Object.assign({}, state, {
                isAuditPDFDownloaded: true
            });
        case DOWNLOAD_ATMAUDITS_REPORT_FAILURE_ACTION:
            return Object.assign({}, state, {
                isAuditPDFDownloaded: false
            });

            case SEND_ATMAUDITS_MAIL_SUCCESS_ACTION:
                return Object.assign({}, state, {
                    mailreportsuccess: true
                });

                case SEND_ATMAUDITS_MAIL_FAILURE_ACTION:
                    return Object.assign({}, state, {
                        mailreportsuccess: false
                    });
                default:
                    return state    

    }
}

export default bank
