import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  Image,
  Grid,

} from "semantic-ui-react";
import ButtonHandler from "../commonViews/ButtonHandler.jsx";
import moment from "moment";
import config from "../../config.js";
import {
  fetchQuesAudits,
  SaveAudits,
  downloadAtmAuditReportAction,
  mailReportAction,
  downloadAtmAuditPhotosAction,
  uploadAtmPhotos,
} from "../../actions/bank_action";
import MailPopup from "../MailPopup.jsx";
import ConstantLabel from "../commonViews/ConstantLabel.jsx";
import DropDownPicker from "../commonViews/DropDownPicker.jsx";
import TextInput from "../commonViews/TextInput.jsx";
import {
  getTextColor,
  getMainBackground,
  getMatchingTint,
  getFontColor,
} from "../../ThemeHandlers/theme";
import IconPicker from "../commonViews/IconPicker.jsx";
import ModalView from "../commonViews/ModalView.jsx";

const storageBaseUrl = config["storage_base_url"];

class BankAuditsDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTc: null,
      showPopup: false,
      EditMode: false,
      selectedPhoto: "",
      showPhotoSlider: false,
      modalOpen: false,
      zoomImage: "",
      disabled: true,
      currentAudit: [],
      initialData: [],
      mailpopup: false,
    };
  }
  componentDidMount() {
    this.props.fetchQues(this.props.user._id);
  }

  zoomPics = (url) => {
    this.setState({ modalOpen: true, zoomImage: url });
  };

  editAudit = async () => {
    this.setState({
      EditMode: true,
      disabled: false,
    });
  };

  atmchangeAnswer = (ques, e) => {
    var answers = "";
    let audit = this.props.atm.audits;
    if (ques.answerType === "text") {
      answers = e.target.value;
      audit.questions.map((eachQuestion) => {
        if (eachQuestion.id === ques.id) {
          eachQuestion.answer = answers;
        }
      });
    }
    if (ques.answerType === "options") {
      answers = e;
      audit.questions.map((eachQuestion) => {
        if (eachQuestion.id === ques.id) {
          eachQuestion.answer = String(answers);
        }
      });
    }
    this.setState({ currentAudit: audit });
  };

  atmdropdownchangeAnswer = (ques, e) => {
    var answers = "";
    let audit = this.props.atm.audits;

    answers = e;
    audit.questions.map((eachQuestion) => {
      if (eachQuestion.id === ques.id) {
        eachQuestion.categoryAnswer = String(answers);
      }
    });
    this.setState({ currentAudit: audit });
  };

  changeNotes = (ques, e) => {
    var answers = "";
    let audit = this.props.atm.audits;
    answers = e.target.value;
    audit.questions.map((eachQuestion) => {
      if (eachQuestion.id === ques.id) {
        eachQuestion.remarks = answers;
      }
    });
    this.setState({ currentAudit: audit });
  };

  closeEditUser = () => {
    this.setState({ modalOpen: false });
  };

  SaveAudit = async () => {
    this.setState({ EditMode: false, disabled: true });
    await this.props.SaveAudits(this.state.currentAudit);
  };
  onDownloadReport = (Id) => {
    this.props.downloadAtmAuditReportAction(
      Id._id,
      this.props.user.atmId,
      this.props.user.atmName
    );
  };

  onDownloadPhotos = (Id) => {
    this.props.downloadAtmAuditPhotosAction(
      Id._id,
      this.props.user.atmId,
      this.props.user.atmName
    );
  };

  download = () => {
    this.setState({
      mailpopup: true,
    });
  };
  handleMail = () => {
    this.setState({
      mailpopup: false,
    });
  };
  handleMailchange = (mail) => {
    var auditId = this.props.atm.audits._id;
    this.props.mailReportAction(auditId, mail);
  };
  CancelEditAudit = async () => {
    this.setState({
      EditMode: false,
      disabled: true,
    });
  };

  onPhotoSelect = (e) => {
    this.setState({
      selectedPhoto: e.target.files[0],
    });
  };

  savePhotos = (ques) => {
    let audit = this.props.atm.audits;
    audit.questions.map((eachQuestion) => {
      if (eachQuestion.id === ques.id) {
        if (eachQuestion.photos && eachQuestion.photos.length > 0) {
          let data = {
            isUploaded: true,
            uploadedImageUrl: this.props.atm.photoUrl,
          };
          eachQuestion.photos.append(data);
        } else {
          let data = [
            {
              isUploaded: true,
              uploadedImageUrl: this.props.atm.photoUrl,
            },
          ];
          eachQuestion.photos = data;
        }
      }
    });
    this.setState({ currentAudit: audit });
  };

  uploadPhoto = (ques) => {
    let photo = this.state.selectedPhoto;
    let locationCode = this.props.user.atmId;
    this.props.uploadAtmPhotos(photo, locationCode, {
      onSuccess: (resp) => {
        let audit = this.props.atm.audits;
        audit.questions.map((eachQuestion) => {
          if (eachQuestion.id === ques.id) {
            if (eachQuestion.photos && eachQuestion.photos.length > 0) {
              let data = {
                isUploaded: true,
                uploadedImageUrl: resp.data,
              };
              eachQuestion.photos.push(data);
            } else {
              let data = [
                {
                  isUploaded: true,
                  uploadedImageUrl: resp.data,
                },
              ];
              eachQuestion.photos = data;
            }
          }
        });
        this.setState({ currentAudit: audit });
      },
      onFailure: () => {
        alert("Photo Upload Failure");
      },
    });
  };

  render() {
    let master = [];
    let questions;
    if (this.props.atm.audits != undefined) {
      master = this.props.atm.audits;
    }
    let dropStyle = {
      marginBottom: 10, width: 300
    }
    return (
      <div
        style={{
          // marginRight: "100px",
          margin: 20,
          // paddingRight: "100px",
          height: "100%",
        }}
      >
        <IconPicker
          name={"arrow left"}
          size={'large'}
          click={this.props.onClose}
          styles={{
            color: "#808080",
            float: "right",
            cursor: "pointer",
            marginTop: "15px",
            position: "absolute",
            right: 20,
          }}
        />

        <ModalView
          open={this.state.modalOpen}
          close={this.closeEditUser}
          size={'small'}
          content={
            <Image src={storageBaseUrl + this.state.zoomImage} size={'large'} />

          }
          showSave={false}
        />


        <h1 style={{ color: getTextColor(this.props.color), fontSize: 20 }}>
          {master.auditName}
        </h1>
        <p style={{ color: getTextColor(this.props.color) }}>
          {" "}
          Created at {moment(master.createdAt).format("DD-MM-YYYY  HH:MM:SS")}
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: 600,
            justifyContent: "flex-start",
          }}
        >
          {!this.state.EditMode && (
            <ConstantLabel
              title={"Edit"}
              size={"medium"}
              onClick={this.editAudit}
              styles={{
                cursor: "pointer",
                backgroundColor: getMainBackground(this.props.color),
                color: getFontColor(this.props.color),
              }}
              iconName={"edit"}
            />
          )}

          {this.state.EditMode && (
            <div>
              <ConstantLabel
                title={"Save Audit"}
                size={"medium"}
                onClick={this.SaveAudit}
                styles={{
                  cursor: "pointer",
                  backgroundColor: getMainBackground(this.props.color),
                  color: getFontColor(this.props.color),
                  marginRight: 5,
                }}
                iconName={"save"}
              />

              <ConstantLabel
                title={"Cancel"}
                size={"medium"}
                onClick={this.CancelEditAudit}
                styles={{
                  cursor: "pointer",
                  backgroundColor: getMainBackground(this.props.color),
                  color: getFontColor(this.props.color),
                  marginRight: 5,
                }}
                iconName={"cancel"}
              />
            </div>
          )}

          <ConstantLabel
            title={"Send Mail"}
            size={"medium"}
            onClick={this.download}
            styles={{
              cursor: "pointer",
              backgroundColor: getMainBackground(this.props.color),
              color: getFontColor(this.props.color),
              marginRight: 5,
            }}
            iconName={"table"}
          />
          <ConstantLabel
            title={"Download Report"}
            size={"medium"}
            onClick={() => this.onDownloadReport(this.props.atm.audits)}

            styles={{
              cursor: "pointer",
              backgroundColor: getMainBackground(this.props.color),
              color: getFontColor(this.props.color),
              marginRight: 5,
            }}
            iconName={"mail square"}
          />
          <ConstantLabel
            title={"Download Photos"}
            size={"medium"}
            onClick={() => this.onDownloadPhotos(this.props.atm.audits)}
            styles={{
              cursor: "pointer",
              backgroundColor: getMainBackground(this.props.color),
              color: getFontColor(this.props.color),
              marginRight: 5,
            }}
            iconName={"camera"}
          />
        </div>
        {this.state.mailpopup && (
          <MailPopup
            open={this.state.mailpopup}
            closePopup={this.handleMail.bind(this)}
            sendMailAndClosePopup={this.handleMailchange}
            getColor={getMainBackground}
            color={this.props.color}
            getCellColor={getMatchingTint}
          />
        )}
        {master.questions !== undefined &&
          master.questions.map((ques) => {
            return (
              <div style={{ margin: 10 }}>
                <Grid>
                  <Grid.Row columns={1}><h3 style={{ color: getTextColor(this.props.color) }}>{ques.question}</h3></Grid.Row>
                  <Grid.Row columns={1}>
                    {ques.answerType === "text" && (
                      <>
                        <Grid.Column>
                          <TextInput styles={{ width: 300 }} placeholder={'Enter Text'} value={ques.answer} disabled={this.state.disabled} onChangeTextInput={(e) => this.atmchangeAnswer(ques, e)} />

                        </Grid.Column>
                        {ques.dropdown && (
                          <Grid.Column>
                            <DropDownPicker
                              placeholder={"Enter Text"}
                              value={Number(ques.categoryAnswer)}
                              options={ques.dropdown.map((label, i) => {
                                return {
                                  value: label.value,
                                  text: label.label,
                                  key: label.key,
                                };
                              })}
                              styles={dropStyle}

                              disabled={this.state.disabled}
                              onChangeDropDown={(combo) => {
                                this.atmdropdownchangeAnswer(ques, combo);
                              }}
                            />
                          </Grid.Column>
                        )}
                        {ques.remarks && (
                          <Grid.Column>
                            <div>
                              <TextInput styles={{ width: 300 }} placeholder={'Enter Remarks'} value={ques.remarks} disabled={this.state.disabled} onChangeTextInput={(e) => this.changeNotes(ques, e)} />


                            </div>
                          </Grid.Column>
                        )}
                        {!ques.remarks && (
                          <Grid.Column>
                            <div>
                              <TextInput styles={{ width: 300 }} placeholder={'Enter Remarks'} value={ques.remarks} disabled={this.state.disabled} onChangeTextInput={(e) => this.changeNotes(ques, e)} />


                            </div>
                          </Grid.Column>
                        )}

                        {ques.photos && ques.photos.length > 0 && (
                          <Grid.Column>
                            <Image.Group
                              size="mini"
                              style={{ cursor: "pointer" }}
                            >
                              {ques.photos.map((p, i) => {
                                if (p.uploadedImageUrl != null) {
                                  return (
                                    <Image
                                      key={i}
                                      src={storageBaseUrl + p.uploadedImageUrl}
                                      onClick={() =>
                                        this.zoomPics(p.uploadedImageUrl)
                                      }
                                    />
                                  );
                                }
                              })}
                            </Image.Group>
                          </Grid.Column>
                        )}
                        {this.state.EditMode === true && (
                          <Grid.Column>
                            <Grid.Row
                              columns={1}
                              style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "row",
                              }}
                            >
                              <Grid.Column>
                                <input
                                  type="file"
                                  content="Upload additional Photos"
                                  icon="cloud upload"
                                  labelPosition="right"
                                  onChange={(e) => this.onPhotoSelect(e)}
                                  multiple
                                  accept=".jpg,.png,.jpeg"
                                />
                              </Grid.Column>
                              <Grid.Column>
                                <ButtonHandler title={'Upload'} color={"green"} size={'medium'} click={() => { this.uploadPhoto(ques) }} />

                              </Grid.Column>
                            </Grid.Row>
                          </Grid.Column>
                        )}
                      </>
                    )}
                    {ques.answerType === "options" && (
                      <>
                        {" "}
                        <Grid.Column>
                          <DropDownPicker
                            placeholder={"Select any option"}
                            value={Number(ques.answer)}
                            options={ques.options.map((label, i) => {
                              return {
                                value: label.value,
                                text: label.label,
                                key: label.key,
                              };
                            })}
                            styles={dropStyle}

                            disabled={this.state.disabled}
                            onChangeDropDown={(value) => {
                              this.atmchangeAnswer(ques, value);
                            }}
                          />
                        </Grid.Column>
                        {ques.dropdown && (
                          <Grid.Column>
                            <DropDownPicker
                              placeholder={"Enter Text"}
                              value={Number(ques.categoryAnswer)}
                              options={ques.dropdown.map((label, i) => {
                                return {
                                  value: label.value,
                                  text: label.label,
                                  key: label.key,
                                };
                              })}
                              styles={dropStyle}
                              disabled={this.state.disabled}
                              onChangeDropDown={(combo) => {
                                this.atmdropdownchangeAnswer(ques, combo);
                              }}
                            />
                          </Grid.Column>
                        )}
                        {ques.remarks && (
                          <Grid.Column>
                            <div>
                              <TextInput styles={{ width: 300 }} placeholder={'Enter Remarks'} value={ques.remarks} disabled={this.state.disabled} onChangeTextInput={(e) => this.changeNotes(ques, e)} />


                            </div>
                          </Grid.Column>
                        )}
                        {!ques.remarks && (
                          <Grid.Column>
                            <div>
                              <TextInput styles={{ width: 300 }} placeholder={'Enter Remarks'} value={ques.remarks} disabled={this.state.disabled} onChangeTextInput={(e) => this.changeNotes(ques, e)} />

                            </div>
                          </Grid.Column>
                        )}
                        {ques.photos && ques.photos.length > 0 && (
                          <Grid.Column>
                            <Image.Group
                              size="mini"
                              style={{ cursor: "pointer" }}
                            >
                              {ques.photos.map((p, i) => {
                                if (p.uploadedImageUrl != null) {
                                  return (
                                    <Image
                                      key={i}
                                      src={storageBaseUrl + p.uploadedImageUrl}
                                      onClick={() =>
                                        this.zoomPics(p.uploadedImageUrl)
                                      }
                                    />
                                  );
                                }
                              })}
                            </Image.Group>
                          </Grid.Column>
                        )}
                        {this.state.EditMode === true && (
                          <Grid.Column>
                            <Grid.Row
                              columns={2}
                              style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "row",
                              }}
                            >
                              <Grid.Column>
                                <input
                                  type="file"
                                  content="Upload additional Photos"
                                  icon="cloud upload"
                                  labelPosition="right"
                                  onChange={(e) => this.onPhotoSelect(e)}
                                  multiple
                                  accept=".jpg,.png,.jpeg"
                                />
                              </Grid.Column>
                              <Grid.Column>
                                <ButtonHandler title={'Upload'} color={"green"} size={'medium'} click={() => { this.uploadPhoto(ques) }} />


                              </Grid.Column>
                            </Grid.Row>
                          </Grid.Column>
                        )}
                      </>
                    )}
                    {ques.answerType === "photo" && (
                      <>
                        <Grid.Column>
                          <Image.Group size="mini" style={{ cursor: "pointer" }}>
                            {ques.photos.length > 0 &&
                              ques.photos.map((p, i) => {
                                if (p.uploadedImageUrl != null) {
                                  return (
                                    <Image
                                      key={i}
                                      src={storageBaseUrl + p.uploadedImageUrl}
                                      onClick={() =>
                                        this.zoomPics(p.uploadedImageUrl)
                                      }
                                    // onClick={(k => {
                                    // return e => this.handlePhotoClick(e, k);
                                    // })(p)}
                                    />
                                  );
                                }
                              })}
                          </Image.Group>
                        </Grid.Column>
                        <Grid.Column>
                          {this.state.EditMode === true && (
                            <Grid.Column>
                              <Grid.Row
                                columns={2}
                                style={{
                                  display: "flex",
                                  flex: 1,
                                  flexDirection: "row",
                                }}
                              >
                                <Grid.Column>
                                  <input
                                    type="file"
                                    content="Upload additional Photos"
                                    icon="cloud upload"
                                    labelPosition="right"
                                    onChange={(e) => this.onPhotoSelect(e)}
                                    multiple
                                    accept=".jpg,.png,.jpeg"
                                  />
                                </Grid.Column>
                                <Grid.Column>
                                  <ButtonHandler title={'Upload'} color={"green"} size={'medium'} click={() => { this.uploadPhoto(ques) }} />

                                </Grid.Column>
                              </Grid.Row>
                            </Grid.Column>
                          )}
                        </Grid.Column>
                      </>
                    )}
                  </Grid.Row>
                </Grid>
              </div>
            );
          })}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    atm: state.atm,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchQues: fetchQuesAudits,
      SaveAudits: SaveAudits,
      downloadAtmAuditReportAction: downloadAtmAuditReportAction,
      mailReportAction: mailReportAction,
      downloadAtmAuditPhotosAction: downloadAtmAuditPhotosAction,
      uploadAtmPhotos: uploadAtmPhotos,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BankAuditsDetail)
);
