import React from "react";
import { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  getJandJAudit,
  saveAudit,
  getJandJAuditReport,
  mailJandJAuditReport,
} from "../../actions/jandj_actions.js";
import {
  Modal,
  Image,
  Segment,
  Tab,
  Grid,
} from "semantic-ui-react";
import IconPicker from "../commonViews/IconPicker.jsx";
import ButtonHandler from "../commonViews/ButtonHandler.jsx";
import TextInput from '../commonViews/TextInput.jsx'
import "semantic-ui-css/semantic.min.css";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import config from "../../config.js";
import MailPopup from "../MailPopup.jsx";
import {
  getTextColor,
  getMainBackground,
  getMatchingTint,
  getFontColor,
} from "../../ThemeHandlers/theme";
import ConstantLabel from "../commonViews/ConstantLabel.jsx";
import DropDownPicker from "../commonViews/DropDownPicker.jsx";
import ModalView from "../commonViews/ModalView.jsx";

const storageBaseUrl = config["storage_base_url"];

export class JandJAuditView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      audit: null,
      showPhotoSlider: false,
      selectedPhoto: "",
      isMailPopupView: false,
      showStockAudit: false,
      productName: "",
      filterAudit: null,
      productCode: "",
      mrp: "",
    };
  }

  componentDidMount() {
    if (this.props.jandj.audit) {
      this.setState({
        audit: JSON.parse(JSON.stringify(this.props.jandj.audit)),
        filterAudit: JSON.parse(JSON.stringify(this.props.jandj.audit)),
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.jandj.audit) {
      this.setState({
        audit: JSON.parse(JSON.stringify(nextProps.jandj.audit)),
        filterAudit: JSON.parse(JSON.stringify(nextProps.jandj.audit)),
      });
    }
  }

  onDownloadReport = () => {
    this.props.getJandJAuditReport(this.state.audit._id);
  };

  onShowPhotos = (id) => {
    let open = `#/images/${id}`;
    window.open(open);
  };

  editAudit = () => {
    this.setState({ editMode: true });
  };

  cancelEditAudit = () => {
    this.setState({ editMode: false });
  };

  saveAudit = () => {
    this.props.saveAudit(this.state.audit);
    this.setState({ editMode: false });
  };

  photoSliderClose = (e) => {
    this.setState({
      showPhotoSlider: false,
    });
  };

  handlePhotoClick = (e, p) => {
    this.setState({
      selectedPhoto: p,
      showPhotoSlider: true,
    });
  };

  handleSendEmailReportClick = (emailIds) => {
    this.props.mailJandJAuditReport(this.state.audit._id, emailIds);
    this.setState({ isMailPopupView: !this.state.isMailPopupView });
  };

  closeMailReportPopup = () => {
    this.setState({ isMailPopupView: false });
  };

  openMailReportPopup = () => {
    this.setState({ isMailPopupView: true });
  };

  openStockAudit = () => {
    this.setState({ showStockAudit: true });
  };

  closeStockAudit = () => {
    this.setState({ showStockAudit: false });
  };

  onAnswerChange = (questionnaireId, questionId, answerType, e) => {
    console.log("changing");
    let audit = this.state.audit;
    audit.questionnaire.map((q) => {
      if (q._id === questionnaireId) {
        q.questions.map((qe) => {
          if (qe._id === questionId) {
            switch (answerType) {
              case "text":
              case "date":
              case "options":
              case "auto":
                qe.answer = e;
                break;
            }
          }
        });
      }
    });

    this.setState({ audit: audit });
  };

  onStockChange = (stockId, questionId, answerType, answer) => {
    console.log("coming");
    let audit = this.state.audit;
    audit.stocks.map((q) => {
      if (q._id === stockId) {
        q.questions.map((qe) => {
          if (qe._id === questionId) {
            switch (answerType) {
              case "text":
              case "date":
                qe.answer = answer;
                break;
            }
          }
        });
      }
    });

    this.setState({ audit: audit });
  };

  changeProductName = (data) => {
    console.log(data, "chekll")
    var prdList = [];
    this.state.filterAudit.stocks.map((stock) => {
      if (stock.prdName == data) {
        prdList.push(stock);
      }
    });
    let audit = this.state.audit;
    audit.stocks = prdList;
    this.setState({
      productName: data,
      audit: audit,
      productCode: "",
      mrp: "",
    });
  };

  changeProductCode = (data) => {
    var prdList = [];
    this.state.filterAudit.stocks.map((stock) => {
      if (stock.productCode == data) {
        prdList.push(stock);
      }
    });
    let audit = this.state.audit;
    audit.stocks = prdList;
    this.setState({
      productCode: data,
      productName: "",
      mrp: "",
      audit: audit,
    });
  };

  changeMrp = (data) => {
    var prdList = [];
    this.state.filterAudit.stocks.map((stock) => {
      if (stock.mrp == data) {
        prdList.push(stock);
      }
    });
    let audit = this.state.audit;
    audit.stocks = prdList;
    this.setState({
      mrp: data,
      productName: "",
      audit: audit,
      productCode: "",
    });
  };

  render() {
    var products = [];
    var productCodes = [];
    var mrp = [];
    if (this.state.filterAudit != null && this.state.filterAudit.stocks) {
      this.state.filterAudit.stocks.map((data, index) => {
        products.push({
          text: data.prdName,
          key: index,
          value: data.prdName,
        });
        productCodes.push({
          text: data.productCode,
          key: index,
          value: data.productCode,
        });
        mrp.push({
          text: data.mrp,
          key: index,
          value: data.mrp,
        });
      });
    }

    return (
      <div>
        {this.state.isMailPopupView ? (
          <MailPopup
            open={this.state.isMailPopupView}
            mailIds={""}
            closePopup={this.closeMailReportPopup}
            sendMailAndClosePopup={this.handleSendEmailReportClick}
            getColor={getTextColor}
            color={this.props.color}
            getCellColor={getMatchingTint}
          />
        ) : null}
        {!this.state.audit ? (
          <div>Loading</div>
        ) : (
          <div>
            <HeaderView
              selectedAudit={this.state.audit}
              editMode={this.state.editMode}
              onDownloadReport={this.onDownloadReport}
              onDownloadPhotos={this.onShowPhotos}
              editAudit={this.editAudit}
              savedAudit={this.saveAudit}
              cancelEditAudit={this.cancelEditAudit}
              openEmailReport={this.openMailReportPopup}
              dealer={this.props.dealer}
              goBack={this.props.onClose}
              openStockAudit={this.openStockAudit}
              color={this.props.color}
            />
            <ModalView
              open={this.state.showPhotoSlider}
              close={this.photoSliderClose}
              content={
                <Image
                  fuild
                  centered
                  src={
                    storageBaseUrl + this.state.selectedPhoto.uploadedImageUrl
                  }
                />
              }
              showSave={false}
            />


            <Tab
              style={{ marginTop: 15 }}
              menu={{
                color: "white",
                attached: false,
                tabular: false,
              }}
              panes={this.state.audit.questionnaire.map((q) => {
                return {
                  menuItem: q.name,
                  render: () => (
                    <Questionnaire
                      questionnaireId={q._id}
                      questions={q.questions}
                      editMode={this.state.editMode}
                      onChange={this.onAnswerChange}
                      handlePhotoClick={this.handlePhotoClick}
                      color={this.props.color}
                    />
                  ),
                };
              })}
            />

            <ModalView
              size={'large'}
              open={this.state.showStockAudit}
              close={this.closeStockAudit}
              header={'Stock Audit'}
              content={
                <Grid>
                  <Grid.Row columns={3}>
                    <Grid.Column>
                      <DropDownPicker
                        styles={{ marginTop: 20 }}
                        placeholder={"Product Name"}
                        options={products}
                        onChangeDropDown={(date) => {
                          this.changeProductName(date)
                        }}
                        value={this.state.productName}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <DropDownPicker
                        styles={{ marginTop: 20 }}
                        placeholder={"Product Code"}
                        options={productCodes}
                        onChangeDropDown={(date) => {
                          this.changeProductCode(date)
                        }}
                        value={this.state.productCode}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <DropDownPicker
                        styles={{ marginTop: 20 }}
                        placeholder={"MRP"}
                        options={mrp}
                        onChangeDropDown={(date) => {
                          this.changeMrp(date)
                        }}
                        value={this.state.mrp}
                      />
                    </Grid.Column>

                  </Grid.Row>
                  <Grid.Row>
                    {this.state.audit.stocks &&
                      this.state.audit.stocks.map((stock) => {
                        return (
                          <Segment raised style={{ display: 'flex', width: '100%' }}>
                            <Grid divided>
                              <Grid.Row columns={2}>
                                <Grid.Column width={8}>
                                  <div style={{ padding: "20px" }}>
                                    <span
                                      style={{
                                        lineHeigStockht: 1.5,
                                        color: "#404040",
                                        fontSize: 15,
                                        display: "block",
                                        marginBottom: 10,
                                      }}
                                    >
                                      <b>Product Name: </b>
                                      {stock.prdName}
                                    </span>
                                    <span
                                      style={{
                                        lineHeight: 1.5,
                                        color: "#404040",
                                        fontSize: 15,
                                        display: "block",
                                        marginBottom: 10,
                                      }}
                                    >
                                      <b>month: </b>
                                      {stock.month}
                                    </span>
                                    <span
                                      style={{
                                        lineHeight: 1.5,
                                        color: "#404040",
                                        fontSize: 15,
                                        display: "block",
                                        marginBottom: 10,
                                      }}
                                    >
                                      <b>distCode: </b>
                                      {stock.distCode}
                                    </span>
                                    <span
                                      style={{
                                        lineHeight: 1.5,
                                        color: "#404040",
                                        fontSize: 15,
                                        display: "block",
                                        marginBottom: 10,
                                      }}
                                    >
                                      <b>distName: </b>
                                      {stock.distName}
                                    </span>
                                    <span
                                      style={{
                                        lineHeight: 1.5,
                                        color: "#404040",
                                        fontSize: 15,
                                        display: "block",
                                        marginBottom: 10,
                                      }}
                                    >
                                      <b>zone: </b>
                                      {stock.zone}
                                    </span>
                                    <span
                                      style={{
                                        lineHeight: 1.5,
                                        color: "#404040",
                                        fontSize: 15,
                                        display: "block",
                                        marginBottom: 10,
                                      }}
                                    >
                                      <b>C&FA: </b>
                                      {stock["C&FA"]}
                                    </span>
                                    <span
                                      style={{
                                        lineHeight: 1.5,
                                        color: "#404040",
                                        fontSize: 15,
                                        display: "block",
                                        marginBottom: 10,
                                      }}
                                    >
                                      <b>salvageRefNo: </b>
                                      {stock.salvageRefNo}
                                    </span>
                                    <span
                                      style={{
                                        lineHeight: 1.5,
                                        color: "#404040",
                                        fontSize: 15,
                                        display: "block",
                                        marginBottom: 10,
                                      }}
                                    >
                                      <b>salvageDate: </b>
                                      {stock.salvageDate}
                                    </span>
                                    <span
                                      style={{
                                        lineHeight: 1.5,
                                        color: "#404040",
                                        fontSize: 15,
                                        display: "block",
                                        marginBottom: 10,
                                      }}
                                    >
                                      <b>productCode: </b>
                                      {stock.productCode}
                                    </span>
                                    <span
                                      style={{
                                        lineHeight: 1.5,
                                        color: "#404040",
                                        fontSize: 15,
                                        display: "block",
                                        marginBottom: 10,
                                      }}
                                    >
                                      <b>prdbatCode: </b>
                                      {stock.prdbatCode}
                                    </span>
                                    <span
                                      style={{
                                        lineHeight: 1.5,
                                        color: "#404040",
                                        fontSize: 15,
                                        display: "block",
                                        marginBottom: 10,
                                      }}
                                    >
                                      <b>salvageQty: </b>
                                      {stock.salvageQty}
                                    </span>
                                    <span
                                      style={{
                                        lineHeight: 1.5,
                                        color: "#404040",
                                        fontSize: 15,
                                        display: "block",
                                        marginBottom: 10,
                                      }}
                                    >
                                      <b>mrp: </b>
                                      {stock.mrp}
                                    </span>
                                    <span
                                      style={{
                                        lineHeight: 1.5,
                                        color: "#404040",
                                        fontSize: 15,
                                        display: "block",
                                        marginBottom: 10,
                                      }}
                                    >
                                      <b>claimRate: </b>
                                      {stock.claimRate}
                                    </span>
                                    <span
                                      style={{
                                        lineHeight: 1.5,
                                        color: "#404040",
                                        fontSize: 15,
                                        display: "block",
                                        marginBottom: 10,
                                      }}
                                    >
                                      <b>salAmount: </b>
                                      {stock.salAmount}
                                    </span>
                                  </div>
                                </Grid.Column>
                                <Grid.Column style={{ marginTop: 30 }} width={8}>
                                  {stock.questions.map((question) => {
                                    return (
                                      <div>
                                        <span
                                          style={{
                                            lineHeight: 1.5,
                                            color: "#404040",
                                            fontSize: 15,
                                            display: "inline-block",
                                            marginBottom: 30,
                                            marginRight: 30,
                                          }}
                                        >
                                          <b>{question.question}</b>
                                        </span>

                                        {question.answerType == "text" && (
                                          <TextInput
                                            disabled={!this.state.editMode}
                                            value={question.answer}
                                            onChangeTextInput={(e) => {
                                              this.onStockChange(
                                                stock._id,
                                                question._id,
                                                question.answerType,
                                                e.target.value
                                              )
                                            }}
                                          />

                                        )}

                                        {question.answerType == "date" && (
                                          <MuiPickersUtilsProvider
                                            libInstance={moment}
                                            utils={MomentUtils}
                                          >
                                            <DatePicker
                                              openTo="year"
                                              disabled={!this.state.editMode}
                                              value={
                                                question.answer != null
                                                  ? // ? new moment(question.answer, "DD-MMM-YYYY HH:mm:SS")
                                                  question.answer
                                                  : new moment()
                                              }
                                              onChange={(e) =>
                                                this.onStockChange(
                                                  stock._id,
                                                  question._id,
                                                  question.answerType,
                                                  e.format("DD-MMM-YYYY HH:mm:SS")
                                                )
                                              }
                                            />
                                          </MuiPickersUtilsProvider>
                                        )}
                                      </div>
                                    );
                                  })}
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          </Segment>
                        );
                      })}

                  </Grid.Row>
                </Grid>
              }
              showSave={false}
            />


          </div>
        )}
      </div>
    );
  }
}

const HeaderView = function (props) {
  let {
    selectedAudit,
    editMode,
    onDownloadPhotos,
    onDownloadReport,
    editAudit,
    savedAudit,
    cancelEditAudit,
    dealer,
    goBack,
    openEmailReport,
    openStockAudit,
  } = props;
  return (
    <div>
      <IconPicker
        styles={{
          color: "#808080",
          marginTop: 10,
          marginRight: 20,
          float: "right",
          cursor: "pointer",
        }}
        click={goBack}
        size={"large"}
        name={"arrow left"}
      />
      <h1 style={{ color: getTextColor(props.color) }}>
        {selectedAudit.auditName}
      </h1>
      <div
        style={{
          color: "black",
          fontsize: "15px",
          fontWeight: "bold",
          paddingBottom: "10px",
          // marginLeft: "10px",
          marginTop: "-15px",
        }}
      >
        {`${dealer.name} - ${dealer.salvageNumber} - ${dealer.address}`}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
        }}
      >
        <ConstantLabel
          title={"Show Photos"}
          size={"medium"}
          onClick={() => {
            onDownloadPhotos(selectedAudit._id);
          }}
          styles={{
            cursor: "pointer",
            backgroundColor: getMainBackground(props.color),
            color: getFontColor(props.color),
          }}
          iconName={"picture"}
        />


        {!editMode && (
          <ConstantLabel
            title={"Edit audit"}
            size={"medium"}
            onClick={editAudit}
            styles={{
              cursor: "pointer",
              backgroundColor: getMainBackground(props.color),
              color: getFontColor(props.color),
            }}
            iconName={"edit"}
          />

        )}

        {editMode && (
          <div >
            <ConstantLabel
              title={"Save audit"}
              size={"medium"}
              onClick={savedAudit}
              styles={{
                cursor: "pointer",
                backgroundColor: getMainBackground(props.color),
                color: getFontColor(props.color),
              }}
              iconName={"save"}
            />
            <ConstantLabel
              title={"Cancel"}
              size={"medium"}
              onClick={cancelEditAudit}
              styles={{
                cursor: "pointer",
                backgroundColor: "grey",
                color: "white",
              }}
              iconName={"cancel"}
            />


          </div>
        )}
        <ConstantLabel
          title={"Download Report"}
          size={"medium"}
          onClick={() => {
            onDownloadReport(selectedAudit.templateId);
          }} styles={{
            cursor: "pointer",
            backgroundColor: getMainBackground(props.color),
            color: getFontColor(props.color),
          }}
          iconName={"table"}
        />

        <ConstantLabel
          title={"Stock Audit"}
          size={"medium"}
          onClick={() => openStockAudit()}
          styles={{
            cursor: "pointer",
            backgroundColor: getMainBackground(props.color),
            color: getFontColor(props.color),
          }}
          iconName={"slack"}
        />

        <ConstantLabel
          title={"Email Report"}
          size={"medium"}
          onClick={() => openEmailReport()
          }
          styles={{
            cursor: "pointer",
            backgroundColor: getMainBackground(props.color),
            color: getFontColor(props.color),
          }}
          iconName={"email"}
        />

      </div>

      <div>
        By{" "}
        {selectedAudit.auditorId != null && selectedAudit.auditorId.displayName}{" "}
        on {moment(selectedAudit.createdAt).format("DD-MM-YYYY  HH:MM:SS")}
      </div>
    </div>
  );
};

const Questionnaire = function (props) {
  let questionViews = [];
  let { questions, editMode, onChange, questionnaireId, handlePhotoClick } =
    props;

  questions
    .filter((eachQuestion) => {
      if (eachQuestion.hasOwnProperty("dependency")) {
        let question = null;
        let questionId = eachQuestion.dependency.questionId;
        let answerNeeded = eachQuestion.dependency.answer;
        questions.map((q) => {
          if (q._id == questionId) {
            question = q;
          }
        });
        if (question.answer != answerNeeded) {
          return false;
        }
        return true;
      } else {
        return true;
      }
    })
    .map((q) => {
      questionViews.push(
        <Segment
          key={q._id}
          style={{ backgroundColor: getMatchingTint(props.color) }}
        >
          <span
            style={{
              lineHeight: 1.5,
              color: "#404040",
              fontSize: 15,
              display: "block",
              marginBottom: 10,
            }}
          >
            {q.question}
          </span>
          {q.answerType === "auto" && (
            <TextInput
              styles={{ marginLeft: "40px" }}

              disabled={!editMode}
              value={q.answer}
              onChangeTextInput={(e) => {
                onChange(questionnaireId, q._id, q.answerType, e.target.value)

              }}
            />

          )}
          {q.answerType === "text" && (
            <TextInput
              styles={{ marginLeft: "40px" }}

              disabled={!editMode}
              value={q.answer}
              onChangeTextInput={(e) => {
                onChange(questionnaireId, q._id, q.answerType, e.target.value)

              }}
            />

          )}

          {q.answerType === "date" && (
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <KeyboardDatePicker
                autoOk={true}
                variant="inline"
                format="YYYY-MM-DD"
                value={moment(q.answer)}
                // InputAdornmentProps={{ position: "start" }}
                onChange={(date, _) =>
                  onChange(questionnaireId, q._id, q.answerType, date)
                }
                disabled={!editMode}
              />
            </MuiPickersUtilsProvider>
          )}

          {q.answerType === "options" && (
            <DropDownPicker
              styles={{ display: "inline-block", width: "50%" }}
              placeholder={"select an option"}
              options={q.options.map((label, i) => {
                return {
                  value: label.value,
                  text: label.label,
                  key: label.value,
                };
              })}
              onChangeDropDown={(date) => {
                onChange(questionnaireId, q._id, q.answerType, date)
              }}
              value={Number(q.answer)}
            />


          )}



          {q.photos.length > 0 && (
            <Image.Group size="mini" style={{ cursor: "pointer" }}>
              {q.photos.map((p, i) => {
                if (p.uploadedImageUrl != null) {
                  return (
                    <Image
                      key={i}
                      src={storageBaseUrl + p.uploadedImageUrl}
                      onClick={((k) => {
                        return (e) => handlePhotoClick(e, k);
                      })(p)}
                    />
                  );
                }
              })}
            </Image.Group>
          )}
        </Segment>
      );
    });

  return <div>{questionViews}</div>;
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    jandj: state.jandj,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getJandJAudit,
      saveAudit,
      getJandJAuditReport,
      mailJandJAuditReport,
    },
    dispatch
  );
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(JandJAuditView)
);
