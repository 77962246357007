import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn } from "../../util";
import {
  Label,
  Card,
  Modal,
  Button,
  List,
  Radio,
  Tab,
} from "semantic-ui-react";
import ButtonHandler from '../commonViews/ButtonHandler.jsx'
import { Icon } from "semantic-ui-react";
import IconPicker from "../commonViews/IconPicker";
import {
  pandgAuditExportAction,
  pandgAuditSubmitAction,
} from "../../actions/pandg_actions.js";
import PandGHeader from "./PandGHeader.jsx";
import {
  getTextColor,
  getMainBackground,
  getFontColor,
  getMatchingTint,
} from "../../ThemeHandlers/theme";
import ConstantLabel from "../commonViews/ConstantLabel";
class PandGAuditsQuestionare extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTc: null,
      showPopup: false,
      editMode: false,
    };
    this.auditForEdit = {};
  }

  componentWillReceiveProps(nextProps) { }

  componentWillMount() { }

  componentDidMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
      return false;
    }
    return true;
  }

  selectTc = (tc) => {
    console.log(tc, "tc");
    this.setState({
      currentTc: tc,
      showPopup: true,
      editMode: false,
      houseDetails: false,
    });
  };

  viewHouseDetails = (tc) => {
    this.setState({
      currentTc: tc,
      showPopup: true,
      editMode: false,
      houseDetails: true,
    });
  };

  closePopup = () => {
    console.log("closing");
    this.setState({ currentTc: {} });

    // restore the actual audit data.
    this.setState({ currentQuestion: {} });
    this.auditForEdit = {};
    this.setState({
      showPopup: false,
      editMode: false,
      houseDetails: false,
      currentTc: null,
    });
  };

  onQuestionAnswerChange = (question) => {
    question.answer = question.answer === "Yes" ? "No" : "Yes";
    // just for re-rendering the UI
    this.setState({ currentQuestion: question });
  };

  getHouseQuestions = (questions) => {
    return questions.map((eachQuestion, index) => {
      return (
        <List.Item key={index}>
          <div style={{ marginTop: 10 }}>
            <span
              style={{
                verticalAlign: "middle",
                width: "70%",
                display: "inline-block",
                fontSize: 15,
                fontWeight: "bold",
              }}
            >
              {eachQuestion.question}
            </span>
            <span
              style={{ verticalAlign: "middle", fontSize: 15, marginLeft: 15 }}
            >
              <Radio
                toggle
                onChange={(evt) => this.onQuestionAnswerChange(eachQuestion)}
                disabled={this.state.editMode != true}
                checked={eachQuestion.answer === "Yes" ? true : false}
              />{" "}
              {eachQuestion.answer === "Yes" ? eachQuestion.answer : "No"}
            </span>
          </div>
        </List.Item>
      );
    });
  };

  editAudit = () => this.setState({ editMode: true });

  saveAudit = (audit) => {
    this.setState({ showPopup: false, editMode: false });
    // replace the edited audit values with the original audit data
    this.props.pandg.selectedPandGAudit = audit;
    this.props.pandgAuditSubmit(audit);
  };

  onDownloadReport = () => {
    this.props.pandgAuditExport(this.props.pandg.selectedPandGAudit._id);
  };

  onDownloadPhotos = () => {
    window.open(`#/images/` + this.props.pandg.selectedPandGAudit._id);
  };

  onMailReport = () => { };

  render() {
    var selectedAudit = this.props.pandg.selectedPandGAudit,
      audit = this.auditForEdit;
    // create a copy of audit to use it for editing instead of modifying the original data.
    if (selectedAudit._id != this.auditForEdit._id) {
      audit = this.auditForEdit = JSON.parse(
        JSON.stringify(this.props.pandg.selectedPandGAudit)
      );
    }

    return (
      <div>
        <PandGHeader
          audit={this.auditForEdit}
          onClick={this.onDownloadReport}
          onPhotos={this.onDownloadPhotos}
          color={this.props.color}
        />

        <div style={{ marginTop: 30 }}>
          <Card.Group columns={"three"}>
            {!audit
              ? null
              : audit.tcs.map((tcs, i) => {
                var housesLength = tcs[this.props.auditsPropKey].length;
                return (
                  <div key={i}>
                    <Card style={{ margin: 5 }}>
                      <Card.Content
                        style={{
                          backgroundColor: getMainBackground(
                            this.props.color
                          ),
                          padding: 6,
                        }}
                      >
                        <div
                          style={{
                            color: getFontColor(this.props.color),
                            fontWeight: "bold",
                          }}
                        >
                          {tcs.name}
                        </div>
                      </Card.Content>
                      <Card.Content
                        description={tcs.code}
                        style={{ wordBreak: "break-word" }}
                      />
                      <Card.Content extra>
                        <div style={{ float: "right" }}>
                          <ConstantLabel
                            title={"Details"}
                            size={"medium"}
                            as={"a"}
                            onClick={() => this.selectTc(audit.tcs[i])}
                            styles={{
                              backgroundColor: getMatchingTint(
                                this.props.color
                              ),
                              color: getTextColor(this.props.color),
                            }}
                            iconName={"info circle"}
                          />


                          <Label
                            as="a"
                            onClick={() =>
                              this.viewHouseDetails(audit.tcs[i])
                            }
                            className={housesLength === 0 ? "disabled" : ""}
                            style={{
                              pointerEvents:
                                housesLength == 0 ? "none" : "auto",
                              backgroundColor: getMatchingTint(
                                this.props.color
                              ),
                              color: getTextColor(this.props.color),
                            }}
                          >
                            <IconPicker name="home" /> {this.props.auditPropTitle}(
                            {housesLength})
                          </Label>
                        </div>
                      </Card.Content>
                    </Card>
                  </div>
                );
              })}
          </Card.Group>
          {this.state.showPopup === true ? (
            <Modal
              open={this.state.showPopup}
              onClose={this.state.closePopup}
              id="pandgQuestionarie"
            >
              <Modal.Header>
                {this.state.currentTc.name} - {this.state.currentTc.code} -{" "}
                {this.state.currentTc.teamCode} (
                {this.state.currentTc[this.props.auditsPropKey].length}
                )
                <br />
                <ConstantLabel
                  title={`Name - Code - Team Code${this.props.auditPropTitle} Count`}
                  size={"tiny"}
                  styles={{
                    transform: "translateY(-7px)",
                    paddingLeft: 0,
                    backgroundColor: "transparent",
                    color: "#d4d4d5",
                  }}
                />
                {/* <Label
                  size="tiny"
                  style={{
                    transform: "translateY(-7px)",
                    paddingLeft: 0,
                    backgroundColor: "transparent",
                    color: "#d4d4d5",
                  }}
                >
                  Name - Code - Team Code ({this.props.auditPropTitle} Count)
                </Label> */}
              </Modal.Header>
              <Modal.Content>
                <Modal.Description>
                  <List>
                    {!this.state.houseDetails &&
                      this.getHouseQuestions(this.state.currentTc.tcQuestions)}
                    {this.state.houseDetails && (
                      <Tab
                        style={{ marginTop: 15 }}
                        menu={{
                          color: "teal",
                          fluid: true,
                          vertical: true,
                          tabular: false,
                          inverted: true,
                          attached: true,
                          pointing: true,
                        }}
                        panes={this.state.currentTc[
                          this.props.auditsPropKey
                        ].map((house, index) => {
                          return {
                            key: index,
                            menuItem: house.name,
                            render: ((house) => {
                              return () => {
                                return (
                                  <Tab.Pane key={index}>
                                    {this.getHouseQuestions(
                                      house[this.props.auditQuestionsKey]
                                    )}
                                  </Tab.Pane>
                                );
                              };
                            })(house),
                          };
                        })}
                      />
                    )}
                  </List>
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                  {this.state.editMode && (
                    <ButtonHandler color={'black'}
                      click={() => {
                        this.saveAudit(audit);
                      }}
                      title={'Save'}
                    />

                  )}
                  {!this.state.editMode && (
                    <ButtonHandler color={'black'}
                      click={this.editAudit}
                      title={'Edit'}
                    />

                  )}
                  <ButtonHandler color={'black'}
                    click={this.closePopup}
                    title={'Cancel'}
                  />

                </div>

              </Modal.Actions>
            </Modal>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    pandg: state.pandg,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      pandgAuditExport: pandgAuditExportAction,
      pandgAuditSubmit: pandgAuditSubmitAction,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PandGAuditsQuestionare)
);
