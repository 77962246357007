import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import IconPicker from "../commonViews/IconPicker";
import TableView from "../commonViews/TableView";
import UdaanStores from "./UdaanStores.jsx";
import {
  fetchUdaanTemplatesAction,
  fetchUdaanAuditReportAction,
} from "../../actions/udaan_actions";
import { getTextColor } from "../../ThemeHandlers/theme";
export class UdaanTemplates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTemplate: {},
      isTemplatesView: true,
      template: [],
      color: "",
      loading: false,
    };
  }
  componentDidMount() {
    if (this.props.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
    this.setState({ loading: true });
    this.props.fetchUdaanTemplates({
      onSuccess: () => {
        this.setState({ loading: false });
      },
      onFailure: () => {
        this.setState({ loading: false });
      },
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth.color !== prevProps.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
    if (this.props.udaan.templates !== prevProps.udaan.templates) {
      this.setState({ template: this.props.udaan.templates });
    }
  }

  handleTableViewAuditClick = (template) => {
    this.setState({ selectedTemplate: template, isTemplatesView: false });
  };
  handleCloseClick = () => {
    this.setState({ isTemplatesView: true });
  };

  handleDownloadAgentReportClick = (template) => {
    if (!template._id) {
      this.setState({ showReportDataNotFound: true });
      return;
    } else {
      this.props.fetchUdaanAuditReportAction(template._id);
    }
  };

  render() {
    var template = this.state.template.length !== 0 ? this.state.template : [];
    var col = [
      {
        title: "Template Name",
        field: "auditName",

        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.auditName}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.auditName.toLowerCase().includes(term.toLowerCase()),
      },
      {
        title: "Report",
        field: "",
        align: "right",
        render: (rowData) => {
          if (rowData._id) {
            return (
              <IconPicker
                size={"large"}
                name={"file excel outline"}
                styles={{
                  color: getTextColor(this.state.color),
                  cursor: "pointer",
                }}
                click={() => this.handleDownloadAgentReportClick(rowData)}
              />
            );
          }
        },
      },
    ];

    return (
      <div style={{ flexGrow: 1, display: "flex", flexFlow: "column" }}>
        {this.state.isTemplatesView && (
          <div>
            <h1 style={{ color: getTextColor(this.state.color) }}>Templates</h1>
            <TableView
              data={template}
              columns={col}
              selection={false}
              load={this.state.loading}
            />
          </div>
        )}
        {!this.state.isTemplatesView && (
          // <div style={{ flexGrow: 1, display: "flex" }}>
          <UdaanStores
            template={this.state.selectedTemplate}
            onClose={this.handleCloseClick}
            color={this.state.color}
          />
          // </div>
        )}
      </div>
    );
  }
}
function AuditTableCell(props) {
  function onClick() {
    props.onClick(props.row);
  }
  return (
    <div style={props.style} onClick={onClick}>
      {props.text}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    udaan: state.udaan,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchUdaanTemplates: fetchUdaanTemplatesAction,
      fetchUdaanAuditReportAction: fetchUdaanAuditReportAction,
    },
    dispatch
  );
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UdaanTemplates)
);
