import {
    FETCH_YAMAHA_TEMPLATENAME_SUCCESS_ACTION,
    FETCH_YAMAHA_TEMPLATENAME_FAILURE_ACTION,
    FETCH_YAMAHA_DEALERCOUNT_SUCCESS_ACTION,
    FETCH_YAMAHA_DEALERCOUNT_FAILURE_ACTION,
    FETCH_YAMAHA_DEALERCODE_SUCCESS_ACTION,
    FETCH_YAMAHA_DEALERCODE_FAILURE_ACTION,
    FETCH_YAMAHA_DEALERS_SUCCESS_ACTION,
    FETCH_YAMAHA_DEALERS_FAILURE_ACTION,
    FETCH_YAMAHA_AUDITS_SUCCESS_ACTION,
    FETCH_YAMAHA_AUDITS_FAILURE_ACTION,
    SAVE_YAMAHA_AUDITS_FAILURE_ACTION,
    SAVE_YAMAHA_AUDITS_SUCCESS_ACTION
} from '../actions/types';

const INIT_STATE = {};

 function yamaha(state = INIT_STATE,action) {
    state = Object.assign({}, state, {});

    switch (action.type) {
        case FETCH_YAMAHA_TEMPLATENAME_SUCCESS_ACTION:
            state.templates = action.payload.templates;
            state.fetchYamahaTemplateNameError = false;
            return state;

        case FETCH_YAMAHA_TEMPLATENAME_FAILURE_ACTION:
            state.fetchYamahaTemplateNameError = true;
            return state;

        case FETCH_YAMAHA_DEALERCOUNT_SUCCESS_ACTION:
            state.templates = action.payload.templates;
            state.fetchYamahaDealerCountError = false;
            return state;

        case FETCH_YAMAHA_DEALERCOUNT_FAILURE_ACTION:
            state.fetchYamahaDealerCountError = true;
            return state;

        case FETCH_YAMAHA_DEALERCODE_SUCCESS_ACTION:
            state.templates = action.payload.templates;
            state.fetchYamahaDealerCodeError = false;
            return state;

        case FETCH_YAMAHA_DEALERCODE_FAILURE_ACTION:
            state.fetchYamahaDealerCodeError = true;
            return state;

        case FETCH_YAMAHA_DEALERS_SUCCESS_ACTION:
            return Object.assign({}, state, {
                dealers: action.payload.dealers,
                isFetchYamahaDealersSuccess: true
            });

        case FETCH_YAMAHA_DEALERS_FAILURE_ACTION:
            return Object.assign({}, state, {
                isFetchYamahaDealersSuccess: false
            });

        case FETCH_YAMAHA_AUDITS_SUCCESS_ACTION:
            return Object.assign({}, state, {
                audits: action.payload.audits,
                isFetchYamahaAuditsSuccess: true
            });

        case FETCH_YAMAHA_AUDITS_FAILURE_ACTION:
            return Object.assign({}, state, {
                isFetchYamahaAuditsSuccess: false
            });
        
        case SAVE_YAMAHA_AUDITS_SUCCESS_ACTION:
            return Object.assign({}, state, {
                saveAudit: action.payload.audits,
                isSaveYamahaAuditsSuccess: true
            });

        case SAVE_YAMAHA_AUDITS_FAILURE_ACTION:
            return Object.assign({}, state, {
                isSaveYamahaAuditsSuccess: false
            });
        default:
             return state    
    }
}

export default yamaha