import React, { Component } from "react";
import { Segment, Grid, Icon, Dimmer, Loader } from "semantic-ui-react";
import { Button, Modal } from "semantic-ui-react";
import {
  getTextColor,
  getMainBackground,
  getMatchingTint,
  getFontColor,
} from "../../ThemeHandlers/theme";
export class CommonPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div
        style={{
          marginLeft: "2vw",
          marginTop: "7vh",
          fontWeight: "bold",
          fontFamily: "Arial, Helvetica, sans-serif",
          height: "100vh",
        }}
      >
        <Dimmer active={this.props.controlPanel.uploadIncomplete}>
          <Loader size="massive">Uploading</Loader>
        </Dimmer>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column stretched>
              <Segment.Group>
                <Segment
                  style={{
                    backgroundColor: getMainBackground(this.props.color),
                  }}
                  tertiary
                >
                  <span
                    style={{
                      paddingLeft: "10vw",
                      color: getFontColor(this.props.color),
                    }}
                  >
                    Upload User CSV to add Users
                  </span>
                </Segment>
                <Segment
                  style={{ backgroundColor: getMatchingTint(this.props.color) }}
                >
                  <input
                    type={"file"}
                    accept=".csv"
                    onChange={this.props.handleUserFileUpload}
                  />

                  <Button
                    animated
                    onClick={this.props.uploadUserData}
                    style={{
                      marginLeft: 20,
                      width: "15vw",
                      backgroundColor: getMainBackground(this.props.color),
                      color: getFontColor(this.props.color),
                      fontFamily: "Arial, Helvetica, sans-serif",
                    }}
                  >
                    <Button.Content visible>Upload User CSV</Button.Content>
                    <Button.Content hidden>
                      <Icon name="upload" />
                    </Button.Content>
                  </Button>
                </Segment>
              </Segment.Group>
            </Grid.Column>
            <Grid.Column>
              <Segment.Group>
                <Segment
                  style={{
                    backgroundColor: getMainBackground(this.props.color),
                  }}
                  tertiary
                >
                  <span
                    style={{
                      paddingLeft: "10vw",
                      color: getFontColor(this.props.color),
                    }}
                  >
                    Upload Dealer CSV to add Dealers
                  </span>
                </Segment>
                <Segment
                  style={{
                    backgroundColor: getMatchingTint(this.props.color),
                  }}
                >
                  <input
                    type={"file"}
                    accept=".csv"
                    onChange={this.props.handleDealerFileUpload}
                  />

                  <Button
                    animated
                    onClick={this.props.uploadDealerData}
                    style={{
                      marginLeft: 20,
                      width: "15vw",
                      backgroundColor: getMainBackground(this.props.color),
                      color: getFontColor(this.props.color),
                      fontFamily: "Arial, Helvetica, sans-serif",
                    }}
                  >
                    <Button.Content visible>Upload Dealer CSV</Button.Content>
                    <Button.Content hidden>
                      <Icon name="upload" />
                    </Button.Content>
                  </Button>
                </Segment>
              </Segment.Group>
            </Grid.Column>
            <Grid.Column style={{ marginTop: "4vh" }}>
              <Segment.Group>
                <Segment
                  style={{
                    backgroundColor: getMainBackground(this.props.color),
                  }}
                  tertiary
                >
                  <span
                    style={{
                      paddingLeft: "10vw",
                      color: getFontColor(this.props.color),
                    }}
                  >
                    Upload Vehicle CSV to add Vehicles
                  </span>
                </Segment>
                <Segment
                  style={{
                    backgroundColor: getMatchingTint(this.props.color),
                  }}
                >
                  <input
                    type={"file"}
                    accept=".csv"
                    onChange={this.props.handleVehicleFileUpload}
                  />
                  <Button
                    animated
                    onClick={this.props.uploadVehicleData}
                    style={{
                      marginLeft: 20,
                      width: "15vw",
                      backgroundColor: getMainBackground(this.props.color),
                      color: getFontColor(this.props.color),
                      fontFamily: "Arial, Helvetica, sans-serif",
                    }}
                  >
                    <Button.Content visible>Upload Vehicle CSV</Button.Content>
                    <Button.Content hidden>
                      <Icon name="upload" />
                    </Button.Content>
                  </Button>
                </Segment>
              </Segment.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {this.props.controlPanel.showLog && (
          <Modal
            close={this.props.closeLog}
            open={this.props.controlPanel.showLog}
          >
            <Modal.Header>Upload Report</Modal.Header>
            <Modal.Content>
              <div
                dangerouslySetInnerHTML={{
                  __html: this.props.controlPanel.log,
                }}
              />
            </Modal.Content>
            <Modal.Actions>
              <Button color="black" onClick={this.props.closeLog}>
                Dismiss
              </Button>
            </Modal.Actions>
          </Modal>
        )}
      </div>
    );
  }
}

export default CommonPanel;
