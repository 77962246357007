import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import DiamlerKeyDealers from "./DiamlerKeyDealers.jsx";
import { Dropdown } from "semantic-ui-react";

import { fetchDiamlerKeyTemplatesAction } from "../../actions/diamler_key_action";
import TableView from "../commonViews/TableView.jsx";
import {
  getTextColor,

} from "../../ThemeHandlers/theme";
import DropDownPicker from "../commonViews/DropDownPicker.jsx";

class DiamlerKeyTemplate extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedTemplate: {},
      isTemplatesView: true,
      showReportDataNotFound: false,
      year: new Date().getFullYear(),
      template: [],
      color: "",
      loading: false,
    };
  }
  componentDidMount() {
    if (this.props.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
    this.setState({ loading: true });
    this.props.fetchDiamlerKeyTemplates({
      year: this.state.year,
      onSuccess: () => {
        this.setState({ loading: false });
      },
      onFailure: () => {
        this.setState({ loading: false });
      },
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth.color !== prevProps.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
    if (this.props.DiamlerKey.templates !== prevProps.DiamlerKey.templates) {
      this.setState({ template: this.props.DiamlerKey.templates });
    }
  }

  handleTableViewAuditClick = (template) => {
    this.setState({ selectedTemplate: template, isTemplatesView: false });
  };
  handleCloseClick = () => {
    this.setState({ isTemplatesView: true });
  };

  onTemplateYearChange = (data) => {
    this.setState({ loading: true, year: data });
    this.props.fetchDiamlerKeyTemplates({
      year: data,
      onSuccess: () => {
        this.setState({ loading: false });
      },
      onFailure: () => {
        this.setState({ loading: false });
      },
    });
  };

  render() {
    var templates = this.state.template ? this.state.template : [];

    var years = [
      {
        value: 2017,
        text: "2017",
        key: "2017",
      },
      {
        value: 2018,
        text: "2018",
        key: "2018",
      },
      {
        value: 2019,
        text: "2019",
        key: "2019",
      },
      {
        value: 2020,
        text: "2020",
        key: "2020",
      },
      {
        value: 2021,
        text: "2021",
        key: "2021",
      },
      {
        value: 2022,
        text: "2022",
        key: "2022",
      },
      {
        value: 2023,
        text: "2023",
        key: "2023",
      },
    ];

    var newKey = [
      {
        title: "Template Name",
        field: "auditName",
      },
      {
        title: "Vehicle Count",
        field: "vehicleCount",
      },
      {
        title: "Dealer Count",
        field: "dealerCount",
      },
    ];

    return (
      <div style={{ flexGrow: 1, display: "flex", flexFlow: "column" }}>
        {this.state.isTemplatesView && (
          <div>
            <div
              style={{
                display: "inline-block",
                marginBottom: 20,
              }}
            >
              <h1
                style={{
                  display: "inline-block",
                  color: getTextColor(this.state.color),
                }}
              >
                Templates
              </h1>
              <DropDownPicker value={this.state.year} placeholder={"Select Year"}
                styles={{ zIndex: 100, width: 300 }}
                options={years}
                onChangeDropDown={(data) => { this.onTemplateYearChange(data) }}
              />

            </div>
            <TableView
              data={templates}
              columns={newKey}
              selection={false}
              onRowClick={(event, rowData) => {
                this.handleTableViewAuditClick(rowData);
              }}
              load={this.state.loading}
            />
            {/* <Table
            data={templates}
            columns={columns}
            rowClick={this.handleTableViewAuditClick}
          /> */}
          </div>
        )}
        {!this.state.isTemplatesView && (
          <div style={{ flexGrow: 1, display: "flex" }}>
            <DiamlerKeyDealers
              template={this.state.selectedTemplate}
              onClose={this.handleCloseClick}
              color={this.state.color}
            />
          </div>
        )}
      </div>
    );
  }
}
function AuditTableCell(props) {
  function onClick() {
    props.onClick(props.row);
  }
  return (
    <div style={props.style} onClick={onClick}>
      {props.text}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    DiamlerKey: state.DiamlerKey,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchDiamlerKeyTemplates: fetchDiamlerKeyTemplatesAction,
    },
    dispatch
  );
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DiamlerKeyTemplate)
);
