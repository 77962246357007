import React from "react";
// import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
// import {fetchAtmTemplatesAction,downloadAtmReportAction} from '../../actions/bank_action'
import {
  fetchHershysCheckTemplatesAction,
  fetchHershysCheckMasterAction,
  setSelectedTemplateData,
  fetchAuditorsDetailAction,
} from "../../actions/HershysCheckAction";
import Master from "./Master";
import TableView from "../commonViews/TableView";

class Template extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      templates: [],
      thisTemplatesView: true,
    };
  }

  componentDidMount() {
    this.props.fetchHershysCheckTemplatesAction();
  }

  componentDidUpdate(prevProps) {
    if (this.props.hershysCheck.template !== prevProps.hershysCheck.template) {
      this.setState({ templates: this.props.hershysCheck.template });
    }
  }

  handleCloseClick = () => {
    this.setState({ thisTemplatesView: true });
  };

  handleTableViewAuditClick = (template) => {
    this.props.fetchHershysCheckMasterAction(template._id);
    this.props.setSelectedTemplateData(template);
    this.props.fetchAuditorsDetailAction();
    this.setState({
      SelectedTemplate: template,
      thisTemplatesView: false,
    });
  };

  downloadReport = (template) => {
    // this.props.downloadAtmReportAction(template);
  };

  hersheyscloseEditUser = () => {
    this.setState({ modalOpen: false });
  };

  render() {
    var templates = this.state.templates ? this.state.templates : [];
    var col = [
      {
        title: "Template Name",
        field: "auditName",
      },
    ];

    var columns = [
      {
        Header: "Template Name",
        accessor: "auditName",
        style: { textAlign: "center", cursor: "pointer" },
        Cell: ({ row: { original } }) => {
          return <span>{original.auditName}</span>;
        },
      },
    ];

    return (
      <div
        style={{
          // flexGrow: 1,
          display: "flex",
          flexFlow: "column",
          width: "100%",
        }}
      >
        <NotificationContainer />
        {this.state.thisTemplatesView && (
          <div>
            <h1>Templates</h1>
            <TableView
              selection={false}
              columns={col}
              data={templates}
              onRowClick={(event, rowData) => {
                this.handleTableViewAuditClick(rowData);
              }}
              actions={[]}
            />

            {/* <Table
              data={templates}
              columns={columns}
              rowClick={this.handleTableViewAuditClick}
            /> */}
          </div>
        )}
        {!this.state.thisTemplatesView && (
          <div style={{ flexGrow: 1 }}>
            <Master
              selectedTemplate={this.state.SelectedTemplate}
              onClose={this.handleCloseClick}
            />
          </div>
        )}
      </div>
    );
  }
}

function AuditTableCell(props) {
  function onClick() {
    props.onClick(props.row);
  }
  return (
    <div style={props.style} onClick={onClick}>
      {props.text}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    hershysCheck: state.hershysCheck,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchHershysCheckTemplatesAction,
      fetchHershysCheckMasterAction,
      setSelectedTemplateData,
      fetchAuditorsDetailAction,
    },
    dispatch
  );
};

// Hersheys.PropTypes = {
//   dealers: PropTypes.array
// };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Template)
);
