import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import TableView from "../../commonViews/TableView.jsx";
import {
  Segment,
  Image,
  Grid,
} from "semantic-ui-react";
import IconPicker from "../../commonViews/IconPicker.jsx";
import ConstantLabel from "../../commonViews/ConstantLabel.jsx";
import ModalView from "../../commonViews/ModalView.jsx";
import Metrics from "../../commonViews/Metrics.jsx";
import Panes from "./panes.jsx";

import {
  fetchStateGraphAction,
  fetchCountryGraphAction,
  fetchStoreGraphAction,
  fetchProductGraphAction,
  fetchCannonDashboardScoreAction,
  fetchDashboardDisplayPhotoAction,
  fetchDashboardDisplay,
  fetchDashboardCategoryAuditsAction,
  downloadCompiledReportAction,
} from "../../../actions/cannon_action";
import config from "./../../../config.js";
import { Header } from "semantic-ui-react";
import { getMainBackground, getFontColor } from "../../../ThemeHandlers/theme.js";

const storageBaseUrl = config["storage_base_url"];

export class DashboardIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      regions: [],
      PlanogramData: [],
      AppliancesData: [],
      HygieneData: [],
      data: [],
      productsData: [],
      productAudits: [],
      categoryAudits: [],
      storeId: "",
      metrics: {},
      DashTableView: true,
      openCarousel: false,
      openDisplayAudits: false,
      photo: [],
      allDisplay: [],
      open: false,
      displayName: "",
      productName: "",
      showCategoryAudits: false,
      templateId: "",
      dbCode: "",
      load: false,
    };
  }
  componentDidMount() {
    let region = [];

    if (this.props.cannon.cannonStoreList) {
      this.props.cannon.cannonStoreList.forEach((data) => {
        if (data.status === "Completed" || data.status == 'Verified') {
          region.push(data);
        }
        this.setState({ templateId: data.templateId });
      });
    }

    if (this.props.cannon.cannonRegionData) {
      this.setState({
        data: this.props.cannon.cannonRegionData,
        metrics: this.props.cannon.allMetrics,
      });
    }
    this.setState({
      regions: region,
    });
  }

  componentWillReceiveProps(nextProps) {
    let Photos = [];

    if (
      nextProps.cannon.cannonRegionData !== this.props.cannon.cannonRegionData
    ) {
      this.setState({
        data: nextProps.cannon.cannonRegionData,
      });
    }
    if (
      nextProps.cannon.dashboardPlanogramData !==
      this.props.cannon.dashboardPlanogramData
    ) {
      this.setState({
        PlanogramData: nextProps.cannon.dashboardPlanogramData, //dashboard Data
      });
    }
    if (
      nextProps.cannon.dashboardAppliancesData !==
      this.props.cannon.dashboardAppliancesData
    ) {
      this.setState({
        AppliancesData: nextProps.cannon.dashboardAppliancesData, //dashboard Data
      });
    }
    if (
      nextProps.cannon.dashboardHygieneData !==
      this.props.cannon.dashboardHygieneData
    ) {
      this.setState({
        HygieneData: nextProps.cannon.dashboardHygieneData, //dashboard Data
      });
    }
    if (
      nextProps.cannon.dashboardDisplayPhotos !==
      this.props.cannon.dashboardDisplayPhotos
    ) {
      nextProps.cannon.dashboardDisplayPhotos.forEach((data) => {
        Photos.push({
          render: () => {
            return <Image src={storageBaseUrl + data.photoUrl} />;
          },
        });
      });

      this.setState({
        photo: Photos,
        allDisplay: nextProps.cannon.dashboardDisplayPhotos,
      });
    }
    if (
      nextProps.cannon.displayProducts !== this.props.cannon.displayProducts
    ) {
      this.setState({
        productsData: nextProps.cannon.displayProducts,
        openDisplayAudits:
          nextProps.cannon.displayProducts.length === 0 ? true : false,
      });
    }
    if (nextProps.cannon.productAudits !== this.props.cannon.productAudits) {
      this.setState({
        productAudits: nextProps.cannon.productAudits,
      });
    }
  }

  onClose = () => {
    if (this.state.openCarousel === true) {
      this.setState({
        openCarousel: false,
        DashTableView: false,
        productsData: [],
        productAudits: [],
      });
    } else {
      this.setState({
        DashTableView: true,
      });
    }
  };

  CloseCategory = () => {
    this.setState({
      showCategoryAudits: false,
      openCarousel: true,

      categoryAudits: [],
    });
  };
  handleTableViewAuditClick = (store) => {
    this.setState({
      load: true,
      storeId: store._id,
      storeName: store.storeName,
      dbCode: store.distributorId,
    });
    this.props.fetchCannonDashboardScoreAction({
      storeId: store._id,
      templateId: store.templateId,
      onSuccess: (data) => {
        this.setState({
          DashTableView: false,
          load: false,
        });
      },
      onFailure: () => {
        this.setState({
          load: false,
        });
      },
    });
  };

  handleShowPhotos = (display) => {
    this.props.fetchDashboardDisplay({
      storeId: this.state.storeId,
      display: display.display,
      onSuccess: (data) => { },
      onFailure: () => { },
    });

    this.setState({
      //for normal view
      openCarousel: true,
      displayName: display.display,

      //for modall
      // open: true
    });
  };

  searchFilter = (filter, row) => {
    var flatData = JSON.stringify(row._original);
    return flatData.toLowerCase().includes(filter.value.toLowerCase());
  };

  getPhotos = () => {
    this.props.fetchDashboardDisplayPhotoAction({
      storeId: this.state.storeId,
      display: this.state.displayName,
      category: this.state.productName,
      onSuccess: (data) => { },
      onFailure: () => { },
    });
    this.setState({
      //5291436
      open: true,
    });
  };
  getCategoryAudits = (category) => {
    this.props.fetchDashboardCategoryAuditsAction({
      storeId: this.state.storeId,
      display: this.state.displayName,
      category: category.category,
      onSuccess: (data) => {
        this.setState({
          categoryAudits: data.categories,
        });
      },
      onFailure: () => { },
    });
    this.setState({
      productName: category.category,
      showCategoryAudits: true,
    });
  };
  download = () => {
    this.props.downloadCompiledReportAction({
      templateId: this.state.templateId,
    });
  };
  render() {
    let Photos = [];
    if (this.state.allDisplay.length !== 0) {
      let photosUrl = this.state.allDisplay;
      photosUrl.map((e) => {
        return Photos.push(
          <Grid.Column>
            <Image.Group size="medium" style={{ marginBottom: "10px" }}>
              <Image
                src={storageBaseUrl + e.photoUrl}
              // onClick={() => this.zoomPics(e.photoUrl)}
              />
            </Image.Group>
            <div
              style={{
                position: "absolute",
                top: 8,
                left: 100,
                color: "yellow",
              }}
            >
              {e.photoTakenDate}
            </div>
            <div
              style={{
                position: "absolute",
                top: 20,
                left: 100,
                color: "yellow",
              }}
            >
              {e.storeName}
            </div>
          </Grid.Column>
        );
      });
    }

    var productColumns = [
      {
        Header: "Product",
        accessor: "category",

        style: { textAlign: "center", cursor: "pointer" },
        // Cell: (row) => (
        //   <AuditTableCell
        //     row={row.original}
        //     text={row.original.category}
        //     onClick={this.getCategoryAudits}
        //   />
        // ),
      },
      {
        Header: "Product Score",
        accessor: "score",

        style: { textAlign: "center", cursor: "pointer" },
        Cell: (row) => (
          <AuditTableCell
            row={row.original}
            text={row.original.score}
            onClick={this.getCategoryAudits}
          />
        ),
      },
    ];

    var DashColumns = [
      {
        Header: "Display",
        accessor: "display",

        style: { textAlign: "center", cursor: "pointer" },
        // Cell: (row) => (
        //   <AuditTableCell
        //     row={row.original}
        //     text={row.original.display}
        //     // onClick={() =>
        //     //   //this.handleDownloadAgentReportClick(row.original)
        //     //   this.handleShowPhotos(row.original)}
        //   />
        // ),
      },
      {
        Header: "Score",
        accessor: "score",

        style: { textAlign: "center", cursor: "pointer" },
        // Cell: (row) => (
        //   <AuditTableCell
        //     row={row.original}
        //     text={row.original.score}
        //     // onClick={() =>
        //     //   //this.handleDownloadAgentReportClick(row.original)
        //     //   this.handleShowPhotos(row.original)}
        //   />
        // ),
      },
    ];

    var newCol = [
      { title: "Region", field: "region" },
      { title: "CIS Version", field: "cisVersion" },
      { title: "Distributor Code", field: "distributorId" },
      { title: "Store Name", field: "storeName" },
      // { title: "Store Category", field: "category" },
      { title: "Maximum Score", field: "maxScore" },
      { title: "Obtained Score", field: "totalScore" },
      { title: "Percentage Score", field: "percentage" },
    ];

    var columns = [
      {
        Header: "Region",
        accessor: "region",
        width: 125,
        style: { textAlign: "center", cursor: "pointer" },
        Cell: ({ row: { original } }) => {
          return <span>{original.region}</span>;
        },
        // Cell: (row) => (
        //   <AuditTableCell
        //     row={row.original}
        //     text={row.original.region}
        //     onClick={this.handleTableViewAuditClick}
        //   />
        // ),
      },

      {
        Header: "CIS Version",
        width: 125,
        accessor: "cisVersion",
        style: { textAlign: "center", cursor: "pointer" },
        Cell: ({ row: { original } }) => {
          return <span>{original.cisVersion}</span>;
        },
        // Cell: (row) => (
        //   <AuditTableCell
        //     row={row.original}
        //     text={row.original.cisVersion}
        //     onClick={this.handleTableViewAuditClick}
        //   />
        // ),
      },
      {
        Header: "Distributor Code",
        width: 125,
        accessor: "distributorId",
        style: { textAlign: "center", cursor: "pointer" },
        Cell: ({ row: { original } }) => {
          return <span>{original.distributorId}</span>;
        },
        // Cell: (row) => (
        //   <AuditTableCell
        //     row={row.original}
        //     text={row.original.distributorId}
        //     onClick={this.handleTableViewAuditClick}
        //   />
        // ),
      },

      {
        Header: "Store Name",
        width: 300,
        accessor: "storeName",
        style: { textAlign: "center", cursor: "pointer" },
        Cell: ({ row: { original } }) => {
          return <span>{original.partnersName}</span>;
        },
        // Cell: (row) => (
        //   <AuditTableCell
        //     row={row.original}
        //     text={row.original.partnersName}
        //     onClick={this.handleTableViewAuditClick}
        //   />
        // ),
      },
      // {
      //   Header: "City Category",
      //   accessor: "category",
      //   style: { textAlign: "center", cursor: "pointer" },
      //   Cell: ({ row: { original } }) => {
      //     return <span>{original.category}</span>;
      //   },
      //   // Cell: (row) => (
      //   //   <AuditTableCell
      //   //     row={row.original}
      //   //     text={row.original.category}
      //   //     onClick={this.handleTableViewAuditClick}
      //   //   />
      //   // ),
      // },

      {
        Header: "Maximum Score",
        accessor: "maxScore",
        style: { textAlign: "center", cursor: "pointer" },
        Cell: ({ row: { original } }) => {
          return <span>{original.maxScore}</span>;
        },
        // Cell: (row) => (
        //   <AuditTableCell
        //     row={row.original}
        //     text={row.original.maxScore}
        //     onClick={this.handleTableViewAuditClick}
        //   />
        // ),
      },
      {
        Header: "Obtained Score",
        accessor: "totalScore",
        style: { textAlign: "center", cursor: "pointer" },
        Cell: ({ row: { original } }) => {
          return <span>{original.totalScore}</span>;
        },
        // Cell: (row) => (
        //   <AuditTableCell
        //     row={row.original}
        //     text={row.original.totalScore}
        //     onClick={this.handleTableViewAuditClick}
        //   />
        // ),
      },
      {
        Header: "Percentage Score",
        accessor: "percentage",
        style: { textAlign: "center", cursor: "pointer" },
        Cell: ({ row: { original } }) => {
          return <span>{original.percentage}</span>;
        },
        // Cell: (row) => (
        //   <AuditTableCell
        //     row={row.original}
        //     text={row.original.percentage}
        //     onClick={this.handleTableViewAuditClick}
        //   />
        // ),
      },
    ];

    return (
      <>
        {this.state.DashTableView === false &&
          this.state.showCategoryAudits === false ? (
          <div>
            <Segment
              onClick={this.onClose}
              style={{
                color: "#808080",
                float: "right",
                cursor: "pointer",
                marginTop: 7,
                display: "inline-block",
                position: "absolute",
                right: 58,
              }}
            >
              <IconPicker name={"arrow left"} color={"brown"} />
            </Segment>

            <h1 style={{ paddingLeft: 40, color: "brown" }}>
              {`${this.state.storeName}-${this.state.dbCode}`}
            </h1>

          </div>
        ) : null}
        {this.state.DashTableView === true &&
          this.state.showCategoryAudits === false &&
          this.state.metrics && (

            <div>
              <Metrics metrics={this.state.metrics} color={this.props.color} />

              <ConstantLabel
                title={"Compiled Report"}
                size={"large"}
                onClick={this.download}
                styles={{
                  cursor: "pointer",
                  float: "right",
                  margin: 20,
                  backgroundColor: getMainBackground(this.props.color),
                  color: getFontColor(this.props.color),
                }}
                iconName={"download"}
              />


            </div>
          )}
        <br></br>

        {this.state.openCarousel === false &&
          this.state.showCategoryAudits === false &&
          this.state.DashTableView === true ? (
          <TableView
            columns={newCol}
            data={this.state.regions}
            selection={false}
            actions={[
              {
                icon: "edit",
                iconProps: {
                  color: "success",
                },
                tooltip: "Edit",
                onClick: (event, row) => this.handleTableViewAuditClick(row),
              },
            ]}
          />
        ) :
          null}

        {this.state.openCarousel === false &&
          this.state.showCategoryAudits === false &&
          this.state.DashTableView === false && (
            <Panes
              PlanogramData={this.state.PlanogramData}
              HygieneData={this.state.HygieneData}
              AppliancesData={this.state.AppliancesData}
              storeId={this.state.storeId}
            />
          )}
        <ModalView
          open={this.state.open}
          close={() => {
            this.setState({ open: false, allDisplay: [] });
          }}
          header={'Display Photos'}
          content={
            <Grid>
              <Grid.Row columns={3}>{Photos}</Grid.Row>
            </Grid>
          }
          showSave={false}
        />

      </>
    );
  }
}

function AuditTableCell(props) {
  function onClick() {
    props.onClick(props.row);
  }
  return (
    <div style={props.style} onClick={onClick}>
      {props.text}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    //reducer props to state
    auth: state.auth,
    cannon: state.cannon,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchStateGraphAction,
      fetchCountryGraphAction,
      fetchStoreGraphAction,
      fetchProductGraphAction,
      fetchCannonDashboardScoreAction,
      fetchDashboardDisplayPhotoAction,
      fetchDashboardDisplay,
      fetchDashboardCategoryAuditsAction,
      downloadCompiledReportAction,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DashboardIndex)
);
