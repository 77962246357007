import {
    UPLOAD_DAIMLER_USER_CSV_FAILURE,
    UPLOAD_DAIMLER_USER_CSV_SUCCESS,
    INITIATE_UPLOAD,
    DISMISS_LOG,
    UPLOAD_DAIMLER_DEALER_CSV_SUCCESS,
    UPLOAD_DAIMLER_DEALER_CSV_FAILURE,
    UPLOAD_DAIMLER_VEHICLE_CSV_SUCCESS,
    UPLOAD_DAIMLER_VEHICLE_CSV_FAILURE,
    UPLOAD_VOLKSWAGEN_DEALER_CSV_FAILURE,
    UPLOAD_VOLKSWAGEN_DEALER_CSV_SUCCESS,
    UPLOAD_VOLKSWAGEN_USER_CSV_FAILURE,
    UPLOAD_VOLKSWAGEN_USER_CSV_SUCCESS,
    UPLOAD_VOLKSWAGEN_VEHICLE_CSV_FAILURE,
    UPLOAD_VOLKSWAGEN_VEHICLE_CSV_SUCCESS,
    UPLOAD_DISTRIBUTOR_CSV_SUCCESS,
    UPLOAD_FUNCTIONALMANAGER_CSV_SUCCESS,
    UPLOAD_AGENCY_CSV_SUCCESS,
    UPLOAD_LOCATION_CSV_SUCCESS,
    UPLOAD_DISTRIBUTOR_CSV_FAILURE,
    UPLOAD_FUNCTIONALMANAGER_CSV_FAILURE,
    UPLOAD_AGENCY_CSV_FAILURE,
    UPLOAD_LOCATION_CSV_FAILURE,
    UPLOAD_GODREJ_AUDIT_SUCCESS,
    UPLOAD_GODREJ_AUDIT_FAILURE,
    UPLOAD_SALES_MASTER_CSV_SUCCESS,
    UPLOAD_SALES_MASTER_CSV_FAILURE,
    UPLOAD_SALES_USER_CSV_SUCCESS,
    UPLOAD_SALES_USER_CSV_FAILURE,
    UPLOAD_SALES_COUNTRY_CSV_SUCCESS,
    UPLOAD_SALES_COUNTRY_CSV_FAILURE,

    UPLOAD_FURLENCO_USER_CSV_SUCCESS,
    UPLOAD_FURLENCO_USER_CSV_FAILURE,
    UPLOAD_FURLENCO_CUSTOMER_CSV_SUCCESS,
    UPLOAD_FURLENCO_CUSTOMER_CSV_FAILURE,
    UPLOAD_ATM_MASTER_CSV_SUCCESS,
    UPLOAD_CANNON_MASTER_CSV_SUCCESS,
    UPLOAD_MRRETAIL_MASTER_CSV_SUCCESS,
    UPLOAD_JANDJ_MASTER_CSV_SUCCESS,
    UPLOAD_JANDJ_CUSTOMER_CSV_SUCCESS,
    UPLOAD_ATM_MASTER_CSV_FAILURE,
    UPLOAD_CANNON_MASTER_CSV_FAILURE,
    UPLOAD_MRRETAIL_MASTER_CSV_FAILURE,
    UPLOAD_JANDJ_CUSTOMER_CSV_FAILURE,
    UPLOAD_JANDJ_MASTER_CSV_FAILURE,
    UPLOAD_UDAAN_MASTER_CSV_SUCCESS,
    UPLOAD_HERSHEYS_MASTER_CSV_SUCCESS,
    UPLOAD_GRB_MASTER_CSV_SUCCESS,
    UPLOAD_UDAAN_MASTER_CSV_FAILURE,
    UPLOAD_HERSHEYS_MASTER_CSV_FAILURE,
    UPLOAD_GRB_MASTER_CSV_FAILURE,
    UPLOAD_TIMESHEET_MASTER_CSV_FAILURE,
    UPLOAD_TIMESHEET_MASTER_CSV_SUCCESS,
    ADD_TIMESHEET_MASTER_CSV_SUCCESS,
    ADD_TIMESHEET_MASTER_CSV_FAILURE,
    FETCH_TIMESHEET_DATA_SUCCESS_ACTION,
    FETCH_TIMESHEET_DATA_FAILURE_ACTION
} from '../actions/types';

const INIT_STATE = {};

function controlPannel(state = INIT_STATE, action) {
    switch (action.type) {
        case UPLOAD_SALES_COUNTRY_CSV_SUCCESS:
        case UPLOAD_SALES_USER_CSV_SUCCESS:
        case UPLOAD_DAIMLER_USER_CSV_SUCCESS:
        case UPLOAD_VOLKSWAGEN_USER_CSV_SUCCESS:
        case UPLOAD_FURLENCO_USER_CSV_SUCCESS:
        case UPLOAD_SALES_MASTER_CSV_SUCCESS:
        case UPLOAD_DAIMLER_DEALER_CSV_SUCCESS:
        case UPLOAD_VOLKSWAGEN_DEALER_CSV_SUCCESS:
        case UPLOAD_FURLENCO_CUSTOMER_CSV_SUCCESS:
        case UPLOAD_DISTRIBUTOR_CSV_SUCCESS:
        case UPLOAD_FUNCTIONALMANAGER_CSV_SUCCESS:
        case UPLOAD_AGENCY_CSV_SUCCESS:
        case UPLOAD_LOCATION_CSV_SUCCESS:
        case UPLOAD_GODREJ_AUDIT_SUCCESS:
        case UPLOAD_DAIMLER_VEHICLE_CSV_SUCCESS:
        case UPLOAD_VOLKSWAGEN_VEHICLE_CSV_SUCCESS:
        case UPLOAD_ATM_MASTER_CSV_SUCCESS:
        case UPLOAD_CANNON_MASTER_CSV_SUCCESS:
        case UPLOAD_MRRETAIL_MASTER_CSV_SUCCESS:
        case UPLOAD_JANDJ_MASTER_CSV_SUCCESS:
        case UPLOAD_JANDJ_CUSTOMER_CSV_SUCCESS:
        case UPLOAD_UDAAN_MASTER_CSV_SUCCESS:
        case UPLOAD_HERSHEYS_MASTER_CSV_SUCCESS:
        case UPLOAD_GRB_MASTER_CSV_SUCCESS:
        case UPLOAD_TIMESHEET_MASTER_CSV_SUCCESS:
            return Object.assign({}, state, {
                uploadIncomplete: false,
                log: action.payload.log,
                showLog: true
            });
        case UPLOAD_SALES_COUNTRY_CSV_FAILURE:
        case UPLOAD_SALES_USER_CSV_FAILURE:
        case UPLOAD_DAIMLER_USER_CSV_FAILURE:
        case UPLOAD_VOLKSWAGEN_USER_CSV_FAILURE:
        case UPLOAD_FURLENCO_USER_CSV_FAILURE:
        case UPLOAD_SALES_MASTER_CSV_FAILURE:
        case UPLOAD_DAIMLER_DEALER_CSV_FAILURE:
        case UPLOAD_VOLKSWAGEN_DEALER_CSV_FAILURE:
        case UPLOAD_FURLENCO_CUSTOMER_CSV_FAILURE:
        case UPLOAD_DISTRIBUTOR_CSV_FAILURE:
        case UPLOAD_FUNCTIONALMANAGER_CSV_FAILURE:
        case UPLOAD_AGENCY_CSV_FAILURE:
        case UPLOAD_LOCATION_CSV_FAILURE:
        case UPLOAD_GODREJ_AUDIT_FAILURE:
        case UPLOAD_DAIMLER_VEHICLE_CSV_FAILURE:
        case UPLOAD_VOLKSWAGEN_VEHICLE_CSV_FAILURE:
        case UPLOAD_ATM_MASTER_CSV_FAILURE:
        case UPLOAD_CANNON_MASTER_CSV_FAILURE:
        case UPLOAD_MRRETAIL_MASTER_CSV_FAILURE:
        case UPLOAD_JANDJ_CUSTOMER_CSV_FAILURE:
        case UPLOAD_JANDJ_MASTER_CSV_FAILURE:
        case UPLOAD_UDAAN_MASTER_CSV_FAILURE:
        case UPLOAD_HERSHEYS_MASTER_CSV_FAILURE:
        case UPLOAD_GRB_MASTER_CSV_FAILURE:
        case UPLOAD_TIMESHEET_MASTER_CSV_FAILURE:
            return Object.assign({}, state, {
                uploadIncomplete: false,
                log: "You Uploaded wrong csv file, Please upload correct file.",
                showLog: true
            });

        case INITIATE_UPLOAD:
            return Object.assign({}, state, {
                uploadIncomplete: action.payload,
                showLog: false
            });

        case DISMISS_LOG:
            return Object.assign({}, state, {
                showLog: action.payload
            });

        case ADD_TIMESHEET_MASTER_CSV_SUCCESS:
            state.adduser = action.payload;
            return state;

        case ADD_TIMESHEET_MASTER_CSV_FAILURE:
            state.adduser = action.payload;
            return state;

        // case FETCH_TIMESHEET_DATA_SUCCESS_ACTION:
        //     console.log(action.payload.master,"payload in timesheet")
        //     state.timesheetData = action.payload.master;
        //     return state;

        // case FETCH_TIMESHEET_DATA_FAILURE_ACTION:
        //     state.timesheetDatafailure = action.payload;
        //     return state;
        default:
            return state
    }
}

export default controlPannel