import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "react-datepicker/dist/react-datepicker.css";
import CheckboxTable from "../commonTables/CheckboxView.jsx";
import ModalView from "../commonViews/ModalView.jsx";
import IconPicker from "../commonViews/IconPicker.jsx";
import ButtonHandler from "../commonViews/ButtonHandler.jsx";

import { fetchPGDMasterAction, PGCAssignAuditsAction, fetchPGCAuditorsDetailAction } from '../../actions/pgc_actions';
import { fetchUserDetailAction } from "../../actions/user_detail_action";

import "react-notifications/lib/notifications.css";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import TableView from "../commonViews/TableView.jsx";
import {
    getTextColor,
    getMainBackground,

    getFontColor,
} from "../../ThemeHandlers/theme";

import PGCAudit from "./PGCAudit.jsx";

import Metrics from "../commonViews/Metrics.jsx";

export class PGCMaster extends Component {
    constructor(props) {
        super(props);
        this.checkboxTable = null;

        this.setHersheysCheckboxRef = (element) => {
            this.checkboxTable = element;
        };

        this.state = {
            CustomerTableView: true,
            selectedCustomer: {},
            auditid: "",
            customerSearch: "",
            filteredData: [],
            modalOpen: false,
            selectedRegion: "",
            selectedState: "",
            selectedCity: "",
            selectedStatus: "",
            selection: [],
            userId: [],
            selectAll: false,
            pgcCustomers: [],
            hershysFilters: [],
            userDetails: [],
            assignData: [],
            isLoading: false,
        };
    }
    componentDidMount() {
        this.setState({ isLoading: true });
        let templateId = this.props.selectedTemplate._id;
        this.props.fetchPGDMasterAction({
            templateId,
            onSuccess: (res) => {
                this.setState({ isLoading: false });
            },
            onFailure: () => {
                this.setState({ isLoading: false });
            },
        });
        this.props.fetchPGCAuditorsDetailAction();
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.pgc.customers !== nextProps.pgc.customers) {
            this.setState({
                selectedRegion: "All",
                selectedCity: "All",
                selectedStatus: "All",
                filteredData: nextProps.pgc.customers,
            });
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.pgc.customers !== undefined) {
            if (
                this.props.pgc.filters === undefined &&
                this.props.pgc.customers !== prevProps.pgc.customers
            ) {
                this.setState({
                    pgcCustomers: this.props.pgc.customers,
                });
            } else if (
                this.props.pgc?.filters !== undefined &&
                this.props.pgc.filters !== prevProps.pgc.filters
            ) {
                this.setState({
                    pgcCustomers: this.props.pgc.filters,
                });
            }
        }
        if (this.props.pgc.userDetails !== prevProps.pgc.userDetails) {
            this.setState({
                userDetails: this.props.pgc.userDetails,
            });
        }
    }
    auditIdFromHersheysAudit = (auditId) => {
        this.setState({
            auditid: auditId,
        });
    };

    pgchandleTableViewAuditClick = (customer) => {
        this.setState({ selectedCustomer: customer, CustomerTableView: false });
    };
    onGotoCustomerView = () => {
        this.setState({
            CustomerTableView: true,
        });
    };

    searchFilter = (filter, row) => {
        let value = filter.value && filter.value.toLowerCase();

        if (filter.id) {
            var flatedData = JSON.stringify(
                row._original[filter.id] ? row._original[filter.id] : ""
            );
            return flatedData.toLowerCase().includes(value);
        }
    };

    addNewUser = () => {
        if (this.state.assignData.length !== 0) {
            this.setState({ modalOpen: true });

        } else {
            alert('Please Select the Stores to Assign')
        }
    };
    closeEditUser = () => {
        this.setState({ modalOpen: false });
    };

    userAssign = () => {
        this.setState({
            modalOpen: false,
            assignData: [],
        });
    };

    removeDuplicates = (e) => {
        let newArray = [];
        let UniqueObject = {};
        for (let i in e) {
            let objTitle = e[i]["text"];
            UniqueObject[objTitle] = e[i];
        }
        for (let i in UniqueObject) {
            newArray.push(UniqueObject[i]);
        }

        return newArray;
    };

    sendedData = (rowData, row) => {
        console.log(rowData, 'row')
        let ids = [];
        let check = [];

        if (rowData.length !== 0) {
            rowData.forEach((data) => {
                ids.push(data._id);
                if (data.status == 'submitted') {
                    check.push(data._id)
                }
            });
        }
        console.log(check, 'check')
        if (check.length !== 0) {
            this.setState({
                assignData: [],
            });
        }
        else {
            this.setState({ assignData: ids })
        }
    };

    userData = (rowData, checkedValue) => {
        var selectedData = this.state.assignData;
        let formAssignData = {
            userId: rowData._id,
            storeId: selectedData,
        };
        this.props.PGCAssignAuditsAction({
            assignData: formAssignData,
            onSuccess: () => {
                this.setState({ isLoading: true });
                let templateId = this.props.selectedTemplate._id
                this.props.fetchPGDMasterAction({
                    templateId,
                    onSuccess: () => {
                        this.setState({ isLoading: false, assignData: [], modalOpen: false });
                    },
                    onFailure: () => {
                        this.setState({ isLoading: false, assignData: [] });
                    },
                });

                NotificationManager.success(
                    "Audits Assigned Successfully",
                    "SUCCESS MESSAGE",
                    3000,
                    true
                );
            },
            onFailure: () => {
                NotificationManager.error(
                    "Audit Not Assigned",
                    "ERROR MESSAGE",
                    3000,
                    true
                );
            },
        });
    };

    render() {
        var customers;
        if (this.state.pgcCustomers.length !== 0) {
            customers = this.state.pgcCustomers;
        } else {
            customers = this.state.hershysFilters;
        }


        var UserList =
            this.state.userDetails.length !== 0 ? this.state.userDetails : [];

        console.log(customers, 'cust')
        const newHershysCol = [
            {
                title: "Customer Name",
                field: "customerName",
                render: (rowData) => (
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            this.pgchandleTableViewAuditClick(rowData);
                        }}
                    >
                        {rowData.customerName}
                    </span>
                ),
                customFilterAndSearch: (term, rowData) =>
                    rowData.customerName.toLowerCase().indexOf(term.toLowerCase()) > -1,
            },
            {
                title: "Distributor",
                field: "",
                render: (rowData) => (
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            this.pgchandleTableViewAuditClick(rowData);
                        }}
                    >
                        {rowData.distributor}
                    </span>
                ),
                customFilterAndSearch: (term, rowData) =>
                    rowData.distributor.toLowerCase().indexOf(term.toLowerCase()) > -1,
            },
            {
                title: "HQ Name",
                field: "",
                render: (rowData) => (
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            this.pgchandleTableViewAuditClick(rowData);
                        }}
                    >
                        {rowData.hqName}
                    </span>
                ),
                customFilterAndSearch: (term, rowData) =>
                    rowData.hqName.toLowerCase().indexOf(term.toLowerCase()) >
                    -1,
            },
            {
                title: "Store Code",
                field: "",
                render: (rowData) => (
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            this.pgchandleTableViewAuditClick(rowData);
                        }}
                    >
                        {rowData.storeCode}
                    </span>
                ),
                customFilterAndSearch: (term, rowData) =>
                    rowData.storeCode.toLowerCase().indexOf(term.toLowerCase()) >
                    -1,
            },
            {
                title: "Unique Store Code",
                field: "",
                render: (rowData) => (
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            this.pgchandleTableViewAuditClick(rowData);
                        }}
                    >
                        {rowData.uniqueStoreCode}
                    </span>
                ),
                customFilterAndSearch: (term, rowData) =>
                    rowData.uniqueStoreCode.toLowerCase().indexOf(term.toLowerCase()) >
                    -1,
            },

            {
                title: "State",
                field: "",
                render: (rowData) => (
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            this.pgchandleTableViewAuditClick(rowData);
                        }}
                    >
                        {rowData.state}
                    </span>
                ),
                customFilterAndSearch: (term, rowData) =>
                    rowData.state.toLowerCase().indexOf(term.toLowerCase()) > -1,
            },
            {
                title: "Status",
                field: "status",
                filtering: true,
                render: (rowData) => (
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            this.pgchandleTableViewAuditClick(rowData);
                        }}
                    >
                        {rowData.status}
                    </span>
                ),
            },
            {
                title: "Assignee",
                field: "userName",
                render: (rowData) => (
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            this.pgchandleTableViewAuditClick(rowData);
                        }}
                    >
                        {rowData.userName}
                    </span>
                ),
            },
            {
                title: "Address",
                field: "addressWithPincode",
                render: (rowData) => (
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            this.pgchandleTableViewAuditClick(rowData);
                        }}
                    >
                        {rowData.addressWithPincode}
                    </span>
                ),
                customFilterAndSearch: (term, rowData) =>
                    rowData.addressWithPincode.toLowerCase().indexOf(term.toLowerCase()) > -1,
            },
        ];

        const column = [
            {
                Header: "Email Id's",
                accessor: "email",
                style: { textAlign: "left", cursor: "pointer" },
                Cell: ({ row: { original } }) => {
                    return <span>{original.email}</span>;
                },
            },
        ];
        return (
            <div>
                <NotificationContainer />
                {this.state.CustomerTableView && (
                    <div>
                        <div style={{ display: "inline-block", marginBottom: 20 }}>
                            <IconPicker
                                name="arrow left"
                                click={this.props.onClose}
                                styles={{
                                    color: getTextColor(this.props.color),
                                    float: "right",
                                    cursor: "pointer",
                                    marginTop: 35,
                                    position: "absolute",
                                    right: 20,
                                }}
                            />
                            <h1
                                style={{
                                    display: "inline-block",
                                    color: getTextColor(this.props.color),
                                }}
                            >
                                P&G Medical Stores
                            </h1>

                        </div>
                        <div style={{ display: "inline-block", marginBottom: 20, marginLeft: 30 }}>
                            <ButtonHandler
                                title={'Assign Audits'}
                                size={'Medium'}
                                click={this.addNewUser}
                                styles={{
                                    cursor: "pointer",
                                    width: "100%",
                                    backgroundColor: getMainBackground(this.props.color),
                                    color: getFontColor(this.props.color),
                                }}
                                isIcon={true}
                                iconName={'add'}
                                disabled={this.state.assignData.length === 0}
                            />
                        </div>
                        <div style={{ marginTop: 10 }}>
                            {this.props.pgc.metrics !== undefined &&
                                Object.keys(this.props.pgc.metrics).length !== 0 &&
                                this.props.pgc.metrics && (<Metrics metrics={this.props.pgc.metrics} color={this.props.color} />

                                )}
                        </div>
                    </div>
                )}
                {this.state.CustomerTableView && (
                    <div
                        style={{
                            display: "flex",
                            flexGrow: 1,
                            flexFlow: "column",
                            marginTop: 20,

                        }}
                    >
                        <TableView
                            selection={true}
                            data={customers.length !== 0 ? customers : []}
                            columns={newHershysCol}
                            load={this.state.isLoading}
                            onSelectionChange={(rows, row) => this.sendedData(rows, row)}
                        />

                    </div>
                )}


                <ModalView
                    open={this.state.modalOpen}
                    close={this.closeEditUser}
                    size={'small'}
                    content={
                        <CheckboxTable
                            data={UserList}
                            columns={column}
                            sendedData={this.userData}
                        />
                    }
                    showSave={true}
                    saveClick={this.userAssign}
                />

                {!this.state.CustomerTableView && (
                    <div style={{ flexGrow: 1, display: "flex" }}>
                        <PGCAudit
                            template={this.props.template}
                            customer={this.state.selectedCustomer}
                            onClose={this.onGotoCustomerView}
                            auditId={this.auditIdFromHersheysAudit}
                            color={this.props.color}
                        />
                    </div>
                )}
            </div>
        );
    }
}

function AuditTableCell(props) {
    function onClick() {
        props.onClick(props.row);
    }
    return (
        <div style={props.style} onClick={onClick}>
            {props.text}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        pgc: state.pgc,
        user: state.user,
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            fetchPGDMasterAction,
            fetchUserDetailAction: fetchUserDetailAction,
            PGCAssignAuditsAction,
            fetchPGCAuditorsDetailAction,
        },
        dispatch
    );
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PGCMaster)
);
