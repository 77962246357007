import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { isLoggedIn } from '../../util';
import { Divider, Modal,Image} from 'semantic-ui-react';
import { downloadPreethiAuditReportAction } from '../../actions/preethi_actions';
import config from '../../config.js';
import moment from 'moment';
import { compose, withProps } from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";

const storageBaseUrl = config['storage_base_url'];
class PreethiAuditsDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTc: null,
            showPopup: false,
            editMode: false,
            selectedPhoto: '',
            showPhotoSlider: false,
            currentAudit: this.props.selectedAudit || {},
            currentTemplate: this.props.selectedTemplate
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedAudit._id != prevProps.selectedAudit._id) {
            this.setState({ currentAudit: this.props.selectedAudit, editMode: false });
        }
    }

    componentWillMount() {}

    componentDidMount() {
        if (!isLoggedIn(this.props.auth)) {
            this.props.history.push(`/login`);
        }
        this.setState({ currentAudit: this.props.selectedAudit });
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!isLoggedIn(this.props.auth)) {
            this.props.history.push(`/login`);
            return false;
        }
        return true;
    }

    handlePhotoClick = (e, p) => {
        this.setState({
            selectedPhoto: p,
            showPhotoSlider: true
        });
    };

 

    changeAnswer = (question, answerObject) => {
        var answer = '';
        let audit = this.state.currentAudit;
        if (question.answerType == 'text') {
            answer = answerObject.target.value;
        }
        if (question.answerType == 'number') {
            answer = answerObject.target.value;
        }
        if (question.answerType == 'yes/no') {
            answer = answerObject;
            if (question.templateStatus) {
                audit.templateStatus = answer.toLowerCase() == 'yes' ? question.templateStatus.yes : null;
            }
        }
        if (question.answerType == 'dropdown') {
            answer = answerObject;
        }
        audit.questions.map(eachQuestion => {
            if (eachQuestion.id == question.id) {
                eachQuestion.answer = answer;
            }
        });
        this.setState({ currentAudit: audit });
    };

    photoSliderClose = e => {
        this.setState({
            showPhotoSlider: false
        });
    };

    editAudit = () => {
        this.setState({ editMode: true });
    };

    cancelEditAudit = () => {
        this.setState({ editMode: false });
    };

    saveAudit = () => {
        this.props.saveDamagesAudit(this.state.currentAudit);
        this.setState({ editMode: false });
    };

    onDownloadPhotos = id => {
        let open = `#/images/${id}?tag=damages`;
        if (this.state.currentAudit.masterInfo) {
            open = `${open}&awCode=${this.state.currentAudit.masterInfo.awCode}&awName=${
                this.state.currentAudit.masterInfo.awName
            }`;
        }
        window.open(open);
    };

    onDownloadReport = id => {
        this.props.downloadAuditReport(id);
    };

    

    render() {
        var selectedAudit = this.state.currentAudit;
        var activeTemplateStatus = selectedAudit.templateStatus;
        var template = this.state.currentTemplate;

        const Map = compose(
            withProps({
              googleMapURL: "https://maps.googleapis.com/maps/api/js?key=" + config.google_map_api_key,
              loadingElement: <div style={{ height: `100%` }} />,
              containerElement: <div style={{ height: `400px` }} />,
              mapElement: <div style={{ height: `100%` }} />,
            }),
            withScriptjs,
            withGoogleMap
          )((props) =>
            <GoogleMap
              defaultZoom={10}
              defaultCenter={{ lat: selectedAudit.latitude, lng: selectedAudit.longitude }}
            >
              {props.isMarkerShown && <Marker position={{ lat: selectedAudit.latitude, lng: selectedAudit.longitude }} />}
            </GoogleMap>
          )

        return (
            <div>
                {this.props.selectedAudit && (
                    <div>
                        <HeaderView
                            selectedAudit={this.props.selectedAudit}
                            editMode={this.state.editMode}
                            onDownloadReport={this.onDownloadReport}
                            onDownloadPhotos={this.onDownloadPhotos}
                            editAudit={this.editAudit}
                            saveAudit={this.saveAudit}
                            cancelEditAudit={this.cancelEditAudit}
                        />
                        <Modal
                            dimmer="blurring"
                            size="fullscreen"
                            closeOnEscape={true}
                            closeOnDimmerClick={true}
                            basic
                            open={this.state.showPhotoSlider}
                            onClose={this.photoSliderClose}>
                            <Modal.Content>
                                <Image fuild centered src={storageBaseUrl + this.state.selectedPhoto.uploadedImageUrl}/>
                            </Modal.Content>
                        </Modal>
                        {selectedAudit.latitude && selectedAudit.longitude ? <Map isMarkerShown /> : null}
                        <br />
                        {selectedAudit.questions != null &&
                            selectedAudit.questions
                                .filter(eachQuestion => {
                                    if (eachQuestion.hasOwnProperty('dependency')) {
                                        let question = null;
                                        let questionId = eachQuestion.dependency.questionId;
                                        let answerNeeded = eachQuestion.dependency.answer;
                                        selectedAudit.questions.map(q => {
                                            if (q.id == questionId) {
                                                question = q;
                                            }
                                        });
                                        if (question.answer != answerNeeded) {
                                            return false;
                                        }
                                        return true;
                                    } else {
                                        return true;
                                    }
                                })
                                .map((eachQuestion, index) => {
                                    if (
                                        activeTemplateStatus &&
                                        !template.templateStatus[activeTemplateStatus].includes(eachQuestion.id)
                                    ) {
                                        return;
                                    }
                                    return (
                                        <div key={index}>
                                            <div
                                                key={eachQuestion.id}
                                                style={{ paddingTop: '30px', paddingBottom: '20px' }}>
                                                <div
                                                    style={{
                                                        fontWeight: 'bold',
                                                        marginLeft: '20px',
                                                        fontSize: '18px',
                                                        paddingBottom: '20px'
                                                    }}>
                                                    {eachQuestion.question}
                                                </div>
                                                <Image.Group size="mini" style={{ cursor: 'pointer' }}>
                                                    {eachQuestion.photos.map((p, i) => {
                                                        if (p.uploadedImageUrl != null) {
                                                            return (
                                                                <Image
                                                                    key={i}
                                                                    src={storageBaseUrl + p.uploadedImageUrl}
                                                                    onClick={(k => {
                                                                        return e => this.handlePhotoClick(e,k);
                                                                    })(p)}
                                                                />
                                                            );
                                                        }
                                                    })}
                                                </Image.Group>
                                            </div>
                                            <Divider />
                                        </div>
                                    );
                                })}
                    </div>
                )}
            </div>
        );
    }
}

const HeaderView = function(props) {
    let { selectedAudit} = props;
    return (
        <div>
            <h1 style={{ color: '#e67e22' }}>{selectedAudit.auditName}</h1>
            <div
                style={{
                    color: '#494949',
                    fontsize: '15px',
                    fontWeight: '100',
                    paddingBottom: '10px',
                    marginLeft: '10px',
                    marginTop: '-15px'
                }}>
                {selectedAudit.location}
            </div>

            <div>
                By {selectedAudit.auditorId != null && selectedAudit.auditorId.displayName} on{' '}
                {moment(selectedAudit.createdAt).format('DD-MM-YYYY  HH:MM:SS')}
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        auth: state.auth,
        preethi: state.preethi
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            downloadAuditReport: downloadPreethiAuditReportAction
        },
        dispatch
    );
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(PreethiAuditsDetail)
);
