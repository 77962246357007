import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn } from "./../../util";
import CommonPanel from "./commonPanel.jsx";
import {
  UploadUserCsv,
  dismissLogs,
  initiateUpload,
  UploadDealerCsv,
  daimlerUploadVehicleCsv,
} from "../../actions/control_panel_action";

class DaimlerControlPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      daimleruserCsvFile: null,
      daimlerdealersCsvFile: null,
      daimlervehicleCsvFile: null,
    };
  }

  componentWillMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!isLoggedIn(nextProps.auth)) {
      this.props.history.push(`/login`);
      return false;
    }
    return true;
  }

  diamlerhandleUserFileUpload = (files) => {
    const file = files.target.files[0];
    this.setState({ daimleruserCsvFile: file });
    files.target.files = null;
  };

  diamlerhandleDealerFileUpload = (files) => {
    const file = files.target.files[0];
    this.setState({ daimlerdealersCsvFile: file });
    files.target.files = null;
  };

  diamlerhandleVehicleFileUpload = (files) => {
    const file = files.target.files[0];
    this.setState({ daimlervehicleCsvFile: file });
    files.target.files = null;
  };

  diamleruploadUserData = () => {
    let userUrl = "/admin/v1/daimler/addUsers";
    this.props.diamlerinitiateUpload(true);
    this.props.diamleruploadUserCsv(userUrl, this.state.daimleruserCsvFile);
  };

  diamleruploadDealerData = () => {
    let daimlerDealerUrl = "/admin/v1/daimler/addDealers";
    this.props.diamlerinitiateUpload(true);
    this.props.uploadDealerCsv(
      daimlerDealerUrl,
      this.state.daimlerdealersCsvFile
    );
  };

  diamleruploadVehicleData = () => {
    let daimlerVandiUrl = "/admin/v1/daimler/addVehicles";
    this.props.diamlerinitiateUpload(true);
    this.props.diamleruploadVehicleCsv(
      daimlerVandiUrl,
      this.state.daimlervehicleCsvFile
    );
  };

  diamlercloseLog = () => {
    this.props.diamlerdismissLogs(false);
  };

  render() {
    return (
      <CommonPanel
        controlPanel={this.props.controlPanel}
        handleUserFileUpload={this.diamlerhandleUserFileUpload}
        uploadUserData={this.diamleruploadUserData}
        handleDealerFileUpload={this.diamlerhandleDealerFileUpload}
        uploadDealerData={this.diamleruploadDealerData}
        handleVehicleFileUpload={this.diamlerhandleVehicleFileUpload}
        uploadVehicleData={this.diamleruploadVehicleData}
        closeLog={this.diamlercloseLog}
        color={this.props.color}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    home: state.home,
    controlPanel: state.controlPanel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      uploadUserCsv: UploadUserCsv,
      uploadDealerCsv: UploadDealerCsv,
      diamleruploadVehicleCsv: daimlerUploadVehicleCsv,
      diamlerinitiateUpload: initiateUpload,
      diamlerdismissLogs: dismissLogs,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DaimlerControlPanel)
);
