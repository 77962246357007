import {
    FETCH_HERSHEYS_TEMPLATE_SUCCESS_ACTION,
    FETCH_HERSHEYS_TEMPLATE_FAILURE_ACTION,
    FETCH_HERSHEYS_STORE_SUCCESS_ACTION,
    FETCH_HERSHEYS_STORE_FAILURE_ACTION,
    FILTER_HERSHEYS_STORE_SUCCESS_ACTION,
    FILTER_HERSHEYS_STORE_FAILURE_ACTION,
    ASSIGN_HERSHEYS_AUDITS_DETAILS_SUCCESS_ACTION,
    ASSIGN_HERSHEYS_AUDITS_FAILIURE_ACTION,
    FETCH_HERSHEYS_AUDIT_SUCCESS_ACTION,
    FETCH_HERSHEYS_AUDIT_FAILURE_ACTION,
    SAVE_HERSHEYS_AUDIT_SUCCESS_ACTION,
    SAVE_HERSHEYS_AUDIT_FAILURE_ACTION,
    DOWNLOAD_HERSHEYS_REPORT_SUCCESS_ACTION,
    DOWNLOAD_HERSHEYS_REPORT_FAILURE_ACTION,
    DOWNLOAD_HERSHEYS_AUDIT_REPORT_SUCCESS_ACTION,
    DOWNLOAD_HERSHEYS_AUDIT_REPORT_FAILURE_ACTION,
    FETCH_AUDITORSDETAILS_SUCCESS_ACTION,
    FETCH_AUDITORSDETAILS_FAILIURE_ACTION,
    get_audit_reports_success,
    get_audit_reports_failure,
    get_audit_count_success,
    get_audit_count_failure
} from '../actions/types';


const INIT_STATE = {};

export default function (state = INIT_STATE,action) {
    state = Object.assign({}, state, {});

    switch (action.type) {
        case FETCH_HERSHEYS_TEMPLATE_SUCCESS_ACTION:
            state.templates = action.payload.templates;
            state.fetchHersheysTemplateError = false;
            return state;

        case FETCH_HERSHEYS_TEMPLATE_FAILURE_ACTION:
            state.fetchHersheysTemplateError = true;
            return state;

        case FETCH_HERSHEYS_STORE_SUCCESS_ACTION:
            return Object.assign({}, state, {
                customers: action.payload.data,
                metrics: action.payload.counts,
                isFetchUdaanStoresSuccess: true
            });


        case FETCH_HERSHEYS_STORE_FAILURE_ACTION:
        case FILTER_HERSHEYS_STORE_FAILURE_ACTION:
            return Object.assign({}, state, {
                isFetchUdaanStoresFailure: false
            });

        case FILTER_HERSHEYS_STORE_SUCCESS_ACTION:
            return Object.assign({}, state, {
                filters: action.payload.data,
                metrics: action.payload.counts,
                isFetchUdaanStoresSuccess: true
            });


        case ASSIGN_HERSHEYS_AUDITS_DETAILS_SUCCESS_ACTION:

            state.assignAuditsDetails = action.payload.assignAudits;
            state.assignAuditsDetailError = false;
            
            return state;

        case ASSIGN_HERSHEYS_AUDITS_FAILIURE_ACTION:
            state.assignAuditsDetailError = true;
        
            return state;

        case FETCH_HERSHEYS_AUDIT_SUCCESS_ACTION:
            return Object.assign({}, state, {
                audits: action.payload.data,
                isFetchUdaanAuditSuccess: true
            });


        case FETCH_HERSHEYS_AUDIT_FAILURE_ACTION:
            return Object.assign({}, state, {
                isFetchUdaanAuditFailure: false
            });

        case SAVE_HERSHEYS_AUDIT_SUCCESS_ACTION:
            return Object.assign({}, state, {
                saveDamagesAuditSuccessful: true
            });

        case SAVE_HERSHEYS_AUDIT_FAILURE_ACTION:
            return Object.assign({}, state, {
                saveDamagesAuditSuccessful: false
            });

        case DOWNLOAD_HERSHEYS_REPORT_SUCCESS_ACTION:
            var buff = new Buffer(action.payload, 'base64');
            var text = buff.toString('utf-8');

            // fileDownload(text, 'report.xlsx');

            return Object.assign({}, state, {
                downloadSuccess: true
            });

        case DOWNLOAD_HERSHEYS_REPORT_FAILURE_ACTION:
            return Object.assign({}, state, {
                downloadSuccess: false
            });

        case DOWNLOAD_HERSHEYS_AUDIT_REPORT_SUCCESS_ACTION:
            return Object.assign({}, state, {
                isAuditPDFDownloaded: true
            });
        case DOWNLOAD_HERSHEYS_AUDIT_REPORT_FAILURE_ACTION:
            return Object.assign({}, state, {
                isAuditPDFDownloaded: false
            });

        case FETCH_AUDITORSDETAILS_SUCCESS_ACTION:

            state.userDetails = action.payload.data;
            state.fetchUserDetailError = false;
            return state;

        case FETCH_AUDITORSDETAILS_FAILIURE_ACTION:
            state.fetchUserDetailError = true;
            return state;

        case get_audit_reports_success:
            return Object.assign({}, state, {
                getAuditReportSuccess: true,
            });

        case get_audit_reports_failure:
            return Object.assign({}, state, {
                getAuditReportSuccess: false,
            });


        case get_audit_count_success:

            state.counts = action.payload.count;
            state.countAuditsDetailError = false;
            return state;

        case get_audit_count_failure:
            state.countAuditsDetailError = true;
            return state;




    }
    return state;
}
