import React from "react";
import { Dimmer, Loader,Segment } from "semantic-ui-react";

const LoadingScreen = (props) => {
  return (
    <Dimmer active={props.active}>
      <Loader>{props.text}</Loader>
    </Dimmer>
  );
};

export default LoadingScreen;
