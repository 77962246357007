import React, { useState, useEffect } from "react";
// import { isLoggedIn } from "./../../util";
import { Icon, Input, Grid, Form, Dropdown, Checkbox } from "semantic-ui-react";
import { Modal } from "semantic-ui-react";
import ButtonHandler from "../commonViews/ButtonHandler";
import {
  dismissLogs,
  initiateUpload,
  timesheetsMasterCV,
  addtimesheetsMasterCV,
  edittimesheetsMasterCV,
  deletetimesheetsMasterCV,
  fetchtimesheetData,
} from "../../actions/control_panel_action";
import MomentUtils from "@date-io/moment";
import {
  KeyboardDatePicker,
  DatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import {
  fetchOrgsDetailAction,
  fetchUserDetailAction1,
} from "../../actions/user_detail_action";
import moment from "moment";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Table from "../commonTables/Table";
import TableView from "../commonViews/TableView";
import {
  fetchtimesheetControlPanlelMaster,
  exportMasterDataAction,
  deleteTimesheetMaster
} from "../../actions/timesheet_action";
import { ModalContent } from "semantic-ui-react";
import {
  getTextColor,
  getMainBackground,
  getMatchingTint,
  getFontColor,
} from "../../ThemeHandlers/theme";
import MaterialTable, { MTableBodyRow } from "material-table";

var assignfile = {};
class TimeSheets extends React.Component {
  constructor(props) {
    super(props);
    this.myTextIcon = React.createRef();
    this.state = {
      editMode: false,
      modalOpen: false,
      editmodeforadduser: false,
      modalOpenforAdduser: false,
      checkBoxStatus: false,
      checkBoxStatusColor: "white",
      name: "",
      audittypename: "",
      locationname: "",
      clientname: "",
      useremail: "",
      fromDate: new Date(),
      toDate: new Date(),
      timesheetstate: [],
      key: "",
      keyId: "",
      modaledituser: false,
      deletemodalOpen: false,
      deletingData: {},
      updatedtimesheet: [],
      userDetails: [],
      reload: true,
      clientCode:''
    };
  }

  async componentDidMount() {
    this.myTextIcon.current.parentNode.parentNode.classList.remove(
      "MuiIconButton-root"
    );

    if (this.props.timesheet.master) {
      this.setState({ timesheetstate: this.props.timesheet.master });
    }
    if (this.props.user) {
      // console.log(this.props.user.orgsDetails, "orgsdetails");
    } else {
      // console.log("nothing");
    }
    this.props.fetchOrgsDetailAction();
    this.props.fetchUserDetailAction({
      onSuccess: (data) => {
        this.setState({ userDetails: data.auditors });
      },
      onFailure: () => { },
    });
    await this.props.fetchtimesheetControlPanlelMaster({
      onSuccess: (data) => {
        if (data.master !== undefined && data.master.length !== 0) {
          data.master.map((eachItem) => {
            if (
              eachItem.plannedStartDate !== undefined &&
              eachItem.plannedEndDate !== undefined
            ) {
              let startDate = eachItem.plannedStartDate;
              let endDate = eachItem.plannedEndDate;

              let reversedStartDate = startDate.split("-").reverse().join("-");
              let reversedEndDate = endDate.split("-").reverse().join("-");
              let formPlannedStartDate = `${reversedStartDate}T${"00:00:00"}`;
              let formPlannedEndDate = `${reversedEndDate}T${"00:00:00"}`;
              if (!("formPlannedStartDate" in eachItem)) {
                eachItem["formPlannedStartDate"] = `${formPlannedStartDate}`;
              } else {
                eachItem["formPlannedStartDate"] = `${formPlannedStartDate}`;
              }
              if (!("formPlannedEndDate" in eachItem)) {
                eachItem["formPlannedEndDate"] = `${formPlannedEndDate}`;
              } else {
                eachItem["formPlannedEndDate"] = `${formPlannedEndDate}`;
              }
            }
          });
        }

        this.setState({ timesheetstate: data.master });
      },
      onFailure: () => { },
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.timesheet.master !== this.props.timesheet.master) {
      this.setState({
        timesheetstate: this.props.timesheet.master,
      });
    }
  }

  closedeletemodal = () => {
    this.setState({ deletemodalOpen: false });
  };

  timesheetfileupload = (file) => {
    assignfile = file.target.files[0];
  };

  handledeleteclick = (data) => {
    this.setState({ deletemodalOpen: true, deletingData: data });
  };

  functionforreload = () => {
    this.props.fetchtimesheetData();
  };

  timesheetuploadData = () => {
    let orgId = this.state.key;
    this.props.initiateUpload(true);
    this.props.uploadtimesheetMatserCV(assignfile, orgId, {
      onSuccess: () => {
        this.props.fetchtimesheetData({
          onSuccess: (data) => {
            this.props.fetchtimesheetControlPanlelMaster({
              onSuccess: (data) => {
                if (data.master !== undefined && data.master.length !== 0) {
                  data.master.map((eachItem) => {
                    if (
                      eachItem.plannedStartDate !== undefined &&
                      eachItem.plannedEndDate !== undefined
                    ) {
                      let startDate = eachItem.plannedStartDate;
                      let endDate = eachItem.plannedEndDate;

                      let reversedStartDate = startDate
                        .split("-")
                        .reverse()
                        .join("-");
                      let reversedEndDate = endDate
                        .split("-")
                        .reverse()
                        .join("-");
                      let formPlannedStartDate = `${reversedStartDate}T${"00:00:00"}`;
                      let formPlannedEndDate = `${reversedEndDate}T${"00:00:00"}`;
                      if (!("formPlannedStartDate" in eachItem)) {
                        eachItem[
                          "formPlannedStartDate"
                        ] = `${formPlannedStartDate}`;
                      } else {
                        eachItem[
                          "formPlannedStartDate"
                        ] = `${formPlannedStartDate}`;
                      }
                      if (!("formPlannedEndDate" in eachItem)) {
                        eachItem[
                          "formPlannedEndDate"
                        ] = `${formPlannedEndDate}`;
                      } else {
                        eachItem[
                          "formPlannedEndDate"
                        ] = `${formPlannedEndDate}`;
                      }
                    }
                  });
                }

                this.setState({ timesheetstate: data.master });
              },
              onFailure: () => { },
            });
            // this.setState({ timesheetstate: data });
          },
          onFailure: () => { },
        });
      },
      onFailure: () => { },
    });
    this.setState({ modalOpen: false, key: "" });
  };

  mastercloseLog = () => {
    this.props.dismissLogs(false);
  };

  handleclick = (data) => {
    console.log(data, "clicking inside add new master cv");
  };



  uploadclick = () => {
    this.setState({ modalOpen: true, clientname: "" });
  };

  exportMasterData = () => {
    this.props.exportMasterDataAction();
  };

  selectfile = (d) => { };

  close = () => { };

  uploadfile = () => { };

  saveedituser = () => {
    let clientName = this.state.clientname;
    let clientCode = this.state.clientCode;
    let auditType = this.state.audittypename;
    let location = this.state.locationname;
    let userEmailId = this.state.useremail;
    let fromDate = moment(this.state.fromDate).format("DD-MM-YYYY");
    let toDate = moment(this.state.toDate).format("DD-MM-YYYY");
    let orgId = this.state.key;
    let _id = this.state.keyId;
    this.props.edittimesheet(
      _id,
      clientName,
      clientCode,
      auditType,
      location,
      userEmailId,
      fromDate,
      toDate,
      {
        onSuccess: () => {
          this.props.fetchtimesheetControlPanlelMaster({
            onSuccess: (data) => {
              this.setState({ timesheetstate: data.master });
            },
            onFailure: () => { },
          });
        },
        onFailure: () => { },
      }
    );
    this.setState({
      clientname: "",
      audittypename: "",
      locationname: "",
      useremail: "",
      clientCode:"",
      fromDate: null,
      toDate: null,
      key: "",
      modaledituser: false,
    });
  };

  saveuser = () => {
    // let clientName = this.state.clientname;
    // let auditType = this.state.audittypename;
    // let location = this.state.locationname;
    // let userEmailId = this.state.useremail;
    // let fromDate = moment(this.state.fromDate).format("DD-MM-YYYY");
    // let toDate = moment(this.state.toDate).format("DD-MM-YYYY");
    // let orgId = this.state.key;
    // this.props.addtimesheetMasterCSV(
    //   clientName,
    //   auditType,
    //   location,
    //   userEmailId,
    //   fromDate,
    //   toDate,
    //   {
    //     onSuccess: () => {
    //       this.props.fetchtimesheetControlPanlelMaster({
    //         onSuccess: (data) => {
    //           this.setState({ timesheetstate: data.master });
    //         },
    //         onFailure: () => {},
    //       });
    //     },
    //     onFailure: () => {},
    //   }
    // );
    // this.setState({
    //   clientname: "",
    //   audittypename: "",
    //   locationname: "",
    //   useremail: "",
    //   fromDate: null,
    //   toDate: null,
    //   key: "",
    //   modalOpenforAdduser: false,
    // });
  };

  closeAddUser = () => {
    this.setState({
      modalOpenforAdduser: false,
    });
  };

  closeuploadoption = () => {
    this.setState({ modalOpen: false });
  };

  nameChange = () => { };

  handleClientDropdown = (e, d) => {
    this.setState({ clientname: d.value });
    let selectedvalue = d.value;
    let optionlength = d.options.length;
    for (let i = 0; i < optionlength; i++) {
      let optionvalue = d.options[i].value;
      if (selectedvalue === optionvalue) {
        this.setState({ key: d.options[i].key });
      }
    }
  };

  handleUsersDropdown = (e, d) => {
    this.setState({ useremail: d.value });
  };

  audittypeclick = (e) => {
    this.setState({ audittypename: e.target.value });
  };

  clienttypeclick = (e) => {
    this.setState({ clientname: e.target.value });
  };

  locationclick = (e) => {
    this.setState({ locationname: e.target.value });
  };

  closeeditUser = () => {
    this.setState({ modaledituser: false });
  };
  handleclickforadduser = () => {
    this.setState({
      modalOpenforAdduser: true,
      clientname: "",
      locationname: "",
      clientCode:"",
      audittypename: "",
      fromDate: new Date(),
      toDate: new Date(),
    });
  };

  deleteDetails = () => {
    this.props.deletetimesheet(this.state.deletingData._id, {
      onSuccess: () => {
        this.props.fetchtimesheetData({
          onSuccess: (data) => {
            this.setState({ timesheetstate: data });
          },
          onFailure: () => { },
        });
      },
      onFailure: () => { },
    });
    this.setState({ deletemodalOpen: false });
  };

  handleFromDate = (date) => {
    this.setState({
      fromDate: date,
    });
  };

  handleToDate = (date) => {
    this.setState({
      toDate: date,
    });
    var From = moment(this.state.fromDate).format("DD-MM-YYYY");
    var To = moment(date).format("DD-MM-YYYY");



    if (From >= To === true) {
      NotificationManager.info(
        "Choose a Date Greater Than From Date",
        "INFO MESSAGE",
        3000,
        true
      );
    }
  };

  checkBoxToggle = () => {
    this.setState({ checkBoxStatus: !this.state.checkBoxStatus });

    if (!this.state.checkBoxStatus === false) {
      this.setState({ clientname: "", checkBoxStatusColor: "white" });
    } else {
      this.setState({ clientname: "", checkBoxStatusColor: "black" });
    }
  };

  handleRowDelete = (oldData, resolve) => {
    this.props.deleteTimesheetMaster(oldData._id, {
      onSuccess: (res) => {
        alert(res.message)
        this.props.fetchtimesheetControlPanlelMaster({
          onSuccess: (data) => {
            this.setState({ timesheetstate: data.master });
          },
          onFailure: () => { },
        });
      },
      onFailure: () => { },
    });
    resolve();
  }


  render() {
    var options = [];
    if (this.props.user.orgsDetails) {
      this.props.user.orgsDetails.map((data) => {
        options.push({
          key: data._id,
          text: data.name,
          value: data.name,
        });
      });
    }

    var usersOptions = [];
    if (this.state.userDetails.length != 0) {
      this.state.userDetails.map((data) => {
        usersOptions.push({
          key: data._id,
          text: data.email,
          value: data.email,
        });
      });
    }

    var col = [
      {
        title: "Client Name",
        field: "orgName",
        editable: "always",
        render: (rowData) => {
          return <span>{rowData.orgName}</span>;
        },
      },
      {
        title: "Client Code",
        field: "clientCode",
        editable: "always",
        render: (rowData) => {
          return <span>{rowData.clientCode}</span>;
        },
      },
      {
        title: "Audit Type",
        field: "auditType",
        editable: "always",

        render: (rowData) => {
          return <span>{rowData.auditType}</span>;
        },
      },
      {
        title: "Location",
        field: "city",
        editable: "always",

        render: (rowData) => {
          return <span>{rowData.city}</span>;
        },
      },
      {
        title: "Manager Name",
        field: "managerName",
        editable: "never",

        render: (rowData) => {
          return <span>{rowData.managerName}</span>;
        },
      },
      {
        title: "Manager Emp ID",
        field: "managerEmpId",
        editable: "always",

        render: (rowData) => {
          return <span>{rowData.managerEmpId}</span>;
        },
      },
      {
        title: "No of TL",
        field: "totalTL",
        editable: "always",

        render: (rowData) => {
          return <span>{rowData.totalTL}</span>;
        },
      },
      {
        title: "TL Emp ID",
        field: "tlEmpId",
        editable: "always",

        render: (rowData) => {
          return <span>{rowData.tlEmpId}</span>;
        },
      },
      {
        title: "No of FE",
        field: "totalFe",
        editable: "always",

        render: (rowData) => {
          return <span>{rowData.totalFe}</span>;
        },
      },
      {
        title: "Field Executive ID",
        field: "fieldExecutiveEmpId",
        editable: "always",

        render: (rowData) => {
          return <span>{rowData.fieldExecutiveEmpId}</span>;
        },
      },
      {
        title: "Allocator Name",
        field: "allocatorName",
        editable: "never",

        render: (rowData) => {
          return <span>{rowData.allocatorName}</span>;
        },
      },
      {
        title: "planned Start Date (DD:MM:YYYY)",
        field: "plannedStartDate",
        editable: "always",

        render: (rowData) => {
          return <span>{rowData.plannedStartDate}</span>;
        },
        editComponent: (props) => (
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              autoOk
              variant="inline"
              inputVariant="outlined"
              format="DD/MM/YYYY"
              InputProps={{
                style: {
                  fontSize: 14,
                  height: 25,
                },
              }}
              value={
                props.rowData.formPlannedStartDate !== undefined
                  ? new Date(props.rowData.formPlannedStartDate)
                  : null
              }
              onChange={(date) => {
                let start = `${moment(date).format(
                  "YYYY-MM-DD"
                )}T${"00:00:00"}`;

                props.rowData.formPlannedStartDate = start;
                if (props.rowData.plannedStartDate === undefined) {
                  props.rowData.plannedStartDate =
                    moment(date).format("DD-MM-YYYY");
                }
                props.onChange(moment(date).format("DD-MM-YYYY"));
              }}
              style={{ width: "100%", height: "30px" }}
            />
          </MuiPickersUtilsProvider>
        ),
      },
      {
        title: "planned End Date (DD:MM:YYYY)",
        field: "plannedEndDate",
        editable: "always",

        render: (rowData) => {
          return <span>{rowData.plannedEndDate}</span>;
        },
        editComponent: (props) => (
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              autoOk
              variant="inline"
              inputVariant="outlined"
              format="DD/MM/YYYY"
              InputProps={{
                style: {
                  fontSize: 14,
                  height: 25,
                },
              }}
              // style={{ height: 100 }}
              minDate={props.rowData.formPlannedStartDate}
              // minDateMessage={"EndDate should be greater than StartDate"}
              minDateMessage={""}
              value={
                props.rowData.formPlannedEndDate !== undefined
                  ? new Date(props.rowData.formPlannedEndDate)
                  : null
              }
              onChange={(date) => {
                let end = `${moment(date).format("YYYY-MM-DD")}T${"00:00:00"}`;
                if (props.rowData.plannedEndDate === undefined) {
                  props.rowData.plannedEndDate =
                    moment(date).format("DD-MM-YYYY");
                }
                props.rowData.formPlannedEndDate = end;
                props.onChange(moment(date).format("DD-MM-YYYY"));
              }}
              style={{ width: "100%", height: "30px" }}
            />
          </MuiPickersUtilsProvider>
        ),
      },
    ];
    let buttonStyle = {
      cursor: "pointer",
      backgroundColor: getMainBackground(this.props.color),
      color: getFontColor(this.props.color),
    }

    return (
      <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
        <NotificationContainer />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginBottom: 10,
          }}
        >
          <ButtonHandler
            styles={buttonStyle}
            click={this.exportMasterData}
            isIcon={true}
            iconName={'download'}
            title={'Export Master Data'}
          />
          <ButtonHandler
            styles={buttonStyle}
            click={this.uploadclick}
            isIcon={true}
            iconName={'upload'}
            title={'Upload Master Data'}
          />



          {this.props.controlPanel.showLog && (
            <Modal
              close={this.mastercloseLog}
              open={this.props.controlPanel.showLog}
            >
              <Modal.Header>Upload Report</Modal.Header>
              <Modal.Content>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.controlPanel.log,
                  }}
                />
              </Modal.Content>
              <Modal.Actions>
                <ButtonHandler
                  styles={buttonStyle}
                  click={this.mastercloseLog}
                  isIcon={true}
                  iconName={'remove'}
                  title={'Dismiss'}
                />

              </Modal.Actions>
            </Modal>
          )}
        </div>
        <div
          style={{
            display: "flex",
            width: "99%",
            flexGrow: 1,
            flexFlow: "column",
            margin: 5,
          }}
        >
          <div>
            <MaterialTable
              columns={col}
              data={
                this.state.timesheetstate.length !== 0
                  ? this.state.timesheetstate
                  : []
              }
              options={{
                actionsColumnIndex: -1,
                showTitle: false,
                toolbar: true,
                filtering: true,
                sorting: false,
                search: false,
                // exportButton: true,
                addRowPosition: "first",
              }}
              icons={{
                Add: (props) => {
                  return (
                    <div ref={this.myTextIcon}>
                      <ButtonHandler
                        styles={buttonStyle}
                        click={() => { }}
                        isIcon={true}
                        iconName={'add'}
                        title={'Add Single Entry'}
                      />

                    </div>
                  );
                },
              }}
              editable={{
                onRowAdd: (newData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      // setData([...data, newData]);
                      // console.log([...this.state.timesheetstate, newData]);
                      this.props.addtimesheetMasterCSV({
                        data: newData,
                        onSuccess: () => {
                          this.setState({
                            timesheetstate: [
                              ...this.state.timesheetstate,
                              newData,
                            ],
                          });
                          resolve();
                        },
                        onFailure: (err) => {
                          console.log(err, "from com");
                          NotificationManager.error(
                            "Master Data Is Invalid",
                            "Invalid Master",
                            3000,
                            true
                          );
                          reject();
                        },
                      });
                    }, 1000);
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const dataUpdate = [...this.state.timesheetstate];
                      const index = oldData.tableData.id;
                      dataUpdate[index] = newData;
                      var start_time =
                        newData.plannedStartDate
                          .split("-")
                          .reverse()
                          .join("-") +
                        "T" +
                        "00:00:00" +
                        "Z";
                      var end_time =
                        newData.plannedEndDate.split("-").reverse().join("-") +
                        "T" +
                        "00:00:00" +
                        "Z";
                      
                      console.log(start_time,'start')
                      console.log(end_time,'endTime')
                      if(start_time == end_time){
                        this.props.edittimesheet({
                          data: newData,
                          onSuccess: () => { },
                          onFailure: () => { },
                        });

                        this.setState({ timesheetstate: [...dataUpdate] });
                        resolve();
                      }
                      else{
                         var isafter = moment(end_time).isAfter(start_time);
                      if (isafter === false) {
                        NotificationManager.error(
                          "Planned End Is Lower than Planned Start Date",
                          "Error",
                          3000,
                          true
                        );
                        reject();
                      } else {
                        this.props.edittimesheet({
                          data: newData,
                          onSuccess: () => { },
                          onFailure: () => { },
                        });

                        this.setState({ timesheetstate: [...dataUpdate] });
                        resolve();
                      }
                      }
                     
                    }, 1000);
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    this.handleRowDelete(oldData, resolve)
                  }),
              }}
            />
          </div>
        </div>
        <Modal
          open={this.state.modalOpen}
          onClose={this.closeuploadoption}
          size="mini"
        >
          <Modal.Content>
            <Form>
              <Grid columns={1}>
                <Grid.Row>

                  <Grid.Column style={{ marginTop: 20 }}>
                    <h4>Upload Master CSV to add master data :</h4>
                    <Input
                      type="file"
                      accept=".csv"
                      onChange={this.timesheetfileupload}
                      multiple
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Modal.Content>

          <Modal.Actions>
            <div style={{ display: 'flex', flexDirection: "row", justifyContent: "flex-end" }}>
              <ButtonHandler
                styles={buttonStyle}
                click={this.timesheetuploadData}

                title={'Upload'}
              />
              <ButtonHandler
                styles={buttonStyle}
                click={this.closeuploadoption}
                isIcon={true}
                iconName={'remove'}
                iconColor={'red'}
                title={'Close'}
              />
            </div>

          </Modal.Actions>
        </Modal>



        {/*edit*/}


        {/*delete*/}
        <Modal
          open={this.state.deletemodalOpen}
          onClose={this.closedeletemodal}
          size="mini"
        >
          <Modal.Content>
            <p>Are you sure you want to delete this data?</p>
          </Modal.Content>
          <Modal.Actions>
            <div style={{ display: 'flex', flexDirection: "row", justifyContent: "flex-end" }}>
              <ButtonHandler
                styles={buttonStyle}
                click={this.deleteDetails}

                title={'Yes'}
              />
              <ButtonHandler
                styles={buttonStyle}
                click={this.closedeletemodal}
                isIcon={true}
                iconName={'remove'}
                iconColor={'red'}
                title={'No'}
              />
            </div>

          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    home: state.home,
    user: state.user,
    controlPanel: state.controlPanel,
    userDetails: state.userDetails,
    timesheet: state.timesheet,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      uploadtimesheetMatserCV: timesheetsMasterCV,
      initiateUpload: initiateUpload,
      dismissLogs: dismissLogs,
      addtimesheetMasterCSV: addtimesheetsMasterCV,
      edittimesheet: edittimesheetsMasterCV,
      deletetimesheet: deletetimesheetsMasterCV,
      fetchtimesheetData: fetchtimesheetData,
      fetchOrgsDetailAction: fetchOrgsDetailAction,
      fetchUserDetailAction: fetchUserDetailAction1,
      fetchtimesheetControlPanlelMaster,
      exportMasterDataAction: exportMasterDataAction,
      deleteTimesheetMaster: deleteTimesheetMaster
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TimeSheets)
);
