import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "react-datepicker/dist/react-datepicker.css";
import CheckboxTable from "../commonTables/CheckboxView.jsx";
import {
  Grid,
} from "semantic-ui-react";
import ModalView from "../commonViews/ModalView.jsx";
import IconPicker from "../commonViews/IconPicker.jsx";
import ButtonHandler from "../commonViews/ButtonHandler.jsx";
import {
  fetchHersheysCompetitionStoresAction,
  AssignAuditsAction,
  fetchAuditorsDetailAction,
  downloadHersheysCompetitionReportAction
} from "../../actions/hersheys_competition_actions";
import { fetchUserDetailAction } from "../../actions/user_detail_action";
// import HersheysCompetitionAuditsDetail from "./HersheysCompetitionAuditsDetail.jsx";
import HersheysCompetitionProducts from "./HersheysCompetitionProducts.jsx";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import TableView from "../commonViews/TableView.jsx";
import {
  getTextColor,
  getMainBackground,

  getFontColor,
} from "../../ThemeHandlers/theme";
import Metrics from "../commonViews/Metrics.jsx";


// const CheckboxTable = selectTable(ReactTable);

export class HersheysCompetitionAudits extends Component {
  constructor(props) {
    super(props);
    this.checkboxTable = null;

    this.setHersheysCompetitionCheckboxRef = (element) => {
      this.checkboxTable = element;
    };

    this.state = {
      CustomerTableView: true,
      selectedCustomer: {},
      auditid: "",
      customerSearch: "",
      filteredData: [],
      modalOpen: false,
      selectedRegion: "",
      selectedState: "",
      selectedCity: "",
      selectedStatus: "",
      selection: [],
      userId: [],
      selectAll: false,
      hersheysCompetitionCustomers: [],
      hersheysCompetitionFilters: [],
      userDetails: [],
      assignData: [],
      isLoading: false,
    };
  }
  componentDidMount() {
    this.setState({ isLoading: true });
    this.props.fetchHersheysCompetitionStoresAction({
      id: this.props.selectedTemplate._id,
      onSuccess: () => {
        this.setState({ isLoading: false });
      },
      onFailure: () => {
        this.setState({ isLoading: false });
      },
    });
    this.props.fetchAuditorsDetailAction();
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.hersheysCompetition.customers !== nextProps.hersheysCompetition.customers) {
      this.setState({
        selectedRegion: "All",
        selectedCity: "All",
        selectedStatus: "All",
        filteredData: nextProps.hersheysCompetition.customers,
      });
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.hersheysCompetition.customers !== undefined) {
      if (
        this.props.hersheysCompetition.filters === undefined &&
        this.props.hersheysCompetition.customers !== prevProps.hersheysCompetition.customers
      ) {
        console.log(this.props.hersheysCompetition.customers, 'cusssssss')
        this.setState({
          hersheysCompetitionCustomers: this.props.hersheysCompetition.customers,
        });
      } else if (
        this.props.hersheysCompetition?.filters !== undefined &&
        this.props.hersheysCompetition.filters !== prevProps.hersheysCompetition.filters
      ) {
        this.setState({
          hersheysCompetitionCustomers: this.props.hersheysCompetition.filters,
        });
      }

    }
    if (this.props.hersheysCompetition.userDetails !== prevProps.hersheysCompetition.userDetails) {
      this.setState({
        userDetails: this.props.hersheysCompetition.userDetails,
      });
    }
  }
  auditIdFromHersheysCompetitionAudit = (auditId) => {
    this.setState({
      auditid: auditId,
    });
  };

  hersheysCompetitionhandleTableViewAuditClick = (customer) => {
    this.setState({ selectedCustomer: customer, CustomerTableView: false });
  };
  onGotoCustomerView = () => {
    this.setState({
      CustomerTableView: true,
    });
  };

  searchFilter = (filter, row) => {
    let value = filter.value && filter.value.toLowerCase();

    if (filter.id) {
      var flatedData = JSON.stringify(
        row._original[filter.id] ? row._original[filter.id] : ""
      );
      return flatedData.toLowerCase().includes(value);
    }
  };

  addNewUser = () => {
    if (this.state.assignData.length !== 0) {
      this.setState({ modalOpen: true });

    } else {
      alert('Please Select the Stores to Assign')
    }
  };
  closeEditUser = () => {
    this.setState({ modalOpen: false });
  };

  userAssign = () => {
    this.setState({
      modalOpen: false,
      assignData: [],
    });
  };

  onRegionChange = (value) => {
    let region = value;
    this.setState({
      selectedRegion: value,
      selectedCity: "",
      selectedStatus: "",
    });
  };
  onStateComboChange = (value) => {
    this.setState({
      selectedState: value,
    });
  };
  onCityComboChange = (value) => {
    this.setState({
      selectedCity: value,
    });
  };

  onStatusComboChange = (value) => {
    this.setState({
      selectedStatus: value,
    });
  };


  removeDuplicates = (e) => {
    let newArray = [];

    // Declare an empty object
    let UniqueObject = {};

    // Loop for the array elements
    for (let i in e) {
      // Extract the title
      let objTitle = e[i]["text"];

      // Use the title as the index
      UniqueObject[objTitle] = e[i];
    }

    // Loop to push unique object into array
    for (let i in UniqueObject) {
      newArray.push(UniqueObject[i]);
    }

    return newArray;
  };

  sendedData = (rowData, row) => {
    let ids = [];

    if (rowData.length !== 0) {
      rowData.forEach((data) => {
        ids.push(data._id);
      });
    }

    this.setState({
      assignData: ids,
    });
  };
  downloadReport = (master) => {
    this.setState({ thisReportDownloading: true, matTableLoad: true });
    this.props.downloadHersheysCompetitionReportAction({
      masterId: master._id,
      outletCode: master.outletCode,
      outletName: master.outletName,
      onSuccess: () => {
        this.setState({ matTableLoad: false });
      },
      onFailure: () => {
        this.setState({ matTableLoad: false });
      },
    });
  }

  userData = (rowData, checkedValue) => {
    var selectedData = this.state.assignData;
    let formAssignData = {
      userId: rowData._id,
      storeId: selectedData,
    };
    this.props.AssignAuditsAction({
      assignData: formAssignData,
      onSuccess: () => {
        this.setState({ isLoading: true });
        this.props.fetchHersheysCompetitionStoresAction({
          id: this.props.selectedTemplate._id,
          onSuccess: () => {
            this.setState({ isLoading: false, assignData: [] });
          },
          onFailure: () => {
            this.setState({ isLoading: false, assignData: [] });
          },
        });

        NotificationManager.success(
          "Audits Assigned Successfully",
          "SUCCESS MESSAGE",
          3000,
          true
        );
      },
      onFailure: () => {
        NotificationManager.error(
          "Audit Not Assigned",
          "ERROR MESSAGE",
          3000,
          true
        );
      },
    });
  };



  render() {
    var customers;
    if (this.state.hersheysCompetitionCustomers.length !== 0) {
      customers = this.state.hersheysCompetitionCustomers;
      console.log(customers, 'custumers')
    } else {
      customers = this.state.hersheysCompetitionFilters;
    }
    console.log(customers, 'test')
    var unique;
    var regionData = [];
    // var stateData = []
    var cityData = [];
    let uniqueCity;
    let status = [
      {
        text: "Pending",
        value: "Pending",
      },
      {
        text: "Assigned",
        value: "Assigned",
      },
      {
        text: "In Progress",
        value: "In Progress",
      },
      {
        text: "Completed",
        value: "Completed",
      },
      {
        text: "All",
        value: "All",
      },
    ];

    var UserList =
      this.state.userDetails.length !== 0 ? this.state.userDetails : [];


    const newHershysCompetitionCol = [
      {
        title: "Outlet Id",
        field: "outletCode",
        render: (rowData) => (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.hersheysCompetitionhandleTableViewAuditClick(rowData);
            }}
          >
            {rowData.outletCode}
          </span>
        ),
        customFilterAndSearch: (term, rowData) =>
          rowData.outletCode.toLowerCase().indexOf(term.toLowerCase()) > -1,
      },
      {
        title: "Outlet Name",
        field: "outletName",
        render: (rowData) => (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.hersheysCompetitionhandleTableViewAuditClick(rowData);
            }}
          >
            {rowData.outletName}
          </span>
        ),
        customFilterAndSearch: (term, rowData) =>
          rowData.outletName.toLowerCase().indexOf(term.toLowerCase()) > -1,
      },
      {
        title: "Trade",
        field: "trade",
        render: (rowData) => (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.hersheysCompetitionhandleTableViewAuditClick(rowData);
            }}
          >
            {rowData.trade}
          </span>
        ),
        customFilterAndSearch: (term, rowData) =>
          rowData.trade.toLowerCase().indexOf(term.toLowerCase()) >
          -1,
      },


      {
        title: "Region",
        field: "region",
        filtering: true,
        render: (rowData) => (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.hersheysCompetitionhandleTableViewAuditClick(rowData);
            }}
          >
            {rowData.region}
          </span>
        ),
        customFilterAndSearch: (term, rowData) =>
          rowData.region.toLowerCase().indexOf(term.toLowerCase()) > -1,
      },
      {
        title: "City",
        field: "city",
        render: (rowData) => (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.hersheysCompetitionhandleTableViewAuditClick(rowData);
            }}
          >
            {rowData.city}
          </span>
        ),
        customFilterAndSearch: (term, rowData) =>
          rowData.city.toLowerCase().indexOf(term.toLowerCase()) > -1,
      },
      {
        title: "Assignee",
        field: "userName",
        render: (rowData) => (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.hersheyshandleTableViewAuditClick(rowData);
            }}
          >
            {rowData.userName}
          </span>
        ),
        customFilterAndSearch: (term, rowData) =>
          rowData.userName.toLowerCase().indexOf(term.toLowerCase()) > -1,
      },
      {
        title: "Status",
        field: "status",
        render: (rowData) => (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.hersheyshandleTableViewAuditClick(rowData);
            }}
          >
            {rowData.status}
          </span>
        ),
        customFilterAndSearch: (term, rowData) =>
          rowData.status.toLowerCase().indexOf(term.toLowerCase()) > -1,
      },
      {
        title: "Audit Date",
        field: "auditDate",
        render: (rowData) => (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.hersheyshandleTableViewAuditClick(rowData);
            }}
          >
            {rowData.auditDate}
          </span>
        )
      },
      {
        title: "Report",
        field: "",
        render: (rowData) => {
          if (rowData._id) {
            return (
              <IconPicker
                size={"large"}
                styles={{
                  cursor: "pointer",
                  color: getTextColor(this.state.color),
                }}
                name={"file excel outline"}
                click={() => this.downloadReport(rowData)}
              />
            );
          }
        },
      }
    ];

    const column = [
      {
        Header: "Email Id's",
        accessor: "email",
        style: { textAlign: "left", cursor: "pointer" },
        Cell: ({ row: { original } }) => {
          return <span>{original.email}</span>;
        },
      },
    ];
    return (
      <div>
        <NotificationContainer />
        {this.state.CustomerTableView && (
          <div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ display: "inline-block", marginBottom: 20 }}>
                <IconPicker
                  name="arrow left"
                  click={this.props.onClose}
                  styles={{
                    color: getTextColor(this.props.color),
                    float: "right",
                    cursor: "pointer",
                    // marginTop: 35,
                    position: "absolute",
                    right: 20,
                  }}
                />
                <h1
                  style={{
                    display: "inline-block",
                    color: getTextColor(this.props.color),
                  }}
                >
                  Hersheys Competition Outlets
                </h1>
              </div>
              <div>
                <ButtonHandler
                  title={'Assign Audits'}
                  size={'Medium'}
                  click={this.addNewUser}
                  styles={{
                    cursor: "pointer",
                    width: "100%",
                    marginTop: 30,
                    marginLeft: 20,
                    backgroundColor: getMainBackground(this.props.color),
                    color: getFontColor(this.props.color),
                  }}
                  isIcon={true}
                  iconName={'add'}
                  disabled={this.state.assignData.length === 0}
                />
              </div>

            </div>
            <div style={{ marginTop: 10 }}>
              {this.props.hersheysCompetition.metrics !== undefined &&
                Object.keys(this.props.hersheysCompetition.metrics).length !== 0 &&
                this.props.hersheysCompetition.metrics && (<Metrics metrics={this.props.hersheysCompetition.metrics} color={this.props.color} />

                )}
            </div>
          </div>
        )}
        {this.state.CustomerTableView && (
          <div
            style={{
              display: "flex",
              flexGrow: 1,
              flexFlow: "column",
              marginTop: 20,

            }}
          >
            <TableView
              selection={true}
              data={customers.length !== 0 ? customers : []}
              columns={newHershysCompetitionCol}
              load={this.state.isLoading}
              onSelectionChange={(rows, row) => this.sendedData(rows, row)}
            />

          </div>
        )}


        <ModalView
          open={this.state.modalOpen}
          close={this.closeEditUser}
          size={'small'}
          content={
            <CheckboxTable
              data={UserList}
              columns={column}
              sendedData={this.userData}
            />
          }
          showSave={true}
          saveClick={this.userAssign}
        />

        {!this.state.CustomerTableView && (
          <div style={{ flexGrow: 1, display: "flex" }}>
            <HersheysCompetitionProducts
              template={this.props.template}
              customer={this.state.selectedCustomer}
              onClose={this.onGotoCustomerView}
              auditId={this.auditIdFromHersheysCompetitionAudit}
              color={this.props.color}
            />
          </div>
        )}
      </div>
    );
  }
}

function AuditTableCell(props) {
  function onClick() {
    props.onClick(props.row);
  }
  return (
    <div style={props.style} onClick={onClick}>
      {props.text}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    hersheysCompetition: state.hersheysCompetition,
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchHersheysCompetitionStoresAction: fetchHersheysCompetitionStoresAction,
      fetchUserDetailAction: fetchUserDetailAction,
      AssignAuditsAction: AssignAuditsAction,
      fetchAuditorsDetailAction: fetchAuditorsDetailAction,
      downloadHersheysCompetitionReportAction
    },
    dispatch
  );
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HersheysCompetitionAudits)
);
