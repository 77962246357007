import {
    FETCH_PANDGAGENTS_SUCCESS_ACTION,
    FETCH_PANDGAGENTS_FAILURE_ACTION,
    FETCH_PANDGDISTRIBUTERS_SUCCESS_ACTION,
    FETCH_PANDGDISTRIBUTERS_FAILURE_ACTION,
    FETCH_PANDGMANAGERS_SUCCESS_ACTION,
    FETCH_PANDGMANAGERS_FAILURE_ACTION,
    FETCH_PANDGLOCATIONS_SUCCESS_ACTION,
    FETCH_PANDGLOCATIONS_FAILURE_ACTION,
    FETCH_PANDGGETALLAUDITS_SUCCESS_ACTION,
    FETCH_PANDGGETALLAUDITS_FAILURE_ACTION,
    SELECT_PANDG_AUDIT_FROM_LEFT_PANE,
    DOWNLOAD_PANDGGETAUDITEXPORT_SUCCESS_ACTION,
    DOWNLOAD_PANDGGETAUDITEXPORT_FAILURE_ACTION,
    UPDATE_PANDGPOSTSUBMIT_SUCCESS_ACTION,
    UPDATE_PANDGPOSTSUBMIT_FAILURE_ACTION,
    FETCH_PANDGTEMPLATES_SUCCESS_ACTION,
    FETCH_PANDGTEMPLATES_FAILURE_ACTION

} from '../actions/types';
import moment from 'moment';

const INIT_STATE = {};

export default function (state = INIT_STATE,action) {

    state = Object.assign({}, state, {});

    switch (action.type) {

        case FETCH_PANDGDISTRIBUTERS_SUCCESS_ACTION:
            state.distributorList = action.payload.distributorList;
            state.fetchPandGDistributorsError = false;
            return state;

        case FETCH_PANDGDISTRIBUTERS_FAILURE_ACTION:
            state.fetchPandGDistributorsError = true;
            return state;

        case FETCH_PANDGAGENTS_SUCCESS_ACTION:
            state.agentList = action.payload.agentList;
            state.fetchPandGAgentsError = false;
            return state;

        case FETCH_PANDGAGENTS_FAILURE_ACTION:
            state.fetchPandGAgentsError = true;
            return state;

        case FETCH_PANDGTEMPLATES_SUCCESS_ACTION:
            state.templateList = action.payload.templateList;
            state.fetchPandGManagersError = false;
            return state;

        case FETCH_PANDGTEMPLATES_FAILURE_ACTION:
            state.fetchPandGManagersError = true;
            return state;


        case FETCH_PANDGMANAGERS_SUCCESS_ACTION:
            state.managerList = action.payload.managerList;
            state.fetchPandGManagersError = false;
            return state;

        case FETCH_PANDGMANAGERS_FAILURE_ACTION:
            state.fetchPandGManagersError = true;
            return state;

        case FETCH_PANDGLOCATIONS_SUCCESS_ACTION:
            state.locations = action.payload.locations;
            state.fetchPandGLocationsError = false;
            return state;

        case FETCH_PANDGLOCATIONS_FAILURE_ACTION:
            state.fetchPandGLocationsError = true;
            return state;

        case FETCH_PANDGGETALLAUDITS_SUCCESS_ACTION:
            state.audits = action.payload.audits;
            state.fetchPandGGetAllAuditsError = false;
            return state;

        case FETCH_PANDGGETALLAUDITS_FAILURE_ACTION:
            state = Object.assign({}, state, {
                fetchPandGGetAllAuditsError: true
            });
            return state;

        case SELECT_PANDG_AUDIT_FROM_LEFT_PANE:
            return Object.assign({}, state, {
                selectedPandGAudit: action.payload
            });
        case DOWNLOAD_PANDGGETAUDITEXPORT_SUCCESS_ACTION:
            state.downloadPandGAuditExportError = false;
            return state;

        case DOWNLOAD_PANDGGETAUDITEXPORT_FAILURE_ACTION:
            state.downloadPandGAuditExportError = true;
            return state;

        case UPDATE_PANDGPOSTSUBMIT_SUCCESS_ACTION:
            console.log(action.payload.audit,"updated audit in pandg")
            state.updatePandGSubmitError = false;
            let auditId = action.payload.audit._id;
            state.audits.forEach((audit, index) => {
                if (audit._id === auditId) {
                    state.audits[index] = action.payload.audit;
                }
            })
            state.selectedPandGAudit = action.payload.audit;
            return state;

        case UPDATE_PANDGPOSTSUBMIT_FAILURE_ACTION:
            state.updatePandGSubmitError = true;
            return state;
    }
    return state;
}