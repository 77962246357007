import React, { Component } from "react";
import { Segment, Button, Icon, Grid } from "semantic-ui-react";
import {
  getTextColor,
  getMainBackground,
  getMatchingTint,
  getFontColor,
} from "../../ThemeHandlers/theme";

export class CommonForAllPannels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      childrens: [],
    };
  }
  componentDidMount() {
    let childrens = this.state.childrens;
    if (this.props.child.length !== 0) {
      if (this.props.child.length === 1) {
        this.props.child.map((data) => {
          childrens.push(
            <Segment.Group>
              <Segment
                style={{ backgroundColor: getMainBackground(this.props.color) }}
                tertiary
              >
                <span
                  style={{
                    textAlign: "center",
                    color: getFontColor(this.props.color),
                  }}
                >
                  {data.itemForSpan}
                </span>
              </Segment>
              <Segment
                style={{ backgroundColor: getMatchingTint(this.props.color) }}
              >
                <input
                  type={"file"}
                  accept=".csv"
                  onChange={data.itemInputChange}
                />

                <Button
                  animated
                  onClick={data.itemClickButton}
                  style={{
                    marginLeft: 20,
                    width: "15vw",
                    backgroundColor: getMainBackground(this.props.color),
                    color: getFontColor(this.props.color),
                  }}
                >
                  <Button.Content visible>
                    {data.itemContentForButton}
                  </Button.Content>
                  <Button.Content hidden>
                    <Icon name="upload" />
                  </Button.Content>
                </Button>
              </Segment>
            </Segment.Group>
          );
        });
      } else {
        this.props.child.map((data) => {
          childrens.push(
            <Grid.Column style={{ marginTop: "4vh" }}>
              <Segment.Group>
                <Segment
                  style={{
                    backgroundColor: getMainBackground(this.props.color),
                  }}
                  tertiary
                >
                  <span
                    style={{
                      textAlign: "center",
                      color: getFontColor(this.props.color),
                    }}
                  >
                    {data.itemForSpan}
                  </span>
                </Segment>
                <Segment
                  style={{
                    backgroundColor: getMatchingTint(this.props.color),
                  }}
                >
                  <input
                    type={"file"}
                    accept=".xlsx"
                    onChange={data.itemInputChange}
                  />

                  <Button
                    animated
                    onClick={data.itemClickButton}
                    style={{
                      marginLeft: 20,
                      width: "15vw",
                      backgroundColor: getMainBackground(this.props.color),
                      color: getFontColor(this.props.color),
                    }}
                  >
                    <Button.Content visible>
                      {data.itemContentForButton}
                    </Button.Content>
                    <Button.Content hidden>
                      <Icon name="upload" />
                    </Button.Content>
                  </Button>
                </Segment>
              </Segment.Group>
            </Grid.Column>
          );
        });
      }
    }
    this.setState({ childrens });
  }

  componentDidUpdate(prevProps) {
    if (this.props.color !== prevProps.color) {
      let childrens = [];
      if (this.props.child.length !== 0) {
        if (this.props.child.length === 1) {
          this.props.child.map((data) => {
            childrens.push(
              <Segment.Group>
                <Segment
                  style={{
                    backgroundColor: getMainBackground(this.props.color),
                  }}
                  tertiary
                >
                  <span
                    style={{
                      textAlign: "center",
                      color: getFontColor(this.props.color),
                    }}
                  >
                    {data.itemForSpan}
                  </span>
                </Segment>
                <Segment
                  style={{ backgroundColor: getMatchingTint(this.props.color) }}
                >
                  <input
                    type={"file"}
                    accept=".csv"
                    onChange={data.itemInputChange}
                  />

                  <Button
                    animated
                    onClick={data.itemClickButton}
                    style={{
                      marginLeft: 20,
                      width: "15vw",
                      backgroundColor: getMainBackground(this.props.color),
                      color: getFontColor(this.props.color),
                    }}
                  >
                    <Button.Content visible>
                      {data.itemContentForButton}
                    </Button.Content>
                    <Button.Content hidden>
                      <Icon name="upload" />
                    </Button.Content>
                  </Button>
                </Segment>
              </Segment.Group>
            );
          });
        } else {
          this.props.child.map((data) => {
            childrens.push(
              <Grid.Column style={{ marginTop: "4vh" }}>
                <Segment.Group>
                  <Segment
                    style={{
                      backgroundColor: getMainBackground(this.props.color),
                    }}
                    tertiary
                  >
                    <span
                      style={{
                        textAlign: "center",
                        color: getFontColor(this.props.color),
                      }}
                    >
                      {data.itemForSpan}
                    </span>
                  </Segment>
                  <Segment
                    style={{
                      backgroundColor: getMatchingTint(this.props.color),
                    }}
                  >
                    <input
                      type={"file"}
                      accept=".xlsx"
                      onChange={data.itemInputChange}
                    />

                    <Button
                      animated
                      onClick={data.itemClickButton}
                      style={{
                        marginLeft: 20,
                        width: "15vw",
                        backgroundColor: getMainBackground(this.props.color),
                        color: getFontColor(this.props.color),
                      }}
                    >
                      <Button.Content visible>
                        {data.itemContentForButton}
                      </Button.Content>
                      <Button.Content hidden>
                        <Icon name="upload" />
                      </Button.Content>
                    </Button>
                  </Segment>
                </Segment.Group>
              </Grid.Column>
            );
          });
        }
      }
      this.setState({ childrens });
    }
  }

  render() {
    return this.state.childrens;
  }
}

export default CommonForAllPannels;
