import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn } from "./../../util";

import { Dimmer, Loader, Header } from "semantic-ui-react";
import { Button, Modal } from "semantic-ui-react";
import CommonForAllPannels from "./CommonForAllPannels.jsx";

class NaturalsControlPanel extends React.Component {
  render() {
    return (
      <div>
        <Header>Naturals</Header>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    home: state.home,
    controlPanel: state.controlPanel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NaturalsControlPanel)
);
