export const getMatchingTint = (color) => {
  let colo = "";
  if (color === "yellow") {
    colo = "#fef9e3";
  } else if (color === "blue") {
    colo = "#eff7fc";
  } else {
    colo = "#e7f6e7";
  }
  return colo;
};

export const getMainBackground = (color) => {
  let colo = "";
  if (color === "yellow") {
    colo = "#f9df68";
  } else if (color === "blue") {
    colo = "#2185d0";
  } else {
    colo = "teal";
  }
  return colo;
};
// below function is for button background
export const getFontColor = (color) => {
  let colo = "";
  if (color === "yellow") {
    colo = "black";
  } else if (color === "blue") {
    colo = "#ffffff";
  } else {
    colo = "#ffffff";
  }
  return colo;
};

export const getTextColor = (color) => {
  let colo = "";
  if (color === "yellow") {
    colo = "black";
  } else if (color === "blue") {
    colo = "#2185d0";
  } else {
    colo = "teal";
  }
  return colo;
};

export const getActiveColor = (color) => {
  let colo = "";
  if (color === "yellow") {
    colo = "#0f181f";
  } else if (color === "blue") {
    colo = "white";
  } else {
    colo = "white";
  }
  return colo;
};
