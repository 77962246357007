import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  fetchCannonTemplatesAction,
  fetchCannonStoreAction,
  fetchDashboardAction,
  fetchCannonPercentageAction,
  fetchWidgetsAction,
} from "../../actions/cannon_action";
import TabView from "./cannonTabView.jsx";

import {
  getTextColor,
  getMainBackground,
  getMatchingTint,
  getFontColor,
} from "../../ThemeHandlers/theme";
import TableView from "../commonViews/TableView";

export class CannonIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTemplate: {},
      isTemplatesView: true,
      templateId: "",
      templates: [],
      loading: false,
      color: "",
    };
  }
  componentDidMount() {
    if (this.props.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
    this.setState({ loading: true });
    this.props.fetchCannonTemplatesAction({
      onSuccess: () => {
        this.setState({ loading: false });
      },
      onFailure: () => {
        this.setState({ loading: false });
      },
    });
    this.props.fetchCannonPercentageAction();
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth.color !== prevProps.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
    if (this.props.cannon.cannonTemplate !== prevProps.cannon.cannonTemplate) {
      this.setState({ templates: this.props.cannon.cannonTemplate });
    }
  }
  handleTableViewAuditClick = (template) => {
    if (!template._id) return;
    this.props.fetchCannonStoreAction({
      templateId: template._id,
      onSuccess: () => {
        this.setState({
          isTemplatesView: false,
        });
      },
      onFailure: () => {
        console.log("store not fetched");
      },
    });
    this.props.fetchWidgetsAction({
      // templateId: template._id,
      onSuccess: () => {},
      onFailure: () => {
        console.log("PAN data not fetched");
      },
    });
    this.props.fetchDashboardAction({
      templateId: template._id,
      onSuccess: () => {},
      onFailure: () => {
        console.log("PAN data not fetched");
      },
    });
    this.setState({ selectedTemplate: template });
  };
  handleCloseClick = () => {
    this.setState({ isTemplatesView: true });
  };
  render() {
    var templates =
      this.state.templates.length !== 0 ? this.state.templates : [];
    var col = [
      {
        title: "Template Name",
        field: "auditName",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.auditName}
            </span>
          );
        },
      },
    ];

    return (
      <div style={{ flexGrow: 1, display: "flex", flexFlow: "column" }}>
        {this.state.isTemplatesView && (
          <div>
            <h1 style={{ color: getTextColor(this.state.color) }}>Templates</h1>
            <TableView
              selection={false}
              columns={col}
              data={templates}
              load={this.state.loading}
            />
          </div>
        )}
        {!this.state.isTemplatesView && (
          <TabView
            template={this.state.selectedTemplate}
            onClose={this.handleCloseClick}
            color={this.state.color}
          />
        )}
      </div>
    );
  }
}

function AuditTableCell(props) {
  function onClick() {
    props.onClick(props.row);
  }
  return (
    <div style={props.style} onClick={onClick}>
      {props.text}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    //reducer props to state
    auth: state.auth,
    cannon: state.cannon,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchCannonTemplatesAction,
      fetchCannonStoreAction,
      fetchDashboardAction,
      fetchCannonPercentageAction,
      fetchWidgetsAction,
      // actions
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CannonIndex)
);

// export default CannonIndex;
