import React from "react";
import { Statistic } from "semantic-ui-react";
class CommonStatistic extends React.Component {
  render() {
    var title = this.props.title;
    var summary = this.props.summary;
    return (
      <div style={{ marginBottom: 10 }}>
        <h2
          style={{
            color: "#e67e22",
            width: 600,
            wordBreak: "break-all",
            color:
              this.props.textColor !== undefined
                ? this.props.textColor(this.props.color)
                : "black",
          }}
        >
          {title}
        </h2>
        <div>
          <Statistic.Group widths="two">
            <Statistic>
              <Statistic.Value
                style={{ color: this.props.textColor(this.props.color) }}
              >
                {summary["totalVehicles"] || 0}
              </Statistic.Value>
              <Statistic.Label>Vehicles</Statistic.Label>
            </Statistic>
            <Statistic>
              <Statistic.Value
                style={{ color: this.props.textColor(this.props.color) }}
              >
                {(summary["totalVerified"] || 0) +
                  "/" +
                  (summary["totalVehicles"] || 0)}
              </Statistic.Value>
              <Statistic.Label>Verified</Statistic.Label>
            </Statistic>
          </Statistic.Group>
        </div>
      </div>
    );
  }
}
export default CommonStatistic;
