import * as types from '../actions/types';

const INIT_STATE = {};

export default function (state = INIT_STATE,action) {
    state = Object.assign({}, state, {});

    switch (action.type) {
        // case FETCH_PREETHI_PHOTO_TEMPLATE_SUCCESS_ACTION:
        //     state.templates = action.payload.templates;
        //     state.fetchDamagesTemplateError = false;
        //     return state;

        // case FETCH_PREETHI_PHOTO_TEMPLATE_FAILURE_ACTION:
        //     state.fetchDamagesTemplateError = true;
        //     return state;

        // case FETCH_PREETHI_PHOTO_AUDITS_SUCCESS_ACTION:
        //     state.preethiAudit = action.payload.preethiAudit;
        //     state.fetchDamagesAuditsError = false;
        //     return state;

        // case FETCH_PREETHI_PHOTO_AUDITS_FAILURE_ACTION:
        //     state.fetchDamagesAuditsError = true;
        //     return state;

        // case DOWNLOAD_PREETHI_PHOTO_AUDIT_REPORT_SUCCESS_ACTION:
        //     return Object.assign({}, state, {
        //         isAuditPDFDownloaded: true
        //     });
        // case DOWNLOAD_PREETHI_PHOTO_AUDIT_REPORT_FAILURE_ACTION:
        //     return Object.assign({}, state, {
        //         isAuditPDFDownloaded: false
        //     });

        case types.jandj_get_templates_success:
            return Object.assign({}, state, {
                templates: action.payload.templates,
                fetchTemplateSuccess: true,
            });

        case types.jandj_get_templates_failure:
            return Object.assign({}, state, {
                fetchTemplateSuccess: false,
                error: action.payload
            });

        case types.jandj_get_dealers_success:
            return Object.assign({}, state, {
                dealers: action.payload.masterData,
                fetchDealersSuccess: true,
            });

        case types.jandj_get_dealers_failure:
            return Object.assign({}, state, {
                fetchDealersSuccess: false,
                error: action.payload
            });

        case types.jandj_get_audit_success:
            return Object.assign({}, state, {
                audit: action.payload.audit,
                fetchAuditSuccess: true,
            });

        case types.jandj_get_audit_failure:
            return Object.assign({}, state, {
                fetchAuditSuccess: false,
                error: action.payload
            });

        case types.jandj_save_audit_success:
            return Object.assign({}, state, {
                audit: action.payload.audit,
                saveAuditSuccess: true,
            });

        case types.jandj_save_audit_failure:
            return Object.assign({}, state, {
                saveAuditSuccess: false,
            });

        case types.jandj_get_audit_report_success:
        case types.jandj_download_audit_report_success:
            return Object.assign({}, state, {
                getAuditReportSuccess: true,
            });

        case types.jandj_get_audit_report_failure:
            return Object.assign({}, state, {
                getAuditReportSuccess: false,
            });

        case types.jandj_download_audit_report_failure:
            return Object.assign({}, state, {
                getAuditReportFailure: false,
            });


    }
    return state;
}
