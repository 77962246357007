import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn } from "../../util";
import QuestionnaireDetail from "./QuestionnaireDetail.jsx";
import QuestionnaireSummary from "../QuestionnaireSummary.jsx";
import { Dimmer, Loader, Tab } from "semantic-ui-react";
import IconPicker from "../commonViews/IconPicker";
import ButtonHandler from "../commonViews/ButtonHandler";
import { updateAuditAction } from "../../actions/audit_actions";
import {
  getMainBackground,
  getMatchingTint,
  getFontColor,
  getTextColor,
} from "../../ThemeHandlers/theme";

class AuditDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      showLoading: false,
      audit: {},
      color: "",
    };
  }

  componentDidMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
    }
    if (this.props.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.audit) {
    }
  }

  componentDidUpdate(prevProps, nextProps) {
    if (this.props.audit) {
    }
    if (this.props.auth.color !== prevProps.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
  }

  // componentWillMount() {
  //   if(this.state.audit !== {}){
  //     this.audit = {}
  //     if(this.state.audit){
  //     this.audit = this.state.audit
  //     }else{this.audit={}}
  //   }
  // }

  // componentDidUpdate(prevProps,nextProps){
  //   // console.log(nextProps.audit,"next")
  //   if(this.props.audit !== prevProps.audit){
  //     console.log("checkkkkkkk")
  //   }
  //   if(prevProps.audit !== this.props.audit){
  //     console.log(prevProps.audit,"11111",this.props.audit)
  //     // console.log(nextProps.audit,"next to nexttttt")
  //     // this.audit = JSON.parse(JSON.stringify(nextProps.audit));

  //   }
  // }

  componentWillMount() {
    if (this.props.audit) {
      this.audit = JSON.parse(JSON.stringify(this.props.audit));
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
      return false;
    }
    return true;
  }

  handleEditClick = () => {
    this.setState({ editMode: true });
  };

  handleCancelClick = () => {
    this.setState({ editMode: false });
  };

  handleassign = (res) => {
    this.setState({ audit: res.data.audit });
  };

  // componentDidUpdate(){
  //   if(this.state.audit){
  //     this.audit = this.state.audit
  //   }
  // }

  handleSaveClick = () => {
    console.log(this.audit._id, this.audit, "what");
    this.props.updateAudit({
      auditId: this.audit._id,
      audit: this.audit,
      onSuccess: (res) => {
        this.handleassign(res);
        // this.setState({audit : JSON.parse(JSON.stringify(res.data.audit))})
      },
      onFailure: () => { },
    });
    this.setState({ editMode: false });
    // console.log(this.state.audit,"audit after onsuccess")
  };

  onDataChange = (newAudit) => {
    console.log(newAudit, "newaudit ");
  };

  render() {
    var audit = this.audit;

    console.log(this.props.previousAudits,'previous audits')

    if (!audit) {
      return <div />;
    }

    const panes = [
      {
        menuItem: "Summary",
        render: () => (
          <QuestionnaireSummary
            audit={audit}
            editMode={this.state.editMode}
            onDataChange={this.onDataChange}
            backColor={getMatchingTint}
            buttonColor={getMainBackground}
            fontColor={getFontColor}
            textColor={getTextColor}
            color={this.state.color}
          />
        ),
      },
    ];

    var questionnaire = audit.questionnaire;

    var previous_questions = this.props.previousAudits

    function filterTabs(ques){
      let previous_audits = []
      previous_questions.map((q)=>{  
        q.questionnaire.map((e)=>{
          if(e.name == ques.name){
            previous_audits.push(e)
          }
        })
      })
      return previous_audits
    }


    for (var q of questionnaire) {
      q.auditId = audit._id;
      if (q.scoreType === "computed") {
        panes.push({
          menuItem: q.name,
          render: ((ques) => {
            return () => {
              return (
                <QuestionnaireDetail
                  key={ques.auditId + ques.id}
                  questionnaire={ques}
                  previous_questionnaire={filterTabs(ques)}
                  editMode={this.state.editMode}
                  onDataChange={this.onDataChange}
                  backColor={getMatchingTint}
                  buttonColor={getMainBackground}
                  fontColor={getFontColor}
                  textColor={getTextColor}
                  color={this.state.color}
                />
              );
            };
          })(q),
        });
      }
    }

    if (this.props.audits.updatedAudit && !this.props.audits.updateAuditError) {
      this.props.audits.updatedAudit = null;
      this.props.audits.updateAuditError = false;
      this.state.showLoading = false;
      this.state.editMode = false;
    }

    return (
      <div style={{ flexGrow: 1, padding: 15 }}>
        <Dimmer active={this.state.showLoading} inverted>
          <Loader indeterminate>Saving Audit Changes</Loader>
        </Dimmer>

        <IconPicker
          styles={{
            color: "#808080",
            // marginTop: 10,
            marginRight: 20,
            float: "right",
            cursor: "pointer",
          }}
          click={this.props.onClose}
          size={"large"}
          name={"arrow left"}
        />

        <div style={{ marginRight: 50 }}>
          <h1 style={{ color: getTextColor(this.state.color) }}>
            {audit.auditName}
          </h1>
          {!this.state.editMode && (
            <div style={{ display: "inline-block" }}>
              <ButtonHandler
                size={"mini"}
                styles={{
                  display: "inline-block",
                  backgroundColor: getMainBackground(this.state.color),
                  color: getFontColor(this.state.color),
                }}
                click={this.handleEditClick}
                title={'Edit'}
                disabled={this.props.auth.displayName == 'TanishqAdmin' ? true:false}
              />

            </div>
          )}

          {this.state.editMode && (
            <div style={{ display: "inline-block" }}>
              <ButtonHandler
                size={"mini"}
                color={"blue"}
                styles={{
                  display: "inline-block",
                  backgroundColor: getMainBackground(this.state.color),
                  color: getFontColor(this.state.color),
                }}
                click={this.handleSaveClick}
                title={'Save'}
              />
              <ButtonHandler
                size={"mini"}
                styles={{ marginLeft: 10, display: "inline-block" }}
                click={this.handleCancelClick}
                title={'Cancel'}
              />

            </div>
          )}
          <span
            style={{
              marginLeft: 20,
              fontWeight: "bold",
              color: getTextColor(this.state.color),
            }}
          >
            Lean Score Index: {audit.lsi}
          </span>
        </div>

        <Tab
          style={{
            marginTop: 15,
            // backgroundColor: getMainBackground(this.state.color),
          }}
          menu={{
            color: getMainBackground(this.state.color),
            // inverted: true,
            attached: false,
            tabular: false,
          }}
          panes={panes}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    audits: state.audits,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(
    {
      updateAudit: updateAuditAction,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuditDetail)
);
