import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn } from "../../util";

import IconPicker from '../commonViews/IconPicker.jsx'
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import DatePicker from "react-datepicker";

import HersheysAudits from "./HersheysAudits.jsx";
import {
  fetchHersheysTemplatesAction,
  downloadHersheysReportAction,
  getAuditReports,
  getAuditCount,
} from "../../actions/hersheys_actions";
import { Grid } from "semantic-ui-react";
import TableView from "../commonViews/TableView";
import {
  getTextColor,
  getMainBackground,
  getMatchingTint,
  getFontColor,
} from "../../ThemeHandlers/theme";
import ConstantLabel from "../commonViews/ConstantLabel";
import ModalView from "../commonViews/ModalView";
import MailPopup from "../MailPopup.jsx";
class Hersheys extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      hersheysfromDate: new Date(),
      hersheystoDate: new Date(),
      firstDateChoose: null,
      hersheysSelectedTemplate: {},
      selectedTemplateData: "",
      thisTemplatesView: true,
      thisReportDownloading: false,
      modalOpen: false,
      template: {},
      email: "",
      count: "",
      templates: [],
      matTableLoad: false,
      color: "",
      isMailPopupView: false,
      templateId: ''
    };
  }

  componentDidMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
      return;
    }
    if (this.props.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
    this.setState({ matTableLoad: true });
    this.props.fetchHersheysTemplates({
      onSuccess: () => {
        this.setState({ matTableLoad: false });
      },
      onFailure: () => {
        this.setState({ matTableLoad: false });
      },
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.auth.color !== prevProps.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
    if (this.props.hersheys.templates !== prevProps.hersheys.templates) {
      this.setState({
        templates: this.props.hersheys.templates,
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ thisReportDownloading: false });
  }

  searchFilter = (filter, row) => {
    var flatData = JSON.stringify(row._original);
    return flatData.toLowerCase().includes(filter.value.toLowerCase());
  };

  handleCloseClick = () => {
    this.setState({ thisTemplatesView: true });
  };

  handleTableViewAuditClick = (template) => {
    if (!template._id) return;
    this.setState({
      hersheysSelectedTemplate: template,
      thisTemplatesView: false,
    });
  };

  handleMailReportClick = (template) => {
    this.setState({ isMailPopupView: !this.state.isMailPopupView, templateId: template._id });
  };

  handleSendMailReportClick = (m, cc) => {
    this.setState({ isMailPopupView: !this.state.isMailPopupView, mailIds: m, ccIds: cc });
    console.log(m, cc, 'mails')
    let templateId = this.state.templateId
    let emailIds = m
    this.props.downloadHersheysReportAction({
      templateId,
      emailIds,
      onSuccess: () => {
        this.setState({ matTableLoad: false });
      },
      onFailure: () => {
        this.setState({ matTableLoad: false });
      },
    });
  };

  downloadReport = (template) => {
    this.setState({ thisReportDownloading: true, matTableLoad: true });
    this.props.downloadHersheysReportAction({
      templateId: template._id,
      onSuccess: () => {
        alert("Report Sent to Your email")
        this.setState({ matTableLoad: false });
      },
      onFailure: () => {
        this.setState({ matTableLoad: false });
      },
    });
  };
  downloadPDFReport = (template) => {
    this.setState({ modalOpen: true, selectedTemplateData: template._id });
  };
  hersheyscloseEditUser = () => {
    this.setState({ modalOpen: false });
  };
  handleFromDate = (date) => {
    console.log(date);
    var From = date;
    var To = date;

    let startDate =
      From.getDate() +
      "-" +
      (From.getMonth() + 1) +
      "-" +
      From.getFullYear() +
      " " +
      From.getHours() +
      " " +
      From.getMinutes() +
      " " +
      From.getSeconds();
    let endDate =
      To.getDate() +
      "-" +
      (To.getMonth() + 1) +
      "-" +
      To.getFullYear() +
      " " +
      To.getHours() +
      " " +
      To.getMinutes() +
      " " +
      To.getSeconds();

    this.props.getAuditCount(
      this.state.selectedTemplateData,
      startDate,
      endDate
    );
    this.setState({
      hersheysfromDate: date,
      firstDateChoose: date,
    });
  };
  handleToDate = (date) => {
    this.setState({
      hersheystoDate: date,
      email: "",
    });
    var From = this.state.hersheysfromDate;
    var To = date;
    if (From <= To === true) {
      let startDate =
        From.getDate() +
        "-" +
        (From.getMonth() + 1) +
        "-" +
        From.getFullYear() +
        " " +
        From.getHours() +
        " " +
        From.getMinutes() +
        " " +
        From.getSeconds();
      let endDate =
        To.getDate() +
        "-" +
        (To.getMonth() + 1) +
        "-" +
        To.getFullYear() +
        " " +
        To.getHours() +
        " " +
        To.getMinutes() +
        " " +
        To.getSeconds();

      this.props.getAuditCount(
        this.state.selectedTemplateData,
        startDate,
        endDate
      );
    } else {
      NotificationManager.info(
        "Choose a Date Greater Than From Date",
        "INFO MESSAGE",
        3000,
        true
      );
    }
  };
  handleEmail = (e) => {
    this.setState({
      email: e.target.value,

      count: this.props.hersheys.counts,
    });
  };

  hersheysdownloadPdfsReports = () => {
    var From = this.state.hersheysfromDate;
    var To = this.state.hersheysfromDate;

    let startedDate =
      From.getDate() +
      "-" +
      (From.getMonth() + 1) +
      "-" +
      From.getFullYear() +
      " " +
      From.getHours() +
      " " +
      From.getMinutes() +
      " " +
      From.getSeconds();

    var filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    var mailformat = filter.test(this.state.email);

    if (mailformat) {
      this.props.getAuditReports({
        templateId: this.state.selectedTemplateData,
        startDate: startedDate,
        endDate: startedDate,
        email: this.state.email,
        onSuccess: () => {
          NotificationManager.success(
            "Reports Sent Successfully",
            "SUCCESS MESSAGE",
            3000,
            true
          );
        },
        onFailure: () => {
          NotificationManager.error(
            "Reports Not Sent",
            "ERROR MESSAGE",
            3000,
            true
          );
        },
      });

      this.setState({
        modalOpen: false,
      });
    } else {
      alert("invalid email");
    }
  };

  render() {
    var templates =
      this.state.templates.length !== 0 ? this.state.templates : [];

    var col = [
      {
        title: "Template Name",
        field: "auditName",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.auditName}
            </span>
          );
        },
      },
      {
        title: "Draft Audits",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.stats !== undefined ? rowData.stats.draftAudits : ""}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.stats.draftAudits == term,
      },
      {
        title: "Submitted Audits",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.stats !== undefined ? rowData.stats.submittedAudits : ""}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.stats.submittedAudits == term,
      },
      {
        title: "Total Audits",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.stats !== undefined ? rowData.stats.totalAudits : ""}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.stats.totalAudits == term,
      },
      {
        title: "Report",
        field: "",
        render: (rowData) => {
          if (rowData._id) {
            return (
              <IconPicker
                size={"large"}
                styles={{
                  cursor: "pointer",
                  color: getTextColor(this.state.color),
                }}
                name={"file excel outline"}
                click={() => this.handleMailReportClick(rowData)}
              />
            );
          }
        },
      },
      {
        title: "Download PDFs",
        field: "",
        render: (rowData) => {
          if (rowData._id) {
            return (
              <IconPicker
                size={"large"}
                color={"red"}
                name={"file pdf outline"}
                click={() => this.downloadPDFReport(rowData)}
              />
            );
          }
        },
      },
    ];

    return (
      <div style={{ flexGrow: 1, display: "flex", flexFlow: "column" }}>
        <NotificationContainer />
        {this.state.thisTemplatesView && (
          <div>
            <h1 style={{ color: getTextColor(this.state.color) }}>Templates</h1>

            <TableView
              columns={col}
              data={templates}
              selection={false}
              load={this.state.matTableLoad}
            />
          </div>
        )}
        {!this.state.thisTemplatesView && (
          <HersheysAudits
            selectedTemplate={this.state.hersheysSelectedTemplate}
            onClose={this.handleCloseClick}
            color={this.state.color}
          />
        )}
        <ModalView
          open={this.state.modalOpen}
          close={this.hersheyscloseEditUser}
          size={"small"}
          content={
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column >
                  <ConstantLabel
                    title={"Date"}
                    size={"large"}
                    styles={{
                      backgroundColor: getMainBackground(this.props.color),
                      color: getFontColor(this.props.color),
                    }}
                  />

                  <div style={{ display: "inline-block", marginLeft: 10 }}>
                    <DatePicker
                      selected={this.state.hersheysfromDate}
                      onChange={this.handleFromDate}
                      placeholderText="Choose From Date"
                      style={{ marginRight: -20 }}

                    />
                  </div>
                </Grid.Column>

                <Grid.Column >

                  <ConstantLabel
                    title={"Email"}
                    size={"large"}
                    styles={{
                      backgroundColor: getMainBackground(this.props.color),
                      color: getFontColor(this.props.color),
                    }}
                  />
                  <input
                    type="email"
                    placeholder="Enter your email address"
                    value={this.state.email}
                    style={{ borderRadius: 5, marginLeft: 10, height: 30 }}
                    onChange={this.handleEmail}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          }
          showSave={true}
          saveClick={this.hersheysdownloadPdfsReports}
          saveText={'Mail Reports'}
        />
        {this.state.isMailPopupView ? (
          <MailPopup
            open={this.state.isMailPopupView}
            mailIds={this.state.mailIds}
            ccIds={this.state.ccIds}
            closePopup={this.handleMailReportClick}
            sendMailAndClosePopup={this.handleSendMailReportClick}
            getColor={getMainBackground}
            color={this.state.color}
            getCellColor={getMatchingTint}
          />
        ) : null}

      </div>
    );
  }
}

function AuditTableCell(props) {
  function onClick() {
    props.onClick(props.row);
  }
  return (
    <div style={props.style} onClick={onClick}>
      {props.text}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    hersheys: state.hersheys,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchHersheysTemplates: fetchHersheysTemplatesAction,
      downloadHersheysReportAction: downloadHersheysReportAction,
      getAuditReports: getAuditReports,
      getAuditCount: getAuditCount,
    },
    dispatch
  );
};

// Hersheys.PropTypes = {
//   dealers: PropTypes.array
// };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Hersheys)
);
