import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as _ from "underscore";
import {
  Grid,
  Accordion,
  Form,
  Header,
} from "semantic-ui-react";
import IconPicker from "../commonViews/IconPicker";
import ButtonHandler from "../commonViews/ButtonHandler";
import TextInput from "../commonViews/TextInput";
import {
  getTextColor,
  getMainBackground,
  getMatchingTint,
  getFontColor,
} from "../../ThemeHandlers/theme";
import ConstantLabel from "../commonViews/ConstantLabel";

import { saveMrRetailAuditAction } from "../../actions/mrRetail_action";
function removeDuplicates(originalArray, prop) {
  var newArray = [];
  var lookupObject = {};

  for (var i in originalArray) {
    lookupObject[originalArray[i][prop]] = originalArray[i];
  }

  for (i in lookupObject) {
    newArray.push(lookupObject[i]);
  }
  return newArray;
}
export class MrRetailAuditsDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      currentAudit: [],
      clickData: {},
      allAudit: [],
    };
  }
  componentDidMount() {
    if (this.props.audit) {
      this.setState({
        currentAudit: this.props.audit.questions,
        allAudit: this.props.audit,
      });
    }
  }
  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex, clickData: titleProps });
  };

  changeInput = (e, ques) => {
    let currentAudit = this.state.currentAudit;
    if (currentAudit) {
      currentAudit.forEach((data) => {
        if (data.answers) {
          if (data.answers.question === ques) {
            data.answers.sod = e.target.value;
          }
        } else {
          var answers = {};
          answers.question = ques;
          answers.sod = e.target.value;
          if (data.label === ques) {
            data["answers"] = answers;
          }
        }
      });

      this.setState({
        currentAudit,
      });
    }
  };

  submitAudit = () => {
    this.state.allAudit.questions = this.state.currentAudit;
    this.props.saveMrRetailAuditAction({
      audit: this.state.allAudit,
      onSuccess: () => {
        this.props.goBack();
      },
      onFailure: () => { },
    });
  };

  render() {
    let acc = [];
    if (this.state.currentAudit.length > 0) {
      let title = this.state.currentAudit;
      if (title.length) {
        title.forEach((data, index) => {
          acc.push(
            <div key={index}>
              <Accordion.Title
                active={this.state.activeIndex === index}
                index={index}
                onClick={this.handleClick}
                style={{ backgroundColor: getMainBackground(this.props.color) }}
              >
                <span style={{ display: "inline-block" }}>
                  <h3
                    style={{
                      fontSize: "bold",
                      display: "inline-block",
                      color: getFontColor(this.props.color),
                    }}
                  >
                    Type Of Display -
                  </h3>{" "}
                  <ConstantLabel
                    title={data.label}
                    size={"large"}
                    styles={{
                      backgroundColor: getMatchingTint(this.props.color),
                      color: getTextColor(this.props.color),
                    }}
                  />

                </span>
              </Accordion.Title>
              <Accordion.Content
                active={this.state.activeIndex === index}
                style={{ backgroundColor: getMatchingTint(this.props.color) }}
              >
                <Grid key={index} style={{ margin: 10 }}>
                  <Grid.Row style={{ display: "inline-block" }}>
                    <Grid.Column width={8} style={{ display: "inline-block" }}>
                      <Form.Field style={{ display: "inline-block" }}>
                        <Header as="h4" style={{ display: "inline-block" }}>
                          {data.sod}
                        </Header>
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={8} style={{ display: "inline-block" }}>
                      {data.answers ? (
                        <TextInput value={data.answers.sod} onChangeTextInput={(e) => { this.changeInput(e, data.label) }} />

                      ) : (
                        <TextInput value={data.answers} onChangeTextInput={(e) => { this.changeInput(e, data.label) }} />


                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Accordion.Content>
            </div>
          );
        });
      }
    }

    let buttonStyle = {
      backgroundColor: getMainBackground(this.props.color),
      color: getFontColor(this.props.color),
      cursor: "pointer",
    }

    return (
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          flexFlow: "column",
          margin: 10,
        }}
      >
        <Grid>
          <Grid.Column floated="left" width={9}>
            <h2
              style={{
                fontSize: "bold",
                color: getTextColor(this.props.color),
              }}
            >
              {this.props.audit.auditName}
            </h2>
            <h4
              style={{
                color: getTextColor(this.props.color),
                fontSize: "italic",
              }}
            >{`Audits Done By - ${this.props.audit.auditorId.displayName}`}</h4>
          </Grid.Column>
          <Grid.Column floated="right" width={1}>
            <IconPicker
              name="arrow left"
              click={this.props.goBack}
              styles={{
                color: getTextColor(this.props.color),
                float: "right",
                cursor: "pointer",
                marginTop: 10,
                display: "inline-block",
                // top:"15",
                position: "absolute",
                right: 20,
              }}
            />
          </Grid.Column>
        </Grid>
        {this.state.currentAudit ? (
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              flexFlow: "column",
              // margin: 20,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: 15,
              }}
            >
              <ButtonHandler styles={buttonStyle} click={this.submitAudit} title={'Submit'} />

            </div>
            <Accordion fluid styled>
              {removeDuplicates(acc, "key")}
            </Accordion>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    mrRetail: state.mrRetail,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { saveMrRetailAuditAction: saveMrRetailAuditAction },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MrRetailAuditsDetails)
);
