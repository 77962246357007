var config = {
    api_url: "https://auditpro.matrixbscloud.com/apis/",
    // "api_url": "https://apstaging.matrixbscloud.com/apis/",
    // "api_url": 'http://localhost:2014',
    "storage_base_url": "https://auditpro-prod2.s3.amazonaws.com/",
    //storage_base_url: 'https://auditpro-staging.s3.amazonaws.com/',
    google_map_api_key: 'AIzaSyBGpsUgr9JdDogY2dnR2Z2vR_u2LJYy_nU', 
    confirmation_emails: "shaij.s@matrixbsindia.com;dtcteam@matrixbsindia.com;dtc.claims@matrixbsindia.com"
};

export default config;
