import {
  FETCH_PGD_AUDIT_SUCCESS_ACTION,
  FETCH_PGD_AUDIT_FAILURE_ACTION,
  FETCH_PGD_CITIES_SUCCESS_ACTION,
  FETCH_PGD_CITIES_FAILURE_ACTION,
} from "../actions/types";

const INIT_STATE = {};

export default function (state = INIT_STATE, action) {
  state = Object.assign({}, state, {});

  switch (action.type) {
    case FETCH_PGD_AUDIT_SUCCESS_ACTION:
      return Object.assign({}, state, {
        audits: action.payload.data,
        doctorsList: action.payload.doctorsList,
        isFetchUdaanAuditSuccess: true,
      });

    case FETCH_PGD_AUDIT_FAILURE_ACTION:
      return Object.assign({}, state, {
        isFetchUdaanAuditFailure: false,
      });

    case FETCH_PGD_CITIES_SUCCESS_ACTION:
      return Object.assign({}, state, {
        cities: action.payload.data,
        isFetchPGDCities: true,
      });

    case FETCH_PGD_CITIES_FAILURE_ACTION:
      return Object.assign({}, state, {
        isFetchPGDCities: false,
      });
  }
  return state;
}
