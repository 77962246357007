import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "react-datepicker/dist/react-datepicker.css";
import {

  Grid,

} from "semantic-ui-react";
import ModalView from "../commonViews/ModalView.jsx";
import ButtonHandler from '../commonViews/ButtonHandler.jsx'
import UdaanAudits from "./UdaanAudits.jsx";
import {
  fetchUdaanStoresAction,
  AssignAuditsAction,
  filterUdaanStoresAction,
} from "../../actions/udaan_actions";
import { fetchUserDetailAction } from "../../actions/user_detail_action";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import CheckboxforAssign from "../commonTables/CheckboxforAssign.jsx";
import TableView from "../commonViews/TableView.jsx";
import {
  getFontColor,
  getMainBackground,
  getTextColor,
} from "../../ThemeHandlers/theme.js";
import Metrics from "../commonViews/Metrics.jsx";
import DropDownPicker from "../commonViews/DropDownPicker.jsx";
import IconPicker from "../commonViews/IconPicker.jsx";
// const CheckboxTable = selectTable(ReactTable);

export class UdaanStores extends Component {
  constructor(props) {
    super(props);
    this.checkboxTable = null;

    this.setUdaanCheckboxRef = (element) => {
      this.checkboxTable = element;
    };
    this.state = {
      isCustomerTableView: true,
      selectedCustomer: {},
      auditid: "",
      customerSearch: "",
      filteredData: [],
      modalOpen: false,
      selectedRegion: "",
      selectedState: "",
      selectedCity: "",
      selectedStatus: "",
      selection: [],
      userId: [],
      selectAll: false,
      udaanfilter: [],
      comingData: [],
      udaanCustomers: [],
      loading: false,
    };
  }
  componentDidMount() {
    this.setState({ loading: true });
    this.props.fetchUdaanStoresAction({
      id: this.props.template._id,
      onSuccess: () => {
        this.setState({ loading: false });
      },
      onFailure: () => {
        this.setState({ loading: false });
      },
    });
    this.props.fetchUserDetailAction();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.udaan.customers != this.props.udaan.customers) {
      this.setState({
        filteredData: nextProps.udaan.customers,
      });
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.udaan.filters !== prevProps.udaan.filters) {
      this.setState({ udaanCustomers: this.props.udaan.filters });
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.udaan.customers !== prevProps.udaan.customers) {
      this.setState({ udaanCustomers: this.props.udaan.customers });
    }
  }

  auditIdFromUdaanAudit = (auditId) => {
    this.setState({
      auditid: auditId,
    });
  };

  handleTableViewAuditClick = (customer) => {
    this.setState({ selectedCustomer: customer, isCustomerTableView: false });
    // this.setState({ modalOpen: true })
  };
  onGotoCustomerView = () => {
    this.setState({
      isCustomerTableView: true,
    });
  };

  searchFilter = (filter, row) => {
    let value = filter.value && filter.value.toLowerCase();

    if (filter.id) {
      var flatData = JSON.stringify(
        row._original[filter.id] ? row._original[filter.id] : ""
      );
      return flatData.toLowerCase().includes(value);
    }
  };

  addNewUser = () => {
    this.setState({ modalOpen: true });
  };
  closeEditUser = () => {
    // this.props.clearUserAction();
    this.setState({ modalOpen: false });
  };

  sendedData = (rowData) => {
    let ids = [];

    if (rowData.length !== 0) {
      rowData.forEach((data) => {
        ids.push(data._id);
      });
    }
    this.setState({
      comingData: ids,
    });
  };

  saveandexit = (rowData, checkedValue) => {
    console.log(rowData[0]._id, "rowData");
    var selectedData = this.state.comingData;
    let data = {
      storeId: selectedData,
      userId: rowData[0]._id,
    };

    this.props.AssignAuditsAction({
      assignData: data,
      onSuccess: () => {
        this.setState({
          comingData: []
        })
        NotificationManager.success(
          "Audits Assigned Successfully",
          "SUCCESS MESSAGE",
          3000,
          true
        );
      },
      onFailure: () => {
        NotificationManager.error(
          "Audit Not Assigned",
          "ERROR MESSAGE",
          3000,
          true
        );
      },
    });
  };

  userAssign = () => {
    // var selectedData = this.state.selection;
    // var userSelection = this.state.userId;
    // var storeIds = [];
    // var userIds = [];
    // var daa;
    // selectedData.map(e => {
    //   storeIds.push(e.slice(7));
    // });
    // userSelection.map(e => {
    //   userIds.push(e.slice(7));
    // });
    // userIds.map(e => {
    //   return (daa = e);
    // });
    // var data = {
    //   userId: daa,
    //   storeId: storeIds
    // };
    // this.props.AssignAuditsAction({
    //   assignData: data,
    //   onSuccess: () => {
    //     NotificationManager.success(
    //       "Audits Assigned Successfully",
    //       "SUCCESS MESSAGE",
    //       3000,
    //       true
    //     );
    //   },
    //   onFailure: () => {
    //     NotificationManager.error(
    //       "Audit Not Assigned",
    //       "ERROR MESSAGE",
    //       3000,
    //       true
    //     );
    //   }
    // });
    this.setState({
      modalOpen: false,
    });
  };

  onRegionChange = (value) => {
    let region = value;
    this.setState({
      selectedRegion: value,
    });
  };
  onStateComboChange = (value) => {
    this.setState({
      selectedState: value,
    });
  };
  onCityComboChange = (value) => {
    this.setState({
      selectedCity: value,
    });
  };

  onStatusComboChange = (value) => {
    this.setState({
      selectedStatus: value,
    });
  };
  onViwDetailsClick = () => {
    let region = this.state.selectedRegion;
    let state = this.state.selectedState;
    let city = this.state.selectedCity;
    let status = this.state.selectedStatus;
    this.setState({ loading: true });
    this.props.filterUdaanStoresAction({
      templateId: this.props.template._id,
      region,
      state,
      city,
      status,
      onSuccess: () => {
        this.setState({ loading: false });
      },
      onFailure: () => {
        this.setState({ loading: false });
      },
    });
  };

  toggleSelection = (key, shift, row) => {
    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(key);

    // check to see if the key exists
    if (keyIndex >= 0) {
      // it does exist so we will remove it using destructing
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1),
      ];
    } else {
      // it does not exist so add it
      selection.push(key);
    }
    // update the state
    this.setState({ selection });
  };

  toggleAll = () => {
    const { keyField } = this.props;
    const selectAll = !this.state.selectAll;
    const selection = [];

    if (selectAll) {
      // we need to get at the internals of ReactTable
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // we just push all the IDs onto the selection array
      currentRecords.forEach((item) => {
        selection.push(`select-${item._original[keyField]}`);
      });
    }
    this.setState({ selectAll, selection });
  };

  isSelected = (key) => {
    return this.state.selection.includes(`select-${key}`);
  };
  rowFn = (state, rowInfo, column, instance) => {
    const { selection } = this.state;

    return {
      onClick: (e, handleOriginal) => {
        if (handleOriginal) {
          handleOriginal();
        }
      },
      style: {
        background:
          rowInfo &&
          selection.includes(`select-${rowInfo.original._id}`) &&
          "lightgreen",
      },
    };
  };

  usertoggleSelection = (key, shift, row) => {
    // start off with the existing state
    let userId = [...this.state.userId];
    const keyIndex = userId.indexOf(key);

    // check to see if the key exists
    if (keyIndex >= 0) {
      // it does exist so we will remove it using destructing
      userId = [...userId.slice(0, keyIndex), ...userId.slice(keyIndex + 1)];
    } else {
      // it does not exist so add it
      userId.push(key);
    }
    // update the state
    this.setState({ userId });
  };

  isUserSelected = (key) => {
    return this.state.userId.includes(`select-${key}`);
  };

  onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  removeDuplicates = (e) => {
    let newArray = [];

    // Declare an empty object
    let uniqueObject = {};

    // Loop for the array elements
    for (let i in e) {
      // Extract the title
      let objTitle = e[i]["text"];

      // Use the title as the index
      uniqueObject[objTitle] = e[i];
    }

    // Loop to push unique object into array
    for (let i in uniqueObject) {
      newArray.push(uniqueObject[i]);
    }

    return newArray;
  };

  arrowClick = () => {
    this.props.onClose();
  };

  render() {
    var customers =
      this.state.udaanCustomers.length !== 0 ? this.state.udaanCustomers : [];

    var unique;
    var regionData = [{ text: "All", value: "All" }];
    var stateData = [{ text: "All", value: "All" }];
    var cityData = [{ text: "All", value: "All" }];
    let uniqueState;
    let uniqueCity;
    let status = [
      {
        text: "All",
        value: "All",
      },
      {
        text: "Pending",
        value: "Pending",
      },
      {
        text: "Assigned",
        value: "Assigned",
      },
      {
        text: "In Progress",
        value: "In Progress",
      },
      {
        text: "Completed",
        value: "Completed",
      },
      {
        text: "Redo",
        value: "Redo",
      },
    ];
    if (this.props.user.userDetails != undefined) {
      var UserList = this.props.user.userDetails;
    }

    if (this.props.udaan.customers != undefined) {
      var regionList = this.props.udaan.customers;

      regionList.map((e) => {
        regionData.push({ text: e.region, value: e.region });
        stateData.push({ text: e.state, value: e.state });
        cityData.push({ text: e.city, value: e.city });
      });
    }
    if (regionData.length > 0) {
      unique = this.removeDuplicates(regionData);
    }
    if (stateData.length > 0) {
      uniqueState = this.removeDuplicates(stateData);
    }
    if (cityData.length > 0) {
      uniqueCity = this.removeDuplicates(cityData);
    }

    const columns = [
      {
        title: "BuyerOrgId",
        field: "buyerOrgId",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer", width: 100, wordBreak: "break-all" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.buyerOrgId}
            </span>
          );
        },
      },
      {
        title: "BuyerName",
        field: "buyerName",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.buyerName}
            </span>
          );
        },
      },
      {
        title: "Region",
        field: "region",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.region}
            </span>
          );
        },
      },
      {
        title: "State",
        field: "state",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.state}
            </span>
          );
        },
      },
      {
        title: "City",
        field: "city",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.city}
            </span>
          );
        },
      },

      {
        title: "Status",
        field: "status",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.status}
            </span>
          );
        },
      },
      {
        title: "MobileNo",
        field: "mobileNo",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.mobileNo}
            </span>
          );
        },
      },
      {
        title: "Assignee",
        field: "userName",
        style: { textAlign: "left", cursor: "pointer" },
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.userName}
            </span>
          );
        },
      },
      {
        title: "Address",
        field: "address",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.address}
            </span>
          );
        },
      },
    ];

    const column = [
      {
        Header: "Email Id's",
        accessor: "email",
        style: { textAlign: "left", cursor: "pointer" },
        Cell: ({ row: { original } }) => {
          return <span>{original.email}</span>;
        },
        // Cell: row =>
        //   <AuditTableCell
        //     row={row.original}
        //     text={row.original.email}
        //   // onClick={this.handleTableViewAuditClick}
        //   />
      },
    ];
    return (
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          // flexFlow: "column",
          width: "100%",
        }}
      >
        <NotificationContainer />
        {this.state.isCustomerTableView && (
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
                justifyContent: "space-between",
              }}
            >
              <h1 style={{ color: getTextColor(this.props.color) }}>
                Udaan Stores
              </h1>
              <div>


                <IconPicker
                  name={"arrow left"}
                  size={"large"}
                  styles={{
                    cursor: "pointer",
                    color: getTextColor(this.props.color),
                  }}
                  click={this.props.onClose}
                />
              </div>
            </div>

            <Grid>
              <Grid.Row columns={6} style={{ height: 70 }}>
                <Grid.Column>

                  <DropDownPicker
                    placeholder={"Select Region"}
                    styles={{ zIndex: 100 }}
                    value={this.state.selectedRegion}
                    options={unique}
                    onChangeDropDown={(data) => {
                      this.onRegionChange(data);
                    }}
                  />
                </Grid.Column>
                <Grid.Column>

                  <DropDownPicker
                    placeholder={"Select State"}
                    styles={{ zIndex: 100 }}
                    value={this.state.selectedState}
                    options={uniqueState}
                    onChangeDropDown={(data) => {
                      this.onStateComboChange(data);
                    }}
                  />
                </Grid.Column>
                <Grid.Column>

                  <DropDownPicker
                    placeholder={"Select City"}
                    styles={{ zIndex: 100 }}
                    value={this.state.selectedCity}
                    options={uniqueCity}
                    onChangeDropDown={(data) => {
                      this.onCityComboChange(data);
                    }}
                  />
                </Grid.Column>
                <Grid.Column>

                  <DropDownPicker
                    placeholder={"Select Status"}
                    styles={{ zIndex: 100 }}
                    value={this.state.selectedStatus}
                    options={status}
                    onChangeDropDown={(data) => {
                      this.onStatusComboChange(data);
                    }}
                  />
                </Grid.Column>
                <Grid.Column>
                  <ButtonHandler
                    styles={
                      {
                        backgroundColor: getMainBackground(this.props.color),
                        color: getFontColor(this.props.color),
                        width: '100%'
                      }
                    }
                    title={'View Details'}
                    click={this.onViwDetailsClick}
                    size={'medium'}
                  />

                </Grid.Column>
                <Grid.Column>
                  <ButtonHandler
                    title={'Assign Audits'}
                    isIcon={true}
                    iconName={'add'}
                    click={this.addNewUser}
                    disabled={this.state.comingData.length === 0}
                    size={"medium"}
                    styles={
                      {
                        backgroundColor: getMainBackground(this.props.color),
                        color: getFontColor(this.props.color),
                        width: '100%'
                      }
                    }
                  />

                </Grid.Column>
              </Grid.Row>
            </Grid>

            {this.props.udaan.metrics && (
              <div>
                <br />
                <Metrics metrics={this.props.udaan.metrics} color={this.props.color} />

              </div>
            )}

            <div
              style={{
                display: "flex",
                flexGrow: 1,
                flexFlow: "column",
                marginTop: 20,
              }}
            >
              <div>
                <div
                  style={{
                    display: "block",
                    float: "right",
                    marginBottom: "40px",
                    cursor: "pointer",
                  }}
                >
                  <ModalView
                    open={this.state.modalOpen}
                    close={this.closeEditUser}
                    size={'small'}
                    content={
                      <CheckboxforAssign
                        data={UserList}
                        columns={column}
                        sendedData={this.saveandexit}
                      />
                    }
                    showSave={true}
                    saveClick={this.userAssign}
                  />
                </div>
                <div style={{ width: "98%", overflowX: "hidden" }}>
                  <TableView
                    selection={true}
                    data={customers}
                    columns={columns}
                    onSelectionChange={(rows, row) =>
                      this.sendedData(rows, row)
                    }
                    load={this.state.loading}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {!this.state.isCustomerTableView && (
          <div style={{ flexGrow: 1, display: "flex" }}>
            <UdaanAudits
              template={this.props.template}
              customer={this.state.selectedCustomer}
              onClose={this.onGotoCustomerView}
              auditId={this.auditIdFromUdaanAudit}
              color={this.props.color}
            />
          </div>
        )}
      </div>
    );
  }
}

function AuditTableCell(props) {
  function onClick() {
    props.onClick(props.row);
  }
  return (
    <div style={props.style} onClick={onClick}>
      {props.text}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    udaan: state.udaan,
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchUdaanStoresAction: fetchUdaanStoresAction,
      fetchUserDetailAction: fetchUserDetailAction,
      AssignAuditsAction: AssignAuditsAction,
      filterUdaanStoresAction: filterUdaanStoresAction,
    },
    dispatch
  );
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UdaanStores)
);
