import React from "react";
import { Modal } from "semantic-ui-react";
import ButtonHandler from "./ButtonHandler";
import './navbar.css'
import { getMainBackground, getFontColor, getMatchingTint } from "../../ThemeHandlers/theme";
import { useSelector } from "react-redux";

const ModalView = (props) => {
    let auth = useSelector((state) => state.auth)
    return (
        <Modal open={props.open} onClose={props.close} size={props.size}>
            {props.header !== undefined &&
                <Modal.Header
                    style={{
                        backgroundColor: getMainBackground(auth.color),
                        color: getFontColor(auth.color),
                    }}
                >{props.header}</Modal.Header>
            }
            <Modal.Content
                style={{
                    backgroundColor: getMatchingTint(auth.color),
                }}
                scrolling
            >{props.content}</Modal.Content>
            <Modal.Actions>
                <div className="modal-action-div">
                    <ButtonHandler title={"Close"} color={"black"} click={props.close} />
                    {props.showSave === true &&
                        <ButtonHandler
                            title={props.saveText !== undefined ? props.saveText : 'Save'}
                            click={props.saveClick}
                            color={"blue"}
                            isIcon={true}
                            iconName={props.saveText !== undefined && props.saveText === "Edit" ? "pencil alternate" : "checkmark"}
                        />
                    }
                </div>
            </Modal.Actions>
        </Modal>
    );
};
export default ModalView;
