import {
    FETCH_PREETHI_PHOTO_TEMPLATE_SUCCESS_ACTION,
    FETCH_PREETHI_PHOTO_TEMPLATE_FAILURE_ACTION,
    FETCH_PREETHI_PHOTO_AUDITS_SUCCESS_ACTION,
    FETCH_PREETHI_PHOTO_AUDITS_FAILURE_ACTION,
    DOWNLOAD_PREETHI_PHOTO_AUDIT_REPORT_SUCCESS_ACTION,
    DOWNLOAD_PREETHI_PHOTO_AUDIT_REPORT_FAILURE_ACTION
} from '../actions/types';

const INIT_STATE = {};

export default function(state = INIT_STATE,action) {
    state = Object.assign({}, state, {});

    switch (action.type) {
        case FETCH_PREETHI_PHOTO_TEMPLATE_SUCCESS_ACTION:
            state.templates = action.payload.templates;
            state.fetchDamagesTemplateError = false;
            return state;

        case FETCH_PREETHI_PHOTO_TEMPLATE_FAILURE_ACTION:
            state.fetchDamagesTemplateError = true;
            return state;
        
        case FETCH_PREETHI_PHOTO_AUDITS_SUCCESS_ACTION:
            state.preethiAudit = action.payload.preethiAudit;
            state.fetchDamagesAuditsError = false;
            return state;

        case FETCH_PREETHI_PHOTO_AUDITS_FAILURE_ACTION:
            state.fetchDamagesAuditsError = true;
            return state;

        case DOWNLOAD_PREETHI_PHOTO_AUDIT_REPORT_SUCCESS_ACTION:
            return Object.assign({}, state, {
                isAuditPDFDownloaded: true
            });
        case DOWNLOAD_PREETHI_PHOTO_AUDIT_REPORT_FAILURE_ACTION:
            return Object.assign({}, state, {
                isAuditPDFDownloaded: false
            });

    }
    return state;
}
