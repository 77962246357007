import {
  FETCH_USERDETAILS_SUCCESS_ACTION,
  FETCH_USERDETAILS_FAILIURE_ACTION,
  EDIT_USERDETAILS_SUCCESS_ACTION,
  EDIT_USERDETAILS_FAILIURE_ACTION,
  FETCH_ORGSDETAILS_SUCCESS_ACTION,
  FETCH_ORGSDETAILS_FAILIURE_ACTION,
  FETCH_DEALERSDETAILS_SUCCESS_ACTION,
  FETCH_DEALERSDETAILS_FAILIURE_ACTION,
  ADD_USERSDETAILS_SUCCESS_ACTION,
  ADD_USERSDETAILS_FAILIURE_ACTION,
  SET_CURRENT_USER,
  CLEAR_USER_ACTION,
  CHANGE_PASSWORD_SUCCESS_ACTION,
  CHANGE_PASSWORD_FAILURE_ACTION,
  ADD_TIMESHEET_MASTER_CSV_SUCCESS,
  ADD_TIMESHEET_MASTER_CSV_FAILURE,
} from "../actions/types";
import { toast } from "react-semantic-toasts";

const INIT_STATE = {};
export default function (state = INIT_STATE, action) {
  state = Object.assign({}, state, {});
  switch (action.type) {
    case FETCH_USERDETAILS_SUCCESS_ACTION:

      state.userDetails = action.payload.auditors;
      state.fetchUserDetailError = false;
      return state;

    case FETCH_USERDETAILS_FAILIURE_ACTION:
      state.fetchUserDetailError = true;
      return state;
    case EDIT_USERDETAILS_SUCCESS_ACTION:
      state.editUserDetailError = false;
      return state;

    case EDIT_USERDETAILS_FAILIURE_ACTION:
      state.editUserDetailError = true;
      return state;
    case FETCH_ORGSDETAILS_SUCCESS_ACTION:
      state.orgsDetails = action.payload.orgs;
      state.fetchOrgsDetailError = false;
      return state;

    case FETCH_ORGSDETAILS_FAILIURE_ACTION:
      state.fetchOrgsDetailError = true;
      return state;
    case FETCH_DEALERSDETAILS_SUCCESS_ACTION:

      state.dealerDetails = action.payload.dealers;
      state.fetchDealerDetailError = false;
      return state;

    case FETCH_DEALERSDETAILS_FAILIURE_ACTION:
      state.fetchDealerDetailError = true;
      return state;

    case ADD_USERSDETAILS_SUCCESS_ACTION:

      state.adduserDetails = action.payload.addUsers;
      state.addUsersDetailError = false;
      return state;

    case ADD_USERSDETAILS_FAILIURE_ACTION:
      state.addusersDetailError = true;
      return state;

    case CHANGE_PASSWORD_SUCCESS_ACTION:
      state.message = action.payload.message;
      setTimeout(() => {
        toast({
          type: "warning",
          title: action.payload.message,
          description: "",
          time: 3000,
        });
      }, 0);
      state.changePasswordError = false;
      return state;

    case CHANGE_PASSWORD_FAILURE_ACTION:
      state.changePasswordError = true;
      return state;

    case SET_CURRENT_USER:
      state.currentUser = action.payload;
      return state;

    case CLEAR_USER_ACTION:
      return Object.assign({}, state, {
        dealerDetails: []
      });
  }
  return state;
}
