import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Dimmer, Loader, Image, Segment } from "semantic-ui-react";
import config from "../../../config.js";
const storageBaseUrl = config["storage_base_url"];
const ProductAudits = (props) => {
  const [categoryAudits, setCategoryAudits] = useState([]);
  // const []
  useEffect(() => {
    if (props.cannon.displayDashboardAudits !== undefined) {
      setCategoryAudits(props.cannon.displayDashboardAudits);
    }
  }, [props.cannon]);
  const displayData = () => {
    let display = [];

    categoryAudits.map((data) => {
      display.push(
        <div>
          <h4><span style={{color:"orange",fontSize:"17px"}}>Category name:</span> <span style={{color:"black",fontSize:"17px",letterSpacing:"1px",marginLeft:"10px"}}>{data.categoryName}</span></h4>
          <div style={{display:"flex",flexDirection:"row",justifyContent:"space-evenly"}}>
          <h5>{props.storeType === "Planogram" ? "Display Status:" : (props.storeType === "Store Hygiene")? "Dust free and CleanCondition:":"Appliances Working Condition"}</h5>
          <span style={{marginLeft:"20px",fontSize:"17px"}}><em>{props.storeType === "Planogram" ? data.displayStatus :(props.storeType === "Store Hygiene")? data.dustfreeAndCleanCondition:data.appliancesWorkingCondition}</em></span>
          <span style={{fontSize:"15px",marginLeft:"20%"}}>Photo:</span>
          <Image.Group size="medium" style={{ marginBottom: "10px" }}>
                <Image
                  src={storageBaseUrl + data.photoUrl}
                  // onClick={() => this.zoomPics(e.photoUrl)}
                />
              </Image.Group>
          </div>
        

        </div>
      );
    });
    return display;
  };
  return (
    <div>
      <Loader active={categoryAudits.length === 0} />
      {categoryAudits.length !== 0 && (
        
          displayData()
        
      )}

    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    //reducer props to state
    cannon: state.cannon,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductAudits)
);
