import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { isLoggedIn, uuidv4 } from '../../util';
import { createYamahaLineItemAction, deleteYamahaLineItemAction } from '../../actions/yamaha_actions';
import { Icon, Button, Grid, Form, Dropdown, Divider } from 'semantic-ui-react';
import ReactTable from 'react-table';

class HelmetDetails extends React.Component {
    static propTypes = {
        history: PropTypes.object.isRequired
    };
    constructor(props) {
        super(props);

        var helmetDetails = [{
            "Type": "Full Face",
            "ModelNames": [{
                "Name": "HELMET GENEX",
                "Price": 899
            },
            {
                "Name": "HEL-YR-4-WHITE",
                "Price": 999
            },
            {
                "Name": "HELMET YR7 MATT BLUE",
                "Price": 1518
            },
            {
                "Name": "HELMET YR GREEN GRAPHICS",
                "Price": 1320
            },
            {
                "Name": "YR7 GRAPHIC MATT BLACK BLUE",
                "Price": 1795
            },
            {
                "Name": "HEL-YR-5 BLACK GLOSSY",
                "Price": 1399
            },
            {
                "Name": "HELMET YRF GLOSSY BLUE GRAPHIC",
                "Price": 3599
            }
            ]
        },
        {
            "Type": "Jet",
            "ModelNames": [{
                "Name": "HELMET YR-6 CYAN GREEN",
                "Price": 1099
            },
            {
                "Name": "HELMET YR-2 SILVER",
                "Price": 1160
            },
            {
                "Name": "HELMET YR-3 BLUE-BLUE KING",
                "Price": 1350
            },
            {
                "Name": "HELMET ELMO DARK PINK",
                "Price": 1189
            },
            {
                "Name": "HELMET COSMO RED",
                "Price": 1030
            }
            ]
        },
        {
            "Type": "Half",
            "ModelNames": [{
                "Name": "HELMET AVION TRI COLOUR",
                "Price": 1380
            },
            {
                "Name": "HELMET AVION CHECK",
                "Price": 1480
            }
            ]
        },
        {
            "Type": "Motocross",
            "ModelNames": [{
                "Name": "HELMET-OFF-ROAD-BK/RD (Graph)",
                "Price": 1960
            },
            {
                "Name": "HELMET-OFF-ROAD-BK/GR (Graph)",
                "Price": 1960
            },
            {
                "Name": "HELMET-OFF-ROAD-BK/BL (Graph)",
                "Price": 1960
            }
            ]
        },
        {
            "Type": "Others",
            "ModelNames": []
        }
        ];

        var helmetRecord = {
            id: uuidv4(),
            type: '',
            modelName: '',
            standardPrice: '',
            tagPrice: '',
            noHelmetsAvailable: '',
            noPriceTagsAvailable: ''
        };

        this.state = {
            helmetDetails: helmetDetails,
            helmetRecord: helmetRecord,
            newHelmets: []
        };
    }

    componentDidMount() {
        if (!isLoggedIn(this.props.auth)) {
            this.props.history.push(`/login`);
            return;
        }
    }

    handleTableViewAuditClick = templates => {

    };

    resetHelmetRecordValues() {
        this.state.helmetRecord.id = uuidv4();
        this.state.helmetRecord.type = '';
        this.state.helmetRecord.modelName = '';
        this.state.helmetRecord.standardPrice = '';
        this.state.helmetRecord.tagPrice = '';
        this.state.helmetRecord.noHelmetsAvailable = '';
        this.state.helmetRecord.noPriceTagsAvailable = '';
        this.setState({ helmetRecord: this.state.helmetRecord });
    }

    addHelmetDetails() {
        var record = JSON.parse(JSON.stringify(this.state.helmetRecord))
        this.state.newHelmets.insertAt(0, record);

        this.props.createYamahaLineItemAction({
            auditId: this.props.auditId,
            itemType: 'helmet',
            data: record
        });

        this.resetHelmetRecordValues();
    }

    onHelmetTypeChange(e, combobox) {
        this.state.helmetRecord.type = combobox.value;
        if (combobox.value === 'Others') {
            this.state.helmetRecord.modelName = '';
            this.state.helmetRecord.standardPrice = '';
        }
        this.setState({ helmetRecord: this.state.helmetRecord })
    }

    onModelNameChange(e, combobox) {

        this.state.helmetRecord.modelName = combobox.value;
        this.state.helmetRecord.standardPrice = this.getStandardPrice(this.state.helmetRecord.type, combobox.value);
        this.setState({ helmetRecord: this.state.helmetRecord })
    }


    onModelNameTextChange(e) {
        this.state.helmetRecord.modelName = e.target.value;
        this.setState({ helmetRecord: this.state.helmetRecord })
    }

    onStandardPriceTextChange(e) {
        this.state.helmetRecord.standardPrice = e.target.value;
        this.setState({ helmetRecord: this.state.helmetRecord })
    }

    handleTagPriceChange(e) {
        this.state.helmetRecord.tagPrice = e.target.value;
        this.setState({ promotionName: this.state.helmetRecord });
    }
    handleNoHelmetsAvailabilityChange(e) {
        this.state.helmetRecord.noHelmetsAvailable = e.target.value;
        this.setState({ promotionName: this.state.helmetRecord });
    }

    handleNoPriceTagsAvailabilityChange(e) {
        this.state.helmetRecord.noPriceTagsAvailable = e.target.value;
        this.setState({ promotionName: this.state.helmetRecord });
    }

    getHelmetTypes() {
        var helmetTypes = [];

        for (var idx = 0; idx < this.state.helmetDetails.length; idx++) {
            var record = this.state.helmetDetails[idx];
            helmetTypes.push({
                key: idx,
                text: record.Type,
                value: record.Type
            });
        }
        return helmetTypes;
    }

    getHelmetModelNames(helmetType) {
        var modelNames = [];
        var types = this.state.helmetDetails.filter(function (record) {
            return record.Type === helmetType;
        })
        if (types.length > 0) {
            for (var idx = 0; idx < types[0].ModelNames.length; idx++) {
                modelNames.push({
                    key: idx,
                    text: types[0].ModelNames[idx].Name,
                    value: types[0].ModelNames[idx].Name
                });
            }
        }

        return modelNames;
    }

    getStandardPrice(helmetType, modelName) {
        var types = this.state.helmetDetails.filter(function (record) {
            return record.Type == helmetType;
        })
        if (types.length > 0) {
            for (var idx = 0; idx < types[0].ModelNames.length; idx++) {
                if (types[0].ModelNames[idx].Name === modelName) {
                    return types[0].ModelNames[idx].Price;
                }
            }
        }
        return -1
    }

    isNumber(value) {
        return /^\d+$/i.test(value);
    }

    removeTableClick(row) {
        this.props.deleteYamahaLineItemAction({
            itemId: row.id,
            itemType: "helmet",
            auditId: this.props.auditId,
        });
        var records = this.props.helmets
        records.splice(records.indexOf(row), 1);
        var newRecords = this.state.newHelmets
        newRecords.splice(newRecords.indexOf(row), 1);
        this.setState({ newHelmets: newRecords });
    }

    render() {
        var records = this.state.newHelmets.concat(this.props.helmets);
        var columns = [
            {
                Header: '',
                width: 35,
                style: { cursor: 'pointer' },
                Cell: row => (
                    <Icon className="remove large icon" color="red" title="Delete Row"
                        onClick={this.removeTableClick.bind(this, row.original)}
                    />
                )
            },
            {
                Header: 'Type',
                accessor: 'type',
                style: { cursor: 'pointer' },
                Cell: row => (
                    <AuditTableCell
                        row={row.original}
                        text={row.original.type}
                        onClick={this.handleTableViewAuditClick}
                    />
                )
            },
            {
                Header: 'Model Name',
                accessor: 'modelName',
                style: { cursor: 'pointer' },
                Cell: row => (
                    <AuditTableCell
                        row={row.original}
                        text={row.original.modelName}
                        onClick={this.handleTableViewAuditClick}
                    />
                )
            },
            {
                Header: 'Standard Price',
                accessor: 'standardPrice',
                style: { cursor: 'pointer' },
                Cell: row => (
                    <AuditTableCell
                        row={row.original}
                        text={row.original.standardPrice}
                        onClick={this.handleTableViewAuditClick}
                    />
                )
            },
            {
                Header: 'Tag Price',
                accessor: 'tagPrice',
                style: { cursor: 'pointer' },
                Cell: row => (
                    <AuditTableCell
                        row={row.original}
                        text={row.original.tagPrice}
                        onClick={this.handleTableViewAuditClick}
                    />
                )
            },
            {
                Header: 'No. of Helmets Available',
                accessor: 'noHelmetsAvailable',
                style: { cursor: 'pointer' },
                Cell: row => (
                    <AuditTableCell
                        row={row.original}
                        text={row.original.noHelmetsAvailable}
                        onClick={this.handleTableViewAuditClick}
                    />
                )
            },
            {
                Header: 'No. of Price Tags Available',
                accessor: 'noPriceTagsAvailable',
                style: { cursor: 'pointer' },
                Cell: row => (
                    <AuditTableCell
                        row={row.original}
                        text={row.original.noPriceTagsAvailable}
                        onClick={this.handleTableViewAuditClick}
                    />
                )
            }
        ];
        var helmetTypes = this.getHelmetTypes();
        var modelNames = this.getHelmetModelNames(this.state.helmetRecord.type);
        var canEnableAddbuton = this.state.helmetRecord.type && this.state.helmetRecord.modelName
            && this.isNumber(this.state.helmetRecord.tagPrice)
            && this.isNumber(this.state.helmetRecord.noHelmetsAvailable)
            && this.isNumber(this.state.helmetRecord.noPriceTagsAvailable);

        return (
            <div style={{ flexGrow: 1, display: 'flex', flexFlow: 'column' }}>
                <div>
                    <div style={{ display: 'flex', flexGrow: 1, flexFlow: 'column' }}>
                        <div>
                            <Divider horizontal>Add Helmets</Divider>
                            <Form>
                                <Grid>

                                    <Grid.Row columns={4} >
                                        <Grid.Column width={4}>
                                            <a className="ui teal left ribbon label primarybtnColor" style={{ marginLeft: 15 }}>
                                                Helmet Types
                                             </a>
                                            <Dropdown
                                                placeholder="Select Helmet Type"
                                                fluid
                                                search
                                                selection
                                                options={helmetTypes}
                                                value={this.state.helmetRecord.type}
                                                onChange={this.onHelmetTypeChange.bind(this)}
                                            />
                                        </Grid.Column>
                                        {this.state.helmetRecord.type != "Others" &&
                                            <Grid.Column width={6}>
                                                <a className="ui teal left ribbon label primarybtnColor" style={{ marginLeft: 15 }}>
                                                    Model Names
                                            </a>
                                                <Dropdown
                                                    placeholder="Select Model Name"
                                                    fluid
                                                    search
                                                    selection
                                                    options={modelNames}
                                                    value={this.state.helmetRecord.modelName}
                                                    onChange={this.onModelNameChange.bind(this)}
                                                />
                                            </Grid.Column>
                                        }

                                        {this.state.helmetRecord.type === "Others" && (
                                            <Grid.Column width={6}>
                                                <Form.Field >
                                                    <label>Model Name</label>
                                                    <input value={this.state.helmetRecord.modelName}
                                                        onChange={this.onModelNameTextChange.bind(this)} />
                                                </Form.Field>
                                            </Grid.Column>)
                                        }

                                        <Grid.Column width={3}>
                                            <Form.Field >
                                                <label>Standard Price</label>
                                                <input disabled={this.state.helmetRecord.type != "Others"}
                                                    value={this.state.helmetRecord.standardPrice}
                                                    onChange={this.onStandardPriceTextChange.bind(this)} />
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column width={3}>
                                            <Form.Field >
                                                <label>Tag Price</label>
                                                <input value={this.state.helmetRecord.tagPrice}
                                                    onChange={this.handleTagPriceChange.bind(this)} />
                                            </Form.Field>
                                        </Grid.Column>

                                    </Grid.Row>

                                    <Grid.Row columns={4}>

                                        <Grid.Column>
                                            <Form.Field >
                                                <label>No. of Helmets Available</label>
                                                <input value={this.state.helmetRecord.noHelmetsAvailable}
                                                    onChange={this.handleNoHelmetsAvailabilityChange.bind(this)} />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Form.Field >
                                                <label>No. of Price Tags Available</label>
                                                <input value={this.state.helmetRecord.noPriceTagsAvailable}
                                                    onChange={this.handleNoPriceTagsAvailabilityChange.bind(this)} />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column className="bottom aligned">
                                            <Form.Field >
                                                <Button primary disabled={!canEnableAddbuton} onClick={this.addHelmetDetails.bind(this)}>Add Helmet</Button>
                                            </Form.Field>
                                        </Grid.Column>

                                    </Grid.Row>
                                    <Divider horizontal> Helmets Details</Divider>

                                    <Grid.Row>
                                        <ReactTable
                                            noDataText="We couldn't find anything"
                                            filterable={true}
                                            style={{ height: '85%', width: '95%', marginLeft: 30 }}
                                            data={records}
                                            defaultPageSize={20}
                                            sortable={true}
                                            defaultFilterMethod={this.searchFilter}
                                            columns={columns}
                                        />
                                    </Grid.Row>
                                </Grid>
                            </Form>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}



const mapStateToProps = state => {
    return {
        auth: state.auth,
        yamaha: state.yamaha
    };
};

function AuditTableCell(props) {
    function onClick() {
        props.onClick(props.row);
    }
    return (
        <div style={props.style} onClick={onClick}>
            {props.text}
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            createYamahaLineItemAction: createYamahaLineItemAction,
            deleteYamahaLineItemAction: deleteYamahaLineItemAction
        },
        dispatch
    );
};

// HelmetDetails.PropTypes = {
//     dealers: PropTypes.array
// };

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(HelmetDetails)
);
