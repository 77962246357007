import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { isLoggedIn} from '../../util';
import Table from "../commonTables/Table.jsx"
import PreethiAudits from './PreethiAudits.jsx';
import { fetchPreethiTemplatesAction} from '../../actions/preethi_actions';

class Preethi extends React.Component {
    static propTypes = {
        history: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            selectedTemplate: {},
            isTemplatesView: true,
            isReportDownloading: false
        };
    }

    componentDidMount() {
        if (!isLoggedIn(this.props.auth)) {
            this.props.history.push(`/login`);
            return;
        }
        this.props.fetchPreethiTemplates();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ isReportDownloading: false });
    }

    searchFilter = (filter, row) => {
        var flatData = JSON.stringify(row._original);
        return flatData.toLowerCase().includes(filter.value.toLowerCase());
    };

    handleCloseClick = () => {
        this.setState({ isTemplatesView: true });
    };

    handleTableViewAuditClick = template => {
        if (!template._id) return;
        this.setState({ selectedTemplate: template, isTemplatesView: false });
    };

    render() {
        var templates = this.props.preethi.templates;
        var columns = [
            {
                Header: 'Template Name',
                accessor: 'auditName',
                style: { cursor: 'pointer' },
                Cell: row => (
                    <AuditTableCell
                        row={row.original}
                        text={row.original.auditName}
                        onClick={this.handleTableViewAuditClick}
                    />
                )
            },
            {
                Header: 'Live Audits',
                accessor: 'draftAudits',
                style: { cursor: 'pointer' },
                Cell: row => (
                    <AuditTableCell
                        row={row.original.stats}
                        text={row.original.stats.draftAudits}
                        onClick={this.handleTableViewAuditClick}
                    />
                )
            },
            {
                Header: 'Submitted Audits',
                accessor: 'submittedAudits',
                style: { cursor: 'pointer' },
                Cell: row => (
                    <AuditTableCell
                        row={row.original.stats}
                        text={row.original.stats.submittedAudits}
                        onClick={this.handleTableViewAuditClick}
                    />
                )
            },
            {
                Header: 'Total Audits',
                accessor: 'totalAudits',
                style: { cursor: 'pointer' },
                Cell: row => (
                    <AuditTableCell
                        row={row.original.stats}
                        text={row.original.stats.totalAudits}
                        onClick={this.handleTableViewAuditClick}
                    />
                )
            }
            // ,
            // {
            //     Header: 'Report',
            //     accessor: 'reportUrl',
            //     width: 150,
            //     style: { textAlign: 'center', cursor: 'pointer' },
            //     Cell: row => {
            //         if (row.original._id) {
            //             return (
            //                 <Icon
            //                     size="large"
            //                     color="green"
            //                     name="file excel outline"
            //                     onClick={() => this.downloadReport(row.original)}
            //                 />
            //             );
            //         }
            //         return <AuditTableCell row={row.original} text="" />;
            //     }
            // }
        ];

        return (
            <div style={{ flexGrow: 1, display: 'flex', flexFlow: 'column' }}>
                {this.state.isTemplatesView && (
                    <div>
                        <h1 style={{ paddingLeft: 30, flex: '0 0 30px' }}>Templates</h1>
                        <div style={{ display: 'flex', flexGrow: 1, flexFlow: 'column' }}>
                            <div>
                                <Table
                                    loading={this.state.isReportDownloading}
                                    defaultFilterMethod={this.searchFilter}
                                    templates={templates}
                                    columns={columns}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {!this.state.isTemplatesView && (
                    <div style={{ flexGrow: 1 }}>
                        <PreethiAudits selectedTemplate={this.state.selectedTemplate} onClose={this.handleCloseClick} />
                    </div>
                )}
            </div>
        );
    }
}



const mapStateToProps = state => {
    return {
        auth: state.auth,
        preethi: state.preethi
    };
};

function AuditTableCell(props) {
    function onClick() {
        props.onClick(props.row);
    }
    return (
        <div style={props.style} onClick={onClick}>
            {props.text}
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            fetchPreethiTemplates: fetchPreethiTemplatesAction
        },
        dispatch
    );
};

// Preethi.PropTypes = {
//     dealers: PropTypes.array
// };

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Preethi)
);
