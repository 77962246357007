import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import MaterialTable from "material-table";
import ButtonHandler from "./ButtonHandler";
import {
  getMatchingTint,
  getMainBackground,
  getFontColor,
} from "../../ThemeHandlers/theme";

const TanishqTable = (props) => {
  const [previous, setPrevious] = useState(null);
  const [next, setNext] = useState(null);
  const [total, seTotal] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [headerColor, setHeaderColor] = useState(null);
  const getColor = useSelector((state) => state.auth.color);
  useEffect(() => {
    setHeaderColor(getColor);
  }, [getColor]);

  let totalpage = total / 30 + 1;
  let roundOff = Math.round(totalpage);
  useEffect(() => {
    if (props.paginate !== undefined) {
      if (props.paginate.hasPrevious === false) {
        setCurrentPage(1);
      }
      setPrevious(!props.paginate.hasPrevious);
      setNext(!props.paginate.hasNext);
      seTotal(props.paginate.totalItems);
    }
  }, [props.paginate]);

  const clickedPre = () => {
    setCurrentPage(currentPage - 1);

    props.handle("previous", props.paginate, props.data);
  };
  const clickedNext = () => {
    setCurrentPage(currentPage + 1);

    props.handle("next", props.paginate, props.data);
  };

  return (
    <MaterialTable
      columns={props.columns}
      data={props.data}
      actions={props.selection === false ? props.actions : []}
      isLoading={props.load}
      options={{
        // actionsColumnIndex: -1,
        headerStyle: {
          backgroundColor: getMainBackground(headerColor),
          color: getFontColor(headerColor),
          fontSize: 15,
          fontWeight: "bold",
        },
        rowStyle: {
          backgroundColor: getMatchingTint(headerColor),
        },
        showTitle: false,
        selection: props.selection,
        toolbar: false,
        filtering: true,
        sorting: false,
        pageSize: 30,

        // filterCellStyle: { backgroundColor: "red", border: "1px solid teal" },
      }}
      onSelectionChange={
        props.selection === true ? props.onSelectionChange : null
      }
      onRowClick={props.onRowClick !== undefined ? props.onRowClick : null}
      components={{
        Pagination: (props) => {
          return (
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                height: 60,
                margin: 5,
              }}
            >
              <ButtonHandler
                isIcon={true}
                labelPosition={'left'}
                color={'teal'}
                click={clickedPre}
                iconName={'arrow circle left'}
                iconSize={'big'}
                disabled={previous !== null ? previous : false}
              />

              <span>{`Page ${currentPage} - off - ${roundOff}`}</span>
              <ButtonHandler
                labelPosition={'right'}

                isIcon={true}
                color={'teal'}
                click={clickedNext}
                iconName={'arrow circle right'}
                iconSize={'big'}
                disabled={next !== null ? next : false}
              />

            </div>
          );
        },
      }}
    />
  );
};
export default TanishqTable;
