import axios from 'axios';
import * as FileSaver from 'file-saver';
import * as types from './types';

export const fetchPreethiTemplatesAction = () => {
    return function(dispatch) {
        axios
            .get(types.API_URL + '/auditor/v1/preethi/getTemplates')
            .then(function(response) {
                dispatch({
                    type: types.FETCH_PREETHI_PHOTO_TEMPLATE_SUCCESS_ACTION,
                    payload: response.data
                });
            })
            .catch(function(error) {
                dispatch({
                    type: types.FETCH_PREETHI_PHOTO_TEMPLATE_FAILURE_ACTION,
                    payload: error
                });
            });
    };
};

export const fetchPreethiTemplateAuditsAction = templateId => {
    return function(dispatch) {
        axios
            .get(types.API_URL + '/auditor/v1/preethi/getAudits/' + templateId)
            .then(function(response) {
                dispatch({
                    type: types.FETCH_PREETHI_PHOTO_AUDITS_SUCCESS_ACTION,
                    payload: response.data
                });
            })
            .catch(function(error) {
                dispatch({
                    type: types.FETCH_PREETHI_PHOTO_AUDITS_FAILURE_ACTION,
                    payload: error
                });
            });
    };
};


export const downloadPreethiAuditReportAction = (auditId, templateId, auditName='audit-report') => {
    return function(dispatch) {
        axios
            .get(types.API_URL + '/auditor/v1/preethi/report/export/' + auditId + '?templateId=' + templateId, {
                responseType: 'blob'
            })
            .then(function(response) {
                FileSaver.saveAs(response.data, auditName + '.pdf');
                dispatch({
                    type: types.DOWNLOAD_PREETHI_PHOTO_AUDIT_REPORT_SUCCESS_ACTION,
                    payload: response.data
                });
            })
            .catch(function(error) {
                console.log(error);
                dispatch({
                    type: types.DOWNLOAD_PREETHI_PHOTO_AUDIT_REPORT_FAILURE_ACTION,
                    payload: error
                });
            });
    };
};
