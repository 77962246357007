import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import MaterialTable from "material-table";
import {
  getMatchingTint,
  getMainBackground,
  getFontColor,
} from "../../ThemeHandlers/theme";

const TableView = (props) => {
  const [headerColor, setHeaderColor] = useState(null);
  const noOfRows = props.noofRows ? 50 : 5 
  const [pageSize, setPageSize] =  useState( noOfRows);
  const [pageRanges, setPageRanges] = useState([5, 10, 20, 50, 75, 100])
  const getColor = useSelector((state) => state.auth.color);
  useEffect(() => {
    setHeaderColor(getColor);
  }, [getColor]);

  useEffect(() => {
    if (props?.noofRows && props?.noofRows > 0) {
      console.log(props.noofRows, typeof (props.noofRows), "noofRows")
      //setPageSize(Number(props.noofRows))
    }
  }, [])

  return (
    <MaterialTable
      columns={props.columns}
      data={props.data}
      actions={props.selection === false ? props.actions : []}
      isLoading={props.load !== undefined ? props.load : false}
      options={{
        headerStyle: {
          backgroundColor: getMainBackground(headerColor),

          color: getFontColor(headerColor),
          fontSize: 14,
          fontWeight: "bold",
        },
        rowStyle: {
          backgroundColor: getMatchingTint(headerColor),
          fontWeight: "normal",
        },
        actionsColumnIndex: -1,
        showTitle: false,
        selection: props.selection,
        toolbar: false,
        filtering: true,
        sorting: false,
        pageSize: pageSize,
        pageSizeOptions: pageRanges
        // filterCellStyle: { backgroundColor: "red", border: "1px solid teal" },
      }}
      onChangeRowsPerPage={(page_size) => { setPageSize(page_size) }}
      onSelectionChange={
        props.selection === true ? props.onSelectionChange : null
      }
      onRowClick={props.onRowClick !== undefined ? props.onRowClick : null}
    />
  );
};
export default TableView;
