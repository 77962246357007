import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn } from "./../../util";
import {
  dismissLogs,
  initiateUpload,
  furlencoUploadMasterCsv,
} from "../../actions/control_panel_action";
import { Dimmer, Loader } from "semantic-ui-react";
import { Button, Modal } from "semantic-ui-react";
import CommonForAllPannels from "./CommonForAllPannels.jsx";

class FurlencoControlPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      furlencovisible: true,

      furlencodealersCsvFile: null,
    };
  }

  componentWillMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!isLoggedIn(nextProps.auth)) {
      this.props.history.push(`/login`);
      return false;
    }
    return true;
  }

  furlencohandleDealerFileUpload = (files) => {
    const file = files.target.files[0];
    this.setState({ furlencodealersCsvFile: file });
    files.target.files = null;
  };

  furlencouploadDealerData = () => {
    this.props.initiateUpload(true);
    this.props.uploadDealerCsv(this.state.furlencodealersCsvFile);
  };

  furlencocloseLog = () => {
    this.props.dismissLogs(false);
  };

  render() {
    let furlencoPannel = [
      {
        itemForSpan: " Upload CSV to add Customers",
        itemInputChange: this.furlencohandleDealerFileUpload,
        itemClickButton: this.furlencouploadDealerData,
        itemContentForButton: "Upload Customers CSV",
      },
    ];
    return (
      <div
        style={{
          marginLeft: "2vw",
          marginTop: "7vh",
          fontWeight: "bold",
          fontFamily: "Arial, Helvetica, sans-serif",
        }}
      >
        <Dimmer active={this.props.controlPanel.uploadIncomplete}>
          <Loader size="massive">Uploading</Loader>
        </Dimmer>

        <CommonForAllPannels child={furlencoPannel} color={this.props.color} />

        {this.props.controlPanel.showLog && (
          <Modal
            close={this.furlencocloseLog}
            open={this.props.controlPanel.showLog}
          >
            <Modal.Header>Upload Report</Modal.Header>
            <Modal.Content>
              <div
                dangerouslySetInnerHTML={{
                  __html: this.props.controlPanel.log,
                }}
              />
            </Modal.Content>
            <Modal.Actions>
              <Button color="black" onClick={this.furlencocloseLog}>
                Dismiss
              </Button>
            </Modal.Actions>
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    home: state.home,
    controlPanel: state.controlPanel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      uploadDealerCsv: furlencoUploadMasterCsv,
      initiateUpload: initiateUpload,
      dismissLogs: dismissLogs,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FurlencoControlPanel)
);
