



import React, { useState } from "react";
import { useTable, usePagination, useRowSelect } from "react-table";
import "../commonTables/table.css";


const IndeterminateCheckbox = React.forwardRef(
  ({click, indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} onClick={click}/>
      </>
    );
  }
);

function CheckboxforAssign({ columns, data,rowClick,sendedData  }) {
  const [selectedId, setSelectedId] = useState([])
  // Use the state and functions returned from useTable to build your UI
  const handleClick = (data) => {
    var id = []
    if(id){
      id.push(data)
    }
    sendedData(id)
    
  }
 
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
    },
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} click={()=>{console.log(selectedFlatRows.map(
                  d => d.original._id
                ),"newwwwwwww")}}/>
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()}    click={()=>{handleClick(row.original)}}/>
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

 

  // Render the UI for your table
  return (
    <>
      <table id="customers" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}  style={{cursor:'pointer'}}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}  onClick={() => { cell.column.id !== 'selection' ? rowClick(row.original) : console.log("checkbox clicked")}}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          height: 40,
          alignItems: "center",
        }}
      >
        <div style={{ flex: "30%", flexDirection: "row" }}>
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </button>{" "}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </button>{" "}
        </div>

        <span style={{ marginRight: 50 }}>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>

        <select
          value={pageSize}
          style={{ marginRight: 20, border: "1px solid #e8e8e8" }}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}

export default CheckboxforAssign;