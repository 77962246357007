import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  Icon,
  Segment,
  Accordion,
  Dropdown,
  Label,
  Grid,
  Button,
  Tab,
  Divider,
} from "semantic-ui-react";
import {
  UploadHershysCheckAction,
  saveHershysCheckAction,
  clearFetchedAudits,
} from "../../actions/HershysCheckAction";
import LoadingScreen from "../Helper/Loader";
import { uuidv4 } from "../../util";
import "./hershysChecklist.css";
import config from "../../config";
const storageBaseUrl = config["storage_base_url"];

const Audits = (props) => {
  const [AuditsFromTemplate, setAuditsFromTemplate] = useState({});
  const [template, setTemplate] = useState({});
  const [rootPanels, setRootPanels] = useState([]);
  const [master, setMaster] = useState({});
  const [checklistQuestionColor, setChecklistQuestionColor] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loaderText, setLoadingText] = useState("");
  const [enableEdit, setEnableEdit] = useState(true);
  useEffect(() => {
    setTemplate(props.hershysCheck.selectedTemplate);
  }, []);
  const panes = [
    {
      menuItem: { key: "audits", icon: "pencil alternate", content: "Audits" },
      render: () => (
        <Tab.Pane>
          <Accordion
            defaultActiveIndex={0}
            panels={rootPanels}
            styled
            style={{ width: "97%" }}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        key: "checklist",
        icon: "clipboard check",
        content: "Check List",
      },
      render: () => <Tab.Pane>{FormCheckListPanes()}</Tab.Pane>,
    },
    {
      menuItem: {
        key: "snaps",
        icon: "camera retro",
        content: "Snaps",
      },
      render: () => <Tab.Pane>{FormSnapPanes()}</Tab.Pane>,
    },
  ];

  useEffect(() => {
    let color = checklistQuestionColor;
    if (
      props.hershysCheck.existingAudits !== undefined &&
      Object.keys(props.hershysCheck.existingAudits).length !== 0
    ) {
      setAuditsFromTemplate(props.hershysCheck.existingAudits);
    } else {
      setAuditsFromTemplate(props.hershysCheck.selectedTemplate);
    }

    if (Object.keys(props.hershysCheck.selectedTemplate).length !== 0) {
      if (props.hershysCheck.selectedTemplate.checklist) {
        props.hershysCheck.selectedTemplate.checklist.map((data) => {
          color.push("black");
        });
      }
      setChecklistQuestionColor([...color]);
    }
  }, [props.hershysCheck.selectedTemplate]);
  useEffect(() => {
    const { selectedMaster } = props.hershysCheck;
    setMaster(selectedMaster);
  }, [props.hershysCheck.selectedMaster]);
  const { audits } = AuditsFromTemplate;

  useEffect(() => {
    let newAudit = AuditsFromTemplate.audits;
    if (newAudit && newAudit.length !== 0) {
      let root = [];
      let forSubCat;

      newAudit.forEach((auditsData, index) => {
        if (!root.some((el) => el.categoryName === auditsData.categoryName)) {
          auditsData.subCategory.forEach((subCat) => {
            forSubCat = getSubCategory(subCat, auditsData.categoryName);
          });
          root.push({
            key: `panel-${index + 1}`,
            title: auditsData.categoryName,
            content: {
              content: forSubCat,
            },
          });
        }
      });
      setRootPanels(root);
    }
  }, [audits]);

  const changeQuestionColor = (question, index) => {
    let colorChange = [...checklistQuestionColor];
    colorChange[index] = "teal";
    setChecklistQuestionColor([...colorChange]);
  };

  const handleChange = (event, id, emp) => {
    const file = event.target.files[0];
    let snapshots = [...AuditsFromTemplate.snaps];

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    props.UploadHershysCheckAction({
      form: file,
      orgId: template.orgId,
      config: config,
      onSuccess: (res) => {
        if (snapshots.length !== 0) {
          snapshots.forEach((snaps) => {
            if (snaps.id === id.id) {
              if (!"uploadedUrl" in snaps) {
                snaps["uploadedUrl"] = `${res}`;
              } else {
                snaps["uploadedUrl"] = `${res}`;
              }
            }
          });
        }
      },
      onFailure: (err) => {},
    });

    setAuditsFromTemplate({ ...AuditsFromTemplate, snaps: snapshots });
    // props.handleFile(fileUploaded);
  };

  const FormSnapPanes = () => {
    return (
      <Segment padded>
        <Grid>
          <Grid.Row columns={2}>
            {AuditsFromTemplate.snaps !== undefined &&
            AuditsFromTemplate.snaps.length !== 0
              ? AuditsFromTemplate.snaps.map((snap) => {
                  return (
                    <>
                      <Grid.Column width={8}>
                        <span
                          style={{
                            fontSize: 16,
                            fontWeight: "inherit",
                          }}
                        >
                          {snap.question.charAt(0).toUpperCase() +
                            snap.question.slice(1)}
                        </span>
                      </Grid.Column>
                      <Grid.Column width={4} style={{ padding: 5 }}>
                        <input
                          type="file"
                          // ref={hiddenFileInput}
                          onChange={(e) => {
                            handleChange(e, snap);
                          }}
                        />
                      </Grid.Column>
                      <Grid.Column width={4} style={{ padding: 5 }}>
                        {snap.uploadedUrl !== undefined && (
                          <img
                            src={storageBaseUrl + `${snap.uploadedUrl}`}
                            style={{ height: "80px", width: "120px" }}
                          />
                        )}
                      </Grid.Column>
                    </>
                  );
                })
              : null}
          </Grid.Row>
        </Grid>
      </Segment>
    );
  };

  const FormCheckListPanes = () => {
    return (
      <Segment padded>
        <Grid>
          <Grid.Row columns={2}>
            {AuditsFromTemplate.checklist !== undefined &&
            AuditsFromTemplate.checklist.length !== 0
              ? AuditsFromTemplate.checklist.map((check, index) => {
                  let formOptions = [];
                  check.options !== undefined &&
                    check.options.length !== 0 &&
                    check.options.map((opt) => {
                      formOptions.push({
                        key: opt.value,
                        text: opt.label,
                        value: opt.value,
                      });
                    });
                  return (
                    <>
                      <Grid.Column width={12}>
                        <span
                          style={{
                            color: checklistQuestionColor[index],
                            fontSize: 16,
                            fontWeight: "inherit",
                          }}
                        >
                          {check.question}
                        </span>
                      </Grid.Column>
                      <Grid.Column width={4} style={{ padding: 5 }}>
                        <Dropdown
                          selection
                          options={formOptions}
                          defaultValue={
                            check.answer !== undefined ? check.answer : ""
                          }
                          onClick={() => {
                            changeQuestionColor(index);
                          }}
                          onClose={() => backtoNormalColor(index)}
                          onChange={(e, data) => {
                            changeChecklistAnswer(e, data, check.id);
                          }}
                        />
                      </Grid.Column>
                    </>
                  );
                })
              : null}
          </Grid.Row>
        </Grid>
      </Segment>
    );
  };

  const changeChecklistAnswer = (e, data, id) => {
    let checklist = [...AuditsFromTemplate.checklist];
    checklist.forEach((check) => {
      if (check.id === id) {
        if (!"answer" in check) {
          check["answer"] = data.value;
        } else {
          check["answer"] = data.value;
        }
      }
    });
    setAuditsFromTemplate({ ...AuditsFromTemplate, checklist });
  };
  const backtoNormalColor = (index) => {
    let colorChange = [...checklistQuestionColor];
    colorChange[index] = "black";
    setChecklistQuestionColor([...colorChange]);
  };

  const getSubCategory = (sub, categoryname) => {
    let formed = [];
    let quesFormed = [];
    if (audits && audits.length !== 0) {
      audits.forEach((subData, index) => {
        if (subData.categoryName === categoryname) {
          subData.subCategory.forEach((subCat) => {
            subCat.questions.forEach((ques) => {
              quesFormed = getQues(
                subData.categoryName,
                subCat.subCategoryName
              );
            });
            formed.push(
              <div style={{ marginTop: 10 }}>
                <Label color="teal">{subCat.subCategoryName}</Label>
                <Accordion.Accordion panels={quesFormed} />
              </div>
            );
          });
        }
      });
    }
    return formed;
  };

  const getQues = (categoryName, subCategoryName) => {
    let quesFormed = [];
    let quesFormation = AuditsFromTemplate.audits;
    if (quesFormation && quesFormation.length !== 0) {
      quesFormation.forEach((subData, index) => {
        if (subData.categoryName === categoryName) {
          subData.subCategory.forEach((subCat) => {
            if (subCat.subCategoryName === subCategoryName) {
              subCat.questions.forEach((ques, index) => {
                let option = [];
                ques.answerType === "dropdown" &&
                  ques.options.map((opt) => {
                    option.push({
                      key: opt.value,
                      text: opt.label,
                      value: opt.value,
                    });
                  });
                quesFormed.push({
                  key: `ques-${index + 1}`,
                  title: ques.question,
                  content: {
                    content:
                      ques.answerType === "dropdown" ? (
                        <Grid>
                          <Grid.Row columns={4}>
                            <Grid.Column>
                              <Segment padded>
                                <Label attached="top" color="teal">
                                  Options
                                </Label>
                                <Dropdown
                                  selection
                                  style={{ width: "100%" }}
                                  options={option}
                                  defaultValue={
                                    ques.answer !== undefined ? ques.answer : ""
                                  }
                                  onChange={(e, data) =>
                                    onAnswersChange(
                                      { optionValue: data.value },
                                      categoryName,
                                      subCategoryName,
                                      ques.id
                                    )
                                  }
                                />
                              </Segment>
                            </Grid.Column>
                            <Grid.Column>
                              <Segment padded style={{ height: "115px" }}>
                                <Label attached="top" color="teal">
                                  Actual Score
                                </Label>
                                <input
                                  className="no-spinner"
                                  type="number"
                                  style={{ width: "100%", height: "38px" }}
                                  value={
                                    ques.actualScore !== undefined
                                      ? ques.actualScore
                                      : ""
                                  }
                                  onChange={(event) => {
                                    onAnswersChange(
                                      { actualValue: event.target.value },
                                      categoryName,
                                      subCategoryName,
                                      ques.id
                                    );
                                  }}
                                />
                              </Segment>
                            </Grid.Column>
                            <Grid.Column>
                              <Segment padded style={{ height: "115px" }}>
                                <Label attached="top" color="teal">
                                  Remarks
                                </Label>

                                <input
                                  className="no-spinner"
                                  style={{ width: "100%", height: "38px" }}
                                  value={
                                    ques.remarks !== undefined
                                      ? ques.remarks
                                      : ""
                                  }
                                  onChange={(event) => {
                                    onAnswersChange(
                                      { remarks: event.target.value },
                                      categoryName,
                                      subCategoryName,
                                      ques.id
                                    );
                                  }}
                                />
                              </Segment>
                            </Grid.Column>
                            <Grid.Column>
                              <Segment padded style={{ height: "115px" }}>
                                <Label
                                  attached="top"
                                  color="teal"
                                  size="medium"
                                >
                                  Max Score
                                </Label>
                                <Label
                                  size="big"
                                  color="purple"
                                  style={{ padding: 12 }}
                                >
                                  <Icon name="check square" />{" "}
                                  {` Max Score - ${ques.maxScore}`}
                                </Label>
                              </Segment>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      ) : ques.answerType === "text" ? (
                        <Grid>
                          <Grid.Row columns={4}>
                            <Grid.Column>
                              <Segment padded style={{ height: "115px" }}>
                                <Label attached="top" color="teal">
                                  Text
                                </Label>
                                <input
                                  placeholder="Enter Value"
                                  style={{ width: "100%", height: "38px" }}
                                  value={
                                    ques.answer !== undefined ? ques.answer : ""
                                  }
                                  onChange={(event) => {
                                    onAnswersChange(
                                      { optionValue: event.target.value },
                                      categoryName,
                                      subCategoryName,
                                      ques.id
                                    );
                                  }}
                                />
                              </Segment>
                            </Grid.Column>
                            <Grid.Column>
                              <Segment padded style={{ height: "115px" }}>
                                <Label attached="top" color="teal">
                                  Actual Score
                                </Label>
                                <input
                                  className="no-spinner"
                                  type="number"
                                  placeholder="Actual Score"
                                  style={{ width: "100%", height: "38px" }}
                                  value={
                                    ques.actualScore !== undefined
                                      ? ques.actualScore
                                      : ""
                                  }
                                  onChange={(event) => {
                                    onAnswersChange(
                                      { actualValue: event.target.value },
                                      categoryName,
                                      subCategoryName,
                                      ques.id
                                    );
                                  }}
                                />
                              </Segment>
                            </Grid.Column>
                            <Grid.Column>
                              <Segment padded style={{ height: "115px" }}>
                                <Label attached="top" color="teal">
                                  Remarks
                                </Label>

                                <input
                                  className="no-spinner"
                                  placeholder="Remarks"
                                  style={{ width: "100%", height: "38px" }}
                                  value={
                                    ques.remarks !== undefined
                                      ? ques.remarks
                                      : ""
                                  }
                                  onChange={(event) => {
                                    onAnswersChange(
                                      { remarks: event.target.value },
                                      categoryName,
                                      subCategoryName,
                                      ques.id
                                    );
                                  }}
                                />
                              </Segment>
                            </Grid.Column>
                            <Grid.Column>
                              <Segment padded style={{ height: "115px" }}>
                                <Label
                                  attached="top"
                                  color="teal"
                                  size="medium"
                                >
                                  Max Score
                                </Label>
                                <Label
                                  size="big"
                                  color="purple"
                                  style={{ padding: 12 }}
                                >
                                  <Icon name="check square" />{" "}
                                  {` Max Score - ${ques.maxScore}`}
                                </Label>
                              </Segment>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      ) : null,
                  },
                });
              });
            }
          });
        }
      });
    }
    return quesFormed;
  };

  const onAnswersChange = (answer, categoryname, subCategoryName, id) => {
    let newAudits = [...AuditsFromTemplate.audits];
    if (newAudits && newAudits.length !== 0) {
      newAudits.map((data) => {
        if (data.categoryName === categoryname) {
          data.subCategory.map((subCat) => {
            if (subCat.subCategoryName === subCategoryName) {
              subCat.questions.map((ques) => {
                if (ques.id === id) {
                  if (answer.optionValue !== undefined) {
                    if (!ques["answer"]) {
                      ques["answer"] = answer.optionValue;
                    } else {
                      ques["answer"] = answer.optionValue;
                    }
                  } else if (answer.actualValue !== undefined) {
                    if (!ques["actualScore"]) {
                      ques["actualScore"] = answer.actualValue;
                    } else {
                      ques["actualScore"] = answer.actualValue;
                    }
                  } else if (answer.remarks !== undefined) {
                    if (!ques["remarks"]) {
                      ques["remarks"] = answer.remarks;
                    } else {
                      ques["remarks"] = answer.remarks;
                    }
                  }
                }
              });
            }
          });
        }
      });

      setAuditsFromTemplate({ ...AuditsFromTemplate, audits: newAudits });
    }
  };

  const saveFirst = () => {
    let data = {
      orgId:
        AuditsFromTemplate.orgId !== undefined
          ? AuditsFromTemplate.orgId
          : template.orgId,
      templateId:
        AuditsFromTemplate.templateId !== undefined
          ? AuditsFromTemplate.templateId
          : template._id,
      masterId:
        AuditsFromTemplate.masterId !== undefined
          ? AuditsFromTemplate.masterId
          : master._id,
      auditId:
        AuditsFromTemplate.auditId !== undefined
          ? AuditsFromTemplate.auditId
          : uuidv4(),
      audits: AuditsFromTemplate.audits,
      checklist: AuditsFromTemplate.checklist,
      snaps: AuditsFromTemplate.snaps,
    };
    setLoading(true);
    setLoadingText("Saving Audits");
    props.saveHershysCheckAction({
      data: data,
      onSuccess: () => {
        setLoading(false);
        setLoadingText("");
        setEnableEdit(true)
      },
      onFailure: () => {
        setLoading(false);
        setLoadingText("");
        setEnableEdit(true)

      },
    });
  };

  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "column",overflowY:'hidden' }}>
      <LoadingScreen active={loading} text={loaderText} />
      <div style={{ padding: 15, display: "inline-block" }}>
        <Segment
          onClick={props.onClose}
          style={{
            color: "#808080",
            float: "right",
            cursor: "pointer",
            marginTop: "10px",
            position: "absolute",
            right: 58,
          }}
        >
          <Icon name="arrow" className="left large" color="brown" />
        </Segment>
        <h1
          style={{
            color: "orange",
            display: "inline-block",
          }}
        >
          Hershys Checklist Audits
        </h1>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",

          width: "97%",
          padding: 10,
        }}
      >
        <Button
          size="medium"
          color={master.status === "Pending" ? "orange" : "yellow"}
        >{`Audit Status - ${master.status}`}</Button>
        {master.status === 'Assigned' && master.status !== 'In Progress' && (
          <Button primary onClick={saveFirst}>
            Save
          </Button>
        )}
         {master.status === 'Pending' && master.status !== 'In Progress' && (
          <Button primary onClick={saveFirst}>
            Save
          </Button>
        )}
        {master.status === "In Progress" && (
          <Button primary onClick={saveFirst}>
          Save
        </Button>
          // <div
          //   style={{
          //     display: "flex",
          //     flexDirection: "row",
          //     justifyContent: "space-evenly",
          //     width: "20%",
          //   }}
          // >
          //   <Button
          //     style={{
          //       backgroundColor: enableEdit === true ? "teal" : "#FFFFFF",
          //       border: "1px solid teal",
          //       color: enableEdit === true ? "#FFFFFF" : "teal",
          //     }}
          //     onClick={() => {
          //       setEnableEdit(false);
          //     }}
          //   >
          //     Edit
          //   </Button>

          //   {enableEdit === false && (
          //     <Button
          //       color="teal"
          //       onClick={saveFirst}
          //       disabled={enableEdit}
          //       style={{
          //         backgroundColor: enableEdit === false ? "teal" : "#FFFFFF",
          //         border: "1px solid teal",
          //         color: enableEdit === true ? "teal" : "#FFFFFF",
          //       }}
          //     >
          //       Save
          //     </Button>
          //   )}
          // </div>
        )}
      </div>
      <div
        style={{
          margin: 10,
          pointerEvents: enableEdit === true  && master.status === "Pending" || 'Assigned' ? "visible" : "none",
        }}
      >
        <Tab panes={panes} />
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    hershysCheck: state.hershysCheck,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { UploadHershysCheckAction, saveHershysCheckAction, clearFetchedAudits },
    dispatch
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Audits));
