import {
  FETCH_DIAMLER_KEY_TEMPLATE_SUCCESS_ACTION,
  FETCH_DIAMLER_KEY_TEMPLATE_FAILURE_ACTION,
  FETCH_DIAMLER_KEY_DEALERS_SUCCESS_ACTION,
  FETCH_DIAMLER_KEY_DEALERS_FAILURE_ACTION,
  FETCH_DIAMLER_KEY_VEHICLES_SUCCESS_ACTION,
  FETCH_DIAMLER_KEY_VEHICLES_FAILURE_ACTION,
  FETCH_DIAMLER_KEY_AUDITS_SUCCESS_ACTION,
  FETCH_DIAMLER_KEY_AUDITS_FAILURE_ACTION,
  SAVE_DIAMLER_KEY_AUDITS_SUCCESS_ACTION,
  SAVE_DIAMLER_KEY_AUDITS_FAILURE_ACTION,
  DOWNLOAD_DIAMLER_KEY_REPORT_SUCCESS_ACTION,
  DOWNLOAD_DIAMLER_KEY_REPORT_FAILURE_ACTION,
  UPLOAD_DIAMLER_KEY_PHOTO_SUCCESS,
  UPLOAD_DIAMLER_KEY_PHOTO_FAILURE,
  SHOW_LOADING_SCREEN_KEY_MGMT,
  TO_CHECK_PHOTOS,
  FETCH_VINS_COUNTS_SUCCESS_ACTION,
  FETCH_VINS_COUNTS_FAILURE_ACTION
} from "../actions/types";

const INIT_STATE = {};

 function daimlerKey (state = INIT_STATE, action) {
  state = Object.assign({}, state, {});

  switch (action.type) {

    case FETCH_DIAMLER_KEY_TEMPLATE_SUCCESS_ACTION:
      state.templates = action.payload.templates;
      state.fetchTemplateError = false;
      return state;

    case FETCH_DIAMLER_KEY_TEMPLATE_FAILURE_ACTION:
      state.fetchTemplateError = true;
      return state;
    case FETCH_DIAMLER_KEY_DEALERS_SUCCESS_ACTION:
      return Object.assign({}, state, {
        dealers: action.payload.dealers,

        isFetchDiamlerKeyDealersSuccess: true
      });

    case FETCH_DIAMLER_KEY_DEALERS_FAILURE_ACTION:
      return Object.assign({}, state, {
        isFetchDiamlerKeyDealersSuccess: false
      });

    case FETCH_DIAMLER_KEY_AUDITS_SUCCESS_ACTION:
      return Object.assign({}, state, {
        audits: action.payload.audits,
        metrics: action.payload.metrics,
        fetchAuditsError: false
      });
    case FETCH_DIAMLER_KEY_AUDITS_FAILURE_ACTION:
      return Object.assign({}, state, {
        fetchAuditsError: true
      });

    case FETCH_DIAMLER_KEY_VEHICLES_SUCCESS_ACTION:
      return Object.assign({}, state, {
        vehicles: action.payload.vehicles,
        fetchVehicleError: false
      });
    case FETCH_DIAMLER_KEY_VEHICLES_FAILURE_ACTION:
      return Object.assign({}, state, {
        fetchVehicleError: true
      });
    case SAVE_DIAMLER_KEY_AUDITS_SUCCESS_ACTION:
      return Object.assign({}, state, {
        saveAudit: action.payload.audits,
        isSaveAuditsSuccess: true
      });

    case SAVE_DIAMLER_KEY_AUDITS_FAILURE_ACTION:
      return Object.assign({}, state, {
        isSaveAuditsSuccess: false
      });
    case DOWNLOAD_DIAMLER_KEY_REPORT_SUCCESS_ACTION:
      var buff = new Buffer(action.payload, "base64");
      var text = buff.toString("utf-8");

      // fileDownload(text, "keymanagementAuditReport.xlsx");

      return Object.assign({}, state, {
        downloadSuccess: true
      });

    case DOWNLOAD_DIAMLER_KEY_REPORT_FAILURE_ACTION:
      return Object.assign({}, state, {
        downloadSuccess: false
      });

    case SHOW_LOADING_SCREEN_KEY_MGMT:
      return Object.assign({}, state, {
        showLoadingScreen: action.payload
      })
    case TO_CHECK_PHOTOS:
      return Object.assign({}, state, {
        historyPhotos: action.payload,
        isPhotoUploadFail: false,
        showLoadingScreen: false
      });
    case UPLOAD_DIAMLER_KEY_PHOTO_SUCCESS:
      return Object.assign({}, state, {
        savedPhotos: action.payload,
        isPhotoUploadFail: false,
        showLoadingScreen: false
      });

    case UPLOAD_DIAMLER_KEY_PHOTO_FAILURE:
      return Object.assign({}, state, {
        isPhotoUploadFail: true,
        showLoadingScreen: false
      });


      case FETCH_VINS_COUNTS_SUCCESS_ACTION:
        return Object.assign({}, state, {
          counts: action.payload.counts,
          fetchVehiclesError: false
        });
        case FETCH_VINS_COUNTS_FAILURE_ACTION:
          return Object.assign({}, state, {
            vehicles: [],
            dealerSummary:[],
            fetchVehiclesError: false,
            
          });
        default:
          return state  
  }


}

export default daimlerKey
