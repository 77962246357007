import axios from "axios";
import * as FileSaver from "file-saver";
import * as types from "./types";

export const fetchFurlencoTemplatesAction = ({ onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/audit/furlenco/templates")
      .then(function (resp) {
        dispatch({
          type: types.FETCH_FURLENCO_TEMPLATE_SUCCESS_ACTION,
          payload: resp.data,
        });
        onSuccess();
      })
      .catch(function (err) {
        dispatch({
          type: types.FETCH_FURLENCO_TEMPLATE_FAILURE_ACTION,
          payload: err.data,
        });
        onFailure();
      });
  };
};

export const fetchFurlencoCustomerAction = ({ id, onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL +
          "/auditor/v1/audit/furlenco/filterCustomers?templateId=" +
          id
      )
      .then(function (response) {
        dispatch({
          type: types.FETCH_FURLENCO_CUSTOMER_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_FURLENCO_CUSTOMER_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const fetchFurlencoAuditsAction = (id) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/furlenco/audits?customerId=" + id)
      .then(function (response) {
        dispatch({
          type: types.FETCH_FURLENCO_AUDITS_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_FURLENCO_AUDITS_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const saveFurlencoAuditAction = (audit_id, audit) => {
  return function (dispatch) {
    axios
      .put(types.API_URL + "/auditor/v1/audit/furlenco/" + audit_id, audit)
      .then(function (response) {
        dispatch({
          type: types.SAVE_FURLENCO_AUDIT_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.SAVE_FURLENCO_AUDIT_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const filterCustomersAction = ({
  fromDate,
  toDate,
  tempId,
  onSuccess,
  onFailure,
}) => {
  console.log(fromDate, toDate, "Actionn");
  return function (dispatch) {
    axios
      .get(
        types.API_URL +
          `/auditor/v1/audit/furlenco/filterCustomers?startdate=${fromDate}&enddate=${toDate}&templateId=${tempId}`
      )
      .then(function (response) {
        dispatch({
          type: types.FILTER_CUSTOMERS_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.FILTER_CUSTOMERS_SUCCESS_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const downloadFurlencoAuditReportAction = (auditId) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/report/furlenco?auditId=" + auditId, {
        responseType: "blob",
      })
      .then(function (response) {
        FileSaver.saveAs(response.data, "furlencoAuditReport.xlsx");
        dispatch({
          type: types.DOWNLOAD_FURLENCO_REPORT_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.DOWNLOAD_FURLENCO_REPORT_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const assignUserToCustomer = (customerId, userId) => {
  return function (dispatch) {
    axios
      .post(
        types.API_URL +
          `/auditor/v1/audit/furlenco/assignAudits?dealerId=${customerId}&userId=${userId}`
      )
      .then(function (response) {
        dispatch({
          type: types.ASSIGN_USER_TO_CUSTOMER_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.ASSIGN_USER_TO_CUSTOMER_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};
