import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn } from "../../util";
import { List, Radio } from "semantic-ui-react";
import {
  pandgAuditExportAction,
  pandgAuditSubmitAction,
} from "../../actions/pandg_actions.js";
import PandGHeader from "./PandGHeader.jsx";
import {
  getMainBackground,
  getFontColor,

} from "../../ThemeHandlers/theme";
import ConstantLabel from "../commonViews/ConstantLabel";
class PandGSchoolAuditsQuestionare extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: false,
      editMode: false,
      editPropName: "Edit",
      selectedAudit: this.props.selectedAudit,
    };
    this.auditForEdit = {};
  }

  componentDidMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
      return false;
    }
    return true;
  }
  onDownloadReport = () => {
    this.props.pandgAuditExport(this.props.pandg.selectedPandGAudit._id);
  };

  onDownloadPhotos = () => {
    window.open(`#/images/` + this.props.pandg.selectedPandGAudit._id);
  };
  onQuestionAnswerChange = (question) => {
    question.answer = question.answer === "Yes" ? "No" : "Yes";
    // just for re-rendering the UI
    this.setState({ currentQuestion: question });
  };
  onEditAuditClick(audit) {
    console.log("coming");
    if (this.state.editMode) {
      this.setState({ editPropName: "Edit", editMode: false });
      this.props.pandgAuditSubmit(audit);
    } else {
      this.setState({ editPropName: "Save", editMode: true });
    }
  }

  getAuditQuestions(questions) {
    return questions.map((eachQuestion, index) => {
      return (
        <List.Item key={index}>
          <div style={{ marginTop: 10 }}>
            <span
              style={{
                verticalAlign: "middle",
                width: "70%",
                display: "inline-block",
                fontSize: 15,
                fontWeight: "bold",
              }}
            >
              {eachQuestion.question}
            </span>
            <span
              style={{ verticalAlign: "middle", fontSize: 15, marginLeft: 15 }}
            >
              <Radio
                toggle
                disabled={this.state.editMode != true}
                checked={eachQuestion.answer == "Yes" ? true : false}
                onChange={(evt) => this.onQuestionAnswerChange(eachQuestion)}
              />{" "}
              {eachQuestion.answer === "Yes" ? eachQuestion.answer : "No"}
            </span>
          </div>
        </List.Item>
      );
    });
  }

  render() {
    var selectedAudit = this.state.selectedAudit,
      audit = this.auditForEdit;
    // create a copy of audit to use it for editing instead of modifying the original data.
    if (selectedAudit._id != this.auditForEdit._id) {
      audit = this.auditForEdit = JSON.parse(
        JSON.stringify(this.props.selectedAudit)
      );
    }
    return (
      <div>
        <PandGHeader
          audit={this.auditForEdit}
          onClick={this.onDownloadReport}
          onPhotos={this.onDownloadPhotos}
          color={this.props.color}
        />
        <ConstantLabel
          title={this.state.editPropName}
          size={"large"}
          onClick={() => this.onEditAuditClick(audit)}
          styles={{
            cursor: "pointer",
            backgroundColor: getMainBackground(this.props.color),
            color: getFontColor(this.props.color),
            marginTop: 10,
          }}
        />

        <List>
          {audit.schoolAudits[0] &&
            this.getAuditQuestions(audit.schoolAudits[0].questions)}
        </List>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    pandg: state.pandg,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      pandgAuditExport: pandgAuditExportAction,
      pandgAuditSubmit: pandgAuditSubmitAction,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PandGSchoolAuditsQuestionare)
);
