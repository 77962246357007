import React from "react";
import { Button, Icon } from "semantic-ui-react";

const ButtonHandler = (props) => {
    return (
        <div>
            <Button
                icon={props.isIcon !== undefined ? props.isIcon : false}
                size={props.size}
                style={props.styles !== undefined ? props.styles : null}
                color={props.color !== undefined ? props.color : null}
                onClick={props.click}
                fluid={props.isfluid !== undefined ? props.isfluid : false}
                disabled={props.disabled !== undefined ? props.disabled : false}
                loading={props.loading !== undefined ? props.loading : false}
            >
                {props.isIcon !== undefined && (
                    <Icon
                        name={props.iconName}
                        size={props.iconSize !== undefined ? props.iconSize : undefined}
                        color={props.iconColor !== undefined ? props.iconColor : undefined}
                        style={{ cursor: "pointer" }}
                    />
                )}
                {props.title !== undefined ? `  
                ${props.title}` : null}
            </Button>
        </div>
    );
};
export default ButtonHandler;
