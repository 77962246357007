import React from 'react';
import { Dropdown } from 'semantic-ui-react';
const DropDownPicker = (props) => {
    return (
        <Dropdown
            placeholder={props.placeholder}
            fluid
            search
            selection
            style={props.styles !== undefined ? props.styles : null}
            options={props.options}
            value={props.value}
            onChange={(e, data) => { props.onChangeDropDown(data.value) }}

            disabled={props.disabled !== undefined ? props.disabled : false}
        />
    )
}

export default DropDownPicker