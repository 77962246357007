import axios from "axios";
import * as FileSaver from "file-saver";
import * as types from "./types";

export const fetchPGDTemplatesAction = ({ onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/pgd/templates")
      .then(function (response) {
        dispatch({
          type: types.FETCH_PGD_TEMPLATE_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response.data);
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_PGD_TEMPLATE_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const fetchPGDCitiesList = ({ templateId, onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + `/auditor/v1/pgd/cityList?templateId=${templateId}`)
      .then(function (response) {
        dispatch({
          type: types.FETCH_PGD_CITIES_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response.data);
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_PGD_CITIES_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const fetchPGDMasterAction = ({
  templateId,
  cityName,
  onSuccess,
  onFailure,
}) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL +
        `/auditor/v1/pgd/masterData?templateId=${templateId}&city=${cityName}`
      )
      .then(function (response) {
        dispatch({
          type: types.FETCH_PGD_MASTER_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response.data);
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_PGD_MASTERS_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const fetchPGDAuditAction = (id) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/pgd/getAudit?masterId=" + id)
      .then(function (response) {
        dispatch({
          type: types.FETCH_PGD_AUDIT_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_PGD_AUDIT_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const savePGDAuditAction = ({ audit, onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .post(types.API_URL + "/auditor/v1/pgd/submit", audit)
      .then(function (response) {
        dispatch({
          type: types.SAVE_PGD_AUDIT_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response);
      })
      .catch(function (error) {
        dispatch({
          type: types.SAVE_PGD_AUDIT_FAILURE_ACTION,
          payload: error,
        });
        onFailure(error);
      });
  };
};

export const downloadPGDAuditReportAction = (auditId) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/pgd/pdfReport?auditId=" + auditId, {
        responseType: "blob",
      })
      .then(function (response) {
        FileSaver.saveAs(response.data, "Audit-Report.pdf");
        dispatch({
          type: types.DOWNLOAD_PGD_AUDIT_REPORT_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        console.log(error);
        dispatch({
          type: types.DOWNLOAD_PGD_AUDIT_REPORT_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const downloadPGDReportAction = ({
  data,
  onSuccess,
  onFailure,
}) => {
  return function (dispatch) {
    axios
      .post(
        types.API_URL + "/auditor/v1/pgd/excelReport", data
      )
      .then(function (response) {
        onSuccess();
        // FileSaver.saveAs(response.data, "PGDAuditReport.xlsx");
        dispatch({
          type: types.DOWNLOAD_HERSHEYS_REPORT_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        console.log(error);
        dispatch({
          type: types.DOWNLOAD_HERSHEYS_REPORT_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};
