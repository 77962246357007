import React from "react";
import { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import Table from "../commonTables/Table.jsx";
import { getJandJTemplates } from "../../actions/jandj_actions.js";
import JandJDealersView from "./JandJDealersView.jsx";
import TableView from "../commonViews/TableView.jsx";
import { getTextColor } from "../../ThemeHandlers/theme";

export class JandJTemplatesView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTemplate: {},
      isTemplatesView: true,
      showReportDataNotFound: false,
      jandjTemplates: [],
      color: "",
      loading: false,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    if (this.props.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
    this.props.getJandJTemplates({
      onSuccess: () => {
        this.setState({ loading: false });
      },
      onFailure: () => {
        this.setState({ loading: false });
      },
    });
  }
  handleTableViewAuditClick = (template) => {
    this.setState({ selectedTemplate: template, isTemplatesView: false });
  };
  handleCloseClick = () => {
    console.log("closing in template page");
    // this.props.clearStoreAction();
    this.setState({ isTemplatesView: true });
  };

  componentDidUpdate(prevProps) {
    if (this.props.auth.color !== prevProps.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
    if (this.props.jandj.templates !== prevProps.jandj.templates) {
      this.setState({ jandjTemplates: this.props.jandj.templates });
    }
  }

  render() {
    var templates =
      this.state.jandjTemplates.length !== 0 ? this.state.jandjTemplates : [];

    var col = [
      {
        title: "Template Name",
        field: "",

        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.auditName}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.auditName.toLowerCase().includes(term.toLowerCase()),
      },
    ];

    return (
      <div style={{ flexGrow: 1, display: "flex", flexFlow: "column" }}>
        {this.state.isTemplatesView && (
          <div>
            <h1 style={{ color: getTextColor(this.state.color) }}>Templates</h1>
            <div style={{ display: "flex", flexGrow: 1, flexFlow: "column" }}>
              <div>
                <TableView
                  columns={col}
                  data={templates}
                  selection={false}
                  load={this.state.loading}
                />
              </div>
            </div>
          </div>
        )}
        {!this.state.isTemplatesView && (
          <div style={{ flexGrow: 1, display: "flex" }}>
            <JandJDealersView
              template={this.state.selectedTemplate}
              onClose={this.handleCloseClick}
              color={this.state.color}
            />
          </div>
        )}
      </div>
    );
  }
}

function AuditTableCell(props) {
  function onClick() {
    props.onClick(props.row);
  }
  return (
    <div style={props.style} onClick={onClick}>
      {props.text}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    jandj: state.jandj,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getJandJTemplates,
    },
    dispatch
  );
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(JandJTemplatesView)
);
