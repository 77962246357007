import {
    FETCH_PGC_MASTER_SUCCESS_ACTION,
    FETCH_PGC_AUDITORSDETAILS_SUCCESS_ACTION,
    FETCH_PGC_AUDIT_SUCCESS_ACTION
} from '../actions/types';


const INIT_STATE = {};

export default function (state = INIT_STATE, action) {
    state = Object.assign({}, state, {});

    switch (action.type) {

        case FETCH_PGC_MASTER_SUCCESS_ACTION:
            return Object.assign({}, state, {
                customers: action.payload.masterData,
                metrics: action.payload.counts,
                isFetchUdaanStoresSuccess: true
            });

        case FETCH_PGC_AUDITORSDETAILS_SUCCESS_ACTION:

            state.userDetails = action.payload.data;
            state.fetchUserDetailError = false;
            return state;

        case FETCH_PGC_AUDIT_SUCCESS_ACTION:
            return Object.assign({}, state, {
                audits: action.payload.data,
                isFetchUdaanAuditSuccess: true,
            });



    }
    return state;
}
