import axios from "axios";
import { data } from "jquery";
import * as types from "./types";

export const loginAction = ({ username, password, onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .post(types.API_URL + "/auditor/v1/login", {
        email: username,
        password: password,
      })
      .then(function (response) {
        dispatch({
          type: types.LOGIN_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response);
      })
      .catch(function (error) {
        dispatch({
          type: types.LOGIN_FAILURE_ACTION,
          payload: error,
        });
        onFailure(error);
      });
  };
};
export const changeTheme = (color) => {
  // console.log(color, "actr");
  return function (dispatch) {
    dispatch({
      type: types.CHANGE_COLOR,
      payload: color,
    });
  };
};
export const forgotPassword = ({ email, onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .post(types.API_URL + "/auditor/v1/forgotPassword", email)
      .then(function (response) {
        console.log(response.data, "inside forget pwd");
        // modaltimeout()
        dispatch({
          type: types.FORGOT_PASSWORD_SUCCESS_ACTION,
          payload: response.data,
        });
        if (response.data.message === "Email does not exist") {
          onSuccess(true);
        } else {
          onSuccess(false);
        }
      })
      .catch(function (error) {
        dispatch({
          type: types.FORGOT_PASSWORD_FAILURE_ACTION,
          payload: error,
        });
        onFailure(false);
      });
  };
};

export const logoutAction = () => {
  return {
    type: types.LOGOUT_ACTION,
  };
};

export const ModalCloseAction = () => {
  console.log("closed clicked");
  return {
    type: types.MODAL_CLOSE,
  };
};

export const ModalOpenAction = () => {
  return {
    type: types.MODAL_OPEN,
  };
};

export const modaltimeout = () => {
  console.log("closing");
  setTimeout(() => {
    console.log("closied");
    modalActionforopen(false);
  }, 0);
};

export const modalActionforopen = (data) => {
  console.log(data, "coming");
  return function (dispatch) {
    console.log(data, "coming inside dispatch");
    dispatch({
      type: types.MODAL_OPEN,
      payload: data,
    });
  };
};

export const modalActionforclose = (data) => {
  console.log(data, "coming");
  return function (dispatch) {
    dispatch({
      type: types.MODAL_CLOSE,
      payload: data,
    });
  };
};

export const selectMenuAction = (selectedMenu) => {
  return {
    type: types.SELECT_MENU_ACTION,
    payload: selectedMenu,
  };
};
