import React from 'react';
import ButtonHandler from './ButtonHandler';

const ThemeButton = (props) => {
    return (
        <ButtonHandler
            isIcon={true}
            size={"mini"}
            color={"#FFFFFF"}
            click={props.changeColor}
            iconName={"circle"}
            iconSize={"large"}
            iconColor={props.color}
        />
    )
}
export default ThemeButton