import axios from "axios";
import * as FileSaver from "file-saver";
import * as types from "./types";

export const fetchHersheysCompetitionTemplatesAction = ({ onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v2/hersheysCompetition/getTemplates")
      .then(function (response) {
        dispatch({
          type: types.FETCH_HERSHEYS_COMPETITION_TEMPLATE_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_HERSHEYS_COMPETITION_TEMPLATE_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const fetchHersheysCompetitionStoresAction = ({ id, onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL + "/auditor/v2/hersheysCompetition/getMasterData?templateId=" + id
      )
      .then(function (response) {
        dispatch({
          type: types.FETCH_HERSHEYS_COMPETITION_STORE_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_HERSHEYS_COMPETITION_STORE_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const fetchAuditorsDetailAction = () => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v2/hersheysCompetition/auditors")
      .then(function (response) {
        dispatch({
          type: types.FETCH_AUDITORS_DETAILS_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_AUDITORS_DETAILS_FAILIURE_ACTION,
          payload: error,
        });
      });
  };
};


export const AssignAuditsAction = ({ assignData, onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .post(
        types.API_URL + "/auditor/v2/hersheysCompetition/multiAssignAudits",
        assignData
      )
      .then(function (response) {
        dispatch({
          type: types.ASSIGN_HERSHEYS_COMPETITION_AUDITS_DETAILS_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.ASSIGN_HERSHEYS_COMPETITION_AUDITS_FAILIURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};


export const fetchProductsDetailAction = ({ masterId, onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + `/auditor/v2/hersheysCompetition/web/getProducts?masterId=${masterId}`)
      .then(function (response) {
        dispatch({
          type: types.FETCH_PRODUCTSDETAILS_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response.data)
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_PRODUCTSDETAILS_FAILIURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const fetchHersheysCompetitionAuditAction = ({ id, onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v2/hersheysCompetition/web/getAudits?auditId=" + id)
      .then(function (response) {
        dispatch({
          type: types.FETCH_HERSHEYS_COMPETITION_AUDIT_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response.data);
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_HERSHEYS_COMPETITION_AUDIT_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const saveHersheysCompetitionAuditAction = (audit) => {
  return function (dispatch) {
    axios
      .post(types.API_URL + "/auditor/v2/hersheysCompetition/web/saveAudit", audit)
      .then(function (response) {
        dispatch({
          type: types.SAVE_HERSHEYS_COMPETITION_AUDIT_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.SAVE_HERSHEYS_COMPETITION_AUDIT_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};


export const downloadHersheysCompetitionReportAction = ({
  masterId,
  outletCode,
  outletName,
  onSuccess,
  onFailure,
}) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL + "/auditor/v1/hersheysCompetition/report?masterId=" + masterId,
        {
          responseType: "blob",
        }
      )
      .then(function (response) {
        onSuccess();
        FileSaver.saveAs(response.data, `${outletCode}-${outletName}.xlsx`);
        dispatch({
          type: types.DOWNLOAD_HERSHEYS_COMPETITION_REPORT_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        console.log(error);
        dispatch({
          type: types.DOWNLOAD_HERSHEYS_COMPETITION_REPORT_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};


export const downloadHersheysVisibilityAuditReportAction = (auditId) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL + "/auditor/v1/hersheysCompetition/pdfReport?auditId=" + auditId,
        {
          responseType: "blob",
        }
      )
      .then(function (response) {
        FileSaver.saveAs(response.data, "Audit-Report.pdf");
        dispatch({
          type: types.DOWNLOAD_HERSHEYS_AUDIT_REPORT_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        console.log(error);
        dispatch({
          type: types.DOWNLOAD_HERSHEYS_AUDIT_REPORT_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};







