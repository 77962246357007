import axios from "axios";
import * as types from "./types";

export const UploadUserCsv = (url, file) => {
  var formData = new FormData();
  formData.append("file", file, file.name);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return function (dispatch) {
    axios
      .post(types.API_URL + url, formData, config)
      .then(function (response) {
        dispatch({
          type: types.UPLOAD_DAIMLER_USER_CSV_SUCCESS,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.UPLOAD_DAIMLER_USER_CSV_FAILURE,
          payload: error,
        });
      });
  };
};

export const UploadDealerCsv = (dealerUrl, file) => {
  var formData = new FormData();
  formData.append("file", file, file.name);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return function (dispatch) {
    axios
      .post(types.API_URL + dealerUrl, formData, config)
      .then(function (response) {
        dispatch({
          type: types.UPLOAD_DAIMLER_DEALER_CSV_SUCCESS,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.UPLOAD_DAIMLER_DEALER_CSV_FAILURE,
          payload: error,
        });
      });
  };
};

export const daimlerUploadVehicleCsv = (url, file) => {
  var formData = new FormData();
  formData.append("file", file, file.name);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return function (dispatch) {
    axios
      .post(types.API_URL + url, formData, config)
      .then(function (response) {
        dispatch({
          type: types.UPLOAD_DAIMLER_VEHICLE_CSV_SUCCESS,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.UPLOAD_DAIMLER_VEHICLE_CSV_FAILURE,
          payload: error,
        });
      });
  };
};

export const initiateUpload = (value) => {
  return function (dispatch) {
    dispatch({
      type: types.INITIATE_UPLOAD,
      payload: value,
    });
  };
};

export const dismissLogs = (value) => {
  return function (dispatch) {
    dispatch({
      type: types.DISMISS_LOG,
      payload: value,
    });
  };
};

// Volkswagen Stuff
export const volkswagenUploadUserCsv = (file) => {
  var formData = new FormData();
  formData.append("file", file, file.name);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return function (dispatch) {
    axios
      .post(types.API_URL + "/admin/v1/volkswagen/addUsers", formData, config)
      .then(function (response) {
        dispatch({
          type: types.UPLOAD_VOLKSWAGEN_USER_CSV_SUCCESS,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.UPLOAD_VOLKSWAGEN_USER_CSV_FAILURE,
          payload: error,
        });
      });
  };
};

export const volkswagenUploadDealerCsv = (file) => {
  var formData = new FormData();
  formData.append("file", file, file.name);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return function (dispatch) {
    axios
      .post(types.API_URL + "/admin/v1/volkswagen/addDealers", formData, config)
      .then(function (response) {
        dispatch({
          type: types.UPLOAD_VOLKSWAGEN_DEALER_CSV_SUCCESS,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.UPLOAD_VOLKSWAGEN_DEALER_CSV_FAILURE,
          payload: error,
        });
      });
  };
};

export const volkswagenUploadVehicleCsv = (file) => {
  var formData = new FormData();
  formData.append("file", file, file.name);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return function (dispatch) {
    axios
      .post(
        types.API_URL + "/admin/v1/volkswagen/addVehicles",
        formData,
        config
      )
      .then(function (response) {
        dispatch({
          type: types.UPLOAD_VOLKSWAGEN_VEHICLE_CSV_SUCCESS,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.UPLOAD_VOLKSWAGEN_VEHICLE_CSV_FAILURE,
          payload: error,
        });
      });
  };
};

// P and G stuff

export const pandgDistributorUploadCsv = (file) => {
  var formData = new FormData();
  formData.append("file", file, file.name);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return function (dispatch) {
    axios
      .post(types.API_URL + "/admin/v1/pandg/addDistributor", formData, config)
      .then(function (response) {
        dispatch({
          type: types.UPLOAD_DISTRIBUTOR_CSV_SUCCESS,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.UPLOAD_DISTRIBUTOR_CSV_FAILURE,
          payload: error,
        });
      });
  };
};

export const pandgFunctionalManagerUploadCsv = (file) => {
  var formData = new FormData();
  formData.append("file", file, file.name);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return function (dispatch) {
    axios
      .post(
        types.API_URL + "/admin/v1/pandg/addFunctionalManagers",
        formData,
        config
      )
      .then(function (response) {
        dispatch({
          type: types.UPLOAD_FUNCTIONALMANAGER_CSV_SUCCESS,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.UPLOAD_FUNCTIONALMANAGER_CSV_FAILURE,
          payload: error,
        });
      });
  };
};

export const pandgAgencyUploadCsv = (file) => {
  var formData = new FormData();
  formData.append("file", file, file.name);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return function (dispatch) {
    axios
      .post(types.API_URL + "/admin/v1/pandg/addAgencies", formData, config)
      .then(function (response) {
        dispatch({
          type: types.UPLOAD_AGENCY_CSV_SUCCESS,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.UPLOAD_AGENCY_CSV_FAILURE,
          payload: error,
        });
      });
  };
};

export const pandgLocationUploadCsv = (file) => {
  var formData = new FormData();
  formData.append("file", file, file.name);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return function (dispatch) {
    axios
      .post(types.API_URL + "/admin/v1/pandg/addLocations", formData, config)
      .then(function (response) {
        dispatch({
          type: types.UPLOAD_LOCATION_CSV_SUCCESS,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.UPLOAD_LOCATION_CSV_FAILURE,
          payload: error,
        });
      });
  };
};

//  Preethi Actions

// Godrej
export const godrejAuditUpload = (file) => {
  var formData = new FormData();
  formData.append("file", file, file.name);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return function (dispatch) {
    axios
      .post(types.API_URL + "/admin/v1/godrej/audits", formData, config)
      .then(function (response) {
        dispatch({
          type: types.UPLOAD_GODREJ_AUDIT_SUCCESS,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.UPLOAD_GODREJ_AUDIT_FAILURE,
          payload: error,
        });
      });
  };
};

//Sales Fundamentals

export const salesUploadUserCsv = (file) => {
  var formData = new FormData();
  formData.append("file", file, file.name);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return function (dispatch) {
    axios
      .post(
        types.API_URL + "/admin/v1/salesfundamentals/addUsers",
        formData,
        config
      )
      .then(function (response) {
        dispatch({
          type: types.UPLOAD_SALES_USER_CSV_SUCCESS,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.UPLOAD_SALES_USER_CSV_FAILURE,
          payload: error,
        });
      });
  };
};

export const salesUploadMasterCsv = (file) => {
  var formData = new FormData();
  formData.append("file", file, file.name);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return function (dispatch) {
    axios
      .post(
        types.API_URL + "/admin/v1/salesfundamentals/addMasterdata",
        formData,
        config
      )
      .then(function (response) {
        dispatch({
          type: types.UPLOAD_SALES_MASTER_CSV_SUCCESS,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.UPLOAD_SALES_MASTER_CSV_FAILURE,
          payload: error,
        });
      });
  };
};

export const salesUploaDCountryCsv = (file) => {
  var formData = new FormData();
  formData.append("file", file, file.name);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return function (dispatch) {
    axios
      .post(
        types.API_URL + "/admin/v1/salesfundamentals/addCountry",
        formData,
        config
      )
      .then(function (response) {
        dispatch({
          type: types.UPLOAD_SALES_COUNTRY_CSV_SUCCESS,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.UPLOAD_SALES_COUNTRY_CSV_FAILURE,
          payload: error,
        });
      });
  };
};

// furlenco

export const furlencoUploadMasterCsv = (file) => {
  var formData = new FormData();
  formData.append("file", file, file.name);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return function (dispatch) {
    axios
      .post(types.API_URL + "/admin/v1/furlenco/addDealers", formData, config)
      .then(function (response) {
        dispatch({
          type: types.UPLOAD_FURLENCO_CUSTOMER_CSV_SUCCESS,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.UPLOAD_FURLENCO_CUSTOMER_CSV_FAILURE,
          payload: error,
        });
      });
  };
};

//atm
export const atmMasterCV = (file) => {
  console.log(file, "...file...");
  var formData = new FormData();
  formData.append("file", file);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return function (dispatch) {
    axios
      .post(types.API_URL + "/admin/v1/atm/addMasterData", formData, config)
      .then(function (response) {
        console.log(response.data, "atm details");
        dispatch({
          type: types.UPLOAD_ATM_MASTER_CSV_SUCCESS,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.UPLOAD_ATM_MASTER_CSV_FAILURE,
          payload: error,
        });
      });
  };
};

//cannon
export const cannonMasterCV = (file) => {
  console.log(file, "...file...");
  var formData = new FormData();
  formData.append("file", file);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return function (dispatch) {
    axios
      .post(types.API_URL + "/admin/v1/canon/addMasterData", formData, config)
      .then(function (response) {
        console.log(response.data, "cannon details");
        dispatch({
          type: types.UPLOAD_CANNON_MASTER_CSV_SUCCESS,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.UPLOAD_CANNON_MASTER_CSV_FAILURE,
          payload: error,
        });
      });
  };
};

//mrretail
export const mrretailMasterCV = (file) => {
  console.log(file, "...file...");
  var formData = new FormData();
  formData.append("file", file);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return function (dispatch) {
    axios
      .post(
        types.API_URL + "/admin/v1/retailAudit/addMasterData",
        formData,
        config
      )
      .then(function (response) {
        console.log(response.data, "mrretail details");
        dispatch({
          type: types.UPLOAD_MRRETAIL_MASTER_CSV_SUCCESS,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.UPLOAD_MRRETAIL_MASTER_CSV_FAILURE,
          payload: error,
        });
      });
  };
};

//jandj
export const jandjDealersCV = (file) => {
  console.log(file, "...file...");
  var formData = new FormData();
  formData.append("file", file);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return function (dispatch) {
    axios
      .post(types.API_URL + "/admin/v1/JandJ/addDealers", formData, config)
      .then(function (response) {
        console.log(response.data, "jandj customer details");
        dispatch({
          type: types.UPLOAD_JANDJ_CUSTOMER_CSV_SUCCESS,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.UPLOAD_JANDJ_CUSTOMER_CSV_FAILURE,
          payload: error,
        });
      });
  };
};

export const jandjMasterCV = (file) => {
  console.log(file, "...file...");
  var formData = new FormData();
  formData.append("file", file);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return function (dispatch) {
    axios
      .post(
        types.API_URL + "/admin/v1/daimler/addJandJ_master",
        formData,
        config
      )
      .then(function (response) {
        console.log(response.data, "jandj master details");
        dispatch({
          type: types.UPLOAD_JANDJ_MASTER_CSV_SUCCESS,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.UPLOAD_JANDJ_MASTER_CSV_FAILURE,
          payload: error,
        });
      });
  };
};

//Grb
export const grbMasterCV = (file) => {
  console.log(file, "...file...");
  var formData = new FormData();
  formData.append("file", file);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return function (dispatch) {
    axios
      .post(types.API_URL + "/admin/v1/grb/addMaster", formData, config)
      .then(function (response) {
        console.log(response.data, "atm details");
        dispatch({
          type: types.UPLOAD_GRB_MASTER_CSV_SUCCESS,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.UPLOAD_GRB_MASTER_CSV_FAILURE,
          payload: error,
        });
      });
  };
};

//Udaan
export const udaanMasterCV = (file) => {
  console.log(file, "...file...");
  var formData = new FormData();
  formData.append("file", file);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return function (dispatch) {
    axios
      .post(types.API_URL + "/admin/v1/udaan/addMasterData", formData, config)
      .then(function (response) {
        console.log(response.data, "atm details");
        dispatch({
          type: types.UPLOAD_UDAAN_MASTER_CSV_SUCCESS,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.UPLOAD_UDAAN_MASTER_CSV_FAILURE,
          payload: error,
        });
      });
  };
};

//Hersheys
export const hersheysMasterCV = (file) => {
  console.log(file, "...file...");
  var formData = new FormData();
  formData.append("file", file);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return function (dispatch) {
    axios
      .post(
        types.API_URL + "/admin/v1/hersheys/addMasterData",
        formData,
        config
      )
      .then(function (response) {
        console.log(response.data, "atm details");
        dispatch({
          type: types.UPLOAD_HERSHEYS_MASTER_CSV_SUCCESS,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.UPLOAD_HERSHEYS_MASTER_CSV_FAILURE,
          payload: error,
        });
      });
  };
};

//TimeSheets
export const timesheetsMasterCV = (file, orgId, { onSuccess, onFailure }) => {
  console.log(file, orgId, "...file...");
  var formData = new FormData();
  formData.append("file", file);
  return function (dispatch) {
    axios
      .post(
        types.API_URL + `/admin/v1/timesheet/uploadMasterData?orgId=${orgId}`,
        formData
      )
      .then(function (response) {
        console.log(response.data, "timesheets details");
        dispatch({
          type: types.UPLOAD_TIMESHEET_MASTER_CSV_SUCCESS,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.UPLOAD_TIMESHEET_MASTER_CSV_FAILURE,
          payload: error,
        });
        onFailure();
      });
  };
};

//ADDTIMESHEET
export const addtimesheetsMasterCV = ({ data, onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .post(types.API_URL + `/admin/v1/timesheet/addMasterData`, data)
      .then(function (response) {
        console.log(response.data, "add timesheets details");
        dispatch({
          type: types.ADD_TIMESHEET_MASTER_CSV_SUCCESS,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.ADD_TIMESHEET_MASTER_CSV_FAILURE,
          payload: error,
        });
        onFailure(error);
      });
  };
};

//fetchtimesheetdata
export const fetchtimesheetData = ({ onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/timesheet/getMasterData")
      .then(function (response) {
        console.log(response.data, "fetched datas");
        dispatch({
          type: types.FETCH_TIMESHEET_DATA_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response.data.master);
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_TIMESHEET_DATA_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

//edittimesheet
export const edittimesheetsMasterCV = ({ data, onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .put(types.API_URL + `/admin/v1/timesheet/editMasterData`, data)
      .then(function (response) {
        console.log(response.data, "add timesheets details");
        dispatch({
          type: types.EDIT_TIMESHEET_MASTER_CSV_SUCCESS,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.EDIT_TIMESHEET_MASTER_CSV_FAILURE,
          payload: error,
        });
        onFailure();
      });
  };
};

//deletetimesheet
export const deletetimesheetsMasterCV = (_id, { onSuccess, onFailure }) => {
  console.log(_id, "...file...");

  return function (dispatch) {
    axios
      .post(
        types.API_URL + `/admin/v1/timesheet/deleteMasterData?masterId=${_id}`
      )
      .then(function (response) {
        console.log(response.data, "add timesheets details");
        dispatch({
          type: types.DELETE_TIMESHEET_MASTER_CSV_SUCCESS,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.DELETE_TIMESHEET_MASTER_CSV_FAILURE,
          payload: error,
        });
        onFailure();
      });
  };
};
