import { combineReducers } from "redux";
import auditReducer from "./audit_reducer";
import authReducer from "./auth_reducer";
import homeReducer from "./home_reducer";
import pandgReducer from "./pandg_reducer";
import damagesReducer from "./damages_reducer";
import controlPanelReducer from "./control_panel_reducer";
import preethiReducer from "./preethi_reducer";
import godrejReducer from "./godrej_reducer";
import YamahaReducer from "./yamaha_reducer";
import DiamlerKeyReducer from "./Diamler_Key_reducer";
import userDetailReducer from "./user_detail_reducer";
import SalesReducer from "./sales_reducers";
import FurlencoReducer from "./furlenco_reducers";
import JandJReducer from "./jandj_reducer";
import naturalsReducer from "./natural_reducer";
import udaanReducer from "./udaan_reducer";
import hersheysReducer from "./hersheys_reducer";
import mrRetailReducer from "./mr_retail_reducer";
import cannonReducer from "./cannon_reducer";
import atmReducer from "./bank_reducer";
import resetReducer from "./reset_reducer";
import timesheet_reducer from "./timesheet_reducer";
import HershysCheck from "./hershysCheck_reducer";
import dtc from "./dtc_reducer";
import bikano from "./bikano_reducer";
import pgd from "./pgd_reducers";
import pgc_reducer from "./pgc_reducer";
import hersheysComp_reducer from "./hersheysComp_reducer";

const rootReducer = combineReducers({
  reset: resetReducer,
  auth: authReducer,
  audits: auditReducer,
  home: homeReducer,
  pandg: pandgReducer,
  damages: damagesReducer,
  controlPanel: controlPanelReducer,
  preethi: preethiReducer,
  godrej: godrejReducer,
  yamaha: YamahaReducer,
  user: userDetailReducer,
  DiamlerKey: DiamlerKeyReducer,
  Sales: SalesReducer,
  Furlenco: FurlencoReducer,
  jandj: JandJReducer,
  naturals: naturalsReducer,
  udaan: udaanReducer,
  hersheys: hersheysReducer,
  mrRetail: mrRetailReducer,
  cannon: cannonReducer,
  atm: atmReducer,
  timesheet: timesheet_reducer,
  hershysCheck: HershysCheck,
  dtc: dtc,
  bikano: bikano,
  pgd: pgd,
  pgc: pgc_reducer,
  hersheysCompetition: hersheysComp_reducer
});

export default rootReducer;
