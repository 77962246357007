import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn, isClient } from "../../util";
import DropDownPicker from "../commonViews/DropDownPicker";
import {
  fetchTemplatesAction,
  clearDealersAction,
  fetchVolkswagenClientTemplatesAction,
  clearVolkswagenDealersAction,
} from "../../actions/vehicle_verification_actions";

import VolkswagenAudits from "./VolkswagenAudits.jsx";
import TableView from "../commonViews/TableView";
import { getTextColor } from "../../ThemeHandlers/theme";

class VolkswagenAuditsByMonth extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedTemplate: "",
      isTemplatesView: true,
      year: new Date().getFullYear(),
      templates: [],
      color: "",
    };
    this.activeConsolidatedTemplateId = null;
  }

  componentDidMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
      return;
    }
    if (this.props.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
    this.props.clearPreviousData();

    if (this.isClientProfile()) this.props.fetchVolkswagenClientTemplates();
    else {
      this.props.fetchTemplates("Volkswagen", this.state.year);
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.auth.color !== prevProps.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
    if (this.props.templates !== prevProps.templates) {
      this.setState({ templates: this.props.templates });
    }
  }

  searchFilter = (filter, row) => {
    var flatData = JSON.stringify(row._original);
    return flatData.toLowerCase().includes(filter.value.toLowerCase());
  };

  isClientProfile = () => {
    return isClient(this.props.auth);
  };

  handleCloseClick = () => {
    this.setState({ isTemplatesView: true });
    this.props.clearVolkswagenDealers();
  };

  handleTableViewAuditClick = (template) => {
    this.props.clearVolkswagenDealers();
    if (!template._id) return;

    this.setState({ selectedTemplate: template, isTemplatesView: false });
  };

  onTemplateYearChange = (data) => {
    this.props.fetchTemplates("Volkswagen", data);
    this.setState({ year: data });
  };

  render() {
    var templates =
      this.state.templates.length !== 0 ? this.state.templates : [];
    var years = [
      {
        value: 2017,
        text: "2017",
        key: "2017",
      },
      {
        value: 2018,
        text: "2018",
        key: "2018",
      },
      {
        value: 2019,
        text: "2019",
        key: "2019",
      },
      {
        value: 2020,
        text: "2020",
        key: "2020",
      },
      {
        value: 2021,
        text: "2021",
        key: "2021",
      },
      {
        value: 2022,
        text: "2022",
        key: "2022",
      },
      {
        value: 2023,
        text: "2023",
        key: "2023",
      },
    ];

    var col = [
      {
        title: "Template Name",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.auditName}
            </span>
          );
        },
        searchable: true,
        customFilterAndSearch: (term, rowData) =>
          rowData.auditName.toLowerCase().includes(term.toLowerCase()),
      },
      {
        title: "Dealers Count",
        field: "",

        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.dealerCount}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) => term == rowData.dealerCount,
      },
      {
        title: "Vehicles Count",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.vehicleCount}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) => term == rowData.vehicleCount,
      },
    ];

    return (
      <div style={{ flexGrow: 1, display: "flex", flexFlow: "column" }}>
        {this.state.isTemplatesView && (
          <div>
            <div
              style={{
                display: "inline-block",
                marginBottom: 20,
              }}
            >
              <h1
                style={{
                  display: "inline-block",
                  color: getTextColor(this.state.color),
                }}
              >
                Templates
              </h1>
              <DropDownPicker styles={{
                width: "20%",
                zIndex: "100",
                width: 300
              }}
                placeholder={"Select Year"}
                value={this.state.year}
                options={years}
                onChangeDropDown={(data) => { this.onTemplateYearChange(data) }}

              />

            </div>
            <div style={{ display: "flex", flexGrow: 1, flexFlow: "column" }}>
              <div>
                <TableView selection={false} data={templates} columns={col} />
              </div>
            </div>
          </div>
        )}
        {!this.state.isTemplatesView && (
          <div style={{ flexGrow: 1, display: "flex" }}>
            <VolkswagenAudits
              template={this.state.selectedTemplate}
              onClose={this.handleCloseClick}
              color={this.state.color}
            />
          </div>
        )}
      </div>
    );
  }
}

function AuditTableCell(props) {
  function onClick() {
    props.onClick(props.row);
  }
  return (
    <div style={props.style} onClick={onClick}>
      {props.text}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    templates: state.audits.templates,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(
    {
      //fetchTemplates: ownProps.apis.fetchTemplatesAction,
      fetchTemplates: fetchTemplatesAction,
      clearPreviousData: clearDealersAction,
      fetchVolkswagenClientTemplates: fetchVolkswagenClientTemplatesAction,
      clearVolkswagenDealers: clearVolkswagenDealersAction,
    },
    dispatch
  );
};

// VolkswagenAuditsByMonth.PropTypes = {
//     dealers: PropTypes.array
// };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VolkswagenAuditsByMonth)
);
