import axios from "axios";
import * as types from "./types";
import * as FileSaver from "file-saver";

export const fetchtimesheetMasterData = ({ onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/timesheet/web/getMasterData")
      .then(function (response) {
        console.log(response.data, "master data");
        dispatch({
          type: types.FETCH_MASTER_TIMESHEET_DATA_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response.data);
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_MASTER_TIMESHEET_DATA_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const filterMasterDataAction = () => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/timesheet/web/filterMasterData")
      .then(function (response) {
        console.log(response.data, "filter master data");
        dispatch({
          type: types.FETCH_FILTERED_MASTER_TIMESHEET_DATA_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_FILTERED_MASTER_TIMESHEET_DATA_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const fetchtimesheetControlPanlelMaster = ({ onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/timesheet/controlPanel/getMaster")
      .then(function (response) {
        console.log(response.data, "master data");
        dispatch({
          type: types.FETCH_CONTROL_PANEL_MASTER_TIMESHEET_DATA_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response.data);
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_CONTROL_PANEL_MASTER_TIMESHEET_DATA_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const fetchDateAction = ({ forms, onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .post(types.API_URL + `/auditor/v1/timesheet/web/filterMasterData`, forms)
      .then(function (response) {
        console.log(response.data, "date details");
        dispatch({
          type: types.FETCH_DATE_TIMESHEET_DATA_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response.data);
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_DATE_TIMESHEET_DATA_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const saveTimesheetData = (data, { onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .post(types.API_URL + `/auditor/v1/timesheet/web/editTimesheetData`, data)
      .then(function (response) {
        console.log(response.data, "date details");
        dispatch({
          type: types.SAVE_TIMESHEET_DATA_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.SAVE_TIMESHEET_DATA_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

//reporttimesheet
export const downloadtimesheetreportAction = () => {
  return function (dispatch) {
    axios
      .get(types.API_URL + `/auditor/v1/timesheet/exportData`, {
        responseType: "blob",
      })
      .then(function (response) {
        FileSaver.saveAs(response.data, `timesheetReport.xlsx`);
        dispatch({
          type: types.REPORT_TIMESHEET_DATA_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        console.log(error);
        dispatch({
          type: types.REPORT_TIMESHEET_DATA_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const exportMasterDataAction = () => {
  return function (dispatch) {
    axios
      .get(types.API_URL + `/auditor/v1/timesheet/exportMasterData`, {
        responseType: "blob",
      })
      .then(function (response) {
        FileSaver.saveAs(response.data, `timesheetMasterData.xlsx`);
        dispatch({
          type: types.EXPORT_TIMESHEET_MASTER_DATA_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        console.log(error);
        dispatch({
          type: types.EXPORT_TIMESHEET_MASTER_DATA_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const saveTimeSheetRow = ({ data, onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .post(types.API_URL + `/auditor/v1/timesheet/web/saveAudit`, data)
      .then(function (response) {
        console.log(response.data, "date details");
        dispatch({
          type: types.SAVE_TIMESHEET_DATA_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response.data);
      })
      .catch(function (error) {
        dispatch({
          type: types.SAVE_TIMESHEET_DATA_FAILURE_ACTION,
          payload: error,
        });
        onFailure(error);
      });
  };
};

export const timesheetTemplateFilter = ({ data, onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .post(types.API_URL + `/auditor/v1/timesheet/web/filterMasterData`, data)
      .then(function (response) {
        dispatch({
          type: types.FETCH_FILTERED_MASTER_TIMESHEET_DATA_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response.data);
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_FILTERED_MASTER_TIMESHEET_DATA_FAILURE_ACTION,
          payload: error,
        });
        onFailure(error);
      });
  };
};


export const deleteTimesheetMaster = (masterId, { onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .post(types.API_URL + `/admin/v1/timesheet/deleteMasterData?masterId=${masterId}`)
      .then(function (response) {
        dispatch({
          type: types.DELETE_TIMESHEET_DATA_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response.data);
      })
      .catch(function (error) {
        dispatch({
          type: types.DELETE_TIMESHEET_DATA_FAILURE_ACTION,
          payload: error,
        });
        onFailure(error);
      });
  };
};