import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn } from "../../util";

import IconPicker from "../commonViews/IconPicker";

import DamagesAuditsDetail from "./DamagesAuditsDetail.jsx";
import {
  downloadTrackerAuditsReportAction,
  downloadDamagesAuditReportAction,
} from "../../actions/damages_actions.js";
// import ReactTable from "react-table";
import Table from "../commonTables/Table.jsx";
import TableView from "../commonViews/TableView";
import config from "../../config.js";
import { getTextColor } from "../../ThemeHandlers/theme";
import Metrics from "../commonViews/Metrics";

const storageBaseUrl = config["storage_base_url"];
class DamagesAudits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAudit: null,
      damagesisAuditView: false,
      isAuditReportDownloading: false,
      damagesAudit: [],
      loading: false,
      metrics: {
        TOTAL_MASTER_DATA: this.props.selectedTemplate.stats.totalMaster || 0,
        AUDITS_PLANNED: this.props.selectedTemplate.stats.totalAudits || 0,
        LIVE: this.props.selectedTemplate.stats.draftAudits || 0,
        AWPOINT: this.props.selectedTemplate.stats.aw || 0,
        CLPOINT: this.props.selectedTemplate.stats.cl || 0,
        COMPLETED: this.props.selectedTemplate.stats.submittedAudits || 0,
        PENDING: this.props.selectedTemplate.stats.pendingAudits || 0
      }
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ isAuditReportDownloading: false });
  }

  componentDidUpdate(prevProps) {
    if (this.props.damages.damagesAudit !== prevProps.damages.damagesAudit) {
      this.setState({
        damagesAudit: this.props.damages.damagesAudit,
      });
    }
  }

  componentDidMount() {
    this.checkdamagesNotLoggedin();
    if (this.props.selectedTemplate) {
      this.setState({ loading: true });
      this.props.fetchTemplateAudit({
        templateId: this.props.selectedTemplate._id,
        onSuccess: () => {
          this.setState({ loading: false });
        },
        onFailure: () => {
          this.setState({ loading: false });
        },
      });
    }
  }

  checkdamagesNotLoggedin = () => {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
    }
  };

  AuditsearchFilter = (filter, row) => {
    var flatData = JSON.stringify(row._original).replace("draft", "live");
    return flatData.toLowerCase().includes(filter.value.toLowerCase());
  };

  AudithandleTableViewAuditClick = (audit) => {
    this.setState({ selectedAudit: audit, damagesisAuditView: true });
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
      return false;
    }
    return true;
  }

  AudithandleDownloadAuditReportClick = (audit) => {
    if (audit._id && audit.templateId) {
      this.setState({ isAuditReportDownloading: true });
      this.props.downloadDamagesAuditReportAction(audit._id, audit.templateId);
    }
  };

  downloadTrackerReport = (audit) => {
    if (audit._id && audit.templateId) {
      this.setState({ isAuditReportDownloading: true });
      this.props.downloadTrackerAuditsReportAction(audit.masterId);
    }
  };

  AudithandleAuditViewClose = () => {
    this.setState({ selectedAudit: null, damagesisAuditView: false });
  };

  render() {
    console.log(this.state.damagesAudit, "data");
    var damagesAudit =
      this.state.damagesAudit.length !== 0 ? this.state.damagesAudit : [];
    var selectedAudit = this.state.selectedAudit;

    if (
      damagesAudit instanceof Array &&
      damagesAudit.length > 0 &&
      selectedAudit === null
    ) {
      selectedAudit = this.props.damages.damagesAudit[0];
    }



    var grbCol = [
      {
        title: "Distributor Name",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.AudithandleTableViewAuditClick(rowData);
              }}
            >
              {rowData.masterInfo.distributorName}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.masterInfo.distributorName
            .toLowerCase()
            .includes(term.toLowerCase()),
      },
      {
        title: "Distributor Code",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.AudithandleTableViewAuditClick(rowData);
              }}
            >
              {rowData.masterInfo.distributorCode}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.masterInfo.distributorCode
            .toLowerCase()
            .includes(term.toLowerCase()),
      },
      {
        title: "Status",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.AudithandleTableViewAuditClick(rowData);
              }}
            >
              {rowData.status === "draft" ? "live" : rowData.status}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) => {
          if (term.includes('live')) {
            return rowData.status.toLowerCase().includes('draft')

          } else {
            return rowData.status.toLowerCase().includes(term.toLowerCase())
          }
        }
      },
      {
        title: "Report",
        field: "",
        render: (rowData) => {
          if (rowData._id) {
            return (
              <IconPicker
                size={"large"}
                color={"red"}
                name={"file pdf outline"}
                click={() =>
                  this.AudithandleDownloadAuditReportClick(rowData)
                }
              />
            );
          }
        },
      },
    ];

    if (this.props.auth.role !== 'viewer') {
      var damagesCol = [
        {
          title: "AW Name",
          field: "",
          render: (rowData) => {
            return (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.AudithandleTableViewAuditClick(rowData);
                }}
              >
                {rowData.masterInfo.awName}
              </span>
            );
          },
          customFilterAndSearch: (term, rowData) =>
            rowData.masterInfo.awName.toLowerCase().includes(term.toLowerCase()),
        },
        {
          title: "AW Code",
          field: "",
          render: (rowData) => {
            return (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.AudithandleTableViewAuditClick(rowData);
                }}
              >
                {rowData.masterInfo.awCode}
              </span>
            );
          },
          customFilterAndSearch: (term, rowData) =>
            rowData.masterInfo.awCode.toLowerCase().includes(term.toLowerCase()),
        },
        {
          title: "AW/CL",
          field: "",
          render: (rowData) => {
            return (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.AudithandleTableViewAuditClick(rowData);
                }}
              >
                {rowData.masterInfo.awOrcentral}
              </span>
            );
          },
          customFilterAndSearch: (term, rowData) =>
            rowData.masterInfo.awOrcentral
              .toLowerCase()
              .includes(term.toLowerCase()),
        },
        {
          title: "Status",
          field: "",
          render: (rowData) => {
            return (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.AudithandleTableViewAuditClick(rowData);
                }}
              >
                {rowData.status === "draft" ? "live" : rowData.status}
              </span>
            );
          },
          customFilterAndSearch: (term, rowData) => {
            if (term.includes('live')) {
              return rowData.status.toLowerCase().includes('draft')

            } else {
              return rowData.status.toLowerCase().includes(term.toLowerCase())
            }
          }


        },
        {
          title: "Report",
          field: "",
          render: (rowData) => {
            if (rowData._id) {
              return (
                <IconPicker
                  size={"large"}
                  color={"red"}
                  name={"file pdf outline"}
                  styles={{ cursor: "pointer" }}
                  click={() =>
                    this.AudithandleDownloadAuditReportClick(rowData)
                  }
                />
              );
            }
          },
        },

      ];
    }
    else {
      var damagesCol = [
        {
          title: "AW Name",
          field: "",
          render: (rowData) => {
            return (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.AudithandleTableViewAuditClick(rowData);
                }}
              >
                {rowData.masterInfo.awName}
              </span>
            );
          },
          customFilterAndSearch: (term, rowData) =>
            rowData.masterInfo.awName.toLowerCase().includes(term.toLowerCase()),
        },
        {
          title: "AW Code",
          field: "",
          render: (rowData) => {
            return (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.AudithandleTableViewAuditClick(rowData);
                }}
              >
                {rowData.masterInfo.awCode}
              </span>
            );
          },
          customFilterAndSearch: (term, rowData) =>
            rowData.masterInfo.awCode.toLowerCase().includes(term.toLowerCase()),
        },
        {
          title: "AW/CL",
          field: "",
          render: (rowData) => {
            return (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.AudithandleTableViewAuditClick(rowData);
                }}
              >
                {rowData.masterInfo.awOrcentral}
              </span>
            );
          },
          customFilterAndSearch: (term, rowData) =>
            rowData.masterInfo.awOrcentral
              .toLowerCase()
              .includes(term.toLowerCase()),
        },
        {
          title: "Status",
          field: "",
          render: (rowData) => {
            return (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.AudithandleTableViewAuditClick(rowData);
                }}
              >
                {rowData.status === "draft" ? "live" : rowData.status}
              </span>
            );
          },
          customFilterAndSearch: (term, rowData) => {
            if (term.includes('live')) {
              return rowData.status.toLowerCase().includes('draft')

            } else {
              return rowData.status.toLowerCase().includes(term.toLowerCase())
            }
          }


        }
      ];
    }

    return (
      <div>
        {damagesAudit && !this.state.damagesisAuditView && (
          <div>
            <h1 style={{ color: getTextColor(this.props.color) }}>
              {this.props.selectedTemplate.auditName}
            </h1>

            <IconPicker
              styles={{
                color: "#808080",
                float: "right",
                cursor: "pointer",
                marginTop: 10,
                marginRight: 30,
              }}
              click={this.props.onClose}
              size={"large"}
              name={"arrow left"}
            />

            <div style={{ marginBottom: 10 }}>
              {Object.keys(this.state.metrics).length !== 0 &&
                <Metrics metrics={this.state.metrics} color={this.props.color} />
              }

            </div>
            <div style={{ display: "flex", flexGrow: 1, flexFlow: "column" }}>
              <div>
                <TableView
                  selection={false}
                  data={damagesAudit}
                  columns={
                    this.props.name === "Damages"
                      ? damagesCol
                      : this.props.name === "grb"
                        ? grbCol
                        : null
                  }
                  load={this.state.loading}
                />
              </div>
            </div>
          </div>
        )}
        {damagesAudit && this.state.damagesisAuditView && (
          <div>
            <IconPicker
              styles={{
                color: "#808080",
                marginTop: 10,
                marginRight: 20,
                float: "right",
                cursor: "pointer",
              }}
              click={this.AudithandleAuditViewClose}
              size={"large"}
              name={"arrow left"}
            />

            <div
              style={{ flexGrow: 1, marginLeft: "30px", overflowX: "hidden" }}
            >
              <DamagesAuditsDetail
                apis={this.props.apis}
                name={this.props.name}
                selectedAudit={selectedAudit}
                selectedTemplate={this.props.selectedTemplate}
                color={this.props.color}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

function AuditTableCell(props) {
  function onClick() {
    props.onClick(props.row);
  }
  return (
    <div style={props.style} onClick={onClick}>
      {props.text}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    damages: state.damages,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(
    {
      fetchTemplateAudit: ownProps.apis.fetchTemplateAuditAction,
      downloadAuditPDF: ownProps.apis.downloadAuditReportAction,
      downloadDamagesAuditReportAction: downloadDamagesAuditReportAction,
      downloadTrackerAuditsReportAction: downloadTrackerAuditsReportAction,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DamagesAudits)
);
