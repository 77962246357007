import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  fetchAgentAuditsAction,
  downloadAgentReportAction,
  changeAgentStatusAction,
  downloadAgentVerificationStatusReportAction,
} from "../actions/audit_actions";
import { isLoggedIn } from "../util";
import AgentAuditDetail from "./AgentAuditDetail.jsx";
import {
  Segment,
  Statistic,
  Label,
  Icon,
  Button,
  Confirm,
} from "semantic-ui-react";
import { Grid } from "semantic-ui-react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import ReactTable from "react-table";
import moment from "moment";

class AgentAudits extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.statusLabels = {
      draft: "Draft",
      verified: "Verified",
      notVerified: "Not Verified",
      submitted: "Submitted",
    };

    this.state = {
      selectedAuditId: "",
      isTableView: true,
      isAgentAuditDetailView: true,
      showConfirmMessage: false,
    };
  }

  handleUsernameChange = (e) => {
    this.setState({ username: e.target.value });
  };

  handlePasswordChange = (e) => {
    this.setState({ password: e.target.value });
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.authenticate();
    }
  };

  authenticate = () => {
    this.props.login(this.state.username, this.state.password);
  };

  componentDidMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
      return;
    }

    this.props.fetchAudits("agentVerification");
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
      return false;
    }
    return true;
  }

  onOrgClick(org) {}

  searchFilter = (filter, row) => {
    var flatData = JSON.stringify(row._original);
    return flatData.toLowerCase().includes(filter.value.toLowerCase());
  };

  handleAuditDetailCloseClick = () => {
    this.setState({ isTableView: true });
  };

  handleTableViewAuditClick = (agent) => {
    if (!agent.audit._id) return;

    this.setState({ selectedAuditId: agent.audit._id, isTableView: false });
  };

  handleListViewAuditClick = (agent) => {
    this.setState({ selectedAuditId: agent.audit._id });
  };

  handleViewAuditDetailsClick = (e, agent) => {
    this.setState({ isAgentAuditDetailView: true });
  };

  handleDownloadAgentReportClick = (agent) => {
    this.props.downloadAgentReport(agent);
  };

  handleConfirm = (agent) => {
    this.setState({ showConfirmMessage: false });
    this.props.changeAgentStatus(agent._id);
  };

  handleDownloadAgentVerificationReport = () => {
    this.props.downloadAgentVerificationStatusReport();
  };

  handleCancel = () => this.setState({ showConfirmMessage: false });

  getAudits = () => {
    var audits = [];

    if (this.props.audits.agentAudits) {
      for (var audit of this.props.audits.agentAudits) {
        // audit.status = "Completed";
        audit.org = this.props.audits.orgs.filter(
          (o) => o._id === audit.orgId
        )[0];
        audit.clientName = audit.org.name;
        var user = this.props.audits.users.filter(
          (u) => u._id === audit.audit.user_id
        )[0];
        if (user) audit.audit.auditor = user.displayName;
        else audit.audit.auditor = "";
        audits.push(audit);
      }
    }

    return audits;
  };

  render() {
    if (this.props.audits.statusChanged) {
      window.location.reload();
    }

    var audits = this.getAudits();

    var columns = [
      {
        Header: "Client",
        accessor: "clientName",
        style: { textAlign: "center", cursor: "pointer" },
        Cell: (row) => (
          <AuditTableCell
            row={row.original}
            text={row.original.clientName}
            onClick={this.handleTableViewAuditClick}
          ></AuditTableCell>
        ),
      },
      {
        Header: "Auditor",
        accessor: "auditor",
        style: { cursor: "pointer" },
        Cell: (row) => (
          <AuditTableCell
            row={row.original}
            text={row.original.audit.auditor}
            onClick={this.handleTableViewAuditClick}
          ></AuditTableCell>
        ),
      },
      {
        Header: "Agent Name",
        accessor: "agentName",
        style: { cursor: "pointer" },
        Cell: (row) => (
          <AuditTableCell
            row={row.original}
            text={row.original.name}
            onClick={this.handleTableViewAuditClick}
          ></AuditTableCell>
        ),
      },
      {
        Header: "Address",
        accessor: "city",
        style: { cursor: "pointer", whiteSpace: "normal" },
        Cell: (row) => {
          var address = row.original.address;
          address += ", " + row.original.town;
          address += ", " + row.original.city;
          return (
            <AuditTableCell
              row={row.original}
              text={address}
              onClick={this.handleTableViewAuditClick}
            ></AuditTableCell>
          );
        },
      },
      {
        Header: "Mobile No",
        accessor: "mobileNo",
        style: { textAlign: "center", cursor: "pointer" },
        Cell: (row) => (
          <AuditTableCell
            row={row.original}
            text={row.original.mobileNo}
            onClick={this.handleTableViewAuditClick}
          ></AuditTableCell>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        width: 150,
        style: { textAlign: "center", cursor: "pointer" },
        Cell: (row) => {
          if (row.original.status === "verified") {
            return (
              <Button
                onClick={() => this.setState({ showConfirmMessage: true })}
                animated="vertical"
              >
                <Button.Content hidden>Not Verified</Button.Content>
                <Button.Content visible>
                  <Icon name="write" />
                  <AuditTableCell
                    row={row.original}
                    text={this.statusLabels[row.original.status]}
                    onClick={this.handleTableViewAuditClick}
                  ></AuditTableCell>
                </Button.Content>
                <Confirm
                  open={this.state.showConfirmMessage}
                  onCancel={this.handleCancel}
                  onConfirm={() => this.handleConfirm(row.original)}
                />
              </Button>
            );
          }
          return (
            <AuditTableCell
              row={row.original}
              text={this.statusLabels[row.original.status]}
              onClick={this.handleTableViewAuditClick}
            ></AuditTableCell>
          );
        },
      },
      {
        Header: "Audit Date",
        accessor: "auditDate",
        style: { textAlign: "center", cursor: "pointer" },
        Cell: (row) => {
          var date = "";
          if (row.original.audit.createdAt) {
            date = moment(row.original.audit.createdAt).format("ll");
          }
          return (
            <AuditTableCell
              row={row.original}
              text={date}
              onClick={this.handleTableViewAuditClick}
            ></AuditTableCell>
          );
        },
      },
      {
        Header: "Report",
        accessor: "reportUrl",
        width: 150,
        style: { textAlign: "center", cursor: "pointer" },
        Cell: (row) => {
          if (row.original.audit._id) {
            // TODO Here is the code to download the pdf.. but need to send it via mail now.
            return (
              <Icon
                size="large"
                color="orange"
                name="file pdf outline"
                onClick={() =>
                  this.handleDownloadAgentReportClick(row.original)
                }
              />
            );
          }
          return <AuditTableCell row={row.original} text=""></AuditTableCell>;
        },
      },
    ];

    var selectedAudit;

    if (audits.length > 0) {
      if (!this.state.selectedAuditId) {
        selectedAudit = audits[0];
        this.state.selectedAuditId = selectedAudit.audit._id;
      } else {
        selectedAudit = audits.filter(
          (a) => a.audit._id === this.state.selectedAuditId
        )[0];
      }
    } else {
      return <div></div>;
    }

    return (
      <div style={{ flexGrow: 1, display: "flex", flexFlow: "column" }}>
        <div>
          <Grid style={{ marginBottom: 10 }}>
            <Grid.Row columns={4}>
              <Grid.Column>
                <h1 style={{ paddingLeft: 30, flex: "0 0 30px" }}>
                  Audits - Agent Verification
                </h1>
              </Grid.Column>
              <Grid.Column>
                <Label
                  color="green"
                  style={{ cursor: "pointer" }}
                  onClick={this.handleDownloadAgentVerificationReport}
                >
                  <Icon name="download" /> Download Master Report
                </Label>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>

        <div>
          <Statistic.Group widths="three">
            <Statistic color="blue">
              <Statistic.Value>
                {this.props.audits.summary["totalAgents"]}
              </Statistic.Value>
              <Statistic.Label>Agents</Statistic.Label>
            </Statistic>
            <Statistic color="violet">
              <Statistic.Value>
                {this.props.audits.summary["totalCompleted"]}
              </Statistic.Value>
              <Statistic.Label>Verified</Statistic.Label>
            </Statistic>
            <Statistic color="purple">
              <Statistic.Value>
                {this.props.audits.summary["totalNotCompleted"]}
              </Statistic.Value>
              <Statistic.Label>Not Verified</Statistic.Label>
            </Statistic>
          </Statistic.Group>
        </div>
        <div style={{ display: "flex", flexGrow: 1, flexFlow: "column" }}>
          {this.state.isTableView && (
            <div>
              <ReactTable
                noDataText="We couldn't find anything"
                filterable={true}
                defaultPageSize={20}
                sortable={true}
                defaultFilterMethod={this.searchFilter}
                style={{ height: "85%", width: "95%", marginLeft: 30 }}
                data={audits}
                columns={columns}
              />
            </div>
          )}

          {!this.state.isTableView && (
            <div style={{ flexGrow: 1, display: "flex", paddingLeft: 20 }}>
              {false && (
                <div
                  style={{
                    flex: "0 0 300px",
                    background: "#fafafa",
                    padding: 15,
                  }}
                >
                  {audits.map((audit, i) => {
                    if (audit.audit._id) {
                      return (
                        <AuditCell
                          audit={audit}
                          key={i}
                          selected={audit._id === selectedAudit._id}
                          onClick={this.handleListViewAuditClick}
                        />
                      );
                    }
                  })}
                </div>
              )}

              {this.state.isAgentAuditDetailView && (
                <AgentAuditDetail
                  audit={selectedAudit}
                  summary={this.props.audits.summary}
                  onClose={this.handleAuditDetailCloseClick}
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

function AuditTableCell(props) {
  function onClick() {
    props.onClick(props.row);
  }
  return (
    <div style={props.style} onClick={onClick}>
      {props.text}
    </div>
  );
}

function AuditCell(props) {
  var audit = props.audit;
  var bgStyle = {
    width: 5,
  };

  if (props.selected) {
    bgStyle.background = "#2ecc71";
  }

  return (
    <Segment
      style={{ display: "flex", padding: "0px" }}
      onClick={() => {
        props.onClick(audit);
      }}
    >
      <div style={bgStyle}></div>
      <div style={{ padding: "1em", lineHeight: 1.7 }}>
        {audit.audit.auditName}
        <br />
        <i>for</i> {audit.name} <br />
        <i>by</i> {audit.audit.auditor}
        <br />
        {moment(audit.audit.createdAt).format("llll")}
      </div>
    </Segment>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    audits: state.audits,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchAudits: fetchAgentAuditsAction,
      downloadAgentReport: downloadAgentReportAction,
      changeAgentStatus: changeAgentStatusAction,
      downloadAgentVerificationStatusReport:
        downloadAgentVerificationStatusReportAction,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AgentAudits)
);
