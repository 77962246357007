import React from "react";
// import ReactTable from "react-table";
import Table from "../commonTables/Table.jsx";
import TableView from "../commonViews/TableView";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn } from "../../util";
import {
  Icon,
  Button,
  Modal,
  Form,
  Menu,
  Grid,
  Segment,
  Checkbox,
  Label,
  Input,
  Dimmer,
  Loader,
  Popup,
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import {
  fetchUserDetailAction,
  editUserDetailAction,
  fetchOrgsDetailAction,
  fetchDealersDetailAction,
  setCurrentUser,
  AddUsersDetailAction,
  clearUserAction,
  ChangePasswordAction,
} from "../../actions/user_detail_action";
import {
  getTextColor,
  getMainBackground,
  getMatchingTint,
  getFontColor,
} from "../../ThemeHandlers/theme";

import { toast, SemanticToastContainer } from "react-semantic-toasts";
import ConstantLabel from "../commonViews/ConstantLabel.jsx";

class UserDetails extends React.Component {
  // static propTypes = {
  //     history: PropTypes.object.isRequired
  // };

  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      selectedOrg: null,
      selectedDealer: null,
      editedUser: {},
      saveError: false,
      newUser: false,
      newPassword: false,
      oldPassword: "",
      name: "",
      phone: "",
      email: "",
      imei: "",
      search: "",
      password: "",
      empId: "",
      filteredData: [],
      checked: false,
      showSearchAndSelectall: false,
      typing: false,
      Password: false,
      hidePass: true,
      hidePass1: true,
      message: "",
      users: [],
    };
  }

  componentDidMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
      return;
    }
    this.props.fetchUserDetailTemplates();
    this.props.fetchOrgsDetailAction();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user.dealerDetails != this.props.user.dealerDetails) {
      this.setState({
        filteredData: nextProps.user.dealerDetails,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.user.userDetails !== prevProps.user.userDetails) {
      this.setState({
        users: this.props.user.userDetails,
      });
    }
  }
  searchFilter = (filter, row) => {
    var flatData = JSON.stringify(row._original);
    return flatData.toLowerCase().includes(filter.value.toLowerCase());
  };

  updateSearch(event) {
    this.setState({
      search: event.target.value,
    });
  }

  editUserDetail = (data) => {
    this.props.setCurrentUser(data);
    this.setState({
      editMode: true,
      name: data.displayName,
      phone: data.mobileNo,
      email: data.email,
      empId: data.empId ? data.empId : "",
      imei: data.imei ? data.imei.join(",") : "",
      newUser: false,
    });
  };

  closeEditUser = () => {
    this.props.clearUserAction();
    this.setState({ editMode: false, showSearchAndSelectall: false });
  };

  labelClick = (orgId) => {
    console.log(orgId, "label click");
    // this.props.fetchDealersDetailAction(orgId);
    this.setState({ selectedOrg: orgId, checked: false });
  };

  handleClick = (orgId) => {
    console.log(orgId, "orgid");
    this.props.fetchDealersDetailAction(orgId);

    var user = this.props.user.currentUser;

    var add = true;

    if (!("permissions" in user)) {
      console.log("true");
      user.permissions = [];
    }
    if (!("orgs" in user)) {
      console.log("true in orgs");
      user.orgs = [];
    }
    user.orgs.map((orgs, i) => {
      console.log(orgs, i, "i");
      if (orgs === orgId) {
        user.orgs.splice(i, 1);
      }
    });
    user.permissions.map((permission, i) => {
      console.log(permission, i, "permission");
      if (permission.org === orgId) {
        add = false;
        user.permissions.splice(i, 1);
      }
    });

    if (add) {
      console.log(orgId, "adding orgid");
      user.permissions.push({ org: orgId });
      user.orgs.push(orgId);
    }
    this.setState({
      selectedOrg: orgId,
      showSearchAndSelectall: true,
      checked: false,
    });
    this.props.setCurrentUser(user);
  };

  handleDealerClick = (dealerId) => {
    console.log(dealerId, "handledealerclick");
    var user = this.props.user.currentUser;
    var add = true;
    user.permissions.map((permission) => {
      if (this.state.selectedOrg === permission.org) {
        if (!("dealers" in permission)) {
          permission.dealers = [];
        }
        permission.dealers.map((dealer, i) => {
          if (dealer === dealerId) {
            add = false;
            permission.dealers.splice(i, 1);
          }
        });
      }
    });
    if (add) {
      user.permissions.map((permission) => {
        if (this.state.selectedOrg === permission.org) {
          if (!("dealers" in permission)) {
            permission.dealers = [];
          }
          permission.dealers.push(dealerId);
        }
      });
    }
    this.setState({
      selectedDealer: dealerId,
    });
    this.props.setCurrentUser(user);
  };

  saveEditedUser = () => {
    console.log("inside save button");
    var user = this.props.user.currentUser;
    user.displayName = this.state.name;
    user.mobileNo = this.state.phone;
    user.email = this.state.email;
    user.empId = this.state.empId;
    user.imei = this.state.imei.split(",");
    this.props.setCurrentUser(user);
    this.props.editUserDetailTemplates(user);
    this.props.clearUserAction();
    this.setState({
      newUser: {},
      editMode: false,
      saveError: false,
      selectedOrg: null,
      selectedDealer: null,
    });
  };

  onNameChange = (e) => {
    this.setState({ name: e.target.value });
  };
  Editpassword = () => {
    this.setState({
      email: "",
      password: "",
      oldPassword: "",
      Password: true,
      newPassword: true,
    });
  };
  CloseEditpassword = () => {
    this.setState({
      Password: false,
    });
  };

  addNewUser = () => {
    console.log("coming inside add user");
    var newUser = {};
    newUser.displayName = "";
    newUser.mobileNo = "";
    newUser.email = "";
    newUser.empId = "";
    newUser.orgs = [];
    newUser.permissions = [];
    this.props.setCurrentUser(newUser);
    this.props.clearUserAction();
    this.setState({
      editMode: true,
      newUser: true,
      name: "",
      phone: "",
      email: "",
      imei: "",
      empId: ""
    });
  };

  saveNewUser = () => {
    console.log("saving the data");
    var user = this.props.user.currentUser;
    user.displayName = this.state.name;
    user.mobileNo = this.state.phone;
    user.email = this.state.email;
    user.imei = this.state.imei.split(",");
    user.empId = this.state.empId;
    this.props.setCurrentUser(user);
    this.props.AddUsersDetailAction(user);
    this.setState({
      editMode: false,
      saveError: false,
      newUser: false,
      selectedOrg: null,
      selectedDealer: null,
    });
  };

  onPhoneChange = (e) => {
    this.setState({ phone: e.target.value });
  };

  onEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };

  onEmpIdChange = (e) => {
    this.setState({ empId: e.target.value })
  }

  onNewPassword = (e) => {
    this.setState({
      password: e.target.value,
    });
  };
  onOldPassword = (e) => {
    this.setState({
      oldPassword: e.target.value,
    });
  };
  CheckPassword = (e) => {
    this.setState({
      confirmPassword: e.target.value,
    });
  };
  clickable = () => {
    this.setState({
      hidePass: !this.state.hidePass,
    });
  };
  clickable1 = () => {
    this.setState({
      hidePass1: !this.state.hidePass1,
    });
  };
  onChangePassword = (e) => {
    let email = this.state.email;
    let new_password = this.state.password;
    let old_password = this.state.oldPassword;
    var changePassword = {
      email,
      new_password,
      old_password,
    };
    this.props.ChangePasswordAction(changePassword);
  };
  onImeiChange = (e) => {
    // let imeiString = e.target.value;
    // let imeiList = imeiString.split(",");
    this.setState({ imei: e.target.value });
  };
  selectAll = (e) => {
    var eachCheckboxes = document.getElementsByName("checkbox");
    var user = this.props.user.currentUser;

    if (this.state.checked === false) {
      user.permissions.map((permission) => {
        if (this.state.selectedOrg === permission.org) {
          if (!("dealers" in permission)) {
            permission.dealers = [];
          }
          eachCheckboxes.forEach((q) => {
            if (q.checked === false) {
              q.checked = true;
              permission.dealers.push(q.id);
            }
          });
        }
      });
      this.setState({
        checked: true,
      });
    } else if (this.state.checked === true) {
      user.permissions.map((permission) => {
        if (this.state.selectedOrg === permission.org) {
          permission.dealers = [];
          eachCheckboxes.forEach((q) => {
            if (q.checked === true) {
              q.checked = false;
            }
          });
        }
      });
      this.setState({
        checked: false,
      });
    }

    this.props.setCurrentUser(user);
  };

  render() {
    var UserList = Array.isArray(this.state.users) ? this.state.users : [];

    // var filteredData = this.props.user.dealerDetails != undefined ? this.props.user.dealerDetails : "";
    var tobeUpdated = this.state.filteredData.filter((dealer) => {
      return dealer.name
        ? dealer.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !==
        -1
        : [];
    });

    var col = [
      {
        title: "Name",
        field: "displayName",
        render: (rowData) => {
          return <span>{rowData.displayName}</span>;
        },
        // customFilterAndSearch: (term, rowData) =>
        //   rowData.displayName.toLowerCase().includes(term.toLowerCase()),
      },
      {
        title: "Email",
        field: "email",
        render: (rowData) => {
          return <span>{rowData.email}</span>;
        },
        // customFilterAndSearch: (term, rowData) =>
        //   rowData.email.toLowerCase().includes(term.toLowerCase()),
      },
      {
        title: "Phone Number",
        field: "mobileNo",
        render: (rowData) => {
          return <span>{rowData.mobileNo}</span>;
        },
        // customFilterAndSearch: (term, rowData) =>
        //   rowData.mobileNo.toLowerCase().includes(term.toLowerCase()),
      },
      // {
      //   title: "Action",
      //   field: "",
      //   render: (rowData) => {
      //     <Button
      //       onClick={() => this.editUserDetail(rowData)}
      //       animated
      //       style={{
      //         width: "6vw",
      //         backgroundColor: "green",
      //         color: "white",
      //         fontFamily: "Arial, Helvetica, sans-serif",
      //       }}
      //       // onClick={this.editPromotionClick.bind(this, row.original)}
      //     >
      //       <Button.Content visible>Edit</Button.Content>
      //       <Button.Content hidden>
      //         <Icon name="undo alternate" />
      //       </Button.Content>
      //     </Button>;
      //   },
      // },
    ];

    const columns = [
      {
        Header: "Name",
        accessor: "displayName",
        width: 300,
        style: {
          textAlign: "center",
          cursor: "pointer",
          fontFamily: "Arial, Helvetica, sans-serif",
        },
        // Cell: row => (
        //   <UserTableCell
        //     row={row.original}
        //     text={row.original.displayName}
        //   // onClick={this.handleTableViewAuditClick}
        //   />
        // )
      },
      {
        Header: "Email",
        accessor: "email",
        width: 300,
        style: {
          textAlign: "center",
          cursor: "pointer",
          fontFamily: "Arial, Helvetica, sans-serif",
        },
        // Cell: row => (
        //   <UserTableCell
        //     row={row.original}
        //     text={row.original.email}
        //   // onClick={this.handleTableViewAuditClick}
        //   />
        // )
      },
      {
        Header: "PhoneNumber",
        accessor: "mobileNo",
        width: 300,
        style: {
          textAlign: "center",
          cursor: "pointer",
          fontFamily: "Arial, Helvetica, sans-serif",
        },
        // Cell: row => (
        //   <UserTableCell
        //     row={row.original}
        //     text={row.original.mobileNo}
        //   // onClick={this.handleTableViewAuditClick}
        //   />
        // )
      },

      {
        Header: "Action",
        accessor: "editing",
        Cell: ({ row: { original } }) => (
          <div>
            {!this.state.editMode && (
              <Button
                onClick={() => this.editUserDetail(original)}
                animated
                style={{
                  width: "6vw",
                  backgroundColor: "green",
                  color: "white",
                  fontFamily: "Arial, Helvetica, sans-serif",
                }}
              // onClick={this.editPromotionClick.bind(this, row.original)}
              >
                <Button.Content visible>Edit</Button.Content>
                <Button.Content hidden>
                  <Icon name="undo alternate" />
                </Button.Content>
              </Button>
            )}
          </div>
        ),
      },
    ];

    return (
      <div
        style={{
          display: "flex",
          flexGrow: 2,
          flexFlow: "column",
          marginTop: "2%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            flexFlow: "column",
            marginTop: "2%",
            width: "100%",
          }}
        >
          <div
            style={{
              marginBottom: "15px",
              height: "40px",
              alignContent: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
            }}
          >
            <ConstantLabel
              title={"Add User"}
              size={"large"}
              onClick={this.addNewUser}
              styles={{
                cursor: "pointer",
                padding: 10,
                backgroundColor: getMainBackground(this.props.color),
                color: getFontColor(this.props.color),
              }}
              iconName={"add"}
            />
            <ConstantLabel
              title={"Change Password"}
              size={"large"}
              onClick={this.Editpassword}
              styles={{
                cursor: "pointer",
                padding: 10,
                backgroundColor: getMainBackground(this.props.color),
                color: getFontColor(this.props.color),
              }}
              iconName={"edit"}
            />


          </div>

          {/* <Button basic color='green' onClick={this.addNewUser}>
            <Icon name={"add"} />
            
          </Button> */}
          {UserList.length === 0 && (
            <div>
              <Dimmer active>
                <Loader size="massive">Loading</Loader>
              </Dimmer>
            </div>
          )}
          <TableView
            selection={false}
            data={UserList}
            columns={col}
            actions={[
              {
                icon: "edit",
                tooltip: "Edit User",
                onClick: (event, rowData) => {
                  // Do save operation
                  this.editUserDetail(rowData);
                },
              },
            ]}
          />
          {/* <Table data={UserList} columns={columns} rowClick={() => {}} /> */}
          <Modal
            open={this.state.Password}
            onClose={this.CloseEditpassword}
            size="large"
          >
            <SemanticToastContainer />
            <Modal.Content
              style={{ backgroundColor: getMatchingTint(this.props.color) }}
            >
              <Form>
                <Grid row={5} padding={5}>
                  <Grid.Column>
                    <Grid.Row style={{ padding: "2%" }}>
                      <Form.Input
                        label="Email"
                        type="email"
                        placeholder="Enter Email"
                        value={this.state.email}
                        disabled={!this.state.newPassword}
                        style={{ color: "brown" }}
                        onChange={this.onEmailChange}
                      />
                    </Grid.Row>
                    <Grid.Row style={{ padding: "2%" }}>
                      <Form.Field>
                        <label>Old password</label>
                        <Input
                          action={{
                            icon: "eye",
                            onClick: () => this.clickable1(),
                          }}
                          type={this.state.hidePass1 ? "password" : "text"}
                          placeholder="Enter old password..."
                          value={this.state.oldPassword}
                          onChange={this.onOldPassword}
                        />
                      </Form.Field>
                    </Grid.Row>
                    <Grid.Row style={{ padding: "2%" }}>
                      <Form.Field>
                        <label>New password</label>
                        <Input
                          action={{
                            icon: "eye",
                            onClick: () => this.clickable(),
                          }}
                          type={this.state.hidePass ? "password" : "text"}
                          placeholder="Enter new password..."
                          value={this.state.password}
                          onChange={this.onNewPassword}
                        />
                      </Form.Field>
                    </Grid.Row>
                    <Grid.Row style={{ padding: "2%" }}>
                      <Form.Input>
                        <Label>
                          The Characters should Contain (A-Z,@#!$&%,1-10)
                          ("e.g:Siva@123")
                        </Label>
                      </Form.Input>
                    </Grid.Row>
                  </Grid.Column>
                </Grid>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color="red" onClick={this.CloseEditpassword}>
                <Icon name="remove" /> Cancel
              </Button>
              <Button color="green" onClick={this.onChangePassword}>
                <Icon name="checkmark" /> Save
              </Button>
            </Modal.Actions>
          </Modal>

          <Modal
            open={this.state.editMode}
            onClose={this.closeEditUser}
            size="large"
          >
            <Modal.Content
              style={{ backgroundColor: getMatchingTint(this.props.color) }}
            >
              <Form>
                <Grid columns={3}>
                  <Grid.Row>
                    <Grid.Column>
                      <Form.Input
                        label="Name"
                        type="text"
                        placeholder="Auditor name"
                        value={this.state.name}
                        onChange={this.onNameChange}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Input
                        label="PhoneNumber"
                        type="any"
                        placeholder="Auditor phone number"
                        value={this.state.phone}
                        onChange={this.onPhoneChange}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Input
                        label="Email"
                        type="email"
                        placeholder="Auditor Email"
                        disabled={!this.state.newUser}
                        value={this.state.email}
                        style={{ color: "brown" }}
                        onChange={this.onEmailChange}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Form.Input
                        label="IMEI Numbers"
                        type="email"
                        placeholder="IMEI (separate nultiple values by comma ',')"
                        value={this.state.imei}
                        style={{ color: "brown" }}
                        onChange={this.onImeiChange}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Input
                        label="Employee Code"
                        type="any"
                        placeholder="Employee Code"
                        value={this.state.empId}
                        style={{ color: "brown" }}
                        onChange={this.onEmpIdChange}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Segment.Group
                        style={{ overflow: "auto", maxHeight: 200 }}
                      >
                        <Menu.Header as="h4" style={{ textAlign: "center" }}>
                          ORGS
                        </Menu.Header>
                        {/* mapping orgs model refer here */}
                        {this.props.user.orgsDetails &&
                          this.props.user.orgsDetails.map((orgDetail) => {
                            var isChecked = false;
                            if (this.props.user.currentUser) {
                              this.props.user.currentUser.permissions.map(
                                (permission) => {
                                  if (permission.org === orgDetail._id) {
                                    isChecked = true;
                                  }
                                }
                              );
                            }
                            return (
                              <Segment>
                                <Form>
                                  <Form.Field>
                                    <Popup
                                      trigger={
                                        <Checkbox
                                          style={{ paddingLeft: "30%", margin: 0 }}
                                          label={orgDetail.name}
                                          name="checkboxRadioGroup"
                                          value="this"
                                          checked={isChecked}
                                          onClick={() => {
                                            this.handleClick(orgDetail._id);
                                          }}
                                        />
                                      }
                                      content="Click here if Selected."
                                      size="tiny"
                                      position="right center"
                                    ></Popup>
                                  </Form.Field>
                                </Form>
                              </Segment>
                            );
                          })}
                      </Segment.Group>
                    </Grid.Column>

                    <Grid.Column>
                      <Segment.Group
                        style={{ overflow: "auto", maxHeight: 200 }}
                      >
                        <Menu.Header as="h4" style={{ textAlign: "center" }}>
                          Dealers
                        </Menu.Header>

                        <input
                          placeholder="Search..."
                          style={{ display: "inline-block" }}
                          type="text"
                          value={this.state.search}
                          onChange={this.updateSearch.bind(this)}
                        />

                        <Checkbox
                          label="Select all"
                          style={{ padding: "3%" }}
                          value="this"
                          checked={this.state.checked}
                          name="selectAll"
                          onClick={this.selectAll.bind(this)}
                        />

                        {tobeUpdated.map((dealerDetail) => {
                          var isChecked = false;
                          if (this.props.user.currentUser) {
                            this.props.user.currentUser.permissions.map(
                              (permission) => {
                                {
                                  permission.dealers &&
                                    permission.dealers.map((dealerId) => {
                                      if (dealerId === dealerDetail._id) {
                                        isChecked = true;
                                      }
                                    });
                                }
                              }
                            );
                          }
                          return (
                            <Segment>
                              <Form>
                                <Form.Field>
                                  <Checkbox
                                    id={dealerDetail._id}
                                    label={
                                      dealerDetail.awCode
                                        ? dealerDetail.awCode +
                                        "-" +
                                        dealerDetail.name
                                        : dealerDetail.name
                                          ? dealerDetail.name
                                          : dealerDetail.dealerCode
                                            ? dealerDetail.dealerName
                                            : dealerDetail.packageName
                                    }
                                    name="checkbox"
                                    value="this"
                                    checked={isChecked}
                                    onClick={() => {
                                      this.handleDealerClick(dealerDetail._id);
                                    }}
                                  />
                                </Form.Field>
                              </Form>
                            </Segment>
                          );
                        })}
                      </Segment.Group>
                    </Grid.Column>
                  </Grid.Row>


                </Grid>
              </Form>
            </Modal.Content>

            <Modal.Actions
              style={{ backgroundColor: getMainBackground(this.props.color) }}
            >
              <Button size="medium" color="red" onClick={this.closeEditUser}>
                <Icon name="remove" /> No
              </Button>
              {this.state.editMode && (
                <Button
                  size="medium"
                  style={{
                    cursor: "pointer",
                    backgroundColor: getMatchingTint(this.props.color),
                    color: getTextColor(this.props.color),
                  }}
                  onClick={
                    this.state.newUser ? this.saveNewUser : this.saveEditedUser
                  }
                >
                  Save
                </Button>
              )}
            </Modal.Actions>
          </Modal>
        </div>
      </div>
    );
  }
}

function UserTableCell(props) {
  function onClick() {
    props.onClick(props.row);
  }
  return (
    <div style={props.style} onClick={onClick}>
      {props.text}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchUserDetailTemplates: fetchUserDetailAction,
      editUserDetailTemplates: editUserDetailAction,
      fetchOrgsDetailAction: fetchOrgsDetailAction,
      fetchDealersDetailAction: fetchDealersDetailAction,
      setCurrentUser: setCurrentUser,
      AddUsersDetailAction: AddUsersDetailAction,
      clearUserAction: clearUserAction,
      ChangePasswordAction: ChangePasswordAction,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserDetails)
);
