import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn, isAdmin } from "./../util";
import DaimlerControlPanel from "./control_panel_components/DaimlerControlPanel.jsx";
import TanishqAndWOTControlPanel from "./control_panel_components/TanishqAndWOTControlPanel.jsx";
import VolkswagenControlPanel from "./control_panel_components/VolkswagenControlPanel.jsx";
import DamagesControlPanel from "./control_panel_components/DamagesControlPanel.jsx";
import DtcControlPanel from "./control_panel_components/DtcControlPanel.jsx";
import PreethiControlPanel from "./control_panel_components/PreethiControlPanel.jsx";
import GodrejControlPanel from "./control_panel_components/GodrejControlPanel.jsx";
import DaimlerKeyControlPanel from "./control_panel_components/DaimlerKeyControlpanel.jsx";
import FurlencoControlPanel from "./control_panel_components/FurlencoControlPanel.jsx";
import UserDetails from "./control_panel_components/UserDetails.jsx";
import JohnsonControlPanel from "./control_panel_components/johnsonControlPanel.jsx";
import NaturalsControlPanel from "./control_panel_components/NaturalsControlpanel.jsx";
import GrbControlPanel from "./control_panel_components/GrbControlPanel.jsx";
import UdaanControlPanel from "./control_panel_components/UdaanControlPanel.jsx";
import MrRetailControlPanel from "./control_panel_components/MrRetailControlPanel.jsx";
import HersheysControlPanel from "./control_panel_components/HersheysControlPanel.jsx";
import CannonControlPanel from "./control_panel_components/CannonControlPanel.jsx";
import AtmControlpanel from "./control_panel_components/AtmControlPanel.jsx";
import { selectMenuAction, logoutAction } from "../actions/index";
import { Segment, Icon } from "semantic-ui-react";
import { Sidebar, Menu, Dropdown } from "semantic-ui-react";
import SalesControlPanel from "./control_panel_components/SalesControlPanel.jsx";

import TimeSheets from "./control_panel_components/TimeSheets";
import { changeTheme } from "../actions/index";
import FormMenuItem from './commonViews/FormMenuItem.jsx'

import HorizontalNavbarContent from "./commonViews/HorizontalNavbarContent";

import {
  getMainBackground,
  getMatchingTint,
  getFontColor,
  getActiveColor,
} from "../ThemeHandlers/theme";

class ControlPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sideBarVisible: true,
      color: "",
    };
  }

  handleItemClick = (e, { name }) => this.props.selectMenu(name);

  logout = () => {
    this.props.logout();
  };

  componentWillMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
    }

    if (!isAdmin(this.props.auth)) {
      this.props.history.push(`/`);
    }
  }
  componentDidMount() {
    if (localStorage.getItem("themeColor") !== null) {
      this.props.changeTheme(localStorage.getItem("themeColor"));
    }
    if (this.props.auth.color !== undefined) {
      this.setState({ color: this.props.auth.color });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth.color !== prevProps.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!isLoggedIn(nextProps.auth)) {
      this.props.history.push(`/login`);
      return false;
    }
    if (!isAdmin(this.props.auth)) {
      this.props.history.push(`/`);
      return false;
    }
    return true;
  }

  toggleSidebar = () => {
    this.setState({ sideBarVisible: !this.state.sideBarVisible });
  };

  openHome = () => {
    this.props.history.push(`/`);
  };
  colorBlueChange = () => {
    this.props.changeTheme("blue");
    localStorage.setItem("themeColor", "blue");
  };

  colorTealChange = () => {
    this.props.changeTheme("orange");
    localStorage.setItem("themeColor", "orange");
  };

  colorYellowChange = () => {
    this.props.changeTheme("yellow");
    localStorage.setItem("themeColor", "yellow");
  };

  render() {
    var activeItem = this.props.home.selectedMenu || "audits";
    var pusherStyle = {
      height: "100vh",
      backgroundColor: getMatchingTint(this.state.color),

      overflow: "auto",
      width: "90%",
      border: 0,
    };

    if (!this.state.sideBarVisible) {
      pusherStyle.width = "100%";
    }
    var theme = "BLUE_THEME";
    var sideBarTheme = "blue";

    if (this.state.color === "blue") {
      theme = "BLUE_THEME";
      sideBarTheme = "blue";
    } else if (this.state.color === "yellow") {
      theme = "TEAL_THEME";
      sideBarTheme = "yellow";
    } else if (this.state.color === "orange") {
      theme = "ORANGE_THEME";
      sideBarTheme = "orange";
    }

    let menuOptions = [
      { name: 'Home', click: this.openHome },
      { name: 'Logout', click: this.logout }
    ]
    return (
      <div>
        <div className={theme} style={{ backgroundColor: "#FFF" }}>
          <HorizontalNavbarContent
            toggleSidebar={this.toggleSidebar}
            ColorToBlue={this.colorBlueChange}
            colorToTeal={this.colorTealChange}
            colorToYellow={this.colorYellowChange}
            itemOptions={menuOptions}
            auth={this.props.auth}
          />

        </div>

        <Sidebar.Pushable
          as={Segment}
          style={{
            marginTop: -15,
            display: "flex",
            borderRadius: 0,
            height: "calc(100% - 70px)",
          }}
        >
          <Sidebar
            as={Menu}
            visible={this.state.sideBarVisible}
            activeIndex="0"
            className={`${sideBarTheme}`}
            style={{
              backgroundColor: getMainBackground(this.state.color),
            }}
            animation="slide along"
            width="thin"
            icon="labeled"
            vertical
          >
            <Menu.Item
              name="Users"
              active={activeItem === "Users"}
              onClick={this.handleItemClick}
              style={{
                backgroundColor:
                  activeItem === "Users"
                    ? getActiveColor(this.state.color)
                    : null,
              }}
            >
              <Icon
                name="user"
                style={{
                  color:
                    activeItem === "Users"
                      ? getMainBackground(this.state.color)
                      : getFontColor(this.state.color),
                }}
              />
              <span
                style={{
                  color:
                    activeItem === "Users"
                      ? getMainBackground(this.state.color)
                      : getFontColor(this.state.color),
                }}
              >
                users
              </span>
            </Menu.Item>
            {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "5a0c1fd4709f16d6fe232142").length === 1 && (
              <FormMenuItem
                name={"audits"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"check"}
                title={"Audits"}
              />
            )}

            {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "5b4efae7b89f85c29de80f08").length === 1 && (
              <FormMenuItem
                name={"daimler"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"car"}
                title={"Daimler"}
              />
            )
            }

            {/* {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "5ac5ce8b3788c53c19c155e4").length === 1 && (
              <FormMenuItem
                name={"volkswagen"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"car"}
                title={"Volkswagen"}
              />
            )} */}

            {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "5b96aca9b443696fdd455599").length === 1 && (
              <FormMenuItem
                name={"pandg"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"certificate"}
                title={"P & G"}
              />
            )
            }
            {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "5bd2aeeb301cb0c3d9cc7ccc").length === 1 && (
              <FormMenuItem
                name={"damages"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"recycle"}
                title={"Damages"}
              />
            )
            }

            {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "5d1b5358d7646014cd5e0ed6").length === 1 && (
              <FormMenuItem
                name={"godrej"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"google"}
                title={"Godrej"}
              />
            )}

            {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "5dc8fe8db8e59747497e2ef4").length === 1 && (
              <FormMenuItem
                name={"DiamlerKeyTemplates"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"key"}
                title={"Daimler Key Management"}
              />
            )
            }

            {/* {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "5de7a27f0cd45a4cb475819e").length === 1 && (
              <FormMenuItem
                name={"Furlenco"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"shipping"}
                title={"Furlenco"}
              />
            )} */}

            {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "5e788041781f3d7b2c7dffc3").length === 1 && (
              <FormMenuItem
                name={"johnsonAndJohnson"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"shipping"}
                title={"Johnson & Johnson"}
              />
            )}

            {/* {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "5eec7fe7ce787bdb7bff1867").length === 1 && (
              <FormMenuItem
                name={"grb"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"glide g"}
                title={"GRB"}
              />
            )}

            {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "5f0e8b94ce787bdb7b1c6a21").length === 1 && (
              <FormMenuItem
                name={"udaan"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"shipping fast"}
                title={"Udaan"}
              />
            )} */}

            {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "5f59b94ad524acc50845fc79").length === 1 && (
              <FormMenuItem
                name={"hersheys"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"shipping fast"}
                title={"Hersheys"}
              />
            )}
            {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "5f6c3b52d524acc508509b31").length === 1 && (
              <FormMenuItem
                name={"mrRetail"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"shopping basket"}
                title={"Mr-Retail"}
              />
            )}
            {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "5fb204f4d524acc508839783").length === 1 && (
              <FormMenuItem
                name={"cannon"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"camera"}
                title={"Cannon"}
              />
            )}
            {/* {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "5fbcbcd07cc708707aed2410").length === 1 && (
              <FormMenuItem
                name={"bank"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"inr"}
                title={"ATM"}
              />
            )} */}
            {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "61bc1a124f2dc40fd89cbab3").length === 1 && (
              <FormMenuItem
                name={"hershysChecklist"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"calendar check outline"}
                title={"Hershys Checklist"}
              />
            )}

            {this.props.auth.role !== "viewer" && this.props.auth.timesheetAccess !== undefined && this.props.auth.timesheetAccess !== "" && this.props.auth.timesheetAccess !== 'user' && (
              <FormMenuItem
                name={"time"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"time"}
                title={"Time Sheets"}
              />
            )}

          </Sidebar>
          <Sidebar.Pusher style={pusherStyle}>
            <Segment
              basic
              style={{
                height: "100%",
                display: "flex",
                padding: "10px 0px 0px 0px",
              }}
            >
              {activeItem === "dashboard" && <div />}
              {activeItem === "audits" && (
                <TanishqAndWOTControlPanel color={this.state.color} />
              )}
              {/* {activeItem === "agentVerification" && <div />} */}
              {/* {activeItem === "volkswagen" && (
                <VolkswagenControlPanel color={this.state.color} />
              )} */}
              {activeItem === "daimler" && (
                <DaimlerControlPanel color={this.state.color} />
              )}
              {activeItem === "pandg" && (
                <DtcControlPanel color={this.state.color} />
              )}
              {activeItem === "damages" && (
                <DamagesControlPanel color={this.state.color} />
              )}
              {/* {activeItem === "preethi" && (
                <PreethiControlPanel color={this.state.color} />
              )} */}
              {activeItem === "godrej" && (
                <GodrejControlPanel color={this.state.color} />
              )}
              {activeItem === "DiamlerKeyTemplates" && (
                <DaimlerKeyControlPanel color={this.state.color} />
              )}
              {/* {activeItem === "salesfundamentals" && (
                <SalesControlPanel color={this.state.color} />
              )} */}
              {/* {activeItem === "Furlenco" && (
                <FurlencoControlPanel color={this.state.color} />
              )} */}
              {activeItem === "johnsonAndJohnson" && (
                <JohnsonControlPanel color={this.state.color} />
              )}
              {/* {activeItem === "Naturals" && (
                <NaturalsControlPanel color={this.state.color} />
              )}
              {activeItem === "grb" && (
                <GrbControlPanel color={this.state.color} />
              )} */}
              {/* {activeItem === "udaan" && (
                <UdaanControlPanel color={this.state.color} />
              )} */}
              {activeItem === "hersheys" && (
                <HersheysControlPanel color={this.state.color} />
              )}
              {activeItem === "mrRetail" && (
                <MrRetailControlPanel color={this.state.color} />
              )}
              {activeItem === "cannon" && (
                <CannonControlPanel color={this.state.color} />
              )}
              {/* {activeItem === "bank" && (
                <AtmControlpanel color={this.state.color} />
              )} */}
              {activeItem === "Users" && (
                <UserDetails color={this.state.color} />
              )}
              {activeItem === "time" && <TimeSheets color={this.state.color} />}
            </Segment>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    home: state.home,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logout: logoutAction,
      selectMenu: selectMenuAction,
      changeTheme: changeTheme,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ControlPanel)
);
