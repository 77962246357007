import {
  FETCH_HERSHYS_CHECK_TEMPLATE_SUCCESS_ACTION,
  FETCH_HERSHYS_CHECK_MASTER_SUCCESS_ACTION,
  FETCH_HERSHYS_CHECK_MASTER_FAILURE_ACTION,
  FETCH_HERSHYS_CHECK_TEMPLATE_FAILURE_ACTION,
  SET_SELECTED_TEMPLATE_DATA,
  SET_SELECTED_MASTER_DATA,
  FETCH_EXISTING_AUDITS_SUCCESS_ACTION,
  FETCH_EXISTING_AUDITS_FAILURE_ACTION,
  FETCH_AUDITORSDETAILS_SUCCESS_ACTION,
  FETCH_AUDITORSDETAILS_FAILIURE_ACTION,
  CLEAR_FETCHED_AUDITS,
} from "../actions/types";

const INIT_STATE = {};

function HershysCheck(state = INIT_STATE, action) {
  state = Object.assign({}, state, {});

  switch (action.type) {
    case FETCH_HERSHYS_CHECK_TEMPLATE_SUCCESS_ACTION:
      state.template = action.payload.templates;
      state.templateError = false;
      return state;
    case FETCH_HERSHYS_CHECK_MASTER_SUCCESS_ACTION:
      state.master = action.payload.master;
      state.masterError = false;
      return state;
    case SET_SELECTED_TEMPLATE_DATA:
      state.selectedTemplate = action.payload.data;
      return state;
    case SET_SELECTED_MASTER_DATA:
      state.selectedMaster = action.payload.data;
      return state;
    case FETCH_EXISTING_AUDITS_SUCCESS_ACTION:
      state.existingAudits = action.payload.audits[0];
      state.fetchExistingAuditsError = false;
      return state;
    case FETCH_EXISTING_AUDITS_FAILURE_ACTION:
      state.fetchExistingAuditsError = true;
      return state;
    case FETCH_AUDITORSDETAILS_SUCCESS_ACTION:
      state.userDetails = action.payload.data;
      state.fetchUserDetailError = false;
      return state;

    case FETCH_AUDITORSDETAILS_FAILIURE_ACTION:
      state.fetchUserDetailError = true;
      return state;
    case CLEAR_FETCHED_AUDITS:
      state.existingAudits = {};
      return state;
    default:
      return state;
  }
}

export default HershysCheck;
