import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { isLoggedIn } from '../../util';
import moment from 'moment';
import { Icon,Statistic, Dimmer, Loader } from 'semantic-ui-react';
import { fetchPreethiTemplateAuditsAction, downloadPreethiAuditReportAction } from '../../actions/preethi_actions';
import Table from "../commonTables/Table.jsx"
import PreethiAuditsDetail from './PreethiAuditsDetail.jsx';

class PreethiAudits extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedAudit: null,
            isAuditView: false,
            isAuditReportDownloading: false
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ isAuditReportDownloading: false });
        if (this.props.audit) {
            this.audit = JSON.parse(JSON.stringify(nextProps.audit));
        }
    }
    componentDidMount() {
        if (!isLoggedIn(this.props.auth)) {
            this.props.history.push(`/login`);
        }
        this.props.fetchPreethiTemplateAudits(this.props.selectedTemplate._id);
    }

    componentWillMount() {
        if (this.props.audit) {
            this.audit = JSON.parse(JSON.stringify(this.props.audit));
        }
    }

    searchFilter = (filter, row) => {
        var flatData = JSON.stringify(row._original).replace("draft", "live");
        return flatData.toLowerCase().includes(filter.value.toLowerCase());
    };

    handleTableViewAuditClick = audit => {
        this.setState({ selectedAudit: audit, isAuditView: true });
    };


    shouldComponentUpdate(nextProps, nextState) {
        if (!isLoggedIn(this.props.auth)) {
            this.props.history.push(`/login`);
            return false;
        }
        return true;
    }

    handleDownloadAuditReportClick = audit => {
        if (audit._id && audit.templateId) {
            this.setState({ isAuditReportDownloading: true });
            let name = audit.masterInfo.dealerName + " - " + audit.masterInfo.dealerCode + " - " + audit.masterInfo.location;
            this.props.downloadAuditPDF(audit._id, audit.templateId, name);
        }
    };

    handleAuditViewClose = () => {
        this.setState({ selectedAudit: null, isAuditView: false });
    };

    render() {
        var damagesAudit = this.props.preethi.preethiAudit,
            selectedAudit = this.state.selectedAudit;

        if (damagesAudit instanceof Array && damagesAudit.length > 0 && selectedAudit == null) {
            selectedAudit = this.props.preethi.preethiAudit[0];
        }
        var columns = [
            {
                Header: 'Dealer Name',
                accessor: 'dealerName',
                style: { cursor: 'pointer' },
                Cell: row => (
                    <AuditTableCell
                        row={row.original}
                        text={row.original.masterInfo.dealerName}
                        onClick={this.handleTableViewAuditClick}
                    />
                )
            },
            {
                Header: 'Dealer Code',
                accessor: 'dealerCode',
                style: { cursor: 'pointer' },
                Cell: row => (
                    <AuditTableCell
                        row={row.original}
                        text={row.original.masterInfo.dealerCode}
                        onClick={this.handleTableViewAuditClick}
                    />
                )
            },
            {
                Header: 'Auditor',
                accessor: 'auditor',
                style: { cursor: 'pointer' },
                Cell: row => (
                    <AuditTableCell
                        row={row.original}
                        text={ row.original.auditorId != null ? row.original.auditorId.displayName : "Not found"}
                        onClick={this.handleTableViewAuditClick}
                    />
                )
            },
            {
                Header: 'Audit Date',
                accessor: 'auditDate',
                style: { cursor: 'pointer' },
                Cell: row => (
                    <AuditTableCell
                        row={row.original}
                        text={moment(row.original.masterInfo.createdAt).format('DD-MM-YYYY  HH:MM:SS')}
                        onClick={this.handleTableViewAuditClick}
                    />
                )
            },
            {
                Header: 'Status',
                accessor: 'status',
                style: { cursor: 'pointer' },
                Cell: row => (
                    <AuditTableCell
                        row={row.original}
                        text={row.original.status == "draft" ? "live": row.original.status}
                        onClick={this.handleTableViewAuditClick}
                    />
                )
            },
            {
                Header: 'Report',
                accessor: 'reportUrl',
                width: 150,
                style: { textAlign: 'center', cursor: 'pointer' },
                Cell: row => {
                    if (row.original._id) {
                        return (
                            <Icon
                                size="large"
                                color="red"
                                name="file pdf outline"
                                onClick={() => this.handleDownloadAuditReportClick(row.original)}
                            />
                        );
                    }
                    return <AuditTableCell row={row.original} text="" />;
                }
            }
        ];

        return (
            <div>
                {!damagesAudit && 
                    <div>
                        <Dimmer active>
                            <Loader size='massive'>Loading</Loader>
                        </Dimmer>
                    </div>
                
                }
                {damagesAudit && !this.state.isAuditView && (
                    <div>
                        <Icon
                            style={{
                                color: '#808080',
                                marginTop: 10,
                                marginRight: 20,
                                float: 'right',
                                cursor: 'pointer'
                            }}
                            onClick={this.props.onClose}
                            size="large"
                            name="arrow left"
                        />

                        <h1 style={{ paddingLeft: 30, flex: '0 0 30px' }}>{this.props.selectedTemplate.auditName}</h1>
                        <div>
                            <Statistic.Group widths="four">
                                <Statistic color="blue">
                                    <Statistic.Value>
                                        {this.props.selectedTemplate.stats.totalAudits || 0}
                                    </Statistic.Value>
                                    <Statistic.Label>Audits Planned</Statistic.Label>
                                </Statistic>
                                <Statistic color="violet">
                                    <Statistic.Value>
                                        {this.props.selectedTemplate.stats.draftAudits || 0}
                                    </Statistic.Value>
                                    <Statistic.Label>Live</Statistic.Label>
                                </Statistic>
                                <Statistic color="purple">
                                    <Statistic.Value>
                                        {this.props.selectedTemplate.stats.submittedAudits || 0}
                                    </Statistic.Value>
                                    <Statistic.Label>Completed</Statistic.Label>
                                </Statistic>
                                <Statistic color="purple">
                                    <Statistic.Value>
                                        {this.props.selectedTemplate.stats.pendingAudits || 0}
                                    </Statistic.Value>
                                    <Statistic.Label>Pending</Statistic.Label>
                                </Statistic>
                            </Statistic.Group>
                        </div>
                        <div style={{ display: 'flex', flexGrow: 1, flexFlow: 'column' }}>
                            <div>
                                <Table
                                    defaultFilterMethod={this.searchFilter}
                                    templates={damagesAudit}
                                    columns={columns}
                                    loading={this.state.isAuditReportDownloading}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {damagesAudit && this.state.isAuditView && (
                    <div>
                        <Icon
                            style={{
                                color: '#808080',
                                marginTop: 10,
                                marginRight: 20,
                                float: 'right',
                                cursor: 'pointer'
                            }}
                            onClick={this.handleAuditViewClose}
                            size="large"
                            name="arrow left"
                        />

                        <div style={{ flexGrow: 1, marginLeft: '30px', overflowX: 'hidden' }}>
                            <PreethiAuditsDetail
                                selectedAudit={selectedAudit}
                                selectedTemplate={this.props.selectedTemplate}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

function AuditTableCell(props) {
    function onClick() {
        props.onClick(props.row);
    }
    return (
        <div style={props.style} onClick={onClick}>
            {props.text}
        </div>
    );
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        preethi: state.preethi
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            fetchPreethiTemplateAudits: fetchPreethiTemplateAuditsAction,
            downloadAuditPDF: downloadPreethiAuditReportAction
        },
        dispatch
    );
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(PreethiAudits)
);
