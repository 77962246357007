import { FETCH_DATE_TIMESHEET_DATA_FAILURE_ACTION, 
    FETCH_DATE_TIMESHEET_DATA_SUCCESS_ACTION, 
    FETCH_FILTERED_MASTER_TIMESHEET_DATA_FAILURE_ACTION, 
    FETCH_FILTERED_MASTER_TIMESHEET_DATA_SUCCESS_ACTION, 
    FETCH_MASTER_TIMESHEET_DATA_FAILURE_ACTION, 
    FETCH_MASTER_TIMESHEET_DATA_SUCCESS_ACTION, 
    REPORT_TIMESHEET_DATA_FAILURE_ACTION, 
    REPORT_TIMESHEET_DATA_SUCCESS_ACTION,
    FETCH_CONTROL_PANEL_MASTER_TIMESHEET_DATA_SUCCESS_ACTION,
    FETCH_CONTROL_PANEL_MASTER_TIMESHEET_DATA_FAILURE_ACTION
} from "../actions/types";


const INIT_STATE = {};

export default function (state = INIT_STATE, action) {

    switch (action.type) {
        case FETCH_MASTER_TIMESHEET_DATA_SUCCESS_ACTION:
            state.templates = action.payload;
            return state;

        case FETCH_MASTER_TIMESHEET_DATA_FAILURE_ACTION:
            state.templateerror = action.payload;
            return state;

        case FETCH_DATE_TIMESHEET_DATA_SUCCESS_ACTION:
            return Object.assign({}, state, {
                dateDetails: action.payload,
            });

        case FETCH_DATE_TIMESHEET_DATA_FAILURE_ACTION:
            state.dateerror = action.payload;
            return state;

        case FETCH_FILTERED_MASTER_TIMESHEET_DATA_SUCCESS_ACTION:
            state.filteredmasterData = action.payload;
            return state;

        case FETCH_FILTERED_MASTER_TIMESHEET_DATA_FAILURE_ACTION:
            state.filtererror = action.payload;
            return state;

        case REPORT_TIMESHEET_DATA_SUCCESS_ACTION:
            state.reportDownloadSuccess = true;
            return state;

        case REPORT_TIMESHEET_DATA_FAILURE_ACTION:
            state.reportDownloadFailure = false;
            return state;
        
        case FETCH_CONTROL_PANEL_MASTER_TIMESHEET_DATA_SUCCESS_ACTION:
            state.master = action.payload.master
            return state;

    }
    return state;
}