import React, { useState } from "react";
import { useTable, usePagination, useRowSelect,useFilters,useGlobalFilter } from "react-table";
import "./checkboxtable.css";


const IndeterminateCheckbox = React.forwardRef(
  ({click, indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;
    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef}  {...rest} onClick={(checked) => {click(checked.target.checked)}}/>
      </>
    );
  }
);
function DefaultColumnFilters({
  column: { filterValue, preFilteredRows, setFilter },
}) {

  return (
    // <input style={{border:'1px solid #efefef',height:28}}/>

    <input
      style={{ border: "1px solid #efefef", height: 28 }}
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
    />
  );
}

function CheckboxTable({ columns, data,rowClick,sendedData  }) {
  // Use the state and functions returned from useTable to build your UI
  const handleClick = (data,checkedValue) => {
    sendedData(data,checkedValue)
    
  }
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilters,
    }),
    []
  );

  const selectAll = (data) => {
    // selectedAllid(data)
  }
 

  const checkID =(id,original)=>{
    if(id === 'verification'){
      return
    }else if(id === 'reportUrl'){
      return
    }
    else if(id === "_id"){
      return
      }
     else if(id === 'signOff'){
       return
      } 
    else if(id  !== 'selection'){
      return  rowClick(original)
    
   }
  }



  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize},
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option

    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} click={()=>{console.log(selectedFlatRows.map(
                  d => selectAll(d.original._id)
                ),"newwwwwwww")}}/>
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()}    click={(data)=>{handleClick(row.original,data)}}/>
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

 

  // Render the UI for your table
  return (
    <>
      <table id="customers" {...getTableProps()} style={{tableLayout:"auto"}}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <>
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
             <tr style={{ height: 29, backgroundColor: "#ececec" }}>
             {headerGroup.headers.map((column) => (
               <th
                 {...column.getHeaderProps()}
                 style={{ backgroundColor: "#ececec" }}
               >
                 <div>
                   {column.canFilter ? column.render("Filter") : null}
                 </div>
               </th>
             ))}
           </tr>
           </>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.length > 0 ? page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}  style={{cursor:'pointer'}}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} onClick={()=>checkID(cell.column.id,row.original)} >{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          }): 
          <>
          <tr style={{border:'0.1px solid #efefef',    padding: '8px',height:'30px'}}>
           
            </tr>
            <tr style={{border:'0.1px solid #efefef', padding: '8px',height:'30px'}}>
           
            </tr>
            <tr style={{border:'0.1px solid #efefef', padding: '8px',height:'30px'}}>
            
            </tr>
            <tr style={{border:'0.1px solid #efefef', padding: '8px',height:'30px'}}>
            
            </tr>
            <tr style={{border:'0.1px solid #efefef', padding: '8px',height:'30px'}}>
           
            </tr>
            <tr style={{border:'0.1px solid #efefef', padding: '8px',height:'30px'}}>
            
            </tr>
            <tr style={{border:'0.1px solid #efefef', padding: '8px',height:'30px'}}>
           
            </tr>
            <tr style={{border:'0.1px solid #efefef', padding: '8px',height:'30px'}}>
           
            </tr>
            <tr style={{border:'0.1px solid #efefef', padding: '8px',height:'30px'}}>
           
            </tr>
            <tr style={{border:'0.1px solid #efefef', padding: '8px',height:'30px'}}>
           
            </tr>
            <tr style={{border:'0.1px solid #efefef', padding: '8px',height:'30px'}}>
           
            </tr>
            <tr style={{border:'0.1px solid #efefef', padding: '8px',height:'30px'}}>
           
            </tr>
            <tr style={{border:'0.1px solid #efefef', padding: '8px',height:'30px'}}>
           
            </tr>
          </>
          }
        </tbody>
      </table>
      {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          height: 40,
          border:'1px solid #efefef',
          backgroundColor:'#ececec',
          alignItems: "center",
          width:'100%'
        }}
      >
        <div style={{ flex: "30%", flexDirection: "row" }}>
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </button>{" "}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </button>{" "}
        </div>

        <span style={{ marginRight: 50 }}>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>

        <select
          value={pageSize}
          style={{ marginRight: 20, border: "1px solid #e8e8e8" }}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}

export default CheckboxTable;
