import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Segment } from "semantic-ui-react";
import ModalView from "../commonViews/ModalView.jsx";
import IconPicker from "../commonViews/IconPicker.jsx";
import DiamlerKeyAudits from "./DiamlerKeyAudits.jsx";
import {
  fetchDiamlerKeyDealersAction,
  fetchDiamleKeyAuditReportAction,
  fetchVinsCountsAction,
} from "../../actions/diamler_key_action";
import TableView from "../commonViews/TableView.jsx";
import {

  getMatchingTint,
  getTextColor,
} from "../../ThemeHandlers/theme.js";

class DiamlerKey2 extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedDealer: {},
      isAuditsView: false,
      modalOpen: false,
      template: [],
      count: [],
      loading: false,
    };
  }
  componentDidMount() {
    this.setState({ loading: true });
    this.props.fetchDiamlerKeyDealers({
      id: this.props.template._id,
      onSuccess: () => {
        this.setState({ loading: false });
      },
      onFailure: () => {
        this.setState({ loading: false });
      },
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.DiamlerKey.dealers !== prevProps.DiamlerKey.dealers) {
      this.setState({ template: this.props.DiamlerKey.dealers });
    }
    if (this.props.DiamlerKey.counts !== prevProps.DiamlerKey.counts) {
      this.setState({ count: this.props.DiamlerKey.counts });
    }
  }
  handleTableViewAuditClick = (dealer) => {
    this.setState({ selectedDealer: dealer, isAuditsView: true });
  };
  handleAuditViewClose = () => {
    this.setState({ selectedAudit: null, isAuditView: false });
  };
  onGoBack = () => {
    this.setState({ isAuditsView: false });
  };
  handleDownloadAgentReportClick = (dealer) => {
    if (!dealer) {
      this.setState({ showReportDataNotFound: true });
      return;
    } else {
      this.props.downloadAuditReport(dealer);
    }
  };
  showCount = (data) => {
    this.props.fetchVinsCountsAction(data);

    this.setState({ modalOpen: true });
  };
  closeEditUser = () => {
    // this.props.clearUserAction();
    this.setState({ modalOpen: false });
  };
  render() {
    var dealers = this.state.template.length !== 0 ? this.state.template : [];
    var dealerCol = [
      {
        title: "DealerCode",
        field: "dealerCode",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.dealerCode}
            </span>
          );
        },
      },
      {
        title: "DealerName",
        field: "name",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.name}
            </span>
          );
        },
      },
      {
        title: "Verification",
        field: "",
        render: (rowData) => {
          if (rowData._id) {
            return (
              <IconPicker
                size={"large"}
                color={"red"}
                styles={{ cursor: "pointer" }}
                name={"calculator"}
                click={() => this.showCount(rowData._id)}
              />
            );
          }
        },
      },
      {
        title: "Report",
        field: "",
        render: (rowData) => {
          if (rowData._id) {
            return (
              <IconPicker
                size={"large"}
                styles={{
                  color: getTextColor(this.props.color),
                  cursor: "pointer",
                }}
                name={"file pdf outline"}
                click={() => this.handleDownloadAgentReportClick(rowData._id)}
              />
            );
          }
        },
      },
    ];

    return (
      <div style={{ flexGrow: 1, display: "flex", flexFlow: "column" }}>
        <ModalView
          open={this.state.modalOpen}
          close={this.closeEditUser}
          size={'mini'}
          content={
            <>
              {this.state.count != undefined && (
                <div>
                  <h1>Total Verified : {this.state.count.totalVerified}</h1>
                  <h1>Total Vehicles : {this.state.count.totalVehicles}</h1>
                </div>
              )}
            </>
          }
          showSave={false}
        />

        {!this.state.isAuditsView && (
          <div>
            <h1 style={{ color: getTextColor(this.props.color) }}>Dealers</h1>
            <div style={{ marginTop: "-10px" }}>
              <Segment
                onClick={this.props.onClose}
                style={{
                  backgroundColor: getMatchingTint(this.props.color),
                  float: "right",
                  cursor: "pointer",
                  marginTop: "-34px",
                  position: "absolute",
                  right: 10,
                }}
              >
                <IconPicker
                  name={"arrow left"}
                  styles={{ color: getTextColor(this.props.color) }}
                />
              </Segment>
            </div>
            <div>
              <div style={{ display: "flex", flexGrow: 1, flexFlow: "column" }}>
                <div style={{ marginTop: "40px" }}>
                  <TableView
                    data={dealers}
                    columns={dealerCol}
                    selection={false}
                    load={this.state.loading}
                  />
                  {/* <Table
                    data={dealers}
                    columns={columns}
                    rowClick={this.handleTableViewAuditClick}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        )}

        {this.state.isAuditsView && (
          <DiamlerKeyAudits
            dealer={this.state.selectedDealer}
            template={this.props.template}
            onClose={this.onGoBack}
            color={this.props.color}
          />
        )}
      </div>
    );
  }
}
function AuditTableCell(props) {
  function onClick() {
    props.onClick(props.row);
  }
  return (
    <div style={props.style} onClick={onClick}>
      {props.text}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    DiamlerKey: state.DiamlerKey,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchDiamlerKeyDealers: fetchDiamlerKeyDealersAction,
      downloadAuditReport: fetchDiamleKeyAuditReportAction,
      fetchVinsCountsAction: fetchVinsCountsAction,
    },
    dispatch
  );
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DiamlerKey2)
);
