import axios from "axios";
import * as FileSaver from "file-saver";
import * as types from "./types";

export const fetchDiamlerKeyTemplatesAction = ({
  year,
  onSuccess,
  onFailure,
}) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/keymanagement/templates?year=" + year)
      .then(function (response) {
        dispatch({
          type: types.FETCH_DIAMLER_KEY_TEMPLATE_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_DIAMLER_KEY_TEMPLATE_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const fetchDiamlerKeyDealersAction = ({ id, onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/keymanagement/dealers?templateId=" + id)
      .then(function (response) {
        dispatch({
          type: types.FETCH_DIAMLER_KEY_DEALERS_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_DIAMLER_KEY_DEALERS_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const fetchDiamlerKeyAuditsAction = ({ id, onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/keymanagement/audits?dealerId=" + id)
      .then(function (response) {
        dispatch({
          type: types.FETCH_DIAMLER_KEY_AUDITS_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_DIAMLER_KEY_AUDITS_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const fetchDiamlerKeyVehiclesAction = (id) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/keymanagement/vehicles?dealerId=" + id)
      .then(function (response) {
        dispatch({
          type: types.FETCH_DIAMLER_KEY_VEHICLES_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_DIAMLER_KEY_VEHICLES_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const saveDiamlerKeyAudit = (audit) => {
  return function (dispatch) {
    axios
      .post(types.API_URL + "/auditor/v1/keymanagement/saveAudits", audit)
      .then(function (response) {
        dispatch({
          type: types.SAVE_DIAMLER_KEY_AUDITS_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.SAVE_DIAMLER_KEY_AUDITS_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const fetchDiamleKeyAuditReportAction = (dealerId) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL + "/auditor/v1/report/keymanagement?dealerId=" + dealerId,
        {
          responseType: "blob",
        }
      )
      .then(function (response) {
        FileSaver.saveAs(response.data, "keymanagementAuditReport.xlsx");
        dispatch({
          type: types.DOWNLOAD_DIAMLER_KEY_REPORT_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.DOWNLOAD_DIAMLER_KEY_REPORT_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const fetchVinsCountsAction = (dealerId) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL + `/auditor/v1/keymanagement/count?dealerId=${dealerId}`
      )
      .then(function (response) {
        dispatch({
          type: types.FETCH_VINS_COUNTS_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_VINS_COUNTS_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const uploadDiamlerKeyPhotoAction = (data, details, toCheck) => {
  var xhr = new XMLHttpRequest();
  var all_pictures = [];
  var to_save = [];
  for (var k = 0; k < data.length; k++) {
    all_pictures.push(data[k].name);
  }
  details.files = all_pictures;
  return function (dispatch) {
    axios
      .post(
        types.API_URL + "/auditor/v1/storage/keyManagement/AddMultipleImages",
        details
      )
      .then(function (response) {
        var FileData = response.data.allData;
        for (var i = 0; i < FileData.length; i++) {
          var each_file = FileData[i];
          for (var j = 0; j < data.length; j++) {
            if (each_file.oldName === data[j].name) {
              var blob = data[i].slice(0, -1, data[j].type);
              var newFile = new File([blob], each_file.objectId, {
                type: data[j].type,
              });
              to_save.push({
                newName: each_file.objectId,
                oldName: each_file.oldName,
              });

              xhr.onreadystatechange = function () {};
              xhr.open("PUT", each_file.url, true);
              xhr.setRequestHeader("Content-Type", data[j].type);
              xhr.onload = () => {
                if (xhr.status === 200) {
                  to_save.push({ responseStatus: response.status });
                }
              };
              xhr.onerror = () => {
                console.log("error");
              };
              xhr.send(newFile);
            }
          }
        }
        console.log(to_save);
        dispatch({
          type: types.UPLOAD_DIAMLER_KEY_PHOTO_SUCCESS,
          payload: to_save,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.UPLOAD_DIAMLER_KEY_PHOTO_FAILURE,
          payload: error,
        });
        console.log(error);
      });
  };
};

export const showLoadingScreen = (toShow) => {
  return function (dispatch) {
    dispatch({
      type: types.SHOW_LOADING_SCREEN_KEY_MGMT,
      payload: toShow,
    });
  };
};

export const toCheckPhotos = (photos) => {
  return function (dispatch) {
    dispatch({
      type: types.TO_CHECK_PHOTOS,
      payload: photos,
    });
  };
};
