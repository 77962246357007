import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { isLoggedIn } from './../util';
import AgentDocumentDetail from './AgentDocumentDetail.jsx';
import { downloadAgentReportAction } from '../actions/audit_actions';
import { Icon, Divider } from 'semantic-ui-react';
import ConstantLabel from './commonViews/ConstantLabel.jsx'
import moment from 'moment';

class AgentAuditDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editMode: false,
            showLoading: false
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.audit) {
            this.audit = JSON.parse(JSON.stringify(nextProps.audit));
        }
    }

    componentWillMount() {
        if (this.props.audit) {
            this.audit = JSON.parse(JSON.stringify(this.props.audit));
        }
    }

    componentDidMount() {
        if (!isLoggedIn(this.props.auth)) {
            this.props.history.push(`/login`);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!isLoggedIn(this.props.auth)) {
            this.props.history.push(`/login`);
            return false;
        }
        return true;
    }

    onDataChange = (newAudit) => {
    }

    handleDownloadReportClick = (e) => {
        this.props.downloadReport(this.audit._id);
    }

    render() {
        var audit = this.audit.audit;

        if (!audit) {
            return <div></div>;
        }

        return (
            <div style={{ flexGrow: 1, padding: 15 }}>
                <Divider />
                <Icon style={{
                    color: "#808080",
                    display: 'none',
                    marginTop: 10,
                    float: "right", cursor: "pointer"
                }} onClick={this.props.onClose} size="large" name="mail" />

                <Icon style={{
                    color: "#808080",
                    marginTop: 10,
                    marginRight: 20, float: "right", cursor: "pointer"
                }} onClick={this.props.onClose} size="large" name="arrow left" />

                <div style={{ marginRight: 50 }}>
                    <h2 style={{ color: "#e67e22" }}>{audit.auditName} - <span style={{ color: "#757575" }}>{this.audit.name}</span></h2>
                    <i>by</i> <strong>{audit.auditor}</strong>&nbsp;&nbsp;<i>on</i>&nbsp;&nbsp;<strong>{moment(audit.createdAt).format('llll')}</strong><br />
                    {/* TODO Add send mail button here */}
                    {this.audit.agentId &&
                        <ConstantLabel
                            title={"Download Report"}
                            size={"medium"}
                            onClick={this.handleDownloadReportClick}
                            styles={{
                                cursor: "pointer",

                            }}
                            iconName={"download"}
                        />
                    }

                </div>
                <AgentDocumentDetail audit={audit} onDataChange={this.onDataChange} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        audits: state.audits
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        downloadReport: downloadAgentReportAction,
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AgentAuditDetail));