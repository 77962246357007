import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn } from "../../util";
import moment from "moment";
import { selectAuditsFromLeftPane } from "../../actions/pandg_actions.js";
import TableView from "../commonViews/TableView";
import Table from "../commonTables/Table.jsx";
import DatePicker from "../commonViews/DatePicker.jsx";

class PandGAudits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterCellDate: new Date(),
    };
  }

  componentDidMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
      return false;
    }
    return true;
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.audit) {
      this.audit = JSON.parse(JSON.stringify(nextProps.audit));
    }
  }

  componentWillMount() {
    if (this.props.audit) {
      this.audit = JSON.parse(JSON.stringify(this.props.audit));
    }
  }

  handleTableViewAuditClick = (audit) => {
    this.props.handleTableViewAuditClick({ audit });
    this.props.selectAuditsFromLeftPane(audit);
  };

  render() {
    var col = [
      {
        title: "Audit",
        field: "auditName",
        filtering: true,
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.auditName}
            </span>
          );
        },

      },
      {
        title: "Chassis",
        field: "chassis",
        filtering: true,
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.chassis}
            </span>
          );
        },

      },
      {
        title: "Location",
        field: "location",
        filtering: true,
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.location}
            </span>
          );
        },
        // customFilterAndSearch: (term, rowData) =>
        //   rowData.location.toLowerCase().includes(term.toLowerCase()),
      },
      {
        title: "Auditor",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.auditorName}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.auditorName.toLowerCase().includes(term.toLowerCase()),
      },

      {
        title: "Date",
        field: 'createdAt',
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {moment(rowData.createdAt).format('YYYY-MM-DD')}
            </span>
          );
        },
        filtering: true,
        // filterComponent: ({ columnDef, onFilterChanged }) => (
        //   <DatePicker
        //     value={this.state.filterCellDate}
        //     label={"date"}
        //     handleDateChange={(date) => {
        //       console.log(columnDef, moment(date).format('YYYY-MM-DD'), "defffff")
        //       this.setState({ filterCellDate: moment(date).toISOString()})
        //       onFilterChanged(
        //         columnDef.tableData.id,
        //         moment(date).format('YYYY-MM-DD')
        //       )
        //     }
        //     }
        //   />
        // ),
      },
    ];
    var columns = [
      {
        Header: "Audit",
        accessor: "auditName",
        style: { cursor: "pointer" },
        Cell: ({ row: { original } }) => {
          return <span>{original.auditName}</span>;
        },
        // Cell: row => (
        //     <AuditTableCell
        //         row={row.original}
        //         text={row.original.auditName}
        //         onClick={this.handleTableViewAuditClick}
        //     />
        // )
      },
      {
        Header: "Chassis",
        accessor: "chassis",
        style: { cursor: "pointer" },
        Cell: ({ row: { original } }) => {
          return <span>{original.chassis || "HTH"}</span>;
        },
        // Cell: row => (
        //     <AuditTableCell
        //         row={row.original}
        //         text={row.original.chassis || 'HTH'}
        //         onClick={this.handleTableViewAuditClick}
        //     />
        // )
      },
      {
        Header: "Location",
        accessor: (row) => row.location,
        width: 150,
        style: { textAlign: "center", cursor: "pointer" },
        Cell: ({ row: { original } }) => {
          return <span>{original.location}</span>;
        },
        // Cell: row => (
        //     <AuditTableCell
        //         row={row.original}
        //         text={row.original.location}
        //         onClick={this.handleTableViewAuditClick}

        //     />
        // )
      },
      {
        Header: "Auditor",
        accessor: "auditorName",
        style: { cursor: "pointer" },
        Cell: ({ row: { original } }) => {
          return <span>{original.auditorName}</span>;
        },
        // Cell: row => (
        //     <AuditTableCell
        //         row={row.original}
        //         text={row.original.auditorName}
        //         onClick={this.handleTableViewAuditClick}
        //     />
        // )
      },
      {
        Header: "Date",
        accessor: (row) => moment(row.createdAt).format("llll"),
        style: { cursor: "pointer" },
        Cell: ({ row: { original } }) => {
          return <span>{moment(original.createdAt).format("llll")}</span>;
        },
        // Cell: row => (
        //     <AuditTableCell
        //         row={row.original}
        //         text={moment(row.original.createdAt).format('llll')}
        //         onClick={this.handleTableViewAuditClick}
        //     />
        // )
      },
      {
        Header: "Status",
        accessor: "status",
        width: 150,
        style: { textAlign: "center", cursor: "pointer" },
        Cell: ({ row: { original } }) => {
          return (
            <span>
              {original.status === "draft" ? "live" : original.status}
            </span>
          );
        },
      },
    ];

    return (
      <div style={{ display: "flex", flexGrow: 1, flexFlow: "column" }}>
        <TableView
          data={this.props.pandgaudits}
          columns={col}
          selection={false}
        />

      </div>
    );
  }
}

function AuditTableCell(props) {
  function onClick() {
    props.onClick(props.row);
  }
  return (
    <div style={props.style} onClick={onClick}>
      {props.text}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    pandg: state.pandg,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      selectAuditsFromLeftPane: selectAuditsFromLeftPane,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PandGAudits)
);
