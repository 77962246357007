import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn } from "../../util";
import IconPicker from "../commonViews/IconPicker";
import DamagesAudits from "./DamagesAudits.jsx";
import TableView from "../commonViews/TableView";
import { getTextColor } from "../../ThemeHandlers/theme";
import DropDownPicker from "../commonViews/DropDownPicker";

class Damages extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      WholeTemplates: [],
      selectedTemplate: {},
      TemplatesView: true,
      isReportDownloading: true,
      NextPage: "",
      isClose: "",
      year: new Date().getFullYear(),
      templates: [],
      isLoading: false,
      color: "",
    };
  }

  componentDidMount() {
    let value = this.state.year;
    if (this.props.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
    this.setState({ isLoading: true });
    this.props.fetchTemplates({
      year: value,
      onSuccess: () => {
        this.setState({ isLoading: false });
      },
      onFailure: (result) => {
        this.setState({ isLoading: false });
      },
    });
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
      return;
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth.color !== prevProps.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
    if (this.props.damages.templates !== prevProps.damages.templates) {
      this.setState({ templates: this.props.damages.templates });
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ isReportDownloading: false });
  }

  TemplatesearchFilter = (filter, row) => {
    var flatData = JSON.stringify(row._original);
    return flatData.toLowerCase().includes(filter.value.toLowerCase());
  };

  TemplatehandleCloseClick = () => {
    this.setState({ TemplatesView: true, isClose: "close" });
  };

  TemplatehandleTableViewAuditClick = (template) => {
    if (!template._id) return;
    this.setState({
      selectedTemplate: template,
      TemplatesView: false,
    });
  };

  downloadReport = (template) => {
    this.setState({ isReportDownloading: true });
    this.props.downloadAuditReport(template._id);
  };

  onTemplateYearChange = (data) => {
    let value = data;
    this.setState({ isLoading: true });
    this.props.fetchTemplates({
      year: value,
      onSuccess: () => {
        this.setState({ isLoading: false });
      },
      onFailure: () => {
        this.setState({ isLoading: false });
      },
    });
    console.log(this.state.isLoading, "isloading");
    this.setState({ year: data, isReportDownloading: true });
  };

  render() {
    let Templates =
      this.state.templates.length !== 0 ? this.state.templates : [];

    var years = [
      {
        value: 2018,
        text: "2018",
        key: "2018",
      },
      {
        value: 2019,
        text: "2019",
        key: "2019",
      },
      {
        value: 2020,
        text: "2020",
        key: "2020",
      },
      {
        value: 2021,
        text: "2021",
        key: "2021",
      },
      {
        value: 2022,
        text: "2022",
        key: "2022",
      },
      {
        value: 2023,
        text: "2023",
        key: "2023",
      },
    ];

    if (this.props.auth.role !== 'viewer') {
      var col = [
        {
          title: "Template Name",
          field: "",
          render: (rowData) => {
            return (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.TemplatehandleTableViewAuditClick(rowData);
                }}
              >
                {rowData.auditName}
              </span>
            );
          },
          customFilterAndSearch: (term, rowData) =>
            rowData.auditName.toLowerCase().includes(term.toLowerCase()),
        },
        {
          title: "Live Audits",
          field: "",
          render: (rowData) => {
            return (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.TemplatehandleTableViewAuditClick(rowData);
                }}
              >
                {rowData.stats.draftAudits}
              </span>
            );
          },
          customFilterAndSearch: (term, rowData) =>
            rowData.stats.draftAudits == term,
        },
        {
          title: "Submitted Audits",
          field: "",
          render: (rowData) => {
            return (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.TemplatehandleTableViewAuditClick(rowData);
                }}
              >
                {rowData.stats.submittedAudits}
              </span>
            );
          },
          customFilterAndSearch: (term, rowData) =>
            rowData.stats.submittedAudits == term,
        },
        {
          title: "Total Audits",
          field: "",
          render: (rowData) => {
            return (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.TemplatehandleTableViewAuditClick(rowData);
                }}
              >
                {rowData.stats.totalAudits}
              </span>
            );
          },
          customFilterAndSearch: (term, rowData) =>
            rowData.stats.totalAudits == term,
        },

        {
          title: "Report",
          field: "",
          align: "right",
          render: (rowData) => {
            if (rowData._id) {
              return (
                <IconPicker
                  size={"large"}
                  styles={{ color: getTextColor(this.state.color), cursor: 'pointer' }}
                  name={"file excel outline"}
                  click={() => this.downloadReport(rowData)}
                />
              );
            }
          },
        },
      ];
    }
    else {
      var col = [
        {
          title: "Template Name",
          field: "",
          render: (rowData) => {
            return (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.TemplatehandleTableViewAuditClick(rowData);
                }}
              >
                {rowData.auditName}
              </span>
            );
          },
          customFilterAndSearch: (term, rowData) =>
            rowData.auditName.toLowerCase().includes(term.toLowerCase()),
        },
        {
          title: "Live Audits",
          field: "",
          render: (rowData) => {
            return (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.TemplatehandleTableViewAuditClick(rowData);
                }}
              >
                {rowData.stats.draftAudits}
              </span>
            );
          },
          customFilterAndSearch: (term, rowData) =>
            rowData.stats.draftAudits == term,
        },
        {
          title: "Submitted Audits",
          field: "",
          render: (rowData) => {
            return (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.TemplatehandleTableViewAuditClick(rowData);
                }}
              >
                {rowData.stats.submittedAudits}
              </span>
            );
          },
          customFilterAndSearch: (term, rowData) =>
            rowData.stats.submittedAudits == term,
        },
        {
          title: "Total Audits",
          field: "",
          render: (rowData) => {
            return (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.TemplatehandleTableViewAuditClick(rowData);
                }}
              >
                {rowData.stats.totalAudits}
              </span>
            );
          },
          customFilterAndSearch: (term, rowData) =>
            rowData.stats.totalAudits == term,
        }
      ];
    }



    return (
      <div style={{ flexGrow: 1, display: "flex", flexFlow: "column" }}>
        {this.state.TemplatesView && (
          <div>
            {/* <h1 style={{ paddingLeft: 30, flex: "0 0 30px" }}>Templates</h1> */}
            <div
              style={{
                display: "inline-block",
                marginBottom: 20,
              }}
            >
              <h1
                style={{
                  display: "inline-block",
                  color: getTextColor(this.state.color),
                }}
              >
                Templates
              </h1>
              {this.props.auth.role !== "viewer" && (
                <DropDownPicker
                  styles={{ zIndex: 100, width: 300 }}
                  placeholder={"Select year"}
                  options={years}
                  onChangeDropDown={(date) => {
                    this.onTemplateYearChange(date)
                  }}
                  value={this.state.year}
                />
              )}
            </div>
            <div style={{ display: "flex", flexGrow: 1, flexFlow: "column" }}>
              <div>
                <TableView
                  selection={false}
                  data={Templates}
                  columns={col}
                  load={this.state.isLoading}
                />
              </div>
            </div>
          </div>
        )}
        {!this.state.TemplatesView && (
          <DamagesAudits
            selectedTemplate={this.state.selectedTemplate}
            onClose={this.TemplatehandleCloseClick}
            apis={this.props.apis}
            name={this.props.name}
            color={this.state.color}
          />
        )}
      </div>
    );
  }
}

function AuditTableCell(props) {
  function onClick() {
    props.onClick(props.row);
  }
  return (
    <div style={props.style} onClick={onClick}>
      {props.text}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    damages: state.damages,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(
    {
      fetchTemplates: ownProps.apis.fetchTemplatesAction,
      downloadAuditReport: ownProps.apis.downloadReportAction,
      // fetchDamagesTemplates: fetchDamagesTemplatesAction,
      // downloadAuditReport: downloadDamagesReportAction
    },
    dispatch
  );
};

// Damages.PropTypes = {
//   dealers: PropTypes.array
// };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Damages)
);
