import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn } from "../../util";
import IconPicker from "../commonViews/IconPicker";
import {
  fetchMrRetailTemplatesAction,
  fetchMrRetailStoreAction,
  downloadReportAction,
} from "../../actions/mrRetail_action";
import MrRetailAudits from "./MrRetailAudits.jsx";
import Table from "../commonTables/Table.jsx";
import MailPopup from "../MailPopup.jsx";
import TableView from "../commonViews/TableView";
import {
  getTextColor,
  getMainBackground,
  getMatchingTint,
  getFontColor,
} from "../../ThemeHandlers/theme";

export class MrRetialIndex extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedTemplate: {},
      isTemplatesView: true,
      isReportDownloading: false,
      isMailPopupView: false,
      templateId: "",
      template: [],
      color: "",
      loading: false,
    };
  }

  componentDidMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
      return;
    }
    if (this.props.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
    this.setState({ loading: true });
    this.props.fetchMrRetailTemplates({
      onSuccess: () => {
        this.setState({ loading: false });
      },
      onFailure: () => {
        this.setState({ loading: false });
      },
    });
    if (this.props.mrRetail.templates != undefined) {
      this.props.mrRetail.templates.map((e) => {
        this.setState({ template: e._id });
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ isReportDownloading: false });
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth.color !== prevProps.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
    if (this.props.mrRetail.templates !== prevProps.mrRetail.templates) {
      this.setState({ template: this.props.mrRetail.templates });
    }
  }
  searchFilter = (filter, row) => {
    var flatData = JSON.stringify(row._original);
    return flatData.toLowerCase().includes(filter.value.toLowerCase());
  };

  handleCloseClick = () => {
    this.setState({ isTemplatesView: true });
  };

  handleTableViewAuditClick = (template) => {
    if (!template._id) return;
    this.props.fetchMrRetailStore(template._id);
    this.setState({ selectedTemplate: template, isTemplatesView: false });
  };
  handleChangeMail = (mail) => {
    if (this.state.templateId !== "") {
      this.props.downloadReportAction(this.state.templateId, mail);
    }
  };

  handleSendMailReportClick = (id) => {
    this.setState({
      isMailPopupView: !this.state.isMailPopupView,
      templateId: id,
    });
  };

  handleMailReportClick = (e) => {
    this.setState({ isMailPopupView: !this.state.isMailPopupView });
  };

  render() {
    var col = [
      {
        title: "Template Name",
        field: "auditName",
        render: (rowData) => (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.handleTableViewAuditClick(rowData);
            }}
          >
            {rowData.auditName}
          </span>
        ),
      },
      {
        title: "Report",
        field: "",
        align: "center",
        render: (rowData) => {
          if (rowData._id) {
            return (
              <IconPicker
                size={"large"}
                name={"file pdf outline"}
                styles={{ color: "red", cursor: "pointer" }}
                click={() => this.handleSendMailReportClick(rowData._id)}
              />
            );
          }
        },
      },
    ];

    return (
      <div style={{ flexGrow: 1, display: "flex", flexFlow: "column" }}>
        {this.state.isTemplatesView && (
          <div>
            <h1 style={{ color: getTextColor(this.state.color) }}>Templates</h1>
            <TableView
              selection={false}
              columns={col}
              data={
                Array.isArray(this.state.template) === true
                  ? this.state.template
                  : []
              }
              load={this.state.loading}
            />
          </div>
        )}
        {this.state.isMailPopupView ? (
          <MailPopup
            open={this.state.isMailPopupView}
            mailIds={this.state.mailIds}
            closePopup={this.handleMailReportClick.bind(this)}
            sendMailAndClosePopup={this.handleChangeMail}
            getColor={getMainBackground}
            color={this.state.color}
            getCellColor={getMatchingTint}
          />
        ) : null}
        {!this.state.isTemplatesView && (
          <div style={{ flexGrow: 1, display: "flex" }}>
            <MrRetailAudits
              template={this.state.selectedTemplate}
              onClose={this.handleCloseClick}
              color={this.state.color}
            />
          </div>
        )}
      </div>
    );
  }
}

function AuditTableCell(props) {
  function onClick() {
    props.onClick(props.row);
  }
  return (
    <div style={props.style} onClick={onClick}>
      {props.text}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    mrRetail: state.mrRetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchMrRetailTemplates: fetchMrRetailTemplatesAction,
      fetchMrRetailStore: fetchMrRetailStoreAction,
      downloadReportAction: downloadReportAction,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MrRetialIndex)
);
