import "semantic-ui-css/semantic.min.css";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Login from "./components/Login.jsx";
import ControlPanel from "./components/ControlPanel.jsx";
import Home from "./components/Home.jsx";
import ShowImages from "./components/ShowImages.jsx";
import LoginEmail from "./components/LoginEmail.jsx";
import AuditDetail from "./components/Tanishq/AuditDetail.jsx";
import ErrorBoundary from "./components/ErrorBoundary.jsx";
import ShowSinglePhoto from "./components/ShowSinglePhoto.jsx";
import { SemanticToastContainer } from "react-semantic-toasts";
import { HashRouter, Route } from "react-router-dom";
import Throttle from "lodash/throttle";
import { saveState, loadState } from "./util";
import { createStore, applyMiddleware, compose } from "redux";
import reducers from "./reducers/index";

import { Provider } from "react-redux";
import reduxThunk from "redux-thunk";
import reportWebVitals from "./reportWebVitals";
require("./styles/main.css");

const persistedState = loadState();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers,
  persistedState,
  composeEnhancers(applyMiddleware(reduxThunk))
);

store.subscribe(
  Throttle(() => {
    saveState({
      auth: store.getState().auth,
      home: store.getState().home,
      time: store.getState().time,
    });
  }, 1000)
);

const Entry = () => (
  <div style={{ height: "100%" }}>
    <SemanticToastContainer />
    <Route path="/" exact component={Home} />
    <Route exact path="/home" component={Home} />
    <Route path="/login" component={Login} />
    <Route path="/controlpanel" component={ControlPanel} />
    <Route path="/audits/:auditId" component={AuditDetail} />
    <Route path="/images/:auditId" component={ShowImages} />
    <Route path="/forgotpassword" component={LoginEmail} />
    <Route path="/showSinglePhoto" component={ShowSinglePhoto} />
  </div>
);

ReactDOM.render(
  <Provider store={store}>
    <HashRouter>
      <Entry />
    </HashRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
