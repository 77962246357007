import {
    FETCH_NATURALS_AUDITS_FAILURE_ACTION,
    FETCH_NATURALS_AUDITS_SUCCESS_ACTION,
    FETCH_NATURALS_AUDIT_IMAGES_SUCCESS_ACTION,
    FETCH_NATURALS_AUDIT_IMAGES_FAILURE_ACTION,
    UPDATE_NATURALS_AUDIT_SUCCESS_ACTION,
    UPDATE_NATURALS_AUDIT_FAILURE_ACTION,
    DOWNLOAD_NATURALS_REPORT_SUCCESS_ACTION,
    DOWNLOAD_NATURALS_REPORT_FAILURE_ACTION,
    MAIL_NATURALS_REPORT_FAILURE_ACTION,
    MAIL_NATURALS_REPORT_SUCCESS_ACTION,
    ALL_READY_FETCHED_NATURALS_AUDITS_ACTION,
    ALL_READY_SEARCHED_NATURALS_AUDITS_ACTION,
    SEARCH_NATURALS_AUDITS_SUCCESS_ACTION,
    CLEAR_SEARCHED_NATURALS_AUDITS_ACTION,
    
  } from "../actions/types";

  import findIndex from "lodash/findIndex";
 
  
  var fileDownload = require("js-file-download");
  
  const INIT_STATE = {
    audits: {},
    users: [],
    isSearchResult: false,
    appliedFilters: {},
    searchResults: {
      audits: {}
    },
    pageLimit: 30,
    activePageKey: "",
    totalPages: null
  };
  
  export default function (state = INIT_STATE,action) {
    switch (action.type) {
      case FETCH_NATURALS_AUDITS_SUCCESS_ACTION:
        let objAssign = Object.assign({}, state, {
          users: action.payload.users,
          orgs: action.payload.orgs,
          audits: Object.assign({}, state.audits, {
            [action.navigation.activePageKey]: {
              data: action.payload.audits,
              pagination: action.payload.pagination
            }
          }),
          isSearchResult: false,
          fetchAuditsError: false,
          searchResults: {
            audits: {}
          },
          appliedFilters: {},
          activePageKey: action.navigation.activePageKey,
          totalPages: Math.round(
            action.payload.pagination.totalItems / state.pageLimit
          )
        });
        return objAssign;
  
      case SEARCH_NATURALS_AUDITS_SUCCESS_ACTION:
        return Object.assign({}, state, {
          users: action.payload.users,
          orgs: action.payload.orgs,
          isSearchResult: true,
          appliedFilters: action.appliedFilters
            ? action.appliedFilters
            : state.appliedFilters,
          searchResults: Object.assign({}, state.searchResults, {
            activePageKey: action.navigation.activePageKey,
            totalPages: Math.round(
              action.payload.pagination.totalItems / state.pageLimit
            ),
            audits: Object.assign({}, state.searchResults.audits, {
              [action.navigation.activePageKey]: {
                data: action.payload.audits,
                pagination: action.payload.pagination
              }
            })
          })
        });
  
      case ALL_READY_FETCHED_NATURALS_AUDITS_ACTION:
        return Object.assign({}, state, {
          isSearchResult: false,
          searchResults: {
            audits: {}
          },
          appliedFilters: {},
          activePageKey: action.navigation.activePageKey
        });
  
      case ALL_READY_SEARCHED_NATURALS_AUDITS_ACTION:
        return Object.assign({}, state, {
          isSearchResult: true,
          appliedFilters: action.appliedFilters
            ? action.appliedFilters
            : state.appliedFilters,
          searchResults: Object.assign({}, state.searchResults, {
            activePageKey: action.navigation.activePageKey
          })
        });
  
      case FETCH_NATURALS_AUDITS_FAILURE_ACTION:
        return Object.assign({}, state, {
          fetchAuditsError: true
        });
  
      case CLEAR_SEARCHED_NATURALS_AUDITS_ACTION:
        return Object.assign({}, state, {
          isSearchResult: false,
          appliedFilters: {},
          searchResults: {
            audits: {}
          }
        });

  
      case FETCH_NATURALS_AUDIT_IMAGES_SUCCESS_ACTION:
        return Object.assign({}, state, {
          images: action.payload.questions,
          images_name: action.payload.name
        });
  
      case FETCH_NATURALS_AUDIT_IMAGES_FAILURE_ACTION:
        return Object.assign({}, state, {
          fetchImagesError: true
        });
  
      case UPDATE_NATURALS_AUDIT_SUCCESS_ACTION:
        var index = findIndex(state.dynamicAudits, {
          _id: action.payload.audit._id
        });
  
        state.dynamicAudits[index] = Object.assign(
          {},
          state.dynamicAudits[index],
          action.payload.audit
        );
  
        return Object.assign({}, state, {
          dynamicAudits: state.dynamicAudits,
          updatedAudit: action.payload.audit,
          updateAuditError: false
        });
  
      case UPDATE_NATURALS_AUDIT_FAILURE_ACTION:
        return Object.assign({}, state, {
          updatedAudit: null,
          updateAuditError: true
        });
  
      case DOWNLOAD_NATURALS_REPORT_SUCCESS_ACTION:
        var buff = new Buffer(action.payload, "base64");
        var text = buff.toString("utf-8");
  
        fileDownload(text, "report.xlsx");
  
        return Object.assign({}, state, {
          downloadSuccess: true
        });
  
      case DOWNLOAD_NATURALS_REPORT_FAILURE_ACTION:
        return Object.assign({}, state, {
          downloadSuccess: false
        });
  
  
  
      case MAIL_NATURALS_REPORT_SUCCESS_ACTION:
        return Object.assign({}, state, {
          mailSentStatus: action.payload.sent_success
        });
  
      case MAIL_NATURALS_REPORT_FAILURE_ACTION:
        return Object.assign({}, state, {
          mailSentStatus: false
        });
  
    }
  
    return state;
  }
  