import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import FurlencoCustomer from "./FurlencoCustomer.jsx";
import TableView from "../commonViews/TableView";
import { getTextColor } from "../../ThemeHandlers/theme";
import { fetchFurlencoTemplatesAction } from "../../actions/furlenco_actions";
export class Furlenco extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTemplate: {},
      isTemplatesView: true,
      templates: [],
      color: "",
      loading: false,
    };
  }
  componentDidMount() {
    this.setState({ loading: true });
    if (this.props.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
    this.props.fetchFurlencoTemplates({
      onSuccess: () => {
        this.setState({ loading: false });
      },
      onFailure: () => {
        this.setState({ loading: false });
      },
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.auth.color !== prevProps.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
    if (this.props.Furlenco.templates !== prevProps.Furlenco.templates) {
      this.setState({
        templates: this.props.Furlenco.templates,
      });
    }
  }
  handleTableViewAuditClick = (template) => {
    this.setState({ selectedTemplate: template, isTemplatesView: false });
  };
  handleCloseClick = () => {
    this.setState({ isTemplatesView: true });
  };

  render() {
    var template =
      this.state.templates.length !== 0 ? this.state.templates : [];
    var col = [
      {
        title: "Template Name",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.auditName}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          term !== ""
            ? rowData.auditName.toLowerCase().indexOf(term.toLowerCase()) > -1
            : undefined,
      },
    ];

    return (
      <div style={{ display: "flex", flexGrow: 1, flexFlow: "column" }}>
        {this.state.isTemplatesView && (
          <div>
            <h1 style={{ color: getTextColor(this.state.color) }}>Templates</h1>
            <TableView
              data={template}
              columns={col}
              selection={false}
              load={this.state.loading}
            />
          </div>
        )}
        {!this.state.isTemplatesView && (
          <div style={{ flexGrow: 1, display: "flex" }}>
            <FurlencoCustomer
              template={this.state.selectedTemplate}
              onClose={this.handleCloseClick}
              startDate={this.props.Furlenco.templates.startDate}
              endDate={this.props.Furlenco.templates.endDate}
              color={this.state.color}
            />
          </div>
        )}
      </div>
    );
  }
}
function AuditTableCell(props) {
  function onClick() {
    props.onClick(props.row);
  }
  return (
    <div style={props.style} onClick={onClick}>
      {props.text}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    Furlenco: state.Furlenco,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchFurlencoTemplates: fetchFurlencoTemplatesAction,
    },
    dispatch
  );
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Furlenco)
);
