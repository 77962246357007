import React from "react";
import { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn } from "../../util";
import { Icon, Segment, Grid } from "semantic-ui-react";
import IconPicker from "../commonViews/IconPicker";
import { getJandJDealers, getJandJAudit } from "../../actions/jandj_actions.js";
import JandJAuditView from "./JandJAuditView.jsx";
import TableView from "../commonViews/TableView";
import { getTextColor, getMatchingTint } from "../../ThemeHandlers/theme";

export class JandJDealersView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTableView: true,
      selectedDealer: "",
      dealersData: [],
      loading: false,
    };
  }

  componentDidMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
      return;
    }
    this.setState({ loading: true });

    this.props.getJandJDealers({
      templateId: this.props.template._id,
      onSuccess: () => {
        this.setState({ loading: false });
      },
      onFailure: () => {
        this.setState({ loading: false });
      },
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.jandj.dealers !== prevProps.jandj.dealers) {
      this.setState({ dealersData: this.props.jandj.dealers });
    }
  }

  searchFilter = (filter, row) => {
    var flatData = JSON.stringify(row._original);
    return flatData.toLowerCase().includes(filter.value.toLowerCase());
  };

  handleTableViewAuditClick = (dealer) => {
    this.setState({ selectedDealer: dealer });
    this.props.getJandJAudit(dealer._id, {
      onSuccess: () => {
        this.setState({ isTableView: false })
      }, onFailure: (error) => {
        alert('No Audits Found')
      }
    })
  };

  CloseClick = () => {
    this.setState({ isTableView: true });
    this.setState({ selectedDealer: "" });
  };

  render() {
    var dealers =
      this.state.dealersData.length !== 0 ? this.state.dealersData : [];
    var col = [
      {
        title: "Name",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.name}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.name.toLowerCase().includes(term.toLowerCase()),
      },
      {
        title: "Salvage Number",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.salvageNumber}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.salvageNumber.toLowerCase().includes(term.toLowerCase()),
      },
      {
        title: "ABI",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.abi}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.abi.toLowerCase().includes(term.toLowerCase()),
      },
      {
        title: "State",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.state}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.state.toLowerCase().includes(term.toLowerCase()),
      },
      {
        title: "Address",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.address}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.address.toLowerCase().includes(term.toLowerCase()),
      },
      {
        title: "Claim Month",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.claimMonth}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.claimMonth.toLowerCase().includes(term.toLowerCase()),
      },
      {
        title: "RDS Code",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.rdsCode}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) =>
          rowData.rdsCode.toLowerCase().includes(term.toLowerCase()),
      },
    ];

    return (
      <div style={{ flexGrow: 1, display: "flex", flexFlow: "column" }}>
        {this.state.isTableView && (
          <Grid style={{ paddingLeft: 10, marginTop: 5, marginBottom: "10px" }}>
            <Grid.Row columns={2}>
              <Grid.Column>
                <h1
                  style={{
                    marginTop: 5,
                    flex: "0 0 30px",
                    width: "100%",
                    color: getTextColor(this.props.color),
                  }}
                >
                  Johnson & Johnson - Dealers
                </h1>
              </Grid.Column>
              <Grid.Column>
                <IconPicker
                  styles={{
                    color: "#808080",
                    marginTop: 10,
                    marginRight: 30,
                    float: "right",
                    cursor: "pointer",
                  }}
                  click={this.props.onClose}
                  size={"large"}
                  name={"arrow left"}
                />
              </Grid.Column>
              {/* </div> */}
            </Grid.Row>
          </Grid>
        )}
        {this.state.isTableView && (
          <div>
            <div style={{ marginTop: "20px", marginBottom: "10px" }}>
              <div style={{ display: "flex", flexGrow: 1, flexFlow: "column" }}>
                <div>
                  <TableView
                    data={dealers}
                    columns={col}
                    selection={false}
                    load={this.state.loading}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {!this.state.isTableView && (
          <div style={{ marginTop: "20px", marginLeft: "20px" }}>
            <Segment.Group>
              <Segment
                style={{ backgroundColor: getMatchingTint(this.props.color) }}
              >
                <JandJAuditView
                  dealer={this.state.selectedDealer}
                  onClose={this.CloseClick}
                  color={this.props.color}
                />
              </Segment>
            </Segment.Group>
          </div>
        )}
      </div>
    );
  }
}

function AuditTableCell(props) {
  function onClick() {
    props.onClick(props.row);
  }
  return (
    <div style={props.style} onClick={onClick}>
      {props.text}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    jandj: state.jandj,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getJandJDealers,
      getJandJAudit
    },
    dispatch
  );
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(JandJDealersView)
);
