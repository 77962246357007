import React from "react";
import { Label, Icon } from "semantic-ui-react";

const ConstantLabel = (props) => {
    return (
        <Label
            as={props.as !== undefined ? props.as : null}
            size={props.size !== undefined ? props.size : "large"}
            onClick={props.onClick !== undefined ? props.onClick : null}
            style={props.styles}
            color={props.uniqueColor !== undefined && props.uniqueColor}

        >
            {props.iconName !== undefined && <Icon name={props.iconName} />}
            {props.title}
        </Label>
    );
};

export default ConstantLabel;
