import axios from "axios";
import * as types from "./types";
import * as FileSaver from "file-saver";

export const fetchAtmTemplatesAction = () => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/atm/getTemplate")
      .then(function (response) {
        dispatch({
          type: types.FETCH_ATM_TEMPLATE_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_ATM_TEMPLATE_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const fetchAtmStoresAction = (templateId, { onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL + `/auditor/v1/atm/getAllMaster?templateId=${templateId}`
      )
      .then(function (response) {
        dispatch({
          type: types.FETCH_ATM_STORE_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_ATM_STORE_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const fetchAuditorsDetailAction = () => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/atm/getAtmUsers")
      .then(function (response) {
        dispatch({
          type: types.FETCH_ATM_AUDITS_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_ATM_AUDITS_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const filteratm = (templateId, state, city, status) => {
  return function (dispatch) {
    axios
      .post(types.API_URL + "/auditor/v1/atm/filter", {
        templateId: templateId,
        state: state,
        city: city,
        status: status,
      })
      .then(function (response) {
        dispatch({
          type: types.FILTER_ATM_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.FILTER_ATM_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const AssignAtmAudits = (
  locationId,
  userId,
  { onSuccess, onFailure }
) => {
  return function (dispatch) {
    axios
      .post(types.API_URL + "/auditor/v1/atm/assignAudits", {
        locationId: locationId,
        userId: userId,
      })
      .then(function (response) {
        dispatch({
          type: types.ASSIGN_ATM_AUDITS_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.ASSIGN_ATM_AUDITS_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const fetchQuesAudits = (locationId) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + `/auditor/v1/atm/getAudit?locationId=${locationId}`)
      .then(function (response) {
        dispatch({
          type: types.FETCH_ATM_PRODUCTS_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_ATM_PRODUCTS_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const SaveAudits = (audit) => {
  return function (dispatch) {
    axios
      .post(types.API_URL + `/auditor/v1/atm/saveAudits`, audit)
      .then(function (response) {
        alert("Data Updated Successfully");
        dispatch({
          type: types.SAVE_ATM_AUDITS_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.SAVE_ATM_AUDITS_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const downloadAtmReportAction = (templateId) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL +
          `/auditor/v1/atm/generateConsolidatedReport?templateId=${templateId}`,
        {
          responseType: "blob",
        }
      )
      .then(function (response) {
        FileSaver.saveAs(response.data, "AtmReport.xlsx");
        dispatch({
          type: types.DOWNLOAD_ATMREPORT_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.DOWNLOAD_ATMREPORT_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const downloadAtmAuditReportAction = (
  auditId,
  locationCode,
  locationName
) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL + `/auditor/v1/atm/individualReport?auditId=${auditId}`,
        {
          responseType: "blob",
        }
      )
      .then(function (response) {
        FileSaver.saveAs(response.data, `${locationCode}-${locationName}.pdf`);
        dispatch({
          type: types.DOWNLOAD_ATMAUDITS_REPORT_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.DOWNLOAD_ATMAUDITS_REPORT_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const mailReportAction = (auditId, mailIds) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL +
          `/auditor/v1/atm/sendMail?auditId=${auditId}&mailIds=${mailIds}`
      )
      .then(function (response) {
        dispatch({
          type: types.SEND_ATMAUDITS_MAIL_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.SEND_ATMAUDITS_MAIL_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const downloadAtmAuditPhotosAction = (
  auditId,
  locationCode,
  locationName
) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + `/auditor/v1/atm/zipPhotos?auditId=${auditId}`, {
        responseType: "blob",
      })
      .then(function (response) {
        FileSaver.saveAs(response.data, `${locationCode}-${locationName}.zip`);
        dispatch({
          type: types.DOWNLOAD_ATMAUDITS_PHOTOS_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.DOWNLOAD_ATMAUDITS_PHOTOS_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const uploadAtmPhotos = (
  photo,
  locationCode,
  { onSuccess, onFailure }
) => {
  let file = new FormData();
  file.append("photo", photo);
  return function (dispatch) {
    axios
      .post(
        types.API_URL +
          `/auditor/v1/upload/atmPhoto?locationCode=${locationCode}`,
        file
      )
      .then(function (response) {
        dispatch({
          type: types.UPLOAD_ATM_PHOTO_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response);
      })
      .catch(function (error) {
        dispatch({
          type: types.UPLOAD_ATM_PHOTO_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};
