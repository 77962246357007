import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn } from "./../../util";
import {
  volkswagenUploadUserCsv,
  dismissLogs,
  initiateUpload,
  volkswagenUploadDealerCsv,
  volkswagenUploadVehicleCsv,
} from "../../actions/control_panel_action";
import CommonPanel from "./commonPanel.jsx";

class VolkswagenControlPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      volksuserCsvFile: null,
      volksdealersCsvFile: null,
      volksvehicleCsvFile: null,
    };
  }

  componentWillMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!isLoggedIn(nextProps.auth)) {
      this.props.history.push(`/login`);
      return false;
    }
    return true;
  }

  volkswagenhandleUserFileUpload = (files) => {
    const file = files.target.files[0];
    this.setState({ volksuserCsvFile: file });
    files.target.files = null;
  };

  volkswagenhandleDealerFileUpload = (files) => {
    const file = files.target.files[0];
    this.setState({ volksdealersCsvFile: file });
    files.target.files = null;
  };

  volkswagenhandleVehicleFileUpload = (files) => {
    const file = files.target.files[0];
    this.setState({ volksvehicleCsvFile: file });
    files.target.files = null;
  };

  volkswagenuploadUserData = () => {
    this.props.volkswageninitiateUpload(true);
    this.props.volkswagenuploadUserCsv(this.state.volksuserCsvFile);
  };

  volkswagenuploadDealerData = () => {
    this.props.volkswageninitiateUpload(true);
    this.props.volkswagenuploadDealerCsv(this.state.volksdealersCsvFile);
  };

  volkswagenuploadVehicleData = () => {
    this.props.volkswageninitiateUpload(true);
    this.props.volkswagenuploadVehicleCsv(this.state.volksvehicleCsvFile);
  };

  volkswagencloseLog = () => {
    this.props.volkswagendismissLogs(false);
  };

  render() {
    return (
      <CommonPanel
        controlPanel={this.props.controlPanel}
        handleUserFileUpload={this.volkswagenhandleUserFileUpload}
        uploadUserData={this.volkswagenuploadUserData}
        handleDealerFileUpload={this.volkswagenhandleDealerFileUpload}
        uploadDealerData={this.volkswagenuploadDealerData}
        handleVehicleFileUpload={this.volkswagenhandleVehicleFileUpload}
        uploadVehicleData={this.volkswagenuploadVehicleData}
        closeLog={this.volkswagencloseLog}
        color={this.props.color}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    home: state.home,
    controlPanel: state.controlPanel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      volkswagenuploadUserCsv: volkswagenUploadUserCsv,
      volkswagenuploadDealerCsv: volkswagenUploadDealerCsv,
      volkswagenuploadVehicleCsv: volkswagenUploadVehicleCsv,
      volkswageninitiateUpload: initiateUpload,
      volkswagendismissLogs: dismissLogs,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VolkswagenControlPanel)
);
