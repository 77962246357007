import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Table from "../commonTables/Table";
import {
  Icon,
  Segment,
  Grid,
  Dropdown,
  Button,
  Modal,
} from "semantic-ui-react";
import {
  setSelectedMasterData,
  fetchExistingAuditsIfPresent,
  clearFetchedAudits,
  downloadHershysChecklistAuditReportAction,
  AssignAuditsAction,
  fetchHershysCheckMasterAction,
} from "../../actions/HershysCheckAction";
// import { AssignAuditsAction } from "../../actions/hersheys_actions";
import Audits from "./Audits";
import CheckboxforAssign from "../commonTables/CheckboxforAssign";
import LoadingScreen from "../Helper/Loader";
import CheckboxTable from "../commonTables/CheckboxView";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import TableView from "../commonViews/TableView";

const Master = (props) => {
  const [master, setMaster] = useState([]);
  const [isMasterView, setIsMasterView] = useState(true);
  const [template, setTemplate] = useState({});

  const [loading, setIsLoading] = useState(false);
  const [selectedMaster, setSelectedMaster] = useState({});
  const [LoaderText, setLoaderText] = useState("");
  const [cityOpt, setCityOpt] = useState([]);
  const [officeOpt, setOfficeOpt] = useState([]);
  const [Region, setRegion] = useState("");
  const [City, setCity] = useState("");
  const [office, setOffice] = useState("");
  const [filtered, setFiltered] = useState([]);
  const [checked, setChecked] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    setTemplate(props.hershysCheck.selectedTemplate);
  }, []);
  useEffect(() => {
    if (props.hershysCheck.master !== undefined) {
      setMaster(props.hershysCheck.master);
    }
  }, [props.hershysCheck]);

  const removeDup = (arr, filterBy) => {
    let newArray = [];

    // Declare an empty object
    let uniqueObject = {};

    // Loop for the array elements
    for (let i in arr) {
      // Extract the title
      var objTitle = arr[i]["text"];

      // Use the title as the index
      uniqueObject[objTitle] = arr[i];
    }

    // Loop to push unique object into array
    for (let i in uniqueObject) {
      newArray.push(uniqueObject[i]);
    }

    // Display the unique objects
    return newArray;
  };

  useEffect(() => {
    let options = [];
    let filteredMaster = [];
    options.push({
      key: "",
      text: "",
      value: "",
    });
    if (master.length !== 0) {
      master.map((data, index) => {
        if (data.region === Region) {
          options.push({
            key: index,
            text: data.city,
            value: data.city,
          });
          filteredMaster.push(data);
        }
      });
    }
    setFiltered([...filteredMaster]);
    setCityOpt(removeDup(options));
  }, [Region]);
  useEffect(() => {
    let options = [];
    let filteredMaster = [];
    options.push({
      key: "",
      text: "",
      value: "",
    });

    if (master.length !== 0) {
      master.map((data, index) => {
        if (data.city === City) {
          options.push({
            key: index,
            text: data.matrixOffice,
            value: data.matrixOffice,
          });
          filteredMaster.push(data);
        }
      });
    }
    setFiltered([...filteredMaster]);

    setOfficeOpt(removeDup(options));
  }, [City]);

  const sendedData = (rowData, row) => {
    let check = [];
    if (row.status === "In Progress") {
      alert("Can't assign audited data to someone");
    } else {
      if (rowData.length !== 0) {
        rowData.forEach((data) => {
          check.push(data._id);
        });
      }
    }
    setChecked([...check]);
  };
  const handleTableViewAuditClick = (selectedMaster) => {
    if (selectedMaster.status === "Initial") {
      alert("Audit Not Assigned to any User.");
    } else {
      setIsLoading(true);
      setLoaderText(`Checking For Existing Audits`);
      props.fetchExistingAuditsIfPresent({
        masterId: selectedMaster._id,
        onSuccess: () => {
          setIsLoading(false);
          setLoaderText("");
          props.setSelectedMasterData(selectedMaster);
          setSelectedMaster(selectedMaster);
          setIsMasterView(false);
        },
        onFailure: () => {
          setLoaderText("");
          setIsLoading(false);
        },
      });
    }
  };
  const back = () => {
    props.clearFetchedAudits();

    setIsMasterView(true);
  };
  const downloadReport = (id, name) => {
    setLoaderText(`Downloading - ${name} Report for ${template.auditName} `);
    setIsLoading(true);
    props.downloadHershysChecklistAuditReportAction({
      id: id,
      name: name,
      onSuccess: () => {
        setLoaderText(``);
        setIsLoading(false);
      },
      onFailure: () => {
        setLoaderText(``);
        setIsLoading(false);
      },
    });
  };
  var col = [
    {
      title: "Region",
      field: "region",
    },
    {
      title: "City",
      field: "city",
    },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "Matrix Office",
      field: "matrixOffice",
    },
    {
      title: "Assignee",
      field: "userName",
    },
    {
      title: "View",
      field: "",
      render: (rowData) => {
        if (rowData._id) {
          return (
            <Icon
              size="large"
              color="green"
              name="envelope open outline"
              onClick={() => handleTableViewAuditClick(rowData)}
            />
          );
        }
      },
    },
    {
      field: "",
      title: "Report",
      render: (rowData) => {
        if (rowData._id) {
          return (
            <Icon
              size="large"
              color="green"
              name="file pdf outline"
              onClick={() =>
                downloadReport(rowData._id, rowData.distributorName)
              }
            />
          );
        }
      },
    },
  ];
  const columns = [
    {
      Header: "Region",
      accessor: "region",
      style: { textAlign: "center", cursor: "pointer" },
      Cell: ({ row: { original } }) => {
        return <span>{original.region}</span>;
      },
    },
    {
      Header: "City",
      accessor: "city",
      style: { textAlign: "center", cursor: "pointer" },
      Cell: ({ row: { original } }) => {
        return <span>{original.city}</span>;
      },
    },

    {
      Header: "Status",
      accessor: "status",
      style: { textAlign: "center", cursor: "pointer" },
      Cell: ({ row: { original } }) => {
        return <span>{original.status}</span>;
      },
    },
    {
      Header: "Matrix Office",
      accessor: "matrixOffice",
      style: { textAlign: "center", cursor: "pointer" },
      Cell: ({ row: { original } }) => {
        return <span>{original.matrixOffice}</span>;
      },
    },
    {
      Header: "Assignee",
      accessor: "userName",
      style: { textAlign: "center", cursor: "pointer" },
      Cell: ({ row: { original } }) => {
        return <span>{original.userName}</span>;
      },
    },
    // {
    //   Header: "Distributor Name",
    //   accessor: "distributorName",
    //   style: { textAlign: "center", cursor: "pointer" },
    //   Cell: ({ row: { original } }) => {
    //     return <span>{original.distributorName}</span>;
    //   },
    // },

    {
      Header: "Report",
      accessor: "reportUrl",
      width: 150,
      style: { textAlign: "center", cursor: "pointer" },
      Cell: ({ row: { original } }) => {
        if (original._id) {
          return (
            <Icon
              size="large"
              color="green"
              name="file pdf outline"
              onClick={() =>
                downloadReport(original._id, original.distributorName)
              }
            />
          );
        }
      },
    },
  ];

  const formFilterRegions = () => {
    let options = [];
    options.push({
      key: "",
      text: "",
      value: "",
    });
    if (master.length !== 0) {
      master.map((data, index) => {
        options.push({
          key: index,
          text: data.region,
          value: data.region,
        });
      });
    }
    let unique = removeDup(options, "region");
    return unique;
  };
  const userColumn = [
    {
      Header: "Email Id's",
      accessor: "email",
      style: { textAlign: "left", cursor: "pointer" },
      Cell: ({ row: { original } }) => {
        return <span>{original.email}</span>;
      },
      // Cell: row =>
      //   <AuditTableCell row={row.original} text={row.original.email} />
    },
  ];

  const userAssign = () => {
    setIsModalOpen(false);
  };

  const userData = (rowData, checkedValue) => {
    var selectedData = [...checked];
    let formAssignData = {
      userId: rowData[0]._id,
      storeId: selectedData,
    };
    props.AssignAuditsAction({
      assignData: formAssignData,
      onSuccess: () => {
        props.fetchHershysCheckMasterAction(template._id);
        // this.props.fetchHersheysStoresAction(this.props.selectedTemplate._id);
        setIsModalOpen(false);
        NotificationManager.success(
          "Audits Assigned Successfully",
          "SUCCESS MESSAGE",
          3000,
          true
        );
      },
      onFailure: () => {
        NotificationManager.error(
          "Audit Not Assigned",
          "ERROR MESSAGE",
          3000,
          true
        );
      },
    });
  };

  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
      <NotificationContainer />

      <LoadingScreen active={loading} text={LoaderText} />
      {isMasterView && (
        <div>
          <div style={{ padding: 5, display: "inline-block" }}>
            <Segment
              onClick={props.onClose}
              style={{
                color: "#808080",
                float: "right",
                cursor: "pointer",
                marginTop: "10px",
                position: "absolute",
                right: 15,
              }}
            >
              <Icon name="arrow" className="left large" color="brown" />
            </Segment>
            <h1
              style={{
                color: "orange",
                display: "inline-block",
              }}
            >
              Hershys Checklist Masters
            </h1>
          </div>
          <Grid style={{ margin: 1 }}>
            <Grid.Row columns={4}>
              <Grid.Column width={4}>
                <Dropdown
                  selection
                  options={formFilterRegions("region")}
                  value={Region}
                  style={{ width: "100%", zIndex: 100 }}
                  placeholder="Region"
                  onChange={(e, data) => {
                    setRegion(data.value);
                    setCity("");
                    setOffice("");
                  }}
                />
              </Grid.Column>
              <Grid.Column width={4}>
                <Dropdown
                  selection
                  placeholder="City"
                  options={cityOpt}
                  style={{ width: "100%", zIndex: 100 }}
                  value={City}
                  onChange={(e, data) => {
                    setCity(data.value);
                    setOffice("");
                  }}
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <Dropdown
                  selection
                  options={officeOpt}
                  style={{ width: "100%", zIndex: 100 }}
                  placeholder="Matrix Office"
                  value={office}
                  onChange={(e, data) => {
                    setOffice(data.value);
                  }}
                />
              </Grid.Column>
              <Grid.Column width={4}>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    style={{
                      backgroundColor: "#FFFFFF",
                      border: "1px solid orange",
                      display: "flex",
                    }}
                    disabled={Region === ""}
                    onClick={() => {
                      setFiltered([]);
                      setRegion("");
                      setCity("");
                      setOffice("");
                    }}
                  >
                    <Icon name="filter" color="orange" />
                    Reset Filters
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "teal",
                      display: "flex",
                      justifyContent: "center",
                      color: "#FFFFFF",
                    }}
                    onClick={() => {
                      setIsModalOpen(true);
                    }}
                  >
                    Assign Audits
                  </Button>
                </div>
              </Grid.Column>
              <Grid.Column></Grid.Column>
            </Grid.Row>
          </Grid>
          <TableView
            selection={true}
            columns={col}
            data={filtered.length === 0 ? master : filtered}
            onSelectionChange={(rows, row) => sendedData(rows, row)}
          />

          {/* <CheckboxTable
            data={filtered.length === 0 ? master : filtered}
            columns={columns}
            rowClick={handleTableViewAuditClick}
            sendedData={sendedData} */}
          {/* /> */}
          <Modal
            open={isModalOpen}
            onClose={() => {
              setIsModalOpen(false);
            }}
          >
            <Modal.Header>Assign To Users</Modal.Header>
            <Modal.Content>
              <CheckboxforAssign
                data={
                  props.hershysCheck.userDetails !== undefined
                    ? props.hershysCheck.userDetails
                    : []
                }
                columns={userColumn}
                sendedData={userData}
              />
            </Modal.Content>
            <Modal.Actions>
              <Button
                color="red"
                onClick={() => {
                  setIsModalOpen(false);
                }}
              >
                <Icon name="remove" /> Close
              </Button>
              <Button color="black" onClick={userAssign}>
                Save
              </Button>
            </Modal.Actions>
          </Modal>
        </div>
      )}
      {!isMasterView && <Audits onClose={back} />}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    hershysCheck: state.hershysCheck,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setSelectedMasterData,
      fetchExistingAuditsIfPresent,
      clearFetchedAudits,
      downloadHershysChecklistAuditReportAction,
      AssignAuditsAction,
      fetchHershysCheckMasterAction,
    },
    dispatch
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Master));
