import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn } from "./../../util";
import {
  UploadUserCsv,
  dismissLogs,
  initiateUpload,
  pandgAgencyUploadCsv,
  pandgDistributorUploadCsv,
  pandgFunctionalManagerUploadCsv,
  pandgLocationUploadCsv,
} from "../../actions/control_panel_action";
import { Grid, Dimmer, Loader } from "semantic-ui-react";
import { Button, Modal } from "semantic-ui-react";
import CommonForAllPannels from "./CommonForAllPannels.jsx";

class DtcControlPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userCsvFile: null,
      distributorCsvFile: null,
      functionalManagerCsvFile: null,
      agencyCsvFile: null,
      locationCsvFile: null,
    };
  }

  componentWillMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!isLoggedIn(nextProps.auth)) {
      this.props.history.push(`/login`);
      return false;
    }
    return true;
  }

  dtchandleUserFileUpload = (files) => {
    const file = files.target.files[0];
    this.setState({ userCsvFile: file });
    files.target.files = null;
  };

  dtchandleDistributorFileUpload = (files) => {
    const file = files.target.files[0];
    this.setState({ distributorCsvFile: file });
    files.target.files = null;
  };

  dtchandleFunctionalManagerFileUpload = (files) => {
    const file = files.target.files[0];
    this.setState({ functionalManagerCsvFile: file });
    files.target.files = null;
  };

  dtchandleAgencyFileUpload = (files) => {
    const file = files.target.files[0];
    this.setState({ agencyCsvFile: file });
    files.target.files = null;
  };

  dtchandleLocationFileUpload = (files) => {
    const file = files.target.files[0];
    this.setState({ locationCsvFile: file });
    files.target.files = null;
  };

  dtcuploadUserData = () => {
    let dtcUrl = "/admin/v1/pandg/addUsers";
    this.props.initiateUpload(true);
    this.props.uploadUserCsv(dtcUrl, this.state.userCsvFile);
  };

  dtcuploadDistributorData = () => {
    this.props.initiateUpload(true);
    this.props.uploadDistributorCsv(this.state.distributorCsvFile);
  };

  dtcuploadFunctionalManagerData = () => {
    this.props.initiateUpload(true);
    this.props.uploadFunctionalManagerCsv(this.state.functionalManagerCsvFile);
  };

  dtcuploadAgencyData = () => {
    this.props.initiateUpload(true);
    this.props.uploadAgencyCsv(this.state.agencyCsvFile);
  };

  dtcuploadLocationData = () => {
    this.props.initiateUpload(true);
    this.props.uploadLocationCsv(this.state.locationCsvFile);
  };

  dtccloseLog = () => {
    this.props.dismissLogs(false);
  };

  render() {
    let dtcPannel = [
      {
        itemForSpan: " Upload User CSV to add Users",
        itemInputChange: this.dtchandleUserFileUpload,
        itemClickButton: this.dtcuploadUserData,
        itemContentForButton: "Upload User CSV",
      },
      {
        itemForSpan: " Upload Distributor CSV to add Distributors",
        itemInputChange: this.dtchandleDistributorFileUpload,
        itemClickButton: this.dtcuploadDistributorData,
        itemContentForButton: "Upload Audit excel",
      },
      {
        itemForSpan:
          " Upload Functional manager CSV to add Functional Managers",
        itemInputChange: this.dtchandleFunctionalManagerFileUpload,
        itemClickButton: this.dtcuploadFunctionalManagerData,
        itemContentForButton: "Upload Functional manager CSV",
      },
      {
        itemForSpan: "Upload Agency CSV to add Agency",
        itemInputChange: this.dtchandleAgencyFileUpload,
        itemClickButton: this.dtcuploadAgencyData,
        itemContentForButton: "Upload Agency CSV",
      },
      {
        itemForSpan: "Upload Location CSV to add Location",
        itemInputChange: this.dtchandleLocationFileUpload,
        itemClickButton: this.dtcuploadLocationData,
        itemContentForButton: "Upload Location CSV",
      },
    ];
    return (
      <div
        style={{
          marginLeft: "2vw",
          marginTop: "7vh",
          fontWeight: "bold",
          fontFamily: "Arial, Helvetica, sans-serif",
        }}
      >
        <Dimmer active={this.props.controlPanel.uploadIncomplete}>
          <Loader size="massive">Uploading</Loader>
        </Dimmer>
        <Grid columns={2}>
          <Grid.Row>
            <CommonForAllPannels child={dtcPannel} color={this.props.color} />
          </Grid.Row>
        </Grid>
        {this.props.controlPanel.showLog && (
          <Modal
            close={this.dtccloseLog}
            open={this.props.controlPanel.showLog}
          >
            <Modal.Header>Upload Report</Modal.Header>
            <Modal.Content>
              <div
                dangerouslySetInnerHTML={{
                  __html: this.props.controlPanel.log,
                }}
              />
            </Modal.Content>
            <Modal.Actions>
              <Button color="black" onClick={this.dtccloseLog}>
                Dismiss
              </Button>
            </Modal.Actions>
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    home: state.home,
    controlPanel: state.controlPanel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      uploadUserCsv: UploadUserCsv,
      uploadDistributorCsv: pandgDistributorUploadCsv,
      uploadFunctionalManagerCsv: pandgFunctionalManagerUploadCsv,
      uploadAgencyCsv: pandgAgencyUploadCsv,
      uploadLocationCsv: pandgLocationUploadCsv,
      initiateUpload: initiateUpload,
      dismissLogs: dismissLogs,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DtcControlPanel)
);
